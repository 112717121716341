import { Action } from '@/store/types';
import { GeonamesInstance } from 'geonames.js/src/geonames-types';
import { TenantReference } from '@mentessa/types';

export const moduleName = 'utils';

export interface Language {
  code: string;
  name: string;
}

export interface UtilsState {
  languages: Language[];
  geonames: GeonamesInstance;
}

export enum UtilsActions {
  LoadLanguages = '[Utils] LoadLanguages',
  InitGeonames = '[Utils] InitGeonames',
  LoadCitySuggestions = '[Utils] Load City Suggestions',
  HubSpotTestNewContact = '[Utils] HubSpot test New Contact',
  LoadAllKeycloakClients = '[Utils] Load All Keycloak Clients',
  UpdateAllKeycloakClients = '[Utils] Update All Keycloak Clients',
  CreateAllKeycloakClients = '[Utils] Create All Keycloak Clients',
  LoadAllKeycloakIdentityProviders = '[Utils] Load All Keycloak Identity Providers',
}

export enum UtilsMutations {
  SetLanguages = '[Utils] Set Languages',
  SetGeonames = '[Utils] Set Geonames',
}

export class LoadLanguages implements Action {
  type = `${moduleName}/${UtilsActions.LoadLanguages}`;

  constructor(private readonly locale: string) {}
}

export class InitGeonames implements Action {
  type = `${moduleName}/${UtilsActions.InitGeonames}`;

  constructor(private readonly locale: string) {}
}

export class LoadCitySuggestions implements Action {
  type = `${moduleName}/${UtilsActions.LoadCitySuggestions}`;

  constructor(private readonly token: string) {}
}

export class HubSpotTestNewContact implements Action {
  type = `${moduleName}/${UtilsActions.HubSpotTestNewContact}`;
}

export class LoadAllKeycloakClients implements Action {
  type = `${moduleName}/${UtilsActions.LoadAllKeycloakClients}`;

  constructor(private tenantRef?: TenantReference) {}
}

export class UpdateAllKeycloakClients implements Action {
  type = `${moduleName}/${UtilsActions.UpdateAllKeycloakClients}`;
}

export class CreateAllKeycloakClients implements Action {
  type = `${moduleName}/${UtilsActions.CreateAllKeycloakClients}`;
}

export class LoadAllKeycloakIdentityProviders implements Action {
  type = `${moduleName}/${UtilsActions.LoadAllKeycloakIdentityProviders}`;
}
