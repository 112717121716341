import { MessagesMutations, MessagesState } from './types';
import { MutationTree } from 'vuex';
import { MessageEntity, RoomEntity } from '@mentessa/types';

export const mutations: MutationTree<MessagesState> = {
  [MessagesMutations.SetRooms](state: MessagesState, rooms: RoomEntity[] = []) {
    rooms.forEach((room) => {
      room.members.forEach((member) => {
        if (member.user && !member.user.identity.attributes.image) {
          member.user.identity.attributes.image = '/user-empty.png';
        }
      });
    });
    state.rooms = rooms;
  },
  [MessagesMutations.AppendRoom](state: MessagesState, room: RoomEntity) {
    const existingRoomId = state.rooms.findIndex((r) => r.id === room.id);
    if (existingRoomId !== -1) {
      state.rooms[existingRoomId] = room;
    } else {
      state.rooms = [room, ...state.rooms];
    }
  },
  [MessagesMutations.SetMessages](state: MessagesState, messages: MessageEntity[]) {
    state.messages.items = messages;
  },
  [MessagesMutations.AppendMessage](state: MessagesState, message: MessageEntity) {
    state.messages.items = [message, ...state.messages.items];
  },
  [MessagesMutations.SetTimer](state: MessagesState, timer?: number) {
    if (timer == null && state.fetchTimer != null) {
      clearInterval(state.fetchTimer);
      state.fetchTimer = undefined;
    } else {
      state.fetchTimer = timer;
    }
  },
  [MessagesMutations.SetLastFetchTime](state: MessagesState, time: Date) {
    state.lastFetchTime = time;
  },
  [MessagesMutations.SetUnreadMessageCount](state: MessagesState, count: number) {
    state.unreadMessageCount = count;
  },
  [MessagesMutations.SetActiveRoom](state: MessagesState, room: RoomEntity) {
    state.activeRoom = room;
  },
  [MessagesMutations.ReplaceRoom](state: MessagesState, { target, replacement }) {
    const roomIndex = state.rooms.findIndex((room) => room.id === target.id);
    if (roomIndex !== -1) {
      state.rooms[roomIndex] = replacement;
    }
  },
};
