<template>
  <m-panel class="fill-width">
    <h3 class="mb-2">{{ $t('mentoring.appointments.title') }}</h3>
    <m-appointments-list
      :appointments="appointments"
      :members="session?.members ?? []"
      :session="session"
      @onBookButtonClick="$emit('onBookButtonClick')"
    />

    <v-btn v-if="isBookingAvailable && !readOnly" :to="appointmentRoute" class="mt-4" outlined>
      {{ $t('mentoring.appointments.showAll') }}
    </v-btn>
  </m-panel>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';
import { IAppointment, IMentoringSession, MentoringSessionStatus } from '@mentessa/types';
import { MAppointmentsList } from '@/components/MAppointmentsList';
import { RouteName } from '@/router/types';
import { compareAsc, parseISO } from 'date-fns';
import MPanel from '@/components/MPanel';

export default Vue.extend({
  name: 'm-mentoring-session-appointments',
  components: { MPanel, MAppointmentsList },
  props: {
    session: { type: Object as PropType<IMentoringSession> },
  },
  computed: {
    ...mapGetters('tenant', {
      isBookingAvailable: 'isBookingAvailable',
    }),
    appointmentRoute() {
      return { name: RouteName.Appointments };
    },
    appointments() {
      let appointments: Array<IAppointment> = this.session?.appointments ?? [];
      return appointments
        .slice()
        .sort((l, r) =>
          compareAsc(parseISO((l.date ?? l.slot?.date).toString()), parseISO((r.date ?? r.slot?.date).toString())),
        );
    },
    readOnly() {
      return this.session?.status !== MentoringSessionStatus.Progress;
    },
  },
});
</script>

<style lang="scss" scoped></style>
