<template>
  <div>
    <m-feature-enable-switch feature="home" />
    <m-panel>
      <v-switch v-model="localConfig.goals" hide-details label="Goals" />
      <v-switch v-model="localConfig.profileScore" hide-details label="Profile score" />
      <v-switch v-model="localConfig.nextSteps" hide-details label="Next steps" />
      <v-switch v-model="localConfig.stats" hide-details label="Stats" />
      <v-switch v-model="localConfig.matches" hide-details label="Matches" />
      <v-switch v-model="localConfig.sessions" hide-details label="Sessions" />
      <v-switch v-model="localConfig.badges" hide-details label="Badges" />
      <v-switch v-model="localConfig.journals" hide-details label="Journals" />
      <v-switch v-model="localConfig.dragAndDrop" hide-details label="Drag and drop" />

      <v-btn color="button" class="mt-4" depressed @click="save">Save</v-btn>
    </m-panel>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { ChangeFeatureConfig, TenantState } from '@/store/tenant';
import MFeatureEnableSwitch from '@/components/Settings/MSettingsFeatures/MFeatureEnableSwitch';
import { diff } from 'deep-object-diff';
import deepmerge from 'deepmerge';
import MPanel from '@/components/MPanel';

export default Vue.extend({
  name: 'm-settings-features-home',
  components: { MPanel, MFeatureEnableSwitch },
  data: () => ({
    localConfig: {
      goals: false,
      profileScore: false,
      nextSteps: false,
      stats: false,
      matches: false,
      sessions: false,
      badges: false,
      journals: false,
      dragAndDrop: false,
    },
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    ...mapGetters('tenant', {
      featureConfig: 'featureConfig',
    }),
    homeConfig() {
      return this.featureConfig('home');
    },
  },
  mounted() {
    this.updateData();
  },
  methods: {
    async save() {
      const update = diff(this.homeConfig, deepmerge(this.homeConfig, this.localConfig));
      await this.$store.dispatch(new ChangeFeatureConfig(this.tenant, 'home', update));
    },
    updateData() {
      this.localConfig.goals = this.homeConfig.goals;
      this.localConfig.profileScore = this.homeConfig.profileScore;
      this.localConfig.nextSteps = this.homeConfig.nextSteps;
      this.localConfig.stats = this.homeConfig.stats;
      this.localConfig.matches = this.homeConfig.matches;
      this.localConfig.sessions = this.homeConfig.sessions;
      this.localConfig.badges = this.homeConfig.badges;
      this.localConfig.journals = this.homeConfig.journals;
      this.localConfig.dragAndDrop = this.homeConfig.dragAndDrop;
    },
  },
});
</script>
