import { Action } from '@/store/types';
import { IToken, TokensPage, UserEntity } from '@mentessa/types';

export const moduleName = 'tokens';

export interface TokensState {
  tokens: TokensPage;
}

export enum TokensActions {
  LoadTokens = '[Tokens] Load Tokens',
  CreateToken = '[Tokens] Create Token',
  DeleteToken = '[Tokens] Delete Token',
}

export enum TokensMutations {
  SetTokens = '[Tokens] Set Tokens',
  RemoveToken = '[Tokens] Remove Token',
}

export class LoadTokens implements Action {
  type = `${moduleName}/${TokensActions.LoadTokens}`;

  constructor(private page: number = 1, private readonly user?: UserEntity) {}
}

export class CreateToken implements Action {
  type = `${moduleName}/${TokensActions.CreateToken}`;

  constructor(private readonly token: Omit<IToken, 'id'>, private readonly user?: UserEntity) {}
}

export class DeleteToken implements Action {
  type = `${moduleName}/${TokensActions.DeleteToken}`;

  constructor(private readonly token: IToken, private readonly user?: UserEntity) {}
}
