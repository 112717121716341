<template>
  <v-dialog :value="value" max-width="574" sentry-tag="discord-dialog" @input="$emit('input', $event)">
    <div class="m-close-icon-container">
      <a class="m-close-icon" sentry-tag="discord-dialog.button.close" @click="$emit('input', false)">
        <v-img contain height="24" src="@/assets/close-icon.svg" width="24" />
      </a>
    </div>
    <v-sheet class="pa-14">
      <h2 class="text-center">{{ $t('settings.integrations.discord.title') }}</h2>
      <validation-observer v-slot="{ invalid }">
        <m-text-field
          class="ma-4"
          v-model="serverId"
          :label="$t('settings.integrations.discord.serverId')"
          validation-rules="required"
          sentry-tag="discord-dialog.input.serverId"
        />
        <div class="d-flex justify-space-between">
          <v-btn sentry-tag="discord-dialog-dialog.button.close" @click="$emit('input', false)">
            {{ $t('settings.integrations.discord.cancel') }}
          </v-btn>
          <v-btn
            :disabled="invalid"
            :loading="loading"
            color="button"
            sentry-tag="discord-dialog-dialog.button.setup"
            @click="connect"
          >
            {{ $t('settings.integrations.discord.setup') }}
          </v-btn>
        </div>
      </validation-observer>
    </v-sheet>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { ValidationObserver } from 'vee-validate';
import { MTextField } from '@/components/Inputs';
import { CreateIntegration, UpdateIntegration } from '@/store/tenant';
import { IIntegration, IntegrationType } from '@mentessa/types';

export default Vue.extend({
  name: 'm-discord-dialog',
  components: {
    ValidationObserver,
    MTextField,
  },
  props: {
    value: Boolean,
    discordIntegration: Object as PropType<IIntegration>,
  },
  data: () => ({
    loading: false,
    serverId: undefined,
  }),
  watch: {
    value(value: boolean) {
      if (value) {
        this.serverId = this.discordIntegration?.attributes?.discord?.serverId ?? '';
      }
    },
  },
  methods: {
    async connect() {
      this.loading = true;
      try {
        if (!this.discordIntegration) {
          await this.$store.dispatch(
            new CreateIntegration({
              type: IntegrationType.Discord,
              attributes: {
                discord: {
                  serverId: this.serverId,
                },
              },
            }),
          );
        } else {
          await this.$store.dispatch(
            new UpdateIntegration(
              { id: this.discordIntegration.id },
              {
                attributes: {
                  discord: {
                    serverId: this.serverId,
                  },
                },
              },
            ),
          );
        }
      } finally {
        this.loading = false;
      }
      this.$emit('input', false);
    },
  },
});
</script>

<style lang="scss" scoped>
.close-icon {
  position: absolute;
  top: 18px;
  right: 18px;
}

.m-close-icon-container {
  display: flex;
  justify-content: flex-end;
}

.m-close-icon {
  position: absolute;
  margin: 18px;
}
</style>
