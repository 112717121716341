<template>
  <m-dialog
    :fullscreen="isQuestionnaireStep"
    :slim="isQuestionnaireStep"
    :value="show"
    persistent
    @click:outside="close"
    @keydown.esc="close"
  >
    <div class="fill-height">
      <m-pick-role-form v-if="isPickRoleStep" v-model="selectedRole" :ritual="ritual" @onNext="onRolePicked" />
      <m-questionnaire-form
        v-else-if="isQuestionnaireStep"
        :ritual-id="ritualId"
        :role="selectedRole"
        @onNext="onQuestionnaireDone"
      />
      <m-success-form v-else-if="isSuccessStep" :ritual="ritual" @onNext="goToProfile" />
      <div v-else class="m-dialog-container--loading d-flex align-center flex-column py-16">
        <m-loader :isLoading="true" style="position: absolute; width: 100%; height: 100%"></m-loader>
      </div>
    </div>
  </m-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import { RouteName } from '@/router/types';
import MLoader from '@/components/MLoader.vue';
import { EnrollToRitual, LoadRitual, LoadRitualQuestionnaire } from '@/store/rituals';
import { IRitualQuestionnaire } from '@mentessa/types';
import { MPickRoleForm, MQuestionnaireForm, MSuccessForm } from './forms';
import { MDialog } from '@/components/Dialogs/MDialog';
import { mapState } from 'vuex';
import { UIState } from '@/store/ui';

enum EnrollStep {
  Loading = 'loading',
  PickRole = 'pick_role',
  Questionnaire = 'questionnaire',
  Success = 'done',
  Error = 'error',
}

export default Vue.extend({
  name: 'm-ritual-enroll-dialog',
  components: { MDialog, MQuestionnaireForm, MSuccessForm, MPickRoleForm, MLoader },
  props: { ritualId: Number },
  data: () => ({
    loading: true,
    step: EnrollStep.Loading,
    ritual: undefined,
    selectedRole: undefined,
    ritualQuestionnaire: undefined,
  }),
  computed: {
    ...mapState<UIState>('ui', {
      dialogsBlocked: (state: UIState) => state.dialogsBlocked,
    }),
    show() {
      return !this.dialogsBlocked;
    },
    isSuccessStep() {
      return this.step === EnrollStep.Success;
    },
    isPickRoleStep() {
      return this.step === EnrollStep.PickRole;
    },
    isQuestionnaireStep() {
      return this.step === EnrollStep.Questionnaire;
    },
  },
  async mounted() {
    try {
      this.ritual = await this.$store.dispatch(new LoadRitual({ id: this.ritualId }));
      if (this.ritual.attributes.roles?.choose === true) {
        this.step = EnrollStep.PickRole;
      } else {
        const ritualQuestionnaire: IRitualQuestionnaire = await this.$store.dispatch(
          new LoadRitualQuestionnaire({ id: this.ritualId }, this.selectedRole),
        );
        if (ritualQuestionnaire) {
          this.ritualQuestionnaire = ritualQuestionnaire;
          this.step = EnrollStep.Questionnaire;
        } else {
          const success = await this.$store.dispatch(new EnrollToRitual({ id: this.ritualId }));
          if (success) {
            this.step = EnrollStep.Success;
          }
        }
      }
    } catch (e) {
      this.step = EnrollStep.Error;
    }
  },
  methods: {
    async close() {
      await this.$router.push({ name: RouteName.Rituals });
    },
    async goToProfile() {
      await this.$router.push({ name: RouteName.Profile });
    },
    async onRolePicked() {
      this.step = EnrollStep.Questionnaire;
    },
    async onQuestionnaireDone() {
      this.step = EnrollStep.Success;
    },
  },
});
</script>

<style lang="scss" scoped>
.m-settings-dialog__form {
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: 20px;
}
</style>
