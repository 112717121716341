import { Action } from '@/store/types';
import { IInvite, InviteKind, InvitesPage } from '@mentessa/types';

export const moduleName = 'invites';

export interface InvitesState {
  invites: InvitesPage;
  current?: IInvite;
}

export enum InvitesActions {
  LoadInvites = '[Invites] Load Invites',
  LoadInvite = '[Invites] Load Current Invite',
  CreateInvite = '[Invites] Create Invite',
  DeleteInvite = '[Invites] Delete Invite',
  DisableInvite = '[Invites] Disable Invite',
  UseInvite = '[Invites] Use Invite',
}

export enum InvitesMutations {
  SetInvites = '[Invites] Set Invites',
  SetCurrentInvite = '[Invites] Set Current Invite',
  AppendInvite = '[Invites] Append Invite',
}

export class LoadInvites implements Action {
  type = `${moduleName}/${InvitesActions.LoadInvites}`;

  constructor(private readonly page: number = 1, private readonly kind: InviteKind = InviteKind.All) {}
}

export class LoadInvite implements Action {
  type = `${moduleName}/${InvitesActions.LoadInvite}`;

  constructor(private readonly guid: string) {}
}

export class CreateInvite implements Action {
  type = `${moduleName}/${InvitesActions.CreateInvite}`;

  constructor(private readonly invite: Omit<IInvite, 'id'>) {}
}

export class DeleteInvite implements Action {
  type = `${moduleName}/${InvitesActions.DeleteInvite}`;

  constructor(private readonly invite: IInvite) {}
}

export class DisableInvite implements Action {
  type = `${moduleName}/${InvitesActions.DisableInvite}`;

  constructor(private readonly invite: IInvite) {}
}

export class UseInvite implements Action {
  type = `${moduleName}/${InvitesActions.UseInvite}`;

  constructor(private readonly invite: IInvite, private readonly email: string) {}
}
