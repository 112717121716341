import { marked } from 'marked';
import DOMPurify from 'dompurify';

const MarkdownPlugin = {
  install(Vue): void {
    Vue.prototype.$marked = function (input: string) {
      return marked(
        DOMPurify.sanitize(input, {
          ALLOWED_TAGS: [], // disallow all HTML tags
          ALLOWED_ATTR: [], // disallow all HTML attributes
        }),
      );
    };
  },
};

export default MarkdownPlugin;

declare module 'vue/types/vue' {
  interface Vue {
    readonly $marked: typeof marked;
  }
}
