<template>
  <div :style="style" class="d-flex justify-space-between py-4 m-section-container">
    <div>
      <h3 class="text--text mb-2">{{ $t('settings.rituals.ritualEdit.sectionName.title') }}</h3>
      <div class="text--text text--lighten-5">{{ $t('settings.rituals.ritualEdit.sectionName.subtitle') }}</div>
    </div>
    <div class="form_container">
      <div class="text--text text--lighten-1 mb-2">{{ $t('settings.rituals.ritualEdit.sectionName.labelTitle') }}</div>
      <m-text-field v-model="title" @change="onFieldUpdate($event, 'title')" />
      <div class="text--text text--lighten-1 mb-2 mt-4">
        {{ $t('settings.rituals.ritualEdit.sectionName.labelDescription') }}
      </div>
      <m-text-field v-model="description" @change="onFieldUpdate($event, 'description')" />
      <div class="text--text text--lighten-1 mb-2 mt-4">
        {{ $t('settings.rituals.ritualEdit.sectionName.labelIcon') }}
      </div>
      <m-select v-model="iconOption" :items="iconOptions" :outlined="true">
        <template v-slot:item="{ item }">
          <template v-if="item !== 'custom'">
            <m-icon :name="item" />
          </template>
          <template v-else>
            {{ $t(`settings.rituals.ritualEdit.sectionName.icons.${item}`) }}
          </template>
        </template>
        <template v-slot:selection="{ item }">
          <template v-if="item !== 'custom'">
            <m-icon :name="item" />
          </template>
          <template v-else>
            <slot name="selection" v-bind="item">
              {{ $t(`settings.rituals.ritualEdit.sectionName.icons.${item}`) }}
            </slot>
          </template>
        </template>
      </m-select>
      <m-image-file-input v-if="iconOption === 'custom'" v-model="icon" class="mt-4 form-image-step__file-input" />
      <div class="d-flex mt-4 gap-4">
        <v-date-picker v-model="range" :locale="locale" no-title range @input="updateRange" />
        <div>
          <div class="text--text text--lighten-1 mb-2 mt-4">
            {{ $t('settings.rituals.ritualEdit.sectionName.labelStart') }}
            <p class="text-center">
              <strong>{{ range?.[0] }}</strong>
            </p>
          </div>

          <div class="text--text text--lighten-1 mb-2 mt-4">
            {{ $t('settings.rituals.ritualEdit.sectionName.labelEnd') }}
            <p class="text-center">
              <strong>{{ range?.[1] }}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { AssetIcon, IRitual, RitualType } from '@mentessa/types';
import { MImageFileInput, MSelect, MTextField } from '@/components/Inputs';
import MIcon from '@/components/MIcon';
import { mapGetters, mapState } from 'vuex';
import { UIState } from '@/store/ui';

export default Vue.extend({
  name: 'm-ritual-edit-section-name',
  components: {
    MSelect,
    MIcon,
    MTextField,
    MImageFileInput,
  },
  data: () => ({
    title: '',
    description: '',
    iconOption: '',
    icon: { src: null },
    iconOptions: Object.values(AssetIcon),
    range: undefined,
  }),
  props: {
    value: { type: Object as PropType<IRitual>, default: () => ({}) },
  },
  computed: {
    ...mapState<UIState>('ui', {
      locale: (state: UIState) => state.translations.dateLocale.code,
    }),
    ...mapGetters('ui', {
      formatInUserTimeZone: 'formatInUserTimeZone',
    }),
    defaultIconOption() {
      switch (this.value?.type as RitualType) {
        case RitualType.HappyHour:
          return AssetIcon.Cup;
        case RitualType.SkillsMatching:
          return AssetIcon.Hands;
        case RitualType.EventMatching:
          return AssetIcon.Diamond;
      }
      return undefined;
    },
    style() {
      return {
        '--m-flex-direction': this.$vuetify.breakpoint.smAndDown ? 'column' : 'row',
        '--m-text-align': this.$vuetify.breakpoint.smAndDown ? 'center' : '',
      };
    },
  },
  watch: {
    value() {
      this.title = this.value.attributes.title ?? this.$t(`settings.rituals.knownNames.${this.value.type}`);
      this.description =
        this.value.attributes.description ?? this.$t(`settings.rituals.knownDescriptions.${this.value.type}`);
      this.iconOption = this.value.attributes?.icon
        ? 'custom'
        : this.value.attributes?.assetIcon
        ? this.value.attributes.assetIcon
        : this.defaultIconOption;
      if (this.value.attributes.start && this.value.attributes.end) {
        this.range = [this.value.attributes.start, this.value.attributes.end];
      } else {
        this.range = undefined;
      }
    },
    iconOption() {
      if (this.iconOption === 'custom') {
        this.icon.src ??= this.value.attributes?.icon;
        this.onFieldUpdate(this.icon, 'icon');
      } else {
        this.onFieldUpdate(this.iconOption, 'assetIcon');
        this.onFieldUpdate(null, 'icon');
      }
    },
    'icon.src'() {
      this.onFieldUpdate(this.icon, 'icon');
    },
  },
  methods: {
    updateRange(value: Array<string>) {
      if (value[0] !== this.value?.attributes?.start) {
        this.onFieldUpdate(value[0], 'start');
      }

      if (value[1] !== this.value?.attributes?.end) {
        this.onFieldUpdate(value[1], 'end');
      }
    },
    onFieldUpdate(value, field: string) {
      const newRitual = this.value;
      newRitual['attributes'][field] = value;
      this.$emit('input', newRitual);
    },
  },
});
</script>

<style lang="scss" scoped>
.form_container {
  padding: 24px;
  width: 100%;
  background-color: var(--v-white-base);
  border: 1px solid var(--v-beige-base);
  border-radius: 16px;
  max-width: 600px;
}

.m-section-container {
  flex-direction: var(--m-flex-direction);
  text-align: var(--m-text-align);
  gap: 15px;
}
</style>
