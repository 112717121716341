<template>
  <v-col>
    <v-row>
      <p>Import users (Warning: Only for Luther):</p>
    </v-row>
    <v-row class="align-center">
      <v-file-input
        v-model="importMembers.file"
        accept="text/csv"
        dense
        hide-details
        outlined
        placeholder="Save xlsx as 'csv (UTF-8)' and put it here:"
      />
      <v-btn :disabled="!importMembers.file" class="ml-8" @click="processImportFile">Process</v-btn>
    </v-row>
    <v-row v-if="importMembers.users.length > 0">
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-center"><span>#</span></th>
            <th class="text-center"><span>Preview</span></th>
            <th class="text-center"><span>FirstName</span></th>
            <th class="text-center"><span>LastName</span></th>
            <th class="text-center"><span>Email</span></th>
            <th class="text-center"><span>Location</span></th>
            <th class="text-center"><span>Gender</span></th>
            <th class="text-center"><span>Service Line</span></th>
            <th class="text-center"><span>Company Role</span></th>
            <th class="text-center"><span>Mentoring Focus</span></th>
            <th class="text-center"><span>Meeting Locations</span></th>
            <th class="text-center"><span>Target group</span></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(user, index) in importMembers.users" :key="`import-user-${index}`">
            <td class="text-center">{{ index }}</td>
            <td>
              <m-user-preview :user="user" />
            </td>
            <td>{{ user.identity.attributes.firstName }}</td>
            <td>{{ user.identity.attributes.lastName }}</td>
            <td>{{ user.identity.attributes.email }}</td>
            <td>{{ user.attributes.location }}</td>
            <td>{{ user.identity.attributes.gender }}</td>
            <td>{{ user.attributes.custom.service_line }}</td>
            <td>{{ user.attributes.custom.company_role }}</td>
            <td>{{ user.attributes.custom.mentoring_focus }}</td>
            <td>{{ user.attributes.mentoring.meetingLocations }}</td>
            <td>{{ user.attributes.custom.target_group }}</td>
          </tr>
        </tbody>
      </v-simple-table>
      Mentors: {{ importMembers.mentorsCount }}; Mentees: {{ importMembers.menteesCount }};
    </v-row>
    <v-row v-if="importMembers.users.length > 0">
      <v-checkbox v-model="importMembers.sure" label="Yes, I want to send emails for all of those users" />
      <v-btn :disabled="!importMembers.sure" class="ml-auto" color="button" depressed @click="doImport"
        >Do Import
      </v-btn>
    </v-row>
  </v-col>
</template>

<script lang="ts">
import Vue from 'vue';
import { ImportMembers } from '@/store/tenant';
import { MeetingLocation, MentoringRole } from '@mentessa/types';
import MUserPreview from '@/components/MUserPreview/UserPreviewPure.vue';

function readFileAsync(file): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsText(file);
  });
}

export default Vue.extend({
  name: 'm-import-users-luther',
  components: { MUserPreview },
  data: () => ({
    importMembers: {
      file: undefined,
      users: [],
      mentorsCount: 0,
      menteesCount: 0,
      sure: false,
    },
  }),
  watch: {
    async usersCSV(file) {
      try {
        const content = await readFileAsync(file);
        this.importMembers.content = content.split('\n');
      } catch (e) {
        console.log(e);
      }
    },
  },
  methods: {
    async processImportFile() {
      this.importMembers.mentorsCount = 0;
      this.importMembers.menteesCount = 0;
      this.importMembers.sure = false;
      const content = await readFileAsync(this.importMembers.file);
      const lines = content.split('\n');
      lines.splice(0, 1);

      const members = [];

      for (const line of lines) {
        const splittedLine = line.split(';');
        if (splittedLine.length < 20 || splittedLine[0].trim().length === 0) {
          console.log(`Wrong line: ${line}`, splittedLine);
          continue;
        }

        // 6 -  Mentoring Präsenz
        // 7 -  Mentoring Remote
        // 8 -  Fokus: Networking & Geschäftsentwicklung
        // 9 -  Fokus: Work-Life-Integration
        // 10 - Fokus: Karriere & Entwicklung
        // 11 - Zielgruppe: Junior Consultant
        // 12 - Zielgruppe: Consultant
        // 13 - Zielgruppe: Senior Consultant
        // 14 - Zielgruppe Associates
        // 15 - Zielgruppe Senior Associates
        // 16 - Zielgruppe Counsel
        // 17 - Zielgruppe Partner

        const firstName = splittedLine[1].trim();
        const lastName = splittedLine[0].trim();
        const email = splittedLine[18].trim().toLowerCase();
        const gender = splittedLine[4].trim().toLowerCase() === 'frau' ? 'female' : 'male';
        const location = splittedLine[2].trim();
        const companyRole = splittedLine[3].trim();
        const serviceLine = splittedLine[5].trim();
        const image = splittedLine[19].trim()
          ? `https://master-identity.obs.eu-de.otc.t-systems.com/${splittedLine[19].trim()}`
          : undefined;
        const mentoringFocus = [];
        const targetGroup = [];

        const mentoring = {
          roles: [MentoringRole.Mentor],
          meetingLocations: [] as Array<MeetingLocation>,
        };

        if (splittedLine[6]) {
          mentoring.meetingLocations.push(MeetingLocation.InPerson);
        }
        if (splittedLine[7]) {
          mentoring.meetingLocations.push(MeetingLocation.Online);
        }
        if (splittedLine[8]) {
          mentoringFocus.push('networking');
        }
        if (splittedLine[9]) {
          mentoringFocus.push('work_life');
        }
        if (splittedLine[10]) {
          mentoringFocus.push('career');
        }

        if (splittedLine[11]) {
          targetGroup.push('junior_consultant');
        }
        if (splittedLine[12]) {
          targetGroup.push('consultant');
        }
        if (splittedLine[13]) {
          targetGroup.push('senior_consultant');
        }
        if (splittedLine[14]) {
          targetGroup.push('associates');
        }
        if (splittedLine[15]) {
          targetGroup.push('senior_associates');
        }
        if (splittedLine[16]) {
          targetGroup.push('counsel');
        }
        if (splittedLine[17]) {
          targetGroup.push('partner');
        }

        members.push({
          identity: {
            attributes: {
              image,
              firstName,
              lastName,
              email,
              gender,
            },
          },
          attributes: {
            roles: ['mentor'],
            location,
            mentoring,
            custom: {
              company_role: companyRole,
              service_line: serviceLine,
              mentoring_focus: mentoringFocus,
              target_group: targetGroup,
            },
          },
        });
      }

      this.importMembers.users = members;
    },
    async doImport() {
      await this.$store.dispatch(new ImportMembers(this.importMembers.users, true));
    },
  },
});
</script>
