<template>
  <v-container>
    <v-row class="ma-4" v-if="false">
      <v-btn class="ma-4" sentry-tag="settings-debug.button.generateSlot" @click="generateSlots">
        Generate random slots for all users
      </v-btn>
      <v-btn class="ma-4" sentry-tag="settings-debug.button.clearBookedSlots" @click="clearBookedSlots">
        Clear booked slots for all users
      </v-btn>
    </v-row>
    <v-btn @click="loadSessions">Load Sessions</v-btn>
    <v-simple-table>
      <thead>
        <tr>
          <th class="text-center"><span>ID</span></th>
          <th class="text-center"><span>Status</span></th>
          <th class="text-center"><span>Member 1</span></th>
          <th class="text-center"><span>Member 2</span></th>
          <th class="text-center">...</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(session, index) in sessions" :key="`session-${index}`">
          <td class="text-center">{{ session.id }}</td>
          <td class="text-center">{{ session.status }}</td>
          <td class="text-center">
            <v-row class="justify-center align-center">
              [{{ session.members?.[0]?.id }}, {{ session.members?.[0]?.role }}] &nbsp;
              <m-user-preview :user="session.members?.[0]?.user" debug />
            </v-row>
          </td>
          <td class="text-center">
            <v-row class="justify-center align-center">
              [{{ session.members?.[1]?.id }}, {{ session.members?.[1]?.role }}] &nbsp;
              <m-user-preview :user="session.members?.[1]?.user" debug />
            </v-row>
          </td>
          <td class="text-center">
            <v-btn icon @click="deleteSession(session)">
              <v-icon>close</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <h3 class="ma-4 mb-1">Add a mentoring session:</h3>
    <m-ais-wrapper index="users">
      <v-row>
        <m-ais-autocomplete-users
          v-model="newSessionMentor"
          class="ma-4"
          debug
          labelTop="Mentor"
          max-width="600px"
          prepend-inner-icon="mdi-message-plus-outline"
        />
        <m-ais-autocomplete-users
          v-model="newSessionMentee"
          class="ma-4"
          debug
          labelTop="Mentee"
          max-width="600px"
          prepend-inner-icon="mdi-message-plus-outline"
        />
      </v-row>
    </m-ais-wrapper>
    <v-btn :disabled="!newSessionMentor || !newSessionMentee" @click="createSession">Create</v-btn>
  </v-container>
</template>

<script lang="ts">
import { ClearBookedSlots, RandomizeSlots } from '@/store/matching';
import {
  CreateMentoringSessionDebug,
  DeleteMentoringSessionDebug,
  LoadMentoringSessionListDebug,
} from '@/store/mentoring';
import {
  IMentoringSession,
  IMentoringSessionMember,
  MentoringRole,
  MentoringSessionReference,
  MentoringSessionStatus,
} from '@mentessa/types';
import MUserPreview from '@/components/MUserPreview/UserPreviewPure.vue';
import MAisWrapper from '@/components/MAIS/AISWrapper/AISWrapper.vue';
import MAisAutocompleteUsers from '@/components/MAIS/AISAutocompleteUsers/MAISAutocompleteUsers.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'm-settings-debug-mentoring',
  components: { MAisAutocompleteUsers, MAisWrapper, MUserPreview },
  data: () => ({
    sessions: [],
    newSessionMentor: undefined,
    newSessionMentee: undefined,
  }),
  methods: {
    async generateSlots() {
      await this.$store.dispatch(new RandomizeSlots());
    },
    async clearBookedSlots() {
      await this.$store.dispatch(new ClearBookedSlots());
    },
    async loadSessions() {
      const data = await this.$store.dispatch(new LoadMentoringSessionListDebug());
      if (Array.isArray(data)) {
        this.sessions = data;
      } else {
        this.sessions = [];
      }
    },
    async createSession() {
      const newSession: Partial<IMentoringSession> = {
        status: MentoringSessionStatus.Progress,
        members: [
          {
            role: MentoringRole.Mentor,
            user: { id: this.newSessionMentor.id },
          } as IMentoringSessionMember,
          {
            role: MentoringRole.Mentee,
            user: { id: this.newSessionMentee.id },
          } as IMentoringSessionMember,
        ],
      };
      const session = await this.$store.dispatch(new CreateMentoringSessionDebug(newSession));
      this.sessions.push(session);
    },
    async deleteSession(sessionRef: MentoringSessionReference) {
      await this.$store.dispatch(new DeleteMentoringSessionDebug(sessionRef));
      const sessionId = this.sessions.findIndex((session) => session.id === sessionRef.id);
      if (sessionId !== -1) {
        this.sessions.splice(sessionId, 1);
      }
    },
  },
});
</script>
