import { GetterTree } from 'vuex';
import { UsersState } from './types';
import { RootState } from '@/store/types';
import { PermissionLevel, UserPermissions } from '@mentessa/types';

export const getters: GetterTree<UsersState, RootState> = {
  canRead: (state: UsersState) => (permission: keyof UserPermissions) => {
    return (
      state.permissions[permission] === PermissionLevel.Read || state.permissions[permission] === PermissionLevel.Write
    );
  },
  canWrite: (state: UsersState) => (permission: keyof UserPermissions) => {
    return state.permissions[permission] === PermissionLevel.Write;
  },
  completionPercentage: (state: UsersState, getters, rootState: RootState) => {
    // ToDo: Adjust availablity calculations
    const personalValues = [
      state.me.identity.attributes.image !== '/user-empty.png' ? state.me.identity.attributes.image : undefined,
      state.me.identity.attributes.firstName,
      state.me.identity.attributes.lastName,
      state.me.identity.attributes.email,
      state.me.identity.attributes.gender,
      state.me.attributes.company,
      state.me.attributes.jobTitle,
      state.me.attributes.location,
      state.me.attributes.languages,
    ];

    const customFields = state.me.attributes?.custom ?? {};

    rootState.tenant.tenant.attributes?.customFields?.forEach((field) => {
      if (!field.hide) personalValues.push(customFields[field.name]);
    });

    const filledFieldsAmount = personalValues.reduce((sum, current) => (current?.length > 0 ? 1 : 0) + sum, 0);
    const personalFieldsScore = (filledFieldsAmount * 50) / personalValues.length;
    const personalScore = personalFieldsScore + (state.me.attributes.about?.length > 0 ? 50 : 0);
    const skillsScore = (state.me.expertiseTags?.length > 0 ? 50 : 0) + (state.me.interestTags?.length > 0 ? 50 : 0);
    const availabilityScore =
      (state.me.attributes.mentoring?.meetingLocations?.length > 0 ? 25 : 0) +
      (state.me.attributes.mentoring?.roles?.length > 0 ? 25 : 0) +
      (state.schedules?.length > 0 ? 50 : 0);

    const totalScore = (personalScore + skillsScore + availabilityScore) / 3;
    return Math.floor(totalScore);
  },
  isPersonalCompleted: (state: UsersState): boolean => {
    const fieldsValues = [
      state.me.identity.attributes.image !== '/user-empty.png' ? state.me.identity.attributes.image : undefined,
      state.me.identity.attributes.firstName,
      state.me.identity.attributes.lastName,
      state.me.identity.attributes.email,
      state.me.attributes.company,
      state.me.attributes.jobTitle,
      state.me.attributes.location,
      state.me.attributes.about,
      state.me.attributes.languages,
    ];

    return fieldsValues.every((value) => value?.length > 0);
  },
  isSkillsCompleted: (state: UsersState, getters, rootState: RootState): boolean => {
    const fieldsValues = [state.me.expertiseTags, state.me.interestTags];

    const customFields = state.me.attributes?.custom ?? {};

    rootState.tenant.tenant.attributes?.customFields?.forEach((field) => {
      if (!field.hide) fieldsValues.push(customFields[field.name]);
    });

    return fieldsValues.every((value) => value?.length > 0);
  },
  isAvailabilityCompleted: (state: UsersState): boolean => {
    // ToDo: Think if we count switched-off availability as not filled value
    return (
      (state.me.attributes.mentoring?.available &&
        Object.keys(state.me.attributes.mentoring?.timeSlots ?? {}).length > 0) ??
      false
    );
  },
};
