import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { api } from '@/store/api';
import { ShowErrorNotification, ShowNotification } from '@/store/notifications';
import i18n from '@/plugins/i18n';
import { EmailsActions, EmailsMutations, EmailsState } from './types';

export const actions: ActionTree<EmailsState, RootState> = {
  async [EmailsActions.LoadEmails]({ commit, dispatch }, { page, options }) {
    try {
      const response = await api.get('email-templates', {
        params: { page, locale: options?.locale, type: options?.type },
      });
      const emails = response?.data;
      commit(EmailsMutations.SetEmails, emails);
    } catch (e) {
      console.error('Error while LoadEmails.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [EmailsActions.CreateEmail]({ dispatch }, { email }) {
    try {
      const response = await api.post('email-templates', email);
      return response?.status === 201 ?? false;
    } catch (e) {
      console.error('Error while CreateEmail.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [EmailsActions.DeleteEmail]({ dispatch }, { email }) {
    try {
      await api.delete(`email-templates/${email.id}`);
    } catch (e) {
      console.error('Error while DeleteEmail.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [EmailsActions.UpdateEmail]({ dispatch }, { email, update }) {
    try {
      const response = await api.put(`email-templates/${email.id}`, update);
      return response.data;
    } catch (e) {
      console.error('Error while UpdateEmail.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [EmailsActions.SendEmail]({ dispatch }, { email, options }) {
    try {
      const response = await api.post(`mail/test`, {
        type: email.type,
        options,
      });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.error('Error while SendEmail.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [EmailsActions.PreviewEmail]({ dispatch }, { email, options }) {
    try {
      const response = await api.post(`mail/preview`, {
        type: email.type,
        options,
      });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.error('Error while PreviewEmail.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
};
