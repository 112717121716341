<template>
  <div v-if="value" class="m-notification-bar d-flex justify-center">
    <div class="d-flex justify-center flex-grow-1">
      <span>
        {{ $t('notifications.desktop.text') }}
        <a class="text-decoration-underline font-weight-bold" @click="allow">
          {{ $t('notifications.desktop.allow') }}
        </a>
      </span>
    </div>
    <v-icon class="m-notification-bar__close-button" @click="closeNotificationBar">mdi-close</v-icon>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { differenceInDays, parseISO } from 'date-fns';

export default Vue.extend({
  name: 'm-notification-bar',
  props: {
    value: Boolean,
  },
  methods: {
    async allow() {
      if (Notification.permission !== 'denied') {
        await Notification.requestPermission();
        this.$emit('input', false);
      }
    },
    closeNotificationBar() {
      localStorage.setItem('skipNotifications', new Date().toISOString());
      this.$emit('input', false);
      this.$emit('closed');
    },
  },
  created() {
    if (Notification.permission !== 'default') {
      this.$emit('input', false);
    } else {
      const skipNotifications = localStorage.getItem('skipNotifications');
      if (skipNotifications) {
        if (differenceInDays(new Date(), parseISO(skipNotifications)) < 14) {
          this.$emit('input', false);
        } else {
          localStorage.removeItem('skipNotifications');
        }
      }
    }
  },
});
</script>

<style lang="scss" scoped>
.m-notification-bar {
  color: var(--v-white-base) !important;
  width: 100%;

  a {
    color: var(--v-white-base) !important;
  }

  &:deep(.v-icon) {
    color: var(--v-white-base) !important;
  }

  &__close-button {
    cursor: pointer;
  }
}
</style>
