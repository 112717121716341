<template>
  <v-dialog v-if="value" :value="value" @input="$emit('input', false)" persistent max-width="480">
    <div class="m-close-icon-container">
      <a class="m-close-icon" sentry-tag="bind-ritual-questionnaire-dialog.button.close" @click="disable">
        <v-img contain height="24" src="@/assets/close-icon.svg" width="24" />
      </a>
    </div>
    <v-sheet class="pt-14 pb-6 px-8 m-nps-dialog">
      <div class="d-flex flex-column justify-center align-center">
        <p class="m-nps-dialog__heading">
          {{ $t(`nps.${nps.title}.text`) }}
        </p>
        <m-scale v-model="score" :defaultValue="1" :max="10" :boundaries="boundaries" />
        <v-btn color="button" depressed :block="$vuetify.breakpoint.xsOnly" @click="send">
          {{ $t(`nps.${nps.title}.send`) }}
        </v-btn>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { MScale } from '@/components/Inputs';
import { DisableNpsForUser, SendNpsResult } from '@/store/nps';
import { INps } from '@mentessa/types';

export default Vue.extend({
  name: 'm-nps-dialog',
  components: { MScale },
  props: {
    value: { type: Boolean, default: false },
    nps: Object as PropType<INps>,
  },
  data: () => ({
    score: 10,
  }),
  computed: {
    boundaries(): Array<string> {
      return [this.$t(`nps.${this.nps.title}.boundaries[0]`), this.$t(`nps.${this.nps.title}.boundaries[1]`)];
    },
  },
  methods: {
    async send() {
      await this.$store.dispatch(new SendNpsResult(this.nps, this.score));
    },
    async disable() {
      await this.$store.dispatch(new DisableNpsForUser(this.nps));
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.m-close-icon-container {
  display: flex;
  justify-content: flex-end;
}

.m-close-icon {
  position: absolute;
  margin: 18px;
}

.m-nps-dialog {
  :deep(.m-scale-container) {
    margin-bottom: 12px;
    margin-top: 8px;
    width: 100%;
  }

  &__heading {
    font-size: 20px;
    text-align: center;
  }

  :deep(.v-btn) {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      margin-top: 24px;
    }
  }
}
</style>
