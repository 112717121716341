<template>
  <div :class="classes">
    <v-row class="justify-space-between ma-0">
      <h3>{{ $t('dashboard.widgets.ritual_matches.title') }}</h3>
      <router-link :to="detailsRoute">{{ $t('dashboard.widgets.details') }}</router-link>
    </v-row>
    <v-row class="mt-0">
      <v-col style="height: 200px; width: 440px; max-width: 450px; min-width: 440px">
        <h4 class="mt-0">{{ $t('dashboard.widgets.ritual_matches.total') }}</h4>
        <h2 class="text-center mt-4">{{ total }}</h2>
        <h4 class="mt-4">{{ $t('dashboard.widgets.ritual_matches.upcoming_date') }}</h4>
        <h2 class="text-center mt-4">-</h2>
      </v-col>
      <v-col class="pt-0" style="height: 200px; width: 440px; max-width: 450px; min-width: 440px">
        <h4 class="mt-0" v-if="false">{{ $t('dashboard.widgets.ritual_matches.status_title') }}</h4>
        <doughnut :data="values" :options="statusChartOptions" />
      </v-col>
    </v-row>

    <v-row v-if="false" class="ma-0">
      <v-col style="max-width: 40px">
        <h1>{{ total }}</h1>
      </v-col>
      <v-col style="max-width: 350px; max-height: 200px">
        <doughnut :data="values" :options="statusChartOptions" />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IRitual, MatchStatus } from '@mentessa/types';
import { Doughnut } from 'vue-chartjs';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { generateLabelsWithCount } from '@/utils/charts';
import { mapGetters } from 'vuex';
import { RouteName } from '@/router/types';

ChartJS.register(ArcElement, Tooltip, Legend);

export interface RitualMatchesData {
  ritual_matches: Array<{ status: MatchStatus; count: string }>;
}

const options = {
  responsive: true,
  maintainAspectRatio: true,
};

export default Vue.extend({
  name: 'm-ritual-matches-widget',
  components: {
    Doughnut,
  },
  props: {
    value: { type: Object as PropType<RitualMatchesData> },
    ritual: { type: Object as PropType<IRitual> },
  },
  data: () => ({
    total: 0,
    pending: 0,
    accepted: 0,
    declined: 0,
    expired: 0,
    closed: 0,
  }),
  computed: {
    ...mapGetters('tenant', {
      getColorPalette: 'getColorPalette',
    }),
    classes() {
      return {
        'm-dashboard-widget__row-2': this.$vuetify.breakpoint.mdAndUp,
        'm-dashboard-widget__col-2': this.$vuetify.breakpoint.mdAndUp,
        'm-dashboard-widget__col-4': this.$vuetify.breakpoint.smAndDown,
      };
    },
    detailsRoute() {
      if (!this.ritual) {
        return undefined;
      }
      return { name: RouteName.RitualManageMembers, params: { ritualId: this.ritual.id } };
    },
    values() {
      return {
        labels: [
          this.$t(`dashboard.widgets.ritual_matches.status.${MatchStatus.Pending}`),
          this.$t(`dashboard.widgets.ritual_matches.status.${MatchStatus.Accepted}`),
          this.$t(`dashboard.widgets.ritual_matches.status.${MatchStatus.Declined}`),
          this.$t(`dashboard.widgets.ritual_matches.status.${MatchStatus.Expired}`),
          this.$t(`dashboard.widgets.ritual_matches.status.${MatchStatus.Closed}`),
        ],
        datasets: [
          {
            backgroundColor: this.getColorPalette,
            data: [this.pending, this.accepted, this.declined, this.expired, this.closed],
          },
        ],
      };
    },
    options() {
      return options;
    },
    statusChartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: 'right',
            labels: {
              font: {
                size: 16,
                lineHeight: 1,
              },
              generateLabels: generateLabelsWithCount,
            },
          },
        },
      };
    },
  },
  async mounted() {
    this.value?.ritual_matches?.forEach((row) => {
      const count = Number(row.count ?? 0);
      switch (row.status) {
        case MatchStatus.Pending:
          this.pending = count;
          break;
        case MatchStatus.Accepted:
          this.accepted = count;
          break;
        case MatchStatus.Declined:
          this.declined = count;
          break;
        case MatchStatus.Expired:
          this.expired = count;
          break;
        case MatchStatus.Closed:
          this.closed = count;
          break;
      }
      this.total += count;
    });
  },
});
</script>

<style lang="scss" scoped>
.m-chart {
  max-height: 240px;
}
</style>
