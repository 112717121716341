<template>
  <div class="m-dashboard-widget__col-2">
    <h4>{{ $t('dashboard.widgets.ritual_top_performance.title') }}</h4>
    <div v-if="users.length > 0" class="d-flex flex-column gap-4 mt-10 ml-4">
      <div v-for="user of users" :key="`user-${user.user.id}`">
        <div class="d-flex flex-row gap-4 align-center">
          <m-user-preview :user="user.user" />
        </div>
      </div>
    </div>
    <div v-else class="d-flex justify-center align-center fill-height text-center">
      {{ $t(`dashboard.no_data`) }}
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IUser, UserIdentityAttributes } from '@mentessa/types';
import MUserPreview from '@/components/MUserPreview';

export interface RitualTopPerformanceData {
  ritual_top_performance: Array<{ userId: string; identityAttributes: UserIdentityAttributes; count: string }>;
}

export default Vue.extend({
  name: 'm-ritual-top-performance-widget',
  components: { MUserPreview },
  props: {
    value: { type: Object as PropType<RitualTopPerformanceData> },
  },
  data: () => ({
    users: [] as Array<{ user: IUser; count: number }>,
  }),
  async mounted() {
    const users = this.value?.ritual_top_performance?.map((item) => ({
      user: {
        id: Number(item.userId),
        identity: { attributes: item.identityAttributes },
      },
      count: Number(item.count),
    }));
    this.users = users ?? [];
  },
});
</script>
