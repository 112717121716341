import { Action } from '@/store/types';
import { ContentPage, IContent } from '@mentessa/types';

export const moduleName = 'content';

export interface ContentState {
  content: ContentPage;
}

export enum ContentActions {
  LoadContent = '[Content] Load Content',
  UpdateContent = '[Content] Update Content',
  DeleteContent = '[Content] Delete Content',
  CreateContent = '[Content] Create Content',
}

export enum ContentMutations {
  SetContent = '[Content] Set Content',
}

export class LoadContent implements Action {
  type = `${moduleName}/${ContentActions.LoadContent}`;

  constructor(private readonly page: number = 1, private readonly tags?: Array<string>) {}
}

export class CreateContent implements Action {
  type = `${moduleName}/${ContentActions.CreateContent}`;

  constructor(private readonly content: Omit<IContent, 'id'>) {}
}

export class UpdateContent implements Action {
  type = `${moduleName}/${ContentActions.UpdateContent}`;

  constructor(private readonly content: IContent) {}
}

export class DeleteContent implements Action {
  type = `${moduleName}/${ContentActions.DeleteContent}`;

  constructor(private readonly content: IContent) {}
}
