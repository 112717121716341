<template>
  <m-user-layout-row
    v-if="row"
    :user="user"
    @onCardClick="onCardClick"
    @onFavoriteClick="onFavoriteClick"
    sentry-tag="user-card.row"
  />
  <m-user-layout-column
    v-else
    :user="user"
    @onCardClick="onCardClick"
    @onFavoriteClick="onFavoriteClick"
    sentry-tag="user-card.column"
  />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { UserEntity } from '@mentessa/types';
import { SetFavoriteStatus } from '@/store/users';
import { MUserLayoutColumn, MUserLayoutRow } from './Layouts';

export default Vue.extend({
  name: 'm-user-card',
  components: {
    MUserLayoutColumn,
    MUserLayoutRow,
  },
  props: {
    user: Object as PropType<UserEntity & { highlights: Array<Record<string, unknown>> }>,
    row: { type: Boolean, default: false },
  },
  methods: {
    onCardClick() {
      this.$emit('onUserDetails', this.user);
    },
    async onFavoriteClick() {
      await this.$store.dispatch(new SetFavoriteStatus(this.user, !this.user.isFavorite));
    },
    onBookingClick() {
      this.$emit('onBooking', this.user);
    },
  },
});
</script>
