import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { api } from '@/store/api';
import { ShowErrorNotification } from '@/store/notifications';
import i18n from '@/plugins/i18n';
import { TokensActions, TokensMutations, TokensState } from './types';
import { getIdOrCurrent } from '@/utils/api';

export const actions: ActionTree<TokensState, RootState> = {
  async [TokensActions.LoadTokens]({ commit, dispatch }, { page, user }) {
    try {
      const response = await api.get(`users/${getIdOrCurrent(user)}/tokens?page=${page}`);
      const tokens = response?.data;
      commit(TokensMutations.SetTokens, tokens);
    } catch (e) {
      console.error('Error while LoadTokens.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [TokensActions.CreateToken]({ dispatch }, { token, user }) {
    try {
      const response = await api.post(`users/${getIdOrCurrent(user)}/tokens`, token);
      return response.data;
    } catch (e) {
      console.error('Error while CreateToken.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [TokensActions.DeleteToken]({ dispatch, commit }, { token, user }) {
    try {
      await api.delete(`users/${getIdOrCurrent(user)}/tokens/${token.id}`);
      commit(TokensMutations.RemoveToken, token);
    } catch (e) {
      console.error('Error while DeleteToken.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
};
