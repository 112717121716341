import { MessagesState, moduleName } from './types';
import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';

export * from './types';

export function messagesGetter(getter: string) {
  return `${moduleName}/${getter}`;
}

export const initialState: MessagesState = {
  rooms: [],
  messages: {
    items: [],
    meta: {
      currentPage: 0,
      totalPages: 0,
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 0,
    },
  },
  lastFetchTime: undefined,
  fetchTimer: undefined,
  unreadMessageCount: 0,
  activeRoom: undefined,
};

export const messagesModule: Module<MessagesState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

export default messagesModule;
