<template>
  <component :is="component" ref="widget" :settings="settings" :value="content" v-bind="{ ...$attrs }" />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IDashboardWidget } from '@mentessa/types';
import {
  MRitualAppointmentsWidget,
  MRitualEngagementWidget,
  MRitualGoalsWidget,
  MRitualMatchesWidget,
  MRitualMembersWidget,
  MRitualMentoringSessionsWidget,
  MRitualMonthlyFeedbacksWidget,
  MRitualRegistrationWidget,
  MRitualResultsWidget,
  MRitualTasksWidget,
  MRitualTopFeedbackWidget,
  MRitualTopMatchesWidget,
  MRitualTopPerformanceWidget,
  MRitualUnmatchedMembersWidget,
} from '@/components/Dashboard/widgets';

export default Vue.extend({
  name: 'm-dashboard-widget',

  props: {
    value: { type: Object as PropType<IDashboardWidget> },
  },
  computed: {
    component() {
      switch (this.value?.widget?.name) {
        case 'ritual_members':
          return MRitualMembersWidget;
        case 'ritual_registration':
          return MRitualRegistrationWidget;
        case 'ritual_mentoring_sessions':
          return MRitualMentoringSessionsWidget;
        case 'ritual_goals':
          return MRitualGoalsWidget;
        case 'ritual_tasks':
          return MRitualTasksWidget;
        case 'ritual_matches':
          return MRitualMatchesWidget;
        case 'ritual_appointments':
          return MRitualAppointmentsWidget;
        case 'ritual_monthly_feedbacks':
          return MRitualMonthlyFeedbacksWidget;
        case 'ritual_results':
          return MRitualResultsWidget;
        case 'ritual_unmatched_members':
          return MRitualUnmatchedMembersWidget;
        case 'ritual_engagement':
          return MRitualEngagementWidget;
        case 'ritual_top_matches':
          return MRitualTopMatchesWidget;
        case 'ritual_top_feedback':
          return MRitualTopFeedbackWidget;
        case 'ritual_top_performance':
          return MRitualTopPerformanceWidget;
      }
      console.log(`Unknown widget ${this.value?.widget?.name}`);
      return undefined;
    },
    content() {
      return this.value?.widget?.content ?? {};
    },
    settings() {
      return this.value?.widget?.settings ?? {};
    },
  },
});
</script>
