import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { moduleName, StatsPeriodType, StatsState } from './types';

export * from './types';

export function statsGetter(getter: string) {
  return `${moduleName}/${getter}`;
}

export const initialState: StatsState = {
  stats: {
    //
  },
  period: {
    type: StatsPeriodType.Week,
    amount: 1,
    custom: undefined,
  },
};

export const statsModule: Module<StatsState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

export default statsModule;
