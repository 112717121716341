<template>
  <m-panel :draggable="draggable" :title="$t('panels.next_steps.title')">
    <div class="d-flex flex-column gap-4 mt-8">
      <m-pending-appointment-feedback-card
        v-if="pendingAppointmentFeedback && false"
        :value="pendingAppointmentFeedback"
        @onStateChanged="nextAppointmentFeedback"
      />
      <m-pending-ritual-card v-if="pendingRitual" :value="pendingRitual" @onStateChanged="nextPendingRitual" />
      <div v-for="step in steps" :key="`step-${step.name}`" class="d-flex align-center">
        <div class="step-marker" />
        <span :class="{ 'text-decoration-line-through': step.checked }" class="ml-2">
          {{ $t(`panels.next_steps.known.${step.name}.title`) }}</span
        >
        <v-btn class="ml-auto" color="button" outlined width="160px" @click="doAction(step)">
          {{ $t(`panels.next_steps.known.${step.name}.cta`) }}
        </v-btn>
      </div>
    </div>
  </m-panel>
</template>

<script lang="ts">
import Vue from 'vue';
import MPanel from '@/components/MPanel';
import { mapGetters } from 'vuex';
import { RouteName } from '@/router/types';
import { CheckUserChecklistItem, LoadUserPersonalChecklist } from '@/store/users';
import { Route } from 'vue-router';
import MPendingAppointmentFeedbackCard from '@/components/Cards/MPendingAppointmentFeedbackCard';
import { HomeFeature, IAppointment, IRitual, RitualUserStatus } from '@mentessa/types';
import { LoadPendingAppointments } from '@/store/matching';
import { LoadUserRituals } from '@/store/rituals';
import MPendingRitualCard from '@/components/Cards/MPendingRitualCard';

export default Vue.extend({
  name: 'm-next-steps-panel',
  components: { MPendingRitualCard, MPendingAppointmentFeedbackCard, MPanel },
  props: {
    draggable: { type: Boolean, default: false },
  },
  data: () => ({
    loading: false,
    resouces: false,
    checklist: {
      skills: false,
      resources: false,
      availability: false,
      goals: false,
    },
    pendingAppointmentFeedbacks: [] as Array<IAppointment>,
    pendingAppointmentFeedbackIndex: 0,
    pendingRituals: [] as Array<IRitual>,
    pendingRitualIndex: 0,
  }),
  computed: {
    ...mapGetters('tenant', {
      isResourcesAvailable: 'isResourcesAvailable',
      isBookingAvailable: 'isBookingAvailable',
      featureConfig: 'featureConfig',
    }),
    homeFeature(): HomeFeature {
      return this.featureConfig('home');
    },
    isLearningGoalsAvailable() {
      // return this.homeFeature?.goals ?? false;
      return false;
    },
    steps() {
      if (!this.checklist) {
        return [];
      }
      const steps = [{ name: 'add_skills', route: { name: RouteName.ProfileSkills }, checked: this.checklist.skills }];
      if (this.isLearningGoalsAvailable) {
        steps.push({ name: 'set_goals', route: undefined, checked: this.checklist.goals });
      }
      if (this.isResourcesAvailable) {
        steps.push({
          name: 'check_resources',
          route: { name: RouteName.Resources },
          checked: this.checklist.resources,
        });
      }
      if (this.isBookingAvailable) {
        steps.push({
          name: 'set_availability',
          route: { name: RouteName.ProfileAvailability },
          checked: this.checklist.availability,
        });
      }
      return steps as Array<{ name: string; route: Route; checked: boolean }>;
    },
    pendingAppointmentFeedback() {
      return this.pendingAppointmentFeedbacks[this.pendingAppointmentFeedbackIndex];
    },
    pendingRitual() {
      return this.pendingRituals[this.pendingRitualIndex];
    },
  },
  async mounted() {
    await this.reload();

    const appointments: Array<IAppointment> = await this.$store.dispatch(new LoadPendingAppointments());
    this.pendingAppointmentFeedbackIndex = 0;
    this.pendingAppointmentFeedbacks = appointments ?? [];

    const rituals: Array<IAppointment> = await this.$store.dispatch(new LoadUserRituals(RitualUserStatus.Invited));
    this.pendingRitualIndex = 0;
    this.pendingRituals = rituals ?? [];

    this.$emit('onReady');
  },
  methods: {
    async reload() {
      try {
        this.loading = true;
        const checklist = await this.$store.dispatch(new LoadUserPersonalChecklist());
        this.checklist = checklist;
      } finally {
        this.loading = false;
      }
    },

    async doAction(step: { name: string; route: Route; checked: boolean }) {
      try {
        this.loading = true;
        if (step.name === 'check_resources') {
          const checklist = await this.$store.dispatch(new CheckUserChecklistItem('resources'));
          this.checklist = checklist;
        }

        if (step.name === 'set_goals') {
          //
        } else {
          await this.$router.push(step.route);
        }
      } finally {
        this.loading = false;
      }
    },

    nextAppointmentFeedback() {
      ++this.pendingAppointmentFeedbackIndex;
    },
    nextPendingRitual() {
      ++this.pendingRitualIndex;
    },
  },
});
</script>

<style lang="scss" scoped>
:deep(.v-list-item__action) {
  min-width: unset;
}

.step-marker {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: var(--v-primary-base);
}
</style>
