<template>
  <v-dialog
    :value="value"
    max-width="700"
    sentry-tag="bind-ritual-questionnaire-dialog"
    @input="$emit('input', $event)"
  >
    <div class="m-close-icon-container">
      <a class="m-close-icon" sentry-tag="bind-ritual-questionnaire-dialog.button.close" @click="$emit('input', false)">
        <v-img contain height="24" src="@/assets/close-icon.svg" width="24" />
      </a>
    </div>
    <v-sheet class="pa-14">
      <h2 class="text-center ma-4">{{ $t('settings.rituals.bindDialog.title') }}</h2>
      <validation-observer v-slot="{ invalid }">
        <v-row>
          <v-col class="pa-0">
            <v-data-table
              :headers="headers"
              :items="bindedQuestionnaires"
              :loading="loading"
              :disable-sort="true"
              class="m-manage-roles-dialog__table"
              hide-default-footer
            >
              <template v-slot:item.role="{ item, index }">
                <m-select class="ma-4" v-model="item.filter.role" :items="roles(index)" />
              </template>
              <template v-slot:item.questionnaire="{ item, index }">
                <m-select
                  :id="`questionnaire_${index}`"
                  :value="getQuestionnaireValue(item)"
                  @input="bindRitualQuestionnaire(item, $event)"
                  :items="questionnaires"
                  validation-rules="required"
                  :label="$t('settings.rituals.bindDialog.labels.questionnaire')"
                />
              </template>
              <template v-slot:item.actions="{ index }">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon @click="removeBinding(index)">
                      <v-icon color="red">mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('settings.rituals.bindDialog.labels.remove') }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <div class="d-flex justify-end mt-4">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon @click="addBinding">
                <v-icon color="primary">mdi-plus-circle-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('settings.rituals.bindDialog.labels.add') }}</span>
          </v-tooltip>
        </div>
        <div class="d-flex justify-space-between mt-8">
          <v-btn sentry-tag="suspend-member-dialog.button.close" @click="$emit('input', false)">
            {{ $t('settings.rituals.bindDialog.cancel') }}
          </v-btn>
          <v-btn
            :disabled="invalid"
            :loading="loading"
            color="button"
            large
            depressed
            sentry-tag="bind-ritual-questionnaire-dialog.button.bind"
            @click="save"
          >
            {{ $t('settings.rituals.bindDialog.save') }}
          </v-btn>
        </div>
      </validation-observer>
    </v-sheet>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { ValidationObserver } from 'vee-validate';
import { MSelect } from '@/components/Inputs';
import {
  CreateRitualQuestionnaire,
  DeleteRitualQuestionnaire,
  LoadQuestionnaires,
  LoadRitualQuestionnaires,
  UpdateRitualQuestionnaire,
} from '@/store/rituals';
import { IQuestionnaire, IRitual, IRitualQuestionnaire, MentoringRole } from '@mentessa/types';
import { detailedDiff } from 'deep-object-diff';

export default Vue.extend({
  name: 'm-bind-ritual-questionnaire-dialog',
  components: {
    ValidationObserver,
    MSelect,
  },
  props: {
    value: Boolean,
    ritual: { type: Object as PropType<IRitual> },
  },
  data: () => ({
    bindedQuestionnaires: [],
    initialBindedQuestionnaires: [],
    questionnaires: [],
    loading: false,
  }),
  computed: {
    headers() {
      return [
        { text: this.$t('settings.rituals.bindDialog.labels.id'), value: 'id' },
        { text: this.$t('settings.rituals.bindDialog.labels.role'), value: 'role' },
        { text: this.$t('settings.rituals.bindDialog.labels.questionnaire'), value: 'questionnaire' },
        { text: this.$t('settings.rituals.bindDialog.labels.actions'), value: 'actions' },
      ];
    },
  },
  watch: {
    async value(value: boolean) {
      if (value) {
        this.initialBindedQuestionnaires = [];
        this.bindedQuestionnaires = [];
        this.questionnaires = [];
        await this.LoadQuestionnaires();
      }
    },
  },
  methods: {
    async save() {
      try {
        this.loading = true;
        const difference = detailedDiff(this.initialBindedQuestionnaires, this.bindedQuestionnaires);
        const updated = Object.keys(difference.updated);
        // delete removed bindings
        Object.keys(difference.deleted).forEach(async (key) => {
          const item = this.initialBindedQuestionnaires[key];
          if (updated.includes(key)) {
            difference.added[key] = difference.updated[key];
            delete difference.updated[key];
          }
          await this.$store.dispatch(new DeleteRitualQuestionnaire(item));
        });
        // update changed bindings
        Object.keys(difference.updated).forEach(async (key) => {
          const item = this.bindedQuestionnaires[key];
          item.ritual = this.ritual;
          await this.$store.dispatch(new UpdateRitualQuestionnaire(item));
        });
        // add new bindings
        Object.values(difference.added).forEach(async (item) => {
          item.ritual = this.ritual;
          await this.$store.dispatch(new CreateRitualQuestionnaire(item));
        });
      } finally {
        this.loading = false;
      }
      this.$emit('input', false);
      this.$emit('bind-questionnaire');
    },
    bindRitualQuestionnaire(item: IRitualQuestionnaire, event: string) {
      const [, id, description, title] = /^\[(\d+)\] (.+) \((.+)\)$/.exec(event);
      item.questionnaire.id = Number(id);
      item.questionnaire.title = title;
      item.questionnaire.description = description;
    },
    addBinding() {
      this.bindedQuestionnaires.push({ filter: {}, questionnaire: {} });
    },
    async LoadQuestionnaires() {
      try {
        this.loading = true;
        const { items } = await this.$store.dispatch(new LoadQuestionnaires());
        items.forEach((item: IQuestionnaire) => {
          this.questionnaires.push(`[${item.id}] ${item.description} (${item.title})`);
        });
        this.initialBindedQuestionnaires = await this.$store.dispatch(new LoadRitualQuestionnaires(this.ritual));
        this.bindedQuestionnaires = JSON.parse(JSON.stringify(this.initialBindedQuestionnaires));
      } finally {
        this.loading = false;
      }
    },
    removeBinding(index: number) {
      this.bindedQuestionnaires.splice(index, 1);
    },
    getQuestionnaireValue(item: IRitualQuestionnaire) {
      if (Object.keys(item.questionnaire).length > 0) {
        return `[${item.questionnaire.id}] ${item.questionnaire.description} (${item.questionnaire.title})`;
      }
      return null;
    },
    roles(myIndex: number) {
      const roles = ['any', ...Object.values(MentoringRole)];
      const usedRoles = this.bindedQuestionnaires.map((item: IRitualQuestionnaire, index: number) => {
        if (index !== myIndex && item.filter?.role) {
          return item.filter.role;
        }
      });
      return roles.filter((role) => !usedRoles.includes(role));
    },
  },
});
</script>

<style lang="scss" scoped>
.close-icon {
  position: absolute;
  top: 18px;
  right: 18px;
}

.m-close-icon-container {
  display: flex;
  justify-content: flex-end;
}

.m-close-icon {
  position: absolute;
  margin: 18px;
}
</style>
