<template>
  <div class="d-flex flex-column pa-4">
    <m-image-picker v-model="image" :label="$t('profile.personal.image')" :user="me" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { ITag, IUser } from '@mentessa/types';
import { MImagePicker } from '@/components/Inputs';
import { mapState } from 'vuex';
import { UsersState } from '@/store/users';

export default Vue.extend({
  name: 'm-onboarding-photo-form',
  components: {
    MImagePicker,
  },
  props: {
    value: Object as PropType<IUser>,
  },
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    image: {
      get() {
        return this.value?.identity?.attributes.image;
      },
      set(value: Array<ITag>) {
        this.$emit('input', {
          ...this.value,
          identity: {
            ...this.value?.identity,
            attributes: {
              ...this.value?.identity?.attributes,
              image: value,
            },
          },
        });
      },
    },
  },
});
</script>
