<template>
  <v-tabs
    v-model="periodIndex"
    background-color="background"
    class="m-dashboard__period mr-4"
    sentry-tag="dashboard-period"
  >
    <v-tab v-for="(period, index) in periods" :key="index">{{ period.title }}</v-tab>
  </v-tabs>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { StatsPeriodOptions, StatsPeriodType } from '@/store/stats';

export default Vue.extend({
  name: 'm-dashboard-period',
  props: {
    value: {
      type: Object as PropType<StatsPeriodOptions>,
      default: (): StatsPeriodOptions => ({
        type: StatsPeriodType.Week,
        amount: 1,
        custom: undefined,
      }),
    },
  },
  computed: {
    periods() {
      return [
        {
          type: StatsPeriodType.Week,
          title: this.$t('dashboard.period.week'),
        },
        {
          type: StatsPeriodType.Month,
          title: this.$t('dashboard.period.month'),
        },
        {
          type: StatsPeriodType.Year,
          title: this.$t('dashboard.period.year'),
        },
      ];
    },
    periodIndex: {
      get() {
        const index = this.periods.findIndex((period) => period.type === this.value.type);
        return index !== -1 ? index : 0;
      },
      set(index: number) {
        this.$emit('input', { ...this.value, type: this.periods[index].type });
      },
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.m-dashboard__period {
  display: flex;
  flex: unset;
  width: auto;

  border-bottom: 1px solid var(--v-lightgray-base);

  @media #{map-get($display-breakpoints, 'xs-only')} {
    width: 100%;
    margin-top: 0;
  }
}
</style>
