<template>
  <div>
    <h2 class="text--text my-4 text-center">{{ $t('enrollRitual.title') }}</h2>

    <div v-for="(field, index) in questions" :key="index">
      <div v-if="step === index" class="text-center d-flex flex-column align-center m-step-container">
        <div class="text--text text--lighten-5">
          {{ $t('enrollRitual.stepLabel', { current: index + 1, max: questions.length }) }}
        </div>
        <h2 class="primary--text">{{ field.title }}</h2>
        <div class="d-flex justify-center chip-group">
          <v-chip-group
            v-model="answers[index]"
            :multiple="field.multiple"
            active-class="active-chip"
            class="d-flex justify-content-center"
            column
          >
            <v-chip v-for="(fieldItem, index) in field.items" :key="index" dense>{{ fieldItem }}</v-chip>
          </v-chip-group>
        </div>
        <div>
          <v-btn :disabled="!isNextStepAvailable" class="m-next-button" color="button" depressed @click="onNext">
            {{ isLastStep ? $t('enrollRitual.complete') : $t('questionnaires.next') }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'm-enroll-dialog-forms-questionnaire',
  props: {
    value: { type: Array, default: () => [] },
    questions: { type: Array, default: () => [] },
  },
  data: () => ({
    step: 0,
    answers: [],
  }),
  computed: {
    isLastStep() {
      return this.step === this.questions.length - 1;
    },
    isNextStepAvailable() {
      return this.answers?.[this.step] != null;
    },
  },
  mounted() {
    this.answers = new Array(this.questions.length);
  },
  methods: {
    onNext() {
      if (this.isLastStep) {
        this.$emit('input', this.answers);
      } else {
        ++this.step;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.m-step-container {
  gap: 60px;
}

.active-chip {
  background-color: var(--v-primary-base) !important;
  color: var(--v-white-base) !important;
}

:deep(.chip-group) {
  max-width: 326px;
  padding: 17px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  justify-self: center;
  align-items: center;
  justify-content: center;
  background-color: var(--v-white-base);
  border: 0.75px solid #d6d3d1;

  .v-slide-group__content {
    justify-content: center;
  }
}

.m-next-button {
  min-width: 128px !important;
}
</style>
