import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { api } from '@/store/api';
import { ShowErrorNotification, ShowNotification } from '@/store/notifications';
import i18n from '@/plugins/i18n';
import { ContentActions, ContentMutations, ContentState } from './types';

export const actions: ActionTree<ContentState, RootState> = {
  async [ContentActions.LoadContent]({ commit, dispatch }, { page, tags }) {
    try {
      const response = await api.get(`content`, { params: { page, tags } });
      const content = response?.data;
      commit(ContentMutations.SetContent, content);
    } catch (e) {
      console.error('Error while LoadContent.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [ContentActions.CreateContent]({ dispatch }, { content }) {
    try {
      const response = await api.post('content', content);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response?.status === 201 ?? false;
    } catch (e) {
      console.error('Error while CreateContent.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [ContentActions.DeleteContent]({ dispatch }, { content }) {
    try {
      await api.delete(`content/${content.id}`);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
    } catch (e) {
      console.error('Error while DeleteContent.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [ContentActions.UpdateContent]({ dispatch }, { content }) {
    try {
      const response = await api.put(`content/${content.id}`, content);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.error('Error while UpdateContent.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
};
