<template>
  <div>
    <m-feature-enable-switch feature="resources" />
    <v-card v-if="false" class="pa-4">
      <v-combobox v-model="tags" chips deletable-chips hide-details label="Tags" multiple outlined></v-combobox>
      <v-divider class="mb-4"></v-divider>
      <v-btn color="button" depressed @click="save">Save</v-btn>
    </v-card>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { ChangeFeatureConfig, TenantState } from '@/store/tenant';
import MFeatureEnableSwitch from '@/components/Settings/MSettingsFeatures/MFeatureEnableSwitch';

export default Vue.extend({
  name: 'm-settings-features-resources',
  components: { MFeatureEnableSwitch },
  data: () => ({
    tags: [],
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    ...mapGetters('tenant', {
      featureConfig: 'featureConfig',
    }),
    resourcesConfig() {
      return this.featureConfig('resources');
    },
  },
  mounted() {
    this.updateData();
  },
  methods: {
    async save() {
      if (
        JSON.stringify([...this.tags].sort()) !==
        JSON.stringify([...(Array.isArray(this.resourcesConfig.tags) ? this.resourcesConfig.tags : [])].sort())
      ) {
        await this.$store.dispatch(new ChangeFeatureConfig(this.tenant, 'resources', { tags: this.tags }));
      }
    },
    updateData() {
      this.tags = [...(Array.isArray(this.resourcesConfig.tags) ? this.resourcesConfig.tags : [])];
    },
  },
});
</script>
