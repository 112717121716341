import { MutationTree } from 'vuex';
import { MatchingMutations, MatchingState } from './types';
import { AppointmentPage, AppointmentReference, IAppointment, MatchingPage } from '@mentessa/types';
import deepmerge from 'deepmerge';

export const mutations: MutationTree<MatchingState> = {
  [MatchingMutations.SetMatches](state: MatchingState, matches: MatchingPage) {
    state.matches = matches;
  },
  [MatchingMutations.SetAppointments](state: MatchingState, appointments: AppointmentPage) {
    state.appointments = appointments;
  },
  [MatchingMutations.ChangeAppointment](
    state: MatchingState,
    { appointmentRef, dto }: { appointmentRef: AppointmentReference; dto: Partial<IAppointment> },
  ) {
    const appointment = state.appointments.items.find((appointment) => appointment.id === appointmentRef.id);
    if (appointment) {
      if (dto.status != null) appointment.status = dto.status;
      if (dto.date != null) appointment.date = dto.date;
      if (dto.attributes != null) appointment.attributes = deepmerge(appointment.attributes, dto.attributes);
    }
  },
};
