<template>
  <m-panel beige :title="$t('panels.profile.title')" draggable="draggable">
    <v-col class="ma-0 pa-0 gap-4 d-flex flex-column">
      <v-row class="ma-0 align-center">
        <v-col class="flex-grow-1 pa-0">
          <v-sparkline
            v-if="false"
            :line-width="30"
            :padding="16"
            :smooth="15"
            :value="mentessaLogoValues"
            auto-draw
            :gradient="gradient"
            gradient-direction="right"
            :auto-draw-duration="3000"
            height="100"
          />
          <m-user-preview v-else :user="me" big job location />
        </v-col>
        <v-col class="flex-grow-0 text-center pa-0">
          <h1>{{ completionPercentage }}%</h1>
          {{ $t('panels.profile.completed') }}
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-progress-linear :value="completionPercentage" height="25" color="primary" rounded />
      </v-row>
      <v-row class="ma-0">
        <v-btn color="button" width="100%" :to="profileRoute">{{ $t('panels.profile.boost') }}</v-btn>
      </v-row>
    </v-col>
  </m-panel>
</template>

<script lang="ts">
import Vue from 'vue';
import MPanel from '@/components/MPanel';
import { mapGetters, mapState } from 'vuex';
import MUserPreview from '@/components/MUserPreview/UserPreviewPure.vue';
import { UsersState } from '@/store/users';
import { RouteName } from '@/router/types';

export default Vue.extend({
  name: 'm-profile-score-panel',
  components: { MUserPreview, MPanel },
  props: {
    draggable: { type: Boolean, default: false },
  },
  data: () => ({
    mentessaLogoValues: [0, 0, 180, 8, 230, 0, 0],
    profileRoute: { name: RouteName.Profile },
  }),
  computed: {
    ...mapGetters('users', {
      completionPercentage: 'completionPercentage',
    }),
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    gradient() {
      const filledItems = (this.completionPercentage * this.mentessaLogoValues.length) / 100;
      const values = Array.from(Array(this.mentessaLogoValues.length), (v, k) => (k <= filledItems ? 'purple' : 0));
      return values;
    },
  },
  mounted() {
    this.$emit('onReady');
  },
});
</script>
