<template>
  <m-panel>
    <v-row class="justify-center flex-column flex-sm-row">
      <v-col>
        <v-chip color="white">Rate session</v-chip>
        <v-row class="mx-2 mt-2 justify-center align-center gap-4">
          <span> with </span>
          <m-user-preview :user="user" @click="showUserDetailsDialog = true" />
        </v-row>
      </v-col>

      <v-col class="flex-grow-0 gap-2 d-flex flex-sm-column justify-center">
        <v-btn class="" color="button" outlined width="120" @click="rateBad">Bad</v-btn>
        <v-btn color="button" width="120" @click="rateGood">Good</v-btn>
      </v-col>
    </v-row>
  </m-panel>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IAppointment } from '@mentessa/types';
import MPanel from '@/components/MPanel';
import MUserPreview from '@/components/MUserPreview';
import { mapState } from 'vuex';
import { UsersState } from '@/store/users';

export default Vue.extend({
  name: 'm-pending-appointment-feedback-card',
  components: {
    MUserPreview,
    MPanel,
  },
  props: {
    value: Object as PropType<IAppointment>,
  },
  data: () => ({
    showMatchActionDialog: false,
    showRitualMatchActionDialog: false,
    showUserDetailsDialog: false,
    matchAction: undefined,
  }),
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    source() {
      return this.value?.match?.source;
    },
    user() {
      if (this.me?.id === this.value?.target?.id) {
        return this.value.organizer;
      } else if (this.me?.id === this.value?.organizer?.id) {
        return this.value.target;
      }
      return undefined;
    },
  },
  watch: {
    showMatchActionDialog(value: boolean) {
      if (!value) {
        this.$emit('onStateChanged');
      }
    },
    showRitualMatchActionDialog(value: boolean) {
      if (!value) {
        this.$emit('onStateChanged');
      }
    },
  },
  methods: {
    async rateBad() {
      this.matchAction = 'accept';
      if (this.ritual) {
        this.showRitualMatchActionDialog = true;
      } else {
        this.showMatchActionDialog = true;
      }
    },
    async rateGood() {
      this.matchAction = 'decline';
      if (this.ritual) {
        this.showRitualMatchActionDialog = true;
      } else {
        this.showMatchActionDialog = true;
      }
    },
  },
});
</script>

<style lang="scss" scoped></style>
