<template>
  <div sentry-tag="matching-message-form">
    <h2 :class="{ 'm-font-size-16 mt-10': $vuetify.breakpoint.xsOnly }" class="text-center">
      {{ $t('matching.messageFormHeading') }}
    </h2>
    <p class="text-center m-matching-message-form-description">
      {{ $t('matching.messageFormDescription') }}
    </p>
    <p class="mb-2 m-textarea-label">{{ $t('matching.messageFormLabel') }}</p>
    <m-text-area
      v-model="message"
      :disabled="isLoading"
      :placeholder="$t('matching.messageFormPlaceholder')"
      outlined
      persistent-placeholder
      validation-rules="max:1000"
    />
    <div class="mt-4 d-flex gap-4 justify-center flex-wrap">
      <v-btn
        :block="$vuetify.breakpoint.xsOnly"
        :loading="isLoading"
        color="button"
        depressed
        large
        min-width="140"
        outlined
        @click="cancel"
      >
        {{ $t('matching.back') }}
      </v-btn>
      <v-btn
        :block="$vuetify.breakpoint.xsOnly"
        :disabled="!message.length"
        :loading="isLoading"
        color="button"
        depressed
        large
        min-width="140"
        sentry-tag="matching-message-form.button.sendMessage"
        @click="sendMessage"
      >
        {{ $t('matching.sendMessage') }}
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { MTextArea } from '@/components/Inputs';

export default Vue.extend({
  name: 'm-matching-message-form',
  components: { MTextArea },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    value: String,
  },
  computed: {
    message: {
      get() {
        return this.value;
      },
      set(value: string) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    sendMessage() {
      this.$emit('onNext');
    },

    cancel() {
      this.$emit('onCancel');
    },
  },
});
</script>

<style lang="scss" scoped>
.m-textarea-label {
  color: #374151;
  font-size: 14px;
  font-weight: 600;
}

.m-matching-message-form-description {
  font-size: 14px;
  color: #6b7280;
}
</style>
