<template>
  <div class="d-flex justify-center align-center">
    <m-chip-group v-model="selected" :items="items" class="mt-4" i18n-path="onboarding.role.items" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { MChipGroup } from '@/components/Inputs';

export default Vue.extend({
  name: 'm-tenant-onboarding-role-form',
  components: { MChipGroup },
  props: {
    value: { type: Array as PropType<Array<string>>, default: () => [] },
  },
  data: () => ({
    items: [
      'dei',
      'people_and_culture',
      'community_management',
      'transformation',
      'innovation',
      'learning',
      'employee',
    ],
  }),
  computed: {
    selected: {
      get() {
        return this.value?.map((item) => this.items.indexOf(item));
      },
      set(value: Array<number>) {
        this.$emit(
          'input',
          value.map((index) => this.items[index]),
        );
      },
    },
  },
});
</script>
