<template>
  <m-panel :title="$t('panels.badges.title')" draggable="draggable"> </m-panel>
</template>

<script lang="ts">
import Vue from 'vue';
import MPanel from '@/components/MPanel';

export default Vue.extend({
  name: 'm-user-badges-panel',
  components: { MPanel },
  props: {
    draggable: { type: Boolean, default: false },
  },
  mounted() {
    this.$emit('onReady');
  },
});
</script>
