<template>
  <div>
    <h4>{{ $t('dashboard.widgets.ritual_mentoring_sessions.title') }}</h4>
    <v-col class="fill-height d-flex align-center">
      <v-row class="ma-0 align-center">
        <h1>{{ progress }}</h1>
        <span class="ml-2">{{ $t('dashboard.widgets.ritual_mentoring_sessions.progress') }}</span>
      </v-row>
    </v-col>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { MentoringSessionStatus } from '@mentessa/types';

export interface RitualMentoringSessionsData {
  ritual_mentoring_sessions: Array<{ status: MentoringSessionStatus; count: string }>;
}

export default Vue.extend({
  name: 'm-ritual-mentoring-sessions-widget',
  props: {
    value: { type: Object as PropType<RitualMentoringSessionsData> },
  },
  data: () => ({
    progress: 0,
    total: 0,
  }),
  async mounted() {
    this.value?.ritual_mentoring_sessions?.forEach((row) => {
      if (row.status === MentoringSessionStatus.Progress) {
        this.progress = Number(row.count);
      }
      this.total += Number(row.count);
    });
  },
});
</script>
