<template>
  <div class="d-flex align-center flex-column fill-height">
    <h2 class="text-center">{{ $t('ritual.enrollDialog.title') }}</h2>
    <p class="mt-4">{{ $t('ritual.enrollDialog.description') }}</p>
    <div class="flex-grow-1 d-flex flex-column align-center justify-center">
      <p class="text-center">{{ $t('ritual.enrollDialog.pickRole.title') }}</p>
      <v-btn-toggle v-model="localValue" color="primary">
        <v-btn v-for="(role, index) of availableRoles" :key="`role-${index}`" :value="role" width="128">
          {{ $t(`mentoring.role.${role}`) }}
        </v-btn>
        <v-btn v-if="anyRoleAvaiable" value="any" width="128">
          {{ $t('ritual.enrollDialog.pickRole.any') }}
        </v-btn>
      </v-btn-toggle>
      <p v-if="false" class="text-center my-8 px-14">{{ $t('enrollRitual.hintPageExpiration') }}</p>
    </div>
    <v-row class="justify-end mt-auto fill-width flex-grow-0">
      <v-col cols="6">
        <v-btn :disabled="!localValue" color="button" depressed width="100%" @click="doAction">
          {{ $t('questionnaires.next') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IRitual } from '@mentessa/types';

export default Vue.extend({
  name: 'm-pick-role-form',
  props: {
    ritual: Object as PropType<IRitual>,
    value: { type: String, default: undefined },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value: string) {
        this.$emit('input', value);
      },
    },
    availableRoles() {
      return this.ritual?.attributes.roles?.available ?? ['mentor', 'mentee'];
    },
    anyRoleAvaiable() {
      return this.ritual?.attributes.roles?.any ?? false;
    },
  },
  methods: {
    async doAction() {
      this.$emit('onNext');
    },
  },
});
</script>
