<template>
  <div class="d-flex flex-column">
    <validation-observer v-slot="{ invalid }">
      <v-form>
        <validation-provider v-slot="v" :name="$t('onboarding.members.addMembersViaEmailLabel')" rules="emails">
          <InviteTextareaInput v-model="localMembers" :errors="v.errors" />
        </validation-provider>
        <v-btn :disabled="invalid" block class="mt-4" color="button" depressed height="48" large @click="submitStep">
          {{ $t('onboarding.buttonNext') }}
        </v-btn>
        <v-btn v-if="false" block class="mt-5 mx-0" color="primary" depressed large text @click="doLater">
          {{ $t('onboarding.buttonLater') }}
        </v-btn>
      </v-form>
    </validation-observer>
  </div>
</template>

<script lang="ts">
import InviteTextareaInput from '@/components/MOnboarding/InviteTextareaInput.vue';
import Vue from 'vue';
import { extend, setInteractionMode, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';

setInteractionMode('aggressive');
extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});
extend('emails', {
  message: 'All records should be a valid email',
  validate: (records: string[]) => records.every((record) => email.validate(record)),
});

export default Vue.extend({
  name: 'm-form-invite-step',
  components: {
    InviteTextareaInput,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    value: String,
  },
  computed: {
    localMembers: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    submitStep() {
      this.$emit('onSubmit');
    },
    doLater() {
      this.$emit('input', []);
      this.$emit('onSubmit');
    },
  },
});
</script>
