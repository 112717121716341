<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :value="value"
    sentry-tag="m-ritual-match-action-dialog"
    width="768"
    @input="close"
  >
    <div v-if="loading" class="m-dialog-container--loading d-flex align-center flex-column py-16">
      <m-loader :isLoading="true" style="position: absolute; width: 100%; height: 100%"></m-loader>
    </div>
    <div v-else class="m-dialog-container d-flex align-center flex-column">
      <h2 class="ma-8">{{ title }}</h2>
      <div class="ma-4 text-center" v-html="$marked(content)" />
      <v-btn class="mb-4 mt-auto" color="button" depressed min-width="128" @click="close">
        {{ $t('ritual.matchActionDialog.action') }}
      </v-btn>
    </div>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';
import { UsersState } from '@/store/users';
import { ChangeRitualMatchAcceptStatus } from '@/store/rituals';
import MLoader from '@/components/MLoader.vue';
import { getFirstName } from '@/utils/user';
import { APIAcceptMatchResponse, APIAcceptMatchStatus } from '@mentessa/types';
import { Route } from 'vue-router';

enum DialogState {
  Loading = 'loading',
  Pending = 'pending',
  Accepted = 'accepted',
  Declined = 'declined',
  DeclineAccepted = 'decline_accepted',
  AcceptDeclined = 'accept_declined',
  Error = 'error',
}

enum MatchingAction {
  Accept = 'accept',
  Decline = 'decline',
}

export default Vue.extend({
  name: 'm-ritual-match-action-dialog',
  components: { MLoader },
  props: {
    value: { type: Boolean, default: false },
    to: { type: Object as PropType<Route> },
    ritualId: Number,
    matchId: Number,
    action: String as PropType<MatchingAction>,
  },
  data: () => ({
    state: DialogState.Loading as DialogState,
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    tenantTitle() {
      return this.tenant?.attributes?.theme?.title ?? '';
    },
    firstName() {
      return getFirstName(this.me);
    },
    loading() {
      return this.state === DialogState.Loading;
    },
    title() {
      switch (this.state) {
        case DialogState.Accepted:
          return this.$t('ritual.matchActionDialog.title.accepted');
        case DialogState.Declined:
          return this.$t('ritual.matchActionDialog.title.declined');
        case DialogState.Pending:
        case DialogState.AcceptDeclined:
          return this.$t('ritual.matchActionDialog.title.pending', { name: this.firstName }); // TODO: Use name of match instead of me
        case DialogState.DeclineAccepted:
          return this.$t('ritual.matchActionDialog.title.accepted');
        default:
          return '';
      }
    },
    content(): string {
      switch (this.state) {
        case DialogState.Accepted:
          return this.$t('ritual.matchActionDialog.content.accepted');
        case DialogState.Declined:
          return this.$t('ritual.matchActionDialog.content.declined');
        case DialogState.Pending:
          return this.$t('ritual.matchActionDialog.content.pending');
        case DialogState.DeclineAccepted:
          return this.$t('ritual.matchActionDialog.content.declineAccepted');
        case DialogState.AcceptDeclined:
          return this.$t('ritual.matchActionDialog.content.acceptDeclined');
        default:
          return '';
      }
    },
  },
  watch: {
    async value(value: boolean) {
      if (value) {
        await this.applyMatchingAction();
      }
    },
  },
  async mounted() {
    if (this.value) {
      await this.applyMatchingAction();
    }
  },
  methods: {
    close() {
      if (this.to) {
        this.$router.replace(this.to);
      } else {
        this.$emit('input', false);
      }
    },
    async applyMatchingAction() {
      if (![MatchingAction.Accept, MatchingAction.Decline].includes(this.action)) {
        this.state = Error;
        await this.close();
      }
      if (this.ritualId) {
        try {
          const result = (await this.$store.dispatch(
            new ChangeRitualMatchAcceptStatus(
              { id: this.ritualId },
              { id: this.matchId },
              this.action === MatchingAction.Accept,
            ),
          )) as APIAcceptMatchResponse;

          if (result.success) {
            switch (result.status) {
              case APIAcceptMatchStatus.Accepted:
                this.state = DialogState.Accepted;
                break;
              case APIAcceptMatchStatus.Declined:
                this.state = DialogState.Declined;
                break;
              case APIAcceptMatchStatus.PartiallyAccepted:
                this.state = DialogState.Pending;
                break;
            }
          } else {
            switch (result.status) {
              case APIAcceptMatchStatus.Accepted:
              case APIAcceptMatchStatus.PartiallyAccepted:
                this.state = DialogState.DeclineAccepted;
                break;
              case APIAcceptMatchStatus.Declined:
                this.state = DialogState.AcceptDeclined;
                break;
            }
          }
        } catch (e) {
          this.state = DialogState.Error;
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.m-dialog-container {
  background-color: var(--v-white-base);
  min-height: 320px;

  &--loading {
    position: relative;
  }
}
</style>
