import {
  FeatureConfig,
  ICreateIntegration,
  IntegrationReference,
  ITenant,
  IUser,
  ProductConfig,
  TenantAttributes,
  TenantReference,
  UserReference,
} from '@mentessa/types';
import { Action } from '@/store/types';

export const moduleName = 'tenant';

export interface TenantState {
  tenant?: ITenant;
  tenants: ITenant[];
}

export interface CreateTenantOptions {
  name: string;
  domain: string;
  tagLine: string;
  color: string;
  skipOwner?: boolean;
  communityRoles?: Array<string>;
  programs?: Array<string>;
  marketing?: Record<string, string | Array<string> | number>;
}

export enum TenantActions {
  LoadTenant = '[Tenant] Load Tenant',
  CreateTenant = '[Tenant] Create Tenant',
  InitTenantCreation = '[Tenant] Init Tenant Creation',
  CreateIntegration = '[Tenant] Create Integration',
  UpdateIntegration = '[Tenant] Update Integration',
  DeleteIntegration = '[Tenant] Delete Integration',
  UpdateTenantAttributes = '[Tenant] Update Tenant Attributes',
  UpdateTenantConfig = '[Tenant] Update Tenant Config',
  UpdateTenantImage = '[Tenant] Update Tenant Image',
  UpdateTenantAmbassador = '[Tenant] Update Tenant Ambassador',
  LoadAvailableTenants = '[Tenant] Load Available Tenants',
  InviteMembers = '[Tenant] Invite members',
  FindTags = '[Tenant] Find Tags',
  ChangeEmailRewriteEnabled = '[Tenant] Change Email Rewrite Enabled',
  ChangeEmailRewriteEmail = '[Tenant] Change Email Rewrite Email',
  RecreateSearchIndex = '[Tenant] Recreate Search Index',
  ReindexSearchForAllTenants = '[Tenant] Reindex Search For All Tenants',
  ChangeFeatureConfig = '[Tenant] Change Tenant Feature Config',
  ChangeFeatureEnabledState = '[Tenant] Change Feature Enabled State',
  LoadKeycloakClient = '[Tenant] Load Keycloak Client',
  UpdateKeycloakClient = '[Tenant] Update Keycloak Client',
  CreateKeycloakClient = '[Tenant] Create Keycloak Client',
  ImportMembers = '[Tenant] Import Members',
  UpdateAllowedProviders = '[Tenant] Update Allowed Providers',
  LoadTopSkills = '[Tenant] Load Top Skills',
}

export enum TenantMutations {
  SetTenant = '[Tenant] Set Tenant',
  SetIntegration = '[Tenant] Set Integration',
  UpdateIntegration = '[Tenant] Update Integration',
  RemoveIntegration = '[Tenant] Remove Integration',
  SetTenantImage = '[Tenant] Set Tenant Image',
  SetTenantAttributes = '[Tenant] Set Tenant Attributes',
  SetTenantConfig = '[Tenant] Set Tenant Config',
  SetAvailableTenants = '[Tenant] Set Available Tenants',
  AppendAvailableTenant = '[Tenant] Append Available Tenant',
  SetEmailRewriteEnabled = '[Tenant] Set Email Rewrite Enabled',
  SetEmailRewriteEmail = '[Tenant] Set Email Rewrite Email',
  SetFeatureEnabledState = '[Tenant] Set Feature Enabled State',
}

export class LoadTenant implements Action {
  type = `${moduleName}/${TenantActions.LoadTenant}`;
}

export class CreateTenant implements Action {
  type = `${moduleName}/${TenantActions.CreateTenant}`;

  constructor(private readonly options: CreateTenantOptions) {}
}

export class InitTenantCreation implements Action {
  type = `${moduleName}/${TenantActions.InitTenantCreation}`;
}

export class CreateIntegration implements Action {
  type = `${moduleName}/${TenantActions.CreateIntegration}`;

  constructor(private readonly options: ICreateIntegration) {}
}

export class UpdateIntegration implements Action {
  type = `${moduleName}/${TenantActions.UpdateIntegration}`;

  constructor(
    private readonly integrationRef: IntegrationReference,
    private readonly options: Partial<ICreateIntegration>,
  ) {}
}

export class DeleteIntegration implements Action {
  type = `${moduleName}/${TenantActions.DeleteIntegration}`;

  constructor(private readonly integration: IntegrationReference) {}
}

export class UpdateTenantAttributes implements Action {
  type = `${moduleName}/${TenantActions.UpdateTenantAttributes}`;

  constructor(private readonly attributes: TenantAttributes, private readonly tenant?: ITenant) {}
}

export class UpdateTenantConfig implements Action {
  type = `${moduleName}/${TenantActions.UpdateTenantConfig}`;

  constructor(private readonly config: Partial<ProductConfig>, private readonly tenant?: ITenant) {}
}

export class UpdateTenantImage implements Action {
  type = `${moduleName}/${TenantActions.UpdateTenantImage}`;

  constructor(private readonly blob: Blob, private readonly tenant?: ITenant, private readonly forceDomain?: string) {}
}

export class UpdateTenantAmbassador implements Action {
  type = `${moduleName}/${TenantActions.UpdateTenantAmbassador}`;

  constructor(private readonly ambassadorRef: UserReference, private readonly tenant?: ITenant) {}
}

export class LoadAvailableTenants implements Action {
  type = `${moduleName}/${TenantActions.LoadAvailableTenants}`;
}

export class InviteMembers implements Action {
  type = `${moduleName}/${TenantActions.InviteMembers}`;

  constructor(
    private readonly emails: string[],
    private readonly roles?: string[],
    private readonly tenant?: TenantReference,
    private readonly forceDomain?: string,
  ) {}
}

export class FindTags implements Action {
  type = `${moduleName}/${TenantActions.FindTags}`;

  constructor(private readonly query?: string, private tenant?: ITenant) {}
}

export class ChangeEmailRewriteEnabled implements Action {
  type = `${moduleName}/${TenantActions.ChangeEmailRewriteEnabled}`;

  constructor(private readonly enabled: boolean, private tenant?: ITenant) {}
}

export class ChangeEmailRewriteEmail implements Action {
  type = `${moduleName}/${TenantActions.ChangeEmailRewriteEmail}`;

  constructor(private readonly email: string, private tenant?: ITenant) {}
}

export class RecreateSearchIndex implements Action {
  type = `${moduleName}/${TenantActions.RecreateSearchIndex}`;

  constructor(private tenant?: ITenant) {}
}

export class ReindexSearchForAllTenants implements Action {
  type = `${moduleName}/${TenantActions.ReindexSearchForAllTenants}`;
}

export class ChangeFeatureConfig implements Action {
  type = `${moduleName}/${TenantActions.ChangeFeatureConfig}`;

  constructor(
    private readonly tenantRef: TenantReference,
    private readonly feature: string,
    private readonly config: Partial<FeatureConfig>,
  ) {}
}

export class ChangeFeatureEnabledState implements Action {
  type = `${moduleName}/${TenantActions.ChangeFeatureEnabledState}`;

  constructor(
    private readonly feature: string,
    private readonly state: boolean,
    private readonly tenantRef?: TenantReference,
  ) {}
}

export class EnableFeature extends ChangeFeatureEnabledState {
  constructor(feature: string, tenantRef?: TenantReference) {
    super(feature, true, tenantRef);
  }
}

export class DisableFeature extends ChangeFeatureEnabledState {
  constructor(feature: string, tenantRef?: TenantReference) {
    super(feature, false, tenantRef);
  }
}

export class LoadKeycloakClient implements Action {
  type = `${moduleName}/${TenantActions.LoadKeycloakClient}`;

  constructor(private tenantRef?: TenantReference) {}
}

export class UpdateKeycloakClient implements Action {
  type = `${moduleName}/${TenantActions.UpdateKeycloakClient}`;

  constructor(private tenantRef?: TenantReference) {}
}

export class CreateKeycloakClient implements Action {
  type = `${moduleName}/${TenantActions.CreateKeycloakClient}`;

  constructor(private tenantRef?: TenantReference) {}
}

export class ImportMembers implements Action {
  type = `${moduleName}/${TenantActions.ImportMembers}`;

  constructor(
    private readonly members: Array<Partial<IUser>>,
    private readonly sendInvite = false,
    private readonly tenantRef?: TenantReference,
  ) {}
}

export class UpdateAllowedProviders implements Action {
  type = `${moduleName}/${TenantActions.UpdateAllowedProviders}`;

  constructor(private readonly providers: Array<string> = [], private readonly tenantRef?: TenantReference) {}
}

export class LoadTopSkills implements Action {
  type = `${moduleName}/${TenantActions.LoadTopSkills}`;

  constructor(private readonly tenantRef?: TenantReference) {}
}
