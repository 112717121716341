<template>
  <v-avatar
    :color="avatarColor"
    :size="imageSize"
    class="m-user-avatar"
    :class="{ 'm-user-avatar__empty': isEmptyAvatar }"
  >
    <v-img v-if="image && !image.includes('user-empty')" :src="image" :alt="`${fullName} avatar`" />
    <span v-else class="m-initials" :style="style">{{ initials }}</span>
  </v-avatar>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { getFullName, getInitials } from '@/utils/user';
import { UserEntity } from '@mentessa/types';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'm-user-avatar',
  props: {
    user: Object as PropType<UserEntity> | undefined | null,
    large: { type: Boolean, default: false },
    xLarge: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters('tenant', {
      getAvatarColor: 'getAvatarColor',
    }),
    image() {
      if (this.user?.identity?.attributes?.image) {
        return this.user?.identity?.attributes?.image;
      }
      return require('@/assets/user-empty.png');
    },
    imageSize() {
      if (this.xLarge) return 96;
      if (this.large) return 56;
      return 32;
    },
    style() {
      let initialsFontSize = '10px';
      if (this.large) initialsFontSize = '20px';
      if (this.xLarge) initialsFontSize = '32px';
      return {
        '--initials-font-size': initialsFontSize,
      };
    },
    avatarColor() {
      if (this.user?.id == null || !this.image.includes('user-empty')) return 'var(--v-beige-base)';
      return this.getAvatarColor(this.user);
    },
    isEmptyAvatar() {
      return this.user?.id == null || this.image.includes('user-empty');
    },
    initials() {
      return getInitials(this.user);
    },
    fullName() {
      return getFullName(this.user);
    },
  },
});
</script>

<style scoped lang="scss">
.m-initials {
  font-size: var(--initials-font-size);
  color: var(--v-white-base);
}
</style>
