import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { api } from '@/store/api';
import { ShowErrorNotification } from '@/store/notifications';
import i18n from '@/plugins/i18n';
import { VideosActions, VideosMutations, VideosState } from './types';

export const actions: ActionTree<VideosState, RootState> = {
  async [VideosActions.LoadVideos]({ commit, dispatch }, { page, tags }) {
    try {
      const response = await api.get(`videos`, { params: { page, tags } });
      const videos = response?.data;
      commit(VideosMutations.SetVideos, videos);
    } catch (e) {
      console.error('Error while LoadVideos.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [VideosActions.AddVideo]({ dispatch }, { video }) {
    try {
      const response = await api.post('videos', video);
      return response?.status === 201 ?? false;
    } catch (e) {
      console.error('Error while AddVideo.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [VideosActions.RemoveVideo]({ dispatch, commit }, { video }) {
    try {
      await api.delete(`videos/${video.id}`);
      commit(VideosMutations.RemoveVideo, video);
    } catch (e) {
      console.error('Error while RemoveVideo.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [VideosActions.UpdateVideo]({ dispatch, commit }, { video }) {
    try {
      await api.put(`videos/${video.id}`, video);
      commit(VideosMutations.UpdateVideo, video);
    } catch (e) {
      console.error('Error while UpdateVideo.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
};
