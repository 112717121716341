<template>
  <v-dialog
    :value="value"
    @input="$emit('onCancel')"
    @keydown.enter.exact.prevent="$emit('onCancel')"
    max-width="360"
    class="m-leave-dialog"
  >
    <v-sheet class="pt-10 pb-4 px-6">
      <div>
        <p class="m-leave-dialog__heading">
          {{ $t('profile.leaveDialog.text') }}
        </p>
        <div class="m-leave-dialog__btn-wrap">
          <v-btn color="button" depressed outlined @click="$emit('onDiscard')">
            {{ $t('profile.leaveDialog.cta.discard') }}
          </v-btn>
          <v-btn depressed color="button" @click="$emit('onSave')">{{ $t('profile.leaveDialog.cta.save') }}</v-btn>
        </div>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'm-leave-dialog',
  props: {
    value: Boolean,
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.m-leave-dialog {
  &__heading {
    font-size: 20px;
    text-align: center;
  }

  &__btn-wrap {
    display: flex;
    justify-content: center;
    padding-top: 12px;

    @media #{map-get($display-breakpoints, 'xs-only')} {
      flex-direction: column;
    }
  }
}

:deep(.v-btn) {
  min-width: 132px !important;
  margin: 6px;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-width: 100%;
  }
}
</style>
