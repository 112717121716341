import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { mutations } from './mutations';
import { actions } from './actions';
import { moduleName, NewsState } from './types';
import { getters } from './getters';

export * from './types';

export function newsGetter(getter: string) {
  return `${moduleName}/${getter}`;
}

export const initialState: NewsState = {
  news: {
    items: [],
    meta: {
      currentPage: 0,
      totalPages: 0,
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 0,
    },
  },
};

export const newsModule: Module<NewsState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

export default newsModule;
