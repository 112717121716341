import { RouteName } from '@/router/types';
import { MAppointmentFeedbackDialog } from '@/components/Mentoring/dialogs';
import { AppointmentFeedback } from '@mentessa/types';
import { MFeedbackDialog } from '@/components/Dialogs';
import { MMentoringSession } from '@/components/Mentoring';
import { MMentoringSessions } from '@/views';
import { sessionRedirector } from '@/router/guards';

const sessionChildrenRoutes = [
  {
    path: 'appointments/:appointmentId',
    name: RouteName.MentoringSessionAppointment,
    component: MAppointmentFeedbackDialog,
    beforeEnter: (to, from, next) => {
      if (!Object.values(AppointmentFeedback).includes(to.query.feedback as AppointmentFeedback)) {
        return next({
          name: RouteName.ActiveMentoringSession,
          params: to.params,
          query: { ...to.query, feedback: undefined },
        });
      }
      next();
    },
    props: (route) => ({
      sessionId: route.params['sessionId'] != null ? Number(route.params['sessionId']) : undefined,
      appointmentId: route.params['appointmentId'] != null ? Number(route.params['appointmentId']) : undefined,
      feedback: route.query.feedback,
    }),
  },
  {
    path: 'feedback',
    name: RouteName.MentoringSessionFeedback,
    component: MFeedbackDialog,
    props: (route) => ({
      sessionId: route.params['sessionId'] != null ? Number(route.params['sessionId']) : undefined,
    }),
  },
];

const mentoringRoutes = [
  {
    path: 'active',
    name: RouteName.ActiveMentoring,
    component: MMentoringSessions,
    children: [
      {
        path: ':sessionId',
        name: RouteName.ActiveMentoringSession,
        component: MMentoringSession,
        props: (route) => ({
          sessionId: route.params['sessionId'] != null ? Number(route.params['sessionId']) : undefined,
        }),
        children: sessionChildrenRoutes,
      },
    ],
  },
  {
    path: 'closed',
    name: RouteName.ClosedMentoring,
    component: MMentoringSessions,
    children: [
      {
        path: ':sessionId',
        name: RouteName.ClosedMentoringSession,
        component: MMentoringSession,
        props: (route) => ({
          sessionId: route.params['sessionId'] != null ? Number(route.params['sessionId']) : undefined,
        }),
      },
    ],
  },
  {
    path: ':sessionId',
    name: RouteName.MentoringSession,
    component: null,
    beforeEnter: sessionRedirector,
  },
  {
    path: ':sessionId/feedback',
    redirect: { name: RouteName.MentoringSessionFeedback },
  },
  {
    path: 'appointments/:appointmentId',
    redirect: { name: RouteName.MentoringSessionAppointment },
  },
];

export { mentoringRoutes };

export default mentoringRoutes;
