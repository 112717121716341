<template>
  <v-app class="m-app-container">
    <div v-if="initialized" class="fill-height page-wrap">
      <m-app-bar v-if="$vuetify.breakpoint.mdAndUp" />
      <m-navigation-drawer />
      <v-main class="pb-0 ma-1 ma-sm-4">
        <v-container fluid class="fill-height">
          <div id="router-view" class="d-flex flex-column m-content fill-height fill-width mx-auto">
            <router-view />
          </div>
        </v-container>
        <discord-widget v-if="discordIntegrationEnabled" :serverId="discordServerId" />
      </v-main>
      <div v-if="isRulesRequiredNotAccepted" class="overlay"></div>
      <m-community-rules />
      <m-bottom-navigation />
      <m-footer />
    </div>
    <m-loader v-else :isLoading="true" />
    <m-notification-snackbar />
    <m-notification-dialog />
    <m-user-onboarding-wizard />
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { TenantState } from '@/store/tenant';
import { RootState } from '@/store/types';
import MLoader from '@/components/MLoader';
import { IIntegration, IntegrationType, TenantEntity } from '@mentessa/types';
import MAppBar from '@/components/MAppBar';
import { MNotificationDialog, MNotificationSnackbar } from '@/components/MNotifications';
import { MFooter } from '@/components/MFooter';
import MBottomNavigation from '@/components/MBottomNavigation';
import MNavigationDrawer from '@/components/MNavigationDrawer';
import DiscordWidget from '@/components/DiscordWidget';
import { MCommunityRules } from '@/components/MCommunityRules';
import { UsersState } from './store/users';
import { MUserOnboardingWizard } from '@/components/Dialogs/MUserOnboardingWizard';

export default Vue.extend({
  name: 'm-app',
  components: {
    MUserOnboardingWizard,
    MNavigationDrawer,
    MFooter,
    MNotificationDialog,
    MNotificationSnackbar,
    MAppBar,
    MLoader,
    MBottomNavigation,
    DiscordWidget,
    MCommunityRules,
  },
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    ...mapState<RootState>({
      initialized: (state: RootState) => state.initialized,
    }),
    ...mapState<UsersState>('users', {
      rulesAccepted: (state: UsersState) => state.me?.rulesAccepted ?? false,
      userComplete: (state: UsersState) => state.me?.complete ?? false,
    }),
    ...mapGetters('tenant', {
      isCommunityRulesAvailable: 'isCommunityRulesAvailable',
      isCommunityRulesRequired: 'isCommunityRulesRequired',
    }),
    discordIntegration() {
      return this.tenant.integrations.find((i: IIntegration) => i.type === IntegrationType.Discord);
    },
    discordIntegrationEnabled() {
      return this.discordIntegration?.enabled ?? false;
    },
    discordServerId() {
      return this.discordIntegration?.attributes.discord.serverId;
    },
    isRulesRequiredNotAccepted() {
      return (
        this.isCommunityRulesRequired && this.isCommunityRulesAvailable && !this.rulesAccepted && this.userComplete
      );
    },
  },
  watch: {
    tenant(tenant: TenantEntity) {
      const theme = this.$vuetify.theme.themes.light;
      const colors = tenant.attributes.theme.colors;
      if (colors.primary) {
        theme.primary = colors.primary;
      }
      if (colors.secondary) {
        theme.secondary = colors.secondary;
      }
      if (colors.accent) {
        theme.accent = colors.accent;
      }
      if (colors.button) {
        theme.button = colors.button;
      }

      const title = tenant.attributes.theme.title;
      document.title = title ? title : 'Mentessa';
      if (tenant.attributes.theme.favicon) {
        const favicon = document.getElementById('favicon') as HTMLLinkElement;
        favicon.href = tenant.attributes.theme.favicon;
      }
    },
  },
  mounted() {
    const inviteGuid = localStorage.getItem('invite');

    if (inviteGuid) {
      console.log('Apply invite', inviteGuid);
      localStorage.removeItem('invite');
    }
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.m-app-container {
  background-color: var(--v-background-base);
}

.page-wrap {
  display: flex;
  flex-direction: column;
  color: var(--v-text-base);
  height: 100%;
}

.m-content {
  max-width: 1200px;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    margin-top: 0;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
</style>
