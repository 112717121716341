<template>
  <v-dialog v-model="isDialogOpen" max-width="746px" sentry-tag="new-resource-dialog">
    <v-card :class="{ 'flex-column': this.$vuetify.breakpoint.xsOnly }" class="fill-height pa-6">
      <div class="d-flex justify-end">
        <a sentry-tag="new-resource-dialog.button.close" @click="$emit('input', false)">
          <v-img contain height="24" src="@/assets/close-icon.svg" width="24" />
        </a>
      </div>
      <validation-observer v-slot="{ invalid }" class="m-settings-dialog__form">
        <h1>{{ $t('settings.resources.add') }}</h1>
        <m-text-field
          v-model="resource.title"
          :label="$t('settings.resources.dialogNew.title')"
          sentry-tag="new-resource-dialog.input.title"
          validation-rules="required"
        />
        <m-text-field
          v-model="resource.content"
          :label="$t('settings.resources.dialogNew.url')"
          sentry-tag="new-resource-dialog.input.url"
          validation-rules="required"
        />
        <div class="modal_foot text-center pt-4">
          <v-btn
            :disabled="invalid"
            color="button"
            depressed
            sentry-tag="new-resource-dialog.button.add"
            x-large
            @click="addResource"
          >
            {{ $t('settings.resources.add') }}
          </v-btn>
        </div>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import { MTextField } from '@/components/Inputs';
import { VideoType } from '@mentessa/types';
import { AddResource } from '@/store/resources';

export default Vue.extend({
  name: 'm-new-resource-dialog',
  components: { MTextField, ValidationObserver },
  props: {
    value: { type: Boolean, default: false },
  },
  data: () => ({
    isLoading: false,
    isValid: false,
    resource: {
      title: '',
      content: '',
      attributes: { type: VideoType.YouTube },
    },
  }),
  computed: {
    ...mapGetters('tenant', {
      contentTags: 'contentTags',
    }),
    isDialogOpen: {
      get() {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    async addResource() {
      await this.$store.dispatch(new AddResource(this.resource));
      this.$emit('input', false);
      this.$emit('onPublish', false);
    },
  },
});
</script>

<style lang="scss" scoped>
.m-settings-dialog__form {
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: 20px;
}
</style>
