import { ActionTree } from 'vuex';
import {
  CheckNewMessages,
  LoadMessages,
  MessagesActions,
  MessagesMutations,
  MessagesState,
  ReadWholeRoom,
} from './types';
import { RootState } from '@/store/types';
import { api } from '@/store/api';
import { ShowErrorNotification, ShowInfoNotification } from '@/store/notifications';
import { getIdOrCurrent } from '@/utils/api';
import { RoomEntity } from '@mentessa/types';
import i18n from '@/plugins/i18n';

export const actions: ActionTree<MessagesState, RootState> = {
  async [MessagesActions.LoadRooms]({ commit, dispatch }, { user, page }) {
    try {
      const response = await api.get(`users/${getIdOrCurrent(user)}/rooms?page=${page}`);
      const rooms = response.data?.length ? response.data : [];
      commit(MessagesMutations.SetRooms, rooms);
    } catch (e) {
      console.log('Error while LoadRooms', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [MessagesActions.FindDirectRoom]({ dispatch }, { userId }) {
    try {
      const response = await api.get(`/users/current/rooms?type=direct&members=${userId}`);
      return response.data?.[0];
    } catch (e) {
      console.log('Error while LoadRoomByUser', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [MessagesActions.LoadMessages]({ commit, dispatch }, { room, user, page }) {
    try {
      const response = await api.get(`users/${getIdOrCurrent(user)}/rooms/${room.id}/messages?page=${page}`);
      const messages = response?.data;
      commit(MessagesMutations.SetMessages, messages);
    } catch (e) {
      console.log('Error while LoadMessages', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [MessagesActions.SendMessage]({ commit, dispatch }, { room, text, user }) {
    try {
      const params = { text };
      if (room.id === 0) {
        params['room'] = {
          type: room.type,
          members: room.members.map((rm) => ({ user: { id: rm.user.id } })),
        };
      }
      const response = await api.post(`users/${getIdOrCurrent(user)}/rooms/${room.id}/messages`, params);
      if (room.id === 0) {
        const newRoom: RoomEntity = response?.data;
        newRoom.members = room.members;
        commit(MessagesMutations.SetActiveRoom, newRoom);
        commit(MessagesMutations.ReplaceRoom, { target: room, replacement: newRoom });
        const messages = newRoom['messages'] ?? newRoom['__messages__'] ?? [];
        commit(
          MessagesMutations.SetMessages,
          messages.map((m) => ({ ...m, roomId: newRoom.id })),
        );
      } else {
        const messages = response?.data;
        commit(MessagesMutations.AppendMessage, messages);
      }
      return 201;
    } catch (e) {
      if (e.response?.status === 429) {
        return 429;
      } else {
        console.error('Error while SendMessage', e);
        await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
          root: true,
        });
        return 500;
      }
    }
  },

  async [MessagesActions.StartFetchMessages]({ commit, dispatch }, { interval }) {
    console.log(`Start fetch messages with interval: ${interval}`);
    const timer = setInterval(() => {
      dispatch(new CheckNewMessages(), { root: true });
    }, interval);
    commit(MessagesMutations.SetTimer, timer);
  },

  async [MessagesActions.StopFetchMessages]({ commit }) {
    console.log('Stop fetch messages');
    commit(MessagesMutations.SetTimer, undefined);
  },

  async [MessagesActions.CheckNewMessages]({ state, commit, dispatch }) {
    const time = new Date();
    // console.log(`Check new messages from ${state.lastFetchTime?.toISOString()}`);
    // const response = await api.get('messages/unread', { params: { from: state.lastFetchTime } });
    const response = undefined;
    commit(MessagesMutations.SetLastFetchTime, time);
    const newCount = Number(response?.data);
    if (newCount !== state.unreadMessageCount) {
      const exactlyNewCount = newCount - state.unreadMessageCount;
      commit(MessagesMutations.SetUnreadMessageCount, response?.data ?? 0);
      if (exactlyNewCount > 0) {
        await dispatch(new ShowInfoNotification(`You have new message`), { root: true });
      }
    }
    return newCount;
  },

  async [MessagesActions.SelectRoom]({ commit, dispatch }, { room }) {
    commit(MessagesMutations.SetActiveRoom, room);
    if (room.id !== 0) {
      await dispatch(new LoadMessages(room), { root: true });
      await dispatch(new ReadWholeRoom(room), { root: true });
    } else {
      commit(MessagesMutations.SetMessages, []);
      commit(MessagesMutations.AppendRoom, room);
    }
  },

  async [MessagesActions.ReadWholeRoom](store, { room, user }) {
    if (!room.hasNewMessages) {
      return;
    }
    console.log(`Read Whole Room: ${room.id}`);
    const response = await api.post(`users/${getIdOrCurrent(user)}/rooms/${room.id}/read`);
    if (response.status !== 201) {
      console.log('Fail mark room messages as read');
    }
  },
};
