<template>
  <div class="m-settings-rituals" sentry-tag="settings-rituals">
    <p class="m-settings-description">{{ $t('settings.rituals.description') }}</p>
    <v-tabs background-color="transparent">
      <v-tab :to="activeRoute" sentry-tag="settings-rituals.tab.active">{{ $t('settings.rituals.active') }}</v-tab>
      <v-tab v-if="isDebugAvailable" :to="templatesRoute" sentry-tag="settings-rituals.tab.templates"
        >{{ $t('settings.rituals.templates') }}
      </v-tab>
    </v-tabs>
    <router-view />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { LoadRituals } from '@/store/rituals';
import { mapGetters } from 'vuex';
import { RouteName } from '@/router/types';

export default Vue.extend({
  name: 'm-settings-rituals',
  data: () => ({
    templatesRoute: { name: RouteName.SettingsRitualsTemplates },
    activeRoute: { name: RouteName.SettingsRituals },
  }),
  computed: {
    ...mapGetters('tenant', {
      isDebugAvailable: 'isDebugAvailable',
    }),
  },
  async mounted() {
    await this.$store.dispatch(new LoadRituals(1, { invitations: true }));
  },
});
</script>

<style lang="scss" scoped>
.m-settings-rituals {
  .m-active-tabs {
    background-color: transparent;
  }

  .m-settings-ritual {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 36px;
    background-color: var(--v-white-base);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
  }

  &__table-pagination:deep(.v-pagination__navigation, .v-pagination__item) {
    box-shadow: none;
  }
}
</style>
