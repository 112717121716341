<template>
  <div>
    <m-headline :description="$t('home.description')" :title="$t('home.title')" />

    <m-pending-actions />

    <div class="grid mt-4">
      <m-next-steps-panel v-if="nextStepsEnabled" :draggable="dragAndDropEnabled" @onReady="rebuild" />
      <m-profile-score-panel v-if="profileScoreEnabled" :draggable="dragAndDropEnabled" @onReady="rebuild" />
      <m-user-stats-panel v-if="statsEnabled" :draggable="dragAndDropEnabled" @onReady="rebuild" />
      <m-my-goals-panel v-if="goalsEnabled" :draggable="dragAndDropEnabled" @onReady="rebuild" />
      <m-user-sessions-panel v-if="sessionsEnabled" :draggable="dragAndDropEnabled" @onReady="rebuild" />

      <m-open-journals-panel v-if="journalsEnabled" :draggable="dragAndDropEnabled" @onReady="rebuild" />

      <m-user-badges-panel v-if="badgesEnabled" :draggable="dragAndDropEnabled" @onReady="rebuild" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';
import MHeadline from '@/components/MHeadline';
import Packery from 'packery';
import {
  MMyGoalsPanel,
  MNextStepsPanel,
  MOpenJournalsPanel,
  MProfileScorePanel,
  MUserBadgesPanel,
  MUserSessionsPanel,
  MUserStatsPanel,
} from '@/components/Panels';
import { HomeFeature } from '@mentessa/types';
import Draggabilly from 'draggabilly';
import MPendingActions from '@/components/MPendingActions';

export default Vue.extend({
  name: 'm-home',
  components: {
    MPendingActions,
    MOpenJournalsPanel,
    MUserBadgesPanel,
    MUserSessionsPanel,
    MUserStatsPanel,
    MNextStepsPanel,
    MProfileScorePanel,
    MMyGoalsPanel,
    MHeadline,
  },
  data: () => ({
    packery: undefined as Packery,
  }),
  computed: {
    ...mapGetters('tenant', {
      isMentoringAvailable: 'isMentoringAvailable',
    }),
    ...mapGetters('users', {
      completionPercentage: 'completionPercentage',
    }),
    ...mapGetters('tenant', {
      featureConfig: 'featureConfig',
    }),
    homeFeature(): HomeFeature {
      return this.featureConfig('home');
    },
    badgesEnabled() {
      return this.homeFeature?.badges ?? false;
    },
    sessionsEnabled() {
      // return this.homeFeature?.sessions ?? false;
      return false;
    },
    matchesEnabled() {
      return this.homeFeature?.matches ?? false;
    },
    journalsEnabled() {
      // return this.homeFeature?.journals ?? false;
      return false;
    },
    goalsEnabled() {
      return this.homeFeature?.goals ?? false;
    },
    statsEnabled() {
      return this.homeFeature?.stats ?? false;
    },
    profileScoreEnabled() {
      return this.homeFeature?.profileScore ?? false;
    },
    nextStepsEnabled() {
      return this.homeFeature?.nextSteps ?? false;
    },
    dragAndDropEnabled() {
      return this.homeFeature?.dragAndDrop ?? false;
    },
  },
  async mounted() {
    const packery = new Packery('.grid', {
      itemSelector: '.grid-item',
      gutter: 16,
      percentPosition: true,
    });
    this.packery = packery;

    if (this.dragAndDropEnabled) {
      packery.getItemElements().forEach(function (itemElem) {
        const handle = itemElem.querySelector('.handle');
        const options = {};
        if (handle) {
          options['handle'] = handle;
        }
        packery.bindDraggabillyEvents(new Draggabilly(itemElem, options));
      });
    }
  },
  methods: {
    rebuild() {
      this.$nextTick(() => {
        this.packery.reloadItems();
        this.packery.layout();
      });
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.grid {
  max-width: 100%;
}

.grid-item {
  min-width: 280px;
  width: calc(50% - 8px);

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    width: 100%;
    min-width: unset;
  }
}
</style>
