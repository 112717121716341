import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { mutations } from './mutations';
import { actions } from './actions';
import { MobileState } from './types';

export * from './types';

export const initialState: MobileState = {
  companyCode: undefined,
};

export const mobileModule: Module<MobileState, RootState> = {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
};

export default mobileModule;
