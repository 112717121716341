<template>
  <v-dialog
    v-model="isDialogOpen"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    sentry-tag="m-cancel-appointment-dialog"
    width="unset"
  >
    <v-card class="pa-4">
      <div class="d-flex justify-end">
        <a sentry-tag="m-cancel-appointment-dialog.button.close" @click="$emit('input', false)">
          <v-img contain height="24" src="@/assets/close-icon.svg" width="24" />
        </a>
      </div>
      <v-card-title class="pt-0">
        {{ $t('mentoring.appointments.dialogs.cancel.title') }}
      </v-card-title>
      <v-card-text>
        <p>
          {{ $t('mentoring.appointments.dialogs.cancel.description') }}
          <span class="primary--text font-weight-bold">{{ targetUserFullName() }}</span>
        </p>
        <div class="d-flex">
          <v-img class="flex-grow-0 mr-2" contain height="18" src="@/assets/calendar.svg" width="18" />
          <p>{{ date }}</p>
        </div>
        <v-divider class="mb-3"></v-divider>
        <p class="text--primary">{{ $t('mentoring.appointments.dialogs.cancel.reason') }}</p>
        <m-text-area v-model="reasonText" :disabled="loading" :placeholder="$t('inputs.textPlaceholder')"></m-text-area>
      </v-card-text>
      <v-card-actions class="justify-end px-6">
        <v-btn :loading="loading" block class="white--text" color="var(--v-primary-base)" @click="cancelSession">
          {{ $t('mentoring.appointments.dialogs.cancel.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { AppointmentStatus, IAppointment, IMentoringSession, ITenant, UserEntity } from '@mentessa/types';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';
import { MTextArea } from '@/components/Inputs';
import { UpdateMentoringSessionAppointment } from '@/store/mentoring';
import { getFullName } from '@/utils/user';
import { UpdateAppointment } from '@/store/matching';

export default Vue.extend({
  name: 'm-cancel-appointment-dialog',
  components: { MTextArea },
  props: {
    value: { type: Boolean, default: false },
    session: { type: Object as PropType<IMentoringSession> },
    appointment: { type: Object as PropType<IAppointment> },
    targetUser: { type: Object as PropType<UserEntity> },
    date: { type: String, default: '' },
  },
  data: () => ({
    loading: false,
    reasonText: '',
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState): ITenant => state.tenant,
    }),
    isDialogOpen: {
      get() {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
  },
  mounted() {
    this.reasonText = '';
  },
  methods: {
    async cancelSession() {
      this.loading = true;

      try {
        const data = { status: AppointmentStatus.Canceled, attributes: { reason: this.reasonText } };
        let success = false;
        if (!this.session) {
          success = await this.$store.dispatch(new UpdateAppointment(this.appointment, data));
        } else {
          success = await this.$store.dispatch(
            new UpdateMentoringSessionAppointment(this.session, this.appointment, data),
          );
        }
        if (success) {
          this.isDialogOpen = false;
        }
      } finally {
        this.loading = false;
      }
    },
    targetUserFullName() {
      return getFullName(this.targetUser);
    },
  },
});
</script>

<style lang="scss" scoped>
.cancel-session-container {
  width: 400px;
}
</style>
