<template>
  <div v-if="value" class="m-chat-wrap">
    <div
      v-if="room"
      :class="{ 'justify-space-between': $vuetify.breakpoint.smAndUp }"
      class="m-inbox-chat__profile-bar d-flex px-2 py-4"
    >
      <button v-if="!$vuetify.breakpoint.smAndUp" sentry-tag="inbox-chat.button.left" @click="$emit('input', false)">
        <v-icon>mdi-arrow-left</v-icon>
      </button>
      <m-user-preview :user="titleUser" bold class="font-size-16 ml-2" />
    </div>
    <div v-if="me" ref="messages-container" class="m-messages-wrap">
      <div v-if="groupedMessages.length > 0">
        <div v-for="(day, indexGroup) in groupedMessages" :key="`chat-group${indexGroup}`">
          <div class="time-separator mt-4">
            <v-divider />
            <span>{{ formatTimeSeparator(day.date) }}</span>
          </div>

          <div
            v-for="(message, messageIndex) in day.messages"
            :key="messageIndex"
            :class="{ 'mt-4 message-with-avatar': message.showUser }"
            class="messages-container__message d-flex"
          >
            <m-user-avatar v-if="message.showUser" :user="message.user" class="mt-1 ml-4" />

            <div :class="{ 'm-show-user-message': message.showUser }" class="message_text">
              <div v-if="message.showUser" class="pb-3 font-size-16 font-weight-medium">
                <span>
                  {{ getFullName(message.user) }}
                  <small class="ml-2">
                    {{ time(message.createdAt) }}
                  </small>
                </span>
              </div>
              <component
                :is="getMessageComponent(message)"
                ref="message"
                class="m-inbox-chat__message"
                v-bind="getMessageProps(message, room)"
              />
              <div class="message_text__date">
                <small>
                  {{ time(message.createdAt) }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <m-empty-community v-if="isCommunityEmpty && isInvitesAvailable" />
        <m-empty-state
          v-else-if="isRoomsLoading === false && room == null"
          :cta="$t('chat.empty.cta')"
          :description="$t('chat.empty.description')"
          :image="require('@/assets/empty/message.svg')"
          :title="$t('chat.empty.title')"
          @onButtonClick="$emit('onButtonStartChatting')"
        />
      </div>
    </div>
    <v-alert v-model="showAbuseMessage" class="keep-new-line mx-4 mb-16 mb-sm-0" type="error">
      <span>{{ $t('chat.too_many_dialogs') }}</span>
    </v-alert>
    <div v-if="room && !showAbuseMessage" ref="message_field" class="m-inbox-chat__input py-4 px-4">
      <m-user-avatar :user="me" class="mr-3" />
      <v-textarea
        v-model="message"
        :disabled="isLoading"
        :loading="isLoading"
        :placeholder="$t('chat.input.placeholder')"
        append-icon="mdi-send"
        auto-grow
        dense
        hide-details
        no-resize
        outlined
        rows="1"
        sentry-tag="inbox-chat.input.textarea"
        @keydown.enter.exact.prevent="sendMessage"
        @click:append="sendMessage"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { MAisAutocompleteUsers, MAisWrapper } from '@/components/MAIS';
import { MAppointmentMessage, MTextMessage } from '@/components/MInbox';
import { MUserPreview } from '@/components/MUserPreview';
import MUserAvatar from '@/components/MUserAvatar';
import { MessagesState, SendMessage } from '@/store/messages';
import { IMessage, MessageAppointmentAttributes, MessageEntity } from '@mentessa/types';
import { UsersState } from '@/store/users';
import { getFullName } from '@/utils/user';
import { getDirectMember } from '@/components/MInbox/utils';
import { dateWithDayOfWeek } from '@/utils/date';
import { isSameDay, isToday, isYesterday, parseISO } from 'date-fns';
import MEmptyState from '@/components/MEmptyState';
import MEmptyCommunity from '@/components/MEmptyCommunity';

const messagesTypesDict = {
  text: MTextMessage,
  appointment: MAppointmentMessage,
};

export default Vue.extend({
  name: 'm-inbox-chat',
  components: {
    MEmptyCommunity,
    MEmptyState,
    MUserAvatar,
    MUserPreview,
    MAisWrapper,
    MAppointmentMessage,
    MAisAutocompleteUsers,
  },
  props: {
    value: { type: Boolean, default: true },
    isRoomsLoading: { type: Boolean, default: false },
  },
  data: () => ({
    message: '',
    isLoading: false,
    showAbuseMessage: false,
  }),
  computed: {
    ...mapGetters('tenant', {
      isInvitesAvailable: 'isInvitesAvailable',
      isCommunityEmpty: 'isCommunityEmpty',
    }),
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    ...mapState<MessagesState>('messages', {
      messagesPage: (state: MessagesState) => state.messages,
      room: (state: MessagesState) => state.activeRoom,
    }),
    ...mapGetters('ui', {
      formatInUserTimeZone: 'formatInUserTimeZone',
    }),
    messages() {
      // ToDo: Fix it. After short amount of time after room was changed messages and room are not sync
      return this.messagesPage?.items?.filter((message) => message.roomId === this.room.id) ?? [];
    },
    titleUser() {
      return getDirectMember(this.room, this.me);
    },
    groupedMessages() {
      const messages: Array<IMessage> = this.messagesPage.items;
      const groupByDay: Array<{ date: Date; messages: Array<IMessage & { showUser: boolean }> }> = [];
      for (let i = messages.length - 1; i >= 0; --i) {
        const message = messages[i];
        const messageDate = parseISO(message.createdAt.toString());
        if (groupByDay.length === 0 || !isSameDay(groupByDay[groupByDay.length - 1].date, messageDate)) {
          groupByDay.push({
            date: messageDate,
            messages: [{ ...message, createdAt: messageDate, showUser: true, user: this.messageUser(message) }],
          });
        } else {
          const group = groupByDay[groupByDay.length - 1];
          const showUser =
            group.messages.length === 0 || group.messages[group.messages.length - 1].userId !== message.userId;

          group.messages.push({ ...message, createdAt: messageDate, showUser, user: this.messageUser(message) });
        }
      }

      return groupByDay;
    },
  },
  watch: {
    room() {
      this.showAbuseMessage = false;
    },
  },
  methods: {
    scrollChatDown() {
      const messages = this.$refs['message'];
      const messageContainer = this.$refs['messages-container'];
      if (messages.length) {
        this.$vuetify.goTo(messages[messages.length - 1].$el, { container: messageContainer });
      }
    },
    formatTimeSeparator(date: Date) {
      if (isToday(date)) {
        return 'Today';
      } else if (isYesterday(date)) {
        return 'Yesterday';
      } else {
        return dateWithDayOfWeek(date);
      }
    },
    messageUser(message: MessageEntity) {
      if (message.userId === this.me.id) return this.me;
      return this.room.members.find((rm) => rm.user?.id === message.userId)?.user;
    },
    isAppointment(message: MessageEntity) {
      return (message.attributes as MessageAppointmentAttributes).appointment != null;
    },
    async sendMessage() {
      if (!this.message) {
        return;
      }
      try {
        this.isLoading = true;
        const status = await this.$store.dispatch(new SendMessage(this.room, this.message));
        if (status === 201) {
          this.message = '';
          this.scrollChatDown();
        } else if (status === 429) {
          this.showAbuseMessage = true;
        }
      } finally {
        this.isLoading = false;
      }
    },
    getMessageComponent(message) {
      let messageType = 'text';
      if (this.isAppointment(message)) messageType = 'appointment';
      return messagesTypesDict[messageType];
    },
    getMessageProps(message, room) {
      return {
        message,
        room,
        members: room.members,
      };
    },
    getFullName,
    getDirectMember,
    time(date: Date | string) {
      return this.formatInUserTimeZone(date, 'H:mm');
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.m-chat-wrap {
  width: 100%;
  //height: calc(100vh - 94px);
  //max-height: calc(100vh - 94px);
  display: flex;
  flex-direction: column;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    //height: calc(100vh - 88px);
    //max-height: calc(100vh - 88px);
  }
}

.m-messages-wrap {
  overflow-y: scroll;
  padding: 0 0 24px;
  flex-grow: 1;
  flex-shrink: 1;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    margin-bottom: 52px;
  }
}

.messages-container__message:hover {
  background-color: var(--v-background-base);
}

.messages-container__message {
  &:not(:hover) .message_text__date {
    display: none;
  }
}

.m-inbox-chat {
  &__input {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;

    @media #{map-get($display-breakpoints, 'sm-only')} {
      position: relative;
      width: 100%;
      bottom: 0;
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
      position: absolute;
      width: 100%;
      bottom: 0;
    }
  }

  &__profile-bar {
    background-color: var(--v-white-base);
    border-bottom: 1px solid var(--v-lightgray-base);
    height: 60px;
  }

  &__message {
    overflow-wrap: anywhere;

    :deep(p) {
      margin-bottom: 0;
    }

    :deep(.v-divider) {
      border-color: #e8e8e8;
    }
  }

  &__title {
    line-height: 48px;
  }
}

.time-separator {
  position: relative;
  top: 12px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: var(--v-gray-base);

  .v-divider {
    border-color: #e8e8e8;
  }

  span {
    position: relative;
    top: -12px;
    background-color: var(--v-white-base);
    padding: 0 8px;
  }
}

.message_text {
  padding: 8px 16px;
  position: relative;

  small {
    color: var(--v-gray-base);
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    width: 100%;
  }

  &:not(.m-show-user-message) {
    margin-left: 44px;
  }

  &.m-show-user-message &__date {
    display: none;
  }

  &__date {
    position: absolute;
    left: -32px;
    top: 12px;
    color: var(--v-gray-base);
  }
}
</style>
