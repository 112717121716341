<template>
  <div class="box-sample" :class="classes" sentry-tag="dashboard-widget-single">
    <h3>{{ value }}</h3>
    <p>{{ title }}</p>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'm-dashboard-widget-single',
  props: {
    value: [String, Number],
    title: [String, Object /*as PropType<TranslateResult>*/],
    nested: { type: Boolean, default: false },
  },
  computed: {
    classes() {
      return {
        'grid-item': !this.nested,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.box-sample {
  height: 140px;
  padding: 24px;
  border: 1px solid var(--v-beige-base);
  border-radius: 16px;
  background: var(--v-white-base);
  text-align: left;
}
</style>
