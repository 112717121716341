<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.xsOnly"
    width="1056"
    sentry-tag="user-details-dialog"
    @click:outside="close"
    :value="value"
    @keydown.esc="close"
  >
    <v-card class="m-user-details">
      <div class="d-flex justify-end m-icon-container">
        <a @click="close" sentry-tag="user-details-content.button.close">
          <v-img contain height="24" src="@/assets/close-icon.svg" width="24" />
        </a>
      </div>
      <m-user-details-content
        :user="user"
        @onUserChanged="loadUser"
        @onMatchStateChanged="$emit('onMatchStateChanged', $event)"
      />
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import MUserDetailsContent from '@/components/Dialogs/MUserDetailsDialog/MUserDetailsContent';
import { LoadUserDetails } from '@/store/users';

export default Vue.extend({
  name: 'm-user-details-simple-dialog',
  components: {
    MUserDetailsContent,
  },
  props: {
    value: { type: Boolean, default: false },
    userId: { type: Number },
  },
  data: () => ({
    user: undefined,
  }),
  watch: {
    async userId() {
      await this.loadUser();
    },
    async value() {
      await this.loadUser();
    },
  },
  async mounted() {
    await this.loadUser();
  },
  methods: {
    async loadUser() {
      if (!this.value || !this.userId) {
        return;
      }
      const user = await this.$store.dispatch(new LoadUserDetails(this.userId));
      if (user) {
        this.user = user;
      } else {
        this.close();
      }
    },
    close() {
      this.$emit('input', false);
    },
  },
});
</script>

<style lang="scss" scoped>
.m-icon-container {
  padding: 8px;
  background-color: var(--v-white-base);
}
</style>
