<template>
  <v-navigation-drawer
    v-model="visible"
    :expand-on-hover="mini"
    :mini-variant="mini"
    :permanent="$vuetify.breakpoint.mdAndUp"
    app
    class="m-navigation-drawer"
    overflow
  >
    <v-list>
      <v-list-item class="pa-1">
        <v-img :src="logo" contain height="100%" max-height="128" width="64" />
      </v-list-item>
      <v-list-item v-if="isHomeAvailable" :to="homeRoute">
        <v-list-item-icon>
          <div class="icon-dashboard" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('appBar.home') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <template v-if="!isHomeAvailable">
        <v-list-item :class="{ 'parent-active': showProfileSections }" :to="personalRoute">
          <v-list-item-icon>
            <div class="icon-profile" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('appBar.profile') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-expand-transition>
          <v-list v-if="showProfileSections && !mini">
            <v-list-item :to="personalRoute" dense>
              <v-list-item-content>
                <v-list-item-title>{{ $t('profile.blocks.personal') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="skillsRoute" dense>
              <v-list-item-content>
                <v-list-item-title>{{ $t('profile.blocks.skills') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="isMentoringAvailable" :to="availabilityRoute" dense>
              <v-list-item-content>
                <v-list-item-title>{{ $t('profile.blocks.availability') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-expand-transition>
      </template>
      <v-list-item :to="communityRoute">
        <v-list-item-icon>
          <div class="icon-community" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('appBar.community') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="isRitualsShopAvailable" :to="ritualsRoute">
        <v-list-item-icon>
          <div class="icon-rituals" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('appBar.rituals') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mx-2" />
      <v-list-item v-if="hasMentoringSessions" :class="{ 'parent-active': showMentoringSections }" :to="mentoringRoute">
        <v-list-item-icon>
          <div class="icon-journals" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('appBar.mentoring') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-expand-transition>
        <v-list v-if="hasMentoringSessions('finished') && showMentoringSections && !mini">
          <v-list-item :to="activeMentoringRoute" dense>
            <v-list-item-content>
              <v-list-item-title>{{ $t('mentoring.blocks.active') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="closedMentoringRoute" dense>
            <v-list-item-content>
              <v-list-item-title>{{ $t('mentoring.blocks.closed') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-expand-transition>
      <v-list-item :to="matchesRoute">
        <v-list-item-icon>
          <div class="icon-matching" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('appBar.myMentoring') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="appointmentsRoute">
        <v-list-item-icon>
          <div class="icon-appointments" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('appBar.appointments') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="mx-2" />
      <v-list-item v-if="isResourcesAvailable" :to="resourcesRoute">
        <v-list-item-icon>
          <div class="icon-videos" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('appBar.resources') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="isContentAvailable" :to="contentRoute">
        <v-list-item-icon>
          <div class="icon-news" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('appBar.content') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <template v-if="isDashboardAvailable || isSettingsAvailable">
        <v-divider class="mx-2" />
        <v-list-item v-if="isDashboardAvailable" :to="dashboardRoute">
          <v-list-item-icon>
            <div class="icon-dashboard" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('appBar.dashboard') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-if="$vuetify.breakpoint.smAndDown">
        <v-divider class="mx-2" />
        <v-list-item v-if="isCommunityRulesAvailable" @click.stop="openCommunityRules">
          <v-list-item-icon>
            <div class="icon-rules" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('appBar.communityRules') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="personalRoute" class="mt-4">
          <m-user-preview :user="me" />
        </v-list-item>
        <v-list-item v-if="canSelectLocale" class="px-0">
          <v-list-item-content class="pt-2 pb-0">
            <m-select-ui-locale
              :label="$t('profile.personal.lang')"
              :outlined="false"
              :value="lang"
              class="pa-0"
              flat
              sentry-tag="profile.input.lang"
              solo
              @input="setLocale"
            />
          </v-list-item-content>
        </v-list-item>
        <template v-if="isImpersonated">
          <v-divider />
          <v-list-item class="pa-2 d-flex justify-center link-logout" @click.stop="cancelImpersonate">
            {{ $t('appBar.cancelImpersonate') }}
          </v-list-item>
          <v-divider />
        </template>
        <v-list-item @click.stop="logout">
          <v-list-item-icon>
            <div class="icon-logout" />
          </v-list-item-icon>
          <v-list-item-content>
            {{ $t('appBar.logout') }}
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { RouteName } from '@/router/types';
import { UpdateUserLang, UsersState } from '@/store/users';
import { MUserPreview } from '@/components/MUserPreview';
import MSelectUiLocale from '@/components/Inputs/MSelectUILocale';
import { ChangeCommunityRulesVisible, ChangeNavigationVisible, UIState } from '@/store/ui';
import { TenantState } from '@/store/tenant';

export default Vue.extend({
  name: 'm-navigation-drawer',
  components: {
    MUserPreview,
    MSelectUiLocale,
  },
  watch: {
    me() {
      this.lang = this.me?.attributes.lang ?? this.defaultLocale;
    },
  },
  mounted() {
    this.lang = this.me?.attributes.lang ?? this.defaultLocale;
  },
  data: () => ({
    inboxRoute: { name: RouteName.Inbox },
    dashboardRoute: { name: RouteName.Dashboard },
    resourcesRoute: { name: RouteName.Resources },
    ritualsRoute: { name: RouteName.Rituals },
    mentoringRoute: { name: RouteName.Mentoring },
    activeMentoringRoute: { name: RouteName.ActiveMentoring },
    closedMentoringRoute: { name: RouteName.ClosedMentoring },
    appointmentsRoute: { name: RouteName.Appointments },
    personalRoute: { name: RouteName.ProfilePersonal },
    homeRoute: { name: RouteName.Home },
    skillsRoute: { name: RouteName.ProfileSkills },
    availabilityRoute: { name: RouteName.ProfileAvailability },
    contentRoute: { name: RouteName.Content },
    communityRoute: { name: RouteName.Community },
    matchesRoute: { name: RouteName.Matches },
    lang: undefined,
  }),
  computed: {
    ...mapGetters('tenant', {
      isDashboardAvailable: 'isDashboardAvailable',
      isProfileAvailable: 'isProfileAvailable',
      isMessagesAvailable: 'isMessagesAvailable',
      isCommunityRulesAvailable: 'isCommunityRulesAvailable',
      isSettingsAvailable: 'isSettingsAvailable',
      isResourcesAvailable: 'isResourcesAvailable',
      isContentAvailable: 'isContentAvailable',
      isRitualsShopAvailable: 'isRitualsShopAvailable',
      isMentoringAvailable: 'isMentoringAvailable',
      isBookingAvailable: 'isBookingAvailable',
      availableLocales: 'availableLocales',
      isHomeAvailable: 'isHomeAvailable',
      logoutUrl: 'logoutUrl',
    }),
    ...mapGetters('mentoring', {
      hasMentoringSessions: 'hasMentoringSessions',
    }),
    ...mapState<TenantState>('tenant', {
      logo: (state: TenantState) => state.tenant?.attributes?.theme?.image,
    }),
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    ...mapState<UIState>('ui', {
      navigationVisible: (state: UIState) => state.navigationVisible,
    }),
    visible: {
      get() {
        return this.navigationVisible;
      },
      set(value: boolean) {
        this.$store.dispatch(new ChangeNavigationVisible(value));
      },
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.smOnly;
    },
    canSelectLocale() {
      return this.availableLocales.length > 1;
    },
    mini() {
      return this.$vuetify.breakpoint.md;
    },
    isImpersonated() {
      return this.$impersonate?.isActive() ?? false;
    },
    showProfileSections() {
      return [
        RouteName.Profile,
        RouteName.ProfilePersonal,
        RouteName.ProfileSkills,
        RouteName.ProfileAvailability,
      ].includes(this.$route.name);
    },
    showMentoringSections() {
      return [
        RouteName.Mentoring,
        RouteName.ActiveMentoring,
        RouteName.ClosedMentoring,
        RouteName.ActiveMentoringSession,
        RouteName.ClosedMentoringSession,
      ].includes(this.$route.name);
    },
  },
  methods: {
    async logout() {
      await this.$keycloak.keycloak.logout({ redirectUri: this.logoutUrl });
    },
    async setLocale(locale: string) {
      await this.$store.dispatch(new UpdateUserLang(locale));
    },
    cancelImpersonate() {
      this.$impersonate?.clear();
    },
    openCommunityRules() {
      this.$store.dispatch(new ChangeCommunityRulesVisible(true));
      this.$store.dispatch(new ChangeNavigationVisible(false));
    },
  },
});
</script>

<style lang="scss" scoped>
.m-navigation-drawer {
  height: 100% !important;

  .v-list-item__icon {
    margin-top: 14px;
    margin-bottom: 14px;
    margin-right: 12px;
  }

  .v-list-item__title {
    font-size: 16px;
    color: var(--v-gray-base);
  }

  .v-list .v-list {
    padding: 0;
    margin-left: 16px;

    .v-list-item {
      border-left: 2px solid var(--v-primary-base);

      .v-list-item__title {
        padding-left: 18px;
        font-weight: 400;
      }
    }
  }
}

[class^='icon-'] {
  width: 24px;
  height: 24px;
  background-color: var(--v-gray-base); /* defines the background color of the image */
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center;
}

:deep(.v-list-item--active),
.parent-active {
  color: var(--v-white-base);

  [class^='icon-'] {
    background-color: var(--v-primary-base);
  }

  .v-list-item__title {
    color: var(--v-primary-base);
    font-weight: 500;
  }
}
</style>
