<template>
  <div class="d-flex flex-column gap-6 py-4">
    <div v-for="(ritual, index) in rituals" :key="index">
      <m-ritual :value="ritual" @ritual-deleted="fetchRituals" />
    </div>
    <m-empty-ritual v-if="loading === false" />
    <m-add-ritual-dialog v-model="createRitualDialogOpened" @ritual-created="fetchRituals" />
  </div>
</template>

<script lang="ts">
import { mapGetters, mapState } from 'vuex';
import Vue from 'vue';
import { getFullName } from '@/utils/user';
import { MatchingState } from '@/store/matching';
import { LoadRituals, RitualsState } from '@/store/rituals';
import { MRitual } from '@/components/MRitual';
import { MAddRitualDialog } from '@/components/Dialogs';
import MEmptyRitual from '@/components/MEmptyRitual';

export default Vue.extend({
  name: 'm-rituals-list',
  components: { MEmptyRitual, MRitual, MAddRitualDialog },
  data: () => ({
    newRitualDialog: false,
    showOnlyActive: false,
    createRitualDialogOpened: false,
    loading: false,
  }),
  computed: {
    ...mapState<MatchingState>('rituals', {
      ritualsPage: (state: RitualsState) => {
        return state.rituals;
      },
    }),
    ...mapGetters('tenant', {
      isDebugAvailable: 'isDebugAvailable',
    }),
    rituals() {
      return this.ritualsPage.items;
    },
    pagination() {
      return this.ritualsPage.meta;
    },
  },
  methods: {
    getFullName: getFullName,
    async fetchRituals() {
      try {
        this.loading = true;
        await this.$store.dispatch(new LoadRituals(1, { invitations: true }));
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    await this.fetchRituals();
  },
});
</script>

<style lang="scss" scoped>
.m-settings-rituals {
  .m-active-tabs {
    background-color: transparent;
  }

  .m-settings-ritual {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 36px;
    background-color: var(--v-white-base);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
  }

  &__table-pagination:deep(.v-pagination__navigation, .v-pagination__item) {
    box-shadow: none;
  }
}
</style>
