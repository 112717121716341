<template>
  <v-dialog :value="value" max-width="574" sentry-tag="add-ritual-dialog" @input="$emit('input', $event)">
    <div class="m-close-icon-container">
      <a class="m-close-icon" sentry-tag="add-ritual-dialog.button.close" @click="$emit('input', false)">
        <v-img contain height="24" src="@/assets/close-icon.svg" width="24" />
      </a>
    </div>
    <v-sheet class="pa-14">
      <h2 class="text-center">{{ $t('settings.rituals.addDialog.add') }}</h2>
      <validation-observer v-slot="{ invalid }">
        <m-text-field
          v-model="ritual.attributes.title"
          :label="'Title'"
          class="mt-4"
          sentry-tag="add-ritual-dialog.input.title"
          validation-rules="required"
        />
        <m-select class="mt-4" v-model="ritual.type" :items="ritualTypes" :label="'Type'" validation-rules="required" />
        <v-row class="justify-center mt-12">
          <v-btn
            :disabled="invalid"
            :loading="loading"
            color="button"
            depressed
            large
            sentry-tag="add-ritual-dialog.button.add"
            @click="addRitual"
          >
            {{ $t('settings.rituals.addDialog.add') }}
          </v-btn>
        </v-row>
      </validation-observer>
    </v-sheet>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import { ValidationObserver } from 'vee-validate';
import { MSelect, MTextField } from '@/components/Inputs';
import { CreateRitual } from '@/store/rituals';
import { RitualState, RitualType } from '@mentessa/types';

const defaultHappyHourAttributes = {
  schedule: {
    type: 'periodic',
    options: {
      periodic: {
        hour: 16,
        weekday: 'fri',
        frequency: 'weekly',
      },
    },
  },
};
const defaultSkillsMatchingAttributes = {
  schedule: {
    type: 'once',
    options: {
      once: {
        date: '2022-09-07T11:00:00.000Z',
      },
    },
  },
};
export default Vue.extend({
  name: 'm-add-ritual-dialog',
  components: {
    ValidationObserver,
    MTextField,
    MSelect,
  },
  props: {
    value: Boolean,
  },
  data: () => ({
    ritual: {
      type: '',
      enabled: false,
      status: RitualState.Disabled,
      attributes: {
        title: '',
      },
    },
    loading: false,
    ritualTypes: Object.values(RitualType),
  }),
  computed: {},
  watch: {
    value(value: boolean) {
      if (value) {
        this.ritual = {
          type: '',
          enabled: false,
          status: RitualState.Disabled,
          attributes: {
            title: '',
          },
        };
      }
    },
  },
  methods: {
    async addRitual() {
      this.loading = true;
      try {
        this.ritual.attributes =
          this.ritual.type === RitualType.HappyHour
            ? { ...this.ritual.attributes, ...defaultHappyHourAttributes }
            : this.ritual.type === RitualType.SkillsMatching
            ? { ...this.ritual.attributes, ...defaultSkillsMatchingAttributes }
            : { ...this.ritual.attributes };
        await this.$store.dispatch(new CreateRitual(this.ritual));
      } finally {
        this.loading = false;
      }
      this.$emit('input', false);
      this.$emit('ritual-created');
    },
  },
});
</script>

<style lang="scss" scoped>
.close-icon {
  position: absolute;
  top: 18px;
  right: 18px;
}

.m-close-icon-container {
  display: flex;
  justify-content: flex-end;
}

.m-close-icon {
  position: absolute;
  margin: 18px;
}
</style>
