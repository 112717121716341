import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { moduleName, VideosState } from './types';

export * from './types';

export function videosGetter(getter: string) {
  return `${moduleName}/${getter}`;
}

export const initialState: VideosState = {
  videos: {
    items: [],
    meta: {
      currentPage: 0,
      totalPages: 0,
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 0,
    },
  },
};

export const videosModule: Module<VideosState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

export default videosModule;
