<template>
  <div>
    <div v-if="isContentAvailable || loading" class="m-ritual-enroll-list-container">
      <v-divider />
      <v-progress-linear :active="loading" absolute color="primary" indeterminate></v-progress-linear>
      <v-simple-table class="pt-4 m-enroll-list-table">
        <tbody>
          <tr v-for="(member, index) in members" :key="`settings-invites-${index}`">
            <td class="m-checkbox-column justify-center">
              <v-checkbox
                v-if="member.accepted === null"
                :input-value="selected.includes(member.id)"
                @change="toggleSelected(member.id, $event)"
              />
            </td>
            <td v-if="isSkillsMatching" class="text-center">
              <v-chip color="primary">{{ memberRole(member) }}</v-chip>
            </td>
            <td class="d-flex align-center">
              <div class="font-weight-medium">
                <m-user-preview :user="member.user" class="font-weight-bold text--text" />
              </div>
            </td>
            <td class="text-center font-weight-medium text--text text--lighten-4 m-text">
              {{ member.user.attributes?.jobTitle }}
            </td>
            <td class="text-center font-weight-medium text--text text--lighten-4">
              {{ formatDate(member.createdAt) }}
            </td>
            <td class="text-end">
              <div v-if="member.accepted === true" class="d-flex align-center justify-end">
                <v-icon class="m-accepted">mdi-check</v-icon>
                <span class="m-accepted font-weight-medium">{{ $t('settings.ritualEnroll.accepted') }}</span>
              </div>
              <div v-else-if="member.accepted === false" class="m-declined d-flex align-center justify-end">
                <v-icon class="m-declined">mdi-close</v-icon>
                <span class="m-declined font-weight-medium">{{ $t('settings.ritualEnroll.declined') }}</span>
              </div>
              <div v-else class="d-flex justify-end align-center">
                <a class="mr-4 primary--text" @click="declineMember(member)">
                  {{ $t('settings.ritualEnroll.decline') }}
                </a>
                <v-btn color="button" outlined small @click="acceptMember(member)">
                  {{ $t('settings.ritualEnroll.accept') }}
                </v-btn>
              </div>
            </td>
            <td class="text-center">
              <v-menu bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item class="error--text" link @click="exclude(member)">
                    <v-list-item-title>{{ $t('settings.ritualEnroll.exclude') }}</v-list-item-title>
                  </v-list-item>

                  <template v-if="isDebugAvailable">
                    <v-divider />
                    <v-list-item link @click="toggleAccepted(member)">
                      <v-list-item-title>Toggle Accepted</v-list-item-title>
                    </v-list-item>
                  </template>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-divider v-if="selected.length > 0 || isAllOperationsAvailable || isPaginationAvailable" />
      <v-row class="ma-0">
        <div v-if="selected.length > 0" class="d-flex">
          <div class="text--text text--lighten-4 font-weight-medium mr-2">
            {{ $t('settings.ritualEnroll.selected') }}:
          </div>
          <a class="mr-2 font-weight-medium" @click="acceptSelectedMembers">
            {{ $t('settings.ritualEnroll.accept') }}
          </a>
          <a class="font-weight-medium" @click="declineSelectedMembers">
            {{ $t('settings.ritualEnroll.decline') }}
          </a>
        </div>
        <div v-if="isAllOperationsAvailable" class="d-flex m-align-right">
          <a class="mr-2 font-weight-medium" @click="acceptAllMembers">
            {{ $t('settings.ritualEnroll.acceptAll') }}
          </a>
          <a class="font-weight-medium" @click="declineAllMembers">
            {{ $t('settings.ritualEnroll.declineAll') }}
          </a>
        </div>
      </v-row>

      <v-pagination
        v-if="isPaginationAvailable"
        v-model="page"
        :disabled="loading"
        :length="meta.totalPages"
        class="m-settings-invitations__table-pagination"
        sentry-tag="settings-invitations.pagination"
      />
    </div>
    <div v-else>
      <v-divider />
      <p class="mt-4 text-center">{{ $t('settings.ritualEnroll.noMembers') }}</p>
    </div>
    <div class="d-flex flex-row align-center mt-4" v-if="isDebugAvailable">
      <m-text-field
        class="ml-auto mr-4"
        v-model="demoUsersCount"
        type="number"
        :label="$t('settings.rituals.ritualEdit.enrollDemoUsers')"
      />
      <v-btn color="button" depressed @click="enrollDemoUsers"> Enroll</v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import {
  AcceptAllRitualUsers,
  AcceptRitualUser,
  AcceptRitualUsers,
  DeclineAllRitualUsers,
  DeclineRitualUser,
  DeclineRitualUsers,
  EnrollDemoUsersToRitual,
  LoadRitualMembers,
  RemoveFromRitual,
} from '@/store/rituals';
import MUserPreview from '@/components/MUserPreview';
import { formatRelative } from 'date-fns';
import { IRitual, IRitualUser, RitualType } from '@mentessa/types';
import { mapGetters } from 'vuex';
import { MTextField } from '@/components/Inputs';

export default Vue.extend({
  name: 'm-ritual-enroll-list',
  components: {
    MUserPreview,
    MTextField,
  },
  props: {
    ritual: Object as PropType<IRitual>,
  },
  data: () => ({
    members: [],
    meta: {},
    page: 1,
    loading: false,
    selected: [],
    demoUsersCount: 0,
  }),
  computed: {
    ...mapGetters('tenant', {
      isDebugAvailable: 'isDebugAvailable',
    }),
    isAllOperationsAvailable() {
      return this.ritual?.type !== RitualType.HappyHour && this.members?.length > 0;
    },
    isPaginationAvailable() {
      return this.meta?.totalPages > 1;
    },
    isContentAvailable() {
      return this.members?.length > 0;
    },
    isSkillsMatching() {
      return this.ritual?.type === RitualType.SkillsMatching;
    },
  },
  watch: {
    async page() {
      await this.fetchData(this.page);
    },
  },
  async mounted() {
    await this.fetchData(this.page);
  },
  methods: {
    memberRole(member: IRitualUser) {
      const roles = member?.user?.attributes?.roles ?? [];
      if (roles.includes('Mentor')) {
        return this.$t('mentoring.role.mentor');
      } else if (roles.includes('Mentee')) {
        return this.$t('mentoring.role.mentee');
      }
      return '';
    },
    formatDate(date) {
      return formatRelative(new Date(date), new Date());
    },
    async enrollDemoUsers() {
      if (this.demoUsersCount > 0) {
        await this.$store.dispatch(new EnrollDemoUsersToRitual({ id: this.ritual?.id }, this.demoUsersCount));
        await this.fetchData(this.page);
      }
    },
    async fetchData(page: number) {
      this.loading = true;
      try {
        const res = await this.$store.dispatch(new LoadRitualMembers(this.ritual, page));
        this.meta = res.meta;
        this.members = res.items;
      } finally {
        this.loading = false;
      }
    },
    toggleSelected(id: number, isSelected: boolean) {
      if (isSelected) {
        if (!this.selected.includes(id)) this.selected.push(id);
      } else {
        const index = this.selected.findIndex((i) => i === id);
        if (index !== -1) {
          this.selected.splice(index, 1);
        }
      }
    },
    updateAccepted(memberIds: Array<number>, status: boolean, all = false) {
      this.members.forEach((member) => {
        if (all || memberIds.includes(member.id)) member.accepted = status;
      });
    },
    async acceptMember(member: IRitualUser) {
      try {
        this.loading = true;
        if (await this.$store.dispatch(new AcceptRitualUser(this.ritual, member))) {
          this.updateAccepted([member.id], true);
          this.toggleSelected(member.id, false);
        }
      } finally {
        this.loading = false;
      }
    },
    async declineMember(member: IRitualUser) {
      try {
        this.loading = true;
        if (await this.$store.dispatch(new DeclineRitualUser(this.ritual, member))) {
          this.updateAccepted([member.id], false);
          this.toggleSelected(member.id, false);
        }
      } finally {
        this.loading = false;
      }
    },
    async acceptSelectedMembers() {
      try {
        this.loading = true;
        if (await this.$store.dispatch(new AcceptRitualUsers(this.ritual, this.selected))) {
          this.updateAccepted(this.selected, true);
          this.selected = [];
        }
      } finally {
        this.loading = false;
      }
    },
    async declineSelectedMembers() {
      try {
        this.loading = true;
        if (await this.$store.dispatch(new DeclineRitualUsers(this.ritual, this.selected))) {
          this.updateAccepted(this.selected, false);
          this.selected = [];
        }
      } finally {
        this.loading = false;
      }
    },
    async acceptAllMembers() {
      try {
        this.loading = true;
        if (await this.$store.dispatch(new AcceptAllRitualUsers(this.ritual))) {
          this.updateAccepted(null, true, true);
          this.selected = [];
        }
      } finally {
        this.loading = false;
      }
    },
    async declineAllMembers() {
      try {
        this.loading = true;
        if (await this.$store.dispatch(new DeclineAllRitualUsers(this.ritual))) {
          this.updateAccepted(null, false, true);
          this.selected = [];
        }
      } finally {
        this.loading = false;
      }
    },
    async toggleAccepted(member: IRitualUser) {
      if (member.accepted) {
        await this.declineMember(member);
      } else {
        await this.acceptMember(member);
      }
    },
    async exclude(member: IRitualUser) {
      try {
        this.loading = true;
        await this.$store.dispatch(new RemoveFromRitual(this.ritual, member));
        const memberId = this.members.findIndex((m) => m.id === member.id);
        if (memberId !== -1) {
          this.members.splice(memberId, 1);
        }
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
:deep(.v-input--selection-controls) {
  margin-top: 0;
  padding-top: 0;
}

:deep(.m-checkbox-column) {
  width: fit-content;
  padding: 0;
  max-width: 48px;
}

.m-enroll-list-table {
  position: relative;

  td {
    border-bottom: none !important;
  }
}

.m-text {
  max-width: 270px;
  text-overflow: ellipsis;
}

.m-accepted {
  color: var(--v-success-base);
}

.m-declined {
  color: var(--v-error-base);
}

.m-align-right {
  margin-left: auto;
}

.m-ritual-enroll-list-container {
  position: relative;
}
</style>
