import { MutationTree } from 'vuex';
import { StatsMutations, StatsPeriodOptions, StatsState } from './types';

export const mutations: MutationTree<StatsState> = {
  [StatsMutations.SetStats](state: StatsState, stats) {
    state.stats = stats;
  },
  [StatsMutations.SetPeriod](state: StatsState, options: StatsPeriodOptions) {
    state.period = options;
  },
};
