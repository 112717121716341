<template>
  <m-video
    :focused-video="focusedVideo"
    :video="video"
    style="max-width: 400px; max-height: 280px"
    @onFocus="focusVideo = true"
  />
</template>

<script lang="ts">
import Vue from 'vue';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';
import { IVideo, VideoType, WelcomeVideoConfig } from '@mentessa/types';
import { MVideo } from '@/components/MVideo';

export default Vue.extend({
  name: 'm-onboarding-video-form',
  components: {
    MVideo,
  },
  data: () => ({
    focusVideo: false,
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      welcomeVideo: (state: TenantState): WelcomeVideoConfig =>
        state.tenant?.attributes.welcomeVideo ?? {
          enabled: true,
          link: 'https://youtu.be/Gq2Z9PlXCl0',
          type: VideoType.YouTube,
        },
    }),
    video(): IVideo {
      return {
        id: 0,
        type: this.welcomeVideo.type ?? VideoType.Raw,
        url: this.welcomeVideo.link,
        attributes: { preview: this.welcomeVideo.preview, tags: [] },
      };
    },
    focusedVideo() {
      return this.focusVideo ? this.video : undefined;
    },
    heading() {
      return this.$t('welcomeVideo.heading', { tenant: this.tenantName });
    },
  },
  async mounted() {
    this.focusVideo = false;
  },
});
</script>
