<template>
  <div>
    <m-settings-panel description="" title="Notifications">
      <div class="d-flex flex-column gap-4">
        <m-text-field v-model="notificationBroadcast.title" label="Title" />
        <m-text-field v-model="notificationBroadcast.text" label="Text" />
        <v-alert v-if="canSendNotificationBroadcast" type="error">
          This action will send a notification to <strong>ALL</strong> users on the <strong>PRODUCTION</strong>
          environment
        </v-alert>
        <v-btn
          class="ml-auto"
          color="button"
          @click="sendBroadcastNotification"
          :disabled="!canSendNotificationBroadcast"
          :loading="loading"
          >Send to All Users
        </v-btn>
      </div>
    </m-settings-panel>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { MTextField } from '@/components/Inputs';
import MSettingsPanel from '@/components/Settings/MSettingsPanel';
import { SendBroadcastPushNotification } from '@/store/mobile';

export default Vue.extend({
  name: 'm-mobile-notifications',
  components: {
    MSettingsPanel,
    MTextField,
  },
  data: () => ({
    loading: false,
    notificationBroadcast: {
      title: undefined as string,
      text: undefined as string,
    },
  }),
  computed: {
    ...mapGetters('tenant', {
      isDebugAvailable: 'isDebugAvailable',
    }),
    canSendNotificationBroadcast() {
      return this.notificationBroadcast.title;
    },
  },
  methods: {
    async sendBroadcastNotification() {
      this.loading = true;
      try {
        await this.$store.dispatch(new SendBroadcastPushNotification({ content: this.notificationBroadcast }));
        this.notificationBroadcast = { title: undefined, text: undefined };
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
//
</style>
