import { UIMutations, UIState } from './types';
import { MutationTree } from 'vuex';
import { I18nContent } from '@mentessa/types';
import i18n from '@/plugins/i18n';
import deepmerge from 'deepmerge';
import { Locale } from 'date-fns';

export const mutations: MutationTree<UIState> = {
  [UIMutations.SetTranslations](state: UIState, options: { lang: string; content: I18nContent; dateLocale: Locale }) {
    i18n.setLocaleMessage(options.lang, deepmerge(i18n.messages[options.lang], options.content));
    state.translations.lang = options.lang;
    state.translations.dateLocale = options.dateLocale;
  },
  [UIMutations.SetNavigationVisible](state: UIState, visible: boolean) {
    state.navigationVisible = visible;
  },
  [UIMutations.SetCommunityRulesVisible](state: UIState, visible: boolean) {
    state.communityRulesVisible = visible;
  },
  [UIMutations.SetDialogBlockedState](state: UIState, blocked: boolean) {
    state.dialogsBlocked = blocked;
  },
};
