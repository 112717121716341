import { IUser, IUserIdentity, UserEntity } from '@mentessa/types';
import i18n from '@/plugins/i18n';

export function getSpecialCaseName(user: IUser): string {
  if (user == null) {
    return i18n.t('user.deleted').toString();
  }
  if (!user.identity?.attributes?.firstName) {
    return user.identity?.attributes?.email ?? user.id.toString();
  }
}

export function getFullName(user: IUser): string {
  return (
    getSpecialCaseName(user) ?? `${user.identity.attributes.firstName ?? ''} ${user.identity.attributes.lastName ?? ''}`
  );
}

export function getFirstName(user: IUser): string {
  return getSpecialCaseName(user) ?? user.identity.attributes.firstName ?? '';
}

export function getInitials(user: IUser): string {
  if (user?.identity?.attributes?.firstName == null) return '';
  return `${user?.identity.attributes.firstName[0] ?? ''}${user.identity.attributes.lastName?.[0] ?? ''}`;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function makeUserEntityFromAIS(searchItem): UserEntity {
  return {
    id: searchItem.userId,
    tenant: {
      id: searchItem.tenantId,
    },
    attributes: {
      jobTitle: searchItem.jobTitle,
      location: searchItem.location,
      // isMentor: searchItem.badges?.includes('mentor') ?? false,
      mentoring: {
        available: searchItem.badges?.includes('available') ?? false,
      },
    },
    identity: {
      attributes: {
        firstName: searchItem.firstName,
        lastName: searchItem.lastName,
        email: searchItem.email,
        image: searchItem.image,
      },
    } as IUserIdentity,
    expertiseTags: searchItem.eTags.map((tag) => ({ name: tag })),
    interestTags: searchItem.iTags.map((tag) => ({ name: tag })),
    isFavorite: searchItem.isFavorite ?? false,
  };
}
