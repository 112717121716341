<template>
  <v-row class="my-0 ml-0 mr-1 py-1 align-center" :class="{ 'mr-10': deleteConfirmation }">
    <m-text-field
      v-model="localValue"
      autofocus
      class="flex-grow-1 mr-2"
      :background-color="deleteConfirmation ? 'var(--v-error-lighten4)' : 'var(--v-white-base)'"
      :disabled="deleteConfirmation"
      dense
      :prepend-inner-icon="prependIcon"
      @keydown.enter.exact.prevent="$emit('onSave', localValue)"
      @keydown.esc.exact.prevent="$emit('onCancel')"
    />
    <div v-if="deleteConfirmation">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="$emit('onDelete', localValue)">
            <v-icon color="error">mdi-check</v-icon>
          </v-btn>
        </template>
        <span>{{ goal ? $t('mentoring.goals.deleteGoalHint') : $t('mentoring.goals.deleteHint') }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="$emit('onDeleteCancel')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('mentoring.goals.cancelHint') }}</span>
      </v-tooltip>
    </div>
    <div v-else>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" :disabled="localValue === ''" icon @click="$emit('onSave', localValue)">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('mentoring.goals.saveHint') }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="$emit('onCancel')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('mentoring.goals.cancelHint') }}</span>
      </v-tooltip>
    </div>
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import MTextField from '@/components/Inputs/MTextField/MTextField.vue';

export default Vue.extend({
  name: 'm-text-field-edit',
  components: { MTextField },
  props: {
    value: [String, Number],
    goal: Boolean,
    deleteConfirmation: Boolean,
  },
  data: () => ({
    localValue: undefined,
  }),
  computed: {
    prependIcon() {
      return this.deleteConfirmation ? 'mdi-trash-can-outline' : '';
    },
  },
  watch: {
    value(value) {
      this.localValue = value;
    },
  },
  mounted() {
    this.localValue = this.value;
  },
});
</script>
