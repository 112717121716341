<template>
  <div>
    <m-text-area v-model="localTenant.attributes" label="Attributes" />
    <v-btn class="ma-4" sentry-tag="settings-debug.button.saveTenantAttributes" @click="saveTenantAttributes">
      Save attributes
    </v-btn>
    <m-text-area v-model="localTenant.config" label="Config" />
    <v-btn class="ma-4" sentry-tag="settings-debug.button.saveTenantConfig" @click="saveTenantConfig">
      Save config
    </v-btn>
  </div>
</template>

<script lang="ts">
import { TenantState, UpdateTenantAttributes, UpdateTenantConfig } from '@/store/tenant';
import MTextArea from '@/components/Inputs/MTextArea/MTextArea.vue';
import { mapState } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
  name: 'm-settings-debug-edit-tenant',
  components: { MTextArea },
  data: () => ({
    localTenant: {
      attributes: undefined,
      config: undefined,
    },
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => {
        return state.tenant;
      },
    }),
  },
  methods: {
    async saveTenantAttributes() {
      await this.$store.dispatch(new UpdateTenantAttributes(JSON.parse(this.localTenant.attributes)));
    },
    async saveTenantConfig() {
      await this.$store.dispatch(new UpdateTenantConfig(JSON.parse(this.localTenant.config)));
    },
  },
  watch: {
    'tenant.attributes'(value) {
      this.localTenant.attributes = JSON.stringify(value, undefined, 4);
    },
    'tenant.config'(value) {
      this.localTenant.config = JSON.stringify(value, undefined, 4);
    },
  },
  async mounted() {
    this.localTenant.attributes = JSON.stringify(this.tenant.attributes, undefined, 4);
    this.localTenant.config = JSON.stringify(this.tenant.config, undefined, 4);
  },
});
</script>
