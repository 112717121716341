<template>
  <div class="d-flex flex-column gap-4">
    <m-search-field v-model="query" :loading="loading" />
    <v-data-table
      :headers="headers"
      :items="users"
      :loading="loading"
      :options.sync="options"
      hide-default-footer
      show-expand
      single-expand
      @item-expanded="loadDetails"
    >
      <template v-slot:item.id="{ item }">
        {{ item.id }}
      </template>
      <template v-slot:item.tenant="{ item }">
        <m-tenant-preview v-if="item.tenant" :tenant="item.tenant" />
      </template>

      <template v-slot:item.user="{ item }">
        <m-user-preview :user="item" job location />
      </template>
      <template v-slot:item.completed="{ item }">
        <v-chip v-if="item.completed" color="success">Onboarded</v-chip>
        <v-chip v-else color="lightgray">Pending</v-chip>
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>

      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length" class="pa-4">
          <m-panel title="Notifications">
            <div class="d-flex flex-column gap-4">
              <m-text-field v-model="notificationSingle.title" label="Title" />
              <m-text-field v-model="notificationSingle.text" label="Text" />
              <v-btn
                class="ml-auto"
                color="button"
                @click="sendNotification(item)"
                :disabled="!canSendNotificationSingle"
              >
                Send
              </v-btn>
            </div>
          </m-panel>
          <m-panel title="Manage" class="mt-4">
            <div class="d-flex flex-wrap gap-4">
              <v-btn color="button" @click="calcAnalytics(item)" :loading="loading"> Recalculate analytics</v-btn>
              <v-btn color="button" @click="calcSkills(item)" :loading="loading"> Recalculate skills</v-btn>
              <v-btn color="button" @click="resetChallenges(item)" :loading="loading"> Reset Challenges</v-btn>
            </div>
          </m-panel>
        </td>
      </template>
      <template v-slot:no-data>
        <p class="mt-4 text-center">No mobile users assigned to this company</p>
      </template>
    </v-data-table>
    <v-row class="justify-end mr-4 mt-2"> Total: {{ totalUsers }}</v-row>
    <v-row dense>
      <v-pagination
        v-model="currentPage"
        :disabled="loading"
        :length="totalPages"
        class="m-settings-members__table-pagination pa-6 ml-auto"
        sentry-tag="settings-members.pagination"
      />
    </v-row>
    <div class="d-flex flex-column mr-auto gap-2">
      <v-checkbox v-model="enableBulkRecalculation" label="Enable bulk actions" />
      <v-alert v-if="enableBulkRecalculation" type="error"
        >It will take a lot of time (5-10 minutes). Do not close or refresh the page
      </v-alert>
      <v-btn
        color="error"
        class="mr-auto"
        @click="calcSkillsForAll"
        :loading="loading"
        :disabled="!enableBulkRecalculation"
      >
        Rerun challenges analyze for all users
      </v-btn>
      <v-btn
        color="error"
        class="mr-auto"
        @click="calcSkillsForAll({ skipAnalyze: true })"
        :loading="loading"
        :disabled="!enableBulkRecalculation"
      >
        Reload skills for all users
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { MSearchField, MTextField } from '@/components/Inputs';
import {
  LoadMobileUsers,
  RecalculateAnalytics,
  RecalculateSkills,
  RecalculateSkillsForAll,
  ResetChallenges,
  SendBroadcastPushNotification,
  SendPushNotification,
} from '@/store/mobile';
import { MUserPreview } from '@/components/MUserPreview';
import { MTenantPreview } from '@/components/MTenantPreview';
import MPanel from '@/components/MPanel/MPanel.vue';

export default Vue.extend({
  name: 'm-mobile-users',
  components: {
    MPanel,
    MTenantPreview,
    MUserPreview,
    MSearchField,
    MTextField,
  },
  data: () => ({
    loading: false,
    localCompanyCode: { code: '' } as { id: number; code: string },
    query: undefined as string,
    options: undefined,
    usersPage: undefined,
    notificationSingle: {
      title: undefined as string,
      text: undefined as string,
    },
    notificationBroadcast: {
      title: undefined as string,
      text: undefined as string,
    },
    enableBulkRecalculation: false as boolean,
  }),
  computed: {
    ...mapGetters('tenant', {
      isDebugAvailable: 'isDebugAvailable',
    }),
    ...mapGetters('ui', {
      formatInUserTimeZone: 'formatInUserTimeZone',
    }),
    currentPage: {
      get() {
        return this.usersPage?.meta?.currentPage ?? 1;
      },
      async set(value: number) {
        await this.loadUsers(value);
      },
    },

    totalPages() {
      return this.usersPage?.meta?.totalPages ?? 1;
    },

    totalUsers() {
      return this.usersPage?.meta?.totalItems ?? 0;
    },
    headers() {
      const headers = [];

      if (this.isDebugAvailable) {
        headers.push({ text: 'Id', value: 'id', sortable: false });
      }

      if (window.location.host.split('.')[0] === 'mobile') {
        headers.push({ text: 'Tenant', value: 'tenant', sortable: true });
      }

      headers.push(
        { text: 'User', value: 'user', sortable: false },
        { text: 'Status', value: 'completed', sortable: true, align: 'center' },
        { text: this.$t('settings.members.table.createdAt'), value: 'createdAt', sortable: true },
      );

      headers.push({ text: '', value: 'data-table-expand' });

      return headers;
    },
    users() {
      const result = this.usersPage?.items ?? [];
      return result.map((mu) => ({ ...mu, attributes: { jobTitle: mu.meta?.jobTitle, location: mu.meta?.location } }));
    },

    canSendNotificationSingle() {
      return this.notificationSingle.title;
    },
    canSendNotificationBroadcast() {
      return this.notificationBroadcast.title;
    },
  },
  watch: {
    async query() {
      await this.loadUsers();
    },
  },
  async mounted() {
    this.enableBulkRecalculation = false;
    await this.loadUsers();
  },
  methods: {
    async loadUsers(page = 1) {
      try {
        const { sortBy, sortDesc } = this.options;
        this.loading = true;
        const orderBy = sortBy?.length > 0 ? (sortDesc[0] ? 'DESC' : 'ASC') : undefined;

        this.usersPage = await this.$store.dispatch(
          new LoadMobileUsers({
            page,
            query: this.query,
            orderBy: sortBy[0],
            orderDir: orderBy,
          }),
        );
      } finally {
        this.loading = false;
      }
    },
    // eslint-disable-next-line
    async loadDetails({ item, value }: { item: any; value: boolean }) {
      if (!value) {
        return;
      }
      this.notificationSingle = { title: undefined, text: undefined };
      // TBD
    },
    formatDate(date: string) {
      return this.formatInUserTimeZone(date, 'dd.MM.yyyy');
    },
    async sendNotification(user) {
      await this.$store.dispatch(new SendPushNotification({ content: this.notificationSingle, users: [user] }));
      this.notificationSingle = { title: undefined, text: undefined };
    },
    async sendBroadcastNotification() {
      await this.$store.dispatch(new SendBroadcastPushNotification({ content: this.notificationBroadcast }));
      this.notificationBroadcast = { title: undefined, text: undefined };
    },

    async calcAnalytics(user) {
      this.loading = true;
      try {
        await this.$store.dispatch(new RecalculateAnalytics(user));
      } finally {
        this.loading = false;
      }
    },
    async calcSkills(user) {
      this.loading = true;
      try {
        await this.$store.dispatch(new RecalculateSkills(user));
      } finally {
        this.loading = false;
      }
    },
    async resetChallenges(user) {
      this.loading = true;
      try {
        await this.$store.dispatch(new ResetChallenges(user));
      } finally {
        this.loading = false;
      }
    },
    async calcSkillsForAll(options: { skipAnalyze: boolean }) {
      this.loading = true;
      try {
        await this.$store.dispatch(new RecalculateSkillsForAll(options));
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
//
</style>
