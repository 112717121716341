export function getMimeType(file: ArrayBufferLike, fallback = null): string {
  const byteArray = new Uint8Array(file).subarray(0, 4);
  let header = '';
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16);
  }
  switch (header) {
    case '89504e47':
      return 'image/png';
    case '47494638':
      return 'image/gif';
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
      return 'image/jpeg';
    default:
      return fallback;
  }
}

export function canvasToBlob(canvas: HTMLCanvasElement, type: string): Promise<Blob> {
  return new Promise(function (resolve) {
    canvas.toBlob(resolve, type);
  });
}

export function scaleCanvasSize(
  srcCanvas: HTMLCanvasElement,
  dstCanvas: HTMLCanvasElement,
  maxWidth: number,
  maxHeight: number,
): void {
  const ratio = srcCanvas.width / srcCanvas.height;
  if (ratio > 1) {
    dstCanvas.width = Math.min(srcCanvas.width, maxWidth);
    dstCanvas.height = dstCanvas.width / ratio;
  } else {
    dstCanvas.height = Math.min(srcCanvas.height, maxHeight);
    dstCanvas.width = dstCanvas.height * ratio;
  }
}
