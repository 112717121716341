<template>
  <v-navigation-drawer
    v-model="localValue"
    :mobile-breakpoint="$vuetify.breakpoint.thresholds.md - 8"
    :touchless="!value"
    class="m-filters-drawer"
    fixed
    hide-overlay
    right
    width="320"
  >
    <div class="filters-sidebar">
      <div class="d-flex justify-space-between align-center">
        <h2 class="mb-0">{{ $t('filterSidebar.title') }}</h2>
        <a sentry-tag="stream-filters.button.back" @click.prevent="$emit('input', false)">
          <v-icon>mdi-window-close</v-icon>
        </a>
      </div>
      <div class="search-panel__filters">
        <component
          :is="filter.component"
          v-for="(filter, index) of filters"
          :key="`filter-${index}`"
          ref="aisFilter"
          :filter="filter"
        />
      </div>
      <ais-clear-refinements>
        <template v-slot="{ canRefine, refine, createURL }">
          <div>
            <v-btn
              v-if="canRefine"
              :href="createURL()"
              color="button"
              depressed
              sentry-tag="stream-filters.button.clearRefinements"
              width="100%"
              @click.prevent="refine"
            >
              {{ $t('filterSidebar.clearFilters') }}
            </v-btn>
          </div>
        </template>
      </ais-clear-refinements>
    </div>
  </v-navigation-drawer>
</template>

<script lang="ts">
import Vue from 'vue';
import { MAisSelectRefinement, MAisToggleRefinement } from '@/components/MAIS';
import { mapGetters, mapState } from 'vuex';
import { TenantState } from '@/store/tenant';

export default Vue.extend({
  name: 'm-stream-filters',
  props: {
    value: { type: Boolean, default: false },
  },
  data: () => ({
    standardFilters: [
      // { name: 'favorite', attribute: 'isFavorite', operator: 'or', component: MAisToggleRefinement },
      { name: 'available', attribute: 'isAvailable', operator: 'or', component: MAisToggleRefinement, enabled: true },
      { name: 'location', attribute: 'location', operator: 'or', component: MAisSelectRefinement, enabled: true },
      { name: 'company', attribute: 'company', operator: 'or', component: MAisSelectRefinement, enabled: false },
      { name: 'jobTitle', attribute: 'jobTitle', operator: 'or', component: MAisSelectRefinement, enabled: false },
      { name: 'languages', attribute: 'languages', operator: 'or', component: MAisSelectRefinement, enabled: true },
      { name: 'expertise', attribute: 'eTags', operator: 'or', component: MAisSelectRefinement, enabled: true },
      { name: 'interest', attribute: 'iTags', operator: 'or', component: MAisSelectRefinement, enabled: true },
      {
        name: 'roles',
        attribute: 'roles',
        operator: 'or',
        component: MAisSelectRefinement,
        enabled: true,
        i18nPath: 'user.roles',
      },
    ],
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    ...mapGetters('tenant', {
      isFilterEnabled: 'isFilterEnabled',
    }),
    filters() {
      const customFilters = [];
      this.tenant?.attributes.customFields?.forEach((field) => {
        if (field.showInFilters) {
          customFilters.push({
            name: field.name,
            attribute: field.name,
            operator: 'or',
            component: MAisSelectRefinement,
            i18nPath: `profile.custom.${field.name}.items`,
            enabled: true,
          });
        }
      });

      this.standardFilters.forEach((filter) => {
        filter.enabled = this.isFilterEnabled(filter.name, filter.enabled);
      });

      return [...this.standardFilters, ...customFilters].filter((filter) => filter.enabled);
    },
    localValue: {
      get() {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
  },
});
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';

.m-filters-drawer {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    z-index: 0;
    padding-top: 60px;
  }
}

.filters-sidebar {
  padding: 24px 32px;
  z-index: 9;
  overflow-y: auto;
}
</style>
