<template>
  <v-card v-if="user" class="user-profile d-flex flex-column" sentry-tag="user-details-content">
    <div class="details-header" @wheel.prevent>
      <div class="header-container">
        <v-avatar
          v-if="user.identity.attributes.image"
          :size="$vuetify.breakpoint.xsOnly ? 140 : 160"
          class="user-profile__user-image"
        >
          <v-img :alt="user.identity.attributes.firstName" :src="user.identity.attributes.image" />
        </v-avatar>

        <div>
          <h3 class="mt-3 mb-1">
            {{ fullName }}
          </h3>

          <p v-if="user.attributes.jobTitle" class="user-profile__typography">{{ user.attributes.jobTitle }}</p>
          <p v-if="user.attributes.company" class="user-profile__typography m-company">
            {{ user.attributes.company }}
          </p>
          <p v-if="user.attributes.location" class="user-profile__typography">
            <v-icon> mdi-map-marker-outline</v-icon>
            {{ user.attributes.location }}
          </p>
        </div>
      </div>
      <div v-if="!isMe && !showMatchRequest && $vuetify.breakpoint.smAndUp" class="user-profile__button-wrap gap-4">
        <v-icon v-if="false" class="star-icon mb-0 mr-6" @click="toggleFavorite">
          {{ isFavorite ? 'mdi-star' : 'mdi-star-outline' }}
        </v-icon>
        <v-btn
          :width="$vuetify.breakpoint.xsOnly ? 140 : 160"
          class="outlined-light"
          color="button"
          depressed
          outlined
          sentry-tag="user-details-content.button.openChat"
          @click="handleChatOpen"
        >
          {{ $t('userProfileModal.btnMSG') }}
        </v-btn>
        <v-btn
          v-if="isBookingAvailable"
          :width="$vuetify.breakpoint.xsOnly ? 140 : 160"
          class="outlined-light"
          color="button"
          depressed
          outlined
          @click="tab = 'appointment'"
        >
          {{ $t('userProfileModal.bookBtn') }}
        </v-btn>
        <v-btn
          v-if="isSelfMatchingAvailable"
          :width="$vuetify.breakpoint.xsOnly ? 140 : 160"
          class="outlined-light"
          color="primary"
          depressed
          @click="tab = 'match'"
        >
          {{ $t('userProfileModal.requestMatch') }}
        </v-btn>
      </div>
    </div>
    <v-alert v-if="pendingRequest" border="left" class="mx-2 mx-sm-10 mt-4" color="primary" colored-border outlined>
      <v-row class="justify-center align-center flex-column flex-sm-row">
        <v-col>
          <div class="d-flex flex-column">
            <div>
              <v-chip>
                <m-ritual-preview v-if="matchRitual" :ritual="matchRitual" />
                <span v-else> {{ $t(`settings.matching.source.${pendingRequest.source}`) }}</span>
              </v-chip>
            </div>
            <span class="mt-2 ml-4">
              {{ $t('userProfileModal.pending_match.title', { name: firstName }) }}
            </span>
          </div>
        </v-col>
        <v-col class="flex-grow-0">
          <div class="d-flex flex-row flex-sm-column gap-2">
            <v-btn class="ml-auto" color="button" outlined width="120" @click="declineMatch"
              >{{ $t('userProfileModal.pending_match.decline') }}
            </v-btn>
            <v-btn color="button" width="120" @click="acceptMatch"
              >{{ $t('userProfileModal.pending_match.accept') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-alert>
    <div class="user-profile__tabs" @wheel.prevent>
      <v-tabs v-model="tab" color="primary" show-arrows>
        <v-tab href="#about">{{ $t('userDetails.tabs.about') }}</v-tab>
        <v-tab v-if="isBookingAvailable" href="#appointment">{{ $t('userDetails.tabs.availability') }}</v-tab>
        <v-tab v-if="isSelfMatchingAvailable" href="#match">{{ $t('userProfileModal.requestMatch') }}</v-tab>
      </v-tabs>
    </div>
    <div v-if="user" class="details-body">
      <v-tabs-items v-model="tab" class="fill-width">
        <v-tab-item value="about">
          <m-user-details-form :user="user" />
        </v-tab-item>
        <v-tab-item value="appointment">
          <m-user-availability-form :user="user" />
        </v-tab-item>
        <v-tab-item value="match">
          <p v-if="notAvailableReason" class="text-center my-4">{{ notAvailableReasonDescription }}</p>
          <m-match-request-form v-else-if="user" :user="user" @onSent="goToAbout" />
        </v-tab-item>
      </v-tabs-items>
    </div>
    <m-match-action-dialog v-model="showMatchActionDialog" :action="matchAction" :match-id="matchId" />
    <m-ritual-match-action-dialog
      v-model="showRitualMatchActionDialog"
      :action="matchAction"
      :match-id="matchId"
      :ritual-id="matchRitualId"
    />
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { SetFavoriteStatus, UsersState } from '@/store/users';
import { IMatch, UserEntity } from '@mentessa/types';
import { getFullName } from '@/utils/user';
import {
  MMatchRequestForm,
  MUserAvailabilityForm,
  MUserDetailsForm,
} from '@/components/Dialogs/MUserDetailsDialog/MUserDetailsContent/forms';
import MRitualPreview from '@/components/MRitualPreview';
import MMatchActionDialog from '@/components/Dialogs/MMatchActionDialog';
import MRitualMatchActionDialog from '@/components/Dialogs/MRitualMatchActionDialog';

enum NotAvailableReason {
  PendingMatch = 'pending',
  OpenSession = 'open_session',
  Busy = 'busy',
}

export default Vue.extend({
  name: 'm-user-details-content',
  props: {
    user: { type: Object as PropType<UserEntity> },
    value: { type: Boolean, default: false },
  },
  components: {
    MRitualMatchActionDialog,
    MMatchActionDialog,
    MRitualPreview,
    MMatchRequestForm,
    MUserAvailabilityForm,
    MUserDetailsForm,
  },
  data: () => ({
    showMatchRequest: false,
    showMatchActionDialog: false,
    showRitualMatchActionDialog: false,
    matchAction: undefined,
    matchId: undefined,
    matchRitualId: undefined,
    processedMatches: [],
  }),
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    ...mapGetters('tenant', {
      isBookingAvailable: 'isBookingAvailable',
      isSelfMatchingAvailable: 'isSelfMatchingAvailable',
    }),
    isFavorite() {
      return this.user.isFavorite;
    },
    isMe() {
      return this.user?.id === this.me?.id;
    },
    tab: {
      set(tab: string) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },
    firstName() {
      return this.user?.identity?.attributes?.firstName ?? '';
    },
    fullName() {
      return getFullName(this.user);
    },
    pendingRequest() {
      const pendingRequest = this.user?.matches?.find(
        (match: IMatch) =>
          !this.processedMatches.includes(match.id) &&
          match.matchUsers.find((mu) => mu.userId === this.me.id)?.accepted == null,
      );
      return pendingRequest;
    },
    matchRitual() {
      return this.pendingRequest?.ritual;
    },
    notAvailableReason() {
      if (this.user?.matches?.length > 0) {
        return NotAvailableReason.PendingMatch;
      } else if (this.user?.openMentoringSessions?.length > 0) {
        return NotAvailableReason.OpenSession;
      } else if (!this.user?.matchingAvailable) {
        return NotAvailableReason.Busy;
      }
      return undefined;
    },
    notAvailableReasonDescription() {
      if (!this.notAvailableReason) {
        return '';
      }
      return this.$t(`userProfileModal.not_available_reason.${this.notAvailableReason}`);
    },
  },
  watch: {
    showMatchActionDialog(value: boolean) {
      if (!value) {
        this.$emit('onMatchStateChanged', this.pendingRequest);
        this.processedMatches.push(this.pendingRequest.id);
      }
    },
    showRitualMatchActionDialog(value: boolean) {
      if (!value) {
        this.$emit('onMatchStateChanged', this.pendingRequest);
        this.processedMatches.push(this.pendingRequest.id);
      }
    },
  },
  methods: {
    goToAbout() {
      this.$emit('onUserChanged');
      this.tab = 'about';
    },
    handleChatOpen() {
      // ToDo change to real room ID, roomId fake not work on my side
      this.$router.push(`/inbox?direct=${this.user.id}`);
    },
    async toggleFavorite() {
      await this.$store.dispatch(new SetFavoriteStatus(this.user, !this.user.isFavorite));
    },

    async acceptMatch() {
      this.matchAction = 'accept';
      this.matchId = this.pendingRequest?.id;
      this.matchRitualId = this.matchRitual?.id;
      if (this.matchRitual) {
        this.showRitualMatchActionDialog = true;
      } else {
        this.showMatchActionDialog = true;
      }
    },
    async declineMatch() {
      this.matchAction = 'decline';
      this.matchId = this.pendingRequest.id;
      this.matchRitualId = this.matchRitual?.id;
      if (this.matchRitual) {
        this.showRitualMatchActionDialog = true;
      } else {
        this.showMatchActionDialog = true;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.user-profile {
  height: calc(100vh - 70px);
  margin-top: 70px;
  border-radius: 18px 18px 0 0 !important;

  //@media #{map-get($display-breakpoints, 'xs-only')} {
  //  height: calc(100vh - 112px);
  //}

  &__typography {
    margin-bottom: 4px;
    color: var(--v-text-lighten3);
  }

  &__button-wrap {
    display: flex;
    justify-content: center;

    @media #{map-get($display-breakpoints, 'xs-only')} {
      width: 100%;
    }
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      flex-direction: column;
      padding-top: 16px;
    }
  }

  &__tabs {
    padding: 0 40px;

    @media #{map-get($display-breakpoints, 'xs-only')} {
      padding: 0;
    }
  }

  .user-profile__close-icon {
    position: absolute;
    top: 16px;
    right: 16px;

    @media #{map-get($display-breakpoints, 'xs-only')} {
      left: 16px;
    }
  }

  .user-profile__user-image {
    position: relative;
    top: -30px;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      margin-right: 32px;
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      margin-right: 16px;
    }
  }

  .header-container {
    margin-top: 8px;
    display: flex;
    background-color: var(--v-white-base);
  }

  .details-header {
    background: var(--v-white-base);
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media #{map-get($display-breakpoints, 'xs-only')} {
      flex-direction: column;
      padding: 0 16px;
    }
  }

  .details-body {
    display: flex;
    flex-grow: 1;
    padding: 10px 40px 0;
    gap: 40px;
    overflow-y: auto;

    @media #{map-get($display-breakpoints, 'xs-only')} {
      padding: 20px 16px 0;
    }

    :deep(.v-window__container) {
      height: 100%;
    }

    :deep(.v-window-item) {
      overflow-y: auto;
    }
  }
}

.star-icon {
  color: var(--v-rating-base);
}

.m-company {
  &:before {
    position: relative;
    top: 2px;
    margin-left: 4px;
    margin-right: 4px;
    content: url(@/assets/suitcase.svg);
    width: 24px;
    height: 24px;
  }
}
</style>
