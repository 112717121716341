import {
  NotificationDialog,
  Notifications,
  NotificationsMutations,
  NotificationsState,
  PersistentNotification,
} from './types';
import { MutationTree } from 'vuex';

export const mutations: MutationTree<NotificationsState> = {
  [NotificationsMutations.AddNotification](state: NotificationsState, notification: Notifications) {
    state.notifications.push(notification);
  },
  [NotificationsMutations.HideNotification](state: NotificationsState, { index }) {
    state.notifications.splice(index, 1);
  },
  [NotificationsMutations.OpenNotificationDialog](state: NotificationsState, notificationDialog: NotificationDialog) {
    state.notificationDialog = notificationDialog;
  },
  [NotificationsMutations.CloseNotificationDialog](state: NotificationsState, notificationDialog: NotificationDialog) {
    state.notificationDialog = notificationDialog;
  },
  [NotificationsMutations.ShowPersistentNotification](
    state: NotificationsState,
    persistentNotification: PersistentNotification,
  ) {
    state.persistentNotification = persistentNotification;
  },
  [NotificationsMutations.HidePersistentNotification](state: NotificationsState, { isOpened }) {
    state.persistentNotification.isOpened = isOpened;
  },
};
