<template>
  <v-dialog v-model="isDialogOpen" max-width="746px" sentry-tag="new-video-dialog">
    <v-card :class="{ 'flex-column': this.$vuetify.breakpoint.xsOnly }" class="fill-height pa-6">
      <div class="d-flex justify-end">
        <a sentry-tag="new-video-dialog.button.close" @click="$emit('input', false)">
          <v-img contain height="24" src="@/assets/close-icon.svg" width="24" />
        </a>
      </div>
      <validation-observer v-slot="{ invalid }" class="m-settings-dialog__form">
        <h1>{{ $t('settings.videos.add') }}</h1>
        <m-text-field
          v-model="video.title"
          :label="$t('settings.videos.dialogNew.title')"
          validation-rules="required"
          sentry-tag="new-video-dialog.input.title"
        />
        <m-text-field
          v-model="video.content"
          :label="$t('settings.videos.dialogNew.url')"
          validation-rules="required"
          sentry-tag="new-video-dialog.input.url"
        />
        <p class="text-left mt-n4 ml-2 text--text text--lighten-4">{{ $t('settings.videos.dialogNew.urlHint') }}</p>
        <m-combobox
          v-if="false"
          v-model="video.attributes.tags"
          :label="$t('settings.videos.dialogNew.tags')"
          multiple
          sentry-tag="new-video-dialog.combobox.tags"
        />
        <v-chip-group v-model="video.attributes.tags" column multiple>
          <v-chip v-for="(tag, index) in contentTags" :key="index" filter>{{ tag }}</v-chip>
        </v-chip-group>
        <div class="modal_foot text-center pt-4">
          <v-btn
            :disabled="invalid"
            depressed
            color="button"
            x-large
            @click="addVideo"
            sentry-tag="new-video-dialog.button.add"
          >
            {{ $t('settings.videos.add') }}
          </v-btn>
        </div>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import { MCombobox, MTextField } from '@/components/Inputs';
import { CreateContent } from '@/store/content';
import { ContentType, VideoType } from '@mentessa/types';

export default Vue.extend({
  name: 'm-new-video-dialog',
  components: { MCombobox, MTextField, ValidationObserver },
  props: {
    value: { type: Boolean, default: false },
  },
  data: () => ({
    isLoading: false,
    isValid: false,
    video: {
      type: ContentType.Video,
      title: '',
      content: '',
      attributes: { tags: [] },
    },
  }),
  computed: {
    ...mapGetters('tenant', {
      contentTags: 'contentTags',
    }),
    isDialogOpen: {
      get() {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    async addVideo() {
      const tagsPrepared = this.contentTags.filter((item, index) => this.video.attributes.tags.includes(index));
      await this.$store.dispatch(
        new CreateContent({ ...this.video, attributes: { tags: tagsPrepared, video: { type: VideoType.YouTube } } }),
      );
      this.$emit('input', false);
      this.$emit('onPublish', false);
    },
  },
});
</script>

<style lang="scss" scoped>
.m-settings-dialog__form {
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: 20px;
}
</style>
