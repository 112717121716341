import { Action } from '@/store/types';
import { IRitualTemplate, ITenantRitualTemplate, RitualTemplateReference, TenantReference } from '@mentessa/types';

export const moduleName = 'ritualTemplates';

export interface RitualTemplatesState {
  ritualTemplates: Array<ITenantRitualTemplate>;
  rawRitualTemplates: Array<IRitualTemplate>;
}

export enum RitualTemplatesActions {
  LoadRitualTemplates = '[RitualTemplates] Load Ritual Templates',
  UpdateRitualTemplateIcon = '[RitualTemplates] Update Ritual Template Icon',
  UpdateRitualTemplate = '[RitualTemplates] Update Ritual Template',
  CreateRitualTemplate = '[RitualTemplates] Create Ritual Template',
  DeleteRitualTemplate = '[RitualTemplates] Delete Ritual Template',
  ChangeRitualTemplateEnabled = '[RitualTemplates] Change Ritual Template Enabled',

  LoadRawRitualTemplates = '[RitualTemplates] Load Raw Ritual Templates',
  UpdateRawRitualTemplateIcon = '[RitualTemplates] Update Raw Ritual Template Icon',
  UpdateRawRitualTemplate = '[RitualTemplates] Update Raw Ritual Template',
  CreateRawRitualTemplate = '[RitualTemplates] Create Raw Ritual Template',
  DeleteRawRitualTemplate = '[RitualTemplates] Delete Raw Ritual Template',
}

export enum RitualTemplatesMutations {
  SetRitualTemplates = '[RitualTemplates] Set Ritual Templates',
  UpdateRitualTemplate = '[RitualTemplates] Update Ritual Template',
  SetRawRitualTemplates = '[RitualTemplates] Set Raw Ritual Templates',
  UpdateRawRitualTemplate = '[RitualTemplates] Update Raw Ritual Template',
  AppendRawRitualTemplate = '[RitualTemplates] Append Raw Ritual Template',
  RemoveRawRitualTemplate = '[RitualTemplates] Remove Raw Ritual Template',
}

export class LoadRitualTemplates implements Action {
  type = `${moduleName}/${RitualTemplatesActions.LoadRitualTemplates}`;

  constructor(private readonly tenantRef?: TenantReference) {}
}

export class LoadRawRitualTemplates implements Action {
  type = `${moduleName}/${RitualTemplatesActions.LoadRawRitualTemplates}`;
}

export class UpdateRitualTemplateIcon implements Action {
  type = `${moduleName}/${RitualTemplatesActions.UpdateRitualTemplateIcon}`;

  constructor(private readonly ritualTemplateRef: RitualTemplateReference, private readonly blob: Blob) {}
}

export class UpdateRawRitualTemplateIcon implements Action {
  type = `${moduleName}/${RitualTemplatesActions.UpdateRawRitualTemplateIcon}`;

  constructor(private readonly ritualTemplateRef: RitualTemplateReference, private readonly blob: Blob) {}
}

export class UpdateRawRitualTemplate implements Action {
  type = `${moduleName}/${RitualTemplatesActions.UpdateRawRitualTemplate}`;

  constructor(
    private readonly ritualTemplateRef: RitualTemplateReference,
    private readonly dto: Partial<IRitualTemplate>,
  ) {}
}

export class CreateRawRitualTemplate implements Action {
  type = `${moduleName}/${RitualTemplatesActions.CreateRawRitualTemplate}`;

  constructor(private readonly dto: Partial<IRitualTemplate>) {}
}

export class DeleteRawRitualTemplate implements Action {
  type = `${moduleName}/${RitualTemplatesActions.DeleteRawRitualTemplate}`;

  constructor(private readonly ritualTemplateRef: RitualTemplateReference) {}
}

export class ChangeRitualTemplateEnabled implements Action {
  type = `${moduleName}/${RitualTemplatesActions.ChangeRitualTemplateEnabled}`;

  constructor(private readonly ritualTemplateRef: RitualTemplateReference, private readonly enabled: boolean) {}
}
