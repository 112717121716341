<template>
  <div>
    <m-headline :title="$t('matching.title')" />
    <v-chip-group v-model="filter" class="px-6" column>
      <v-chip v-for="(ritual, index) in rituals" :key="index" filter>
        <m-ritual-preview :ritual="ritual" dense />
      </v-chip>
    </v-chip-group>
    <div v-if="hasMatches" class="d-flex flex-column flex-wrap gap-4 mt-4">
      <div v-for="(match, index) in filteredMatches" :key="`match-${match.id}-${index}`">
        <m-match-card-row :value="match" @onStateChanged="reload" />
      </div>
    </div>
    <m-empty-state
      v-else-if="loading === false"
      :cta="$t('matching.empty.cta')"
      :description="$t('matching.empty.description')"
      :image="require('@/assets/empty/community.svg')"
      :title="$t('matching.empty.title')"
      :to="communityRoute"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { IMatchUser } from '@mentessa/types';
import { LoadUserMatches } from '@/store/matching';
import MMatchCardRow from '@/components/Cards/MMatchCardRow';
import MHeadline from '@/components/MHeadline';
import { MRitualPreview } from '@/components/MRitualPreview';
import MEmptyState from '@/components/MEmptyState';
import { RouteName } from '@/router/types';

export default Vue.extend({
  name: 'm-matches',
  components: { MEmptyState, MRitualPreview, MHeadline, MMatchCardRow },
  data: () => ({
    matches: [],
    filter: undefined,
    rituals: [],
    loading: false as boolean,
    communityRoute: { name: RouteName.Community },
  }),
  computed: {
    filteredMatches() {
      if (this.filter == null) {
        return this.matches;
      }
      return this.matches.filter((match) => match.match?.ritual?.id === this.rituals[this.filter].id);
    },
    hasMatches() {
      return this.matches?.length > 0;
    },
  },
  async mounted() {
    await this.reload();
  },
  methods: {
    async reload() {
      try {
        this.loading = true;
        const matches: Array<IMatchUser> = await this.$store.dispatch(new LoadUserMatches());
        const existsMatches = matches.filter((match) => match.match?.matchUsers?.[0]?.user != null);
        const ritualIds = new Set();
        const rituals = [];
        existsMatches.forEach((match) => {
          if (match.match?.ritual && !ritualIds.has(match.match.ritual.id)) {
            ritualIds.add(match.match.ritual.id);
            rituals.push(match.match.ritual);
          }
        });
        this.rituals = rituals;
        this.matches = existsMatches;
        this.$emit('onLoaded');
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
