<template>
  <div v-if="isMobile" class="mt-14">
    <v-bottom-navigation color="primary" app grow>
      <v-btn @click="openNavigation">
        <v-icon>menu</v-icon>
      </v-btn>
      <v-btn :to="communityRoute">
        <v-icon>groups</v-icon>
      </v-btn>
      <v-btn v-if="isProfileAvailable" :to="inboxRoute">
        <v-icon>mail</v-icon>
      </v-btn>
      <v-btn v-if="isProfileAvailable" :to="profilePersonalRoute">
        <v-icon>person</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { RouteName } from '@/router/types';
import { ChangeNavigationVisible } from '@/store/ui';

export default Vue.extend({
  name: 'm-bottom-navigation',
  data: () => ({
    inboxRoute: { name: RouteName.Inbox },
    communityRoute: { name: RouteName.Community },
    profilePersonalRoute: { name: RouteName.ProfilePersonal },
  }),
  computed: {
    ...mapGetters('tenant', {
      isProfileAvailable: 'isProfileAvailable',
      isMessagesAvailable: 'isMessagesAvailable',
    }),
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.smOnly;
    },
  },
  methods: {
    openNavigation() {
      this.$store.dispatch(new ChangeNavigationVisible(true));
    },
  },
});
</script>
