import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { api } from '@/store/api';
import { ShowErrorNotification } from '@/store/notifications';
import i18n from '@/plugins/i18n';
import { NewsActions, NewsMutations, NewsState } from './types';

export const actions: ActionTree<NewsState, RootState> = {
  async [NewsActions.LoadNews]({ commit, dispatch }, { page, tags }) {
    try {
      const response = await api.get(`news`, { params: { page, tags } });
      const news = response?.data;
      commit(NewsMutations.SetNews, news);
    } catch (e) {
      console.error('Error while LoadNews.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [NewsActions.CreateNews]({ dispatch }, { news }) {
    try {
      const response = await api.post('news', news);
      return response?.status === 201 ?? false;
    } catch (e) {
      console.error('Error while CreateNews.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [NewsActions.DeleteNews]({ dispatch }, { news }) {
    try {
      await api.delete(`news/${news.id}`);
    } catch (e) {
      console.error('Error while DeleteNews.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [NewsActions.UpdateNews]({ dispatch }, { news }) {
    try {
      const response = await api.put(`news/${news.id}`, news);
      return response.data;
    } catch (e) {
      console.error('Error while UpdateNews.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
};
