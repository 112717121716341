<template>
  <svg fill="none" height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L13 13" stroke="#6B7280" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'm-icon-close',
});
</script>
