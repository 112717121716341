export function toBoolean(value: string | string[] | undefined, defaultValue = false): boolean {
  if (value == null) return defaultValue;
  const v = typeof value === 'string' ? value : value[0];
  if (['true', '1'].includes(v.toLowerCase())) return true;
  else if (['false', '0'].includes(v.toLowerCase())) return false;
  return defaultValue;
}

export function arabicToRoman(number: number | string): string {
  const digits = String(+number).split('');
  const key = [
    '',
    'C',
    'CC',
    'CCC',
    'CD',
    'D',
    'DC',
    'DCC',
    'DCCC',
    'CM',
    '',
    'X',
    'XX',
    'XXX',
    'XL',
    'L',
    'LX',
    'LXX',
    'LXXX',
    'XC',
    '',
    'I',
    'II',
    'III',
    'IV',
    'V',
    'VI',
    'VII',
    'VIII',
    'IX',
  ];
  let roman = '';
  let i = 3;
  while (i--) roman = (key[+digits.pop() + i * 10] || '') + roman;
  return Array(+digits.join('') + 1).join('M') + roman;
}

export function romanToArabic(value: string) {
  const upperValue = value.toUpperCase();
  const token = /[MDLV]|C[MD]?|X[CL]?|I[XV]?/g;
  const key = { M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1 };
  let number = 0,
    m;
  while ((m = token.exec(upperValue))) number += key[m[0]];
  return number;
}
