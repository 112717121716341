// Works only with 1 dataset per chart
export function generateLabelsWithCount(chart) {
  const data = chart.data;
  if (data.labels.length && data.datasets.length) {
    const {
      labels: { pointStyle, color },
    } = chart.legend.options;

    return data.labels.map((label, i) => {
      const meta = chart.getDatasetMeta(0);
      const style = meta.controller.getStyle(i);
      return {
        text: `${label} (${data.datasets[0].data[i]})`,
        fillStyle: style.backgroundColor,
        strokeStyle: style.borderColor,
        fontColor: color,
        lineWidth: style.borderWidth,
        pointStyle: pointStyle,
        hidden: !chart.getDataVisibility(i),

        // Extra data used for toggling the correct item
        index: i,
      };
    });
  }
  return [];
}
