<template>
  <div>
    <div class="m-profile-header">
      <m-headline
        :description="
          $t('profile.description', {
            percentage: completionPercentage,
            addMiddle: completionPercentage < 100 ? $t('profile.descriptionFillMore') : '',
          })
        "
        :title="$t('profile.title')"
      />
      <v-alert dense type="info" color="primary">
        {{ $t('profile.descriptionPrivacy') }}
      </v-alert>
      <v-tabs :grow="$vuetify.breakpoint.smAndDown">
        <v-tab :to="personalRoute" large>
          {{ $t('profile.blocks.personal') }}
        </v-tab>
        <v-tab :to="skillsRoute" large>
          {{ $t('profile.blocks.skills') }}
        </v-tab>
        <v-tab v-if="isMentoringAvailable" :to="availabilityRoute" large>
          {{ $t('profile.blocks.availability') }}
        </v-tab>
        <v-tab :to="notificationsRoute" large>
          {{ $t('profile.blocks.notifications') }}
        </v-tab>
        <v-tab v-if="isUserIntegrationsAvailable" :to="integrationsRoute" large>
          {{ $t('profile.blocks.integrations') }}
        </v-tab>
      </v-tabs>
    </div>
    <router-view />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { RouteName } from '@/router/types';
import MHeadline from '@/components/MHeadline';

export default Vue.extend({
  name: 'm-profile',
  components: { MHeadline },
  data: () => ({
    personalRoute: { name: RouteName.ProfilePersonal },
    skillsRoute: { name: RouteName.ProfileSkills },
    availabilityRoute: { name: RouteName.ProfileAvailability },
    integrationsRoute: { name: RouteName.ProfileIntegrations },
    notificationsRoute: { name: RouteName.ProfileNotifications },
  }),
  computed: {
    ...mapGetters('tenant', {
      isMentoringAvailable: 'isMentoringAvailable',
      isUserIntegrationsAvailable: 'isUserIntegrationsAvailable',
    }),
    ...mapGetters('users', {
      completionPercentage: 'completionPercentage',
    }),
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.m-profile-header {
  z-index: 2;
  position: sticky;
  top: 60px;
  //padding-top: 12px;
  background-color: var(--v-white-base);
  border-bottom: 1px solid var(--v-lightgray-base);

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    top: 0;
    left: 0;
  }
}
</style>
