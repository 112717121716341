<template>
  <v-img
    :height="maxSize"
    :max-height="maxSize"
    :max-width="maxSize"
    :src="localSrc"
    :width="maxSize"
    :contain="contain"
  />
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'm-icon',
  props: {
    contain: { type: Boolean, default: true },
    big: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    name: String,
    src: String,
  },
  computed: {
    assetNameOverride() {
      return this.name;
    },
    srcOverride() {
      return undefined;
    },
    maxSize() {
      if (this.big) {
        return 56;
      }
      if (this.dense) {
        return 24;
      }
      return 32;
    },
    localSrc() {
      if (this.srcOverride) {
        return this.srcOverride;
      }
      if (this.assetNameOverride) {
        return require(`@/assets/rituals/${this.assetNameOverride}.png`);
      }
      return this.src;
    },
  },
});
</script>
