<template>
  <validation-provider v-slot="{ errors }" :debounce="validationDebounce" :name="label" :rules="validationRules" slim>
    <v-text-field
      :error-messages="errors"
      :label="labelWithRequired"
      :value="value"
      sentry-tag="text-field"
      v-bind="{ ...$props, ...$attrs }"
      v-on="$listeners"
    />
  </validation-provider>
</template>

<script lang="ts">
import { between, email, integer, max, required } from 'vee-validate/dist/rules';
import { extend, setInteractionMode, ValidationProvider } from 'vee-validate';
import Vue from 'vue';

const urlRegexp =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
const lowercaseUnderscore = /^[a-z\d_]+$/;
export default Vue.extend({
  name: 'm-text-field',
  components: {
    ValidationProvider,
  },
  props: {
    value: [String, Number],
    label: [String, Object /*as PropType<TranslateResult>*/],
    outlined: { type: Boolean, default: true },
    hideDetails: { type: String, default: 'auto' },
    validationRules: String,
    validationDebounce: Number,
    validationMode: { type: String, default: 'aggressive' },
    requiredSuffix: { type: String, default: ' *' },
  },
  computed: {
    isRequired() {
      return this.validationRules?.includes('required') ?? false;
    },
    labelWithRequired() {
      return this.isRequired ? `${this.label ?? ''}${this.requiredSuffix}` : this.label;
    },
  },
  watch: {
    validationMode(mode: string) {
      setInteractionMode(mode);
    },
  },
  created() {
    setInteractionMode(this.validationMode);

    extend('required', {
      ...required,
      message: this.$t('validation.required'),
    } as never);

    extend('max', {
      ...max,
      message: this.$t('validation.max'),
    } as never);

    extend('email', {
      ...email,
      message: this.$t('validation.email'),
    } as never);

    extend('between', {
      ...between,
      message: this.$t('validation.between'),
    } as never);

    extend('integer', {
      ...integer,
      message: this.$t('validation.number'),
    } as never);

    extend('url', {
      validate: (value) => {
        return urlRegexp.test(value);
      },
      message: this.$t('validation.url'),
    } as never);

    extend('lowercaseUnderscore', {
      validate: (value) => {
        return lowercaseUnderscore.test(value);
      },
      message: this.$t('validation.lowercaseUnderscore'),
    } as never);

    extend('linkedin_link', {
      validate: (value) => {
        return urlRegexp.test(value);
      },
      message: this.$t('validation.url'),
    } as never);

    extend('size', {
      validate: (value) => {
        if (value.size / (1024 * 1024) > this.maxSize) {
          return this.$t('validation.size', { size: this.maxSize });
        }
        return true;
      },
    } as never);
  },
});
</script>
