<template>
  <m-dialog v-if="initialized" :value="show" persistent slim>
    <div class="m-onboarding-container d-flex flex-column">
      <m-wizard
        v-if="stage === Stage.Wizard"
        v-model="userDto"
        :loading="loading"
        :steps="steps"
        raw-model
        @onComplete="save"
      />
      <v-card v-else-if="stage === Stage.Video" class="m-onboarding-content d-flex flex-column pa-4">
        <v-card-title class="pb-0 mt-auto">
          <h3>{{ $t('onboarding_user.video.title', { tenant: this.tenantName }) }}</h3>
        </v-card-title>
        <v-card-text>
          {{ $t('onboarding_user.video.description', { tenant: this.tenantName }) }}
        </v-card-text>
        <div class="d-flex align-center justify-center">
          <m-onboarding-video-form />
        </div>
        <v-card-actions class="justify-end mt-auto">
          <v-col cols="6">
            <v-btn color="button" depressed outlined width="100%" @click="skipVideo">{{ $t('actions.skip') }}</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
      <v-card v-else class="m-onboarding-content d-flex flex-column pa-4">
        <v-card-title class="pb-0 mt-auto">
          <h3>{{ $t('onboarding_user.complete.title', { tenant: this.tenantName }) }}</h3>
        </v-card-title>
        <v-card-text>
          {{ $t('onboarding_user.complete.description', { tenant: this.tenantName }) }}
        </v-card-text>
        <div class="d-flex align-center justify-center"></div>
        <v-card-actions class="justify-end mt-auto">
          <v-col cols="6">
            <v-btn color="button" depressed width="100%" @click="close">{{ $t('actions.continue') }}</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
  </m-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import { GetMe, SaveUserProfile, UpdateUserImage, UsersState } from '@/store/users';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';
import { WelcomeVideoConfig } from '@mentessa/types';
import {
  MOnboardingAboutForm,
  MOnboardingAvailabilityForm,
  MOnboardingExpertiseForm,
  MOnboardingGoalsForm,
  MOnboardingInterestsForm,
  MOnboardingLanguagesForm,
  MOnboardingLocationForm,
  MOnboardingPersonalForm,
  MOnboardingPhotoForm,
  MOnboardingVideoForm,
} from './forms';
import deepmerge from 'deepmerge';
import { CreatePersonalGoal } from '@/store/mentoring';
import { ChangeDialogBlockedState } from '@/store/ui';
import { RootState } from '@/store/types';
import { MDialog } from '@/components/Dialogs/MDialog';
import { MWizard } from '@/components/MWizard';

enum Stage {
  Wizard = 'wizard',
  Video = 'video',
  Complete = 'complete',
}

export default Vue.extend({
  name: 'm-user-onboarding-wizard',
  components: {
    MWizard,
    MDialog,
    MOnboardingVideoForm,
  },
  data: () => ({
    stepIndex: 0 as number,
    userDto: undefined,
    showVideo: false as boolean,
    show: false as boolean,
    Stage,
    stage: Stage.Wizard as Stage,
    loading: false as boolean,
    wizardData: {
      personal: {
        firstName: undefined as string,
        lastName: undefined as string,
        email: undefined as string,
        jobTitle: undefined as string,
      },
      languages: undefined as Array<string>,
      expertise: undefined,
      interests: undefined,
      goals: undefined,
      availability: undefined,
      about: undefined,
      location: undefined,
      photo: undefined,
    },
  }),
  computed: {
    ...mapState<RootState>({
      initialized: (state: RootState) => state.initialized,
    }),
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    ...mapState<TenantState>('tenant', {
      welcomeVideo: (state: TenantState): WelcomeVideoConfig =>
        state.tenant?.attributes.welcomeVideo ?? {
          enabled: true,
        },
      useFullProfile: (state: TenantState) => state.tenant?.attributes.useFullProfile ?? false,
      tenantName: (state: TenantState) => state.tenant?.attributes?.theme?.title ?? '',
    }),
    progress() {
      return Math.round(((this.stepIndex + 1) / this.steps.length) * 100);
    },
    steps() {
      const steps = [];

      steps.push(
        {
          name: 'personal',
          title: this.$t(`onboarding_user.personal.title`, { tenant: this.tenantName }),
          description: this.$t(`onboarding_user.personal.description`, { tenant: this.tenantName }),
          component: MOnboardingPersonalForm,
        },
        {
          title: this.$t(`onboarding_user.languages.title`, { tenant: this.tenantName }),
          description: this.$t(`onboarding_user.languages.description`, { tenant: this.tenantName }),
          component: MOnboardingLanguagesForm,
        },
        {
          title: this.$t(`onboarding_user.expertise.title`, { tenant: this.tenantName }),
          description: this.$t(`onboarding_user.expertise.description`, { tenant: this.tenantName }),
          component: MOnboardingExpertiseForm,
        },
        {
          title: this.$t(`onboarding_user.interests.title`, { tenant: this.tenantName }),
          description: this.$t(`onboarding_user.interests.description`, { tenant: this.tenantName }),
          component: MOnboardingInterestsForm,
        },
        {
          title: this.$t(`onboarding_user.goals.title`, { tenant: this.tenantName }),
          description: this.$t(`onboarding_user.goals.description`, { tenant: this.tenantName }),
          component: MOnboardingGoalsForm,
        },
        {
          title: this.$t(`onboarding_user.availability.title`, { tenant: this.tenantName }),
          description: this.$t(`onboarding_user.availability.description`, { tenant: this.tenantName }),
          component: MOnboardingAvailabilityForm,
        },
        {
          title: this.$t(`onboarding_user.location.title`, { tenant: this.tenantName }),
          description: this.$t(`onboarding_user.location.description`, { tenant: this.tenantName }),
          component: MOnboardingLocationForm,
        },
        {
          title: this.$t(`onboarding_user.about.title`, { tenant: this.tenantName }),
          description: this.$t(`onboarding_user.about.description`, { tenant: this.tenantName }),
          component: MOnboardingAboutForm,
        },
        {
          title: this.$t(`onboarding_user.photo.title`, { tenant: this.tenantName }),
          description: this.$t(`onboarding_user.photo.description`, { tenant: this.tenantName }),
          component: MOnboardingPhotoForm,
        },
      );
      return steps;
    },
    step() {
      return this.steps[this.stepIndex];
    },
    isNextAvailable() {
      return this.stepIndex < this.steps.length - 1;
    },
    isBackAvailable() {
      return this.stepIndex > 0;
    },
  },
  watch: {
    'me.complete'() {
      this.updateData();
    },
    async show(value: boolean) {
      if (value) {
        this.stepIndex = 0;
      }
      await this.$store.dispatch(new ChangeDialogBlockedState(value));
    },
  },
  mounted() {
    this.focusVideo = false;
    this.updateData();
  },
  methods: {
    skipVideo() {
      this.showVideo = false;
      this.stage = Stage.Complete;
    },
    close() {
      this.showVideo = false;
      this.show = false;
      this.stage = Stage.Wizard;
    },
    async updateData() {
      if (!this.me) {
        return;
      }
      this.userDto = {
        attributes: deepmerge({}, this.me.attributes),
        identity: deepmerge({}, this.me.identity),
        expertiseTags: deepmerge({}, this.me.expertiseTags),
        interestTags: deepmerge({}, this.me.interestTags),
        tz: this.me.tz,
        lang: this.me.lang,
      };

      if (!this.me.complete) {
        this.show = true;
      }
    },
    async save() {
      try {
        this.loading = true;
        if (this.userDto.identity?.attributes?.image !== this.me.identity.attributes.image) {
          await this.$store.dispatch(new UpdateUserImage(this.userDto.identity.attributes.image));
        }

        const dto = {
          attributes: {
            ...this.userDto.attributes,
          },
          identity: {
            attributes: {
              ...this.userDto.identity.attributes,
            },
          },
          tags: {
            expertise: this.userDto.expertiseTags,
            interests: this.userDto.interestTags,
          },
        };
        if (this.userDto.lang) {
          dto['lang'] = this.userDto.lang;
        }
        if (this.userDto.tz) {
          dto['tz'] = this.userDto.tz;
        }
        await this.$store.dispatch(new SaveUserProfile(dto));

        if (this.userDto.goals) {
          for (const goal of this.userDto.goals) {
            await this.$store.dispatch(new CreatePersonalGoal({ text: goal, i18n: true }));
          }
        }

        await this.$store.dispatch(new GetMe());

        if (this.me.complete) {
          if (this.welcomeVideo?.enabled) {
            this.showVideo = true;
            this.stage = Stage.Video;
          } else {
            this.stage = Stage.Complete;
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.m-onboarding-container {
  background-color: var(--v-white-base);
  height: 100%;
}

.m-onboarding-content {
  border: none !important;
  height: 550px;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    height: 100%;
  }
}

.m-stepper-container {
  width: 24px;
  height: 100%;
}

.m-stepper-item {
  border-radius: 18px;
  background-color: var(--v-lightgray-base);
  width: 16px;
  height: 16px;

  &__big {
    width: 36px;
    height: 36px;
  }

  &__active {
    background-color: var(--v-primary-base);
  }

  &__top {
    border-radius: 0 0 18px 18px;
  }

  &__bottom {
    border-radius: 18px 18px 0 0;
  }

  &__tall {
    height: 64px;
  }
}
</style>
