import { UsersMutations, UsersState } from './types';
import { MutationTree } from 'vuex';
import { IUser, Locale, UserAttributes, UserPermissions, UsersPage } from '@mentessa/types';
import * as Sentry from '@sentry/vue';

export const mutations: MutationTree<UsersState> = {
  [UsersMutations.SetMe](state: UsersState, user: IUser) {
    if (!user.identity.attributes.image) {
      user.identity.attributes.image = '/user-empty.png';
    }
    state.me = user;
    Sentry.setUser({
      id: user.id.toString(),
      email: user.identity.attributes.email,
    });
  },
  [UsersMutations.SetMyImage](state: UsersState, url: string) {
    state.me.identity.attributes.image = url;
  },
  [UsersMutations.SetMyFullName](state: UsersState, fullName: { firstName: string; lastName: string }) {
    state.me.identity.attributes.firstName = fullName.firstName;
    state.me.identity.attributes.lastName = fullName.lastName;
  },
  [UsersMutations.SetMyAttributes](state: UsersState, attributes: UserAttributes) {
    state.me.attributes = attributes;
  },
  [UsersMutations.SetMyTags](state: UsersState, user: IUser) {
    state.me.expertiseTags = user.expertiseTags;
    state.me.interestTags = user.interestTags;
  },
  [UsersMutations.SetMyEmail](state: UsersState, email: string) {
    state.me.identity.attributes.email = email;
  },
  [UsersMutations.SetMyComplete](state: UsersState, complete: boolean) {
    state.me.complete = complete;
  },
  [UsersMutations.SetDetailedUser](state: UsersState, user: IUser) {
    if (!user.identity.attributes.image) {
      user.identity.attributes.image = '/user-empty.png';
    }
    state.detailedUser = user;
  },
  [UsersMutations.SetPermissions](state: UsersState, permissions: UserPermissions) {
    state.permissions = permissions;
  },
  [UsersMutations.SetFavoriteStatus](state: UsersState, options: { target: IUser; status: boolean }) {
    options.target.isFavorite = options.status;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [UsersMutations.SetSlotAvailable](state: UsersState, { user, weekday, slot, isAvailable }) {
    // ToDo: Apply new data to AIS
  },
  [UsersMutations.SetAdminFlag](state: UsersState, options: { user: IUser; isAdmin: boolean }) {
    // ToDo: Apply new data to AIS
    const { user, isAdmin } = options;
    const index = state.members.items.findIndex((item) => item.id === user.id);
    if (isAdmin)
      state.members.items[index].attributes.roles = state.members.items[index].attributes.roles
        ? [...state.members.items[index].attributes.roles, 'Admin']
        : ['Admin'];
    else
      state.members.items[index].attributes.roles = state.members.items[index].attributes.roles.filter(
        (role) => role != 'Admin',
      );
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [UsersMutations.RemoveUser](state: UsersState, user: IUser) {
    // ToDo: Apply new data to AIS
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [UsersMutations.SuspendUser](state: UsersState, user: IUser) {
    // ToDo: Apply new data to AIS
  },
  [UsersMutations.RestoreUser](state: UsersState, user: IUser) {
    // ToDo: Apply new data to AIS
    const index = state.members.items.findIndex((item) => item.id === user.id);
    state.members.items[index].deletedAt = null;
  },
  [UsersMutations.HideUser](state: UsersState, user: IUser) {
    // ToDo: Apply new data to AIS
    const index = state.members.items.findIndex((item) => item.id === user.id);
    state.members.items[index].hidden = true;
  },
  [UsersMutations.UnHideUser](state: UsersState, user: IUser) {
    // ToDo: Apply new data to AIS
    const index = state.members.items.findIndex((item) => item.id === user.id);
    state.members.items[index].hidden = false;
  },
  [UsersMutations.SetUserAttributes](state: UsersState, user: IUser) {
    const index = state.members.items.findIndex((item) => item.id === user.id);
    state.members.items[index].attributes = user.attributes;
  },
  [UsersMutations.SetMembers](state: UsersState, page: UsersPage) {
    state.members = page;
  },

  [UsersMutations.AppendToStream](state: UsersState, user: IUser) {
    state.usersStream.items.push({ loaded: true, user });
  },

  [UsersMutations.IncreaseStreamPosition](state: UsersState) {
    ++state.usersStream.position;
  },

  [UsersMutations.SetStreamLoading](state: UsersState, loading: boolean) {
    state.usersStream.loading = loading;
  },

  [UsersMutations.SetStreamFinished](state: UsersState, finished: boolean) {
    state.usersStream.finished = finished;
  },

  // ToDo: rework or remove
  [UsersMutations.SetTimeSlotAvailability](state: UsersState, { schedule, target }) {
    const user = state.usersStream.items.find((item) => item.user?.id === target.id)?.user;
    if (user) {
      const selectedSlotIndex = user.schedules.findIndex((s) => {
        return s.id === schedule.id;
      });
      if (selectedSlotIndex !== -1) {
        // ToDo: rework 'dates'
        const indexToRemove = user.schedules[selectedSlotIndex]['dates'].findIndex((d) => {
          return d === `${schedule.date} ${schedule.hour}`;
        });
        user.schedules[selectedSlotIndex]['dates'].splice(indexToRemove, 1);
      }
    }
  },

  [UsersMutations.SetRulesAccepted](state: UsersState, rulesAccepted: boolean) {
    state.me = { ...state.me, rulesAccepted };
  },

  [UsersMutations.SetMyLang](state: UsersState, lang: Locale) {
    state.me.attributes.lang = lang;
  },

  [UsersMutations.SetSchedules](state: UsersState, schedules) {
    state.schedules = schedules ?? [];
  },
};
