import { format, parse, parseISO } from 'date-fns';

export function parseAndFormatDate(date: string): string {
  try {
    return format(parseISO(date), 'dd.MM.yyyy');
  } catch (e) {
    return '-';
  }
}

export function dateWithDayOfWeek(date: Date): string {
  try {
    return format(date, 'EEEE, d.M.yyyy');
  } catch (e) {
    return '-';
  }
}

export function hourAndDayToCalendarDate(date: string): string {
  // const d = parse(`${hour} ${day}`, 'H d', new Date());
  // return format(d, 'y-MM-dd');

  const d = parse(`${date}`, 'y-MM-dd H:mm:ss.SSS xxxx', new Date());
  return format(d, 'y-MM-dd');
}

export function parseSlot(slot: string): Date {
  return parse(slot, 'HH:mm', new Date());
}

export function parseHourDay(slot: string): Date {
  return parse(slot, 'HH:mm', new Date());
}

export function formatOneHourSlot(slot: string): string {
  const date = parseSlot(slot);
  return `${format(date, 'H:mm')} - ${format(date.setHours(date.getHours() + 1), 'H:mm')}`;
}
