<template>
  <v-menu offset-y v-bind="$attrs" max-height="288">
    <template v-slot:activator="{ on, attrs, value }">
      <v-btn v-on="on" v-bind="attrs" text class="pa-1" x-large :block="block">
        <slot name="activator" />
        <v-icon>{{ value ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</v-icon>
      </v-btn>
    </template>

    <slot name="items" />
  </v-menu>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'm-app-bar-menu',
  props: {
    block: { type: Boolean, default: false },
  },
});
</script>
