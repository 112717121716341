<template>
  <div>
    <v-btn class="ma-4" sentry-tag="settings-debug.button.refreshTokens" @click="refreshTokens">
      Refresh a tokens list
    </v-btn>
    <v-btn class="ma-4" sentry-tag="settings-debug.button.createToken" @click="createToken">Create token</v-btn>
    <div v-if="token">
      Created token (warning: there is no way to get token key again):
      <m-text-area :value="token" readonly />
    </div>

    <v-simple-table>
      <thead>
        <tr>
          <th class="text-center"><span>ID</span></th>
          <th class="text-center"><span>User (ID)</span></th>
          <th class="text-center"><span>created by (ID)</span></th>
          <th class="text-center"><span>crated At</span></th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(token, index) in tokens" :key="`settings-debug-tokens-${index}`">
          <td class="text-center">{{ token.id }}</td>
          <td class="text-center">{{ token.userId }}</td>
          <td class="text-center">{{ token.createdById }}</td>
          <td class="text-center">{{ token.createdAt }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script lang="ts">
import MTextArea from '@/components/Inputs/MTextArea/MTextArea.vue';
import { CreateToken, LoadTokens, TokensState } from '@/store/tokens';
import { mapState } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
  name: 'm-settings-debug-tokens',
  components: { MTextArea },
  data: () => ({
    token: '',
    localTenant: {
      attributes: undefined,
      config: undefined,
    },
  }),
  computed: {
    ...mapState<TokensState>('tokens', {
      tokensPage: (state: TokensState) => {
        return state.tokens;
      },
    }),
    tokens() {
      return this.tokensPage?.items ?? [];
    },
  },
  methods: {
    async refreshTokens() {
      await this.$store.dispatch(new LoadTokens());
    },
    async createToken() {
      this.token = await this.$store.dispatch(new CreateToken({}));
    },
  },
  async mounted() {
    await this.refreshTokens();
  },
});
</script>
