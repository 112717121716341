<template>
  <div class="d-flex justify-center align-center flex-column">
    <m-chip-group v-model="selected" :items="items" class="mt-4" i18n-path="onboarding.tools.items">
      <template v-slot:item="{ item }">
        <m-tool-preview :name="item" />
      </template>
    </m-chip-group>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { MChipGroup } from '@/components/Inputs';
import MToolPreview from '@/components/MToolPreview/MToolPreview.vue';

export default Vue.extend({
  name: 'm-tenant-onboarding-help-with-form',
  components: { MToolPreview, MChipGroup },
  props: {
    value: { type: Array as PropType<Array<string>>, default: () => [] },
  },
  data: () => ({
    items: ['whatsapp', 'linkedin', 'teams', 'workday', 'excel', 'slack', 'facebook'],
  }),
  computed: {
    selected: {
      get() {
        return this.value.map((item) => this.items.indexOf(item));
      },
      set(value: Array<number>) {
        this.$emit(
          'input',
          value.map((index) => this.items[index]),
        );
      },
    },
  },
});
</script>
