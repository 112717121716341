<template>
  <div class="d-flex flex-column text-center pa-4">
    <m-chip-group v-model="interests" :items="skills" class="mt-4" i18n-path="onboarding_user.skills" multiple />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { ITag, IUser } from '@mentessa/types';
import { MChipGroup } from '@/components/Inputs';

export default Vue.extend({
  name: 'm-onboarding-interests-form',
  components: {
    MChipGroup,
  },
  props: {
    value: Object as PropType<IUser>,
  },
  data: () => ({
    skills: [
      'teamwork',
      'time_management',
      'communication',
      'coding',
      'career_growth',
      'critical_thinking',
      'conflict_resolution',
      'customer_development',
      'leadership',
      'long_term_strategy',
      'problem_solving',
      'presentations',
      'project_management',
      'personal_branding',
      'networking',
      'new_business_planning',
      'new_work',
      'innovation',
      'negotiation',
      'sales',
      'online_marketing',
      'work_life_balance',
      'happiness',
    ],
  }),
  computed: {
    names() {
      return this.skills.map((skill: string) => this.$t(`onboarding_user.skills.${skill}`) as string);
    },
    interests: {
      get() {
        const values = this.value?.interestTags?.map(
          (
            tag: ITag & {
              key: string;
            },
          ) => (tag.key ? this.skills.indexOf(tag.key) : this.names.indexOf(tag.name)),
        );
        return values.filter((value: number) => value !== -1);
      },
      set(value: Array<number>) {
        const deleted = this.interests.filter((index: number) => !value.includes(index));

        const result = [...(this.value?.interestTags ?? [])].filter((tag: ITag & { key: string }) => {
          return !deleted.some((index) => tag.name === this.names[index] || tag.key === this.skills[index]);
        });

        if (value.length > 0) {
          result.push(
            ...value
              .map((index) => ({ name: this.names[index], key: this.skills[index] }))
              .filter(
                (dto) =>
                  !this.value?.interestTags?.some(
                    (
                      tag: ITag & {
                        key: string;
                      },
                    ) => tag.name === dto.name || tag.key === dto.key,
                  ),
              ),
          );
        }

        this.$emit('input', {
          ...this.value,
          interestTags: result,
        });
      },
    },
  },
});
</script>
