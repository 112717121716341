<template>
  <div class="resource_card_container">
    <div class="resource_card__header d-flex justify-space-between align-center">
      <h3 class="text--text">{{ resource.title }}</h3>
      <div class="d-flex align-center">
        <div class="text--text text--lighten-5">{{ formattedDate }}</div>
        <div v-if="isResourcesManagingAvailable">
          <v-menu bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link sentry-tag="resource-admins.menu.editResource" @click="$emit('onEdit', resource)">
                <v-list-item-title>{{ $t('resources.action.edit') }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="canDelete"
                link
                sentry-tag="news-admins.menu.deleteResource"
                @click="$emit('onDelete', resource)"
              >
                <v-list-item-title>{{ $t('resources.action.delete') }}</v-list-item-title>
              </v-list-item>
              <v-list-item link sentry-tag="news-admins.menu.hideResource" @click="$emit('onHide', resource)">
                <v-list-item-title>{{ $t('resources.action.hide') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </div>
    <m-video v-if="isVideo" :video="video" :focusedVideo="focusedResource" @onFocus="focusResource" />
    <div v-else class="text--text" v-html="markedContent" />
    <v-chip-group column>
      <v-chip v-for="(tag, index) in resource.attributes.tags" :key="index">{{ tag }}</v-chip>
    </v-chip-group>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import MVideo from '../MVideo';
import { mapGetters, mapState } from 'vuex';
import { IResource, IVideo } from '@mentessa/types';
import { linkifyHtml } from '@/utils/linkify';
import { TenantState } from '@/store/tenant';

export default Vue.extend({
  name: 'm-resource-card',
  components: {
    MVideo,
  },
  props: {
    resource: { type: Object as PropType<IResource>, default: () => ({}) },
  },
  data: () => ({
    focusedResource: undefined,
  }),
  computed: {
    ...mapGetters('tenant', {
      isResourcesManagingAvailable: 'isResourcesManagingAvailable',
    }),
    ...mapGetters('ui', {
      formatInUserTimeZone: 'formatInUserTimeZone',
    }),
    ...mapState<TenantState>('tenant', {
      tenantId: (state: TenantState) => state.tenant.id,
    }),
    formattedDate() {
      if (this.resource?.createdAt) {
        return this.formatInUserTimeZone(this.resource.createdAt, 'd.M.yyyy');
      }
      return '';
    },
    video(): IVideo {
      return {
        id: this.resource.id,
        url: this.resource.content,
        type: this.resource.attributes.type,
        attributes: { preview: this.resource.attributes.preview, tags: this.resource.attributes.tags },
      };
    },
    canDelete() {
      return this.tenantId === this.resource.tenantId;
    },
    isVideo() {
      return this.resource.attributes.type != null; // attributes.type is VideoType and available only for videos.
    },
    markedContent() {
      return this.$marked(this.resource.content ?? '');
    },
  },
  methods: {
    linkify: linkifyHtml,
    focusResource(video: IVideo) {
      this.focusedResource = video;
    },
  },
});
</script>

<style lang="scss" scoped>
.resource_card_container {
  padding: 30px;
  background-color: var(--v-white-base);
  width: 100%;
  border: 1px solid var(--v-beige-base);
  border-radius: 16px;
}

.resource_card__header {
  width: 100%;
}
</style>
