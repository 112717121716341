import { ActionTree } from 'vuex';
import { RootState, StoreDataState } from '@/store/types';
import { MentoringActions, MentoringMutations, MentoringState } from './types';
import { api } from '@/store/api';
import i18n from '@/plugins/i18n';
import { ShowErrorNotification, ShowNotification } from '@/store/notifications';
import { getIdOrCurrent } from '@/utils/api';

export const actions: ActionTree<MentoringState, RootState> = {
  async [MentoringActions.LoadMentoringSessionList]({ commit, dispatch }, { user }) {
    try {
      commit(MentoringMutations.SetSessionsState, StoreDataState.Loading);
      const response = await api.get(`/users/${getIdOrCurrent(user)}/mentoring-sessions`);
      commit(MentoringMutations.SetSessionsState, StoreDataState.Loaded);
      commit(MentoringMutations.SetMentoringSessionsList, response.data);
    } catch (e) {
      console.error('Error while LoadMentoringSessionList.', e);
      commit(MentoringMutations.SetSessionsState, StoreDataState.Error);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [MentoringActions.LoadMentoringSession]({ commit, dispatch }, { sessionRef, user }) {
    try {
      commit(MentoringMutations.SetSessionState, { sessionRef, data: StoreDataState.Loading });
      const response = await api.get(`/users/${getIdOrCurrent(user)}/mentoring-sessions/${sessionRef.id}`);
      commit(MentoringMutations.SetSessionState, { sessionRef, data: StoreDataState.Loaded });
      commit(MentoringMutations.SetMentoringSession, response.data);
    } catch (e) {
      console.error('Error while LoadMentoringSession.', e);
      commit(MentoringMutations.SetSessionState, { sessionRef, data: StoreDataState.Error });
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [MentoringActions.CloseMentoringSession]({ commit, dispatch }, { sessionRef }) {
    try {
      const response = await api.post(`/mentoring-sessions/${sessionRef.id}/finish`);
      commit(MentoringMutations.UpdateMentoringSession, response.data);
    } catch (e) {
      console.error('Error while CloseMentoringSession.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [MentoringActions.OpenMentoringSession]({ commit, dispatch }, { sessionRef }) {
    try {
      const response = await api.post(`/mentoring-sessions/${sessionRef.id}/progress`);
      commit(MentoringMutations.UpdateMentoringSession, response.data);
    } catch (e) {
      console.error('Error while OpenMentoringSession.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [MentoringActions.LoadMentoringSessionGoals]({ commit, dispatch }, { sessionRef, user }) {
    try {
      const response = await api.get(`/users/${getIdOrCurrent(user)}/mentoring-sessions/${sessionRef.id}/goals`);
      commit(MentoringMutations.SetMentoringSessionGoals, response.data);
    } catch (e) {
      console.error('Error while LoadMentoringSessionGoals.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [MentoringActions.LoadMentoringSessionMessages]({ commit, dispatch }, { sessionRef, user }) {
    try {
      const response = await api.get(`/users/${getIdOrCurrent(user)}/mentoring-sessions/${sessionRef.id}/messages`);
      commit(MentoringMutations.SetMentoringSessionMessages, response.data);
    } catch (e) {
      console.error('Error while LoadMentoringSessionMessages.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [MentoringActions.LoadMentoringSessionAppointments]({ commit, dispatch }, { sessionRef, user }) {
    try {
      const response = await api.get(`/users/${getIdOrCurrent(user)}/mentoring-sessions/${sessionRef.id}/appointments`);
      commit(MentoringMutations.SetMentoringSessionAppointments, response.data);
    } catch (e) {
      console.error('Error while LoadMentoringSessionAppointments.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [MentoringActions.SetCurrentMentoringSession]({ commit, dispatch }, { sessionRef }) {
    try {
      commit(MentoringMutations.SetActiveMentoringSessionId, sessionRef.id);
    } catch (e) {
      console.error('Error while SetCurrentMentoringSession.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [MentoringActions.CreateMentoringSessionMessage]({ commit, dispatch }, { sessionRef, text, user }) {
    try {
      const response = await api.post(`/users/${getIdOrCurrent(user)}/mentoring-sessions/${sessionRef.id}/messages`, {
        text,
      });
      commit(MentoringMutations.AppendSessionMessage, { sessionRef, data: response.data });
    } catch (e) {
      console.error('Error while CreateMentoringSessionMessage.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [MentoringActions.DeleteMentoringSessionMessage]({ commit, dispatch }, { sessionRef, messageRef, user }) {
    try {
      const response = await api.delete(
        `/users/${getIdOrCurrent(user)}/mentoring-sessions/${sessionRef.id}/messages/${messageRef.id}`,
      );
      commit(MentoringMutations.AppendSessionMessage, response.data);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
    } catch (e) {
      console.error('Error while DeleteMentoringSessionMessage.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [MentoringActions.CreateMentoringSessionAppointment](
    { commit, dispatch },
    { targetUserRef, sessionRef, scheduleRef, date, message, slot, userRef },
  ) {
    try {
      const response = await api.post(
        `/users/${getIdOrCurrent(userRef)}/mentoring-sessions/${sessionRef.id}/appointments`,
        {
          target: { id: targetUserRef.id },
          schedule: { id: scheduleRef.id },
          attributes: { message },
          date,
          slot,
        },
      );
      commit(MentoringMutations.AppendSessionAppointment, { sessionRef, data: response.data });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return true;
    } catch (e) {
      console.error('Error while CreateMentoringSessionAppointment.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
  async [MentoringActions.UpdateMentoringSessionAppointment](
    { commit, dispatch },
    { sessionRef, appointmentRef, data, user },
  ) {
    try {
      await api.patch(
        `/users/${getIdOrCurrent(user)}/mentoring-sessions/${sessionRef.id}/appointments/${appointmentRef.id}`,
        data,
      );
      commit(MentoringMutations.ChangeSessionAppointment, { sessionRef, appointmentRef, data });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return true;
    } catch (e) {
      console.error('Error while UpdateMentoringSessionAppointment.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
  async [MentoringActions.CreateMentoringSessionGoal]({ commit, dispatch }, { sessionRef, goal, user }) {
    try {
      const response = await api.post(`/users/${getIdOrCurrent(user)}/mentoring-sessions/${sessionRef.id}/goals`, {
        goal,
      });
      commit(MentoringMutations.AppendSessionGoal, { sessionRef, data: response.data });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return true;
    } catch (e) {
      console.error('Error while CreateMentoringSessionGoal.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
  async [MentoringActions.UpdateMentoringSessionGoal](
    { commit, dispatch },
    { sessionRef, sessionGoalRef, sessionGoal, user },
  ) {
    try {
      await api.patch(`/users/${getIdOrCurrent(user)}/mentoring-sessions/${sessionRef.id}/goals/${sessionGoalRef.id}`, {
        ...sessionGoal,
      });
      commit(MentoringMutations.ChangeSessionGoal, { sessionRef, sessionGoalRef, data: sessionGoal });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return true;
    } catch (e) {
      console.error('Error while UpdateMentoringSessionGoal.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
  async [MentoringActions.DeleteMentoringSessionGoal]({ commit, dispatch }, { sessionRef, sessionGoalRef, user }) {
    try {
      await api.delete(`/users/${getIdOrCurrent(user)}/mentoring-sessions/${sessionRef.id}/goals/${sessionGoalRef.id}`);
      commit(MentoringMutations.RemoveSessionGoal, { sessionRef, sessionGoalRef, data: { id: sessionGoalRef.id } });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return true;
    } catch (e) {
      console.error('Error while DeleteMentoringSessionGoal.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
  async [MentoringActions.CreateMentoringSessionTask](
    { commit, dispatch },
    { sessionRef, sessionGoalRef, task, user },
  ) {
    try {
      const response = await api.post(
        `/users/${getIdOrCurrent(user)}/mentoring-sessions/${sessionRef.id}/goals/${sessionGoalRef.id}/tasks`,
        {
          ...task,
        },
      );
      commit(MentoringMutations.AppendSessionGoalTask, { sessionRef, sessionGoalRef, data: response.data });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return true;
    } catch (e) {
      console.error('Error while CreateMentoringSessionTask.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
  async [MentoringActions.UpdateMentoringSessionTask](
    { commit, dispatch },
    { sessionRef, sessionGoalRef, goalTaskRef, task, user },
  ) {
    try {
      await api.patch(
        `/users/${getIdOrCurrent(user)}/mentoring-sessions/${sessionRef.id}/goals/${sessionGoalRef.id}/tasks/${
          goalTaskRef.id
        }`,
        {
          ...task,
        },
      );
      commit(MentoringMutations.ChangeSessionGoalTask, { sessionRef, sessionGoalRef, goalTaskRef, data: task });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return true;
    } catch (e) {
      console.error('Error while UpdateMentoringSessionTask.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
  async [MentoringActions.DeleteMentoringSessionTask](
    { commit, dispatch },
    { sessionRef, sessionGoalRef, goalTaskRef, user },
  ) {
    try {
      await api.delete(
        `/users/${getIdOrCurrent(user)}/mentoring-sessions/${sessionRef.id}/goals/${sessionGoalRef.id}/tasks/${
          goalTaskRef.id
        }`,
      );
      commit(MentoringMutations.RemoveSessionGoalTask, {
        sessionRef,
        sessionGoalRef,
        goalTaskRef,
        data: { id: goalTaskRef.id },
      });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return true;
    } catch (e) {
      console.error('Error while DeleteMentoringSessionTask.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
  async [MentoringActions.LoadMentoringSessionListDebug]({ dispatch }) {
    try {
      const response = await api.get(`/mentoring-sessions`);
      return response.data;
    } catch (e) {
      console.error('Error while LoadMentoringSessionListDebug.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [MentoringActions.CreateMentoringSessionDebug]({ dispatch }, { session }) {
    try {
      const response = await api.post(`/mentoring-sessions`, session);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.error('Error while CreateMentoringSessionDebug.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [MentoringActions.DeleteMentoringSessionDebug]({ dispatch }, { sessionRef }) {
    try {
      const response = await api.delete(`/mentoring-sessions/${sessionRef.id}`);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.error('Error while DeleteMentoringSessionDebug.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [MentoringActions.SendMentoringSessionAppointmentFeedback](
    { dispatch },
    { sessionRef, appointmentRef, userRef, feedback },
  ) {
    try {
      const response = await api.post(
        `/users/${getIdOrCurrent(userRef)}/mentoring-sessions/${sessionRef.id}/appointments/${
          appointmentRef.id
        }/feedback`,
        { feedback },
      );
      return response.data;
    } catch (e) {
      console.error('Error while SendMentoringSessionAppointmentFeedback.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [MentoringActions.SendMentoringSessionFeedbackAnswers](
    { dispatch },
    { sessionRef, sessionFeedbackRef, answers, userRef },
  ) {
    try {
      await api.post(
        `users/${getIdOrCurrent(userRef)}/mentoring-sessions/${sessionRef.id}/feedbacks/${
          sessionFeedbackRef.id
        }/answers`,
        answers,
      );
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
    } catch (e) {
      console.error('Error while SendMentoringSessionFeedbackAnswers.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [MentoringActions.LoadMentoringSessionFeedbackQuestionnaire]({ dispatch }, { sessionRef, userRef }) {
    try {
      const response = await api.get(
        `users/${getIdOrCurrent(userRef)}/mentoring-sessions/${sessionRef.id}/feedbacks/active`,
      );
      return response.data;
    } catch (e) {
      console.error('Error while LoadMentoringSessionFeedbackQuestionnaire.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [MentoringActions.LoadPersonalGoals]({ dispatch }, { userRef }) {
    try {
      const response = await api.get(`users/${getIdOrCurrent(userRef)}/goals`);
      return response.data;
    } catch (e) {
      console.error('Error while LoadPersonalGoals.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [MentoringActions.CreatePersonalGoal]({ dispatch }, { userRef, goal }) {
    try {
      const response = await api.post(`users/${getIdOrCurrent(userRef)}/goals`, goal);
      return response.data;
    } catch (e) {
      console.error('Error while CreatePersonalGoal.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [MentoringActions.UpdatePersonalGoal]({ dispatch }, { userRef, goalRef, update }) {
    try {
      const response = await api.put(`users/${getIdOrCurrent(userRef)}/goals/${goalRef.id}`, update);
      return response.data;
    } catch (e) {
      console.error('Error while UpdatePersonalGoal.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [MentoringActions.DeletePersonalGoal]({ dispatch }, { userRef, goalRef }) {
    try {
      await api.delete(`users/${getIdOrCurrent(userRef)}/goals/${goalRef.id}`);
      return true;
    } catch (e) {
      console.error('Error while DeletePersonalGoal.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
};
