<template>
  <v-dialog :value="value" max-width="574" sentry-tag="manage-roles-dialog" @input="$emit('input', $event)">
    <div class="m-close-icon-container">
      <a class="m-close-icon" sentry-tag="add-members-dialog.button.close" @click="closeDialog">
        <v-img contain height="24" src="@/assets/close-icon.svg" width="24" />
      </a>
    </div>
    <v-sheet class="pa-14">
      <div class="d-flex flex-column justify-center">
        <div class="m-manage-roles-dialog__header align-center pa-6">
          <h2 class="text-center">{{ $t('settings.members.manageRolesDialog.title') }}</h2>
          <p class="text-center text--secondary">
            {{ $t('settings.members.manageRolesDialog.description') }}
          </p>
        </div>
        <validation-observer v-slot="{ invalid }">
          <v-row>
            <v-col class="pa-0">
              <v-data-table
                :disable-sort="true"
                :headers="headers"
                :items="roles"
                :loading="loading"
                class="m-manage-roles-dialog__table"
                hide-default-footer
              >
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td v-if="index >= rolesLength">
                      <m-text-field
                        :id="`${index}`"
                        v-model="item.role.value"
                        :label="$t('settings.members.manageRolesDialog.role')"
                        class="ma-4"
                        validation-rules="required"
                      />
                    </td>
                    <td v-else>{{ item.role.text }}</td>
                    <td>
                      <v-simple-checkbox v-model="item.NewsAdmins" :ripple="false"></v-simple-checkbox>
                    </td>
                    <td>
                      <v-simple-checkbox v-model="item.VideosAdmins" :ripple="false"></v-simple-checkbox>
                    </td>
                    <!-- <td><v-simple-checkbox v-model="item.RitualsAdmins" :ripple="false"></v-simple-checkbox></td>
                    <td><v-simple-checkbox v-model="item.UsersAdmins" :ripple="false"></v-simple-checkbox></td> -->
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <div v-if="false" class="d-flex justify-end mt-4">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" @click="roles.push({})" v-on="on">
                  <v-icon color="primary">mdi-plus-circle-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('settings.members.manageRolesDialog.add') }}</span>
            </v-tooltip>
          </div>
          <div class="d-flex justify-space-between mt-8">
            <v-btn sentry-tag="manage-roles-dialog.button.close" @click="closeDialog">
              {{ $t('settings.members.manageRolesDialog.cancel') }}
            </v-btn>
            <v-btn
              :disabled="invalid"
              :loading="loading"
              color="primary"
              sentry-tag="manage-roles-dialog.button.save"
              @click="saveChanges"
            >
              {{ $t('settings.members.manageRolesDialog.saveChanges') }}
            </v-btn>
          </div>
        </validation-observer>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script lang="ts">
import { MTextField } from '@/components/Inputs';
import Vue from 'vue';
import { ValidationObserver } from 'vee-validate';
import { CreateRoleGroup, LoadRoleGroups, RemoveRoleGroup } from '@/store/users';
import { detailedDiff } from 'deep-object-diff';

export default Vue.extend({
  name: 'm-manage-roles-dialog',
  props: {
    value: Boolean,
  },
  components: {
    ValidationObserver,
    MTextField,
  },
  data: () => ({
    loading: false,
    rolesLength: 1,
    roles: [],
    initialRoles: [],
  }),
  computed: {
    headers() {
      return [
        { text: this.$t('settings.members.manageRolesDialog.headers.role'), value: 'role' },
        { text: this.$t('settings.members.manageRolesDialog.headers.news'), value: 'news' },
        { text: this.$t('settings.members.manageRolesDialog.headers.videos'), value: 'videos' },
        // { text: this.$t('settings.members.manageRolesDialog.rituals'), value: 'rituals' },
        // { text: this.$t('settings.members.manageRolesDialog.users'), value: 'users' },
      ];
    },
  },
  watch: {
    async value(value: boolean) {
      if (value) {
        await this.loadGroups();
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit('input', false);
    },
    async saveChanges() {
      this.loading = true;
      try {
        const diff = detailedDiff(this.initialRoles, this.roles);
        for (const [index, val] of this.roles.entries()) {
          const { role } = val;
          const added = diff.added[index.toString()];
          const removed = diff.updated[index.toString()];
          if (added) {
            for (const key of Object.keys(added)) {
              await this.$store.dispatch(new CreateRoleGroup(role.value, key));
            }
          }
          if (removed) {
            for (const key of Object.keys(removed)) {
              await this.$store.dispatch(new RemoveRoleGroup(role.value, key));
            }
          }
        }
      } finally {
        this.loading = false;
      }
      this.closeDialog();
    },
    async loadGroups() {
      try {
        this.loading = true;

        const groups = (await this.$store.dispatch(new LoadRoleGroups('*'))).reduce((obj, str) => {
          obj[str] = true;
          return obj;
        }, {});
        this.roles = [
          {
            role: { text: this.$t('settings.members.manageRolesDialog.roles.anybody'), value: '*' },
            ...groups,
          },
        ];
        this.initialRoles = [...JSON.parse(JSON.stringify(this.roles))];
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.close-icon {
  position: absolute;
  top: 18px;
  right: 18px;
}

.m-close-icon-container {
  display: flex;
  justify-content: flex-end;
}

.m-close-icon {
  position: absolute;
  margin: 18px;
}

.m-manage-roles-dialog {
  background-color: var(--v-white-base);

  &__header {
    border-bottom: 1px solid var(--v-lightgray-base);
  }

  &__table {
    border-radius: unset;
    border-bottom: 1px solid var(--v-lightgray-base);
  }

  &__table-pagination:deep(.v-pagination__navigation, .v-pagination__item) {
    box-shadow: none;
  }
}
</style>
