import { TenantMutations, TenantState } from '@/store/tenant/types';
import { MutationTree } from 'vuex';
import { Features, IIntegration, ITenant, ProductConfig, TenantAttributes } from '@mentessa/types';
import { ensurePath } from '@/utils/object';
import deepmerge from 'deepmerge';

export interface SetFeatureEnabledStatePayload {
  feature: Features;
  state: boolean;
}

export const mutations: MutationTree<TenantState> = {
  [TenantMutations.SetTenant](state: TenantState, tenant: ITenant) {
    state.tenant = tenant;
  },

  [TenantMutations.SetIntegration](state: TenantState, integration: IIntegration) {
    state.tenant.integrations ??= [];
    state.tenant.integrations.push(integration);
  },

  [TenantMutations.UpdateIntegration](state: TenantState, integration: IIntegration) {
    const index = state.tenant.integrations.findIndex((i) => i.id === integration.id);
    const oldIntegration = state.tenant.integrations.splice(index, 1);
    state.tenant.integrations.push({ ...oldIntegration[0], ...integration });
  },

  [TenantMutations.RemoveIntegration](state: TenantState, integration: IIntegration) {
    state.tenant.integrations = state.tenant.integrations.filter((i) => i.id !== integration.id);
  },

  [TenantMutations.SetTenantImage](state: TenantState, image: string) {
    if (state.tenant?.attributes?.theme) {
      state.tenant.attributes.theme.image = image;
    }
  },

  [TenantMutations.SetTenantAttributes](state: TenantState, attributes: TenantAttributes) {
    if (state.tenant) {
      state.tenant.attributes = attributes;
    }
  },

  [TenantMutations.SetTenantConfig](state: TenantState, config: Partial<ProductConfig>) {
    if (state.tenant) {
      state.tenant.config = deepmerge(state.tenant.config, config, { arrayMerge: <T>(dst: T, src: T) => src });
    }
  },

  [TenantMutations.SetAvailableTenants](state: TenantState, tenants: ITenant[]) {
    state.tenants = tenants;
  },

  [TenantMutations.AppendAvailableTenant](state: TenantState, tenant: ITenant) {
    state.tenants.push(tenant);
  },

  [TenantMutations.SetEmailRewriteEnabled](state: TenantState, enabled: boolean) {
    ensurePath(state.tenant.config, ['features', 'email', 'to', 'rewrite']);
    state.tenant.config.features.email.to.rewrite.enabled = enabled;
  },

  [TenantMutations.SetEmailRewriteEmail](state: TenantState, email: string) {
    ensurePath(state.tenant.config, ['features', 'email', 'to', 'rewrite']);
    state.tenant.config.features.email.to.rewrite.email = email;
  },

  [TenantMutations.SetFeatureEnabledState](state: TenantState, payload: SetFeatureEnabledStatePayload) {
    if (state.tenant.config.features[payload.feature] == null) {
      state.tenant.config.features[payload.feature] = { enabled: payload.state };
    } else {
      state.tenant.config.features[payload.feature].enabled = payload.state;
    }
  },
};
