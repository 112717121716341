<template>
  <v-dialog
    v-model="isDialogOpen"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    sentry-tag="m-add-task-dialog"
    width="unset"
  >
    <v-card class="user-book-container pa-4">
      <div class="d-flex justify-end">
        <a sentry-tag="m-add-task-dialog.button.close" @click="$emit('input', false)">
          <v-img contain height="24" src="@/assets/close-icon.svg" width="24" />
        </a>
      </div>
      <v-card-title>
        {{ $t('mentoring.tasks.dialogs.add.title') }}
      </v-card-title>
      <v-card-text>
        <m-text-field
          ref="taskField"
          v-model="newTask.text"
          :disabled="loading"
          autofocus
          @keydown.enter.exact.prevent="createTask"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn :loading="loading" @click="createTask" color="button" depressed>
          {{ $t('mentoring.tasks.dialogs.add.create') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IMentoringSession, IMentoringSessionGoal, ITenant } from '@mentessa/types';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';
import MTextField from '@/components/Inputs/MTextField/MTextField.vue';
import { CreateMentoringSessionTask } from '@/store/mentoring';

export default Vue.extend({
  name: 'm-add-task-dialog',
  components: { MTextField },
  props: {
    value: { type: Boolean, default: false },
    mentoringSession: { type: Object as PropType<IMentoringSession> },
    mentoringGoal: { type: Object as PropType<IMentoringSessionGoal> },
  },
  data: () => ({
    loading: false,
    newTask: {
      text: '',
    },
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState): ITenant => state.tenant,
    }),
    isDialogOpen: {
      get() {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    async createTask() {
      this.loading = true;
      try {
        const success = await this.$store.dispatch(
          new CreateMentoringSessionTask(this.mentoringSession, this.mentoringGoal, this.newTask),
        );
        if (success) {
          this.newTask = { text: '' };
          this.isDialogOpen = false;
        }
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.user-book-container {
  width: 400px;
  //background-color: var(--v-background-base);
  //min-height: 80%;
  //height: 100%;
  //width: 100%;
  //display: flex;
  //flex-direction: column;
  //overflow: auto;
}
</style>
