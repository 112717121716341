<template>
  <div class="d-flex justify-center align-center flex-column">
    <div class="m-color-preview" :style="style" @click="toggleColorPickerDialog" />
    <div class="text-caption text--text text--lighten-4 mt-3">{{ value }}</div>

    <v-dialog v-model="isColorPickerDialogOpen" content-class="m-color-picker-dialog" width="unset">
      <v-color-picker v-model="localColor" dot-size="25" mode="hexa" swatches-max-height="200" />
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'm-form-color-step',
  props: {
    value: String,
  },
  data: () => ({
    isColorPickerDialogOpen: false,
  }),
  computed: {
    localColor: {
      get() {
        return this.value;
      },
      set(value: string) {
        this.$emit('input', value);
      },
    },
    style() {
      return {
        '--background-color': this.value,
      };
    },
  },
  methods: {
    doLater() {
      this.$emit('input', undefined);
      this.$emit('onSubmit');
    },
    submitStep(): void {
      this.$emit('onSubmit');
    },
    toggleColorPickerDialog() {
      this.isColorPickerDialogOpen = !this.isColorPickerDialogOpen;
    },
  },
});
</script>

<style lang="scss" scoped>
.m-color-preview {
  width: 200px;
  height: 200px;
  border-radius: 7px;
  background-color: var(--background-color);
  cursor: pointer;
}
</style>
