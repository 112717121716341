import { RouteName } from '@/router/types';
import {
  MSettingsDebugCasbin,
  MSettingsDebugEditTenant,
  MSettingsDebugEmail,
  MSettingsDebugEvents,
  MSettingsDebugHubSpot,
  MSettingsDebugKeycloak,
  MSettingsDebugMentoring,
  MSettingsDebugNewTenant,
  MSettingsDebugQuestionnaires,
  MSettingsDebugRitualTemplates,
  MSettingsDebugSearch,
  MSettingsDebugTokens,
  MSettingsDebugTranslations,
  MSettingsDebugUsers,
  MSettingsNps,
} from '@/components/Settings/MSettingsDebug/forms';

const settingsDebugRoutes = [
  {
    path: 'mentoring',
    name: RouteName.SettingsDebugMentoring,
    component: MSettingsDebugMentoring,
  },
  {
    path: 'tokens',
    name: RouteName.SettingsDebugTokens,
    component: MSettingsDebugTokens,
  },
  {
    path: 'users',
    name: RouteName.SettingsDebugUsers,
    component: MSettingsDebugUsers,
  },
  {
    path: 'email',
    name: RouteName.SettingsDebugEmail,
    component: MSettingsDebugEmail,
  },
  {
    path: 'search',
    name: RouteName.SettingsDebugSearch,
    component: MSettingsDebugSearch,
  },
  {
    path: 'hubspot',
    name: RouteName.SettingsDebugHubSpot,
    component: MSettingsDebugHubSpot,
  },
  {
    path: 'new_tenant',
    name: RouteName.SettingsDebugNewTenant,
    component: MSettingsDebugNewTenant,
  },
  {
    path: 'edit_tenant',
    name: RouteName.SettingsDebugEditTenant,
    component: MSettingsDebugEditTenant,
  },
  {
    path: 'keycloak',
    name: RouteName.SettingsDebugKeycloak,
    component: MSettingsDebugKeycloak,
  },
  {
    path: 'events',
    name: RouteName.SettingsDebugEvents,
    component: MSettingsDebugEvents,
  },
  {
    path: 'casbin',
    name: RouteName.SettingsDebugCasbin,
    component: MSettingsDebugCasbin,
  },
  {
    path: 'questionnaires',
    name: RouteName.SettingsDebugQuestionnaires,
    component: MSettingsDebugQuestionnaires,
  },
  {
    path: 'translations',
    name: RouteName.SettingsDebugTranslations,
    component: MSettingsDebugTranslations,
  },
  {
    path: 'nps',
    name: RouteName.SettingsDebugNps,
    component: MSettingsNps,
  },
  {
    path: 'ritual-templates',
    name: RouteName.SettingsDebugRitualTemplates,
    component: MSettingsDebugRitualTemplates,
  },
];

export { settingsDebugRoutes };
export default settingsDebugRoutes;
