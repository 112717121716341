<template>
  <v-app>
    <v-main>
      <v-container class="d-flex flex-column justify-center align-center fill-height m-no-access" fluid>
        <div class="d-flex flex-column justify-center align-center" style="width: 600px">
          <v-img :src="image" contain height="128px" width="128px" />
          <h3 class="text-center my-4">
            {{ title }}
          </h3>
          <div v-html="content" />
          <v-btn class="mt-4" color="button" depressed style="min-width: 160px" @click="logout">
            {{ $t('no_access.logout') }}
          </v-btn>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapState } from 'vuex';
import { LoadTenant, TenantState } from '@/store/tenant';
import { LoadTranslations } from '@/store/ui';

export default Vue.extend({
  name: 'no-access-app',
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    tenantTitle() {
      return this.tenant?.attributes?.theme?.title ?? '';
    },
    image() {
      if (this.tenant?.attributes.theme.image) {
        return this.tenant?.attributes.theme.image;
      }
      return require('@/assets/logo.svg');
    },
    email() {
      return `${this.$keycloak.userName}`;
    },
    reason() {
      return 'unknown';
    },
    title() {
      return this.$t(`no_access.title.${this.reason}`, { email: this.email, tenant: this.tenantTitle });
    },
    content() {
      return this.$marked(
        this.$t(`no_access.description.${this.reason}`, { email: this.email, tenant: this.tenantTitle }),
      );
    },
  },
  async created() {
    if (!this.$keycloak.authenticated) {
      await this.$keycloak.loginFn({ redirectUri: window.location.origin });
    }
    await this.$store.dispatch(new LoadTenant());
    await this.$store.dispatch(new LoadTranslations(this.$i18n.locale));
  },
  methods: {
    logout() {
      this.$keycloak.logoutFn();
    },
  },
});
</script>

<style lang="scss" scoped>
.m-no-access {
  background: var(--v-background-base);

  & > div {
    padding: 40px;
    max-width: 462px;
    border-radius: 6px;
    background: var(--v-white-base);
    box-shadow: 0 4px 12px 6px rgba(0, 0, 0, 0.06);
  }

  &__logout {
    position: absolute;
    top: 16px;
    right: 16px;

    :deep(.v-btn) {
      position: relative;
      bottom: 1px;
      margin-left: 4px;
    }
  }
}
</style>
