import { moduleName, TenantState } from './types';
import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';

export * from './types';

export function tenantGetter(getter: string) {
  return `${moduleName}/${getter}`;
}

export const initialState: TenantState = {
  tenant: undefined,
  tenants: [],
};

export const tenantModule: Module<TenantState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

export default tenantModule;
