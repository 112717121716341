import { Module } from 'vuex';
import { RootState } from '@/store/types';
// import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { NotificationsState } from './types';

export * from './types';

export const initialState: NotificationsState = {
  notifications: [],
  maxMessages: 10,
  notificationDialog: {
    message: '',
    isOpened: false,
  },
  persistentNotification: {
    title: '',
    text: '',
    isOpened: false,
  },
};

export const notificationsModule: Module<NotificationsState, RootState> = {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
};

export default notificationsModule;
