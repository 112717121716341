<template>
  <div>
    <m-app-bar-menu v-if="hasManyTenants" id="menu-tenant" block class="px-0">
      <template v-slot:activator>
        <h3>{{ tenantName }}</h3>
      </template>
      <template v-slot:items>
        <v-list class="pt-0 pb-0">
          <v-list-item v-if="showSearch" class="ma-0 pa-0" @click.stop>
            <m-search-field v-model="filter" autofocus />
          </v-list-item>
          <v-list-item
            v-for="(tenant, index) in filteredTenants"
            :key="`at-item-${index}`"
            class="pl-4"
            sentry-tag="app-bar-menu.list-item.tenants"
            @click="goToTenant(tenant)"
          >
            <m-tenant-preview :tenant="tenant" />
          </v-list-item>
        </v-list>
      </template>
    </m-app-bar-menu>
    <h3 v-else>{{ tenantName }}</h3>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';
import MTenantPreview from '@/components/MTenantPreview';
import { ITenant, TenantEntity } from '@mentessa/types';
import MAppBarMenu from './AppBarMenu.vue';
import { MSearchField } from '@/components/Inputs';

export default Vue.extend({
  name: 'm-app-bar-menu-tenant',
  components: {
    MSearchField,
    MAppBarMenu,
    MTenantPreview,
  },
  props: {
    mini: { type: Boolean, default: false },
  },
  data: () => ({
    filter: '',
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
      availableTenants: (state: TenantState) => state.tenants,
    }),
    filteredTenants() {
      if (!this.filter?.length) {
        return this.availableTenants;
      }

      const lowercase = this.filter.toLowerCase();

      return this.availableTenants.filter(
        (tenant: ITenant) =>
          tenant.domain.includes(lowercase) || tenant.attributes.theme?.title?.toLowerCase().includes(lowercase),
      );
    },
    showSearch() {
      return this.availableTenants?.length > 6;
    },
    tenantName() {
      return this.tenant?.attributes?.theme?.title ?? '';
    },
    hasManyTenants() {
      return this.availableTenants?.length > 1;
    },
  },
  methods: {
    goToTenant(tenant: TenantEntity) {
      if (tenant.domain.includes('.')) {
        window.location.host = tenant.domain;
      } else {
        const splitHost = window.location.host.split('.');
        splitHost[0] = tenant.domain;
        window.location.host = splitHost.join('.');
      }
    },
  },
});
</script>
