<template>
  <ais-instant-search :index-name="index" :middlewares="middlewares" :search-client="searchClient">
    <slot />
  </ais-instant-search>
</template>

<script lang="ts">
import Vue from 'vue';
import TypesenseInstantSearchAdapter from './typesense-instantsearch-adapter';
import { mapState } from 'vuex';
import { UsersState } from '@/store/users';
import { TenantState } from '@/store/tenant';
import { ChangeActiveFacets } from '@/store/search';
import { InstantSearch } from 'instantsearch.js';

export default Vue.extend({
  name: 'm-ais-wrapper',
  props: {
    index: { type: String, default: undefined },
    queryFields: { type: String },
    syncStore: { type: Boolean, default: false },
  },
  computed: {
    ...mapState<UsersState>('users', {
      sortingSequence: (state: UsersState) => state.sortingSequence,
    }),
    ...mapState<TenantState>('tenant', {
      customFields: (state: TenantState) => state.tenant.attributes.customFields,
    }),
    searchClient() {
      return new TypesenseInstantSearchAdapter({
        // cacheSearchResultsForSeconds: 2 * 60,
        additionalSearchParameters: {
          query_by: this.localQueryFields,
          sort_by: this.sortingSequence,
        },
      }).searchClient;
    },
    tenantBasedQueryFields() {
      const tags = ['firstName', 'lastName', 'location', 'iTags', 'eTags', 'roles', 'company'];
      const customTags = this.customFields?.filter((field) => field.showInFilters).map((field) => field.name);
      if (customTags?.length > 0) {
        tags.push(...customTags);
      }
      return tags.join(',');
    },
    localQueryFields() {
      return this.queryFields ?? this.tenantBasedQueryFields;
    },
    middlewares() {
      if (!this.syncStore) {
        return [];
      }
      return [this.refinementControlMiddleware];
    },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    refinementControlMiddleware({ instantSearchInstance }: { instantSearchInstance: InstantSearch }) {
      const dispatch = this.$store.dispatch;
      return {
        onStateChange({ uiState }) {
          dispatch(new ChangeActiveFacets(uiState?.users?.refinementList));
        },
      };
    },
  },
});
</script>
