<template>
  <m-ais-wrapper index="users" queryFields="iTags,eTags" sentry-tag="dashboard-grid">
    <m-headline :description="$t('dashboard.tabs.community.description')" />
    <div class="grid">
      <div class="gutter-sizer"></div>
      <div class="grid-sizer"></div>
      <component
        :is="widget.component"
        v-for="(widget, index) in widgets"
        :key="`widget-${index}`"
        :title="widget.title"
        :value="widget.value"
        :size="widget.size"
      />
    </div>
  </m-ais-wrapper>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { LoadStats, StatsPeriodOptions, StatsPeriodType, StatsState } from '@/store/stats';
import {
  MDashboardWidgetCloudTags,
  MDashboardWidgetList,
  MDashboardWidgetListTags,
  MDashboardWidgetSingle,
} from '../widgets';
import Packery from 'packery';
import Draggabilly from 'draggabilly';
import { mapState } from 'vuex';
import MAisWrapper from '@/components/MAIS/AISWrapper/AISWrapper.vue';
import MHeadline from '@/components/MHeadline';

export default Vue.extend({
  name: 'm-legacy-dashboard',
  components: {
    MHeadline,
    MAisWrapper,
    MDashboardWidgetSingle,
    MDashboardWidgetList,
    MDashboardWidgetListTags,
    MDashboardWidgetCloudTags,
  },
  props: {
    value: {
      type: Object as PropType<StatsPeriodOptions>,
      default: (): StatsPeriodOptions => ({
        type: StatsPeriodType.Week,
        amount: 1,
        custom: undefined,
      }),
    },
  },
  data: () => ({
    packery: undefined,
  }),
  computed: {
    ...mapState<StatsState>('stats', {
      stats: (state: StatsState) => state.stats,
    }),
    widgets() {
      return [
        {
          component: 'MDashboardWidgetSingle',
          value: this.stats.users?.total ?? 0,
          title: this.$t('dashboard.widgets.members.total'),
        },
        {
          component: 'MDashboardWidgetSingle',
          value: this.stats.users?.pending ?? 0,
          title: this.$t('dashboard.widgets.members.pending'),
        },
        {
          component: 'MDashboardWidgetSingle',
          value: this.stats.users?.completed ?? 0,
          title: this.$t('dashboard.widgets.members.active'),
        },
        {
          component: 'MDashboardWidgetSingle',
          value: this.stats.mentoring?.available ?? 0,
          title: this.$t('dashboard.widgets.mentoring.available'),
        },
        {
          component: 'MDashboardWidgetSingle',
          value: this.stats.mentoring?.share ?? 0,
          title: this.$t('dashboard.widgets.mentoring.share'),
        },

        {
          component: 'MDashboardWidgetSingle',
          value: this.stats.matching?.total ?? 0,
          title: this.$t('dashboard.widgets.matching.total'),
        },
        {
          component: 'MDashboardWidgetSingle',
          value: this.stats.matching?.auto ?? 0,
          title: this.$t('dashboard.widgets.matching.available'),
        },
        {
          component: 'MDashboardWidgetSingle',
          value: this.stats.matching?.share ?? 0,
          title: this.$t('dashboard.widgets.matching.share'),
        },
        {
          component: 'MDashboardWidgetListTags',
          value: 'iTags',
          title: this.$t('dashboard.widgets.tags.interests'),
        },
        {
          component: 'MDashboardWidgetListTags',
          value: 'eTags',
          title: this.$t('dashboard.widgets.tags.expertise'),
        },
        {
          component: 'MDashboardWidgetCloudTags',
          value: 'iTags',
          title: this.$t('dashboard.widgets.tags.interests'),
          size: 'x2',
        },
        {
          component: 'MDashboardWidgetCloudTags',
          value: 'eTags',
          title: this.$t('dashboard.widgets.tags.expertise'),
          size: 'x2',
        },
      ];
    },
  },
  async mounted() {
    const packery = new Packery('.grid', {
      columnWidth: '.grid-sizer',
      itemSelector: '.grid-item',
      gutter: '.gutter-sizer',
      percentPosition: true,
    });

    packery.getItemElements().forEach(function (itemElem) {
      packery.bindDraggabillyEvents(new Draggabilly(itemElem));
    });

    this.packery = packery;

    await this.$store.dispatch(new LoadStats());
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.grid {
  max-width: 1200px;
}

/* clear fix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}

.grid-sizer,
.grid-item {
  width: calc(25% - 16px);
}

.gutter-sizer {
  width: 16px;
}

.grid-item {
  float: left;
  //width: calc(25% - 16px);

  &.is-dragging,
  &.is-positioning-post-drag {
    z-index: 2;
  }

  &__x2 {
    width: calc(50% - 16px);
  }
}

.grid-item--large {
  z-index: 2;
  width: calc(100% - 16px);
  height: 100%;
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .grid-sizer,
  .grid-item {
    width: calc(100% - 16px);
  }
}

@media #{map-get($display-breakpoints, 'sm-only')} {
  .grid-sizer,
  .grid-item {
    width: calc(50% - 16px);
  }
}
</style>
