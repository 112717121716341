<template>
  <validation-provider
    v-slot="{ errors }"
    :debounce="validationDebounce"
    :rules="validationRules"
    class="m-scale-container"
  >
    <v-slider
      class="m-scale-item"
      :value="localValue"
      :error-messages="errors"
      :tick-labels="tickLabels"
      sentry-tag="select"
      ticks="always"
      v-bind="{ ...$props, ...$attrs }"
      :color="isTouched ? 'primary' : 'var(--v-text-lighten2)'"
      :track-color="isTouched ? 'var(--v-primary-lighten5)' : 'var(--v-lightgray-darken2)'"
      :track-fill-color="isTouched ? 'primary' : 'var(--v-text-lighten3)'"
      @change="onChange($event)"
    >
    </v-slider>
    <div class="d-flex justify-space-between mt-4">
      <span>{{ minDescription }}</span>
      <span>{{ maxDescription }}</span>
    </div>
  </validation-provider>
</template>

<script lang="ts">
import { max, required } from 'vee-validate/dist/rules';
import { extend, setInteractionMode, ValidationProvider } from 'vee-validate';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'm-scale',
  components: {
    ValidationProvider,
  },
  props: {
    value: { type: Number },
    defaultValue: { type: Number, default: 3 },
    boundaries: { type: Array as PropType<Array<string>>, default: () => ['', ''] },
    min: { type: Number, default: 1 },
    max: { type: Number, default: 5 },
    outlined: { type: Boolean, default: true },
    hideDetails: { type: String, default: 'auto' },
    validationRules: String,
    validationDebounce: Number,
    validationMode: { type: String, default: 'aggressive' },
    requiredSuffix: { type: String, default: ' *' },
    i18nPath: { type: String },
  },
  computed: {
    isTouched() {
      return this.value != null;
    },
    localValue() {
      return this.value || this.defaultValue;
    },
    isRequired() {
      return this.validationRules?.includes('required') ?? false;
    },
    tickLabels() {
      return Array.from({ length: this.max - this.min + 1 }, (_, i) => i + this.min);
    },
    minDescription() {
      return this.boundaries?.[0];
    },
    maxDescription() {
      return this.boundaries?.[1];
    },
  },
  watch: {
    validationMode(mode: string) {
      setInteractionMode(mode);
    },
  },
  created() {
    setInteractionMode(this.validationMode);

    extend('required', {
      ...required,
      message: this.$t('validation.required'),
    } as never);

    extend('max', {
      ...max,
      message: this.$t('validation.max'),
    } as never);
  },

  methods: {
    onChange(value) {
      this.$emit('input', value);
    },
  },
});
</script>

<style lang="scss" scoped>
.no_wrap {
  white-space: nowrap;
}

.m-scale-container {
  display: flex;
  flex-direction: column;
  max-width: 360px;
  min-width: 360px;
}

.m-scale-item {
  :deep(.v-slider__thumb) {
    outline: 2px solid var(--v-white-base);
  }
}
</style>
