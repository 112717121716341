<template>
  <div>
    <m-loader v-if="loading" :is-loading="loading" />
    <m-dashboard v-else :dashboard-settings-id="dashboardId" class="mt-4" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapState } from 'vuex';
import { MatchingState } from '@/store/matching';
import { RitualsState } from '@/store/rituals';
import { IRitual } from '@mentessa/types';
import MLoader from '@/components/MLoader.vue';
import { LoadRitualDashboard } from '@/store/dashboards';
import { MDashboard } from '@/components/Dashboard/MDashboard';

export default Vue.extend({
  name: 'm-ritual-settings',
  components: {
    MDashboard,
    MLoader,
  },
  data: () => ({
    dashboardId: undefined as number,
    loading: true,
  }),
  computed: {
    ...mapState<MatchingState>('rituals', {
      ritualsPage: (state: RitualsState) => {
        return state.rituals;
      },
      ritual: (state: RitualsState) => state.current,
    }),
    rituals(): Array<IRitual> {
      return this.ritualsPage.items ?? [];
    },
  },
  watch: {
    async ritual() {
      await this.loadRitual();
    },
  },
  async mounted() {
    await this.loadRitual();
  },
  methods: {
    async loadRitual() {
      if (!this.ritual) {
        return;
      }
      this.loading = true;

      try {
        const dashboards = await this.$store.dispatch(new LoadRitualDashboard(this.ritual));
        if (dashboards.items?.length > 0) {
          this.dashboardId = dashboards.items[0].id;
        }
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
