<template>
  <div>
    <m-settings-panel :description="$t('settings.mobile.code.description')" :title="$t('settings.mobile.code.title')">
      <m-text-field v-model="localCompanyCode.code" :label="$t('settings.mobile.code.title')" />
      <v-btn color="button" :disabled="!hasChanges" @click="save" :loading="loading">{{ $t('actions.save') }}</v-btn>
    </m-settings-panel>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { UsersState } from '@/store/users';
import { MTextField } from '@/components/Inputs';
import MSettingsPanel from '@/components/Settings/MSettingsPanel';
import { CreateCompanyCode, LoadCompanyCode, MobileState, UpdateCompanyCode } from '@/store/mobile';

export default Vue.extend({
  name: 'm-mobile-settings',
  components: {
    MSettingsPanel,
    MTextField,
  },
  data: () => ({
    loading: false,
    localCompanyCode: { code: '' } as { id: number; code: string },
  }),
  computed: {
    ...mapGetters('tenant', {
      isDebugAvailable: 'isDebugAvailable',
    }),
    ...mapState<UsersState>('mobile', {
      companyCode: (state: MobileState) => state.companyCode,
    }),
    hasChanges() {
      return this.localCompanyCode?.code?.length > 0 && this.localCompanyCode?.code !== this.companyCode?.code;
    },
  },
  watch: {
    companyCode(code: { id: number; code: string }) {
      if (code) {
        this.localCompanyCode = { ...code };
      } else {
        this.localCompanyCode = { code: undefined };
      }
    },
  },

  async mounted() {
    await this.loadCompanyCode();
  },
  methods: {
    async loadCompanyCode() {
      this.loading = true;
      try {
        await this.$store.dispatch(new LoadCompanyCode());
      } finally {
        this.loading = false;
      }
    },
    async save() {
      if (!this.localCompanyCode.code) {
        return;
      }
      this.loading = true;
      try {
        if (this.localCompanyCode.id) {
          await this.$store.dispatch(new UpdateCompanyCode(this.localCompanyCode, this.localCompanyCode.code));
        } else {
          await this.$store.dispatch(new CreateCompanyCode(this.localCompanyCode.code));
        }
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
//
</style>
