<template>
  <div class="fill-height white-box d-flex flex-column">
    <p class="mb-4 font-weight-bold">
      {{ $t('userProfileModal.requestMatchWith', { name: firstName }) }}
    </p>
    <v-divider />
    <p class="mt-6 mb-0">
      {{ $t('userProfileModal.requestMatchPurpose') }}
    </p>
    <m-chip-group class="mt-4" v-model="selectedPurposes" :items="purposes" i18n-path="userProfileModal.purpose" />
    <p class="mt-4">{{ $t('userProfileModal.requestMatchAdvice', { name: firstName }) }}</p>
    <m-text-area
      v-model="expectations"
      :label="$t('userProfileModal.expectationsLabel', { name: firstName })"
      class="mb-4"
    />
    <v-btn :loading="loading" class="mb-4" color="button" depressed large @click="sendRequest" style="float: left">
      {{ $t('userProfileModal.requestMatching') }}
    </v-btn>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { MTextArea } from '@/components/Inputs';
import { UserEntity } from '@mentessa/types';
import { RequestMatch } from '@/store/matching';
import { getFirstName } from '@/utils/user';
import MChipGroup from '@/components/Inputs/MChipGroup/MChipGroup.vue';

export default Vue.extend({
  name: 'm-match-request-form',
  props: {
    user: Object as PropType<UserEntity>,
  },
  components: {
    MChipGroup,
    MTextArea,
  },
  data: () => ({
    expectations: undefined,
    loading: false,
    purposes: [
      'mentoring',
      'feedback',
      'collaboration',
      'brainstorming',
      'workshop',
      'podcast',
      'shadowing',
      'coffee',
      'else',
    ],
    selectedPurposes: [0],
  }),
  computed: {
    firstName() {
      return getFirstName(this.user);
    },
  },
  methods: {
    async sendRequest() {
      try {
        await this.$store.dispatch(
          new RequestMatch(
            this.user,
            this.expectations,
            this.selectedPurposes?.map((purpose) => this.purposes[purpose]),
          ),
        );
        this.expectations = undefined;
        this.$emit('onSent');
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
