<template>
  <v-card class="pa-8 d-flex flex-column" sentry-tag="m-ritual-card" width="325">
    <div v-if="manage" class="ml-auto m-ritual-status-line">
      <v-chip v-if="enabled && isRitualsManagingAvailable" color="primary" label small>
        {{ $t('settings.rituals.active') }}
      </v-chip>
      <v-menu bottom min-width="128px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item :to="manageRoute" class="text--darken-3" link>
            <v-list-item-title>
              {{ $t(`settings.rituals.manage`) }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="false" class="text--darken-3" link>
            <v-list-item-title>
              {{ $t(`settings.rituals.archive`) }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-row class="flex-grow-0 my-4 m-ritual-logo" justify="center">
      <m-ritual-icon :ritual="value" big />
    </v-row>

    <v-card-title class="justify-center">
      <p class="primary--text">{{ title }}</p>
    </v-card-title>
    <v-card-text class="text-center flex-grow-1">
      <p v-snip="{ lines: isTextHidden ? 3 : 0, onSnipped }" class="keep-new-line">{{ description }}</p>
      <v-btn
        v-if="hasEllipsis || !isTextHidden"
        class="font-weight-bold mt-n4"
        color="primary"
        text
        @click="isTextHidden = !isTextHidden"
      >
        {{ $t(isTextHidden ? 'actions.show.more' : 'actions.show.less') }}
      </v-btn>
    </v-card-text>
    <v-card-actions class="mt-4 justify-end">
      <v-row class="gap-4" justify="center">
        <v-btn v-if="enabled" :outlined="enrolled" color="button" depressed min-width="200" @click="doAction">
          {{ cta }}
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IRitual, RitualType, RitualUserStatus } from '@mentessa/types';
import { RouteName } from '@/router/types';
import { UnenrollFromRitual } from '@/store/rituals';
import MRitualIcon from '@/components/MRitualIcon/MRitualIcon.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'm-ritual-card',
  components: { MRitualIcon },
  props: {
    value: Object as PropType<IRitual>,
    manage: { type: Boolean, default: false },
  },
  data: () => ({
    isTextHidden: true,
    hasEllipsis: false,
  }),
  computed: {
    ...mapGetters('tenant', {
      isRitualsManagingAvailable: 'isRitualsManagingAvailable',
    }),
    title() {
      return this.value.attributes.title ?? this.$t(`settings.rituals.knownNames.${this.value.type}`);
    },
    description() {
      return (
        this.value.attributes.description ?? this.$t(`settings.rituals.knownDescriptions.${this.value.type}`).toString()
      );
    },
    manageRoute() {
      return {
        name: this.isRitualsManagingAvailable ? RouteName.RitualManage : RouteName.RitualManageMembers,
        params: { ritualId: this.value.id },
      };
    },
    enrollRoute() {
      switch (this.value?.type as RitualType) {
        case RitualType.HappyHour:
          return { name: RouteName.CommunityEnroll, query: { ritualId: this.value.id } };
        case RitualType.EventMatching:
        case RitualType.SkillsMatching:
          return { name: RouteName.RitualEnroll, params: { ritualId: this.value.id } };
      }
      return undefined;
    },
    enrolled() {
      return this.value?.members?.[0] && this.value.members[0].status !== RitualUserStatus.Invited;
    },
    cta() {
      if (this.enrolled) {
        return this.$t('ritualShop.unenroll');
      } else {
        return this.$t('ritualShop.enroll');
      }
    },
    enabled() {
      return this.value?.enabled ?? false;
    },
  },
  methods: {
    onSnipped(props) {
      this.hasEllipsis = props.hasEllipsis;
    },
    async doAction() {
      if (this.enrolled) {
        await this.$store.dispatch(new UnenrollFromRitual(this.value));
      } else {
        await this.$router.push(this.enrollRoute);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.m-ritual-status-line {
  min-height: 24px;
}

.m-ritual-logo {
  min-height: 54px;
}
</style>
