<template>
  <div>
    <m-headline :description="$t('mentoring.description')" :title="$t('mentoring.title')" />
    <template v-if="sessions.length > 0">
      <router-view :sessionsStatus="sessionsStatusFromRoute" />
    </template>
    <template v-else>
      <m-empty-community v-if="isCommunityEmpty && isInvitesAvailable" />
      <m-empty-state
        v-else-if="loading === false"
        :cta="$t('mentoring.empty.cta')"
        :description="$t('mentoring.empty.description')"
        :image="require('@/assets/empty/matching.svg')"
        :title="$t('mentoring.empty.title')"
        @onButtonClick="goToCommunity"
      />
    </template>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { RouteName } from '@/router/types';
import { LoadMentoringSessionList } from '@/store/mentoring';
import MHeadline from '@/components/MHeadline';
import MEmptyCommunity from '@/components/MEmptyCommunity';
import MEmptyState from '@/components/MEmptyState';
import { MentoringSessionStatus } from '@mentessa/types';

export default Vue.extend({
  name: 'm-mentoring',
  components: { MEmptyState, MEmptyCommunity, MHeadline },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters('tenant', {
      isInvitesAvailable: 'isInvitesAvailable',
      isCommunityEmpty: 'isCommunityEmpty',
    }),
    ...mapGetters('mentoring', {
      allSessions: 'allSessions',
      sessionsByStatus: 'sessionsByStatus',
    }),
    sessionsStatusFromRoute() {
      if (
        [
          RouteName.ActiveMentoringSession,
          RouteName.ActiveMentoring,
          RouteName.MentoringSessionAppointment,
          RouteName.MentoringSessionFeedback,
        ].includes(this.$route.name)
      ) {
        return MentoringSessionStatus.Progress;
      } else if ([RouteName.ClosedMentoringSession, RouteName.ClosedMentoring].includes(this.$route.name)) {
        return MentoringSessionStatus.Finished;
      } else {
        return null;
      }
    },
    sessions() {
      return this.sessionsByStatus(this.sessionsStatusFromRoute);
    },
  },
  async mounted() {
    try {
      this.loading = true;
      await this.$store.dispatch(new LoadMentoringSessionList());
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async goToCommunity() {
      await this.$router.push({ name: RouteName.Community });
    },
  },
});
</script>
