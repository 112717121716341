import { Action } from '@/store/types';

export const moduleName = 'stats';

export enum StatsPeriodType {
  Week = 'week',
  Month = 'month',
  Year = 'year',
  Custom = 'custom',
}

export interface StatsPeriod {
  from: Date;
  to: Date;
}

export interface StatsPeriodOptions {
  type: StatsPeriodType;
  amount: number;
  custom: StatsPeriod;
}

export interface StatsState {
  stats: Record<string, unknown>;
  period: StatsPeriodOptions;
}

export enum StatsActions {
  LoadStats = '[Stats] Load Stats',
  ChangeStatsPeriod = '[Stats] Change Stats Period',
}

export enum StatsMutations {
  SetStats = '[Stats] Set Stats',
  SetPeriod = '[Stats] Set Period',
}

export class LoadStats implements Action {
  type = `${moduleName}/${StatsActions.LoadStats}`;
}

export class ChangeStatsPeriod implements Action {
  type = `${moduleName}/${StatsActions.ChangeStatsPeriod}`;

  constructor(private periodType: StatsPeriodType, private amount = 1, private custom?: StatsPeriod) {}
}
