<template>
  <v-dialog
    v-model="isDialogOpen"
    width="500"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    sentry-tag="cancel-mentoring-session-dialog"
  >
    <v-card class="fill-height d-flex flex-column pa-5">
      <div class="d-flex justify-space-between fill-width">
        <h2 class="text--text">{{ $t('mentoring.cancelDialog.title') }}</h2>
        <a
          @click="$emit('input', false)"
          class="m-close-icon"
          sentry-tag="cancel-mentoring-session-dialog.button.close"
        >
          <v-img contain height="24" src="@/assets/close-icon.svg" width="24" />
        </a>
      </div>
      <p class="mt-2">{{ $t('mentoring.cancelDialog.body') }}</p>
      <div class="d-flex justify-space-between mt-2">
        <v-btn
          outlined
          sentry-tag="cancel-mentoring-session-dialog.button.close"
          @click="$emit('input', false)"
          depressed
          :disabled="isLoading"
        >
          {{ $t('mentoring.cancelDialog.cancel') }}
        </v-btn>
        <v-btn
          color="button"
          depressed
          sentry-tag="cancel-mentoring-session-dialog.button.delete"
          @click="cancelSession"
          :loading="isLoading"
        >
          {{ $t('mentoring.cancelDialog.confirm') }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { RouteName } from '@/router/types';
import { CloseMentoringSession } from '@/store/mentoring';
import { ShowNotification } from '@/store/notifications';
import { IMentoringSession } from '@mentessa/types';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'm-close-mentoring-session-dialog',
  props: {
    value: { type: Boolean, default: false },
    session: { type: Object as PropType<IMentoringSession> },
  },
  data: () => ({ isLoading: false }),
  computed: {
    isDialogOpen: {
      get() {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    async cancelSession() {
      try {
        this.isLoading = true;
        await this.$store.dispatch(new CloseMentoringSession({ id: this.session.id }));
        await this.$store.dispatch(new ShowNotification(this.$t('notifications.mentoringSessionClosed')), {
          root: true,
        });
        this.$emit('input', false);
        this.$router.push({
          name: RouteName.ActiveMentoring,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.m-close-icon {
  margin-top: 1.25%;
}
</style>
