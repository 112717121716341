<template>
  <v-container class="m-mentoring-session-container pa-0 mt-4">
    <v-row class="my-2" no-gutters>
      <m-mentoring-session-timeline :session="session" />
    </v-row>
    <v-row v-if="$vuetify.breakpoint.smAndDown">
      <v-col class="my-2" cols="12" md="6">
        <m-mentoring-user-card
          ref="mentoringUserCard"
          :class="{ 'ml-4': $vuetify.breakpoint.mdAndUp }"
          :member="selectedMember"
          :session="session"
          class="m-mentoring-item-small"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="my-2" cols="12" md="6">
        <m-mentoring-session-dashboard
          v-if="false"
          :class="{ 'mr-4': $vuetify.breakpoint.mdAndUp }"
          :session="session"
          class="m-mentoring-item-small"
        />
        <m-mentoring-session-goals
          :class="{ 'mr-4': $vuetify.breakpoint.mdAndUp }"
          :session="session"
          class="m-mentoring-item-small"
        />
      </v-col>
      <v-col class="my-2" cols="12" md="6">
        <m-mentoring-user-card
          v-if="$vuetify.breakpoint.mdAndUp"
          ref="mentoringUserCard"
          :class="{ 'ml-4': $vuetify.breakpoint.mdAndUp }"
          :member="selectedMember"
          :session="session"
          class="m-mentoring-item-small"
        />
        <m-mentoring-session-messages
          :class="{ 'ml-4': $vuetify.breakpoint.mdAndUp }"
          :session="session"
          class="m-mentoring-item-small mt-4"
        />
      </v-col>
    </v-row>
    <v-row v-if="hasAppointments" class="my-2" no-gutters>
      <m-mentoring-session-appointments :session="session" @onBookButtonClick="openBookDialog" />
    </v-row>
    <router-view />
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { LoadMentoringSession, SetCurrentMentoringSession } from '@/store/mentoring';
import { UsersState } from '@/store/users';
import { StoreDataState } from '@/store/types';
import { IMentoringSession } from '@mentessa/types';
import {
  MMentoringSessionAppointments,
  MMentoringSessionDashboard,
  MMentoringSessionGoals,
  MMentoringSessionMessages,
  MMentoringSessionTimeline,
  MMentoringUserCard,
} from '@/components/Mentoring/cards';

export default Vue.extend({
  name: 'm-mentoring-session',
  components: {
    MMentoringSessionTimeline,
    MMentoringSessionAppointments,
    MMentoringSessionDashboard,
    MMentoringSessionGoals,
    MMentoringSessionMessages,
    MMentoringUserCard,
  },
  props: {
    sessionId: Number,
    feedback: String,
  },
  data: () => ({
    showFeedback: false,
  }),
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    ...mapGetters('mentoring', {
      getSession: 'getSession',
    }),
    session() {
      return this.getSession(this.sessionId)?.data as IMentoringSession;
    },
    state() {
      return this.getSession(this.sessionId)?.state as StoreDataState;
    },
    selectedMember() {
      return this.session?.members.find((member) => member.user?.id !== this.me.id);
    },
    hasAppointments() {
      return this.session?.appointments?.length > 0 ?? false;
    },
  },
  watch: {
    async session(session: IMentoringSession) {
      if (session && this.state !== StoreDataState.Loaded) {
        await this.fetchMentoringSession();
      }
    },
    async sessionId(sessionId) {
      await this.$store.dispatch(new SetCurrentMentoringSession({ id: sessionId }));
    },
  },
  async mounted() {
    if (this.sessionId) {
      await this.$store.dispatch(new SetCurrentMentoringSession({ id: this.sessionId }));
    }

    if (this.session && this.state === StoreDataState.Unknown) {
      await this.fetchMentoringSession();
    }

    if (this.feedback === 'monthly') {
      this.showFeedback = true;
    }
  },
  methods: {
    async fetchMentoringSession() {
      await this.$store.dispatch(new LoadMentoringSession({ id: this.sessionId }));
    },
    openBookDialog() {
      this.$refs.mentoringUserCard.openUserDetailsDialog();
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.m-mentoring-session-container {
  max-width: 1200px;
}

.m-mentoring-item-small {
  min-height: 280px;
}

.m-mentoring-item-big {
  min-height: 600px;
}
</style>
