<template>
  <v-card class="pa-6">
    <template v-if="pendingAppointmentsCount">
      <h4>{{ $t('mentoring.dashboard.upcomingSessions', { count: pendingAppointmentsCount }) }}</h4>
      <div class="mt-6 d-flex">
        <v-img class="flex-grow-0 mr-2" contain height="24" src="@/assets/calendar.svg" width="24" />
        <h4 class="mt-1">{{ nextAppointmentDate }}</h4>
      </div>
    </template>
    <template v-else-if="totalAppointments > 0">
      {{ $t('mentoring.dashboard.futureSessions') }}
    </template>
    <template v-else>
      <h4>{{ $t('mentoring.dashboard.noUpcomingSessions') }}</h4>
    </template>
    <h4 class="mt-6">{{ $t('mentoring.dashboard.doNext') }}</h4>
    <v-checkbox
      v-for="(task, index) of tasks"
      :key="`task-${index}`"
      v-model="task.done"
      :class="{ 'text-decoration-line-through': task.done }"
      :disabled="readOnly"
      :label="$t(`mentoring.dashboard.tasks.${task.name}`)"
      color="success"
      dense
      hide-details
      readonly
    />
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { UsersState } from '@/store/users';
import { AppointmentStatus, IAppointment, IMentoringSession, MentoringSessionStatus } from '@mentessa/types';
import { parseISO } from 'date-fns';

export default Vue.extend({
  name: 'm-mentoring-session-dashboard',
  props: {
    session: { type: Object as PropType<IMentoringSession> },
  },
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    ...mapGetters('ui', {
      formatInUserTimeZone: 'formatInUserTimeZone',
    }),
    appointments(): Array<IAppointment> {
      return this.session?.appointments ?? [];
    },
    pendingAppointmentsCount() {
      const currentDate = new Date();
      return this.appointments.reduce(
        (pV, cV) =>
          parseISO(cV.slot?.date ?? cV.date) > currentDate &&
          [AppointmentStatus.Pending, AppointmentStatus.Confirmed].includes(cV.status)
            ? pV + 1
            : pV,
        0,
      );
    },
    totalAppointments() {
      return this.appointments.length - this.pendingAppointmentsCount;
    },
    nextAppointmentDate() {
      const currentDate = new Date();
      const nextAppointment = this.appointments.reduce((pV, cV) => {
        const currentSlotDate = parseISO(cV.slot?.date ?? cV.date);

        if (
          cV.status !== AppointmentStatus.Canceled &&
          currentSlotDate > currentDate &&
          (pV == null || parseISO(pV.slot?.date ?? pV.date) > currentSlotDate)
        ) {
          return cV;
        }
        return pV;
      }, undefined);

      if (nextAppointment) {
        return this.formatInUserTimeZone(nextAppointment.date, 'EEE d MMMM yyyy H:mm');
      }
      return '';
    },
    tasks() {
      return [
        { name: 'bookFirstSession', done: this.appointments?.length > 0 ?? false },
        { name: 'sendFirstMessage', done: this.session?.messages?.length > 0 ?? false },
        {
          name: 'addGoals',
          done: this.session?.goals?.filter((goal) => goal.createdBy?.id === this.me.id).length > 0 ?? false,
        },
      ];
    },
    readOnly() {
      return this.session?.status !== MentoringSessionStatus.Progress;
    },
  },
  methods: {},
});
</script>

<style lang="scss" scoped></style>
