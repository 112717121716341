<template>
  <div class="d-flex flex-column text-center pa-4">
    <v-chip-group v-model="goals" color="primary" column multiple>
      <v-chip v-for="(goal, index) in suggestedGoals" :key="`goal-${index}`">{{ $t(`known_goals.${goal}`) }}</v-chip>
    </v-chip-group>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IUser } from '@mentessa/types';

export default Vue.extend({
  name: 'm-onboarding-goals-form',
  components: {},
  props: {
    value: Object as PropType<IUser>,
  },
  data: () => ({
    // goals: [],
  }),
  computed: {
    suggestedGoals() {
      return [
        'learn_skill',
        'find_mentor',
        'become_mentor',
        'get_buddy',
        'grow_career',
        'build_network',
        'find_friends',
        'find_customers',
      ];
    },
    goals: {
      get() {
        return this.value?.goals?.map((goal: string) => this.suggestedGoals.indexOf(goal));
      },
      set(values: Array<string>) {
        this.$emit('input', {
          ...this.value,
          goals: values.map((goalIndex) => this.suggestedGoals[goalIndex]),
        });
      },
    },
  },
});
</script>
