import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { mutations } from './mutations';
import { actions } from './actions';
import { EmailsState, moduleName } from './types';
import { getters } from './getters';

export * from './types';

export function emailsGetter(getter: string) {
  return `${moduleName}/${getter}`;
}

export const initialState: EmailsState = {
  emailsPage: {
    items: [],
    meta: {
      currentPage: 0,
      totalPages: 0,
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 0,
    },
  },
};

export const emailsModule: Module<EmailsState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

export default emailsModule;
