<template>
  <v-dialog v-model="isDialogOpen" max-width="746px" sentry-tag="new-resource-dialog">
    <v-card :class="{ 'flex-column': this.$vuetify.breakpoint.xsOnly }" class="fill-height pa-6">
      <div class="d-flex justify-end">
        <a sentry-tag="edit-resource-dialog.button.close" @click="$emit('input', false)">
          <v-img contain height="24" src="@/assets/close-icon.svg" width="24" />
        </a>
      </div>
      <validation-observer v-slot="{ invalid }" class="m-settings-dialog__form">
        <h1>{{ $t('settings.resources.edit') }}</h1>
        <m-text-field
          v-model="currentResource.title"
          :label="$t('settings.videos.dialogNew.title')"
          sentry-tag="new-resource-dialog.input.title"
          validation-rules="required"
        />
        <m-text-field
          v-model="currentResource.content"
          :label="$t('settings.resources.dialogNew.url')"
          sentry-tag="new-resource-dialog.input.url"
          validation-rules="required"
        />
        <div class="modal_foot text-center pt-4">
          <v-btn
            :disabled="invalid"
            color="button"
            depressed
            sentry-tag="edit-resource-dialog.button.add"
            x-large
            @click="onPublish"
          >
            {{ $t('settings.resources.action.save') }}
          </v-btn>
        </div>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { ValidationObserver } from 'vee-validate';
import { IContent, IResource } from '@mentessa/types';
import { MTextField } from '@/components/Inputs';
import deepmerge from 'deepmerge';
import { UpdateResource } from '@/store/resources';

export default Vue.extend({
  name: 'm-edit-resource-dialog',
  components: { MTextField, ValidationObserver },
  props: {
    value: { type: Boolean, default: false },
    resource: { type: Object as PropType<IContent>, default: () => ({}) },
  },
  data: () => ({
    currentResource: undefined,
  }),
  watch: {
    resource(value: IResource) {
      this.currentResource = deepmerge({}, value);
    },
  },
  computed: {
    isDialogOpen: {
      get() {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    async onPublish() {
      await this.$store.dispatch(new UpdateResource(this.currentResource));
      this.$emit('input', false);
      this.$emit('onPublish');
    },
  },
});
</script>

<style lang="scss" scoped>
.m-settings-dialog__form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
}
</style>
