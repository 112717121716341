<template>
  <v-dialog :value="value" max-width="574" sentry-tag="manage-member-roles-dialog" @input="$emit('input', $event)">
    <v-sheet class="pa-14">
      <div class="d-flex flex-column justify-center">
        <h2 class="text-center">{{ $t('settings.members.manageMemberRolesDialog.title') }}</h2>
        <v-chip-group v-model="selectedRoles" class="flex-wrap mt-4 ma-4" column multiple>
          <v-chip v-for="(role, index) in availableRoles" :key="`${index}`" filter> {{ roleName(role) }}</v-chip>
        </v-chip-group>
        <div class="d-flex justify-space-between">
          <v-btn sentry-tag="manage-member-roles-dialog.button.close" @click="$emit('input', false)">
            {{ $t('settings.members.manageMemberRolesDialog.cancel') }}
          </v-btn>
          <v-btn
            :disabled="!isRolesChanged"
            :loading="loading"
            color="button"
            depressed
            large
            sentry-tag="manage-member-roles-dialog.button.apply"
            @click="changeRoles"
          >
            {{ $t('settings.members.manageMemberRolesDialog.apply') }}
          </v-btn>
        </div>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { UserEntity } from '@mentessa/types';
import { TenantState } from '@/store/tenant';
import { mapState } from 'vuex';
import { UpdateUserAttributes } from '@/store/users';

export default Vue.extend({
  name: 'm-manage-member-roles-dialog',
  props: {
    value: Boolean,
    user: Object as PropType<UserEntity>,
  },
  data: () => ({
    loading: false,
    selectedRoles: [],
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    availableRoles() {
      const roles = ['Admin'];
      const customRoles = this.tenant?.attributes.customRoles;
      if (customRoles?.length > 0) roles.push(...customRoles);
      return roles;
    },
    isRolesChanged() {
      const roles = this.selectedRoles?.map((roleIndex) => this.availableRoles[roleIndex]);
      if (roles?.length !== this.user?.attributes?.roles?.length && (this.user?.attributes?.roles || roles.length > 0))
        return true;
      for (let index = 0; index < this.user?.attributes?.roles?.length; index++) {
        const role = this.user?.attributes?.roles[index];
        if (!roles.includes(role)) {
          return true;
        }
      }
      return false;
    },
    roleName() {
      return (role: string) => {
        if (this.$te(`user.roles.${role}`)) {
          return this.$t(`user.roles.${role}`);
        }
        return role;
      };
    },
  },
  watch: {
    value(value: boolean) {
      if (value) {
        this.selectedRoles = this.user?.attributes?.roles?.map((role) =>
          this.availableRoles.findIndex((availableRole) => role === availableRole),
        );
      }
    },
  },
  methods: {
    async changeRoles() {
      this.loading = true;
      try {
        const roles = this.selectedRoles.map((roleIndex) => this.availableRoles[roleIndex]);
        await this.$store.dispatch(new UpdateUserAttributes({ roles }, this.user));
        this.$emit('roles-changed');
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.close-icon {
  position: absolute;
  top: 18px;
  right: 18px;
}

.m-close-icon-container {
  display: flex;
  justify-content: flex-end;
}

.m-close-icon {
  position: absolute;
  margin: 18px;
}
</style>
