<template>
  <div class="mt-4 d-flex gap-4 m-user-info-card">
    <m-user-avatar :large="!xLarge" :user="value" :x-large="xLarge" @click="$emit('click')" />
    <div class="m-user-info-card__content">
      <m-text-with-hint :font-size="20" :text="fullName" class="font-weight-medium" />
      <m-text-with-hint v-if="jobTitle" :text="jobTitle" class="mt-0 mb-3" />
      <m-text-with-hint v-if="company" :lines="1" :text="company" class="m-company" />
      <m-text-with-hint v-if="location" :lines="1" :text="location" class="m-location" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IUser } from '@mentessa/types';
import MTextWithHint from '@/components/MTextWithHint';
import MUserAvatar from '@/components/MUserAvatar';
import { getFullName } from '@/utils/user';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';

export default Vue.extend({
  name: 'm-user-info-card',
  components: { MUserAvatar, MTextWithHint },
  props: {
    value: Object as PropType<IUser>,
    xLarge: { type: Boolean, default: false },
  },
  computed: {
    ...mapState<TenantState>('tenant', {
      customFields: (state: TenantState) => state.tenant.attributes.customFields ?? [],
      customProfileFields: (state: TenantState) => state.tenant.attributes.customFields ?? [],
    }),
    cardOverride() {
      const result = {};
      this.customFields.forEach((field) => {
        if (field.cardOverride) {
          result[field.cardOverride] = this.value?.attributes?.custom?.[field.name];
        }
      });
      this.customProfileFields.forEach((field) => {
        if (field.cardOverride) {
          result[field.cardOverride] = this.value?.attributes?.custom?.[field.name];
        }
      });
      return result;
    },
    fullName() {
      return this.value ? getFullName(this.value) : '';
    },
    jobTitle() {
      if (this.cardOverride?.jobTitle) {
        return this.cardOverride?.jobTitle;
      }
      return this.value?.attributes?.jobTitle ?? '';
    },
    location() {
      if (this.cardOverride?.location) {
        return this.cardOverride?.location;
      }
      return this.value?.attributes?.location ?? '';
    },
    company() {
      if (this.cardOverride?.company) {
        return this.cardOverride?.company;
      }
      return this.value?.attributes?.company;
    },
  },
});
</script>

<style lang="scss" scoped>
@mixin user-icon-field {
  margin-bottom: 4px;
  display: flex;
  &:before {
    margin-right: 5px;
    position: relative;
    top: 3px;
  }
}

.m-user-info-card {
  height: 162px;
}

.m-user-info-card__info {
  max-width: 220px;
}

.m-company {
  @include user-icon-field;

  &:before {
    content: url(@/assets/suitcase.svg);
  }
}

.m-location {
  @include user-icon-field;

  &:before {
    content: url(@/assets/pin.svg);
  }
}
</style>
