import { RouteName } from '@/router/types';
import { Route } from 'vue-router';
import { NavigationGuardNext } from 'vue-router/types/router';
import { MRitualsShop } from '@/components/MRitualsShop';
import { MRitualEnrollDialog, MRitualMatchActionDialog } from '@/components/Dialogs';
import { MRitualTemplates } from '@/components/MRitualTemplates';
import { MRitualManage } from '@/components/MRitualManage';
import { MRitualSettings } from '@/components/MRitualSettings';
import { MRitualInvite } from '@/components/MRitualInvite';
import { MRitualResults } from '@/components/MRitualResults';
import { MRitualMembersNew } from '@/components/MRitualMembersNew';
import { MRitualMatchingReport } from '@/components/MRitualMatchingReport';
import { MRitualDashboard } from '@/components/MRitualDashboard';

const ritualsRoutes = [
  {
    path: '',
    name: RouteName.Rituals,
    component: MRitualsShop,
    alias: RouteName.Rituals,
  },
  {
    path: 'templates',
    name: RouteName.RitualTempaltes,
    component: MRitualTemplates,
  },
  {
    path: ':ritualId',
    name: RouteName.RitualManage,
    component: MRitualManage,
    props: (route) => ({
      ritualId: route.params['ritualId'] != null ? Number(route.params['ritualId']) : undefined,
    }),
    redirect: { name: RouteName.RitualManageSettings },
    children: [
      {
        path: 'dashboard',
        name: RouteName.RitualManageDashboard,
        component: MRitualDashboard,
      },
      {
        path: 'settings',
        name: RouteName.RitualManageSettings,
        component: MRitualSettings,
      },
      {
        path: 'members',
        name: RouteName.RitualManageMembers,
        component: MRitualMembersNew,
      },
      {
        path: 'results',
        name: RouteName.RitualManageResults,
        component: MRitualMatchingReport,
      },
      {
        path: 'invite',
        name: RouteName.RitualManageInvite,
        component: MRitualInvite,
      },
      {
        path: 'debug',
        name: RouteName.RitualManageDebug,
        component: MRitualResults,
      },
    ],
  },
  {
    path: ':ritualId/enroll',
    name: RouteName.RitualEnroll,
    component: MRitualEnrollDialog,
    props: (route) => ({
      ritualId: route.params['ritualId'] != null ? Number(route.params['ritualId']) : undefined,
    }),
  },
  {
    path: ':ritualId/matches/:matchId',
    name: RouteName.RitualMatchAction,
    component: MRitualMatchActionDialog,
    props: (route) => ({
      ritualId: route.params['ritualId'] != null ? Number(route.params['ritualId']) : undefined,
      matchId: route.params['matchId'] != null ? Number(route.params['matchId']) : undefined,
      action: route.query['action'],
      to: { name: RouteName.Community },
      value: true,
    }),
  },
  {
    path: '*',
    beforeEnter: (from: Route, to: Route, next: NavigationGuardNext): void => {
      next({ name: RouteName.Rituals });
    },
  },
];

export { ritualsRoutes };

export default ritualsRoutes;
