<template>
  <m-user-stream />
</template>

<script lang="ts">
import Vue from 'vue';
import MUserStream from '@/components/MUserStream/MUserStream.vue';

export default Vue.extend({
  name: 'm-community',
  components: {
    MUserStream,
  },
  computed: {},
});
</script>
