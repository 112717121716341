import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { api } from '@/store/api';
import { ShowErrorNotification } from '@/store/notifications';
import i18n from '@/plugins/i18n';
import { LoadStats, StatsActions, StatsMutations, StatsPeriod, StatsPeriodType, StatsState } from './types';
import { subMonths, subWeeks, subYears } from 'date-fns';

function buildPeriod(type: StatsPeriodType, amount = 1, custom?: StatsPeriod): StatsPeriod {
  switch (type) {
    case StatsPeriodType.Week:
      return { to: new Date(), from: subWeeks(new Date(), amount) };
    case StatsPeriodType.Month:
      return { to: new Date(), from: subMonths(new Date(), amount) };
    case StatsPeriodType.Year:
      return { to: new Date(), from: subYears(new Date(), amount) };
    case StatsPeriodType.Custom:
      return { ...custom };
  }
}

export const actions: ActionTree<StatsState, RootState> = {
  async [StatsActions.LoadStats]({ commit, dispatch, state }) {
    try {
      const period = buildPeriod(state.period.type, state.period.amount, state.period.custom);
      const response = await api.get(`metrics?from=${period.from}&to=${period.to}`);
      const stats = response?.data;
      commit(StatsMutations.SetStats, stats);
    } catch (e) {
      console.error('Error while LoadStats.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [StatsActions.ChangeStatsPeriod]({ commit, dispatch }, { periodType, amount, custom }) {
    commit(StatsMutations.SetPeriod, { type: periodType, amount, custom });
    return dispatch(new LoadStats(), { root: true });
  },
};
