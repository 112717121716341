<template>
  <div class="d-flex flex-column text-center pa-4 gap-4">
    <m-location v-model="location" :label="$t('profile.personal.location')" sentry-tag="profile.input.location" />
    <template v-if="!forceTimeZone">
      <m-time-zone-picker v-model="tz" :label="$t('profile.tz')" sentry-tag="profile.tz"></m-time-zone-picker>
    </template>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IUser } from '@mentessa/types';
import { MLocation, MTimeZonePicker } from '@/components/Inputs';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';

export default Vue.extend({
  name: 'm-onboarding-location-form',
  components: {
    MTimeZonePicker,
    MLocation,
  },
  props: {
    value: Object as PropType<IUser>,
  },
  computed: {
    ...mapState<TenantState>('tenant', {
      forceTimeZone: (state: TenantState) => state.tenant?.attributes?.forceTenantTimeZone ?? false,
      forcedTz: (state: TenantState) => state.tenant?.tz,
    }),
    location: {
      get() {
        return this.value?.attributes.location;
      },
      set(value) {
        this.$emit('input', {
          ...this.value,
          attributes: {
            ...this.value?.attributes,
            location: value,
          },
        });
      },
    },
    tz: {
      get() {
        return this.value?.tz;
      },
      set(value: string) {
        this.$emit('input', {
          ...this.value,
          tz: value,
        });
      },
    },
  },
  mounted() {
    if (this.forceTimeZone) {
      this.tz = this.forcedTz;
    } else if (!this.value.tz) {
      this.tz = this.value.attributes.tz ?? Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'CET';
    }
  },
});
</script>
