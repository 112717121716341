<template>
  <v-dialog :value="value" width="600px" @input="$emit('input', $event)" sentry-tag="ritual-edit-dialog">
    <v-sheet class="m-edit-ritual-dialog-container">
      <div class="d-flex justify-end icon-container">
        <a @click="$emit('input', false)" sentry-tag="ritual-edit-dialog.close-button">
          <v-img contain height="24" src="@/assets/close-icon.svg" width="24" />
        </a>
      </div>
      <p class="font-weight-bold text-h5">{{ $t('settings.rituals.editRitualTitle') }}</p>
      <m-select
        v-model="schedule.options.periodic.weekday"
        :items="weekdays"
        :label="$t('settings.rituals.weekday')"
        class="pr-4"
        sentry-tag="ritual-edit-dialog.select.weekday"
      >
        <template v-slot:selection="{ item }">
          {{ $t(`weekdays.full.${item}`) }}
        </template>
        <template v-slot:item="{ item }">
          {{ $t(`weekdays.full.${item}`) }}
        </template>
      </m-select>
      <m-select
        v-model="schedule.options.periodic.hour"
        :items="hours"
        :label="$t('settings.rituals.hour')"
        class="pr-4"
        sentry-tag="ritual-edit-dialog.select.hour"
      />
      <div class="d-flex justify-end">
        <v-btn
          :disabled="!hasChanges"
          color="button"
          depressed
          @click="updateRitual"
          sentry-tag="ritual-edit-dialog.button.save"
        >
          {{ $t('settings.rituals.save') }}
        </v-btn>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IRitual, RitualAttributes, RitualSchedulePeriodicFrequency, RitualScheduleType } from '@mentessa/types';
import { UpdateRitual } from '@/store/rituals';
import MSelect from '@/components/Inputs/MSelect/MSelect.vue';

export default Vue.extend({
  name: 'm-ritual-edit-dialog',
  components: { MSelect },
  props: {
    value: Boolean,
    ritual: { type: Object as PropType<IRitual> },
  },
  data: () => ({
    weekdays: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],

    hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0],
    schedule: {
      type: RitualScheduleType.Periodic,
      options: {
        periodic: {
          frequency: RitualSchedulePeriodicFrequency.Weekly,
          weekday: 'fri',
          hour: 15,
        },
      },
    },
  }),
  computed: {
    hasChanges() {
      const options = this.ritual?.attributes.schedule?.options;
      if (!options) return false;
      return (
        this.schedule.options.periodic !== options.periodic.weekday ||
        this.schedule.options.periodic.hour !== options.periodic.hour
      );
    },
  },
  watch: {
    ritual() {
      this.updateData();
    },
  },
  mounted() {
    this.updateData();
  },
  methods: {
    async updateRitual() {
      const newAttributes: RitualAttributes = { schedule: this.schedule };

      const newRitual = {
        ...this.ritual,
        attributes: newAttributes,
      };

      if (await this.$store.dispatch(new UpdateRitual(this.ritual, newRitual))) {
        this.$emit('input', false);
      }
    },

    updateData() {
      const schedule = this.ritual.attributes.schedule;
      if (!schedule) return;
      if (schedule.type === RitualScheduleType.Periodic) {
        this.schedule.options.periodic.weekday = schedule.options.periodic.weekday;
        this.schedule.options.periodic.hour = schedule.options.periodic.hour;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.m-edit-ritual-dialog-container {
  padding: 36px;
}
</style>
