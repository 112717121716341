<template>
  <v-card class="d-flex flex-column pa-4 gap-4 border-dashed" max-width="354" min-width="354" width="354">
    <v-card-title class="mt-10">
      <div class="d-flex gap-4">
        <h2>{{ $t('ritual_templates.more.title') }}</h2>
      </div>
    </v-card-title>
    <v-card-text>
      <p>
        {{ $t('ritual_templates.more.description') }}
      </p>
    </v-card-text>
    <v-card-actions class="mt-auto justify-end">
      <div class="d-flex flex-column">
        <v-btn
          color="button"
          depressed
          href="mailto:support@mentessa.com?subject=Enabling new programs for my community"
          outlined
        >
          {{ $t('ritual_templates.more.cta') }}
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'm-ritual-template-more-card',
});
</script>

<style lang="scss" scoped>
.m-image-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
}
</style>
