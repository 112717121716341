<template>
  <v-card class="d-flex flex-column pa-4 gap-4" max-width="325" min-width="325" width="325">
    <div class="ml-auto m-ritual-status-line">
      <v-chip v-if="enabled" color="primary" label small>
        {{ $t('ritual_templates.template') }}
      </v-chip>
    </div>
    <v-row class="flex-grow-0 my-4 m-ritual-logo" justify="center">
      <m-ritual-icon :ritual-template="ritualTemplate" big />
    </v-row>
    <v-card-title class="justify-center">
      <p class="primary--text">{{ title }}</p>
    </v-card-title>
    <v-card-text class="text-center flex-grow-1">
      <p v-snip="{ lines: isTextHidden ? 3 : 0, onSnipped }">{{ description }}</p>
      <v-btn
        v-if="hasEllipsis || !isTextHidden"
        class="font-weight-bold mt-n4"
        color="primary"
        text
        @click="isTextHidden = !isTextHidden"
      >
        {{ $t(isTextHidden ? 'actions.show.more' : 'actions.show.less') }}
      </v-btn>
    </v-card-text>
    <v-card-actions class="mt-auto justify-end">
      <div class="d-flex flex-column">
        <v-btn :disabled="loading" color="button" min-width="128px" @click="create">
          {{ $t('ritual_templates.use') }}
        </v-btn>
        <v-switch v-if="isDebugAvailable" v-model="enabled" class="ml-auto" label="Available" />
      </div>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { ITenantRitualTemplate } from '@mentessa/types';
import { CreateRitual } from '@/store/rituals';
import { RouteName } from '@/router/types';
import { mapGetters } from 'vuex';
import { ChangeRitualTemplateEnabled } from '@/store/ritualTemplates';
import { MRitualIcon } from '@/components/MRitualIcon';

export default Vue.extend({
  name: 'm-ritual-template-card',
  components: { MRitualIcon },
  props: {
    ritualTemplate: { type: Object as PropType<ITenantRitualTemplate> },
  },
  data: () => ({
    loading: false,
    isTextHidden: true,
    hasEllipsis: false,
  }),
  computed: {
    ...mapGetters('tenant', {
      isDebugAvailable: 'isDebugAvailable',
    }),
    enabled: {
      get() {
        return this.ritualTemplate.enabled;
      },
      async set(value: boolean) {
        await this.$store.dispatch(new ChangeRitualTemplateEnabled(this.ritualTemplate, value));
      },
    },
    title() {
      return (
        this.ritualTemplate.ritualAttributes.title ?? this.$t(`settings.rituals.knownNames.${this.ritualTemplate.type}`)
      );
    },
    description() {
      return (
        this.ritualTemplate.ritualAttributes.description ??
        this.$t(`settings.rituals.knownDescriptions.${this.ritualTemplate.type}`).toString()
      );
    },
  },
  methods: {
    onSnipped(props) {
      this.hasEllipsis = props.hasEllipsis;
    },
    async create() {
      this.loading = true;
      try {
        const ritual = await this.$store.dispatch(new CreateRitual({}, this.ritualTemplate));
        await this.$router.push({
          name: RouteName.RitualManageSettings,
          params: { ritualId: ritual.id.toString() },
        });
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.m-ritual-status-line {
  min-height: 24px;
}

.m-ritual-logo {
  min-height: 54px;
}
</style>
