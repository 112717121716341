<template>
  <div class="d-flex justify-center align-center flex-column">
    <m-chip-group
      v-model="selected"
      :items="items"
      class="mt-4"
      i18n-path="onboarding.community_roles.items"
      validation-rules="required"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { MChipGroup } from '@/components/Inputs';

export default Vue.extend({
  name: 'm-tenant-onboarding-community-roles-form',
  components: { MChipGroup },
  props: {
    value: { type: Array as PropType<Array<string>>, default: () => [] },
  },
  data: () => ({
    items: [
      'expert',
      'advisor',
      'mentor',
      'mentee',
      'startup',
      'investor',
      'ambassador',
      'supervisor',
      'manager',
      'hr_partner',
      'host',
    ],
  }),
  computed: {
    selected: {
      get() {
        return this.value.map((item) => this.items.indexOf(item));
      },
      set(value: Array<number>) {
        this.$emit(
          'input',
          value.map((index) => this.items[index]),
        );
      },
    },
  },
});
</script>
