import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { NpsActions, NpsMutations, NpsState } from './types';
import { api } from '@/store/api';
import i18n from '@/plugins/i18n';
import { ShowErrorNotification, ShowNotification } from '@/store/notifications';
import { getIdOrCurrent } from '@/utils/api';

export const actions: ActionTree<NpsState, RootState> = {
  async [NpsActions.LoadNps]({ commit, dispatch }, { userRef }) {
    try {
      const response = await api.get(`users/${getIdOrCurrent(userRef)}/nps/active`);
      const nps = response?.data;
      if (nps) {
        commit(NpsMutations.SetNps, nps);
      }
    } catch (e) {
      if (e.response?.status !== 404) {
        console.error('Error while LoadNps.', e);
        await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
          root: true,
        });
      }
    }
  },

  async [NpsActions.SendNpsResult]({ commit, dispatch }, { npsRef, score, userRef }) {
    try {
      await api.post(`users/${getIdOrCurrent(userRef)}/nps/${npsRef.id}/result`, { score });
      await dispatch(new ShowNotification(i18n.t('notifications.npsSubmitSuccess').toString()), {
        root: true,
      });
    } catch (e) {
      console.error('Error while SendNpsResult.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    } finally {
      commit(NpsMutations.SetNps, undefined);
    }
  },

  async [NpsActions.DisableNpsForUser]({ commit, dispatch }, { npsRef, userRef }) {
    try {
      await api.post(`users/${getIdOrCurrent(userRef)}/nps/${npsRef.id}/discard`);
    } catch (e) {
      if (e.response?.status !== 404) {
        console.error('Error while CloseNps.', e);
        await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
          root: true,
        });
      }
    } finally {
      commit(NpsMutations.SetNps, undefined);
    }
  },

  async [NpsActions.LoadNpsForTenant]({ dispatch }, { tenantRef }) {
    try {
      const response = await api.get(`tenants/${getIdOrCurrent(tenantRef)}/nps`);
      return response?.data;
    } catch (e) {
      console.error('Error while LoadNpsForTenant.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [NpsActions.CreateNps]({ dispatch }, { nps, tenantRef }) {
    try {
      const response = await api.post(`tenants/${getIdOrCurrent(tenantRef)}/nps`, nps);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.error('Error while UpdateNps.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [NpsActions.UpdateNps]({ dispatch }, { npsRef, nps, tenantRef }) {
    try {
      await api.put(`tenants/${getIdOrCurrent(tenantRef)}/nps/${npsRef.id}`, nps);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
    } catch (e) {
      if (e.response?.status !== 404) {
        console.error('Error while UpdateNps.', e);
        await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
          root: true,
        });
      }
    }
  },
};
