<template>
  <div class="py-2">
    <m-rating v-model="rating" :label="label" :length="max" :boundaries="boundaries" />
    <div v-if="!hideComment">
      {{ commentLabel }}
      <m-text-area v-model="comment" :placeholder="$t('inputs.textPlaceholder')" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { MRating, MTextArea } from '@/components/Inputs';

export interface RatingWithText {
  rating?: number;
  comment?: string;
}

export default Vue.extend({
  name: 'm-rating-with-text',
  components: { MTextArea, MRating },
  props: {
    value: { type: Object as PropType<RatingWithText>, default: () => ({ rating: undefined, comment: undefined }) },
    max: { type: Number, default: 5 },
    boundaries: { type: Array as PropType<Array<string>> },
    label: String,
    commentLabel: String,
    hideComment: { type: Boolean, default: false },
  },
  computed: {
    rating: {
      get() {
        return this.value.rating;
      },
      set(rating: number) {
        const result: RatingWithText = { rating };
        if (this.value.comment != null) result.comment = this.value.comment;
        this.$emit('input', result);
      },
    },
    comment: {
      get() {
        return this.value.comment;
      },
      set(comment: string) {
        const result: RatingWithText = { comment };
        if (comment === '') delete result.comment;
        if (this.value.rating != null) result.rating = this.value.rating;
        this.$emit('input', result);
      },
    },
  },
});
</script>
