<template>
  <ais-search-box>
    <template v-slot="{ isSearchStalled, refine }">
      <m-search-field :debounce="debounce" :loading="isSearchStalled" :value="query" @input="refine($event)" />
    </template>
  </ais-search-box>
</template>

<script lang="ts">
import Vue from 'vue';
import { MSearchField } from '@/components/Inputs';

export default Vue.extend({
  name: 'm-ais-search-box',
  components: { MSearchField },
  props: {
    debounce: { type: Number, default: 500 },
  },
  data: () => ({
    query: '',
  }),
});
</script>
