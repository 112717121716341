import { ActionTree } from 'vuex';
import { UsersActions, UsersMutations, UsersState } from './types';
import { RootState } from '@/store/types';
import { api } from '@/store/api';
import { ISchedule, IUser } from '@mentessa/types';
import { OpenNotificationDialog, ShowErrorNotification, ShowNotification } from '@/store/notifications';
import i18n from '@/plugins/i18n';
import { getIdOrCurrent } from '@/utils/api';

export const actions: ActionTree<UsersState, RootState> = {
  async [UsersActions.GetMe]({ commit }) {
    const response = await api.get('users/current');
    const user: IUser = response?.data;
    console.log(`Hi ${user.identity.attributes.firstName} ${user.identity.attributes.lastName} (${user.id})`);
    commit(UsersMutations.SetMe, user);
    return user;
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async [UsersActions.LoadUserDetails]({ commit, state, dispatch }, { userId, fetchOnly }) {
    try {
      commit(UsersMutations.SetStreamLoading, true);
      const response = await api.get(`users/${userId}`);
      const user = response.data as IUser;
      if (user) {
        if (!fetchOnly) {
          await commit(UsersMutations.AppendToStream, user);
        }
        return user;
      }
    } catch (e) {
      console.error(e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    } finally {
      commit(UsersMutations.SetStreamLoading, false);
    }
  },

  async [UsersActions.UpdateUserField]({ commit, state }, { field, value }) {
    commit(UsersMutations.SetUserField, { field, value });
    const path = (field as string).replaceAll('.', '/');
    await api.put(`user/current/${path}`, value);
    return state.me;
  },

  // eslint-disable-next-line
  async [UsersActions.InviteUser]({ commit, dispatch }, { email, isMentor, isAdmin }) {
    try {
      await api.post('manage/invite', { emails: [email], isMentor, isAdmin });
      await dispatch(
        new OpenNotificationDialog(
          i18n.t('notifications.inviteSent').toString(),
          '',
          i18n.t('notifications.ok').toString(),
        ),
        { root: true },
      );
    } catch (e) {
      if (e.response.status !== 409) {
        await dispatch(
          new OpenNotificationDialog(
            i18n.t('notifications.inviteError').toString(),
            '',
            i18n.t('notifications.ok').toString(),
          ),
          { root: true },
        );
      } else {
        await dispatch(
          new OpenNotificationDialog(
            i18n.t('notifications.inviteExists').toString(),
            '',
            i18n.t('notifications.ok').toString(),
          ),
          { root: true },
        );
      }
    }
  },
  // eslint-disable-next-line
  async [UsersActions.UpdateUserImage]({ commit, state, dispatch }, { user, blob }) {
    const formData = new FormData();
    const fileName = `${user ? user.identity.id : state.me.identity.id}-image-${new Date().toISOString()}.jpeg`;
    formData.append('file', blob, fileName);
    try {
      const result = await api.post(`users/${getIdOrCurrent(user)}/identity/image`, formData);
      const url = result.data.result;
      commit(UsersMutations.SetMyImage, url);
      return url;
    } catch (e) {
      console.log('Error while UpdateUserImage', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [UsersActions.UpdateUserFullName]({ commit, dispatch }, { firstName, lastName, tz, user }) {
    try {
      const response = await api.put(`users/${getIdOrCurrent(user)}/identity/name`, { firstName, lastName, tz });
      commit(UsersMutations.SetMe, response.data);
      return response.data;
    } catch (e) {
      console.log('Error while UpdateUserFullName', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [UsersActions.UpdateUserEmail]({ commit, dispatch }, { email, user }) {
    try {
      await api.put(`users/${getIdOrCurrent(user)}/identity/email`, { email });
      commit(UsersMutations.SetMyEmail, email);
      return email;
    } catch (e) {
      console.log('Error while UpdateUserEmail', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [UsersActions.UpdateUserAttributes]({ commit, dispatch }, { attributes, user }) {
    try {
      const id = getIdOrCurrent(user);
      const result = await api.put(`users/${id}/attributes`, attributes);
      const resultUser = result.data.result;
      if (id === 'current') commit(UsersMutations.SetMyAttributes, resultUser.attributes);
      else commit(UsersMutations.SetUserAttributes, resultUser);
      if (!user) {
        // ToDo: Retrieve a real data
        commit(UsersMutations.SetMyComplete, true);
      }
      await dispatch(new ShowNotification(i18n.t('notifications.updateUserAttributesSuccess').toString()), {
        root: true,
      });
      return resultUser;
    } catch (e) {
      console.log('Error while UpdateUserAttributes', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [UsersActions.CreateRoleGroup]({ dispatch }, { role, roleToAssign }) {
    try {
      await api.post(`casbin/roles/${role}/groups`, { roleToAssign });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
    } catch (e) {
      console.log('Error while CreateRoleGroup', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [UsersActions.RemoveRoleGroup]({ dispatch }, { role, roleToRemove }) {
    try {
      await api.delete(`casbin/roles/${role}/groups/${roleToRemove}`);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
    } catch (e) {
      console.log('Error while RemoveRoleGroup', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [UsersActions.LoadRoleGroups]({ dispatch }, { role }) {
    try {
      const result = await api.get(`casbin/roles/${role}/groups`);
      return result?.data;
    } catch (e) {
      console.log('Error while LoadRoleGroups', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [UsersActions.UpdateUserTags]({ commit, dispatch }, { tags, user }) {
    try {
      const result = await api.put(`users/${getIdOrCurrent(user)}/tags`, tags);
      const resultTags = result.data;
      commit(UsersMutations.SetMyTags, resultTags);
      return resultTags;
    } catch (e) {
      console.log('Error while UpdateUserTags', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [UsersActions.UpdateUserLang]({ commit, dispatch }, { user, lang }) {
    try {
      await api.put(`users/${getIdOrCurrent(user)}/attributes`, { lang });
      if (!user) {
        commit(UsersMutations.SetMyLang, lang);
      }
    } catch (e) {
      console.log('Error while UpdateUserLang', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [UsersActions.SaveUserProfile]({ commit, dispatch }, { profile, user }) {
    try {
      const result = await api.put(`users/${getIdOrCurrent(user)}/profile`, profile);
      commit(UsersMutations.SetMe, result.data);
      await dispatch(new ShowNotification(i18n.t('notifications.profileSaveSuccess').toString()), {
        root: true,
      });
      return result.data;
    } catch (e) {
      console.log('Error while SaveUserProfile', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [UsersActions.SaveUserSchedules]({ dispatch }, { schedule, userRef, showSuccessNotification }) {
    try {
      const response = await api.put(`users/${getIdOrCurrent(userRef)}/schedule`, { schedule });
      if (showSuccessNotification === true) {
        await dispatch(new ShowNotification(i18n.t('notifications.profileSaveSuccess').toString()), {
          root: true,
        });
      }
      return response?.status === 200 ?? false;
    } catch (e) {
      console.log('Error while SaveUserSchedules', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [UsersActions.LoadPermissions]({ commit }) {
    const result = await api.get('users/current/permissions');
    commit(UsersMutations.SetPermissions, result.data);
    return result.data;
  },

  async [UsersActions.SetFavoriteStatus]({ commit, dispatch }, { target, enabled, user }) {
    if (target.isFavorite === enabled) {
      return;
    }
    try {
      if (enabled) {
        await api.post(`users/${getIdOrCurrent(user)}/favorites`, { id: target.id });
      } else {
        await api.delete(`users/${getIdOrCurrent(user)}/favorites/${target.id}`);
      }

      commit(UsersMutations.SetFavoriteStatus, { target, status: enabled });
      await dispatch(new ShowNotification(i18n.t('notifications.setFavoriteSuccess').toString()), {
        root: true,
      });
    } catch (e) {
      console.error(e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.setFavoriteError').toString()), {
        root: true,
      });
    }
    return target;
  },

  async [UsersActions.AddAdminRole]({ commit, dispatch }, { user }) {
    try {
      const result = await api.post(`manage/admins/`, { id: user.id });
      if (result?.status === 201) {
        commit(UsersMutations.SetAdminFlag, { user, isAdmin: true });
        return true;
      }
    } catch (e) {
      console.error(e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
    return false;
  },

  async [UsersActions.RevokeAdminRole]({ commit, dispatch }, { user }) {
    try {
      const result = await api.delete(`manage/admins/${user.id}`);
      if ([200, 204].includes(result?.status)) {
        commit(UsersMutations.SetAdminFlag, { user, isAdmin: false });
        return true;
      }
    } catch (e) {
      console.error(e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
    return false;
  },

  async [UsersActions.DeleteUser]({ commit, dispatch }, { user }) {
    try {
      const result = await api.delete(`users/${getIdOrCurrent(user)}?hardDelete=${true}`);
      if ([200, 204].includes(result?.status)) {
        commit(UsersMutations.RemoveUser, user);
        await dispatch(new ShowNotification(i18n.t('notifications.userDeleteSuccess').toString()), {
          root: true,
        });
        return true;
      }
    } catch (e) {
      console.error('Error while DeleteUser', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
    return false;
  },
  async [UsersActions.SuspendUser]({ commit, dispatch }, { user }) {
    try {
      const result = await api.delete(`users/${getIdOrCurrent(user)}`);
      if ([200, 204].includes(result?.status)) {
        commit(UsersMutations.SuspendUser, user);
        await dispatch(new ShowNotification(i18n.t('notifications.userSuspensionSuccess').toString()), {
          root: true,
        });
        return true;
      }
    } catch (e) {
      console.error('Error while SuspendUser', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
    return false;
  },
  async [UsersActions.RestoreUser]({ commit, dispatch }, { user }) {
    try {
      const result = await api.post(`users/${getIdOrCurrent(user)}/restore`);
      if ([200, 201].includes(result?.status)) {
        commit(UsersMutations.RestoreUser, user);
        await dispatch(new ShowNotification(i18n.t('notifications.userRestoreSuccess').toString()), {
          root: true,
        });
        return true;
      }
    } catch (e) {
      console.error('Error while RestoreUser', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
    return false;
  },
  async [UsersActions.HideUser]({ commit, dispatch }, { user }) {
    try {
      const result = await api.put(`users/${getIdOrCurrent(user)}/hide`);
      if ([200, 201].includes(result?.status)) {
        commit(UsersMutations.HideUser, user);
        await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
          root: true,
        });
        return true;
      }
    } catch (e) {
      console.error('Error while HideUser', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
    return false;
  },
  async [UsersActions.UnHideUser]({ commit, dispatch }, { user }) {
    try {
      const result = await api.put(`users/${getIdOrCurrent(user)}/unhide`);
      if ([200, 201].includes(result?.status)) {
        commit(UsersMutations.UnHideUser, user);
        await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
          root: true,
        });
        return true;
      }
    } catch (e) {
      console.error('Error while UnHideUser', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
    return false;
  },

  async [UsersActions.LoadMembers]({ dispatch, commit }, { page, filter, status, roles, sortBy, sortOrder }) {
    try {
      const result = await api.get(`users`, { params: { page, filter, status, roles, sortBy, sortOrder } });
      commit(UsersMutations.SetMembers, result.data);
    } catch (e) {
      console.error('Error while LoadMembers', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [UsersActions.ExportMembers]({ dispatch }, { filters }) {
    try {
      const response = await api.get(`users/export`, { params: filters, responseType: 'blob' });
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      const fileName = response.headers['content-disposition']?.split('"')?.[1] ?? 'mentessa_members.csv';
      link.setAttribute('download', fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (e) {
      console.error('Error while ExportMembers', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [UsersActions.LoadNextStreamUser]({ dispatch, commit, state }) {
    try {
      commit(UsersMutations.SetStreamLoading, true);
      const response = await api.get(`users/random?seed=${state.usersStream.seed}&pos=${state.usersStream.position}`);
      const user = response.data as IUser;
      if (user) {
        await commit(UsersMutations.IncreaseStreamPosition);
        await commit(UsersMutations.AppendToStream, user);
      } else {
        await commit(UsersMutations.SetStreamFinished, true);
      }
      return user;
    } catch (e) {
      console.error('Error while LoadNextStreamUser', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    } finally {
      commit(UsersMutations.SetStreamLoading, false);
    }
  },

  async [UsersActions.GenerateDemoUsers]({ dispatch }) {
    try {
      await api.post('manage/debug/generateDemoUsers');
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
    } catch (e) {
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      console.error('Error while GenerateDemoUsers', e);
    }
  },

  async [UsersActions.GenerateDemoUsersSchedule]({ dispatch }) {
    try {
      await api.post('manage/debug/generateDemoUsersSchedule');
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
    } catch (e) {
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      console.error('Error while GenerateDemoUsersSchedule', e);
    }
  },

  async [UsersActions.RemoveDemoUsers]({ dispatch }) {
    try {
      await api.post('manage/debug/removeDemoUsers');
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
    } catch (e) {
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      console.error('Error while RemoveDemoUsers', e);
    }
  },

  async [UsersActions.AcceptRules]({ commit, dispatch }, { user }) {
    try {
      const result = await api.put(`users/${getIdOrCurrent(user)}/rulesAccepted`, { value: true });
      const rulesAccepted = result.data.result;
      commit(UsersMutations.SetRulesAccepted, rulesAccepted);
      return rulesAccepted;
    } catch (e) {
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      console.log('Error while AcceptRules', e);
    }
  },

  async [UsersActions.FindIncompleteUsers]({ dispatch }) {
    try {
      const result = await api.get('manage/debug/incompleteUsers');
      return result.data;
    } catch (e) {
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      console.log('Error while FindIncompleteUsers', e);
    }
  },

  async [UsersActions.FindNotFullProfileUsers]({ dispatch }) {
    try {
      const result = await api.get('manage/debug/usersWithLowProfile');
      return result.data;
    } catch (e) {
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      console.log('Error while FindUsersWithLowProfile', e);
    }
  },

  async [UsersActions.NotifyIncompleteUsers]({ dispatch }) {
    try {
      const result = await api.post('manage/debug/incompleteUsers');
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return result.data;
    } catch (e) {
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      console.log('Error while FindIncompleteUsers', e);
    }
  },

  async [UsersActions.NotifyNotFullProfileUsers]({ dispatch }) {
    try {
      const result = await api.post('manage/debug/usersWithLowProfile');
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return result.data;
    } catch (e) {
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      console.log('Error while FindUsersWithLowProfile', e);
    }
  },

  async [UsersActions.LoadTimeSlots]({ dispatch, commit }, { userRef }) {
    try {
      const response = await api.get(`users/${getIdOrCurrent(userRef)}/schedule`);
      const schedules: Array<ISchedule> = response.data ?? [];
      commit(UsersMutations.SetSchedules, schedules);
      return schedules;
    } catch (e) {
      console.log('Error while LoadTimeSlots', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  // ToDo: to remove
  async [UsersActions.LoadUserTimeSlots]({ dispatch }, { userRef }) {
    try {
      const response = await api.get(`users/${userRef.id}/attributes/mentoring`);
      return response.data?.result;
    } catch (e) {
      console.log('Error while LoadUserTimeSlots', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [UsersActions.LoadSchedule]({ dispatch }, { userRef, start, end }) {
    try {
      const response = await api.get(`users/${getIdOrCurrent(userRef)}/schedule/available`, { params: { start, end } });
      const schedules = response?.data;
      return schedules ?? false;
    } catch (e) {
      if (e.response?.status !== 404) {
        console.error('Error while GetSchedule.', e);
        await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
          root: true,
        });
      }
      return false;
    }
  },

  async [UsersActions.LoadUserStats]({ dispatch }, { userRef }) {
    try {
      const response = await api.get(`users/${getIdOrCurrent(userRef)}/stats`);
      return response.data;
    } catch (e) {
      console.log('Error while LoadUserStats', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [UsersActions.LoadUserChecklists]({ dispatch }, { userRef }) {
    try {
      const response = await api.get(`users/${getIdOrCurrent(userRef)}/checklists`);
      return response.data;
    } catch (e) {
      console.log('Error while LoadUserChecklists', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [UsersActions.LoadUserPersonalChecklist]({ dispatch }, { userRef }) {
    try {
      const response = await api.get(`users/${getIdOrCurrent(userRef)}/checklists/personal`);
      return response.data;
    } catch (e) {
      console.log('Error while LoadUserPersonalChecklist', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [UsersActions.CheckUserChecklistItem]({ dispatch }, { userRef, name }) {
    try {
      const response = await api.post(`users/${getIdOrCurrent(userRef)}/checklists/personal/check`, { [name]: true });
      return response.data;
    } catch (e) {
      console.log('Error while CheckUserChecklistItem', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [UsersActions.LoadManagers]({ dispatch }) {
    try {
      const response = await api.get(`users/managers`);
      return response.data;
    } catch (e) {
      console.log('Error while LoadManagers', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [UsersActions.TestCompleteStatus]({ commit }, { complete }) {
    commit(UsersMutations.SetMyComplete, complete);
  },
};
