<template>
  <div sentry-tag="matching-pick-pairs-form">
    <div class="d-flex flex-column align-center">
      <h2 class="text-center">
        {{ $t('matching.dialogTitle') }}
      </h2>
      <p class="text-center text--secondary mt-6">
        {{ $t('matching.chooseAPair') }}
      </p>
    </div>

    <m-ais-wrapper class="py-2 py-sm-6" index="users">
      <div v-for="(pair, index) in value.pairs" :key="index">
        <div class="d-flex py-2 align-sm-center">
          <div
            class="m-matching-pick-pairs-form__input-container d-flex justify-space-between align-center flex-wrap flex-sm-nowrap py-2"
          >
            <m-ais-autocomplete-users
              :label="$t('matching.chooseMember')"
              :label-top="firstMemberInputLabel(index)"
              :loading="isLoading"
              :placeholder="$t('matching.chooseMember')"
              :value="pair.left"
              class="px-2"
              hide-details
              hide-no-data
              @input="changeUser(index, 0, $event)"
            />
            <div v-if="$vuetify.breakpoint.smAndUp" :class="{ 'pt-6': !index }">
              <v-avatar size="26">
                <v-img :src="handshakeEmoji" />
              </v-avatar>
            </div>
            <m-ais-autocomplete-users
              :exclude="pair.left && [pair.left]"
              :label="$t('matching.chooseMember')"
              :label-top="secondMemberInputLabel(index)"
              :loading="isLoading"
              :placeholder="$t('matching.chooseMember')"
              :value="pair.right"
              class="px-2"
              hide-details
              hide-no-data
              @input="changeUser(index, 1, $event)"
            />
          </div>
          <div v-if="value.pairs.length > 1" :class="{ 'pt-6': !index }">
            <v-icon color="primary" @click="removePair(index)">close</v-icon>
          </div>
        </div>
        <v-divider v-if="index !== value.pairs.length - 1" class="cols-12 mt-5"></v-divider>
      </div>
      <v-btn
        :block="$vuetify.breakpoint.xsOnly"
        color="primary"
        large
        sentry-tag="matching-pick-pairs-form.button.addPair"
        text
        @click="addPair"
      >
        <v-icon left>add_circle_outline</v-icon>
        {{ $t('matching.addAnotherPair') }}
      </v-btn>
    </m-ais-wrapper>

    <template v-if="createSession">
      <div class="d-flex flex-row flex-grow-1 align-center px-2">
        <span>{{ $t('matching.memberRole.left') }}:</span>
        <m-select v-model="rolesLeft" :items="availableRoles" class="m-role-picker mx-4" dense>
          <template v-slot:selection="{ item }"> {{ $t(`mentoring.role.${item}`) }}</template>
          <template v-slot:item="{ item }">
            {{ $t(`mentoring.role.${item}`) }}
          </template>
        </m-select>
      </div>
      <div class="d-flex flex-row flex-grow-1 align-center px-2 mt-2">
        <span>{{ $t('matching.memberRole.right') }}:</span>
        <m-select v-model="rolesRight" :items="availableRoles" class="m-role-picker mx-4" dense>
          <template v-slot:selection="{ item }"> {{ $t(`mentoring.role.${item}`) }}</template>
          <template v-slot:item="{ item }">
            {{ $t(`mentoring.role.${item}`) }}
          </template>
        </m-select>
      </div>
    </template>
    <div class="text-center">
      <v-btn
        :block="$vuetify.breakpoint.xsOnly"
        :disabled="!isValid"
        :loading="isLoading"
        color="button"
        depressed
        large
        sentry-tag="matching-pick-pairs-form.button.match"
        width="139"
        @click="doMatch"
      >
        {{ $t('matching.btnMatch') }}
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IUser, MentoringRole } from '@mentessa/types';
import { MatchingPair } from '@/store/matching';
import { MAisAutocompleteUsers, MAisWrapper } from '@/components/MAIS';
import { MSelect } from '@/components/Inputs';

export interface PickPairsData {
  pairs: Array<MatchingPair>;
  roles: { left: MentoringRole; right: MentoringRole };
  createSession: boolean;
}

export default Vue.extend({
  name: 'm-matching-pick-pairs-form',
  components: {
    MSelect,
    MAisWrapper,
    MAisAutocompleteUsers,
  },
  props: {
    value: {
      type: Object as PropType<PickPairsData>,
      default: () => ({
        pairs: [{ left: undefined, right: undefined }],
        roles: {
          left: MentoringRole.Peer,
          right: MentoringRole.Peer,
        },
        createSession: true,
      }),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    availableRoles: [MentoringRole.Peer, MentoringRole.Mentor, MentoringRole.Mentee],
  }),
  computed: {
    createSession: {
      get() {
        return this.value.createSession;
      },
      set(value: boolean) {
        this.$emit('input', { ...this.value, createSession: value });
      },
    },
    rolesLeft: {
      get() {
        return this.value.roles.left;
      },
      set(value: MentoringRole) {
        const roles = {
          left: value,
          right:
            value === MentoringRole.Mentor
              ? MentoringRole.Mentee
              : value === MentoringRole.Mentee
              ? MentoringRole.Mentor
              : value,
        };
        this.$emit('input', { ...this.value, roles });
      },
    },
    rolesRight: {
      get() {
        return this.value.roles.right;
      },
      set(value: MentoringRole) {
        const roles = {
          left:
            value === MentoringRole.Mentor
              ? MentoringRole.Mentee
              : value === MentoringRole.Mentee
              ? MentoringRole.Mentor
              : value,
          right: value,
        };
        this.$emit('input', { ...this.value, roles });
      },
    },
    handshakeEmoji() {
      return require('@/assets/handshake-emoji.png');
    },
    excludedUsers() {
      return this.value.pairs.flatMap((pair) => {
        const excludedUsers = [];
        if (pair.left) excludedUsers.push(pair.left);
        if (pair.right) excludedUsers.push(pair.right);
        return excludedUsers;
      });
    },
    isValid() {
      let validPairs = 0;
      let invalidPairs = 0;
      this.value.pairs.forEach((pair) => {
        if (Boolean(pair.left) && Boolean(pair.right)) {
          ++validPairs;
        } else if (!pair.left && !pair.right) {
          // Skip empty pair
        } else ++invalidPairs;
      });
      return validPairs > 0 && invalidPairs === 0;
    },
  },
  methods: {
    changeUser(pairIndex: number, userIndex: number, user: IUser) {
      if (pairIndex >= this.value.pairs.length) {
        console.error(`Pair with index ${pairIndex} not exists`);
        return;
      }
      if (userIndex > 1) {
        console.error(`Only 2 users can be paired`);
        return;
      }

      const pairs = this.value.pairs.slice(0, pairIndex);
      pairs.push({
        left: userIndex === 0 ? user : this.value.pairs[pairIndex].left,
        right: userIndex === 1 ? user : this.value.pairs[pairIndex].right,
      });
      pairs.push(...this.value.pairs.slice(pairIndex + 1));

      this.$emit('input', { ...this.value, pairs });
    },
    addPair() {
      this.$emit('input', { ...this.value, pairs: [...this.value.pairs, { left: undefined, right: undefined }] });
    },
    removePair(index: number) {
      this.$emit('input', {
        ...this.value,
        pairs: [...this.value.pairs.slice(0, index), ...this.value.pairs.slice(index + 1)],
      });
    },
    doMatch() {
      this.$emit('onNext');
    },
    firstMemberInputLabel(index) {
      return !this.$vuetify.breakpoint.xsOnly && index ? '' : this.$t('matching.members.left');
    },
    secondMemberInputLabel(index) {
      return !this.$vuetify.breakpoint.xsOnly && index ? '' : this.$t('matching.members.right');
    },
  },
});
</script>

<style lang="scss" scoped>
.m-matching-pick-pairs-form {
  &__input-container {
    width: 100%;
  }
}

.ais-Autocomplete {
  width: 100%;
}

.m-role-picker {
  max-width: 110px;
}
</style>
