<template>
  <div>
    <v-col>
      <v-row>
        <v-btn class="ma-4" sentry-tag="settings-debug.button.generateDemoUsers" @click="generateDemoUsers">
          Generate Demo users
        </v-btn>
        <v-btn class="ma-4" @click="generateSchedule"> Generate schedule for demo users</v-btn>
        <v-btn class="ma-4" color="error" sentry-tag="settings-debug.button.removeDemoUsers" @click="removeDemoUsers">
          Remove Demo users
        </v-btn>
      </v-row>

      <v-row>
        <v-btn class="ma-4" @click="findIncompleteUsers">Find Incomplete</v-btn>
        <v-btn class="ma-4" @click="findUsersWithLowProfile">Find With Low Profile</v-btn>
      </v-row>

      <v-row v-if="false">
        <v-btn class="ma-4" @click="notifyIncompleteUsers">Notify Incomplete</v-btn>
        <v-btn class="ma-4" @click="notifyUsersWithLowProfile">Notify Not Full Profile</v-btn>
      </v-row>

      <v-row>
        <v-btn class="ma-4" @click="markIncomplete">Mark myself incomplete (Locally)</v-btn>
      </v-row>

      <v-row>
        <v-col> Default users:</v-col>
        <p>TBD</p>
      </v-row>

      <v-row class="my-4">
        <v-tabs v-model="tabImport">
          <v-tab>Generic CSV</v-tab>
          <v-tab>Generic JSON</v-tab>
          <v-tab>Luther</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabImport" class="ma-4">
          <v-tab-item>
            <m-import-users />
          </v-tab-item>
          <v-tab-item>
            <m-import-users-json />
          </v-tab-item>
          <v-tab-item>
            <m-import-users-luther />
          </v-tab-item>
        </v-tabs-items>
      </v-row>
    </v-col>
  </div>
</template>

<script lang="ts">
import {
  FindIncompleteUsers,
  FindUsersWithLowProfile,
  GenerateDemoUsers,
  GenerateDemoUsersSchedule,
  NotifyIncompleteUsers,
  NotifyNotFullProfileUsers,
  RemoveDemoUsers,
  TestCompleteStatus,
} from '@/store/users';
import Vue from 'vue';
import MImportUsers from '@/components/Settings/MSettingsDebug/forms/MImportUsers.vue';
import MImportUsersLuther from '@/components/Settings/MSettingsDebug/forms/MImportUsersLuther.vue';
import MImportUsersJson from '@/components/Settings/MSettingsDebug/forms/MImportUsersJSON.vue';

export default Vue.extend({
  name: 'm-settings-debug-users',
  components: { MImportUsersJson, MImportUsersLuther, MImportUsers },
  data: () => ({
    tabImport: undefined,
  }),
  methods: {
    async generateDemoUsers() {
      await this.$store.dispatch(new GenerateDemoUsers());
    },
    async generateSchedule() {
      await this.$store.dispatch(new GenerateDemoUsersSchedule());
    },
    async removeDemoUsers() {
      await this.$store.dispatch(new RemoveDemoUsers());
    },
    async findIncompleteUsers() {
      const users = await this.$store.dispatch(new FindIncompleteUsers());
      console.log(users);
    },
    async findUsersWithLowProfile() {
      const users = await this.$store.dispatch(new FindUsersWithLowProfile());
      console.log(users);
    },
    async notifyIncompleteUsers() {
      await this.$store.dispatch(new NotifyIncompleteUsers());
    },
    async notifyUsersWithLowProfile() {
      await this.$store.dispatch(new NotifyNotFullProfileUsers());
    },
    markIncomplete() {
      this.$store.dispatch(new TestCompleteStatus(false));
    },
  },
});
</script>
