import store from '@/store';
import { Route } from 'vue-router';
import { NavigationGuardNext } from 'vue-router/types/router';
import { RouteName } from '@/router/types';

export function homeGuard(to: Route, from: Route, next: NavigationGuardNext): boolean {
  if (!store.getters['tenant/isHomeAvailable']) {
    next({ name: RouteName.Community });
  } else {
    next();
  }
  return true;
}
