<template>
  <div>
    <h4>{{ $t('dashboard.widgets.ritual_goals.title') }}</h4>
    <v-row class="ma-0 fill-height align-center">
      <v-col>
        <v-row class="ma-0 align-center">
          <h1>{{ created }}</h1>
          <span class="ml-2">{{ $t('dashboard.widgets.ritual_goals.created') }}</span>
        </v-row>
      </v-col>
      <v-col>
        <v-row class="ma-0 align-center">
          <h1>{{ completed }}</h1>
          <span class="ml-2">{{ $t('dashboard.widgets.ritual_goals.completed') }}</span>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

export interface RitualGoalsData {
  ritual_goals: Array<{ completed: string; total: string; without_tasks: string }>;
}

export default Vue.extend({
  name: 'm-ritual-goals-widget',
  props: {
    value: { type: Object as PropType<RitualGoalsData> },
  },
  data: () => ({
    created: 0,
    completed: 0,
    empty: 0,
  }),
  async mounted() {
    const values = this.value?.ritual_goals?.[0];
    this.completed = Number(values?.completed ?? 0);
    this.created = Number(values?.total ?? 0);
    this.empty = Number(values?.without_tasks ?? 0);
  },
});
</script>
