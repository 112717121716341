import { Action } from '@/store/types';
import { TenantReference } from '@mentessa/types';

export const moduleName = 'mobile';

export interface MobileState {
  companyCode: { id: number; code: string };
}

export enum MobileActions {
  LoadCompanyCode = '[MOBILE] Load Company Code',
  UpdateCompanyCode = '[MOBILE] Update Company Code',
  CreateCompanyCode = '[MOBILE] Create Company Code',
  LoadMobileUsers = '[MOBILE] Load Mobile Users',
  SendPushNotification = '[MOBILE] Send Push Notification',
  SendBroadcastPushNotification = '[MOBILE] Send Broadcast Push Notification',
  ResetChallenges = '[MOBILE] Reset Challenges',
  RecalculateSkills = '[MOBILE] Recalculate Skills',
  RecalculateAnalytics = '[MOBILE] Recalculate Analytics',
  RecalculateAll = '[MOBILE] Recalculate All',
  RecalculateSkillsForAll = '[MOBILE] Recalculate Skills For All',
  RecalculateAnalyticsForAll = '[MOBILE] Recalculate Analytics For All',
  RecalculateSkillsAndAnalyticsForAll = '[MOBILE] Recalculate Skills And Analytics For All',
}

export enum MobileMutations {
  SetCompanyCode = '[MOBILE] Set Company code',
}

export class LoadCompanyCode implements Action {
  type = `${moduleName}/${MobileActions.LoadCompanyCode}`;

  constructor(private readonly tenantRef?: TenantReference) {}
}

export class UpdateCompanyCode implements Action {
  type = `${moduleName}/${MobileActions.UpdateCompanyCode}`;

  constructor(
    private readonly codeRef: {
      id: number;
    },
    private readonly code: string,
    private readonly tenantRef?: TenantReference,
  ) {}
}

export class CreateCompanyCode implements Action {
  type = `${moduleName}/${MobileActions.CreateCompanyCode}`;

  constructor(private readonly code: string, private readonly tenantRef?: TenantReference) {}
}

export interface LoadMobileUsersOptions {
  page?: number;
  query?: string;
  orderBy?: string;
  orderDir?: 'ASC' | 'DESC';
}

export class LoadMobileUsers implements Action {
  type = `${moduleName}/${MobileActions.LoadMobileUsers}`;

  constructor(private readonly options?: LoadMobileUsersOptions, private readonly tenantRef?: TenantReference) {}
}

export interface SendPushNotificationOptions {
  content: {
    title: string;
    text: string;
  };
}

export interface SendPushNotificationTargetOptions extends SendPushNotificationOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  users: Array<Record<string, any>>;
}

export class SendPushNotification implements Action {
  type = `${moduleName}/${MobileActions.SendPushNotification}`;

  constructor(private readonly options?: SendPushNotificationTargetOptions) {}
}

export class SendBroadcastPushNotification implements Action {
  type = `${moduleName}/${MobileActions.SendBroadcastPushNotification}`;

  constructor(private readonly options?: SendPushNotificationOptions) {}
}

export class ResetChallenges implements Action {
  type = `${moduleName}/${MobileActions.ResetChallenges}`;

  constructor(private readonly mobileUserRef: { id: number }) {}
}

export class RecalculateAnalytics implements Action {
  type = `${moduleName}/${MobileActions.RecalculateAnalytics}`;

  constructor(private readonly mobileUserRef: { id: number }) {}
}

export class RecalculateSkills implements Action {
  type = `${moduleName}/${MobileActions.RecalculateSkills}`;

  constructor(private readonly mobileUserRef: { id: number }) {}
}

export class RecalculateAll implements Action {
  type = `${moduleName}/${MobileActions.RecalculateAll}`;

  constructor(private readonly mobileUserRef: { id: number }) {}
}

export class RecalculateAnalyticsForAll implements Action {
  type = `${moduleName}/${MobileActions.RecalculateAnalyticsForAll}`;

  constructor(private readonly options: { skipAnalyze?: boolean } = {}) {}
}

export class RecalculateSkillsForAll implements Action {
  type = `${moduleName}/${MobileActions.RecalculateSkillsForAll}`;

  constructor(private readonly options: { skipAnalyze?: boolean } = {}) {}
}

export class RecalculateSkillsAndAnalyticsForAll implements Action {
  type = `${moduleName}/${MobileActions.RecalculateSkillsAndAnalyticsForAll}`;

  constructor(private readonly options: { skipAnalyze?: boolean } = {}) {}
}
