<template>
  <div class="font-weight-medium py-1" sentry-tag="appointment-message">
    <div class="d-flex pl-0">
      <span class="d-inline-flex align-center">
        {{ title }}
      </span>
    </div>
    <p v-if="hasText" class="pl-4 ma-0" v-html="text" />
    <v-divider class="mt-2 mb-2" />
    <span class="mr-2">{{ $t('messages.addToCalendar') }}:</span>
    <v-spacer v-if="$vuetify.breakpoint.smAndDown" class="my-1" />
    <v-tooltip v-if="appointmentsConfig.google" bottom nudge-top="5">
      <template v-slot:activator="{ on }">
        <v-btn
          :href="calendarLink('google')"
          class="ma-1 pa-3"
          sentry-tag="appointment-message.calendar.google"
          small
          target="_blank"
          v-on="on"
        >
          <v-img :src="require('@/assets/calendar/google.svg')" contain height="30" width="12" />
        </v-btn>
      </template>
      <span>{{ $t('messages.calendar.google') }}</span>
    </v-tooltip>
    <v-tooltip v-if="appointmentsConfig.outlook" bottom nudge-top="5">
      <template v-slot:activator="{ on }">
        <v-btn
          :href="calendarLink('outlook')"
          class="ma-1"
          sentry-tag="appointment-message.calendar.outlook"
          small
          target="_blank"
          v-on="on"
        >
          <v-img :src="require('@/assets/calendar/outlook.svg')" contain height="24" width="12" />
        </v-btn>
      </template>
      <span>{{ $t('messages.calendar.outlook') }}</span>
    </v-tooltip>
    <v-tooltip v-if="appointmentsConfig.apple" bottom nudge-top="5">
      <template v-slot:activator="{ on }">
        <v-btn
          :href="calendarLink('ics')"
          class="ma-1"
          sentry-tag="appointment-message.calendar.apple"
          small
          target="_blank"
          v-on="on"
        >
          <v-img :src="require('@/assets/calendar/apple.svg')" contain height="20" width="12" />
        </v-btn>
      </template>
      <span>{{ $t('messages.calendar.apple') }}</span>
    </v-tooltip>
    <v-tooltip v-if="appointmentsConfig.ics" bottom nudge-top="5">
      <template v-slot:activator="{ on }">
        <v-btn
          :href="calendarLink('ics')"
          class="ma-1"
          download="invitation.ics"
          sentry-tag="appointment-message.calendar.ics"
          small
          target="_blank"
          v-on="on"
        >
          <v-icon>download</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('messages.calendar.apple') }}</span>
    </v-tooltip>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { google, office365, outlook, yahoo } from 'calendar-link';
import { CalendarEvent } from 'calendar-link/dist/interfaces';
import { MessageAppointmentAttributes, MessageEntity, RoomMemberEntity } from '@mentessa/types';
import { getFullName } from '@/utils/user';
import { mapGetters, mapState } from 'vuex';
import { TenantState } from '@/store/tenant';
import { linkifyHtml } from '@/utils/linkify';
import { UsersState } from '@/store/users';
import { createEvent, EventAttributes } from 'ics';
import { getDate, getHours, getMinutes, getMonth, getYear, parseISO } from 'date-fns';

export default Vue.extend({
  name: 'm-appointment-message',
  props: {
    message: { type: Object as PropType<MessageEntity>, default: () => ({}) },
    members: { type: Array as PropType<RoomMemberEntity[]>, default: () => [] },
  },
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    ...mapState<TenantState>('tenant', {
      tenantTitle: (state: TenantState) => state.tenant.attributes.theme.title,
      appointmentsConfig: (state: TenantState) => ({
        google: true,
        outlook: true,
        apple: false,
        ics: true,
        ...state.tenant.attributes.messages?.appointment,
      }),
    }),
    ...mapGetters('ui', {
      formatInUserTimeZone: 'formatInUserTimeZone',
    }),
    title(): string {
      return this.$t('timeSlots.appointmentRequests', {
        date: this.formatInUserTimeZone(this.message.attributes.appointment.date, 'EEE, MMMM d, yyyy, H:mm'),
      });
    },

    hasText() {
      return this.message?.text?.length > 0;
    },
    text() {
      return this.$marked(this.linkify(this.message.text));
    },
  },
  methods: {
    calendarLink(target) {
      const guests = this.members.filter((member: RoomMemberEntity) => member.user);
      const startDate = parseISO((this.message.attributes as MessageAppointmentAttributes).appointment.date.toString());
      if (target === 'ics') {
        const event: EventAttributes = {
          attendees: guests.map((guest) => ({
            email: guest.user.identity.attributes.email,
            name: getFullName(guest.user),
            role: 'REQ-PARTICIPANT',
          })),
          description: this.message.text,
          duration: { hours: 1 },
          start: [
            getYear(startDate),
            getMonth(startDate),
            getDate(startDate),
            getHours(startDate),
            getMinutes(startDate),
          ],
          title: `${this.tenantTitle} - 1:1 with ${getFullName(this.members[0].user)}`,
        };
        const content = createEvent(event);
        const encodedContent = encodeURIComponent(content.value);

        return `data:text/calendar;charset=utf8,${encodedContent}`;
      }

      const event: CalendarEvent = {
        title: `${this.tenantTitle} - 1:1 with ${getFullName(this.members[0].user)}`,
        description: this.message.text,
        start: (this.message.attributes as MessageAppointmentAttributes).appointment.date,
        duration: [1, 'hour'],
        guests: guests.map((guest) => guest.user.identity.attributes.email),
      };

      return target === 'google'
        ? google(event)
        : target === 'outlook'
        ? outlook(event)
        : target === 'office365'
        ? office365(event)
        : target === 'yahoo'
        ? yahoo(event)
        : undefined;
    },
    getFullName: getFullName,
    linkify: linkifyHtml,
  },
});
</script>
