<template>
  <div class="m-settings-integration-card mb-8">
    <v-row>
      <v-col class="text-center logo-container" cols="2">
        <v-img class="discord-logo" src="@/assets/calendar/office-365.svg" />
      </v-col>
      <v-col class="d-flex align-center">
        <p class="font-weight-bold text-h6 ma-0">Microsoft 365</p>
      </v-col>
      <v-col cols="2">
        <v-btn color="button" depressed large @click="connect">
          {{ discordIntegration ? $t('settings.integrations.edit') : $t('settings.integrations.connect') }}
        </v-btn>
      </v-col>
      <v-col v-if="discordIntegration" cols="2">
        <v-btn :loading="loading" color="red" depressed large @click="disconnect">
          {{ $t('settings.integrations.disconnect') }}
        </v-btn>
      </v-col>
    </v-row>
    <m-discord-dialog v-model="open" :discordIntegration="discordIntegration" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { MDiscordDialog } from '@/components/Dialogs';
import { DeleteIntegration } from '@/store/tenant';
import { IIntegration } from '@mentessa/types';
import { PublicClientApplication } from '@azure/msal-browser';
import { mapState } from 'vuex';
import { UsersState } from '@/store/users';

export default Vue.extend({
  name: 'm-ms365-integration',
  props: {
    discordIntegration: Object as PropType<IIntegration>,
  },
  components: {
    MDiscordDialog,
  },
  data: () => ({
    open: false,
    loading: false,
  }),
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
  },
  mounted() {
    //
  },
  methods: {
    async connect() {
      try {
        const hostParts = window.location.host.split('.');
        const targetDomain = hostParts[0];
        hostParts[0] = 'redirect';
        const msalConfig = {
          auth: {
            clientId: '',
            authority: '',
            redirectUri: `https://${hostParts.join('.')}/ms`,
          },
        };

        const msal = new PublicClientApplication(msalConfig);

        await msal.initialize();
        await msal.handleRedirectPromise();

        const accounts = msal.getAllAccounts();

        if (accounts.length > 10) {
          console.log(accounts);
        } else {
          const response = await msal.loginPopup({
            redirectUri: window.location.href,
            scopes: ['User.Read', 'Calendars.ReadWrite'],
            extraQueryParameters: {
              client: targetDomain,
              user: this.me.id,
            },
            tokenBodyParameters: {
              client: targetDomain,
              user: this.me.id,
            },
            state: JSON.stringify({
              client: targetDomain,
              user: this.me.id,
            }),
          });
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async disconnect() {
      this.loading = true;
      try {
        if (!this.discordServerId) {
          await this.$store.dispatch(new DeleteIntegration(this.discordIntegration));
        }
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.m-settings-integration-card {
  width: 75%;
  padding: 24px;
  background-color: var(--v-white-base);
  border: 1px solid var(--v-beige-base);
  border-radius: 16px;
}

.logo-container {
  display: grid;
  justify-content: center;
}

.discord-logo {
  width: 50px;
  height: 50px;
  background-color: var(--v-discord-base);
}
</style>
