<template>
  <div>
    <v-col class=".m-admin-container">
      <v-tabs optional show-arrows>
        <v-tab v-for="{ title, link, sentryTag } in tabs" :key="title" :sentry-tag="sentryTag" :to="link">
          {{ title }}
        </v-tab>
      </v-tabs>
      <router-view />
    </v-col>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { RouteName } from '@/router/types';

export default Vue.extend({
  name: 'm-settings-features',
  data: () => ({
    tabs: [
      {
        title: 'Email',
        link: { name: RouteName.SettingsFeaturesEmail },
        sentryTag: 'settings-features.tab.email',
      },
      {
        title: 'Community rules',
        link: { name: RouteName.SettingsFeaturesCommunityRules },
        sentryTag: 'settings-features.tab.community-rules',
      },
      {
        title: 'Content',
        link: { name: RouteName.SettingsFeaturesContent },
        sentryTag: 'settings-features.tab.content',
      },
      {
        title: 'Resources',
        link: { name: RouteName.SettingsFeaturesResources },
        sentryTag: 'settings-features.tab.resources',
      },
      {
        title: 'Mobile',
        link: { name: RouteName.SettingsFeaturesMobile },
        sentryTag: 'settings-features.tab.mobile',
      },
      {
        title: 'Home',
        link: { name: RouteName.SettingsFeaturesHome },
        sentryTag: 'settings-features.tab.home',
      },
      {
        title: 'Integrations',
        link: { name: RouteName.SettingsFeaturesIntegrations },
        sentryTag: 'settings-features.tab.integrations',
      },
      {
        title: 'User Integrations',
        link: { name: RouteName.SettingsFeaturesUserIntegrations },
        sentryTag: 'settings-features.tab.userIntegrations',
      },
      {
        title: 'Invites',
        link: { name: RouteName.SettingsFeaturesInvites },
        sentryTag: 'settings-features.tab.invites',
      },
      {
        title: 'Customization',
        link: { name: RouteName.SettingsFeaturesCustomization },
        sentryTag: 'settings-features.tab.customization',
      },
      {
        title: 'Dashboard',
        link: { name: RouteName.SettingsFeaturesDashboard },
        sentryTag: 'settings-features.tab.dashboard',
      },
      {
        title: 'Messages',
        link: { name: RouteName.SettingsFeaturesMessages },
        sentryTag: 'settings-features.tab.messages',
      },
      {
        title: 'Rituals',
        link: { name: RouteName.SettingsFeaturesRituals },
        sentryTag: 'settings-features.tab.rituals',
      },
      {
        title: 'Matching',
        link: { name: RouteName.SettingsFeaturesMatching },
        sentryTag: 'settings-features.tab.matching',
      },
      {
        title: 'Mentoring',
        link: { name: RouteName.SettingsFeaturesMentoring },
        sentryTag: 'settings-features.tab.mentoring',
      },
      {
        title: 'Profile',
        link: { name: RouteName.SettingsFeaturesProfile },
        sentryTag: 'settings-features.tab.profile',
      },
      {
        title: 'Users',
        link: { name: RouteName.SettingsFeaturesUsers },
        sentryTag: 'settings-features.tab.users',
      },
      {
        title: 'NPS',
        link: { name: RouteName.SettingsFeaturesNps },
        sentryTag: 'settings-features.tab.nps',
      },
    ],
  }),
});
</script>
