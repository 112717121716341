import { Module } from 'vuex';
import { RootState, StoreDataState } from '@/store/types';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { MentoringState, moduleName } from './types';

export * from './types';

export function mentoringGetter(getter: string) {
  return `${moduleName}/${getter}`;
}

export const initialState: MentoringState = {
  currentSessionId: undefined,
  sessions: {
    state: StoreDataState.Unknown,
    data: [],
  },
};

export const mentoringModule: Module<MentoringState, RootState> = {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};

export default mentoringModule;
