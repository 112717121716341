<template>
  <m-select v-model="localValue" :items="availableLocales" sentry-tag="ui-locale" v-bind="{ ...$props, ...$attrs }">
    <template v-slot:selection="data">
      <v-chip>
        {{ nameForLang(data.item) }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      {{ nameForLang(data.item) }}
    </template>
  </m-select>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import Vue from 'vue';
import MSelect from '@/components/Inputs/MSelect/MSelect.vue';
import { LoadTranslations } from '@/store/ui';
import i18n from '@/plugins/i18n';
import { InitGeonames, LoadLanguages } from '@/store/utils';

export default Vue.extend({
  name: 'm-select-ui-locale',
  components: {
    MSelect,
  },
  props: {
    value: { type: String },
  },
  computed: {
    ...mapGetters('utils', {
      nameForLang: 'nameForLang',
    }),
    ...mapGetters('tenant', {
      availableLocales: 'availableLocales',
    }),
    localValue: {
      get() {
        return this.value;
      },
      async set(value: string) {
        this.$i18n.locale = value;
        await this.$store.dispatch(new LoadTranslations(i18n.locale));
        await this.$store.dispatch(new LoadLanguages(i18n.locale));
        await this.$store.dispatch(new InitGeonames(i18n.locale));
        this.$emit('input', value);
      },
    },
  },
});
</script>
