<template>
  <m-panel :banner="isPending" max-width="480px">
    <v-row class="justify-center flex-column flex-sm-row ma-0">
      <v-col class="pa-0">
        <v-chip :color="isPending ? 'white' : 'beige'">
          <m-ritual-preview v-if="ritual" :ritual="ritual" max-width="240px" dense />
          <span v-else>{{ $t(`settings.matching.source.${source}`) }}</span>
        </v-chip>
        <m-user-preview :user="matchedUser" class="mt-4" @click="showUserDetailsDialog = true" />
      </v-col>

      <v-col class="flex-grow-0 pa-0 gap-2 d-flex flex-sm-column justify-center">
        <template v-if="isPending">
          <v-btn class="" color="button" outlined width="120" @click="declineMatch">
            {{ $t('cards.match.pending_match.decline') }}
          </v-btn>
          <v-btn color="button" width="120" @click="acceptMatch">{{ $t('cards.match.pending_match.accept') }}</v-btn>
        </template>
        <v-btn v-else-if="journal" :to="journalRoute" color="button" depressed>{{ $t('cards.journal.open') }}</v-btn>
        <v-btn v-else :to="viewProfileRoute" color="button" depressed outlined
          >{{ $t('mentoring.userCard.viewProfile') }}
        </v-btn>
      </v-col>
    </v-row>
    <m-match-action-dialog v-model="showMatchActionDialog" :action="matchAction" :match-id="matchId" />
    <m-ritual-match-action-dialog
      v-model="showRitualMatchActionDialog"
      :action="matchAction"
      :match-id="matchId"
      :ritual-id="matchRitualId"
    />
    <m-user-details-simple-dialog
      v-model="showUserDetailsDialog"
      :user-id="matchedUserId"
      @onMatchStateChanged="handleMatchStateChanged"
    />
  </m-panel>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IMatch, IMatchUser, MatchStatus } from '@mentessa/types';
import MPanel from '@/components/MPanel';
import MRitualPreview from '@/components/MRitualPreview';
import MUserPreview from '@/components/MUserPreview';
import { MMatchActionDialog, MRitualMatchActionDialog, MUserDetailsSimpleDialog } from '@/components/Dialogs';
import { RouteName } from '@/router/types';

export default Vue.extend({
  name: 'm-match-card-row',
  components: {
    MUserDetailsSimpleDialog,
    MUserPreview,
    MMatchActionDialog,
    MRitualMatchActionDialog,
    MRitualPreview,
    MPanel,
  },
  props: {
    value: Object as PropType<IMatchUser>,
  },
  data: () => ({
    showMatchActionDialog: false,
    showRitualMatchActionDialog: false,
    showUserDetailsDialog: false,
    matchAction: undefined,
  }),
  computed: {
    source() {
      return this.value?.match?.source;
    },
    matchedUser() {
      return this.value?.match?.matchUsers?.[0]?.user;
    },
    ritual() {
      return this.value?.match?.ritual;
    },
    matchId() {
      return this.value?.match?.id;
    },
    matchRitualId() {
      return this.ritual?.id;
    },
    matchedUserId() {
      return this.matchedUser?.id;
    },
    isPending() {
      return this.value?.match?.status === MatchStatus.Pending && this.value?.accepted === null;
    },
    journal() {
      return this.value?.match?.mentoringSessions?.[0];
    },
    journalRoute() {
      return this.journal ? { name: RouteName.MentoringSession, params: { sessionId: this.journal.id } } : undefined;
    },
    viewProfileRoute() {
      return {
        name: RouteName.CommunityUserDetails,
        params: { userId: this.matchedUserId },
      };
    },
  },
  watch: {
    showMatchActionDialog(value: boolean) {
      if (!value) {
        this.$emit('onStateChanged');
      }
    },
    showRitualMatchActionDialog(value: boolean) {
      if (!value) {
        this.$emit('onStateChanged');
      }
    },
  },
  methods: {
    async acceptMatch() {
      this.matchAction = 'accept';
      if (this.ritual) {
        this.showRitualMatchActionDialog = true;
      } else {
        this.showMatchActionDialog = true;
      }
    },
    async declineMatch() {
      this.matchAction = 'decline';
      if (this.ritual) {
        this.showRitualMatchActionDialog = true;
      } else {
        this.showMatchActionDialog = true;
      }
    },
    handleMatchStateChanged(match: IMatch) {
      if (match.id === this.matchId) {
        this.$emit('onStateChanged');
      }
    },
  },
});
</script>

<style lang="scss" scoped></style>
