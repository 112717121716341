<template>
  <div>
    <v-card class="pa-4 my-4">
      <v-card-title>User limit</v-card-title>
      <v-card-text>
        The maximum amount of users which can be created.
        <p class="error--text mb-0">Applied only to self-registered and 3rd party SSO provideed users at this moment</p>
      </v-card-text>
      <v-text-field v-model="limit" dense label="limit" outlined type="number" />
    </v-card>
    <v-card class="pa-4 mb-4">
      <v-card-title>Self registration</v-card-title>
      <v-card-text>
        Enables the "sign up" functionnality on Keycloak
        <p class="error--text mb-0">Do not affect 3rd-party SSO and LinkedIn</p>
      </v-card-text>
      <v-switch v-model="selfRegistrationEnabled" hide-details label="Enabled" />
      <v-switch v-if="false" v-model="selfRegistrationModerated" label="Moderated" />
      <v-card class="pa-4 mt-4">
        <v-card-title>Socials</v-card-title>
        <v-card-text> Enables LinkedIn</v-card-text>
        <v-switch v-model="socials" hide-details label="Enabled" />
      </v-card>
    </v-card>
    <v-card class="pa-4 mb-4">
      <v-card-title>3rd-party SSO</v-card-title>
      <v-card-text>
        Configuration for the external user provider
        <p class="error--text mb-0">The provider itself should be configured on Keycloak side</p>
      </v-card-text>
      <v-card class="pa-4">
        <v-card-title>Roles</v-card-title>
        <v-card-text>
          Configuration for roles which came from SSO
          <p class="error--text mb-0">
            Warning: those settings should be synchronized with SSO and will break the entire tenant if will be used
            without very clear purpose
          </p>
        </v-card-text>
        <h5>Require role</h5>
        <span class="text-body-2">Will block user access if no role provided from SSO</span>
        <v-switch v-model="roleRequired" hide-details label="Enabled" />
        <h5 class="mt-4">Strict roles</h5>
        <span class="text-body-2">If enabled then any non SSO-provided will be removed from users</span>
        <v-switch v-model="strictRoles" hide-details label="Enabled" />
        <h5 class="mt-4">Check access field</h5>
        <span class="text-body-2"
          >Will block user with <i>hasAccess=false</i> (or if it does not exists) flag from SSO</span
        >
        <v-switch v-model="checkAccess" hide-details label="Enabled" />
      </v-card>

      <v-card class="pa-4 mt-4">
        <v-card-title>Identity Providers</v-card-title>
        <v-card-text>
          Configuration for external user sources
          <p class="error--text mb-0">
            Warning: those settings should be synchronized with SSO and will break the entire tenant if will be used
            without very clear purpose
          </p>
        </v-card-text>
        <h5>List of allowed providers:</h5>
        <span class="text-body-2"> Will not allow new users from not-selected providers </span>
        <m-combobox v-model="allowedProviders" :items="providers" />
      </v-card>
    </v-card>
    <v-btn class="float-right" color="button" depressed min-width="128" @click="save">Save</v-btn>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { ChangeFeatureConfig, TenantState } from '@/store/tenant';
import MCombobox from '@/components/Inputs/MCombobox/MCombobox.vue';
import { UsersFeature } from '@mentessa/types';

export default Vue.extend({
  name: 'm-settings-features-users',
  components: { MCombobox },
  data: () => ({
    selfRegistrationEnabled: false,
    selfRegistrationModerated: false,
    socials: false,
    roleRequired: false,
    strictRoles: false,
    checkAccess: false,
    limit: undefined,
    providers: [],
    allowedProviders: [],
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    ...mapGetters('tenant', {
      featureConfig: 'featureConfig',
    }),
    usersConfig() {
      return this.featureConfig('users') as UsersFeature;
    },
  },
  mounted() {
    this.updateData();
  },
  methods: {
    async save() {
      const config: Record<string, unknown> = {};

      if (this.selfRegistrationEnabled !== !!this.usersConfig.selfRegistration?.enabled) {
        config.selfRegistration = { enabled: this.selfRegistrationEnabled };
      }

      if (this.socials !== !!this.usersConfig.socials?.enabled) {
        config.socials = { enabled: this.socials };
      }

      if (this.selfRegistrationModerated !== !!this.usersConfig.selfRegistration?.moderated) {
        if (config.selfRegistration == null) {
          config.selfRegistration = {};
        }
        config.selfRegistration['moderated'] = this.selfRegistrationModerated;
      }

      if (this.roleRequired !== !!this.usersConfig.roleRequired) {
        config.roleRequired = this.roleRequired;
      }

      if (this.strictRoles !== !!this.usersConfig.strictRoles) {
        config.strictRoles = this.strictRoles;
      }

      if (this.checkAccess !== !!this.usersConfig.checkAccess) {
        config.checkAccess = this.checkAccess;
      }

      if (this.limit !== this.usersConfig.limit && (!!this.limit || !!this.usersConfig.limit)) {
        config.limit = this.limit;
      }

      if (!this.usersConfig.allowedProviders) {
        config.allowedProviders = this.allowedProviders;
      }

      if (Object.keys(config).length > 0) {
        await this.$store.dispatch(new ChangeFeatureConfig(this.tenant, 'users', config));
      }
    },
    updateData() {
      this.selfRegistrationEnabled = this.usersConfig.selfRegistration?.enabled;
      this.selfRegistrationModerated = this.usersConfig.selfRegistration?.moderated;
      this.roleRequired = this.usersConfig.roleRequired;
      this.strictRoles = this.usersConfig.strictRoles;
      this.checkAccess = this.usersConfig.checkAccess;
      this.limit = this.usersConfig.limit;
      this.providers = ['direct', ...(this.usersConfig.allowedProviders ?? [])];
      this.allowedProviders = this.usersConfig.allowedProviders ?? [];
      this.socials = this.usersConfig.socials?.enabled ?? false;
    },
  },
});
</script>
