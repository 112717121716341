import { MutationTree } from 'vuex';
import { ResourcesMutations, ResourcesState } from './types';
import { IResource, ResourcesPage } from '@mentessa/types';

export const mutations: MutationTree<ResourcesState> = {
  [ResourcesMutations.SetResources](state: ResourcesState, resources: ResourcesPage) {
    state.resources = resources;
  },
  [ResourcesMutations.UpdateResource](state: ResourcesState, resource: IResource) {
    state.resources.items = state.resources.items.map((resourceItem) => {
      if (resourceItem.id === resource.id) {
        return { ...resource };
      } else {
        return resourceItem;
      }
    });
  },
  [ResourcesMutations.RemoveResource](state: ResourcesState, resource: IResource) {
    state.resources.items = state.resources.items.filter((resourceItem) => resourceItem.id !== resource.id);
  },
};
