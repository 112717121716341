<template>
  <div>
    <m-feature-enable-switch feature="email" />
    <v-card class="pa-4">
      <v-switch v-model="fetchAuto" label="fetchAuto" />
      <v-text-field v-model="fetchInterval" dense label="fetchInterval" outlined />
      <v-divider class="mb-4"></v-divider>
      <v-btn color="button" depressed @click="save">Save</v-btn>
    </v-card>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { ChangeFeatureConfig, TenantState } from '@/store/tenant';
import { MessagesFeature } from '@mentessa/types';
import MFeatureEnableSwitch from '@/components/Settings/MSettingsFeatures/MFeatureEnableSwitch';

export default Vue.extend({
  name: 'm-settings-features-messages',
  components: { MFeatureEnableSwitch },
  data: () => ({
    fetchAuto: false,
    fetchInterval: undefined,
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    ...mapGetters('tenant', {
      featureConfig: 'featureConfig',
    }),
    messagesConfig() {
      return this.featureConfig('messages');
    },
  },
  mounted() {
    this.updateData();
  },
  methods: {
    async save() {
      const config: Partial<MessagesFeature> = {};

      if (!!this.fetchAuto !== !!this.messagesConfig.fetchAuto) {
        config.fetchAuto = this.fetchAuto;
      }

      if (
        this.fetchInterval !== this.messagesConfig.fetchInterval &&
        (!!this.fetchInterval || !!this.messagesConfig.fetchInterval)
      ) {
        config.fetchInterval = this.fetchInterval;
      }

      if (JSON.stringify(config) !== '{}') {
        await this.$store.dispatch(new ChangeFeatureConfig(this.tenant, 'messages', config));
      }
    },
    updateData() {
      this.fetchAuto = this.messagesConfig.fetchAuto;
      this.fetchInterval = this.messagesConfig.fetchInterval;
    },
  },
});
</script>
