<template>
  <div class="d-flex flex-column text-center pa-4 gap-4">
    <v-row :class="{ 'gap-4': $vuetify.breakpoint.xsOnly }" class="mb-0">
      <v-col class="py-0" cols="12" sm="6">
        <m-text-field
          v-model="firstName"
          :label="$t('profile.personal.firstName')"
          sentry-tag="profile.input.firstName"
          validation-rules="required|max:150"
        />
      </v-col>
      <v-col class="py-0" cols="12" sm="6">
        <m-text-field
          v-model="lastName"
          :label="$t('profile.personal.lastName')"
          sentry-tag="profile.input.lastName"
          validation-rules="required|max:150"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { MTextField } from '@/components/Inputs';

export default Vue.extend({
  name: 'm-tenant-onboarding-name-form',
  components: {
    MTextField,
  },
  props: {
    value: Object as PropType<{ firstName: string; lastName: string }>,
  },
  computed: {
    firstName: {
      get() {
        return this.value?.firstName;
      },
      set(value: string) {
        this.$emit('input', { ...(this.value ?? {}), firstName: value });
      },
    },
    lastName: {
      get() {
        return this.value?.lastName;
      },
      set(value: string) {
        this.$emit('input', { ...(this.value ?? {}), lastName: value });
      },
    },
  },
});
</script>
