<template>
  <div class="d-flex flex-column text-center pa-4">
    <m-mentoring-availability v-model="mentoring" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IUser, MentoringOptions } from '@mentessa/types';
import { MMentoringAvailability } from '@/components/Inputs';

export default Vue.extend({
  name: 'm-onboarding-availability-form',
  components: {
    MMentoringAvailability,
  },
  props: {
    value: Object as PropType<IUser>,
  },
  computed: {
    mentoring: {
      get() {
        return this.value?.attributes.mentoring;
      },
      set(value: MentoringOptions) {
        this.$emit('input', {
          ...this.value,
          attributes: {
            ...this.value?.attributes,
            mentoring: value,
          },
        });
      },
    },
  },
});
</script>
