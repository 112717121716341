<template>
  <v-card class="user-row my-3" sentry-tag="user-layout-row">
    <div class="user-row__col-1">
      <m-user-avatar :user="user" x-large class="mr-4" @click="onCardClick" />
      <div class="user-row__info mr-2 w-40">
        <m-text-with-hint :text="fullName" :font-size="20" class="font-weight-medium" />
        <m-text-with-hint v-if="user.attributes.jobTitle" :text="user.attributes.jobTitle" class="mt-0 mb-3" />
        <m-text-with-hint
          v-if="user.attributes.company"
          :text="user.attributes.company"
          :lines="1"
          class="user-row__company"
        />
        <m-text-with-hint
          v-if="user.attributes.location"
          :text="user.attributes.location"
          :lines="1"
          class="user-row__location"
        />
      </div>
    </div>
    <div class="user-row__col-2">
      <m-tags-wrapper :tags="user.expertiseTags" :label="$t('userCard.expertise')" class="mb-4" />
      <m-tags-wrapper :tags="user.interestTags" :label="$t('userCard.interests')" />
    </div>
    <v-btn
      @click="onCardClick"
      large
      :width="$vuetify.breakpoint.smAndDown ? '100%' : 145"
      depressed
      color="button"
      class="user-row__meet-button"
      sentry-tag="user-layout-row.button.meet"
    >
      {{ $t('userCard.meetBtn') }}
    </v-btn>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import MTagsWrapper from '@/components/MTagsWrapper';
import MTextWithHint from '@/components/MTextWithHint';
import MUserAvatar from '@/components/MUserAvatar';
import { getFullName } from '@/utils/user';
import { UserEntity } from '@mentessa/types';

export default Vue.extend({
  name: 'm-user-layout-row',
  components: { MTextWithHint, MTagsWrapper, MUserAvatar },
  props: {
    user: Object as PropType<UserEntity>,
  },
  computed: {
    fullName() {
      return getFullName(this.user);
    },
  },
  methods: {
    onCardClick() {
      this.$emit('onCardClick');
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

@mixin user-icon-field {
  margin-bottom: 4px;
  display: flex;
  &:before {
    margin-right: 5px;
    position: relative;
    top: 3px;
  }
}

.user-row {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  overflow: hidden;

  &__info {
    display: flex;
    flex-direction: column;
    max-width: 220px;
  }

  &__company {
    @include user-icon-field;

    &:before {
      content: url(@/assets/suitcase.svg);
    }
  }

  &__location {
    @include user-icon-field;

    &:before {
      content: url(@/assets/pin.svg);
    }
  }

  &__col-1 {
    display: flex;
    width: calc(50% - 80px);

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      width: 100%;
    }
  }

  &__col-2 {
    width: calc(50% - 80px);
    max-width: 300px;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      margin: 24px 0;
      width: 100%;
    }
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    flex-direction: column;
  }

  &__meet-button {
    :deep(.v-btn__content) {
      font-size: 16px;
      color: #ffffff;
    }
  }
}
</style>
