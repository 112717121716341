import { Action, StoreData } from '@/store/types';
import {
  DashboardReference,
  DashboardSettingsReference,
  IDashboard,
  IDashboardSettings,
  IDashboardWidget,
  RitualReference,
  TenantReference,
} from '@mentessa/types';

export const moduleName = 'dashboards';

export interface DashboardsState {
  dashboards: StoreData<Array<IDashboardSettings>>;
}

export enum DasboardsActions {
  LoadDashboardList = '[Dashboards] Load Dashboard List',
  LoadDashboard = '[Dashboards] Load Dashboard',
  LoadRitualDasboard = '[Dashboards] Load Ritual Dashboard',
  LoadAdminDasboard = '[Dashboards] Load Admin Dasboard',
  UpdateDasboard = '[Dashboards] Update Dasboard',
  DeleteDasboard = '[Dashboards] Delete Dasboard',
  CreateDasboard = '[Dashboards] Create Dasboard',
  RetriveMetricsData = '[Dashboard] Retrive Metrics Data',
  RetriveWidgetData = '[Dashboard] Retrive Widget Data',
}

export enum DasboardsMutations {
  SetDasboards = '[Dashboards] Set Dasboards',
  SetDasboardState = '[Dashboards] Set Dasboard State',
}

export class LoadDashboardList implements Action {
  type = `${moduleName}/${DasboardsActions.LoadDashboardList}`;

  constructor(private readonly page: number = 1, private readonly tenantRef?: TenantReference) {}
}

export class LoadDashboard implements Action {
  type = `${moduleName}/${DasboardsActions.LoadDashboard}`;

  constructor(private readonly dashboardSettingsRef: DashboardSettingsReference) {}
}

export class LoadRitualDashboard implements Action {
  type = `${moduleName}/${DasboardsActions.LoadRitualDasboard}`;

  constructor(private readonly ritualRef: RitualReference) {}
}

export class CreateDasboard implements Action {
  type = `${moduleName}/${DasboardsActions.CreateDasboard}`;

  constructor(private readonly dashboard: Omit<IDashboard, 'id'>) {}
}

export class UpdateDasboard implements Action {
  type = `${moduleName}/${DasboardsActions.UpdateDasboard}`;

  constructor(private readonly dashboardRef: DashboardReference, private readonly updateDto: Partial<IDashboard>) {}
}

export class DeleteDasboard implements Action {
  type = `${moduleName}/${DasboardsActions.DeleteDasboard}`;

  constructor(private readonly dashboardRef: DashboardReference) {}
}

export class RetriveMetricsData implements Action {
  type = `${moduleName}/${DasboardsActions.RetriveMetricsData}`;

  constructor(private readonly dashboardRef: DashboardReference, private readonly query: string) {}
}

export class LoadAdminDasboard implements Action {
  type = `${moduleName}/${DasboardsActions.LoadAdminDasboard}`;

  constructor(private readonly tenantRef?: TenantReference) {}
}

export class RetriveWidgetData implements Action {
  type = `${moduleName}/${DasboardsActions.RetriveWidgetData}`;

  constructor(
    private readonly dashboardRef: DashboardReference,
    private readonly widget: IDashboardWidget,
    private readonly filters?: Record<string, unknown>,
    private readonly tenantRef?: TenantReference,
  ) {}
}
