<template>
  <m-panel>
    <h4 class="text-uppercase">{{ $t('panels.matches.title') }}</h4>
    <!--    <v-chip-group v-model="filter" color="primary">-->
    <!--      <v-chip filter value="need_action">Action Needed</v-chip>-->
    <!--      <v-chip filter value="with_journal">With journal</v-chip>-->
    <!--    </v-chip-group>-->
    <v-slide-group
      v-model="activeItem"
      center-active
      class="m-slide-group mt-4"
      mandatory
      show-arrows
      @click:next="selectNext"
      @click:prev="selectPrev"
    >
      <v-slide-item
        v-for="(match, index) in filteredMatches"
        :key="`match-${match.id}-${index}`"
        v-slot="{ toggle }"
        class="mx-2"
      >
        <m-match-card :value="match" @click="toggle" />
      </v-slide-item>
    </v-slide-group>
    <m-user-details-simple-dialog v-if="activeMatch" v-model="showUserDetailsDialog" :user-id="activeMatch.id" />
  </m-panel>
</template>

<script lang="ts">
import Vue from 'vue';
import MPanel from '@/components/MPanel';
import { LoadUserMatches } from '@/store/matching';
import { IMatchUser, IUser, MatchStatus } from '@mentessa/types';
import { MUserDetailsSimpleDialog } from '@/components/Dialogs';
import MMatchCard from '@/components/Cards/MMatchCard/MMatchCard.vue';

export default Vue.extend({
  name: 'm-user-matches-panel2',
  components: { MMatchCard, MUserDetailsSimpleDialog, MPanel },
  props: {
    max: { type: Number, default: 10 },
  },
  data: () => ({
    matchedUsers: [] as Array<IUser>,
    matches: [] as Array<IMatchUser>,
    loading: false,
    activeMatch: undefined as IUser,
    showUserDetailsDialog: false,
    activeItem: 0,
    filter: undefined,
  }),
  computed: {
    filteredMatches() {
      if (this.filter === 'need_action') {
        return this.matches.filter((match) => match.match?.status === MatchStatus.Pending && match.accepted == null);
      } else if (this.filter === 'with_journal') {
        return this.matches.filter((match) => match.match?.mentoringSessions?.[0]);
      }
      return this.matches;
    },
  },
  watch: {
    loading(value: boolean) {
      if (!value) {
        this.$emit('onLoaded');
      }
    },
    filter() {
      this.activeItem = 0;
    },
  },
  async mounted() {
    try {
      this.loading = true;
      const matches: Array<IMatchUser> = await this.$store.dispatch(new LoadUserMatches());
      const matchedUsers = [];
      const uniqMatchUsers = new Set();
      this.matches = matches.filter((match) => match.match?.matchUsers?.[0]?.user != null);
      matches.forEach((match) => {
        const matchWith = match.match?.matchUsers?.[0]?.user;
        if (matchWith && !uniqMatchUsers.has(matchWith.id)) {
          uniqMatchUsers.add(matchWith.id);
          matchedUsers.push(matchWith);
        }
      });
      this.matchedUsers = matchedUsers;
      this.$emit('onLoaded');
    } finally {
      this.loading = false;
    }
  },
  methods: {
    showDetails(user: IUser, toggle: () => void) {
      toggle();
      this.activeMatch = user;
      this.showUserDetailsDialog = true;
    },
    selectNext() {
      this.activeItem = (this.activeItem + 1) % this.filteredMatches.length;
    },
    selectPrev() {
      this.activeItem = (this.activeItem - 1) % this.filteredMatches.length;
    },
  },
});
</script>

<style lang="scss" scoped>
.m-slide-group:deep(.v-slide-group__wrapper) {
  mask: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 5%, rgba(0, 0, 0, 1) 95%, rgba(0, 0, 0, 0) 100%);
}

.m-slide-group:deep(.v-slide-group__next) {
  min-width: 24px;
}

.m-slide-group:deep(.v-slide-group__prev) {
  min-width: 24px;
}
</style>
