<template>
  <m-panel>
    <m-feature-enable-switch feature="userIntegrations" />
    <v-switch v-model="ms365" label="Microsoft 365" />
    <v-switch v-model="google" label="Google" />
    <v-switch v-model="linkedIn" label="LinkedIn" />
    <v-switch v-model="slack" label="slack" />
  </m-panel>
</template>

<script lang="ts">
import Vue from 'vue';
import MFeatureEnableSwitch from '@/components/Settings/MSettingsFeatures/MFeatureEnableSwitch';
import { mapGetters, mapState } from 'vuex';
import { ChangeFeatureConfig, TenantState } from '@/store/tenant';
import { UserIntegrationsFeature } from '@mentessa/types';
import MPanel from '@/components/MPanel';

export default Vue.extend({
  name: 'm-settings-features-user-integrations',
  components: { MPanel, MFeatureEnableSwitch },
  data: () => ({
    ms365: undefined,
    google: undefined,
    linkedIn: undefined,
    slack: undefined,
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    ...mapGetters('tenant', {
      featureConfig: 'featureConfig',
    }),
    userIntegrationsConfig() {
      return this.featureConfig('userIntegrations');
    },
  },
  methods: {
    async save() {
      const config: Partial<UserIntegrationsFeature> = {};

      if (!!this.fetchAuto !== !!this.userIntegrationsConfig.fetchAuto) {
        config.ms365 = this.ms365;
      }

      if (!!this.slack !== !!this.userIntegrationsConfig.slack) {
        config.slack = this.slack;
      }

      if (!!this.google !== !!this.userIntegrationsConfig.google) {
        config.google = this.google;
      }

      if (!!this.linkedIn !== !!this.userIntegrationsConfig.linkedIn) {
        config.linkedIn = this.linkedIn;
      }

      if (JSON.stringify(config) !== '{}') {
        await this.$store.dispatch(new ChangeFeatureConfig(this.tenant, 'userIntegrations', config));
      }
    },
    updateData() {
      this.ms365 = this.userIntegrationsConfig.ms365;
      this.slack = this.userIntegrationsConfig.slack;
      this.google = this.userIntegrationsConfig.google;
      this.linkedIn = this.userIntegrationsConfig.linkedIn;
    },
  },
});
</script>
