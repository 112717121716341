<template>
  <v-container class="" sentry-tag="settings">
    <div class="text-center d-flex flex-column m-ritual-created-container pa-8">
      <div class="d-flex align-center flex-column">
        <v-img :src="require('@/assets/sparkles.png')" contain max-height="128" max-width="128" />
        <h1 class="text--text mt-2">{{ $t('settings.rituals.ritualEdit.eventMatchingSetupTitle') }}</h1>
      </div>

      <p class="text--text text--lighten-5 description">
        {{ $t('settings.rituals.ritualEdit.eventMatchingSetupDescription') }}
      </p>

      <m-text-field
        append-icon="mdi-content-copy"
        class="m-invitation-link px-4"
        readonly
        :value="link"
        @click:append="copyInvitation"
      ></m-text-field>
      <router-link :to="ritualsRoute"> Back to rituals</router-link>
    </div>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import deepmerge from 'deepmerge';
import { ShowNotification } from '@/store/notifications';
import i18n from '@/plugins/i18n';
import { RouteName } from '@/router/types';
import { MTextField } from '@/components/Inputs';
import { mapState } from 'vuex';
import { MatchingState } from '@/store/matching';
import { LoadRitualInvitation, RitualsState } from '@/store/rituals';
import { copy } from '@/utils/clipboard';

export default Vue.extend({
  name: 'm-ritual-created',
  components: { MTextField },
  props: {
    ritualId: { type: Number },
  },
  data: () => ({
    ritual: undefined,
    ritualsRoute: { name: RouteName.SettingsRituals },
    invite: undefined,
  }),
  computed: {
    ...mapState<MatchingState>('rituals', {
      ritualsPage: (state: RitualsState) => {
        return state.rituals;
      },
    }),
    rituals() {
      return this.ritualsPage.items;
    },
    title() {
      if (!this.ritual) {
        return this.$t(`settings.rituals.knownNames.custom`);
      }
      return this.ritual?.attributes.title ?? this.$t(`settings.rituals.knownNames.${this.ritual?.type}`);
    },
    link() {
      return this.invite ? `${window.origin}/invites/${this.invite.guid}` : undefined;
    },
  },
  watch: {
    rituals() {
      this.loadRitual();
    },
  },
  methods: {
    loadRitual() {
      if (!this.ritualId) return;
      const baseRitual = this.rituals.find((r) => r.id === Number(this.ritualId));
      if (!baseRitual) return;
      this.ritual = deepmerge(baseRitual, {});
    },
    async copyInvitation() {
      await copy(this.link);
      await this.$store.dispatch(new ShowNotification(i18n.t('notifications.ok').toString()));
    },
  },
  async mounted() {
    this.loadRitual();
    this.invite = await this.$store.dispatch(new LoadRitualInvitation({ id: this.ritualId }));
  },
});
</script>

<style lang="scss" scoped>
.m-ritual-created-container {
  gap: 32px;
  align-items: center;
  justify-content: center;
  background-color: var(--v-white-base);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  aspect-ratio: 1;
  border-radius: 6px;
  width: 100%;
}

.m-invitation-link {
  max-width: 432px;
  width: 100%;
}

.description {
  max-width: 432px;
}
</style>
