<template>
  <m-combobox
    v-model="localValue"
    :items="items"
    :loading="loading"
    item-text="name"
    sentry-tag="combobox-tags"
    v-bind="{ ...$attrs }"
    @update:search-input="searchForItems($event)"
  />
</template>

<script lang="ts">
import Vue from 'vue';
import { MCombobox } from '@/components/Inputs';
import { FindTags } from '@/store/tenant';
import { TagEntity } from '@mentessa/types';

export default Vue.extend({
  name: 'm-combobox-tags',
  components: {
    MCombobox,
  },
  props: {
    debounce: { type: Number, default: 500 },
    value: { type: Array },
  },
  data: () => ({
    items: [],
    loading: false,
    searchTimer: undefined,
    previousSearch: undefined,
  }),
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(tags: Array<TagEntity | string>[]) {
        const newTags = tags
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((tag: string | Record<string, any>) => (typeof tag === 'string' ? { name: tag.trim() } : tag))
          .filter((tag, index, self) => self.findIndex((t) => t.name === tag.name) === index); // ToDo: Implement this in the proper way

        this.$emit('input', newTags);
      },
    },
  },
  methods: {
    async loadTags(query?: string) {
      try {
        this.loading = true;
        const tags = await this.$store.dispatch(new FindTags(query));
        if (tags) this.items = tags;
      } finally {
        this.loading = false;
      }
    },
    async searchForItems(value: string) {
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(async () => {
        if (this.previousSearch !== value) {
          this.previousSearch = value;
          await this.loadTags(value);
        }
      }, this.debounce);
    },
  },
});
</script>
