<template>
  <m-panel>
    <h3 class="mb-10">{{ $t('mentoring.messages.title') }}</h3>
    <div ref="messagesContainer" class="mentoring-messages-wrap pr-2">
      <m-mentoring-message
        v-for="(message, index) in messages"
        :key="`message-${index}`"
        :member="messageMember(message)"
        :message="message"
        :right="isMyMessage(message)"
      />
      <div ref="messagesEnd"></div>
    </div>
    <div class="d-flex align-center justify-center mt-auto">
      <m-user-avatar :user="me" />
      <v-textarea
        v-model="newMessage"
        :disabled="loading || readOnly"
        :loading="loading"
        :placeholder="$t('mentoring.messages.input.placeholder')"
        append-icon="mdi-send"
        auto-grow
        class="ml-2"
        hide-details
        no-resize
        outlined
        rows="1"
        sentry-tag="mentoring-session-messages.send-message"
        @keydown.enter.exact.prevent="sendMessage"
        @click:append="sendMessage"
        dense
      />
    </div>
  </m-panel>
</template>

<script lang="ts">
import MMentoringMessage from './MMentoringMessage.vue';
import { MUserAvatar } from '@/components/MUserAvatar';
import { mapState } from 'vuex';
import { UsersState } from '@/store/users';
import { IMentoringSession, IMentoringSessionMessage, MentoringSessionStatus } from '@mentessa/types';
import Vue, { PropType } from 'vue';
import { CreateMentoringSessionMessage } from '@/store/mentoring';
import { compareAsc, parseISO } from 'date-fns';
import MPanel from '@/components/MPanel';

export default Vue.extend({
  name: 'm-mentoring-session-messages',
  components: { MPanel, MMentoringMessage, MUserAvatar },
  props: {
    session: { type: Object as PropType<IMentoringSession> },
  },
  data: () => ({
    newMessage: '',
    loading: false,
  }),
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    messages() {
      return (
        this.session?.messages?.slice().sort((l, r) => {
          return compareAsc(parseISO(l.createdAt), parseISO(r.createdAt));
        }) ?? []
      );
    },
    readOnly() {
      return this.session?.status !== MentoringSessionStatus.Progress;
    },
  },
  watch: {
    messages() {
      this.$nextTick(() => {
        this.$vuetify.goTo(this.$refs.messagesEnd, { container: this.$refs.messagesContainer });
      });
    },
  },
  methods: {
    isMyMessage(message: IMentoringSessionMessage) {
      return message.createdById === this.me?.id;
    },
    messageMember(message: IMentoringSessionMessage) {
      return this.session.members.find((member) => member.user.id === message.createdById);
    },
    async sendMessage() {
      if (this.newMessage.length === 0) {
        return;
      }
      this.loading = true;
      try {
        await this.$store.dispatch(new CreateMentoringSessionMessage(this.session, this.newMessage));
        this.newMessage = '';
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.mentoring-messages-wrap {
  max-height: 400px; // put correct value
  overflow-y: scroll;
}
</style>
