<template>
  <div>
    <m-headline
      :description="$t('ritual_templates.description')"
      :title="$t('ritual_templates.title')"
      class="mr-auto ml-4"
    />
    <div class="d-flex pa-4 align-center">
      <router-link :to="backRoute" class="ml-auto d-flex gap-2">
        <v-img contain height="24" src="@/assets/arrow-left-circle.svg" width="24" />
        <div class="text--text text--lighten-4">{{ $t('settings.rituals.ritualEdit.back') }}</div>
      </router-link>
    </div>
    <div class="d-flex gap-4 py-4 flex-wrap justify-center">
      <m-ritual-template-card
        v-for="(template, index) in templates"
        :key="`template-${index}`"
        :ritual-template="template"
      />
      <m-ritual-template-more-card />
    </div>
  </div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import Vue from 'vue';
import { LoadRitualTemplates, RitualTemplatesState } from '@/store/ritualTemplates';
import { MRitualTemplateCard } from '@/components/Cards/MRitualTemplateCard';
import { ITenantRitualTemplate } from '@mentessa/types';
import { RouteName } from '@/router/types';
import MHeadline from '@/components/MHeadline';
import { MRitualTemplateMoreCard } from '@/components/Cards/MRitualTemplateMoreCard';

export default Vue.extend({
  name: 'm-ritual-templates',
  components: { MHeadline, MRitualTemplateCard, MRitualTemplateMoreCard },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapState<RitualTemplatesState>('ritualTemplates', {
      templates: (state: RitualTemplatesState): Array<ITenantRitualTemplate> => state.ritualTemplates ?? [],
    }),
    backRoute() {
      return { name: RouteName.Rituals };
    },
  },
  async mounted() {
    try {
      this.loading = true;
      await this.$store.dispatch(new LoadRitualTemplates());
    } finally {
      this.loading = false;
    }
  },
});
</script>
