import { Action } from '@/store/types';

export const moduleName = 'notifications';

export interface NotificationDialog {
  message: string;
  variant?: string;
  buttonText?: string;
  isOpened: boolean;
}

export interface Notifications {
  message: string;
  variant: NotificationsVariant;
  timeout: number;
}

export interface PersistentNotification {
  title: string;
  text: string;
  link?: {
    text: string;
    url: string;
  };
  isOpened: boolean;
}

export interface NotificationsState {
  notifications: Array<Notifications>;
  maxMessages: number;
  notificationDialog: NotificationDialog;
  persistentNotification: PersistentNotification;
}

export enum NotificationsMutations {
  OpenNotificationDialog = '[Notifications] Open Notification Dialog',
  CloseNotificationDialog = '[Notifications] Close Notification Dialog',
  AddNotification = '[Notifications] Add Notification',
  HideNotification = '[Notifications] Hide Notification',
  ShowPersistentNotification = '[Notifications] Open Persistent Notification',
  HidePersistentNotification = '[Notifications] Hide Persistent Notification',
  ShowDesktopNotification = '[Notifications] Show Desktop Notification',
  CloseDesktopNotification = '[Notifications] Close Desktop Notification',
}

export enum NotificationsActions {
  OpenNotificationDialog = '[Notifications] Open Notification Dialog',
  CloseNotificationDialog = '[Notifications] Close Notification Dialog',
  ShowNotification = '[Notifications] Show Notification',
  HideNotification = '[Notifications] Hide Notification',
  ShowPersistentNotification = '[Notifications] Show Persistent Notification',
  HidePersistentNotification = '[Notifications] Hide Persistent Notification',
  ShowDesktopNotification = '[Notifications] Show Desktop Notification',
  CloseDesktopNotification = '[Notifications] Close Desktop Notification',
}

export enum NotificationsVariant {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}

export class OpenNotificationDialog implements Action {
  type = `${moduleName}/${NotificationsActions.OpenNotificationDialog}`;
  public isOpened: boolean;

  constructor(
    public message: string,
    public variant: string = NotificationsVariant.SUCCESS,
    public buttonText: string = 'Ok',
  ) {
    this.message = message;
    this.variant = variant;
    this.buttonText = buttonText;
    this.isOpened = true;
  }
}

export class CloseNotificationDialog implements Action {
  type = `${moduleName}/${NotificationsActions.CloseNotificationDialog}`;
  public message: string;
  public isOpened: boolean;

  constructor() {
    this.message = '';
    this.isOpened = false;
  }
}

export class ShowNotification implements Action {
  type = `${moduleName}/${NotificationsActions.ShowNotification}`;

  constructor(
    public message: string,
    public variant: NotificationsVariant = NotificationsVariant.SUCCESS,
    public timeout: number = 2000,
  ) {
    this.message = message;
    this.variant = variant;
    this.timeout = timeout;
  }
}

export class ShowErrorNotification extends ShowNotification {
  constructor(public message: string, public timeout: number = -1) {
    super(message, NotificationsVariant.ERROR, timeout);
  }
}

export class ShowInfoNotification extends ShowNotification {
  constructor(public message: string, public timeout: number = 2000) {
    super(message, NotificationsVariant.INFO, timeout);
  }
}

export class ShowWarningNotification extends ShowNotification {
  constructor(public message: string, public timeout: number = 2000) {
    super(message, NotificationsVariant.WARNING, timeout);
  }
}

export class HideNotification implements Action {
  type = `${moduleName}/${NotificationsActions.HideNotification}`;

  constructor(public index: number) {
    this.index = index;
  }
}

export class ShowPersistentNotification implements Action {
  type = `${moduleName}/${NotificationsActions.ShowPersistentNotification}`;
  public isOpened = true;

  constructor(
    private readonly title: string,
    private readonly text: string,
    private link: { url: string; text: string },
  ) {}
}

export class HidePersistentNotification implements Action {
  type = `${moduleName}/${NotificationsActions.HidePersistentNotification}`;
  public isOpened = false;
}

export class ShowDesktopNotification implements Action {
  type = `${moduleName}/${NotificationsActions.ShowDesktopNotification}`;

  constructor(private readonly title: string, private readonly body: string, private readonly icon: string) {}
}

export class CloseDesktopNotification implements Action {
  type = `${moduleName}/${NotificationsActions.ShowDesktopNotification}`;

  constructor(private readonly notification) {}
}
