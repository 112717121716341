<template>
  <section>
    <v-tabs v-model="tab" class="m-appointments-tabs" show-arrows background-color="background">
      <v-tab key="upcoming">{{ $t('appointments.tabs.upcoming') }}</v-tab>
      <v-tab key="past">{{ $t('appointments.tabs.past') }}</v-tab>
      <v-tab key="cancelled">{{ $t('appointments.tabs.cancelled') }}</v-tab>
    </v-tabs>
    <div v-if="currentAppointments.length > 0" class="mt-9 gap-4 d-flex flex-column">
      <m-appointment-card
        v-for="appointment in currentAppointments"
        :key="appointment.id"
        :appointment="appointment"
        :session="session"
        :user="getMatchUser(appointment)"
        @remove-match="removeMatch"
      ></m-appointment-card>
    </div>
    <v-tabs-items v-else v-model="tab">
      <v-tab-item>
        <div v-if="isMentoringSession">
          <p v-if="readOnly" class="text-center mt-14">{{ $t('appointments.empty.upcoming.title') }}</p>
          <m-empty-state
            v-else
            :cta="$t('appointments.empty.upcoming.ctaMentoringSession')"
            :description="$t('appointments.empty.upcoming.title')"
            @onButtonClick="$emit('onBookButtonClick')"
          />
        </div>
        <div v-else>
          <m-empty-community v-if="isCommunityEmpty && isInvitesAvailable" />
          <m-empty-state
            v-else
            :cta="$t('appointments.empty.upcoming.cta')"
            :description="$t('appointments.empty.upcoming.description')"
            :image="require('@/assets/empty/calendar.svg')"
            :title="$t('appointments.empty.upcoming.title')"
            @onButtonClick="goToCommunity"
          />
        </div>
      </v-tab-item>
      <v-tab-item>
        <p class="text-center mt-14">{{ $t('appointments.empty.past.title') }}</p>
      </v-tab-item>
      <v-tab-item>
        <p class="text-center mt-14">{{ $t('appointments.empty.cancelled.title') }}</p>
      </v-tab-item>
    </v-tabs-items>
  </section>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { UsersState } from '@/store/users';
import { ClearBookedSession } from '@/store/matching';
import {
  AppointmentStatus,
  IAppointment,
  IMentoringSession,
  IMentoringSessionMember,
  IUser,
  MentoringSessionStatus,
} from '@mentessa/types';
import MAppointmentCard from '@/components/MAppointmentCard';
import { isAfter, isBefore, parseISO } from 'date-fns';
import MEmptyState from '@/components/MEmptyState';
import MEmptyCommunity from '@/components/MEmptyCommunity';
import { RouteName } from '@/router/types';

export default Vue.extend({
  name: 'm-appointments-list',
  components: { MEmptyCommunity, MEmptyState, MAppointmentCard },
  data: () => ({
    tab: null,
  }),
  props: {
    appointments: { type: Array as PropType<IAppointment[]> },
    members: { type: Array as PropType<IMentoringSessionMember[]>, default: () => [] },
    session: { type: Object as PropType<IMentoringSession> },
  },
  computed: {
    ...mapGetters('tenant', {
      isInvitesAvailable: 'isInvitesAvailable',
      isCommunityEmpty: 'isCommunityEmpty',
    }),
    ...mapState<UsersState>('users', {
      me: (state: UsersState): IUser => state.me,
    }),
    upcomingAppointments() {
      return this.appointments.filter((appointment: IAppointment) => {
        const appointmentDate: string = appointment.date?.toString() ?? appointment.slot?.date.toString();
        return appointment.status !== AppointmentStatus.Canceled && isAfter(parseISO(appointmentDate), new Date());
      });
    },
    pastAppointments() {
      return this.appointments.filter((appointment: IAppointment) => {
        const appointmentDate: string = appointment.date?.toString() ?? appointment.slot?.date.toString();
        return appointment.status !== AppointmentStatus.Canceled && isBefore(parseISO(appointmentDate), new Date());
      });
    },
    canceledAppointments() {
      return this.appointments.filter((appointment: IAppointment) => appointment.status === AppointmentStatus.Canceled);
    },
    currentAppointments() {
      if (this.tab === 0) {
        return this.upcomingAppointments;
      } else if (this.tab === 1) {
        return this.pastAppointments;
      }
      return this.canceledAppointments;
    },
    isMentoringSession() {
      return this.session != null;
    },
    readOnly() {
      return this.session?.status !== MentoringSessionStatus.Progress;
    },
  },
  methods: {
    getMatchUser(appointment: IAppointment) {
      if (appointment.organizerId === this.me.id) {
        return this.members.length > 0
          ? this.findSessionMemberByUserId(appointment.targetId)?.user
          : appointment.target;
      }
      return this.members.length > 0
        ? this.findSessionMemberByUserId(appointment.organizerId)?.user
        : appointment.organizer;
    },
    async removeMatch(appointment: IAppointment) {
      await this.$store.dispatch(new ClearBookedSession(this.me, appointment));
    },
    findSessionMemberByUserId(id: number) {
      return this.members.find((member: IMentoringSessionMember) => member.user?.id === id);
    },
    async goToCommunity() {
      await this.$router.push({ name: RouteName.Community });
    },
  },
});
</script>

<style lang="scss" scoped>
.m-appointments-tabs:deep(.v-slide-group__wrapper) {
  box-shadow: inset 0 -2px 0 0 var(--v-lightgray-base);
  margin-bottom: 2px;
}
</style>
