<template>
  <v-dialog v-model="isDialogOpen" width="600" :fullscreen="$vuetify.breakpoint.xsOnly" sentry-tag="add-news-dialog">
    <v-card class="fill-height d-flex flex-column align-center pa-5">
      <div class="d-flex justify-end close-icon-container">
        <a @click="$emit('input', false)" class="m-close-icon" sentry-tag="add-members-dialog.button.close">
          <v-img contain height="24" src="@/assets/close-icon.svg" width="24" />
        </a>
      </div>
      <h1 class="text--text">{{ $t('news.addNewsDialogHeader') }}</h1>
      <p class="text--text text--lighten-4">{{ $t('news.addNewsDialogSubheader') }}</p>
      <validation-observer v-slot="{ invalid }" class="add-news__form pa-5">
        <m-text-field
          v-model="header"
          :label="$t('news.addNewsDialogHeaderTitle')"
          no-resize
          validation-rules="required"
          sentry-tag="add-news-dialog.header"
          class="m-input-field"
        />
        <m-text-area
          v-model="content"
          :label="$t('news.addNewsDialogContentTitle')"
          no-resize
          validation-rules="required"
          sentry-tag="add-news-dialog.content"
          class="m-input-field"
        />
        <template v-if="contentTags.length">
          <h3 class="text--text">{{ $t('news.addNewsDialogChooseTags') }}</h3>
          <v-chip-group v-model="selectedContentTags" column multiple>
            <v-chip v-for="(tag, index) in contentTags" :key="index" filter>{{ tag }}</v-chip>
          </v-chip-group>
        </template>
        <div class="d-flex justify-center">
          <v-btn depressed color="button" @click="onPublish" :disabled="invalid">
            {{ $t('news.addNewsDialogPublishButtonTitle') }}
          </v-btn>
        </div>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { CreateContent } from '@/store/content';
import { ValidationObserver } from 'vee-validate';
import MTextArea from '@/components/Inputs/MTextArea/MTextArea.vue';
import MTextField from '@/components/Inputs/MTextField/MTextField.vue';
import { ContentType } from '@mentessa/types';

export default Vue.extend({
  name: 'm-add-news-dialog',
  props: {
    value: { type: Boolean, default: false },
  },
  components: {
    ValidationObserver,
    MTextArea,
    MTextField,
  },
  data: () => ({
    header: '',
    content: '',
    selectedContentTags: [],
  }),
  computed: {
    isDialogOpen: {
      get() {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    ...mapGetters('tenant', {
      contentTags: 'contentTags',
    }),
  },
  methods: {
    async onPublish() {
      const newsRaw = {
        title: this.header,
        type: ContentType.News,
        content: this.content,
        attributes: {
          tags: this.contentTags.filter((item, index) => this.selectedContentTags.includes(index)),
        },
      };
      await this.$store.dispatch(new CreateContent(newsRaw));
      this.$emit('input', false);
      this.$emit('onPublish');
      this.header = '';
      this.content = '';
      this.selectedContentTags = [];
    },
  },
});
</script>

<style lang="scss" scoped>
.close-icon-container {
  width: 100%;
}

.add-news__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.m-input-field {
  width: 100%;
}
</style>
