<template>
  <div>
    <div class="d-flex pa-4 align-center">
      <m-ritual-preview :ritual="ritual" big />
      <router-link :to="backRoute" class="ml-auto d-flex gap-2">
        <v-img contain height="24" src="@/assets/arrow-left-circle.svg" width="24" />
        <div class="text--text text--lighten-4">{{ $t('settings.rituals.ritualEdit.back') }}</div>
      </router-link>
    </div>
    <p class="keep-new-line">{{ $t('ritualShop.description') }}</p>
    <v-divider />
    <v-tabs v-model="tab" color="primary" show-arrows>
      <v-tab v-if="isRitualsManagingAvailable" :to="dashboardRoute">{{ $t('ritualShop.tabs.dashboard') }}</v-tab>
      <v-tab v-if="isRitualsManagingAvailable" :to="settingsRoute">{{ $t('ritualShop.tabs.settings') }}</v-tab>
      <v-tab :to="membersRoute">{{ $t('ritualShop.tabs.members') }}</v-tab>
      <v-tab v-if="isRitualsManagingAvailable" :to="resultsRoute">{{ $t('ritualShop.tabs.results') }}</v-tab>
      <v-tab v-if="isRitualsManagingAvailable" :to="inviteRoute">{{ $t('ritualShop.tabs.invite') }}</v-tab>
      <v-tab v-if="isDebugAvailable" :to="debugRoute" class="error--text">{{ $t('ritualShop.tabs.debug') }}</v-tab>
    </v-tabs>
    <router-view />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { RouteName } from '@/router/types';
import { LoadRitual, RitualsState } from '@/store/rituals';
import { MatchingState } from '@/store/matching';
import MRitualPreview from '@/components/MRitualPreview/MRitualPreview.vue';

export default Vue.extend({
  name: 'm-ritual-manage',
  components: { MRitualPreview },
  props: {
    ritualId: { type: Number },
  },
  data: () => ({
    tab: undefined,
  }),
  computed: {
    ...mapGetters('tenant', {
      isDebugAvailable: 'isDebugAvailable',
      isRitualsManagingAvailable: 'isRitualsManagingAvailable',
    }),
    ...mapState<MatchingState>('rituals', {
      ritual: (state: RitualsState) => state.current,
    }),
    dashboardRoute() {
      return { name: RouteName.RitualManageDashboard };
    },
    settingsRoute() {
      return { name: RouteName.RitualManageSettings };
    },
    membersRoute() {
      return { name: RouteName.RitualManageMembers };
    },
    resultsRoute() {
      return { name: RouteName.RitualManageResults };
    },
    inviteRoute() {
      return { name: RouteName.RitualManageInvite };
    },
    debugRoute() {
      return { name: RouteName.RitualManageDebug };
    },
    backRoute() {
      return { name: RouteName.Rituals };
    },
  },
  async mounted() {
    const result = await this.$store.dispatch(new LoadRitual({ id: this.ritualId }, { invitations: true }));
    if (!result) {
      await this.$router.replace(this.backRoute);
    }
  },
});
</script>
