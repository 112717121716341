<template>
  <div :class="classes" :style="styles" class="m-panel">
    <div v-if="title || draggable" class="d-flex">
      <h4 class="text-uppercase">{{ title }}</h4>
      <v-icon v-if="draggable" class="handle ml-auto">mdi-drag</v-icon>
    </div>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'm-panel',
  props: {
    background: { type: Boolean, default: false },
    border: { type: Boolean, default: true },
    beige: { type: Boolean, default: false },
    banner: { type: Boolean, default: false },
    shadow: { type: Boolean, default: false },
    accent: { type: Boolean, default: false },
    maxWidth: String,
    title: [String, Object],
    draggable: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
  },
  computed: {
    styles() {
      return {
        '--panel-max-width': this.maxWidth ?? '100%',
      };
    },
    classes() {
      return {
        'm-panel__border': this.border,
        'm-panel__background': this.background,
        'm-panel__beige': this.beige,
        'm-panel__shadow': this.shadow,
        'm-panel__banner': this.banner,
        'm-panel__accent': this.accent,
        'grid-item': this.draggable,
        'm-panel__dense': this.dense || this.$vuetify.breakpoint.xsOnly,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.m-panel {
  background-color: var(--v-white-base);
  max-width: var(--panel-max-width);
  padding: 16px;

  &__dense {
    padding: 8px;
  }

  &__shadow {
    box-shadow: 2px 2px 8px 2px var(--v-lightgray-base);
  }

  &__background {
    background-color: var(--v-background-base);
  }

  &__border {
    border: 1px solid var(--v-beige-base);
    border-radius: 16px;
  }

  &__beige {
    background-color: var(--v-beige-base);
  }

  &__banner {
    background-color: var(--v-banner-base);
  }
}
</style>
