<template>
  <v-dialog v-model="isDialogOpen" max-width="746px" sentry-tag="new-match-dialog">
    <v-card :class="{ 'flex-column': this.$vuetify.breakpoint.xsOnly }" class="fill-height pa-4">
      <div class="m-close-icon-container">
        <a class="m-close-icon" sentry-tag="new-match-dialog.button.close" @click="$emit('input', false)">
          <v-img contain height="24" src="@/assets/close-icon.svg" width="24" />
        </a>
      </div>
      <m-matching-pick-pairs-form
        v-if="isPickUsersStep"
        v-model="pairsData"
        :isLoading="isLoading"
        @onNext="goToMessage"
      />
      <m-matching-message-form
        v-else
        v-model="message"
        :isLoading="isLoading"
        @onCancel="goToPairs"
        @onNext="createMatch"
      />
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import {
  MMatchingMessageForm,
  MMatchingPickPairsForm,
  PickPairsData,
} from '@/components/Dialogs/MNewMatchDialog/forms';

import { AddMatch } from '@/store/matching';

enum MatchingDialogStep {
  PickUsers = 0,
  SendMessage = 1,
}

export default Vue.extend({
  name: 'm-new-match-dialog',
  components: {
    MMatchingMessageForm,
    MMatchingPickPairsForm,
  },
  props: {
    value: { type: Boolean, default: false },
  },
  data: () => ({
    isLoading: false,
    step: MatchingDialogStep.PickUsers,
    pairsData: undefined as PickPairsData,
    message: '',
  }),
  watch: {
    value(value: boolean) {
      if (!value) {
        this.clean();
      }
    },
  },
  computed: {
    isDialogOpen: {
      get() {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    isPickUsersStep() {
      return this.step === MatchingDialogStep.PickUsers;
    },
  },
  mounted() {
    this.clean();
  },
  methods: {
    async createMatch() {
      await this.$store.dispatch(
        new AddMatch(this.pairsData.pairs, this.pairsData.roles, this.message, this.pairsData.createSession),
      );
      this.isDialogOpen = false;
      await this.clean();
    },

    clean() {
      this.message = this.$t('matching.defaultMatchedMessage');
      this.pairsData = undefined;
      this.step = MatchingDialogStep.PickUsers;
    },

    goToPairs() {
      this.step = MatchingDialogStep.PickUsers;
    },

    goToMessage() {
      this.step = MatchingDialogStep.SendMessage;
    },
  },
});
</script>

<style lang="scss" scoped>
.m-invitation-dialog__form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.m-close-icon-container {
  display: flex;
  justify-content: flex-end;
}
</style>
