import {
  AccessRole,
  ISchedule,
  IUser,
  UserAttributes,
  UserPermissions,
  UserReference,
  UsersPage,
} from '@mentessa/types';
import { Action } from '@/store/types';

export const moduleName = 'users';

export interface UsersStreamItem {
  loaded: boolean;
  user?: IUser;
}

export interface UsersStream {
  position: number;
  seed: number;
  items: UsersStreamItem[];
  loading: boolean;
  finished: boolean;
}

export interface UsersState {
  me?: IUser;
  schedules: Array<ISchedule>;
  detailedUser?: IUser;
  permissions: UserPermissions;
  members: UsersPage;
  usersStream: UsersStream;
  sortingSequence: string;
}

export enum UsersActions {
  GetMe = '[Users] Get Me',
  LoadUserDetails = '[Users] Load User Details',
  UpdateUserField = '[Users] Update field',
  InviteUser = '[Users] Invite User',
  UpdateUserImage = '[Users] Update User Image',
  UpdateUserFullName = '[Users] Update User FullName',
  UpdateUserEmail = '[Users] Update User Email',
  UpdateUserAttributes = '[Users] Update User Attributes',
  UpdateUserTags = '[Users] Update Tags',
  UpdateUserLang = '[Users] Update User Lang',
  SaveUserProfile = '[Users] Save User Profile',
  SaveUserSchedules = '[Users] Save User Schedules',
  LoadPermissions = '[Users] Load Permissions',
  SetFavoriteStatus = '[Users] Set Favorite Status',
  AddAdminRole = '[Users] Add Admin Role',
  RevokeAdminRole = '[Users] Revoke Admin Role',
  DeleteUser = '[Users] Delete user',
  SuspendUser = '[Users] Suspend user',
  RestoreUser = '[Users] Restore user',
  HideUser = '[Users] Hide user',
  UnHideUser = '[Users] Unhide user',
  LoadMembers = '[Users] Load Members',
  ExportMembers = '[Users] Export Members',
  LoadNextStreamUser = '[Users] Load Next Stream User',
  GenerateDemoUsers = '[Users] Generate Demo Users',
  GenerateDemoUsersSchedule = '[Users] Generate Demo Users Schedule',
  RemoveDemoUsers = '[Users] Remove Demo Users',
  AcceptRules = '[Users] Accept Rules',
  FindIncompleteUsers = '[Users] Find Incomplete Users',
  FindNotFullProfileUsers = '[Users] Find Not Full Profile Users',
  NotifyIncompleteUsers = '[Users] Notify Incomplete Users',
  NotifyNotFullProfileUsers = '[Users] Notify Not Full Profile Users',
  LoadTimeSlots = '[Users] Load Time Slots',
  LoadUserTimeSlots = '[Users] Load User Time Slots',
  CreateRoleGroup = '[Users] Create Role Group',
  RemoveRoleGroup = '[Users] Remove Role Group',
  LoadRoleGroups = '[Users] Load Role Groups',
  LoadSchedule = '[Users] Get Schedules',
  LoadUserStats = '[Users] Load User Stats',
  LoadUserChecklists = '[Users] Load User Checklist',
  LoadUserPersonalChecklist = '[Users] Load User Personal Checklist',
  CheckUserChecklistItem = '[Users] Check User Checklist Item',
  LoadManagers = '[Users] Load Managers',
  TestCompleteStatus = '[Users] Test Complete Status',
}

export enum UsersMutations {
  SetMe = '[Users] Set me',
  SetMyImage = '[Users] Set My image',
  SetMyFullName = '[Users] Set My FullName',
  SetMyAttributes = '[Users] Set My Attributes',
  SetUserAttributes = '[Users] Set User Attributes',
  SetMyTags = '[Users] Set My Tags',
  SetMyEmail = '[Users] Set My Email',
  SetMyComplete = '[Users] Set My Complete',
  SetMyLang = '[Users] Set My Lang',
  SetDetailedUser = '[Users] Set Detailed User',
  SetUserField = '[Users] Set User Field',
  SetPermissions = '[Users] Set Permissions',
  SetFavoriteStatus = '[Users] Set Favorite Status',
  SetSlotAvailable = '[Users] Set Slot available',
  SetAdminFlag = '[Users] Set Admin Flag',
  RemoveUser = '[Users] Remove User',
  SuspendUser = '[Users] Suspend User',
  RestoreUser = '[Users] Restore User',
  HideUser = '[Users] Hide user',
  UnHideUser = '[Users] Unhide user',
  SetMembers = '[Users] Set members',
  AppendToStream = '[Users] Append To Stream',
  IncreaseStreamPosition = '[Users] Increase Stream Position',
  SetStreamLoading = '[Users] Set Stream Loading',
  SetStreamFinished = '[Users] Set Stream Finished',
  SetTimeSlotAvailability = '[Users] Set Time Slot Availability',
  SetRulesAccepted = '[Users] Set Rules Accepted',
  SetSchedules = '[Users] Set Schedules',
}

export class GetMe implements Action {
  type = `${moduleName}/${UsersActions.GetMe}`;
}

export class LoadUserDetails implements Action {
  type = `${moduleName}/${UsersActions.LoadUserDetails}`;

  constructor(private readonly userId: number, private readonly fetchOnly = false) {}
}

export class UpdateUserField implements Action {
  type = `${moduleName}/${UsersActions.UpdateUserField}`;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(private readonly field: string, private readonly value) {}
}

export class InviteUser implements Action {
  type = `${moduleName}/${UsersActions.InviteUser}`;

  constructor(
    private readonly email: string,
    private readonly isMentor: boolean = false,
    private readonly isAdmin: boolean = false,
  ) {}
}

export class UpdateUserImage implements Action {
  type = `${moduleName}/${UsersActions.UpdateUserImage}`;

  constructor(private readonly blob: Blob, private readonly user?: IUser) {}
}

export class UpdateUserFullName implements Action {
  type = `${moduleName}/${UsersActions.UpdateUserFullName}`;

  constructor(
    private readonly firstName: string,
    private readonly lastName: string,
    private readonly tz?: string,
    private readonly user?: IUser,
  ) {}
}

export class UpdateUserEmail implements Action {
  type = `${moduleName}/${UsersActions.UpdateUserEmail}`;

  constructor(private readonly email: string, private readonly user?: IUser) {}
}

export class UpdateUserAttributes implements Action {
  type = `${moduleName}/${UsersActions.UpdateUserAttributes}`;

  constructor(private readonly attributes: UserAttributes, private readonly user?: IUser) {}
}

export class CreateRoleGroup implements Action {
  type = `${moduleName}/${UsersActions.CreateRoleGroup}`;

  constructor(private readonly role: AccessRole, private readonly roleToAssign: AccessRole) {}
}

export class RemoveRoleGroup implements Action {
  type = `${moduleName}/${UsersActions.RemoveRoleGroup}`;

  constructor(private readonly role: AccessRole, private readonly roleToRemove: AccessRole) {}
}

export class LoadRoleGroups implements Action {
  type = `${moduleName}/${UsersActions.LoadRoleGroups}`;

  constructor(private readonly role: AccessRole) {}
}

export class SaveUserProfile implements Action {
  type = `${moduleName}/${UsersActions.SaveUserProfile}`;

  constructor(private readonly profile, private readonly user?: IUser) {}
}

export class SaveUserSchedules implements Action {
  type = `${moduleName}/${UsersActions.SaveUserSchedules}`;

  constructor(
    private readonly schedule: Array<Partial<ISchedule>>,
    private readonly userRef?: UserReference,
    private readonly showSuccessNotification?: boolean,
  ) {}
}

export class UpdateUserTags implements Action {
  type = `${moduleName}/${UsersActions.UpdateUserTags}`;

  constructor(
    private readonly tags: {
      expertise?: string[];
      interests?: string[];
    },
    private readonly user?: IUser,
  ) {}
}

export class UpdateUserLang implements Action {
  type = `${moduleName}/${UsersActions.UpdateUserLang}`;

  constructor(private readonly lang: string, private readonly user?: UserReference) {}
}

export class LoadPermissions implements Action {
  type = `${moduleName}/${UsersActions.LoadPermissions}`;
}

export class SetFavoriteStatus implements Action {
  type = `${moduleName}/${UsersActions.SetFavoriteStatus}`;

  constructor(private readonly target: IUser, private readonly enabled: boolean, private readonly user?: IUser) {}
}

export class AddAdminRole implements Action {
  type = `${moduleName}/${UsersActions.AddAdminRole}`;

  constructor(private readonly user: IUser) {}
}

export class RevokeAdminRole implements Action {
  type = `${moduleName}/${UsersActions.RevokeAdminRole}`;

  constructor(private readonly user: IUser) {}
}

export class DeleteUser implements Action {
  type = `${moduleName}/${UsersActions.DeleteUser}`;

  constructor(private readonly user?: IUser) {}
}

export class SuspendUser implements Action {
  type = `${moduleName}/${UsersActions.SuspendUser}`;

  constructor(private readonly user?: IUser) {}
}

export class RestoreUser implements Action {
  type = `${moduleName}/${UsersActions.RestoreUser}`;

  constructor(private readonly user?: IUser) {}
}

export class HideUser implements Action {
  type = `${moduleName}/${UsersActions.HideUser}`;

  constructor(private readonly user?: IUser) {}
}

export class UnHideUser implements Action {
  type = `${moduleName}/${UsersActions.UnHideUser}`;

  constructor(private readonly user?: IUser) {}
}

export class LoadMembers implements Action {
  type = `${moduleName}/${UsersActions.LoadMembers}`;

  constructor(
    private readonly page: number = 1,
    private readonly filter?: string,
    private readonly status?: Array<string>,
    private readonly roles?: Array<string>,
    private readonly sortBy?: string,
    private readonly sortOrder?: 'ASC' | 'DESC',
  ) {}
}

export class ExportMembers implements Action {
  type = `${moduleName}/${UsersActions.ExportMembers}`;

  constructor(private readonly filters?: Record<string, string | Array<string>>) {}
}

export class LoadNextStreamUser implements Action {
  type = `${moduleName}/${UsersActions.LoadNextStreamUser}`;
}

export class GenerateDemoUsers implements Action {
  type = `${moduleName}/${UsersActions.GenerateDemoUsers}`;
}

export class GenerateDemoUsersSchedule implements Action {
  type = `${moduleName}/${UsersActions.GenerateDemoUsersSchedule}`;
}

export class RemoveDemoUsers implements Action {
  type = `${moduleName}/${UsersActions.RemoveDemoUsers}`;
}

export class AcceptRules implements Action {
  type = `${moduleName}/${UsersActions.AcceptRules}`;

  constructor(private readonly user?: IUser) {}
}

export class FindIncompleteUsers implements Action {
  type = `${moduleName}/${UsersActions.FindIncompleteUsers}`;

  constructor(private readonly user?: IUser) {}
}

export class FindUsersWithLowProfile implements Action {
  type = `${moduleName}/${UsersActions.FindNotFullProfileUsers}`;
}

export class NotifyIncompleteUsers implements Action {
  type = `${moduleName}/${UsersActions.NotifyIncompleteUsers}`;

  constructor(private readonly user?: IUser) {}
}

export class NotifyNotFullProfileUsers implements Action {
  type = `${moduleName}/${UsersActions.NotifyNotFullProfileUsers}`;
}

export class LoadTimeSlots implements Action {
  type = `${moduleName}/${UsersActions.LoadTimeSlots}`;

  constructor(private readonly userRef?: UserReference) {}
}

export class LoadUserTimeSlots implements Action {
  type = `${moduleName}/${UsersActions.LoadUserTimeSlots}`;

  constructor(private readonly userRef?: UserReference) {}
}

export class LoadSchedule implements Action {
  type = `${moduleName}/${UsersActions.LoadSchedule}`;

  constructor(private readonly userRef: UserReference, private readonly start: Date, private readonly end: Date) {}
}

export class LoadUserStats implements Action {
  type = `${moduleName}/${UsersActions.LoadUserStats}`;

  constructor(private readonly userRef?: UserReference) {}
}

export class LoadUserChecklists implements Action {
  type = `${moduleName}/${UsersActions.LoadUserChecklists}`;

  constructor(private readonly userRef?: UserReference) {}
}

export class LoadUserPersonalChecklist implements Action {
  type = `${moduleName}/${UsersActions.LoadUserPersonalChecklist}`;

  constructor(private readonly userRef?: UserReference) {}
}

export class CheckUserChecklistItem implements Action {
  type = `${moduleName}/${UsersActions.CheckUserChecklistItem}`;

  constructor(private readonly name: string, private readonly userRef?: UserReference) {}
}

export class LoadManagers implements Action {
  type = `${moduleName}/${UsersActions.LoadManagers}`;
}

export class TestCompleteStatus implements Action {
  type = `${moduleName}/${UsersActions.TestCompleteStatus}`;

  constructor(private readonly complete: boolean) {}
}
