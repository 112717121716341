<template>
  <div>
    <v-snackbar
      ref="snackbar"
      v-for="(notification, index) in notifications"
      :style="{ 'margin-top': calcMargin(index) }"
      top
      right
      :timeout="notification.timeout"
      :value="Boolean(notification.message)"
      :key="notification.message + Math.random()"
      :color="notification.variant"
      @input="hideNotification(index)"
      sentry-tag="notification-snackbar"
    >
      {{ notification.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="hideNotification(index)" sentry-tag="notification-snackbar.button.close">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapState } from 'vuex';
import { HideNotification, NotificationsState } from '@/store/notifications';

export default Vue.extend({
  name: 'm-notification-snackbar',
  computed: {
    ...mapState<NotificationsState>('notifications', {
      notifications: (state: NotificationsState) => state.notifications,
    }),
  },
  methods: {
    calcMargin(index) {
      if (!index) return 0;

      const heightOfPreviousSnackbar = this.$refs.snackbar[index - 1].$el.firstChild.offsetHeight;
      return index * (heightOfPreviousSnackbar + 10) + 'px';
    },
    hideNotification(index) {
      this.$store.dispatch(new HideNotification(index));
    },
  },
});
</script>
