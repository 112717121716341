<template>
  <div>
    <div :style="style" class="d-flex justify-space-between py-4 m-section-container">
      <div>
        <h3 class="text--text mb-2">{{ $t('settings.rituals.ritualEdit.sectionMatching.title') }}</h3>
        <div class="text--text text--lighten-5">{{ $t('settings.rituals.ritualEdit.sectionMatching.subtitle') }}</div>
      </div>
      <div class="form_container">
        <div>
          {{ $t(`settings.rituals.ritualEdit.sectionMatching.repeatability.${periodic ? 'periodic' : 'once'}`) }}
        </div>
        <v-switch
          v-if="isDebugAvailable"
          v-model="periodic"
          :label="$t('settings.rituals.ritualEdit.sectionMatching.periodic')"
          hide-details
        />

        <div class="mt-4">{{ $t('settings.rituals.ritualEdit.sectionMatching.acceptance.title') }}</div>
        <v-chip class="ml-4 my-2" color="primary"
          >{{ $t(`settings.rituals.ritualEdit.sectionMatching.acceptance.items.${acceptanceRule}`) }}
        </v-chip>
        <template v-if="isDebugAvailable">
          <m-select v-model="acceptanceRule" :items="acceptanceRuleValue">
            <template v-slot:selection="{ item }">
              {{ $t(`settings.rituals.ritualEdit.sectionMatching.acceptance.items.${item}`) }}
            </template>
            <template v-slot:item="{ item }">
              {{ $t(`settings.rituals.ritualEdit.sectionMatching.acceptance.items.${item}`) }}
            </template>
          </m-select>
        </template>
        <template v-if="isSpecial">
          <div class="mt-4">{{ $t('settings.rituals.ritualEdit.sectionMatching.companyRule.title') }}</div>
          <m-select v-model="companyRule" :items="companyRules">
            <template v-slot:selection="{ item }">
              {{ $t(`settings.rituals.ritualEdit.sectionMatching.companyRule.items.${item}`) }}
            </template>
            <template v-slot:item="{ item }">
              {{ $t(`settings.rituals.ritualEdit.sectionMatching.companyRule.items.${item}`) }}
            </template>
          </m-select>
          <div class="mt-4">{{ $t('settings.rituals.ritualEdit.sectionMatching.jobRule.title') }}</div>
          <m-select v-model="jobRule" :items="jobRules">
            <template v-slot:selection="{ item }">
              {{ $t(`settings.rituals.ritualEdit.sectionMatching.jobRule.items.${item}`) }}
            </template>
            <template v-slot:item="{ item }">
              {{ $t(`settings.rituals.ritualEdit.sectionMatching.jobRule.items.${item}`) }}
            </template>
          </m-select>
        </template>
      </div>
    </div>
    <template v-if="isDebugAvailable">
      <v-divider />
      <div :style="style" class="d-flex justify-space-between py-4 pt-0 m-section-container mt-4">
        <div>
          <h3 class="text--text mb-2">{{ $t('settings.rituals.ritualEdit.sectionMentoring.title') }}</h3>
        </div>
        <div class="form_container">
          <v-switch
            v-model="monthlyFeedbackEnabled"
            :label="$t('settings.rituals.ritualEdit.sectionMentoring.enabled')"
            class="ma-0 mt-4"
          />
          <v-switch
            v-model="monthlyFeedbackReminderEnabled"
            :label="$t('settings.rituals.ritualEdit.sectionMentoring.remindersEnabled')"
            class="ma-0"
          />
          <template v-if="false">
            <p>EnabledAt: {{ enabledAt }}</p>
            <p>Feedback sent for month: {{ lastMonth }} (Next at {{ nextFeedback }})</p>
            <p>Feedback Reminder sent for month: {{ lastMonthReminder }} (Next at {{ nextFeedbackReminder }})</p>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { AcceptanceRule, IRitual } from '@mentessa/types';
import { mapGetters } from 'vuex';
import deepmerge from 'deepmerge';
import MSelect from '@/components/Inputs/MSelect/MSelect.vue';
import { addMonths, parseISO, set } from 'date-fns';

export default Vue.extend({
  name: 'm-ritual-edit-section-skills-matching',
  components: { MSelect },
  props: {
    value: { type: Object as PropType<IRitual>, default: () => ({}) },
  },
  data: () => ({
    acceptanceRuleValue: Object.values(AcceptanceRule),
    companyRules: ['same', 'cross', 'any'],
    jobRules: ['same', 'any'],
  }),
  computed: {
    ...mapGetters('tenant', {
      isDebugAvailable: 'isDebugAvailable',
      isSpecial: 'isSpecial',
    }),
    ...mapGetters('ui', {
      formatInUserTimeZone: 'formatInUserTimeZone',
    }),
    style() {
      return {
        '--m-flex-direction': this.$vuetify.breakpoint.smAndDown ? 'column' : 'row',
        '--m-text-align': this.$vuetify.breakpoint.smAndDown ? 'center' : '',
      };
    },
    periodic: {
      get() {
        return this.value?.attributes?.periodic ?? false;
      },
      set(value: boolean) {
        this.updateRitual({
          attributes: {
            periodic: value,
          },
        });
      },
    },
    acceptanceRule: {
      get() {
        return this.value?.attributes?.acceptanceRule ?? AcceptanceRule.Mentee;
      },
      set(rule: AcceptanceRule) {
        // Skip default rule
        if (rule === AcceptanceRule.Mentee && this.value?.attributes?.acceptanceRule == null) {
          return;
        }
        this.updateRitual({
          attributes: {
            acceptanceRule: rule,
          },
        });
      },
    },
    monthlyFeedbackEnabled: {
      get() {
        return this.value?.attributes?.monthlyFeedback?.enabled ?? false;
      },
      set(enabled: boolean) {
        // Skip default rule
        if (!enabled && this.value?.attributes?.monthlyFeedback?.enabled == null) {
          return;
        }
        this.updateRitual({
          attributes: {
            monthlyFeedback: {
              enabled,
            },
          },
        });
      },
    },
    monthlyFeedbackReminderEnabled: {
      get() {
        return this.value?.attributes?.monthlyFeedback?.reminderEnabled ?? false;
      },
      set(enabled: boolean) {
        // Skip default rule
        if (!enabled && this.value?.attributes?.monthlyFeedback?.reminderEnabled == null) {
          return;
        }
        this.updateRitual({
          attributes: {
            monthlyFeedback: {
              reminderEnabled: enabled,
            },
          },
        });
      },
    },
    companyRule: {
      get() {
        return this.value?.attributes?.companyRule ?? 'any';
      },
      set(rule: string) {
        this.updateRitual({
          attributes: {
            companyRule: rule,
          },
        });
      },
    },
    jobRule: {
      get() {
        return this.value?.attributes?.jobRule ?? 'any';
      },
      set(rule: string) {
        this.updateRitual({
          attributes: {
            jobRule: rule,
          },
        });
      },
    },
    lastMonth() {
      return this.value?.attributes?.monthlyFeedback?.lastMonth;
    },
    nextFeedback() {
      if (!this.value?.enabledAt) {
        return;
      }
      const notificationDay = 1;
      const notificationHour = 12;
      const date = set(addMonths(parseISO(this.value.createdAt), this.lastMonth ?? 1), {
        date: notificationDay,
        hours: notificationHour,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });
      return this.formatInUserTimeZone(date, 'MMMM d, yyyy H:mm');
    },
    lastMonthReminder() {
      return this.value?.attributes?.monthlyFeedback?.lastMonthReminder;
    },
    nextFeedbackReminder() {
      if (!this.value?.enabledAt) {
        return;
      }

      const notificationDay = 1 + 7;
      const notificationHour = 12;
      const date = set(
        addMonths(parseISO(this.value.createdAt), Math.max(this.lastMonthReminder ?? 1, this.lastMonth ?? 1) - 1),
        {
          date: notificationDay,
          hours: notificationHour,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        },
      );
      return this.formatInUserTimeZone(date, 'MMMM d, yyyy H:mm');
    },
    enabledAt() {
      return this.value?.enabledAt;
    },
  },
  methods: {
    updateRitual(dto: Partial<IRitual>) {
      const update = deepmerge(this.value, dto);
      this.$emit('input', update);
    },
  },
});
</script>

<style lang="scss" scoped>
.form_container {
  padding: 24px;
  width: 100%;
  background-color: var(--v-white-base);
  border: 1px solid var(--v-beige-base);
  border-radius: 16px;
  max-width: 600px;
}

.custom-time-picker .v-time-picker-title {
  pointer-events: none;
}

.v-time-picker-title__time > *:not(:first-child) {
  display: none;
}

.m-section-container {
  flex-direction: var(--m-flex-direction);
  text-align: var(--m-text-align);
  gap: 15px;
}
</style>
