<template>
  <ais-refinement-list ref="ais" :attribute="filter.attribute" :operator="filter.operator">
    <template v-slot="{ items, refine, searchForItems }">
      <v-autocomplete
        :items="items"
        :label="$t(`filters.${filter.name}`)"
        :value="selectedItems"
        hide-no-data
        item-text="label"
        item-value="value"
        multiple
        outlined
        return-object
        @blur="debounceSearch(searchForItems, '')"
        @update:search-input="debounceSearch(searchForItems, $event)"
      >
        <template v-slot:selection="{ item, index }">
          <span v-if="index === 0" :style="selectionStyle" class="text-truncate filter-selection">
            {{ tr(item.value) }}
          </span>
          <span v-if="index === 1" class="grey--text text-caption">, (+{{ selectedItems.length - 1 }}) </span>
        </template>
        <template v-slot:prepend-item>
          <v-list-item ripple @click="clear(refine)">
            <v-list-item-action class="mr-2">
              <v-icon :color="selectedItems.length > 0 ? 'primary darken-1' : ''"> mdi-close-box</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title> {{ $t('filters.reset') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2" />
        </template>
        <template v-slot:item="{ item, attrs, on }">
          <v-list-item #default="{ active }" v-bind="attrs" @click="refine(item.value)" v-on="on">
            <v-list-item-action class="mr-2">
              <v-checkbox :input-value="active" />
            </v-list-item-action>
            <v-tooltip open-delay="300" top>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-content v-bind="attrs" v-on="on">
                  <v-list-item-title> {{ tr(item.value) }} ({{ item.count }}) </v-list-item-title>
                </v-list-item-content>
              </template>
              <span> {{ tr(item.value) }} ({{ item.count }}) </span>
            </v-tooltip>
          </v-list-item>
        </template>
      </v-autocomplete>
    </template>
  </ais-refinement-list>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { mapState } from 'vuex';
import { SearchState } from '@/store/search';

export interface Filter {
  name: string;
  attribute: string;
  operator: 'and' | 'or';
  i18nPath?: string;
}

export default Vue.extend({
  name: 'm-ais-select-refinement',
  props: {
    filter: { type: Object as PropType<Filter> },
    debounce: { type: Number, default: 500 },
  },
  data: () => ({
    selectedItems: [],
    query: '',
    previousQuery: '',
    queryTimer: undefined,
    loading: false,
  }),
  computed: {
    ...mapState<SearchState>('search', {
      activeFacets: (state: SearchState) => state.activeFacets,
    }),
    selectionStyle() {
      return {
        '--selection-max-width': this.selectedItems.length > 1 ? '126px' : '170px',
      };
    },
    selected(): Array<string> {
      return this.activeFacets[this.filter.attribute] ?? [];
    },
  },
  watch: {
    async selected(newValues) {
      if (!this.$refs.ais?.state) {
        return;
      }
      const result = [];
      for (const value of newValues) {
        const item = this.$refs.ais.state.items.find((item) => item.value === value);
        if (item) {
          result.push(item);
        } else {
          result.push({ value, label: value });
        }
      }
      this.selectedItems = result;
    },
  },
  methods: {
    tr(value: string) {
      if (this.filter.i18nPath && this.$te(`${this.filter.i18nPath}.${value}`)) {
        return this.$t(`${this.filter.i18nPath}.${value}`);
      }

      return value;
    },
    debounceSearch(search: (string) => void, value: string) {
      if (value == null) return;
      this.loading = true;
      clearTimeout(this.queryTimer);
      this.queryTimer = setTimeout(async () => {
        try {
          if (this.previousQuery !== value) {
            this.previousQuery = value;
            search(value);
          }
        } finally {
          this.loading = false;
        }
      }, this.debounce);
    },

    clear(refine?: (any) => void) {
      if (refine) {
        for (const item of this.selected) {
          refine(item);
        }
      }
    },
  },
});
</script>

<style lang="scss">
.filter-selection {
  max-width: var(--selection-max-width);
}
</style>
