<template>
  <div class="fill-height">
    <div v-if="!showBookingDetails" ref="bookingContainer" class="m-user-availability-wrapper overflow-y-auto">
      <div class="d-flex pb-4">
        <div v-if="showCalendar" class="m-calendar-wrapper">
          <div>
            <v-toolbar flat>
              <v-btn color="grey darken-2" fab small text @click="prev">
                <v-icon small> mdi-chevron-left</v-icon>
              </v-btn>
              <v-spacer />
              <v-toolbar-title> {{ calendarTitle }}</v-toolbar-title>
              <v-spacer />
              <v-btn color="grey darken-2" fab small text @click="next">
                <v-icon small> mdi-chevron-right</v-icon>
              </v-btn>
            </v-toolbar>
            <v-calendar
              ref="calendar"
              v-model="selectedDate"
              :locale="lang"
              class="m-calendar"
              color="primary"
              type="month"
              @change="onRangeChanged"
            >
              <template v-slot:day-label="{ day, past, present, date }">
                <v-btn
                  :class="{ 'm-calendar-day__present': present, 'm-calendar-day__selected': date === selectedDate }"
                  :color="date === selectedDate ? 'primary' : hasSchedule(date) ? 'var(--v-beige-base)' : 'white'"
                  :disabled="past"
                  class="m-calendar-day"
                  depressed
                  rounded
                  @click="selectDate(date)"
                >
                  {{ day }}
                </v-btn>
              </template>
            </v-calendar>
          </div>
        </div>
        <div v-if="showTimeslots" class="m-timeslots-wrapper">
          <div class="d-flex align-center">
            <v-btn v-if="$vuetify.breakpoint.xsOnly" color="button" icon @click="toCalendar">
              <v-img contain height="24" src="@/assets/calendar-with-days.svg" width="24" />
            </v-btn>
            <h3 class="m-timeslots-date">
              {{
                noAvailableSlots
                  ? $t('userDetails.availability.noSlotsAvailableMonth', {
                      name: user.identity.attributes.firstName,
                      month: currentMonthName,
                    })
                  : headingDate
              }}
            </h3>
          </div>
          <div v-if="dailyScheduleColumns.length === 0 && !noAvailableSlots">
            <p class="mb-1">
              {{ $t('userDetails.availability.noSlotsAvailableDay', { name: user.identity.attributes.firstName }) }}
            </p>
          </div>
          <p v-else-if="!noAvailableSlots" class="mb-1">{{ $t('userDetails.availability.oneHourSlots') }}</p>
          <div class="d-flex">
            <div v-for="(column, index) in dailyScheduleColumns" :key="index">
              <div v-for="(slot, index) in column" :key="index">
                <v-btn
                  :class="{ 'outlined-light': !isSlotSelected(slot.hour) }"
                  :color="!isSlotSelected(slot.hour) ? 'gray' : 'primary'"
                  :disabled="isMe"
                  class="my-1 mr-3"
                  depressed
                  outlined
                  rounded
                  @click="selectSlot(slot)"
                >
                  {{ slot.hour }}
                </v-btn>
              </div>
            </div>
          </div>
          <div v-if="showSheetTimeslots" class="fill-width my-4 py-6" />
        </div>
      </div>
      <v-btn
        v-if="!isMe && $vuetify.breakpoint.smAndUp"
        ref="bookBtn"
        :disabled="bookButtonDisabled"
        block
        class="m-user-availability-wrapper__book-button my-4"
        color="button"
        large
        @click="toDetails"
      >
        {{ bookButtonText }}
      </v-btn>
    </div>
    <div v-else class="fill-height d-flex flex-column justify-space-between">
      <div>
        <div class="m-booking-preview mb-4">
          <div class="d-flex">
            <div>
              <v-img contain height="24" src="@/assets/calendar-with-days.svg" width="24" />
            </div>
            <p class="mt-1 mb-0 ml-1">{{ selectedSlotDate }}</p>
          </div>
          <v-btn class="outlined-light" depressed min-width="120" outlined @click="toTimeslots">
            {{ $t('userDetails.availability.edit') }}
          </v-btn>
        </div>
        <m-text-area v-model="message" :label="$t('userDetails.availability.expectations')" />
      </div>
      <div class="d-flex fill-width py-4">
        <v-btn
          :x-large="$vuetify.breakpoint.xsOnly"
          class="mr-2"
          color="button"
          depressed
          large
          outlined
          width="calc(50% - 8px)"
          @click="toCalendar"
        >
          {{ $t('userDetails.availability.bookButton.cancel') }}
        </v-btn>
        <v-btn
          :x-large="$vuetify.breakpoint.xsOnly"
          class="ml-2"
          color="button"
          depressed
          large
          width="calc(50% - 8px)"
          @click="bookAppointment"
        >
          {{ sendButtonText }}
        </v-btn>
      </div>
    </div>
    <v-bottom-sheet :value="showSheetCalendar" hide-overlay no-click-animation persistent>
      <v-sheet class="text-center pt-4 pb-8 px-4">
        <v-btn block color="button" depressed x-large @click="toTimeslots">
          {{ $t('userDetails.availability.continueToPickASlot') }}
        </v-btn>
      </v-sheet>
    </v-bottom-sheet>
    <v-bottom-sheet :value="showSheetTimeslots" hide-overlay no-click-animation persistent>
      <v-sheet class="text-center pa-4">
        <v-btn block color="button" depressed x-large @click="toDetails">
          {{ $t('userDetails.availability.continueToBooking') }}
        </v-btn>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { IMentoringSession, IUser } from '@mentessa/types';
import { LoadSchedule, UsersState } from '@/store/users';
import { BookSession } from '@/store/matching';
import { addDays, addHours, format } from 'date-fns';
import { formatInTimeZone, fromZonedTime } from 'date-fns-tz';
import { MTextArea } from '@/components/Inputs';
import { CreateMentoringSessionAppointment } from '@/store/mentoring';

enum BookingStep {
  PickDate = 'pick_date',
  PickTime = 'pick_time',
  CompleteDetails = 'complete_details',
}

export default Vue.extend({
  name: 'm-user-availability-form',
  components: { MTextArea },
  props: {
    user: Object as PropType<IUser>,
    mentoringSession: Object as PropType<IMentoringSession>,
  },
  data: () => ({
    selectedDate: undefined,
    selectedSlot: undefined,
    isLoading: false,
    slots: {},
    step: BookingStep.PickDate as BookingStep,
    message: undefined,
    canShowCalendarSheet: false,
    calendarRange: undefined,
  }),
  computed: {
    ...mapGetters('tenant', {
      defaultLocale: 'defaultLocale',
    }),
    ...mapGetters('ui', {
      currentUserTz: 'currentUserTz',
      formatInUserTimeZone: 'formatInUserTimeZone',
      formatInUserLocale: 'formatInUserLocale',
    }),
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
      language: (state: UsersState) => state.me?.attributes.lang,
    }),
    isMe() {
      return this.user?.id === this.me?.id;
    },
    noAvailableSlots() {
      // ToDo: How we can check it?
      // return !this.isLoading && Object.keys(this.slots).length === 0;
      return false;
    },
    selectedSlotDate() {
      if (!this.selectedSlot) {
        return '';
      }
      const dateUtc = new Date(this.selectedSlot.dateUtc);
      const dateFormatted = this.formatInUserTimeZone(dateUtc, 'EEE, dd MMM • HH:mm');
      const dateInUtcPlusOneHour = addHours(dateUtc, 1);
      const nextHourFormatted = this.formatInUserTimeZone(dateInUtcPlusOneHour, 'HH:mm');
      return `${dateFormatted} — ${nextHourFormatted}`;
    },
    showCalendar() {
      return this.$vuetify.breakpoint.smAndUp || this.step === BookingStep.PickDate;
    },
    showTimeslots() {
      return this.$vuetify.breakpoint.smAndUp || this.step === BookingStep.PickTime;
    },
    showBookingDetails() {
      return this.step === BookingStep.CompleteDetails;
    },
    showSheetCalendar() {
      return (
        this.$vuetify.breakpoint.xsOnly &&
        this.step === BookingStep.PickDate &&
        this.hasSchedule(this.selectedDate) &&
        this.canShowCalendarSheet
      );
    },
    showSheetTimeslots() {
      return this.$vuetify.breakpoint.xsOnly && this.step === BookingStep.PickTime && this.selectedSlot?.hour;
    },
    headingDate() {
      if (this.selectedDate) {
        return this.formatInUserLocale(this.selectedDate, 'MMMM d, y');
      }
      return '';
    },
    currentMonthName() {
      if (this.selectedDate) {
        return this.formatInUserLocale(this.selectedDate, 'MMMM');
      }
      return '';
    },
    dailyScheduleColumns() {
      const schedules = [...(this.slots[this.selectedDate] || [])].sort((slotA, slotB) => {
        return new Date(slotA.dateUtc).getTime() - new Date(slotB.dateUtc).getTime();
      });

      const splitArray = (arr) => {
        return arr.reduce((result, current, index) => {
          const subArrayIndex = Math.floor(index / 6);

          if (!result[subArrayIndex]) {
            result[subArrayIndex] = [];
          }

          result[subArrayIndex].push(current);
          return result;
        }, []);
      };

      return splitArray(schedules);
    },
    bookButtonDisabled() {
      return !this.selectedSlot || this.selectedSlot.date !== this.selectedDate;
    },
    bookButtonText() {
      if (this.selectedSlot != null) {
        return this.$t('userDetails.availability.bookButton.book', {
          date: this.selectedSlotDate,
        });
      }
      return this.$t('userDetails.availability.bookButton.noSlotSelected');
    },
    sendButtonText() {
      return this.$vuetify.breakpoint.xsOnly
        ? this.$t('userDetails.availability.bookButton.send')
        : this.$t('userDetails.availability.bookButton.sendRequest');
    },
    calendarTitle() {
      if (!this.calendarRange?.start?.date) {
        return '';
      }

      return this.formatInUserLocale(this.calendarRange.start.date, 'MMMM y');
    },
    lang() {
      return this.me?.attributes.lang ?? this.defaultLocale;
    },
  },
  methods: {
    formatInTimeZone,
    fromZonedTime,
    toCalendar() {
      this.step = BookingStep.PickDate;
    },
    toTimeslots() {
      this.message = undefined;
      this.step = BookingStep.PickTime;
    },
    toDetails() {
      this.step = BookingStep.CompleteDetails;
    },
    selectDate(date) {
      this.selectedSlot = null;
      this.selectedDate = date;
      this.canShowCalendarSheet = true;
    },
    async onRangeChanged(range) {
      this.calendarRange = range;
      await this.getSchedule();
    },
    async getSchedule() {
      if (!this.calendarRange) {
        return;
      }

      this.isLoading = true;
      try {
        const schedules = await this.$store.dispatch(
          new LoadSchedule(
            this.user,
            fromZonedTime(this.calendarRange.start.date, this.currentUserTz),
            addDays(fromZonedTime(this.calendarRange.end.date, this.currentUserTz), 1),
          ),
        );

        schedules.forEach((schedule) => {
          schedule.dates?.forEach((dateUtc) => {
            const date = formatInTimeZone(dateUtc, this.currentUserTz, 'y-MM-dd H:mm');
            const day = date.slice(0, 10);
            const slotToAdd = { id: schedule.id, hour: date.slice(11, 16), dateUtc };

            if (this.slots[day] == null) {
              this.$set(this.slots, day, [slotToAdd]);
            } else if (this.slots[day].find((slot) => slot.hour === slotToAdd.hour) === undefined) {
              this.$set(this.slots, day, [...this.slots[day], slotToAdd]);
            }
          });
        });

        if (schedules) {
          this.selectedSlot = null;
        }
      } finally {
        this.isLoading = false;
      }
    },
    hasSchedule(date: string): boolean {
      return this.slots[date]?.length > 0;
    },
    async prev() {
      this.selectedSlot = null;
      this.$refs.calendar.prev();
    },
    async next() {
      this.selectedSlot = null;
      this.$refs.calendar.next();
      // await this.getSchedule();
    },
    isSlotSelected(hour) {
      return this.selectedSlot?.hour === hour && this.selectedSlot?.date === this.selectedDate;
    },
    async selectSlot(slot) {
      const weekday = this.formatInUserTimeZone(slot.dateUtc, 'EEE').toLowerCase();
      this.selectedSlot = { id: slot.id, weekday, hour: slot.hour, date: this.selectedDate, dateUtc: slot.dateUtc };
      if (this.$refs.bookBtn?.$el) {
        await this.$vuetify.goTo(this.$refs.bookBtn.$el, { container: this.$refs.bookingContainer });
      }
    },
    async bookAppointment() {
      this.isLoading = false;
      const date = new Date(this.selectedSlot.dateUtc);
      try {
        let success = true;
        if (!this.mentoringSession) {
          success = await this.$store.dispatch(
            new BookSession(this.user, { id: this.selectedSlot.id }, date, this.message, {
              available: true,
              time: this.selectedSlot.hour,
              day: this.selectedSlot.weekday,
              date: this.selectedSlot.date,
            }),
          );
        } else {
          success = await this.$store.dispatch(
            new CreateMentoringSessionAppointment(
              this.user,
              this.mentoringSession,
              { id: this.selectedSlot.id },
              date,
              this.message,
              {
                time: this.selectedSlot.hour,
                day: this.selectedSlot.weekday,
                date: this.selectedSlot.date,
              },
            ),
          );
        }
        if (success) {
          const i = this.slots[this.selectedSlot.date].findIndex((slot) => {
            return slot.id === this.selectedSlot.id && slot.hour === this.selectedSlot.hour;
          });
          this.slots[this.selectedSlot.date].splice(i, 1);
          this.$set(this.slots, this.selectedSlot.date, this.slots[this.selectedSlot.date]);
          this.selectedSlot = null;
          this.toCalendar();
        }
      } finally {
        this.message = undefined;
        this.selectedDate = format(new Date(), 'y-MM-dd');
        this.isLoading = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.m-booking-preview {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--v-beige-base);
  border-radius: 16px;
  padding: 24px;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    flex-direction: column;

    .v-btn {
      width: 100%;
      margin-top: 16px;
    }
  }
}

.m-calendar-wrapper {
  margin-right: 12px;
  min-height: 326px;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-right: 0;
  }

  > div {
    width: 290px;
  }
}

.m-timeslots-wrapper {
  padding-top: 20px;
  margin-left: 12px;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    width: 100%;
    margin-left: 0;
  }
}

.m-user-availability-wrapper {
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__book-button {
    max-height: 44px;
  }
}

:deep(.v-toolbar__content) {
  padding: 4px 0;
}

.v-btn.m-calendar-day {
  width: 36px;
  min-width: 36px;
  padding: 0;

  &__present:not(.m-calendar-day__selected) {
    color: var(--v-primary-base);
  }
}

.m-calendar {
  &.v-calendar-weekly {
    border-top: white 1px solid;
    border-left: white 1px solid;
    height: auto;
  }

  :deep(.v-calendar-weekly__day) {
    border-right: white 1px solid;
    border-bottom: white 1px solid;
  }

  :deep(.v-calendar-weekly) {
    border-right: white 1px solid;
  }

  :deep(.v-calendar-weekly__day.v-outside),
  :deep(.v-calendar-weekly__head-weekday.v-outside) {
    background-color: white;
  }

  :deep(.v-calendar-weekly__day .m-calendar-day.v-btn--disabled) {
    background-color: white !important;
  }

  :deep(.v-calendar-weekly__head-weekday) {
    border-right: white 1px solid;
  }
}

:deep(.v-dialog) {
  box-shadow: 0 -1px var(--v-beige-base);
}
</style>
