<template>
  <!--  <div style="border: 2px solid blue">-->
  <!--    <v-img alt="Handshake" :src="require('../../assets/handshake-emoji.png')" contain height="100" width="100"></v-img>-->
  <!--  </div>-->
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L13 13" stroke="#6B7280" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'HandshakeEmoji',
});
</script>
