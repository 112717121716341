<template>
  <div>
    <v-tabs background-color="white" v-model="currentTab">
      <v-tab sentry-tag="settings-debug.tab.translations.customise">Customise Translations</v-tab>
      <v-tab sentry-tag="settings-debug.tab.translations.diff">Missing Translations</v-tab>
    </v-tabs>
    <v-tabs-items v-model="currentTab">
      <v-tab-item>
        <v-row>
          <m-select
            v-model="custom.locale"
            :items="locales"
            sentry-tag="debug-ui-locale"
            label="Locale"
            class="ma-4 pt-2"
          >
            <template v-slot:selection="data">
              <v-chip>
                {{ nameForLang(data.item) }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              {{ nameForLang(data.item) }}
            </template>
          </m-select>
        </v-row>
        <m-text-area label="Default Content" v-model="custom.defaultContent" readonly class="ma-4" />
        <m-text-area label="Customised Content" v-model="custom.customisedContent" class="ma-4" />
        <v-btn class="ma-4" sentry-tag="settings-debug.button.saveCustomisedContent" @click="saveLocaleContent">
          Save Content
        </v-btn>
      </v-tab-item>
      <v-tab-item>
        <v-row>
          <m-select
            v-model="compare.baseLocale"
            :items="locales"
            sentry-tag="debug-other-locale"
            class="ma-6"
            label="Base Language"
          >
            <template v-slot:selection="data">
              <v-chip>
                {{ nameForLang(data.item) }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              {{ nameForLang(data.item) }}
            </template>
          </m-select>
          <m-select
            v-model="compare.otherLocale"
            :items="locales"
            sentry-tag="debug-base-locale"
            class="ma-6"
            label="Other Language"
          >
            <template v-slot:selection="data">
              <v-chip>
                {{ nameForLang(data.item) }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              {{ nameForLang(data.item) }}
            </template>
          </m-select>
        </v-row>
        <m-text-area label="Difference Preview" v-model="differencePreview" readonly class="ma-4" />
        <v-btn
          class="ma-4"
          sentry-tag="settings-debug.button.downloadDifferenceInLocale"
          @click="downloadDifferenceLocale"
        >
          Download Difference
        </v-btn>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script lang="ts">
import { MSelect, MTextArea } from '@/components/Inputs';
import { mapGetters } from 'vuex';
import { CreateLocaleContent, LoadLocaleContent, UpdateLocaleContent } from '@/store/ui';
import Vue from 'vue';
import { addedDiff } from 'deep-object-diff';

export default Vue.extend({
  name: 'm-settings-debug-translations',
  data: () => ({
    currentTab: undefined,
    custom: {
      locale: undefined,
      defaultContent: undefined,
      customisedContent: undefined,
      hasCustomisedContent: false,
    },
    compare: {
      baseLocale: 'en',
      otherLocale: 'en',
      diff: undefined,
    },
  }),
  components: { MTextArea, MSelect },
  methods: {
    async saveLocaleContent() {
      if (this.custom.hasCustomisedContent) {
        await this.$store.dispatch(
          new UpdateLocaleContent(this.custom.locale, JSON.parse(this.custom.customisedContent)),
        );
      } else {
        await this.$store.dispatch(
          new CreateLocaleContent(this.custom.locale, JSON.parse(this.custom.customisedContent)),
        );
      }
    },
    downloadDifferenceLocale() {
      let filename = `${this.compare.otherLocale}-diff-${this.compare.baseLocale}.json`;
      let element = document.createElement('a');
      element.setAttribute(
        'href',
        `data:application/json;charset=utf-8,${encodeURIComponent(JSON.stringify(this.compare.diff, undefined, 4))}`,
      );
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();
      document.body.removeChild(element);
    },
    async getDifferenceLocale() {
      const baseLocale = (await import(`@/locales/${this.compare.baseLocale}.json`)).default;
      const otherLocale = (await import(`@/locales/${this.compare.otherLocale}.json`)).default;
      return addedDiff(otherLocale, baseLocale);
    },
  },
  computed: {
    ...mapGetters('utils', {
      nameForLang: 'nameForLang',
    }),
    differencePreview() {
      return JSON.stringify(this.compare.diff ?? {}, undefined, 4);
    },
    locales() {
      return Object.keys(this.$i18n.messages);
    },
  },
  watch: {
    async 'custom.locale'() {
      const localeContent = await this.$store.dispatch(new LoadLocaleContent(this.custom.locale));
      const defaultLocale = (await import(`@/locales/${this.custom.locale}.json`)).default;
      this.custom.defaultContent = JSON.stringify(defaultLocale, undefined, 4);
      this.custom.hasCustomisedContent = !!localeContent;
      this.custom.customisedContent = JSON.stringify(localeContent || {}, undefined, 4);
    },
    async 'compare.baseLocale'() {
      this.compare.diff = await this.getDifferenceLocale();
    },
    async 'compare.otherLocale'() {
      this.compare.diff = await this.getDifferenceLocale();
    },
  },
  async mounted() {
    this.custom.locale = this.$i18n.locale;
  },
});
</script>
