<template>
  <div class="content_card_container">
    <div class="content_card__header d-flex justify-space-between align-center">
      <h3 class="text--text">{{ content.title }}</h3>
      <div class="d-flex align-center">
        <div class="text--text text--lighten-5">{{ formattedDate }}</div>
        <div v-if="isContentManagingAvailable">
          <v-menu bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link sentry-tag="content-admins.menu.editContent" @click="$emit('onEdit', content)">
                <v-list-item-title>{{ $t('content.action.onEdit') }}</v-list-item-title>
              </v-list-item>
              <v-list-item link sentry-tag="content-admins.menu.deleteContent" @click="$emit('onDelete', content)">
                <v-list-item-title>{{ $t('content.action.onDelete') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </div>
    <m-video v-if="isVideo" :focusedVideo="focusedContent" :video="video" @onFocus="focusContent" />
    <div v-else class="text--text" v-html="markedContent" />
    <v-chip-group column>
      <v-chip v-for="(tag, index) in content.attributes.tags" :key="index">{{ tag }}</v-chip>
    </v-chip-group>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';
import { ContentType, IContent, IVideo } from '@mentessa/types';
import MVideo from '../MVideo';

export default Vue.extend({
  name: 'm-content-card',
  props: {
    content: { type: Object as PropType<IContent>, default: () => ({}) },
  },
  components: {
    MVideo,
  },
  data: () => ({
    focusedContent: undefined,
  }),
  computed: {
    ...mapGetters('tenant', {
      isContentManagingAvailable: 'isContentManagingAvailable',
    }),
    ...mapGetters('ui', {
      formatInUserTimeZone: 'formatInUserTimeZone',
    }),
    formattedDate() {
      if (this.content?.createdAt) {
        return this.formatInUserTimeZone(this.content.createdAt, 'd.M.yyyy');
      }
      return '';
    },
    markedContent() {
      return this.$marked(this.content.content);
    },
    video(): IVideo {
      return {
        id: this.content.id,
        url: this.content.content,
        type: this.content.attributes.video.type,
        attributes: { preview: this.content.attributes.video.preview, tags: this.content.attributes.tags },
      };
    },
    isVideo() {
      return this.content.type === ContentType.Video;
    },
  },
  methods: {
    focusContent(video: IVideo) {
      this.focusedContent = video;
    },
  },
});
</script>

<style lang="scss" scoped>
.content_card_container {
  padding: 30px;
  background-color: var(--v-white-base);
  width: 100%;
  border: 1px solid var(--v-beige-base);
  border-radius: 16px;
}

.content_card__header {
  width: 100%;
}
</style>
