<template>
  <m-panel>
    <h4 class="text-uppercase">{{ $t('panels.pending_matches.title') }}</h4>
    <v-slide-group
      v-if="hasMatches"
      v-model="activeItem"
      center-active
      class="m-slide-group mt-4"
      mandatory
      show-arrows
      @click:next="selectNext"
      @click:prev="selectPrev"
    >
      <v-slide-item>
        <div style="width: 64px; height: 100%"></div>
      </v-slide-item>
      <v-slide-item
        v-for="(match, index) in matches"
        :key="`match-${match.id}-${index}`"
        v-slot="{ toggle }"
        class="mx-2"
      >
        <m-match-card :value="match" @click="toggle" @onStateChanged="reload" />
      </v-slide-item>
      <v-slide-item>
        <div style="width: 64px; height: 100%"></div>
      </v-slide-item>
    </v-slide-group>
    <m-empty-state
      v-else
      :image="require('@/assets/empty/matching.svg')"
      :title="$t('panels.pending_matches.empty.title')"
    />
    <m-user-details-simple-dialog v-if="activeMatch" v-model="showUserDetailsDialog" :user-id="activeMatch.id" />
  </m-panel>
</template>

<script lang="ts">
import Vue from 'vue';
import MPanel from '@/components/MPanel';
import { LoadUserMatches } from '@/store/matching';
import { IMatchUser, IUser, MatchStatus } from '@mentessa/types';
import { MUserDetailsSimpleDialog } from '@/components/Dialogs';
import MMatchCard from '@/components/Cards/MMatchCard';
import MEmptyState from '@/components/MEmptyState';
import { RouteName } from '@/router/types';
import { compareMatches } from '@/utils/matches';

export default Vue.extend({
  name: 'm-pending-matches-panel',
  components: { MEmptyState, MMatchCard, MUserDetailsSimpleDialog, MPanel },
  data: () => ({
    matches: [] as Array<IMatchUser>,
    loading: false,
    activeMatch: undefined as IUser,
    showUserDetailsDialog: false,
    activeItem: 1,
    communityRoute: { name: RouteName.Community },
  }),
  computed: {
    hasMatches() {
      return this.matches.length > 0;
    },
  },
  watch: {
    loading(value: boolean) {
      if (!value) {
        this.$emit('onReady');
      }
    },
  },
  async mounted() {
    await this.reload();
  },
  methods: {
    async reload() {
      try {
        this.loading = true;
        const matches: Array<IMatchUser> = await this.$store.dispatch(new LoadUserMatches(MatchStatus.Pending));
        this.matches = matches.filter((match) => match.match?.matchUsers?.[0]?.user != null).sort(compareMatches);
        this.activeItem = 1;
      } finally {
        this.loading = false;
      }
    },
    showDetails(user: IUser, toggle: () => void) {
      toggle();
      this.activeMatch = user;
      this.showUserDetailsDialog = true;
    },
    selectNext() {
      this.activeItem = (this.activeItem + 1) % (this.matches.length + 2);
    },
    selectPrev() {
      this.activeItem = (this.activeItem - 1) % (this.matches.length + 2);
    },
  },
});
</script>

<style lang="scss" scoped>
.m-slide-group:deep(.v-slide-group__wrapper) {
  mask: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 5%, rgba(0, 0, 0, 1) 95%, rgba(0, 0, 0, 0) 100%);
}

.m-slide-group:deep(.v-slide-group__next) {
  min-width: 24px;
}

.m-slide-group:deep(.v-slide-group__prev) {
  min-width: 24px;
}
</style>
