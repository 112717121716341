import { MutationTree } from 'vuex';
import { CommunityRulesMutations, CommunityRulesState } from './types';
import { ICommunityRules } from '@mentessa/types';

export const mutations: MutationTree<CommunityRulesState> = {
  [CommunityRulesMutations.SetCommunityRules](state: CommunityRulesState, communityRules: ICommunityRules[]) {
    state.communityRules = communityRules;
  },

  [CommunityRulesMutations.AppendCommunityRules](state: CommunityRulesState, communityRules: ICommunityRules) {
    state.communityRules.push(communityRules);
  },

  [CommunityRulesMutations.RemoveCommunityRules](state: CommunityRulesState, communityRules: ICommunityRules) {
    state.communityRules = state.communityRules.filter((rule) => rule.id !== communityRules.id);
  },

  [CommunityRulesMutations.UpdateCommunityRules](state: CommunityRulesState, communityRules: ICommunityRules) {
    const rulesToUpdate = state.communityRules.find((rules) => rules.name === communityRules.name);
    if (rulesToUpdate) {
      rulesToUpdate.rules = communityRules.rules;
    }
  },

  [CommunityRulesMutations.SetCommunityRulesEnabled](state: CommunityRulesState, status: boolean) {
    state.enabled = status;
  },

  [CommunityRulesMutations.SetCommunityRulesRequired](state: CommunityRulesState, status: boolean) {
    state.required = status;
  },
};
