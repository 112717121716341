import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { GetBookedSessions, MatchingActions, MatchingMutations, MatchingState } from './types';
import { api } from '@/store/api';
import { ShowErrorNotification, ShowNotification } from '@/store/notifications';
import i18n from '@/plugins/i18n';
import { getIdOrCurrent } from '@/utils/api';
import { MatchStatus, MentoringRole } from '@mentessa/types';

export const actions: ActionTree<MatchingState, RootState> = {
  async [MatchingActions.AddMatch]({ dispatch }, { pairs, roles, message, createSession }) {
    try {
      const preparedPairs = pairs.map((pair) => ({
        matchUsers: [
          { user: { id: pair.left.id }, attributes: { role: roles?.left ?? MentoringRole.Peer } },
          { user: { id: pair.right.id }, attributes: { role: roles?.right ?? MentoringRole.Peer } },
        ],
      }));
      await api.post('matches', { pairs: preparedPairs, message, createSession });
      await dispatch(new ShowNotification(i18n.t('matching.created').toString()), {
        root: true,
      });
      return true;
    } catch (e) {
      console.error('Error while add matching', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
    return false;
  },

  async [MatchingActions.LoadMatches]({ dispatch, commit }, { page, filter, order }) {
    try {
      const response = await api.get(`matches?page=${page}`, { params: { ...filter, ...order } });
      commit(MatchingMutations.SetMatches, response.data);
      return response.data;
    } catch (e) {
      console.error('Error while LoadMatches', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [MatchingActions.BookSession]({ dispatch }, { targetRef, scheduleRef, date, message, slot, userRef }) {
    let success = false;
    try {
      const response = await api.post(`/users/${getIdOrCurrent(userRef)}/appointments`, {
        target: { id: targetRef.id },
        schedule: { id: scheduleRef.id },
        attributes: { message },
        date,
        slot,
      });
      success = response.status === 201;
    } catch (e) {
      console.error('Error while BookSession', e);
      success = false;
    }
    if (success) {
      await dispatch(new ShowNotification(i18n.t('timeSlots.notifyBooked').toString()), {
        root: true,
      });
    } else {
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
    return success;
  },

  async [MatchingActions.RequestMatch]({ dispatch }, { targetRef, message, purpose, actor }) {
    try {
      const response = await api.post(`/users/${getIdOrCurrent(actor)}/matches`, {
        targetRef: { id: targetRef.id },
        message,
        purpose,
      });
      await dispatch(new ShowNotification(i18n.t('notifications.matching.requested').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.error('Error while RequestMatch', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [MatchingActions.ChangeMatchAcceptedStatus]({ dispatch }, { matchRef, status, actor }) {
    try {
      const response = await api.put(`/users/${getIdOrCurrent(actor)}/matches/${matchRef.id}/accepted`, {
        status,
      });
      return response.data;
    } catch (e) {
      console.error('Error while SetMatchAcceptedStatus', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [MatchingActions.LoadMatchMentoringSessionRef]({ dispatch }, { matchRef, actor }) {
    try {
      const response = await api.get(`/users/${getIdOrCurrent(actor)}/matches/${matchRef.id}/mentoring-session`);
      return response.data?.mentoringSessions?.[response.data.mentoringSessions.length - 1];
    } catch (e) {
      console.error('Error while LoadMatchMentoringSessionRef', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [MatchingActions.UpdateAppointment]({ dispatch, commit }, { appointmentRef, userRef, dto }) {
    try {
      const response = await api.put(`/users/${getIdOrCurrent(userRef)}/appointments/${appointmentRef.id}`, dto);
      commit(MatchingMutations.ChangeAppointment, { appointmentRef, dto });
      return response.data;
    } catch (e) {
      console.error('Error while UpdateAppointment', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async [MatchingActions.GetBookedSessions]({ commit, dispatch }, { user }) {
    try {
      const response = await api.get(`/users/${getIdOrCurrent(user)}/appointments`);
      commit(MatchingMutations.SetAppointments, response.data);
      return response.data;
    } catch (e) {
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      console.error('Error while GetBookedSessions', e);
    }
  },

  async [MatchingActions.ClearBookedSession]({ dispatch }, { user, appointment }) {
    try {
      await api.delete(`/users/${getIdOrCurrent(user)}/appointments/${appointment.id}`);
      await dispatch(new GetBookedSessions(user));
    } catch (e) {
      console.error('Error while GetBookedSessions', e);
    }
  },

  async [MatchingActions.RandomizeSlots]({ dispatch }) {
    try {
      await api.post('manage/debug/randomizeTimeSlots');
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
    } catch (e) {
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      console.error('Error while RandomizeSlots', e);
    }
  },

  async [MatchingActions.ClearBookedSlots]({ dispatch }) {
    try {
      await api.post('manage/debug/clearBookedSlots');
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
    } catch (e) {
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      console.error('Error while ClearBookedSlots', e);
    }
  },

  async [MatchingActions.SendAppointmentFeedback]({ dispatch }, { appointmentRef, userRef, feedback }) {
    try {
      const response = await api.post(`/users/${getIdOrCurrent(userRef)}/appointments/${appointmentRef.id}/feedback`, {
        feedback,
      });
      return response.data;
    } catch (e) {
      console.error('Error while SendAppointmentFeedback.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [MatchingActions.LoadPendingAppointments]({ dispatch }, { userRef }) {
    try {
      const response = await api.get(`/users/${getIdOrCurrent(userRef)}/appointments/feedbacks`);
      return response.data;
    } catch (e) {
      console.error('Error while LoadPendingAppointments.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [MatchingActions.LoadUserMatches]({ dispatch }, { userRef, status }) {
    try {
      const response = await api.get(`/users/${getIdOrCurrent(userRef)}/matches`, { params: { status } });
      return response.data;
    } catch (e) {
      console.error('Error while LoadUserMatches.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [MatchingActions.LoadPendingPatches]({ dispatch }, { userRef }) {
    try {
      const response = await api.get(`/users/${getIdOrCurrent(userRef)}/matches`, {
        params: { status: MatchStatus.Pending },
      });
      return response.data;
    } catch (e) {
      console.error('Error while LoadPendingPatches.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
};
