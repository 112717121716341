<template>
  <div>
    <div class="d-flex flex-row m-settings-members__header align-center gap-4 mt-4">
      <m-search-field v-model="query" :loading="loading" />
      <div class="ml-auto">
        <v-btn :loading="exportInProgress" class="ml-4" color="button" depressed large @click="exportMembers(false)">
          {{ $t('settings.members.export') }}
        </v-btn>
        <v-btn
          v-if="false"
          :loading="exportInProgress"
          class="ml-4"
          color="button"
          depressed
          large
          @click="exportMembers(true)"
        >
          {{ $t('settings.members.export_answers') }}
        </v-btn>
      </div>
    </div>
    <v-chip-group v-if="isRitualsManagingAvailable" v-model="filters" class="px-6 mt-4" column multiple>
      <v-chip v-for="(item, index) in availableFilters" :key="index" filter>
        <span> {{ item.label }} </span>
      </v-chip>
    </v-chip-group>
    <v-data-table
      :headers="headers"
      :items="members"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalMembers"
      hide-default-footer
      show-expand
      single-expand
      @item-expanded="loadDetails"
    >
      <template v-slot:item.user="{ item }">
        <m-user-preview :user="item.user" job />
      </template>
      <template v-slot:item.role="{ item }">
        <v-chip v-if="item.role" color="primary">{{ item.role }}</v-chip>
      </template>
      <template v-slot:item.enrollmentStatus="{ item }">
        <v-chip color="success">{{ $t(`settings.rituals.enrollment_status.${item.enrollmentStatus}`) }}</v-chip>
      </template>
      <template v-slot:item.matched="{ item }">
        <v-chip v-if="item.matched" color="success">Matched</v-chip>
        <v-chip v-else color="warning">Not Matched</v-chip>
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
      <template v-slot:item.accepted="{ item }">
        <v-chip v-if="item.accepted === true" color="success">{{ $t('settings.ritualEnroll.accepted') }}</v-chip>
        <v-chip v-else-if="item.accepted === false" color="error">{{ $t('settings.ritualEnroll.declined') }}</v-chip>
        <div v-else class="d-flex align-center justify-center">
          <a class="mr-4 primary--text" @click="declineMember(item)">
            {{ $t('settings.ritualEnroll.decline') }}
          </a>
          <v-btn color="button" outlined small @click="acceptMember(item)">
            {{ $t('settings.ritualEnroll.accept') }}
          </v-btn>
        </div>
      </template>

      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length" class="pa-4">
          <v-stepper alt-labels flat>
            <v-stepper-header>
              <v-stepper-step complete step="">
                {{ $t('settings.rituals.enrollment_status.registered') }}
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="item.enrollmentStatus !== EnrollmentStatus.Registered" step=""
                >{{ $t('settings.rituals.enrollment_status.enrolled') }}
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="item.enrollmentStatus === EnrollmentStatus.Ready" step=""
                >{{ $t('settings.rituals.enrollment_status.ready') }}
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
          <m-panel v-if="answers.length > 0">
            <v-simple-table>
              <thead>
                <tr>
                  <th class="text-center">
                    <span>{{ $t('settings.rituals.answers.question') }}</span>
                  </th>
                  <th class="text-center">
                    <span>{{ $t('settings.rituals.answers.answer') }}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(answer, index) in answers" :key="`answer-${index}`">
                  <td>{{ answer.question }}</td>
                  <td>
                    <v-chip-group v-if="answer.type === 'chips'" column>
                      <v-chip v-for="(item, index) of answer.value" :key="`chip-${index}`">{{ item }}</v-chip>
                    </v-chip-group>
                    <v-simple-table v-else-if="answer.type === 'table'">
                      <thead v-if="false">
                        <tr>
                          <th class="text-center"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) of answer.value" :key="`item-${index}`" class="text-center">
                          <th>{{ item.name }}</th>
                          <th>{{ item.value }}</th>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <span v-else>{{ answer.value }}</span>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </m-panel>
          <div class="d-flex flex-row gap-4 justify-end">
            <v-btn color="error--text" @click="exclude(item)">{{ $t('settings.ritualEnroll.exclude') }}</v-btn>
            <v-btn v-if="isDebugAvailable && false" @click="toggleAccepted(item)">Toggle Accepted</v-btn>
          </div>
        </td>
      </template>
      <template v-slot:no-data>
        <p class="mt-4 text-center">{{ $t('settings.ritualEnroll.noMembers') }}</p>
      </template>
    </v-data-table>
    <v-row class="justify-end mr-4 mt-2"> Total: {{ totalMembers }}</v-row>
    <v-row dense>
      <v-pagination
        v-model="currentPage"
        :disabled="loading"
        :length="totalPages"
        class="m-settings-members__table-pagination pa-6 ml-auto"
        sentry-tag="settings-members.pagination"
      />
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {
  AcceptRitualManagedUser,
  AcceptRitualUser,
  DeclineRitualManagedUser,
  DeclineRitualUser,
  EnrollDemoUsersToRitual,
  ExportRitualAnswers,
  ExportRitualMembers,
  LoadRitualManagedMembers,
  LoadRitualMemberDetails,
  LoadRitualMembers,
  RemoveFromRitual,
  RitualsState,
} from '@/store/rituals';
import {
  IRitualQuestionnaireAnswer,
  IRitualUser,
  MentoringRole,
  QuestionType,
  RitualType,
  RitualUserPage,
  RitualUserStatus,
} from '@mentessa/types';
import { mapGetters, mapState } from 'vuex';
import { MUserPreview } from '@/components/MUserPreview';
import { MSearchField } from '@/components/Inputs';
import { MPanel } from '@/components/MPanel';

enum FilterKind {
  Status = 'status',
  Matched = 'matched',
  Role = 'role',
}

enum EnrollmentStatus {
  Registered = 'registered', // Invited, but a user is not complete
  Enrolled = 'enrolled', // Invited and a user is complete
  Ready = 'ready', // A user finished a questionnaire
}

interface FilterItem {
  kind: FilterKind;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  label?: string;
}

interface Answer {
  question: string;
  rawType: QuestionType;
  type: 'text' | 'chips' | 'table';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: string | Array<any> | object;
}

function getEnrollmentStatus(ritualUser: IRitualUser) {
  if (!ritualUser.user?.complete) {
    return EnrollmentStatus.Registered;
  } else if ([RitualUserStatus.Invited, RitualUserStatus.Enrollment].includes(ritualUser.status)) {
    return EnrollmentStatus.Enrolled;
  }

  return EnrollmentStatus.Ready;
}

export default Vue.extend({
  name: 'm-ritual-members-new',
  components: { MSearchField, MPanel, MUserPreview },
  data: () => ({
    loading: false,
    selected: [],
    demoUsersCount: 0,
    options: undefined,
    membersPage: undefined as RitualUserPage,
    query: undefined,
    exportInProgress: false,
    filters: [],
    FilterKind,
    EnrollmentStatus,
    answers: [] as Array<Answer>,
  }),
  computed: {
    ...mapGetters('tenant', {
      isDebugAvailable: 'isDebugAvailable',
      isSpecial: 'isSpecial',
      isRitualsManagingAvailable: 'isRitualsManagingAvailable',
    }),
    ...mapState<RitualsState>('rituals', {
      ritual: (state: RitualsState) => state.current,
    }),
    ...mapGetters('ui', {
      formatInUserTimeZone: 'formatInUserTimeZone',
    }),

    headers() {
      const headers = [];
      if (!this.ritual) {
        return headers;
      }

      if (this.isDebugAvailable) {
        headers.push({ text: 'Id', value: 'id', sortable: false });
      }

      headers.push({ text: 'Member', value: 'user', sortable: false });

      if (this.ritual.type !== RitualType.HappyHour) {
        headers.push({ text: 'Role', value: 'role', sortable: true, align: 'center' });
      }

      headers.push(
        { text: this.$t('settings.members.table.status'), value: 'enrollmentStatus', sortable: true, align: 'center' },
        // { text: 'Matched', value: 'matched', sortable: true, align: 'center' },

        { text: this.$t('settings.members.table.createdAt'), value: 'createdAt', sortable: true },
      );

      if (this.isSpecial) {
        headers.push({
          text: 'Accepted',
          value: 'accepted',
          sortable: true,
          align: 'center',
        });
      }

      headers.push({ text: '', value: 'data-table-expand' });

      return headers;
    },

    currentPage: {
      get() {
        return this.membersPage?.meta?.currentPage ?? 1;
      },
      async set(value: number) {
        await this.loadMembers(value);
      },
    },

    totalPages() {
      return this.membersPage?.meta?.totalPages ?? 1;
    },

    totalMembers() {
      return this.membersPage?.meta?.totalItems ?? 0;
    },

    members() {
      return (
        this.membersPage?.items?.map((ritualUser) => {
          const role = ritualUser.ritualQuestionnairesAnswers?.[0]?.ritualQuestionnaire?.filter?.role;
          return {
            ...ritualUser,
            enrollmentStatus: getEnrollmentStatus(ritualUser),
            matched: true,
            role: role ? this.$t(`mentoring.role.${role.toLowerCase()}`) : undefined,
          };
        }) ?? []
      );
    },

    availableRoles() {
      const roles = [];
      if (this.ritual?.type === RitualType.SkillsMatching) {
        roles.push(MentoringRole.Mentor, MentoringRole.Mentee, MentoringRole.Peer);
        if (this.ritual.attributes?.roles?.any) {
          roles.push('any');
        }
      }
      return roles;
    },

    availableFilters() {
      const items: Array<FilterItem> = [
        {
          kind: FilterKind.Status,
          value: EnrollmentStatus.Registered,
          label: this.$t('settings.rituals.enrollment_status.registered'),
        },
        {
          kind: FilterKind.Status,
          value: EnrollmentStatus.Enrolled,
          label: this.$t('settings.rituals.enrollment_status.enrolled'),
        },
        {
          kind: FilterKind.Status,
          value: EnrollmentStatus.Ready,
          label: this.$t('settings.rituals.enrollment_status.ready'),
        },
        {
          kind: FilterKind.Matched,
          value: false,
          label: this.$t('settings.ritualEnroll.unmatched'),
        },
      ];

      this.availableRoles.forEach((role) => {
        items.push({
          kind: FilterKind.Role,
          value: role,
          label: this.$t(`mentoring.role.${role.toLowerCase()}`),
        });
      });

      // items.push({ kind: FilterKind.Matched, value: false, label: 'Not Matched' });

      return items;
    },
  },
  watch: {
    async ritual() {
      await this.loadMembers();
    },
    async query() {
      await this.loadMembers();
    },
    options: {
      async handler() {
        await this.loadMembers();
      },
      deep: true,
    },
    async filters() {
      await this.loadMembers();
    },
  },
  async mounted() {
    if (this.ritual) {
      await this.loadMembers();
    }
  },
  methods: {
    async loadMembers(page = 1) {
      if (!this.ritual) {
        return;
      }
      if (this.isRitualsManagingAvailable) {
        await this.loadAllMembers(page);
      } else {
        await this.loadManagedMembers(page);
      }
    },
    async loadAllMembers(page = 1) {
      try {
        const { sortBy, sortDesc } = this.options;
        this.loading = true;
        const orderBy = sortBy?.length > 0 ? (sortDesc[0] ? 'DESC' : 'ASC') : undefined;
        const statusFilter = this.filters
          .filter((filterIndex: number) => this.availableFilters[filterIndex].kind === FilterKind.Status)
          .map((filterIndex) => this.availableFilters[filterIndex].value);
        const roleFilter = this.filters
          .filter((filterIndex: number) => this.availableFilters[filterIndex].kind === FilterKind.Role)
          .map((filterIndex) => this.availableFilters[filterIndex].value);
        const matchedFilter = this.filters.find(
          (filterIndex) => this.availableFilters[filterIndex].kind === FilterKind.Matched,
        )
          ? false
          : undefined;

        this.membersPage = await this.$store.dispatch(
          new LoadRitualMembers(
            this.ritual,
            page,
            this.query,
            statusFilter,
            roleFilter,
            matchedFilter,
            sortBy[0],
            orderBy,
          ),
        );
      } finally {
        this.loading = false;
      }
    },

    async loadManagedMembers(page = 1) {
      try {
        this.loading = true;
        this.membersPage = await this.$store.dispatch(new LoadRitualManagedMembers(this.ritual, page));
      } finally {
        this.loading = false;
      }
    },

    formatDate(date: string) {
      return this.formatInUserTimeZone(date, 'dd.MM.yyyy');
    },

    async exportMembers(answers = false) {
      this.exportInProgress = true;
      try {
        const statusFilter = this.filters
          .filter((filterIndex: number) => this.availableFilters[filterIndex].kind === FilterKind.Status)
          .map((filterIndex) => this.availableFilters[filterIndex].value);
        const roleFilter = this.filters
          .filter((filterIndex: number) => this.availableFilters[filterIndex].kind === FilterKind.Role)
          .map((filterIndex) => this.availableFilters[filterIndex].value);
        const matchedFilter = this.filters.find(
          (filterIndex) => this.availableFilters[filterIndex].kind === FilterKind.Matched,
        )
          ? false
          : undefined;

        const action = answers
          ? new ExportRitualAnswers(this.ritual, this.query, statusFilter, roleFilter, matchedFilter)
          : new ExportRitualMembers(this.ritual, this.query, statusFilter, roleFilter, matchedFilter);

        await this.$store.dispatch(action);
      } finally {
        this.exportInProgress = false;
      }
    },

    memberRole(member: IRitualUser) {
      const roles = member?.user?.attributes?.roles ?? [];
      if (roles.includes('Mentor')) {
        return this.$t('mentoring.role.mentor');
      } else if (roles.includes('Mentee')) {
        return this.$t('mentoring.role.mentee');
      }
      return '';
    },

    async enrollDemoUsers() {
      if (this.demoUsersCount > 0) {
        await this.$store.dispatch(new EnrollDemoUsersToRitual({ id: this.ritual?.id }, this.demoUsersCount));
        await this.fetchData(this.page);
      }
    },
    updateAccepted(memberIds: Array<number>, status: boolean, all = false) {
      this.members.forEach((member) => {
        if (all || memberIds.includes(member.id)) member.accepted = status;
      });
    },
    async acceptMember(member: IRitualUser) {
      try {
        this.loading = true;
        const action = this.isRitualsManagingAvailable
          ? new AcceptRitualUser(this.ritual, member)
          : new AcceptRitualManagedUser(this.ritual, member);

        if (await this.$store.dispatch(action)) {
          this.updateAccepted([member.id], true);
          this.toggleSelected(member.id, false);
        }
      } finally {
        this.loading = false;
      }
    },
    async declineMember(member: IRitualUser) {
      try {
        this.loading = true;
        const action = this.isRitualsManagingAvailable
          ? new DeclineRitualUser(this.ritual, member)
          : new DeclineRitualManagedUser(this.ritual, member);

        if (await this.$store.dispatch(action)) {
          this.updateAccepted([member.id], false);
          this.toggleSelected(member.id, false);
        }
      } finally {
        this.loading = false;
      }
    },
    async toggleAccepted(member: IRitualUser) {
      if (member.accepted) {
        await this.declineMember(member);
      } else {
        await this.acceptMember(member);
      }
    },
    async exclude(member: IRitualUser) {
      try {
        this.loading = true;
        await this.$store.dispatch(new RemoveFromRitual(this.ritual, member));
        const memberIndex = this.membersPage.items.findIndex((m) => m.id === member.id);
        if (memberIndex !== -1) {
          this.membersPage.items.splice(memberIndex, 1);
        }
      } finally {
        this.loading = false;
      }
    },
    async loadDetails(row: { value: boolean; item: IRitualUser }) {
      this.answers = [];
      if (row?.value) {
        const ritualUser: IRitualUser & {
          ritualQuestionnairesAnswers: Array<IRitualQuestionnaireAnswer>;
        } = await this.$store.dispatch(new LoadRitualMemberDetails(this.ritual, row.item));
        const rqa = ritualUser.ritualQuestionnairesAnswers?.[0];
        if (!rqa) {
          return;
        }

        const i18nPath = `questionnaire.${rqa.ritualQuestionnaire.questionnaire.title}`;
        const result: Array<Answer> = [];

        rqa.ritualQuestionnaire.questionnaire.sections.forEach((section) => {
          if (section.name === 'result') {
            return;
          }
          section.questions.forEach((question) => {
            if (
              [
                QuestionType.Schedule,
                QuestionType.Guidelines,
                QuestionType.RatingWithText,
                QuestionType.Submit,
                QuestionType.WeekdayExtended,
                QuestionType.Weekday,
              ].includes(question.type)
            ) {
              return;
            }
            const answer: Answer = {
              question: this.$t(`${i18nPath}.${section.name}.questions.${question.name}`),
              rawType: question.type,
              value: rqa.answers?.[section.name]?.[question.name],
              type: 'text',
            };
            if (Array.isArray(answer.value)) {
              answer.type = 'chips';
              if (question.type === QuestionType.ChipGroup) {
                answer.value = answer.value?.map((value) => {
                  const itemName = question.items?.[value];
                  return this.$t(`${i18nPath}.${section.name}.items.${question.name}.${itemName}`);
                });
              } else if (question.type === QuestionType.FreeSkillsTags) {
                answer.value = answer.value?.map((value) => value.name);
              }
            }
            if (question.type === QuestionType.ScaleMultiple) {
              answer.type = 'table';
              const values = [];
              for (const value in answer.value as object) {
                const name = this.$t(`${i18nPath}.${section.name}.items.${question.name}.${value}`);
                values.push({ name, value: answer.value[value] });
              }
              answer.value = values;
            }
            result.push(answer);
          });
        });

        this.answers = result;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
:deep(.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content) {
  box-shadow: none;
}
</style>
