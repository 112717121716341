<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :value="true"
    persistent
    sentry-tag="m-appointment-feedback-dialog"
    width="368px"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card class="d-flex flex-column justify-center pa-4 m-content-container">
      <m-loader v-if="loading" :is-loading="loading" class="m-loader" />
      <div v-else class="text-center">
        <v-img
          :src="require('@/assets/sparkles.png')"
          class="d-inline-block"
          contain
          max-height="128"
          max-width="128"
        />
        <h2 class="text-center mb-2">{{ $t('mentoring.appointments.dialogs.feedback.title') }}</h2>
        <p class="text-center text--secondary mb-6">{{ $t('mentoring.appointments.dialogs.feedback.text') }}</p>
        <v-btn color="button" depressed large @click="closeDialog">
          {{
            $t(
              `mentoring.appointments.dialogs.feedback.cta.${
                isMentoringSession ? 'prepareNextSession' : 'exploreCommunity'
              }`,
            )
          }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { AppointmentFeedback, ITenant } from '@mentessa/types';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';
import MLoader from '@/components/MLoader.vue';
import { SendMentoringSessionAppointmentFeedback } from '@/store/mentoring';
import { RouteName } from '@/router/types';
import { SendAppointmentFeedback } from '@/store/matching';

export default Vue.extend({
  name: 'm-appointment-feedback-dialog',
  components: { MLoader },
  props: {
    appointmentId: Number,
    sessionId: Number,
    feedback: { type: String as PropType<AppointmentFeedback> },
  },
  data: () => ({
    loading: true,
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState): ITenant => state.tenant,
    }),
    tenantTitle() {
      return this.tenant?.attributes?.theme?.title ?? '';
    },
    isMentoringSession() {
      return this.sessionId != null;
    },
  },
  methods: {
    async closeDialog() {
      await this.$router.replace({
        name: this.isMentoringSession ? RouteName.ActiveMentoringSession : RouteName.Community,
        params: this.$route.params,
        query: { ...this.$route.query, feedback: undefined },
      });
    },
  },
  async mounted() {
    if (!this.appointmentId || !this.feedback) {
      await this.closeDialog();
      return;
    }

    try {
      const action = this.isMentoringSession
        ? new SendMentoringSessionAppointmentFeedback({ id: this.sessionId }, { id: this.appointmentId }, this.feedback)
        : new SendAppointmentFeedback({ id: this.appointmentId }, this.feedback);
      await this.$store.dispatch(action);
      this.loading = false;
    } catch (e) {
      await this.closeDialog();
    }
  },
});
</script>

<style lang="scss" scoped>
.m-content-container {
  position: relative;
  min-height: 368px;
}

.m-loader {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
