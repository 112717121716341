import store from '@/store';
import { LoadMentoringSession } from '@/store/mentoring';
import { NavigationGuardNext, Route } from 'vue-router';
import { RouteName } from '@/router/types';
import { MentoringSessionStatus } from '@mentessa/types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function appointmentsGuard(to: Route, from: Route, next: NavigationGuardNext): boolean {
  if (!store.getters['tenant/isBookingAvailable']) {
    next({ name: RouteName.Community });
  } else {
    next();
  }
  return true;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function sessionRedirector(to: Route, from: Route, next: NavigationGuardNext): Promise<boolean> {
  const sessionId = to.params.sessionId;
  await store.dispatch(new LoadMentoringSession({ id: parseInt(sessionId) }));
  const session = store.getters['mentoring/getSession'](parseInt(sessionId));

  switch (session.data.status) {
    case MentoringSessionStatus.Progress:
      next({ name: RouteName.ActiveMentoringSession, params: { sessionId: sessionId } });
      break;
    case MentoringSessionStatus.Finished:
      next({ name: RouteName.ClosedMentoringSession, params: { sessionId: sessionId } });
      break;
    default:
      next();
  }

  return true;
}

export function mentoringRedirection(to: Route, from: Route, next: NavigationGuardNext): boolean {
  if (to.name === 'mentoring') {
    const currentSession = store.getters['mentoring/currentSession'];
    if (currentSession) {
      next({ name: RouteName.MentoringSession, params: { sessionId: currentSession.data.id } });
    } else {
      next({ name: RouteName.ActiveMentoring });
    }
  } else {
    next();
  }
  return true;
}
