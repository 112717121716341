<template>
  <div class="d-flex flex-column fill-height fill-width">
    <v-alert color="primary" dense type="info">
      {{ $t('chat.note', { email: email }) }}
    </v-alert>
    <div class="d-flex fill-height fill-width">
      <m-inbox-sidebar
        :is-select-user-opened-external="isSelectUserOpenedExternal"
        :value="isSidebarVisible"
        @input="changeSidebarVisible"
        class="flex-grow-0"
      />
      <m-inbox-chat
        :is-rooms-loading="isRoomsLoading"
        :value="isChatVisible"
        @input="changeChatVisible"
        @onButtonStartChatting="isSelectUserOpenedExternal = true"
        class="flex-grow-1"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { MInboxSidebar } from '@/components/MInbox';
import MInboxChat from '@/components/MInbox/MInboxChat/MInboxChat.vue';
import { mapState } from 'vuex';
import { LoadRooms, MessagesState, SelectRoom } from '@/store/messages';
import { RoomEntity } from '@mentessa/types';
import { RouteName } from '@/router/types';
import { TenantState } from '@/store/tenant';

export default Vue.extend({
  name: 'm-inbox',
  components: {
    MInboxChat,
    MInboxSidebar,
  },
  props: {
    roomId: Number,
  },
  data: () => ({
    isSidebarVisible: true,
    isChatVisible: true,
    isRoomsLoading: false,
    isSelectUserOpenedExternal: false,
  }),
  computed: {
    ...mapState<MessagesState>('messages', {
      rooms: (state: MessagesState) => state.rooms,
      room: (state: MessagesState) => state.activeRoom,
    }),
    ...mapState<TenantState>('tenant', {
      email: (state: TenantState) => state.tenant?.ambassador?.identity?.attributes?.email ?? 'admin@mentessa.com',
    }),
  },
  watch: {
    '$vuetify.breakpoint.xs'(value) {
      if (value) {
        if (this.room?.id != null) {
          this.isSidebarVisible = false;
          this.isChatVisible = true;
        } else {
          this.isSidebarVisible = true;
          this.isChatVisible = false;
        }
      } else {
        this.isChatVisible = true;
        this.isSidebarVisible = true;
      }
    },
  },
  async mounted() {
    try {
      this.isRoomsLoading = true;
      await this.$store.dispatch(new LoadRooms());
    } finally {
      this.isRoomsLoading = false;
    }
    if (this.roomId) {
      const room = this.rooms.find((r) => r.id === Number(this.roomId));
      if (room) {
        await this.selectRoom(room);
        if (this.$vuetify.breakpoint.xsOnly) {
          this.isSidebarVisible = false;
          this.isChatVisible = true;
        }
      } else {
        await this.$router.push('/inbox');
        if (this.$vuetify.breakpoint.xsOnly) {
          this.isSidebarVisible = true;
          this.isChatVisible = false;
        }
      }
    } else {
      if (this.$vuetify.breakpoint.xsOnly) {
        this.isSidebarVisible = true;
        this.isChatVisible = false;
      }
    }
  },
  methods: {
    async selectRoom(room: RoomEntity) {
      await this.$store.dispatch(new SelectRoom(room));
      if (this.$route.path !== `/inbox/${room.id}`) {
        await this.$router.push({ name: RouteName.InboxRoom, params: { roomId: String(room.id) } });
      }
    },
    changeSidebarVisible(value) {
      this.isSidebarVisible = value;
      if (!value && !this.isChatVisible) {
        this.isChatVisible = true;
      }
    },
    changeChatVisible(value) {
      this.isChatVisible = value;
      if (!value && !this.isSidebarVisible) {
        this.isSidebarVisible = true;
      }
    },
  },
});
</script>
