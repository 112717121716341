import { Action } from '@/store/types';
import { InstantSearch } from 'instantsearch.js';

export const moduleName = 'search';

export type ResponseCache = Map<string, never>;

export interface SearchState {
  isLoading: boolean;

  cache: {
    users: ResponseCache;
  };

  instantSearchInstance: InstantSearch;
  activeFacets: Record<string, Array<string>>;
}

// export default class RequestWithCache {
//   private responseCache: Map<string, any> = new Map<string, any>()
//
//   // Todo: should probably be passed a callback instead, or an apiCall instance. Types are messy this way
//   async perform<T extends any>(
//     requestContext: any,
//     requestFunction: (...params: any) => unknown,
//     requestFunctionArguments: any[],
//     cacheOptions: CacheOptions
//   ): Promise<T> {
//     const { cacheResponseForSeconds = defaultCacheResponseForSeconds, maxSize = defaultMaxSize } = cacheOptions
//     const isCacheDisabled = cacheResponseForSeconds <= 0 || maxSize <= 0
//
//     if (isCacheDisabled) {
//       return requestFunction.call(requestContext, ...requestFunctionArguments)
//     }
//
//     const requestFunctionArgumentsJSON = JSON.stringify(requestFunctionArguments)
//     const cacheEntry = this.responseCache.get(requestFunctionArgumentsJSON)
//     const now = Date.now()
//
//     if (cacheEntry) {
//       const isEntryValid = now - cacheEntry.requestTimestamp < cacheResponseForSeconds * 1000
//       if (isEntryValid) {
//         this.responseCache.delete(requestFunctionArgumentsJSON)
//         this.responseCache.set(requestFunctionArgumentsJSON, cacheEntry)
//         return Promise.resolve(cacheEntry.response)
//       } else {
//         this.responseCache.delete(requestFunctionArgumentsJSON)
//       }
//     }
//     const response = await requestFunction.call(requestContext, ...requestFunctionArguments)
//     this.responseCache.set(requestFunctionArgumentsJSON, {
//       requestTimestamp: now,
//       response
//     })
//     const isCacheOverMaxSize = this.responseCache.size > maxSize
//     if (isCacheOverMaxSize) {
//       const oldestEntry = this.responseCache.keys().next().value
//       this.responseCache.delete(oldestEntry)
//     }
//     return response as T
//   }
// }

export enum SearchActions {
  SearchUsers = '[Search] SearchUsers',
  CheckDomainNameAvailability = '[Search] CheckDomainNameAvailability',
  ChangeInstantSearchInstance = '[Search] ChangeInstantSearchInstance',
  ChangeActiveFacets = '[Search] ChangeActiveFacets',
}

export enum SearchMutations {
  SetInstantSearchInstance = '[Search] SetInstantSearchInstance',
  SetActiveFacets = '[Search] SetActiveFacets',
}

export class SearchUsers implements Action {
  type = `${moduleName}/${SearchActions.SearchUsers}`;

  constructor(public token: string) {
    this.token = token;
  }
}

export class CheckDomainNameAvailability implements Action {
  type = `${moduleName}/${SearchActions.CheckDomainNameAvailability}`;

  constructor(private readonly name: string) {}
}

export class ChangeInstantSearchInstance implements Action {
  type = `${moduleName}/${SearchActions.ChangeInstantSearchInstance}`;

  constructor(private readonly instance: InstantSearch) {}
}

export class ChangeActiveFacets implements Action {
  type = `${moduleName}/${SearchActions.ChangeActiveFacets}`;

  constructor(private readonly facets: Record<string, Array<string>>) {}
}
