import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { mutations } from './mutations';
import { actions } from './actions';
import { moduleName, RitualTemplatesState } from './types';
import { getters } from './getters';

export * from './types';

export function communityRulesGetter(getter: string) {
  return `${moduleName}/${getter}`;
}

export const initialState: RitualTemplatesState = {
  ritualTemplates: [],
  rawRitualTemplates: [],
};

export const ritualTemplatesModule: Module<RitualTemplatesState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

export default ritualTemplatesModule;
