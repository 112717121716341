<template>
  <div :style="style" class="d-flex justify-space-between py-4 m-section-container">
    <div>
      <h3 class="text--text mb-2">{{ $t('settings.rituals.ritualEdit.sectionMatching.title') }}</h3>
      <div class="text--text text--lighten-5">{{ $t('settings.rituals.ritualEdit.sectionMatching.subtitle') }}</div>
    </div>
    <div class="form_container">
      <div class="text--text text--lighten-1 mb-2">
        {{ $t('settings.rituals.ritualEdit.sectionMatching.labelMeetingPoints') }}
      </div>
      <m-text-field v-model="meetingPoints" class="pr-4" type="number"></m-text-field>

      <div class="text--text text--lighten-1 mb-2">
        {{ $t('settings.rituals.ritualEdit.sectionMatching.labelDate') }}
      </div>
      <v-date-picker v-model="date" color="primary" />

      <div class="text--text text--lighten-1 mb-2">
        {{ $t('settings.rituals.ritualEdit.sectionMatching.labelDeadline') }}
      </div>

      <m-select
        v-model="hour"
        :items="hours"
        :label="$t('settings.rituals.hour')"
        class="pr-4"
        sentry-tag="ritual-edit-dialog.select.hour"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IRitual } from '@mentessa/types';
import deepmerge from 'deepmerge';
import { parseISO } from 'date-fns';
import MTextField from '@/components/Inputs/MTextField/MTextField.vue';
import MSelect from '@/components/Inputs/MSelect/MSelect.vue';
import { formatInTimeZone, fromZonedTime, toZonedTime } from 'date-fns-tz';

export default Vue.extend({
  name: 'm-ritual-edit-section-event-matching',
  components: { MSelect, MTextField },
  props: {
    value: { type: Object as PropType<IRitual>, default: () => ({}) },
  },
  data: function () {
    return {
      hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0],
    };
  },
  computed: {
    meetingPoints: {
      get() {
        return this.value?.attributes?.meetingPoints ?? 1;
      },
      set(value: number) {
        const ritualUpdate = {
          attributes: {
            meetingPoints: value,
          },
        };
        const update = deepmerge(this.value, ritualUpdate);
        this.$emit('input', update);
      },
    },
    date: {
      get() {
        const ritualDate = this.value?.attributes?.schedule?.options?.once?.date;
        const date = ritualDate ? parseISO(ritualDate.toString()) : new Date();
        return formatInTimeZone(date, 'CET', 'yyyy-MM-dd');
      },
      set(value: string) {
        const ritualDate = this.value?.attributes?.schedule?.options?.once?.date;
        const date = ritualDate ? parseISO(ritualDate.toString()) : new Date();
        const newDate = parseISO(value);
        const utcDate = toZonedTime(date, 'CET');
        utcDate.setFullYear(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
        this.updateDate(fromZonedTime(utcDate, 'CET'));
      },
    },
    hour: {
      get() {
        const ritualDate = this.value?.attributes?.schedule?.options?.once?.date;
        const date = ritualDate ? parseISO(ritualDate.toString()) : new Date();
        return toZonedTime(date, 'CET').getHours();
      },
      set(value: number) {
        const ritualDate = this.value?.attributes?.schedule?.options?.once?.date;
        const date = ritualDate ? parseISO(ritualDate.toString()) : new Date();
        const utcDate = toZonedTime(date, 'CET');
        utcDate.setHours(value, 0, 0, 0);
        this.updateDate(fromZonedTime(utcDate, 'CET'));
      },
    },

    style() {
      return {
        '--m-flex-direction': this.$vuetify.breakpoint.smAndDown ? 'column' : 'row',
        '--m-text-align': this.$vuetify.breakpoint.smAndDown ? 'center' : '',
      };
    },
  },
  mounted() {
    const ritualDate = this.value?.attributes?.schedule?.options?.once?.date;
    const date = ritualDate ? parseISO(ritualDate.toString()) : new Date();

    const ritualUpdate = {
      attributes: {
        meetingPoints: this.value?.attributes?.meetingPoints ?? 1,
        schedule: {
          options: {
            once: {
              date: date.toISOString(),
            },
          },
        },
      },
    };

    const update = deepmerge(this.value, ritualUpdate);
    this.$emit('input', update);
  },
  methods: {
    updateDate(date: Date) {
      const ritualUpdate = {
        attributes: {
          schedule: {
            options: {
              once: {
                date: date.toISOString(),
              },
            },
          },
        },
      };
      const update = deepmerge(this.value, ritualUpdate);
      this.$emit('input', update);
    },
  },
});
</script>

<style lang="scss" scoped>
.form_container {
  padding: 24px;
  width: 100%;
  background-color: var(--v-white-base);
  border: 1px solid var(--v-beige-base);
  border-radius: 16px;
  max-width: 600px;
}

.custom-time-picker .v-time-picker-title {
  pointer-events: none;
}

.v-time-picker-title__time > *:not(:first-child) {
  display: none;
}

.m-section-container {
  flex-direction: var(--m-flex-direction);
  text-align: var(--m-text-align);
  gap: 15px;
}
</style>
