import { IRitual, RitualType } from '@mentessa/types';
import { compareAsc, parseISO } from 'date-fns';

const typeScore = {
  [RitualType.HappyHour]: 2,
  [RitualType.EventMatching]: 1,
  [RitualType.SkillsMatching]: 1,
  [RitualType.Custom]: 0,
};

// HappyHour > EventMatching > Custom
function compareTypes(lType: RitualType, rType: RitualType): number {
  return typeScore[rType] - typeScore[lType];
}

export function compareRitualDates(l: IRitual, r: IRitual): number {
  const lDate = l.attributes.schedule?.options?.once?.date
    ? parseISO(l.attributes.schedule.options.once.date.toString())
    : undefined;
  const rDate = r.attributes.schedule?.options?.once?.date
    ? parseISO(r.attributes.schedule.options.once.date.toString())
    : undefined;

  if (lDate == undefined && rDate != undefined) return -1;
  else if (lDate != undefined && rDate == undefined) return 1;

  return compareAsc(lDate, rDate);
}

export function compareRituals(l: IRitual, r: IRitual): number {
  const enabledDiff = Number(r.enabled) - Number(l.enabled);
  if (enabledDiff !== 0) return enabledDiff;

  const typeDiff = compareTypes(l.type, r.type);
  if (typeDiff !== 0) return typeDiff;

  return compareRitualDates(l, r);
}
