<template>
  <m-autocomplete
    v-model="localValue"
    :items="items"
    :search-input="search"
    sentry-tag="m-time-zone-picker"
    v-bind="{ ...$props, ...$attrs }"
  >
  </m-autocomplete>
</template>

<script lang="ts">
import MAutocomplete from '@/components/Inputs/MAutocomplete/MAutocomplete.vue';
import { listTimeZones } from 'timezone-support';

export default MAutocomplete.extend({
  name: 'm-time-zone-picker',
  components: {
    MAutocomplete,
  },
  props: {
    value: String,
    items: { type: Array, default: () => listTimeZones() },
  },
  data: () => ({
    search: '',
  }),
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value: string) {
        this.$emit('input', value);
        this.search = '';
      },
    },
  },
});
</script>
