<template>
  <div class="m-dashboard-widget__col-2">
    <h3>{{ $t('dashboard.widgets.ritual_members.title') }}</h3>
    <h4 class="mt-4">{{ $t('dashboard.widgets.ritual_members.total') }}</h4>
    <h2 class="text-center mt-4">{{ members }}</h2>
    <h4 class="mt-4">{{ $t('dashboard.widgets.ritual_members.members_share') }}</h4>
    <h2 class="text-center mt-4">{{ share }}</h2>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

export interface RitualMembersData {
  ritual_members: Array<{
    total_users: number;
    member: number;
  }>;
}

export default Vue.extend({
  name: 'm-ritual-members-widget',
  props: {
    value: { type: Object as PropType<RitualMembersData> },
  },
  data: () => ({
    total_users: 0,
    members: 0,
  }),
  computed: {
    share() {
      if (this.total_users === 0) {
        return '-';
      }
      return `${Math.floor((this.members / this.total_users) * 100)}%`;
    },
  },
  async mounted() {
    this.total_users = Number(this.value?.ritual_members?.[0]?.total_users ?? 0);
    this.members = Number(this.value?.ritual_members?.[0]?.members ?? 0);
  },
});
</script>
