<template>
  <div class="section-settings" sentry-tag="settings-community">
    <m-settings-panel
      :description="$t('settings.community.info.description')"
      :title="$t('settings.community.info.title')"
    >
      <m-text-field
        v-model="tenantAttributes.theme.title"
        :label="$t('settings.community.info.name.title')"
        :placeholder="$t('settings.community.info.name.placeholder')"
        sentry-tag="settings-community.input.name"
      />

      <m-text-field
        v-model="tenantAttributes.theme.tagLine"
        :label="$t('settings.community.info.tagLine.title')"
        :placeholder="$t('settings.community.info.tagLine.placeholder')"
        sentry-tag="settings-community.input.tagLine"
      />

      <m-image-file-input v-model="tenantImage" class="form-image-step__file-input" />
      <p class="text--text text--lighten-4 mb-5">{{ $t('settings.community.info.logo.hint') }}</p>

      <p class="mb-n4">{{ $t('settings.community.info.color.title') }}</p>
      <v-btn
        :color="tenantAttributes.theme.colors.primary"
        large
        @click="isColorPickerDialogOpen = true"
        width="64"
        min-width="64"
        sentry-tag="settings-community.button.close"
      />
      <v-dialog v-model="isColorPickerDialogOpen" content-class="m-color-picker-dialog">
        <v-color-picker
          v-model="tenantAttributes.theme.colors.primary"
          dot-size="25"
          mode="hexa"
          swatches-max-height="200"
          sentry-tag="settings-community.colorPicker"
        />
      </v-dialog>

      <m-ais-wrapper class="m-inbox-sidebar__search" index="users">
        <p class="mt-4 mb-2">{{ $t('settings.community.info.ambassador.title') }}</p>
        <p class="text--text text--lighten-4 mb-5">{{ $t('settings.community.info.ambassador.description') }}</p>
        <v-row>
          <m-ais-autocomplete-users v-model="ambassador" class="mx-3" max-width="600px" />
        </v-row>
      </m-ais-wrapper>

      <v-switch
        v-if="isDebugAvailable"
        :input-value="showBanner"
        @change="toggleBanner"
        :label="$t('settings.community.info.profileCompletionBanner')"
      />
    </m-settings-panel>

    <div class="tab-actions d-flex pt-8">
      <v-btn :loading="loading" depressed color="button" @click="save" sentry-tag="settings-community.button.save">
        {{ $t('settings.community.save') }}
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { TenantState, UpdateTenantAmbassador, UpdateTenantAttributes, UpdateTenantImage } from '@/store/tenant';
import { TenantAttributes, TenantEntity } from '@mentessa/types';
import MSettingsPanel from '@/components/Settings/MSettingsPanel';
import { MTextField } from '@/components/Inputs';
import { MImageFileInput } from '@/components/Inputs/MImageFileInput';
import MAisWrapper from '@/components/MAIS/AISWrapper';
import MAisAutocompleteUsers from '@/components/MAIS/AISAutocompleteUsers';
import { LoadUserDetails } from '@/store/users';

export default Vue.extend({
  name: 'm-settings-community',
  components: { MImageFileInput, MTextField, MSettingsPanel, MAisAutocompleteUsers, MAisWrapper },
  data: () => ({
    tenantAttributes: {
      theme: {
        title: undefined,
        tagLine: undefined,
        colors: {
          primary: undefined,
        },
        image: undefined,
      },
      hideCompletionBanner: undefined,
    } as Partial<TenantAttributes>,
    ambassador: undefined,
    isColorPickerDialogOpen: false,
    tenantImage: { src: null },
    loading: false,
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState): TenantEntity => state.tenant,
    }),
    ...mapGetters('tenant', {
      isDebugAvailable: 'isDebugAvailable',
    }),
    showBanner() {
      return !this.tenantAttributes.hideCompletionBanner;
    },
  },
  watch: {
    tenant() {
      this.updateFromTenant();
    },
  },
  methods: {
    toggleBanner() {
      this.tenantAttributes.hideCompletionBanner = !this.tenantAttributes.hideCompletionBanner;
    },

    selectImage() {
      this.$refs.logoFileInput.$refs.input.click();
    },

    async updateFromTenant() {
      this.tenantImage.src = this.tenant?.attributes.theme.image;
      this.tenantAttributes = {
        theme: {
          title: this.tenant?.attributes.theme.title,
          tagLine: this.tenant?.attributes.theme.tagLine,
          colors: {
            primary: this.tenant?.attributes.theme.colors.primary,
          },
        },
        hideCompletionBanner: this.tenant?.attributes.hideCompletionBanner,
      };
      if (this.tenant?.ambassadorId) {
        this.ambassador = await this.$store.dispatch(new LoadUserDetails(this.tenant.ambassadorId, true));
      }
    },

    async save() {
      try {
        this.loading = true;
        if (this.tenantImage.src !== this.tenant.attributes.theme.image) {
          await this.$store.dispatch(new UpdateTenantImage(this.tenantImage.blob));
        }
        if (this.ambassador != null && this.ambassador.id !== this.tenant?.ambassadorId) {
          await this.$store.dispatch(new UpdateTenantAmbassador(this.ambassador));
        }
        await this.$store.dispatch(new UpdateTenantAttributes(this.tenantAttributes));
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.updateFromTenant();
  },
});
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';

.tab-actions {
  justify-content: end;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    justify-content: center;
  }
}

.m-color-picker-dialog {
  width: unset;
}
</style>
