<template>
  <div class="d-flex align-center flex-column fill-height">
    <v-img :src="require('@/assets/sparkles.png')" class="d-inline-block" contain max-height="128" max-width="128" />
    <h2 class="text-center mb-2">{{ $t('dialogs.invitation.welcome.title', { ritual: ritualTitle }) }}</h2>
    <div class="text-center text--text my-4 font-size-18" v-html="$marked(content)" />
    <div class="justify-center mt-auto flex-grow-0">
      <v-btn v-if="false" class="mr-4" color="button" depressed outlined x-large @click="openProfile">
        {{ $t('dialogs.invitation.welcome.goToProfile') }}
      </v-btn>
      <v-btn color="button" depressed x-large @click="$emit('onNext')">
        {{ $t('dialogs.invitation.welcome.proceed') }}
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IInvite, ITenant } from '@mentessa/types';
import { RouteName } from '@/router/types';

export default Vue.extend({
  name: 'm-invitation-welcome-form',
  props: {
    tenant: Object as PropType<ITenant>,
    invite: Object as PropType<IInvite>,
  },
  computed: {
    tenantTitle() {
      return this.tenant?.attributes?.theme?.title ?? '';
    },
    ritualTitle() {
      return this.invite?.ritual?.attributes.title ?? '';
    },
    content(): string {
      return this.$t('dialogs.invitation.welcome.content', { tenant: this.tenantTitle, ritual: this.ritualTitle });
    },
  },
  methods: {
    openProfile() {
      this.$router.push({ name: RouteName.Profile });
    },
  },
});
</script>
