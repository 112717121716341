var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"fill-width fill-height d-flex flex-column",attrs:{"id":"wizard-container"}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-progress-linear',{staticClass:"m-progress-top",attrs:{"value":_vm.progress}}):_vm._e(),_c('v-window',{staticClass:"flex-grow-1",attrs:{"id":"qweqwe","vertical":""},model:{value:(_vm.stepIndex),callback:function ($$v) {_vm.stepIndex=$$v},expression:"stepIndex"}},_vm._l((_vm.steps),function(step,index){return _c('v-window-item',{key:`step-${index}`,staticClass:"fill-height"},[_c('validation-observer',{staticClass:"fill-height flex-grow-1 d-flex flex-row",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"m-progress-left d-flex flex-column gap-4 ml-4 mr-2 align-center"},[_c('div',{staticClass:"m-stepper-item m-stepper-item__active",class:{
              'm-stepper-item__big': _vm.isFirstStep,
              'm-stepper-item__top': !_vm.isFirstStep,
              'm-stepper-item__tall': !_vm.isFirstStep,
              'mt-4': _vm.isFirstStep,
            }}),_c('div',{staticClass:"m-stepper-item m-stepper-item__active flex-grow-1"}),_c('div',{staticClass:"m-stepper-item",class:{
              'm-stepper-item__big': _vm.isLastStep,
              'm-stepper-item__bottom': !_vm.isLastStep,
              'm-stepper-item__tall': !_vm.isLastStep,
              'm-stepper-item__active': _vm.isLastStep,
              'mb-9': _vm.isLastStep,
            }})]):_vm._e(),_c('v-card',{staticClass:"no-border d-flex flex-column fill-height overflow-y-auto flex-grow-1 pa-4 no-backgound",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pb-0 mt-auto"},[_c('h3',[_vm._v(_vm._s(step.title))])]),_c('v-card-text',[_vm._v(" "+_vm._s(step.description)+" ")]),_c(step.component,_vm._b({tag:"component",attrs:{"value":_vm.rawModel ? _vm.value : _vm.value[step.name]},on:{"input":function($event){return _vm.mutate(step.name, $event)}}},'component',step.props,false)),_c('v-card-actions',{staticClass:"justify-center mt-auto"},[(_vm.isBackAvailable || !_vm.centerActions)?_c('v-col',{attrs:{"cols":"6"}},[(_vm.isBackAvailable)?_c('v-btn',{attrs:{"disabled":_vm.loading,"color":"button","depressed":"","outlined":"","width":"100%"},on:{"click":_vm.prevStep}},[_vm._v(_vm._s(_vm.$t('actions.prev'))+" ")]):_vm._e()],1):_vm._e(),_c('v-col',{attrs:{"cols":"6"}},[(_vm.isNextAvailable)?_c('v-btn',{attrs:{"disabled":invalid || _vm.loading,"color":"button","depressed":"","width":"100%"},on:{"click":_vm.nextStep}},[_vm._v(_vm._s(_vm.$t('actions.next'))+" ")]):_c('v-btn',{attrs:{"disabled":invalid,"color":"button","depressed":"","width":"100%"},on:{"click":_vm.complete}},[_vm._v(_vm._s(_vm.$t('actions.done'))+" ")])],1)],1)],1)]}}],null,true)})],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }