<template>
  <div class="m-settings-ritual-card-empty">
    <div>
      <p class="font-weight-bold text-h6 mb-2">{{ $t('settings.rituals.empty.title') }}</p>
      <p class="text-h7 text--text text--lighten-4 mb-4">{{ $t('settings.rituals.empty.description') }}</p>
    </div>
    <v-btn large depressed outlined color="button" :href="$t('settings.rituals.empty.requestNewRitualLink')">
      {{ $t('settings.rituals.empty.cta') }}
    </v-btn>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'm-empty-ritual',
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.m-settings-ritual-card-empty {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 24px;
  background-color: var(--v-white-base);
  border: 1px dashed var(--v-beige-base);
  border-radius: 16px;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    flex-direction: column;
  }
}
</style>
