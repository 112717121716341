import { IRoom, IUser, MessagesPage } from '@mentessa/types';
import { Action } from '@/store/types';

export const moduleName = 'messages';

export interface MessagesState {
  rooms: Array<IRoom>;
  messages: MessagesPage;
  lastFetchTime?: Date;
  fetchTimer?: number;
  unreadMessageCount: number;
  activeRoom?: IRoom;
}

export enum MessagesActions {
  LoadRooms = '[Messages] Load Rooms',
  FindDirectRoom = '[Messages] Load Room By User',
  LoadMessages = '[Messages] Load Messages',
  SendMessage = '[Messages] Send Message',
  SendDirectMessage = '[Messages] Send Direct Message',
  StartFetchMessages = '[Messages] Start Fetch Message',
  StopFetchMessages = '[Messages] Stop Fetch Message',
  CheckNewMessages = '[Messages] Check New Messages',
  ReadWholeRoom = '[Messages] Read Whole Room',
  SelectRoom = '[Messages] Select Room',
}

export enum MessagesMutations {
  SetRooms = '[Messages] Set Rooms',
  AppendRoom = '[Messages] Append Room',
  SetMessages = '[Messages] Set Messages',
  AppendMessage = '[Messages] Append Message',
  SetTimer = '[Messages] Set Timer',
  SetLastFetchTime = '[Messages] Set Last Fetch Time',
  SetUnreadMessageCount = '[Messages] Set Unread Message Count',
  SetActiveRoom = '[Messages] Set Active Room',
  ReplaceRoom = '[Messages] Replace Room',
}

export class LoadRooms implements Action {
  type = `${moduleName}/${MessagesActions.LoadRooms}`;

  constructor(private readonly page: number = 1, private readonly user?: IUser) {}
}

export class FindDirectRoom implements Action {
  type = `${moduleName}/${MessagesActions.FindDirectRoom}`;

  constructor(private readonly userId?: string) {}
}

export class LoadMessages implements Action {
  type = `${moduleName}/${MessagesActions.LoadMessages}`;

  constructor(private readonly room: IRoom, private readonly page: number = 1, private readonly user?: IUser) {}
}

export class SendMessage implements Action {
  type = `${moduleName}/${MessagesActions.SendMessage}`;

  constructor(private readonly room: IRoom, private readonly text: string, private readonly user?: IUser) {}
}

export class SendDirectMessage implements Action {
  type = `${moduleName}/${MessagesActions.SendDirectMessage}`;

  constructor(private toUserId: number, private text: string) {
    this.toUserId = toUserId;
    this.text = text;
  }
}

export class StartFetchMessages implements Action {
  type = `${moduleName}/${MessagesActions.StartFetchMessages}`;

  constructor(private interval: number = 5 * 60 * 1000) {
    this.interval = interval;
  }
}

export class StopFetchMessages implements Action {
  type = `${moduleName}/${MessagesActions.StopFetchMessages}`;
}

export class CheckNewMessages implements Action {
  type = `${moduleName}/${MessagesActions.CheckNewMessages}`;
}

export class SelectRoom implements Action {
  type = `${moduleName}/${MessagesActions.SelectRoom}`;

  constructor(private readonly room: IRoom, private readonly user?: IUser) {}
}

export class ReadWholeRoom implements Action {
  type = `${moduleName}/${MessagesActions.ReadWholeRoom}`;

  constructor(private readonly room: IRoom, private readonly user?: IUser) {}
}
