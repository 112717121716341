import { MutationTree } from 'vuex';
import { InvitesMutations, InvitesState } from '@/store/invites/types';
import { IInvite, InvitesPage } from '@mentessa/types';

export const mutations: MutationTree<InvitesState> = {
  [InvitesMutations.SetInvites](state: InvitesState, invites: InvitesPage) {
    state.invites = invites;
  },
  [InvitesMutations.SetCurrentInvite](state: InvitesState, invite: IInvite) {
    state.current = invite;
  },
  [InvitesMutations.AppendInvite](state: InvitesState, invite: IInvite) {
    state.invites.items.push(invite);
  },
};
