import { MutationTree } from 'vuex';
import { RitualsMutations, RitualsState } from '@/store/rituals/types';
import { IRitual, IRitualUser, RitualReference, RitualScheduleType, RitualsPage, UserReference } from '@mentessa/types';

export const mutations: MutationTree<RitualsState> = {
  [RitualsMutations.SetRituals](state: RitualsState, page: RitualsPage) {
    state.rituals = page;
  },
  [RitualsMutations.UpdateRitual](state: RitualsState, ritual: IRitual) {
    const existingRitual = state.rituals.items.find((r) => r.id === ritual.id);
    if (existingRitual) {
      existingRitual.enabled = ritual.enabled;
      if (ritual.attributes.schedule) {
        if (!existingRitual.attributes.schedule?.options) {
          existingRitual.attributes.schedule = { options: {}, type: ritual.attributes.schedule.type };
        }
        existingRitual.attributes.schedule.type = ritual.attributes.schedule.type;
        const options = existingRitual.attributes.schedule.options;
        if (existingRitual.attributes.schedule.type === RitualScheduleType.Periodic) {
          options.periodic.weekday = ritual.attributes.schedule.options.periodic.weekday;
          options.periodic.hour = ritual.attributes.schedule.options.periodic.hour;
          options.periodic.frequency = ritual.attributes.schedule.options.periodic.frequency;
        } else {
          options.once.date = ritual.attributes.schedule.options.once.date;
        }
      }

      existingRitual.attributes.acceptanceRule = ritual.attributes.acceptanceRule;
      existingRitual.attributes.monthlyFeedback = ritual.attributes.monthlyFeedback;
      existingRitual.attributes.auto = ritual.attributes.auto;
      existingRitual.attributes.inviteExistingUsers = ritual.attributes.inviteExistingUsers;
      existingRitual.attributes.title = ritual.attributes.title;
      existingRitual.attributes.description = ritual.attributes.description;
      existingRitual.attributes.icon = ritual.attributes.icon;
      existingRitual.attributes.assetIcon = ritual.attributes.assetIcon;
    }
  },

  [RitualsMutations.AppendRitual](state: RitualsState, ritual: IRitual) {
    state.rituals.items = [ritual, ...state.rituals.items];
  },

  [RitualsMutations.SetRitualIcon](state: RitualsState, options: { url: string; ritual: IRitual }) {
    const { url, ritual } = options;
    const existingRitual = state.rituals.items.find((r) => r.id === ritual.id);
    if (existingRitual) {
      existingRitual.attributes.icon = url;
    }
  },
  [RitualsMutations.AddMember](state: RitualsState, options: { ritualRef: RitualReference; member: IRitualUser }) {
    const existingRitual = state.rituals.items.find((r) => r.id === options.ritualRef.id);
    if (existingRitual) {
      if (existingRitual.members == null) {
        existingRitual.members = [];
      }
      existingRitual.members.push(options.member);
    }
  },
  [RitualsMutations.RemoveMember](
    state: RitualsState,
    options: { ritualRef: RitualReference; userRef: UserReference },
  ) {
    const existingRitual = state.rituals.items.find((r) => r.id === options.ritualRef.id);
    if (existingRitual) {
      const memberIndex = existingRitual.members?.findIndex((member) => member.userId === options.userRef.id);
      if (memberIndex !== -1) {
        existingRitual.members.splice(memberIndex, 1);
      }
    }
  },
  [RitualsMutations.SetCurrentRitual](state: RitualsState, ritual: IRitual) {
    state.current = ritual;
  },
};
