<template>
  <validation-provider v-slot="v" :name="$t('onboarding.tag.communityTagLineLabel')">
    <v-text-field
      v-model="localTagline"
      :error-messages="v.errors"
      :placeholder="$t('onboarding.tag.communityTagLinePlaceholder')"
      class="m-text-field"
      outlined
    />
  </validation-provider>
</template>

<script lang="ts">
import Vue from 'vue';
import { extend, setInteractionMode, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

setInteractionMode('aggressive');
extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});

export default Vue.extend({
  name: 'm-form-tagline-step',
  components: {
    ValidationProvider,
  },
  props: {
    value: String,
  },
  computed: {
    localTagline: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    submitStep(): void {
      this.$emit('onSubmit');
    },
  },
});
</script>
