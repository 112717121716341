<template>
  <div>
    <p class="m-settings-description">{{ $t('settings.invitations.description') }}</p>
    <div class="m-settings-invitations mt-8" sentry-tag="settings-invitations">
      <div class="d-flex flex-row-reverse pa-6">
        <v-btn
          color="button"
          depressed
          sentry-tag="settings-invitations.button.close"
          x-large
          @click="newInviteDialog = true"
        >
          {{ $t('settings.invitations.add') }}
        </v-btn>
      </div>

      <template v-if="invites.items.length > 0">
        <div>
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-left">
                  <div class="d-flex align-center">
                    <span>{{ $t('settings.invitations.table.name') }}</span>
                  </div>
                </th>
                <th class="text-center">
                  <span>{{ $t('settings.invitations.table.valid') }}</span>
                </th>
                <th class="text-center">
                  <span>{{ $t('settings.invitations.table.limit') }}</span>
                </th>

                <th class="text-center">
                  <span>{{ $t('settings.invitations.table.count') }}</span>
                </th>
                <th class="text-center">
                  <span>{{ $t('settings.invitations.table.actions') }}</span>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(invite, index) in invites.items" :key="`settings-invites-${index}`">
                <td>
                  <div class="text-center">
                    <div>{{ invite.attributes.name }}</div>
                  </div>
                </td>
                <td class="text-center">
                  <v-chip v-if="invite.isValid" color="success" small>
                    {{ $t('settings.invitations.state.active') }}
                  </v-chip>
                  <v-chip v-else small>{{ $t('settings.invitations.state.expired') }}</v-chip>
                </td>
                <td class="text-center">{{ invite.limit }}</td>
                <td class="text-center">{{ invite.useCount }}</td>
                <td>
                  <a
                    v-if="!isRitualInvite(invite)"
                    sentry-tag="settings-invitations.button.copyToClipboard"
                    @click="copyToClipboard(invite)"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </a>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>

        <div class="d-flex justify-center justify-sm-end pt-6 pb-6 pr-6">
          <v-pagination
            v-model="page"
            :disabled="loading"
            :length="invites.meta.totalPages"
            class="m-settings-invitations__table-pagination"
            sentry-tag="settings-invitations.pagination"
          />
        </div>
      </template>
      <m-empty-state
        v-else
        :cta="$t('settings.invitations.empty.cta')"
        :description="$t('settings.invitations.empty.description')"
        :image="require('@/assets/empty/invite.svg')"
        :title="$t('settings.invitations.empty.title')"
        @onButtonClick="newInviteDialog = true"
      />
      <m-new-invitation-link-dialog v-model="newInviteDialog" />
    </div>
  </div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import Vue from 'vue';
import { DeleteInvite, DisableInvite, InvitesState, LoadInvites } from '@/store/invites';
import MNewInvitationLinkDialog from '@/components/Dialogs/MNewInvitaionLinkDialog';
import { IInvite, InviteKind, InvitesPage } from '@mentessa/types';
import { copy } from '@/utils/clipboard';
import { ShowNotification } from '@/store/notifications';
import i18n from '@/plugins/i18n';
import MEmptyState from '@/components/MEmptyState';

export default Vue.extend({
  name: 'm-settings-invitations',
  components: { MEmptyState, MNewInvitationLinkDialog },
  props: {},
  data: () => ({
    page: 1,
    loading: false,
    newInviteDialog: false,
  }),
  computed: {
    ...mapState<InvitesState>('invites', {
      invites: (state: InvitesState): InvitesPage => state.invites,
    }),
  },
  watch: {
    async page() {
      await this.loadPlatformInvites();
    },
  },
  methods: {
    async deleteInvite(invite: IInvite) {
      await this.$store.dispatch(new DeleteInvite(invite));
    },
    async disableInvite(invite: IInvite) {
      await this.$store.dispatch(new DisableInvite(invite));
    },
    async copyToClipboard(invite: IInvite) {
      await copy(this.inviteLink(invite));
      await this.$store.dispatch(new ShowNotification(i18n.t('notifications.ok').toString()));
    },
    inviteLink(invite: IInvite) {
      return this.isRitualInvite(invite)
        ? `${window.origin}/invites/${invite.guid}`
        : `${window.origin}/invite?key=${invite.guid}`;
    },
    isRitualInvite(invite: IInvite) {
      return invite.ritual;
    },
    async loadPlatformInvites() {
      try {
        this.loading = true;
        await this.$store.dispatch(new LoadInvites(this.page, InviteKind.Platform));
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    await this.loadPlatformInvites();
  },
});
</script>

<style lang="scss" scoped>
.m-settings-invitations {
  border: 1px solid var(--v-beige-base);
  border-radius: 16px;
  background-color: var(--v-white-base);

  &__table-pagination:deep(.v-pagination__navigation, .v-pagination__item) {
    box-shadow: none;
  }
}
</style>
