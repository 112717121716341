import { Action } from '@/store/types';
import { CommunityRulesReference, ICommunityRules, ITenant } from '@mentessa/types';

export const moduleName = 'communityRules';

export interface CommunityRulesState {
  communityRules: Array<ICommunityRules>;
  enabled: boolean;
  required: boolean;
}

export enum CommunityRulesActions {
  LoadCommunityRules = '[CommunityRules] Load Community Rules',
  UpdateCommunityRules = '[CommunityRules] Update Community Rules',
  DeleteCommunityRules = '[CommunityRules] Delete Community Rules',
  CreateCommunityRules = '[CommunityRules] Create Community Rules',
  ChangeCommunityRulesEnabled = '[CommunityRules] Change Community Rules Enabled',
  ChangeCommunityRulesRequired = '[CommunityRules] Change Community Rules Required',
}

export enum CommunityRulesMutations {
  SetCommunityRules = '[CommunityRules] Set Community Rules',
  AppendCommunityRules = '[CommunityRules] Append Community Rules',
  RemoveCommunityRules = '[CommunityRules] Remove Community Rules',
  UpdateCommunityRules = '[CommunityRules] Update Community Rules',
  SetCommunityRulesEnabled = '[CommunityRules] Set Community Rules Enabled',
  SetCommunityRulesRequired = '[CommunityRules] Set Community Rules Required',
}

export class LoadCommunityRules implements Action {
  type = `${moduleName}/${CommunityRulesActions.LoadCommunityRules}`;

  constructor(private readonly tenant?: ITenant) {}
}

export class CreateCommunityRules implements Action {
  type = `${moduleName}/${CommunityRulesActions.CreateCommunityRules}`;

  constructor(private readonly communityRules: Omit<ICommunityRules, 'id'>, private readonly tenant?: ITenant) {}
}

export class UpdateCommunityRules implements Action {
  type = `${moduleName}/${CommunityRulesActions.UpdateCommunityRules}`;

  constructor(
    private readonly communityRulesRef: CommunityRulesReference,
    private readonly update: Partial<ICommunityRules>,
    private readonly tenant?: ITenant,
  ) {}
}

export class DeleteCommunityRules implements Action {
  type = `${moduleName}/${CommunityRulesActions.DeleteCommunityRules}`;

  constructor(private readonly communityRules: ICommunityRules, private readonly tenant?: ITenant) {}
}

export class ChangeCommunityRulesEnabled implements Action {
  type = `${moduleName}/${CommunityRulesActions.ChangeCommunityRulesEnabled}`;

  constructor(private readonly status: boolean, private readonly tenant?: ITenant) {}
}

export class ChangeCommunityRulesRequired implements Action {
  type = `${moduleName}/${CommunityRulesActions.ChangeCommunityRulesRequired}`;

  constructor(private readonly status: boolean, private readonly tenant?: ITenant) {}
}
