<template>
  <m-integration-card
    :integration="notionIntegration"
    :loading="loading"
    lg
    sales
    tool="notion"
    @onConnect="connect"
    @onDisconnect="disconnect"
  >
  </m-integration-card>
</template>

<script lang="ts">
import Vue from 'vue';
import { TenantState } from '@/store/tenant';
import { IIntegration, IntegrationType } from '@mentessa/types';
import MIntegrationCard from '@/components/integrations/MIntegrationCard';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'm-tenant-integration-notion',
  components: {
    MIntegrationCard,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      integrations: (state: TenantState) => {
        return state.tenant.integrations;
      },
    }),
    notionIntegration() {
      return this.integrations.find((i: IIntegration) => i.type === IntegrationType.Slack);
    },
  },
  methods: {
    connect() {
      // ToDo: TBD
    },
    async disconnect() {
      // ToDo: TBD
    },
  },
});
</script>
