<template>
  <div>
    <v-btn class="ma-4" sentry-tag="settings-debug.button.removeDemoUsers" @click="hubspotCreateContact">
      Test contact
    </v-btn>
  </div>
</template>

<script lang="ts">
import { HubSpotTestNewContact } from '@/store/utils';
import Vue from 'vue';

export default Vue.extend({
  name: 'm-settings-debug-hubspot',
  methods: {
    async hubspotCreateContact() {
      await this.$store.dispatch(new HubSpotTestNewContact());
    },
  },
});
</script>
