import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { NotificationsActions, NotificationsMutations, NotificationsState } from './types';

export const actions: ActionTree<NotificationsState, RootState> = {
  async [NotificationsActions.ShowNotification]({ commit }, { message, variant, timeout }) {
    commit(NotificationsMutations.AddNotification, { message, variant, timeout });
  },
  async [NotificationsActions.HideNotification]({ commit }, { index }) {
    commit(NotificationsMutations.HideNotification, { index });
  },
  async [NotificationsActions.OpenNotificationDialog]({ commit }, { message, variant, buttonText, isOpened }) {
    commit(NotificationsMutations.OpenNotificationDialog, { message, variant, buttonText, isOpened });
  },
  async [NotificationsActions.CloseNotificationDialog]({ commit }, { message, variant, buttonText, isOpened }) {
    commit(NotificationsMutations.CloseNotificationDialog, { message, variant, buttonText, isOpened });
  },
  async [NotificationsActions.ShowPersistentNotification]({ commit }, { title, text, link, isOpened }) {
    commit(NotificationsMutations.ShowPersistentNotification, { title, text, link, isOpened });
  },
  async [NotificationsActions.HidePersistentNotification]({ commit }, { isOpened }) {
    commit(NotificationsMutations.HidePersistentNotification, { isOpened });
  },
  // eslint-disable-next-line no-empty-pattern
  async [NotificationsActions.ShowDesktopNotification]({}, { title, body, icon }) {
    return new Notification(title, { body, icon });
  },
  // eslint-disable-next-line no-empty-pattern
  async [NotificationsActions.CloseDesktopNotification]({}, { notification }) {
    notification.close();
  },
};
