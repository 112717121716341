<template>
  <v-col>
    <v-row>
      <p>Import users:</p>
    </v-row>
    <v-row class="align-center">
      <v-file-input
        v-model="file"
        accept="text/csv"
        dense
        hide-details
        outlined
        placeholder="Save xlsx as 'csv (UTF-8)' and put it here:"
      />
      <v-btn :disabled="!file" class="ml-8" @click="processImportFile">Process</v-btn>
    </v-row>
    <v-row v-if="users.length > 0">
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-center"><span>#</span></th>
            <th class="text-center"><span>Preview</span></th>
            <th class="text-center"><span>FirstName</span></th>
            <th class="text-center"><span>LastName</span></th>
            <th class="text-center"><span>Email</span></th>
            <th class="text-center"><span>Roles</span></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(user, index) in users" :key="`import-user-${index}`">
            <td class="text-center">{{ index }}</td>
            <td>
              <m-user-preview :user="user" />
            </td>
            <td>{{ user.identity.attributes.firstName }}</td>
            <td>{{ user.identity.attributes.lastName }}</td>
            <td>{{ user.identity.attributes.email }}</td>
            <td>{{ user.attributes.roles }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-row>
    <v-row v-if="users.length > 0">
      <v-checkbox v-model="sure" label="Yes, I want to send emails for all of those users" />
      <v-btn :disabled="!sure" class="ml-auto" color="button" depressed @click="doImport">Do Import</v-btn>
    </v-row>
  </v-col>
</template>

<script lang="ts">
import Vue from 'vue';
import { IUser } from '@mentessa/types';
import MUserPreview from '@/components/MUserPreview/UserPreviewPure.vue';
import { ImportMembers } from '@/store/tenant';

function readFileAsync(file): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsText(file);
  });
}

interface UserField {
  name: string;
  title: string;
}

export default Vue.extend({
  name: 'm-import-users',
  components: { MUserPreview },
  data: () => ({
    file: undefined,
    sure: false,
    headers: [] as Array<UserField>,
    users: [] as Array<Partial<IUser>>,
    content: undefined,
  }),
  watch: {
    async usersCSV(file) {
      try {
        const content = await readFileAsync(file);
        this.content = content.split('\n');
      } catch (e) {
        console.log(e);
      }
    },
  },
  methods: {
    async processImportFile() {
      this.sure = false;
      const content = await readFileAsync(this.file);
      const lines = content.split('\n');
      lines.splice(0, 1);

      for (const line of lines) {
        const splittedLine = line.split(';');
        if (splittedLine.length < 7 || !splittedLine[0].length || !splittedLine[1].length) {
          console.log('Invalid line', line);
          continue;
        }
        const firstName = splittedLine[0].trim();
        const lastName = splittedLine[1].trim();
        const email = splittedLine[2].trim().toLowerCase();
        const role = splittedLine[6].trim();

        this.users.push({
          identity: {
            attributes: {
              firstName,
              lastName,
              email,
            },
          },
          attributes: {
            roles: [role],
          },
        });
      }
    },
    async doImport() {
      await this.$store.dispatch(new ImportMembers(this.users, true));
    },
  },
});
</script>
