<template>
  <div>
    <m-empty-state
      :image="require('@/assets/empty/calendar.svg')"
      :title="$t('emptyCommunity.title')"
      :description="$t('emptyCommunity.description')"
      :cta="$t('emptyCommunity.cta')"
      @onButtonClick="isAddMembersDialogOpened = true"
    />
    <m-add-members-dialog v-model="isAddMembersDialogOpened" :allowInviteLink="true" :allowRoleSelection="false" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import MEmptyState from '@/components/MEmptyState';
import MAddMembersDialog from '@/components/Dialogs/MAddMembersDialog';

export default Vue.extend({
  name: 'm-empty-community',
  components: { MEmptyState, MAddMembersDialog },
  data: () => ({
    isAddMembersDialogOpened: false,
  }),
});
</script>

<style lang="scss" scoped></style>
