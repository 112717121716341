import { MutationTree } from 'vuex';
import { DasboardsMutations, DashboardsState } from './types';
import { IDashboardSettings } from '@mentessa/types';
import { StoreDataState } from '@/store/types';

export const mutations: MutationTree<DashboardsState> = {
  [DasboardsMutations.SetDasboardState](state: DashboardsState, dataState: StoreDataState) {
    state.dashboards.state = dataState;
  },

  [DasboardsMutations.SetDasboards](state: DashboardsState, dashboards: Array<IDashboardSettings>) {
    state.dashboards.state = StoreDataState.Loaded;
    state.dashboards.data = dashboards;
  },
};
