<template>
  <div style="position: relative">
    <slot name="prepend" />
    <div v-if="state.hits.length" :class="classes">
      <slot name="prepend-hit" />
      <div v-for="hit in state.hits" :key="hit.objectID">
        <slot :item="hit" name="item" />
      </div>
      <slot name="append-hit" />
      <div v-observe-visibility="visibilityChanged" class="sentinel" />
    </div>
    <m-loader v-else-if="loading" :is-loading="true" />
    <div v-else>
      <div class="d-flex flex-column align-center text-center">
        <v-img class="mb-10" contain height="270" src="@/assets/no-results-emoji.svg" width="270" />
        <h3 class="mb-4">{{ $t('filters.noMatches.heading') }}</h3>
        <h4 class="text--text text--lighten-5 font-weight-regular" v-html="$t('filters.noMatches.text')" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { createWidgetMixin } from 'vue-instantsearch';
import { connectInfiniteHits } from 'instantsearch.js/es/connectors';
import MLoader from '@/components/MLoader.vue';

export default Vue.extend({
  name: 'm-ais-stream-container',
  components: { MLoader },
  props: {
    grid: { type: Boolean, default: false },
  },
  data: () => ({
    // Mixins and typescript are not compatible with each other in vue 2.
    // Type declarations from instantsearch.js/es/types not works too.
    // So there is just a mock for real state what will be rewritten by createWidgetMixin
    state: {
      showMore: Function,
      isLastPage: false,
      hits: [],
    },
    loading: true,
  }),
  mixins: [createWidgetMixin({ connector: connectInfiniteHits })],
  computed: {
    classes() {
      return {
        'stream-container-grid': this.grid,
        'stream-container-list': !this.grid,
        'd-flex': true,
        'flex-wrap': true,
      };
    },
  },
  watch: {
    state() {
      if (this.loading) {
        this.loading = false;
      }
    },
  },
  methods: {
    visibilityChanged(isVisible) {
      if (isVisible && !this.state.isLastPage) {
        this.state.showMore();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.sentinel {
  list-style-type: none;
  width: 100%;
}

.stream-container-list {
  & > div {
    width: 100%;
  }
}

.stream-container-grid {
  gap: 32px;
  padding-top: 2px;
  justify-content: center;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    overflow: hidden;
  }
}
</style>
