import { Action } from '@/store/types';
import { INps, NpsReference, TenantReference, UserReference } from '@mentessa/types';

export const moduleName = 'nps';

export interface NpsState {
  nps: INps;
}

export enum NpsActions {
  LoadNps = '[NPS] Load Nps',
  LoadNpsForTenant = '[NPS] Load Nps For Tenant',
  SendNpsResult = '[NPS] Send Nps Result',
  DisableNpsForUser = '[NPS] Disable Nps For User',
  UpdateNps = '[NPS] Update Nps',
  CreateNps = '[NPS] Create Nps',
}

export enum NpsMutations {
  SetNps = '[NPS] Set Nps',
}

export class LoadNps implements Action {
  type = `${moduleName}/${NpsActions.LoadNps}`;

  constructor(private readonly userRef?: UserReference) {}
}

export class LoadNpsForTenant implements Action {
  type = `${moduleName}/${NpsActions.LoadNpsForTenant}`;

  constructor(private readonly tenantRef?: TenantReference) {}
}

export class SendNpsResult implements Action {
  type = `${moduleName}/${NpsActions.SendNpsResult}`;

  constructor(
    private readonly npsRef: NpsReference,
    private readonly score: number,
    private readonly userRef?: UserReference,
  ) {}
}

export class DisableNpsForUser implements Action {
  type = `${moduleName}/${NpsActions.DisableNpsForUser}`;

  constructor(private readonly npsRef: NpsReference, private readonly userRef?: UserReference) {}
}

export class UpdateNps implements Action {
  type = `${moduleName}/${NpsActions.UpdateNps}`;

  constructor(
    private readonly npsRef: NpsReference,
    private readonly nps: Partial<INps>,
    private readonly tenantRef?: TenantReference,
  ) {}
}

export class CreateNps implements Action {
  type = `${moduleName}/${NpsActions.CreateNps}`;

  constructor(private readonly nps: Partial<INps>, private readonly tenantRef?: TenantReference) {}
}
