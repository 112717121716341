<template>
  <validation-provider v-slot="{ errors }" :debounce="validationDebounce" :name="label" :rules="validationRules" slim>
    <v-textarea
      :value="value"
      :error-messages="errors"
      :label="labelWithRequired"
      v-bind="{ ...$props, ...$attrs }"
      v-on="$listeners"
      sentry-tag="textarea"
    />
  </validation-provider>
</template>

<script lang="ts">
import { email, max, min, required } from 'vee-validate/dist/rules';
import { extend, setInteractionMode, ValidationProvider } from 'vee-validate';
import Vue from 'vue';

export default Vue.extend({
  name: 'm-text-area',
  components: {
    ValidationProvider,
  },
  props: {
    value: String,
    label: [String, Object /*as PropType<TranslateResult>*/],
    outlined: { type: Boolean, default: true },
    hideDetails: { type: String, default: 'auto' },
    validationRules: String,
    validationDebounce: Number,
    validationMode: { type: String, default: 'aggressive' },
    requiredSuffix: { type: String, default: ' *' },
  },
  computed: {
    isRequired() {
      return this.validationRules?.includes('required') ?? false;
    },
    labelWithRequired() {
      if (!this.label) return undefined;
      return this.isRequired ? `${this.label ?? ''}${this.requiredSuffix}` : this.label;
    },
  },

  watch: {
    validationMode(mode: string) {
      setInteractionMode(mode);
    },
  },
  created() {
    setInteractionMode(this.validationMode);

    extend('required', {
      ...required,
      message: this.$t('validation.required'),
    } as never);

    extend('max', {
      ...max,
      message: this.$t('validation.max'),
    } as never);

    extend('min', {
      ...min,
      message: this.$t('validation.min'),
    } as never);

    extend('email', {
      ...email,
      message: this.$t('validation.email'),
    } as never);

    extend('emails', {
      message: this.$t('validation.emails'),
      validate: (records: string) => {
        return records
          .split(/[,\n]/)
          .filter((email) => email.trim())
          .every((record) => email.validate(record.trim()));
      },
    } as never);
  },
});
</script>
