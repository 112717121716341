<template>
  <m-panel>
    <v-chip>
      <m-ritual-preview v-if="ritual" :ritual="ritual" max-width="240px" />
      <span v-else>{{ $t(`settings.matching.source.${source}`) }}</span>
    </v-chip>

    <m-user-info-card :value="matchedUser" />

    <div class="d-flex flex-row flex-wrap gap-2 mt-4 justify-center">
      <template v-if="actionRequired">
        <v-btn class="" color="button" outlined width="120" @click="declineMatch"
          >{{ $t('userProfileModal.pending_match.decline') }}
        </v-btn>
        <v-btn color="button" width="120" @click="acceptMatch">{{ $t('userProfileModal.pending_match.accept') }}</v-btn>
      </template>
      <v-btn v-else-if="journal" :to="journalRoute" color="button" width="120">{{ $t('cards.journal.open') }}</v-btn>
      <v-chip v-else-if="waiting" color="beige">{{ $t('cards.match.waiting') }}</v-chip>
      <v-chip v-else>{{ status }}</v-chip>
    </div>
    <m-match-action-dialog v-model="showMatchActionDialog" :action="matchAction" :match-id="matchId" />
    <m-ritual-match-action-dialog
      v-model="showRitualMatchActionDialog"
      :action="matchAction"
      :match-id="matchId"
      :ritual-id="matchRitualId"
    />
  </m-panel>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IMatchUser, MatchStatus } from '@mentessa/types';
import MPanel from '@/components/MPanel';
import MRitualPreview from '@/components/MRitualPreview';
import { RouteName } from '@/router/types';
import MUserInfoCard from '@/components/Cards/MUserInfoCard/MUserInfoCard.vue';
import MRitualMatchActionDialog from '../../Dialogs/MRitualMatchActionDialog';
import MMatchActionDialog from '../../Dialogs/MMatchActionDialog';

export default Vue.extend({
  name: 'm-match-card',
  components: { MMatchActionDialog, MRitualMatchActionDialog, MUserInfoCard, MRitualPreview, MPanel },
  props: {
    value: Object as PropType<IMatchUser>,
  },
  data: () => ({
    showMatchActionDialog: false,
    showRitualMatchActionDialog: false,
    matchAction: undefined,
  }),
  computed: {
    source() {
      return this.value?.match?.source;
    },
    status() {
      return this.value?.match?.status;
    },
    matchedUser() {
      return this.value?.match?.matchUsers?.[0]?.user;
    },
    ritual() {
      return this.value?.match?.ritual;
    },
    actionRequired() {
      return this.value?.match?.status === MatchStatus.Pending && this.value?.accepted == null;
    },
    waiting() {
      return this.value?.match?.status === MatchStatus.Pending && this.value?.accepted != null;
    },
    journal() {
      return this.value?.match?.mentoringSessions?.[0];
    },
    journalRoute() {
      return this.journal ? { name: RouteName.MentoringSession, params: { sessionId: this.journal.id } } : undefined;
    },
    matchId() {
      return this.value?.match?.id;
    },
    matchRitualId() {
      return this.ritual?.id;
    },
  },
  watch: {
    showMatchActionDialog(value: boolean) {
      if (!value) {
        this.$emit('onStateChanged');
      }
    },
    showRitualMatchActionDialog(value: boolean) {
      if (!value) {
        this.$emit('onStateChanged');
      }
    },
  },
  methods: {
    async acceptMatch() {
      this.matchAction = 'accept';
      if (this.ritual) {
        this.showRitualMatchActionDialog = true;
      } else {
        this.showMatchActionDialog = true;
      }
    },
    async declineMatch() {
      this.matchAction = 'decline';
      if (this.ritual) {
        this.showRitualMatchActionDialog = true;
      } else {
        this.showMatchActionDialog = true;
      }
    },
  },
});
</script>

<style lang="scss" scoped></style>
