import { Route, RouteConfig } from 'vue-router';
import { toBoolean } from '@/utils/convert';
import {
  MAppointments,
  MBadges,
  MCommunity,
  MContent,
  MHome,
  MInbox,
  MMentoring,
  MResources,
  MRituals,
  MSettings,
} from '@/views';
import {
  appointmentsGuard,
  badgesGuard,
  contentGuard,
  dashboardGuard,
  homeGuard,
  inboxGuard,
  profileGuard,
  ritualsGuard,
  settingsGuard,
} from '@/router/guards';
import { RouteName } from '@/router/types';
import MProfile from '@/views/MProfile';
import { NavigationGuardNext } from 'vue-router/types/router';
import { resourcesGuard } from '@/router/guards/resources';
import {
  communityRoutes,
  dashboardsRoutes,
  mentoringRoutes,
  profileRoutes,
  ritualsRoutes,
  settingsRoutes,
} from '@/router/routes';
import { MDashboards } from '@/views/MDashboards';
import { MMatches } from '@/views/MMatches';

export const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: { name: RouteName.Home },
    children: [
      {
        path: 'user/:userId',
        redirect: { name: RouteName.CommunityUserDetails },
      },
      {
        path: 'enroll',
        redirect: { name: RouteName.CommunityEnroll },
      },
      {
        path: 'matches/:matchId',
        redirect: { name: RouteName.CommunityMatchAction },
      },
      {
        path: 'invites/:inviteId',
        redirect: { name: RouteName.CommunityInvite },
      },
      {
        path: 'appointments/:appointmentId',
        redirect: { name: RouteName.CommunityAppointmentFeedback },
      },
    ],
  },
  {
    path: '/community',
    name: RouteName.Community,
    component: MCommunity,
    props: (route) => ({
      matchingMode: toBoolean(route.query.matching),
    }),
    children: communityRoutes,
  },
  {
    path: '/home',
    name: RouteName.Home,
    component: MHome,
    beforeEnter: homeGuard,
  },
  {
    path: '/dashboards',
    name: RouteName.Dashboard,
    component: MDashboards,
    beforeEnter: dashboardGuard,
    children: dashboardsRoutes,
    redirect: { name: RouteName.DashboardCommunity },
  },
  {
    path: '/settings',
    name: RouteName.Settings,
    component: MSettings,
    beforeEnter: settingsGuard,
    children: settingsRoutes,
  },
  {
    path: '/inbox',
    name: RouteName.Inbox,
    component: MInbox,
    beforeEnter: inboxGuard,
  },
  {
    path: '/inbox/:roomId',
    name: RouteName.InboxRoom,
    component: MInbox,
    beforeEnter: inboxGuard,
    props: (route) => ({
      roomId: route.params['roomId'] != null ? Number(route.params['roomId']) : undefined,
    }),
  },
  {
    path: '/profile',
    name: RouteName.Profile,
    component: MProfile,
    beforeEnter: profileGuard,
    children: profileRoutes,
    redirect: { name: RouteName.ProfilePersonal },
  },
  {
    path: '/matches',
    name: RouteName.Matches,
    component: MMatches,
  },
  {
    path: '/appointments',
    name: RouteName.Appointments,
    component: MAppointments,
    beforeEnter: appointmentsGuard,
  },
  {
    path: '/badges',
    name: RouteName.Badges,
    component: MBadges,
    beforeEnter: badgesGuard,
  },
  {
    path: '/resources',
    name: RouteName.Resources,
    component: MResources,
    beforeEnter: resourcesGuard,
  },
  {
    path: '/content',
    name: RouteName.Content,
    component: MContent,
    beforeEnter: contentGuard,
    alias: ['/news', '/videos'],
  },
  {
    path: '/rituals',
    component: MRituals,
    beforeEnter: ritualsGuard,
    children: ritualsRoutes,
  },
  {
    path: '/mentoring',
    name: RouteName.Mentoring,
    component: MMentoring,
    // mentoringRedirection is set as global before guards instead of as beforeEnter
    // so that we ensure the redirector is alwasys called event when navigating between children components
    // https://router.vuejs.org/guide/advanced/navigation-guards.html#per-route-guard
    // beforeEnter: mentoringRedirection,
    children: mentoringRoutes,
  },
  {
    path: '*',
    beforeEnter: (from: Route, to: Route, next: NavigationGuardNext): void => {
      next({ path: '/home' });
    },
  },
];
