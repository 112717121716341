<template>
  <div v-if="show" class="m-banner">
    <div class="pr-4">
      <h3 class="m-banner__heading">
        {{ $t('banners.completeProfile.heading', { percentage: completionPercentage }) }}
      </h3>
      <p v-if="extended" class="mb-0 m-banner__text">{{ $t('banners.completeProfile.text') }}</p>
    </div>
    <v-btn v-if="extended" depressed color="button" :to="profileRoute">
      {{ $t('banners.completeProfile.button') }}
    </v-btn>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { RouteName } from '@/router/types';
import { TenantState } from '@/store/tenant';

export default Vue.extend({
  name: 'm-profile-completion-banner',
  props: {
    extended: { type: Boolean, default: false },
  },
  data: () => ({
    profileRoute: { name: RouteName.Profile },
  }),
  computed: {
    ...mapGetters('users', {
      completionPercentage: 'completionPercentage',
    }),
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    ...mapGetters('tenant', {
      hideCompletionBanner: 'hideCompletionBanner',
    }),
    show() {
      return !this.hideCompletionBanner;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.m-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  background-color: var(--v-banner-base);
  padding: 16px 24px;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    flex-direction: column;
    align-items: start;
  }

  &__heading {
    font-weight: 600;
  }

  &__text {
    font-size: 14px;
    color: var(--v-gray-base);
  }

  .v-btn {
    border-radius: 4px;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      margin-top: 8px;
      margin-bottom: 4px;
      width: 100%;
    }
  }
}
</style>
