<template>
  <div class="d-flex justify-end gap-4">
    <m-pending-match-card v-if="pendingMatch" :value="pendingMatch" @onStateChanged="nextMatch" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { IMatchUser, MatchStatus } from '@mentessa/types';
import { LoadUserMatches } from '@/store/matching';
import { isActionRequired } from '@/utils/matches';
import MPendingMatchCard from '@/components/Cards/MPendingMatchCard';

export default Vue.extend({
  name: 'm-pending-actions',
  components: {
    MPendingMatchCard,
  },
  data: () => ({
    pendingMatches: [] as Array<IMatchUser>,
    pendingMatchIndex: 0,
  }),
  computed: {
    pendingMatch() {
      return this.pendingMatches[this.pendingMatchIndex];
    },
  },
  async mounted() {
    const matches: Array<IMatchUser> = await this.$store.dispatch(new LoadUserMatches(MatchStatus.Pending));
    this.pendingMatchIndex = 0;
    this.pendingMatches = matches.filter((mu) => mu.match.matchUsers?.[0]?.user != null && isActionRequired(mu));
  },
  methods: {
    nextMatch() {
      ++this.pendingMatchIndex;
    },
  },
});
</script>

<style lang="scss" scoped>
//
</style>
