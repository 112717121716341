import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { SearchActions, SearchMutations, SearchState } from './types';
import { api } from '@/store/api';
import { ShowErrorNotification } from '@/store/notifications';
import i18n from '@/plugins/i18n';

export const actions: ActionTree<SearchState, RootState> = {
  async [SearchActions.SearchUsers](_, { token }) {
    console.log(`Search for user. Token: ${token}`, token);
    const response = await api.post('/search', { token });
    return response.data;
  },

  async [SearchActions.CheckDomainNameAvailability]({ dispatch }, { name }) {
    try {
      const response = await api.post('/onboarding/check_domain', { name });
      const data = response?.data;
      return data?.result ?? false;
    } catch (e) {
      console.error('Error while CheckDomainNameAvailability.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [SearchActions.ChangeInstantSearchInstance]({ commit }, { instance }) {
    commit(SearchMutations.SetInstantSearchInstance, instance);
  },

  async [SearchActions.ChangeActiveFacets]({ commit }, { facets }) {
    commit(SearchMutations.SetActiveFacets, facets);
  },
};
