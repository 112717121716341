<template>
  <m-panel :title="$t('panels.journals.title')" :draggable="draggable">
    <v-slide-group
      v-if="hasJournals"
      v-model="activeItem"
      center-active
      class="m-slide-group mt-4"
      mandatory
      show-arrows
      @click:next="selectNext"
      @click:prev="selectPrev"
    >
      <v-slide-item>
        <div style="width: 64px; height: 100%"></div>
      </v-slide-item>
      <v-slide-item
        v-for="(journal, index) in journals"
        :key="`journal-${journal.id}-${index}`"
        v-slot="{ toggle }"
        class="mx-2"
      >
        <m-journal-card :value="journal" @click="toggle" />
      </v-slide-item>
      <v-slide-item>
        <div style="width: 64px; height: 100%"></div>
      </v-slide-item>
    </v-slide-group>
    <m-empty-state
      v-else
      :cta="$t('mentoring.empty.cta')"
      :description="$t('mentoring.empty.description')"
      :image="require('@/assets/empty/matching.svg')"
      :title="$t('mentoring.empty.title')"
      :to="communityRoute"
    />
  </m-panel>
</template>

<script lang="ts">
import Vue from 'vue';
import MPanel from '@/components/MPanel';
import { MentoringSessionStatus } from '@mentessa/types';
import { mapState } from 'vuex';
import { MentoringState } from '@/store/mentoring';
import MJournalCard from '@/components/Cards/MJournalCard';
import MEmptyState from '@/components/MEmptyState';
import { RouteName } from '@/router/types';

export default Vue.extend({
  name: 'm-open-journals-panel',
  components: { MEmptyState, MJournalCard, MPanel },
  props: {
    draggable: { type: Boolean, default: false },
  },
  data: () => ({
    activeItem: 1,
    communityRoute: { name: RouteName.Community },
  }),
  computed: {
    ...mapState<MentoringState>('mentoring', {
      dataState: (state: MentoringState) => state.sessions.state,
      sessionsPage: (state: MentoringState) => state.sessions.data,
    }),
    journals() {
      if (this.dataState) {
        return this.sessionsPage
          .filter((sessionData) => sessionData.data.status === MentoringSessionStatus.Progress)
          .map((sessionData) => sessionData.data);
      }
      return [];
    },
    hasJournals() {
      return this.journals.length > 0;
    },
  },
  mounted() {
    this.$emit('onReady');
  },
  methods: {
    selectNext() {
      this.activeItem = (this.activeItem + 1) % (this.journals.length + 2);
    },
    selectPrev() {
      this.activeItem = (this.activeItem - 1) % (this.journals.length + 2);
    },
  },
});
</script>

<style lang="scss" scoped>
.m-slide-group:deep(.v-slide-group__wrapper) {
  //.v-slide-group__wrapper {
  mask: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 5%, rgba(0, 0, 0, 1) 95%, rgba(0, 0, 0, 0) 100%);
}

.m-slide-group:deep(.v-slide-group__next) {
  min-width: 24px;
}

.m-slide-group:deep(.v-slide-group__prev) {
  min-width: 24px;
}
</style>
