import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { UtilsState } from '@/store/utils/types';

export const getters: GetterTree<UtilsState, RootState> = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  nameForLang: (state: UtilsState, getters) => (lang: string) => {
    return state.languages[lang] || lang;
  },
};
