<template>
  <v-container
    class="m-settings-debug-questionnaires mt-8 elevation-1 rounded"
    sentry-tag="settings-debug-questionnaires"
  >
    <v-row class="m-settings-debug-questionnaires__header align-center pa-6">
      <v-col cols="7"></v-col>
      <v-col class="d-flex" cols="5">
        <div class="ml-auto">
          <v-btn
            color="button"
            depressed
            large
            sentry-tag="settings-debug-questionnaires.button.createQuestionnaire"
            @click="openCreateQuestionnaireDialog"
          >
            Create Questionnaire
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-0">
        <v-data-table
          :headers="headers"
          :items="questionnaires.items"
          :loading="loading"
          :disable-sort="true"
          hide-default-footer
          class="m-settings-debug-questionnaires__table"
        >
          <template v-slot:item.actions="{ item }">
            <div class="pa-3">
              <v-menu bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    link
                    sentry-tag="settings-debug-questionnaires.menu.editQuestionnaire"
                    @click="openEditQuestionnaireDialog(item)"
                  >
                    <v-list-item-title> Edit Questionnaire</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    link
                    sentry-tag="settings-debug-questionnaires.menu.deleteQuestionnaire"
                    @click="deleteQuestionnaire(item)"
                  >
                    <v-list-item-title> Delete Questionnaire</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
        </v-data-table>
        <v-row dense>
          <v-pagination
            v-model="page"
            :disabled="loading"
            :length="questionnaires?.meta?.totalPages"
            class="m-settings-debug-questionnaires__table-pagination pa-6 ml-auto"
            sentry-tag="m-settings-debug-questionnairess.pagination"
          />
        </v-row>
      </v-col>
    </v-row>
    <m-questionnaire-dialog
      v-model="questionnaireDialogOpened"
      @questionnaire-create-edit="fetchQuestionnaires"
      :type="type"
      :editedQuestionnaire="editedQuestionnaire"
    />
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import { DeleteQuestionnaire, LoadQuestionnaires } from '@/store/rituals';
import { MQuestionnaireDialog } from '@/components/Dialogs';
import { IQuestionnaire } from '@mentessa/types';

export default Vue.extend({
  name: 'm-settings-debug-questionnaires',
  components: { MQuestionnaireDialog },
  data: () => ({
    questionnaireDialogOpened: false,
    page: 1,
    loading: false,
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Title', value: 'title' },
      { text: 'Description', value: 'description' },
      { text: 'Actions', value: 'actions' },
    ],
    questionnaires: {},
    type: 'Create',
    editedQuestionnaire: undefined,
  }),
  watch: {
    async page() {
      await this.fetchQuestionnaires();
    },
  },
  computed: {},
  async mounted() {
    await this.fetchQuestionnaires();
  },
  methods: {
    openCreateQuestionnaireDialog() {
      this.type = 'Create';
      this.editedQuestionnaire = undefined;
      this.questionnaireDialogOpened = true;
    },
    openEditQuestionnaireDialog(item: IQuestionnaire) {
      this.type = 'Edit';
      this.editedQuestionnaire = item;
      this.questionnaireDialogOpened = true;
    },
    async deleteQuestionnaire(item: IQuestionnaire) {
      try {
        this.loading = true;
        await this.$store.dispatch(new DeleteQuestionnaire(item));
        this.fetchQuestionnaires();
      } finally {
        this.loading = false;
      }
    },
    async fetchQuestionnaires() {
      try {
        this.loading = true;
        this.questionnaires = await this.$store.dispatch(new LoadQuestionnaires(this.page));
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.m-settings-debug-questionnaires {
  background-color: var(--v-white-base);

  &__header {
    border-bottom: 1px solid var(--v-lightgray-base);
  }

  &__table {
    border-radius: unset;
    border-bottom: 1px solid var(--v-lightgray-base);
  }

  &__table-pagination:deep(.v-pagination__navigation, .v-pagination__item) {
    box-shadow: none;
  }
}
</style>
