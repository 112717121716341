import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { UtilsActions, UtilsMutations, UtilsState } from '@/store/utils/types';
import { LocalizedLanguageNames } from '@cospired/i18n-iso-languages';
import Geonames from 'geonames.js';
import { api } from '@/store/api';
import { ShowErrorNotification, ShowNotification } from '@/store/notifications';
import i18n from '@/plugins/i18n';

export const actions: ActionTree<UtilsState, RootState> = {
  async [UtilsActions.LoadLanguages]({ commit }, { locale }) {
    let data = await import(`@cospired/i18n-iso-languages/langs/${locale}.json`);
    if (data == null) {
      data = await import(`@cospired/i18n-iso-languages/langs/en.json`);
    }
    const langNames: LocalizedLanguageNames = data.languages;
    commit(UtilsMutations.SetLanguages, langNames);
    console.log(`Languages for ${locale} loaded`);
  },

  async [UtilsActions.InitGeonames]({ commit }, { locale }) {
    const geonames = Geonames({
      username: 'tihmenev',
      lan: 'en', // ToDo: Use locale here
      encoding: 'JSON',
    });
    commit(UtilsMutations.SetGeonames, geonames);
    console.log(`Init geonames for ${locale}`);
  },

  async [UtilsActions.LoadCitySuggestions]({ state }, { token }) {
    try {
      const result = (await state.geonames.search({
        name_startsWith: token,
        searchlang: 'en',
        featureClass: ['P'],
        cities: 'cities1000',
        style: 'short',
        maxRows: 16,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      })) as any;
      return result.geonames.filter((x) => ['C', 'A', '', undefined].includes(x.fcode[3])).map((x) => x.name);
    } catch (e) {
      console.log('Error while LoadCitySuggestions', e);
    }
    return [];
  },

  async [UtilsActions.HubSpotTestNewContact]({ dispatch }) {
    try {
      const response = await api.post(`hubspot/test`);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.error('Error while HubSpotTestNewContact.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [UtilsActions.LoadAllKeycloakClients]({ dispatch }) {
    try {
      const response = await api.get(`keycloak/clients`);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.error('Error while LoadAllKeycloakClients.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [UtilsActions.UpdateAllKeycloakClients]({ dispatch }) {
    try {
      const response = await api.put(`keycloak/clients`);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.error('Error while UpdateAllKeycloakClients.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [UtilsActions.CreateAllKeycloakClients]({ dispatch }) {
    try {
      const response = await api.post(`keycloak/clients`);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.error('Error while CreateAllKeycloakClients.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [UtilsActions.LoadAllKeycloakIdentityProviders]({ dispatch }) {
    try {
      const response = await api.get(`keycloak/providers`);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.error('Error while LoadAllKeycloakIdentityProviders.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
};
