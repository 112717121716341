<template>
  <m-autocomplete-pure
    :filter="userFilter"
    :items="filteredUsers"
    :value="value"
    item-value="id"
    return-object
    sentry-tag="autocomplete-users-pure"
    v-bind="{ ...$attrs, ...$props }"
    @onSearchChanged="$emit('onSearchChanged', $event)"
    v-on="$listeners"
  >
    <template v-slot:content="{ item }">
      <m-user-preview :debug="debug" :user="item" />
    </template>
  </m-autocomplete-pure>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import MAutocompletePure from './AutocompletePure.vue';
import { MUserPreview } from '@/components/MUserPreview';
import { UserEntity } from '@mentessa/types';
import { getFullName } from '@/utils/user';

export default Vue.extend({
  name: 'm-autocomplete-users-pure',
  components: {
    MUserPreview,
    MAutocompletePure,
  },
  props: {
    items: Array as PropType<UserEntity[]>,
    value: { type: Object as PropType<UserEntity>, default: () => ({}) },
    exclude: { type: Array as PropType<UserEntity[]>, default: () => [] },
    debug: { type: Boolean, default: false },
  },
  computed: {
    filteredUsers() {
      if (this.exclude?.length > 0) {
        const excludeIds = this.exclude.map((user) => user.id);
        return this.items.filter((user) => user.id === this.value?.id || !excludeIds.includes(user.id));
      }
      return this.items;
    },
  },
  methods: {
    userFilter(item, queryText) {
      const searchText = queryText.toLowerCase();
      return this.fullName(item).toLowerCase().includes(searchText);
    },
    fullName(user: UserEntity) {
      return getFullName(user);
    },
  },
});
</script>
