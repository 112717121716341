<template>
  <div class="m-empty-state">
    <div v-if="image" class="m-empty-state__image">
      <v-img :src="image" class="m-shake-animation" contain height="46" transition="scale-transition" width="46" />
    </div>
    <h3 v-if="title" class="m-empty-state__heading mt-6">{{ title }}</h3>
    <p v-if="description" class="m-empty-state__text">{{ description }}</p>
    <v-btn
      v-if="cta"
      :to="to"
      class="outlined-light mt-2"
      color="button"
      depressed
      outlined
      @click="$emit('onButtonClick')"
    >
      {{ cta }}
    </v-btn>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'm-empty-state',
  props: {
    image: String,
    title: [String, Object /*as PropType<TranslateResult>*/],
    description: [String, Object /*as PropType<TranslateResult>*/],
    cta: [String, Object /*as PropType<TranslateResult>*/],
    to: { type: Object },
  },
});
</script>

<style lang="scss" scoped>
.m-empty-state {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 40px 24px;

  &__image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: var(--v-beige-base);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__heading {
    margin-bottom: 4px;
    font-size: 18px;
    text-align: center;
  }

  &__text {
    margin-bottom: 12px;
    color: var(--v-gray-base);
    text-align: center;
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-10px);
  }
  40% {
    transform: translateX(8px);
  }
  60% {
    transform: translateX(-6px);
  }
  80% {
    transform: translateX(4px);
  }
  100% {
    transform: translateX(0);
  }
}

.m-shake-animation {
  animation: shake 1s 1s forwards;
}
</style>
