<template>
  <v-app>
    <v-main>
      <v-container class="d-flex flex-column justify-center align-center fill-height mobile-app-container" fluid>
        <div class="d-flex flex-column justify-center align-center">
          <v-img :src="image" contain height="128px" width="128px" />
          <h3 class="text-center my-4">{{ $t('mobile.title') }}</h3>
          <v-btn class="mt-4" color="button" depressed style="min-width: 160px" href="mentessa://loginRedirect">
            {{ $t('mobile.openApp') }}
          </v-btn>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapState } from 'vuex';
import { LoadTenant, TenantState } from '@/store/tenant';
import { LoadTranslations } from '@/store/ui';

export default Vue.extend({
  name: 'mobile-app',
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    tenantTitle() {
      return this.tenant?.attributes?.theme?.title ?? '';
    },
    image() {
      if (this.tenant?.attributes.theme.image) {
        return this.tenant?.attributes.theme.image;
      }
      return require('@/assets/logo.svg');
    },
  },
  async created() {
    await this.$store.dispatch(new LoadTenant());
    await this.$store.dispatch(new LoadTranslations(this.$i18n.locale));
  },
});
</script>

<style lang="scss" scoped>
.mobile-app-container {
  background: var(--v-background-base);
  width: 600px;

  & > div {
    padding: 40px;
    max-width: 462px;
    border-radius: 6px;
    background: var(--v-white-base);
    box-shadow: 0 4px 12px 6px rgba(0, 0, 0, 0.06);
  }
}
</style>
