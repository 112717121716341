<template>
  <div>
    <m-feature-enable-switch feature="email" />
    <v-card class="pa-4 my-4">
      <v-card-title>From</v-card-title>
      <h5>Name</h5>
      <span class="error--text text-body-2">
        Warning: it will override all rules from email templates and will be forcefully used for all emails
      </span>
      <v-text-field v-model="senderName" :placeholder="defaultName" dense outlined />
    </v-card>
    <v-card class="pa-4 my-4">
      <v-card-title>To</v-card-title>
      <v-card class="pa-4">
        <v-card-title>Rewrite</v-card-title>
        <v-card-text class="pb-0">
          All emails from the platform will be sent to the defined email. Useful for tests
        </v-card-text>
        <v-switch v-model="receiverRewriteEnabled" label="Enable" />
        <v-text-field v-if="receiverRewriteEnabled" v-model="receiverRewriteEmail" dense label="Email" outlined />
      </v-card>
    </v-card>
    <v-btn class="float-right" color="button" depressed min-width="128" @click="save">Save</v-btn>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { ChangeFeatureConfig, TenantState } from '@/store/tenant';
import MFeatureEnableSwitch from '@/components/Settings/MSettingsFeatures/MFeatureEnableSwitch';

export default Vue.extend({
  name: 'm-settings-features-email',
  components: { MFeatureEnableSwitch },
  data: () => ({
    senderName: undefined,
    senderEmail: undefined,
    receiverRewriteEnabled: false,
    receiverRewriteEmail: undefined,
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    ...mapGetters('tenant', {
      featureConfig: 'featureConfig',
    }),
    emailConfig() {
      return this.featureConfig('email');
    },
    defaultName() {
      return `Mentessa via ${this.tenant.attributes.theme.title}`;
    },
  },
  mounted() {
    this.updateData();
  },
  methods: {
    async save() {
      const config: Record<string, unknown> = {};

      const stringUpdateCondition = (strA, strB) => {
        return strA !== strB && (!!strA || !!strB);
      };

      if (stringUpdateCondition(this.senderName, this.emailConfig.from?.name)) {
        config.from = {
          name: this.senderName,
        };
      }
      if (stringUpdateCondition(this.senderEmail, this.emailConfig.from?.email)) {
        if (config.from == null) {
          config.from = {};
        }
        config.from['email'] = this.senderEmail;
      }
      if (!!this.receiverRewriteEnabled !== !!this.emailConfig.to?.rewrite?.enabled) {
        if (config.to == null) {
          config.to = {};
        }
        if (config.to['rewrite'] == null) {
          config.to['rewrite'] = {};
        }
        config.to['rewrite']['enabled'] = !!this.receiverRewriteEnabled;
      }
      if (stringUpdateCondition(this.receiverRewriteEmail, this.emailConfig.to?.rewrite?.email)) {
        if (config.to == null) {
          config.to = {};
        }
        if (config.to['rewrite'] == null) {
          config.to['rewrite'] = {};
        }
        config.to['rewrite']['email'] = this.receiverRewriteEmail;
      }

      if (Object.keys(config).length > 0) {
        await this.$store.dispatch(new ChangeFeatureConfig(this.tenant, 'email', config));
      }
    },
    updateData() {
      this.senderName = this.emailConfig.from?.name;
      this.senderEmail = this.emailConfig.from?.email;
      this.receiverRewriteEnabled = this.emailConfig.to?.rewrite?.enabled;
      this.receiverRewriteEmail = this.emailConfig.to?.rewrite?.email;
    },
  },
});
</script>
