<template>
  <div class="fill-width">
    <m-text-area v-model="localValue" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { MTextArea } from '@/components/Inputs';

export default Vue.extend({
  name: 'm-tenant-onboarding-need-to-know-form',
  components: { MTextArea },
  props: {
    value: { type: String },
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value: string) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    submitStep(): void {
      this.$emit('onSubmit');
    },
  },
});
</script>
