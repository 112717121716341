import { RouteName } from '@/router/types';
import {
  MRitualCreated,
  MRitualEdit,
  MRitualInvite,
  MRitualsList,
  MRitualTemplates,
  MSettingsRitualsData,
  MSettingsRitualsResults,
} from '@/components/Settings';
import { settingsRitualTemplatesGuard } from '@/router/guards';

const settingsRitualsRoutes = [
  {
    path: '',
    name: RouteName.SettingsRituals,
    component: MRitualsList,
  },
  {
    path: 'templates',
    name: RouteName.SettingsRitualsTemplates,
    component: MRitualTemplates,
    beforeEnter: settingsRitualTemplatesGuard,
  },
  {
    path: ':ritualId/edit',
    name: RouteName.SettingsRitualsEdit,
    component: MRitualEdit,
    props: (route) => ({
      ritualId: route.params['ritualId'] != null ? Number(route.params['ritualId']) : undefined,
    }),
  },
  {
    path: ':ritualId/invite',
    name: RouteName.SettingsRitualsInvitation,
    component: MRitualInvite,
    props: (route) => ({
      ritualId: route.params['ritualId'] != null ? Number(route.params['ritualId']) : undefined,
    }),
  },
  {
    path: ':ritualId/created',
    name: RouteName.SettingsRitualsCreated,
    component: MRitualCreated,
    props: (route) => ({
      ritualId: route.params['ritualId'] != null ? Number(route.params['ritualId']) : undefined,
    }),
  },
  {
    path: ':ritualId/results',
    name: RouteName.SettingsRitualsResults,
    component: MSettingsRitualsResults,
    props: (route) => ({
      ritualId: route.params['ritualId'] != null ? Number(route.params['ritualId']) : undefined,
    }),
  },
  {
    path: ':ritualId/data',
    name: RouteName.SettingsRitualsData,
    component: MSettingsRitualsData,
    props: (route) => ({
      ritualId: route.params['ritualId'] != null ? Number(route.params['ritualId']) : undefined,
    }),
  },
];

export { settingsRitualsRoutes };
export default settingsRitualsRoutes;
