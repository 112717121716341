<template>
  <validation-provider v-slot="{ errors }" :debounce="validationDebounce" :name="label" :rules="validationRules" slim>
    <v-select
      :error-messages="errors"
      :label="labelWithRequired"
      v-bind="{ ...$props, ...$attrs }"
      v-on="$listeners"
      sentry-tag="select"
      multiple
      chips
      deletable-chips
    >
      <template v-slot:item="data">
        <slot name="item" v-bind="data">{{ i18nPath ? $t(`${i18nPath}.${data.item}`) : data.item }}</slot>
      </template>
      <template v-slot:selection="data">
        <v-chip close close-icon="close" @click:close="deleteItem(data)">
          {{ i18nPath ? $t(`${i18nPath}.${data.item}`) : data.item }}
        </v-chip>
      </template>
    </v-select>
  </validation-provider>
</template>

<script lang="ts">
import { max, required } from 'vee-validate/dist/rules';
import { extend, setInteractionMode, ValidationProvider } from 'vee-validate';
import Vue from 'vue';

export default Vue.extend({
  name: 'm-select-multiple',
  components: {
    ValidationProvider,
  },
  props: {
    label: [String, Object /*as PropType<TranslateResult>*/],
    value: { type: Array, default: () => [] },
    outlined: { type: Boolean, default: true },
    hideDetails: { type: String, default: 'auto' },
    validationRules: String,
    validationDebounce: Number,
    validationMode: { type: String, default: 'aggressive' },
    requiredSuffix: { type: String, default: ' *' },
    i18nPath: { type: String },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value: Array<never>) {
        this.$emit('input', value);
      },
    },
    isRequired() {
      return this.validationRules?.includes('required') ?? false;
    },
    labelWithRequired() {
      return this.isRequired ? `${this.label ?? ''}${this.requiredSuffix}` : this.label;
    },
  },

  watch: {
    validationMode(mode: string) {
      setInteractionMode(mode);
    },
  },
  created() {
    setInteractionMode(this.validationMode);

    extend('required', {
      ...required,
      message: this.$t('validation.required'),
    } as never);

    extend('max', {
      ...max,
      message: this.$t('validation.max'),
    } as never);
  },
  methods: {
    deleteItem(chip) {
      this.$emit('input', [...this.value.slice(0, chip.index), ...this.value.slice(chip.index + 1)]);
    },
  },
});
</script>
