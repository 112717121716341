<template>
  <m-panel banner>
    <v-row class="justify-center flex-column flex-sm-row">
      <v-col>
        <v-chip color="white">
          <m-ritual-preview v-if="ritual" :ritual="ritual" max-width="240px" />
          <span v-else>{{ $t(`settings.matching.source.${source}`) }}</span>
        </v-chip>
        <v-row class="mx-2 mt-2 justify-center align-center gap-4">
          <span>
            {{ $t('cards.match.pending_match.title') }}
          </span>
          <m-user-preview :user="matchedUser" @click="showUserDetailsDialog = true" />
        </v-row>
      </v-col>

      <v-col class="flex-grow-0 gap-2 d-flex flex-sm-column justify-center">
        <v-btn class="" color="button" outlined width="120" @click="declineMatch"
          >{{ $t('cards.match.pending_match.decline') }}
        </v-btn>
        <v-btn color="button" width="120" @click="acceptMatch">{{ $t('cards.match.pending_match.accept') }}</v-btn>
      </v-col>
    </v-row>
    <m-match-action-dialog v-model="showMatchActionDialog" :action="matchAction" :match-id="matchId" />
    <m-ritual-match-action-dialog
      v-model="showRitualMatchActionDialog"
      :action="matchAction"
      :match-id="matchId"
      :ritual-id="matchRitualId"
    />
    <m-user-details-simple-dialog
      v-model="showUserDetailsDialog"
      :user-id="matchedUserId"
      @onMatchStateChanged="handleMatchStateChanged"
    />
  </m-panel>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IMatch, IMatchUser } from '@mentessa/types';
import MPanel from '@/components/MPanel';
import MRitualPreview from '@/components/MRitualPreview';
import MRitualMatchActionDialog from '../../Dialogs/MRitualMatchActionDialog';
import MMatchActionDialog from '../../Dialogs/MMatchActionDialog';
import MUserPreview from '@/components/MUserPreview/UserPreviewPure.vue';
import { MUserDetailsSimpleDialog } from '@/components/Dialogs';

export default Vue.extend({
  name: 'm-pending-match-card',
  components: {
    MUserDetailsSimpleDialog,
    MUserPreview,
    MMatchActionDialog,
    MRitualMatchActionDialog,
    MRitualPreview,
    MPanel,
  },
  props: {
    value: Object as PropType<IMatchUser>,
  },
  data: () => ({
    showMatchActionDialog: false,
    showRitualMatchActionDialog: false,
    showUserDetailsDialog: false,
    matchAction: undefined,
  }),
  computed: {
    source() {
      return this.value?.match?.source;
    },
    matchedUser() {
      return this.value?.match?.matchUsers?.[0]?.user;
    },
    ritual() {
      return this.value?.match?.ritual;
    },
    matchId() {
      return this.value?.match?.id;
    },
    matchRitualId() {
      return this.ritual?.id;
    },
    matchedUserId() {
      return this.matchedUser?.id;
    },
  },
  watch: {
    showMatchActionDialog(value: boolean) {
      if (!value) {
        this.$emit('onStateChanged');
      }
    },
    showRitualMatchActionDialog(value: boolean) {
      if (!value) {
        this.$emit('onStateChanged');
      }
    },
  },
  methods: {
    async acceptMatch() {
      this.matchAction = 'accept';
      if (this.ritual) {
        this.showRitualMatchActionDialog = true;
      } else {
        this.showMatchActionDialog = true;
      }
    },
    async declineMatch() {
      this.matchAction = 'decline';
      if (this.ritual) {
        this.showRitualMatchActionDialog = true;
      } else {
        this.showMatchActionDialog = true;
      }
    },
    handleMatchStateChanged(match: IMatch) {
      if (match.id === this.matchId) {
        this.$emit('onStateChanged');
      }
    },
  },
});
</script>

<style lang="scss" scoped></style>
