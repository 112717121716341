<template>
  <div :class="classes" class="box-list" sentry-tag="dashboard-widget-list-tags">
    <h4>{{ title }}</h4>

    <ais-refinement-list :attribute="value" class="grid-item">
      <template v-slot="{ items }">
        <div v-for="(item, index) in makeTagsList(items)" :key="`list-item-${index}`">
          <dl class="mt-4">
            <dt>{{ item.title }}</dt>
            <dd>{{ item.value }}</dd>
          </dl>
        </div>
      </template>
    </ais-refinement-list>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

export interface ListWidgetValue {
  title: string;
  value: string | number;
}

export default Vue.extend({
  name: 'm-dashboard-widget-list-tags',
  props: {
    value: String as PropType<'iTags' | 'eTags'>,
    title: [String, Object /*as PropType<TranslateResult>*/],
    nested: { type: Boolean, default: false },
  },
  computed: {
    classes() {
      return {
        'grid-item': !this.nested,
      };
    },
  },
  methods: {
    makeTagsList(items): Array<ListWidgetValue> {
      return items.map((item) => ({ title: item.label, value: item.count }));
    },
  },
});
</script>

<style lang="scss" scoped>
.box-list {
  padding: 24px;
  border: 1px solid var(--v-beige-base);
  border-radius: 16px;
  background: var(--v-white-base);
  text-align: left;
  min-height: 448px;
  max-height: 448px;
}

dl {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
