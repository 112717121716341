<template>
  <m-feature-enable-switch feature="groups" />
</template>

<script lang="ts">
import Vue from 'vue';
import MFeatureEnableSwitch from '@/components/Settings/MSettingsFeatures/MFeatureEnableSwitch';

export default Vue.extend({
  name: 'm-settings-features-groups',
  components: { MFeatureEnableSwitch },
});
</script>
