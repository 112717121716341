<template>
  <div>
    <v-simple-table class="m-matches-container mt-4">
      <thead>
        <tr>
          <th class="text-center"><span></span></th>
          <th class="text-center">
            <span>{{ $t('settings.matching.table.user1') }}</span>
          </th>
          <th class="text-center"><span></span></th>
          <th class="text-center">
            <span>{{ $t('settings.matching.table.user2') }}</span>
          </th>
          <th class="text-center">
            <span>{{ $t('settings.rituals.result.status.title') }}</span>
          </th>
          <th class="text-center">
            <span>{{ $t('settings.ritualEnroll.date') }}</span>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(match, index) in matches" :key="`settings-ritual-enroll-user-${index}`">
          <td>
            <v-chip v-if="match.user1?.attributes?.role" color="primary">{{ match.user1.attributes.role }}</v-chip>
          </td>
          <td class="text-center">
            <m-user-preview :user="match.user1?.user" />
          </td>
          <td>
            <v-chip v-if="match.user2?.attributes?.role" color="primary">{{ match.user2.attributes.role }}</v-chip>
          </td>
          <td class="text-center">
            <m-user-preview :user="match.user2?.user" />
          </td>
          <td class="text-center">
            <v-chip :color="match.statusTitle.color"> {{ match.statusTitle.title }}</v-chip>
          </td>
          <td class="text-center">{{ formatDate(match.createdAt) }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { LoadRitualMatches, RitualsState } from '@/store/rituals';
import MUserPreview from '@/components/MUserPreview/UserPreviewPure.vue';
import { formatInTimeZone } from 'date-fns-tz';
import { IMatch, IMatchUser, IRitual, MatchStatus } from '@mentessa/types';
import { mapState } from 'vuex';
import { MatchingState } from '@/store/matching';

interface RitualResultsRow extends IMatch {
  user1: IMatchUser;
  user2: IMatchUser;
  statusTitle: { title: string; color: string };
}

export default Vue.extend({
  name: 'm-ritual-results',
  components: { MUserPreview },
  data: () => ({
    matchesPage: {},
  }),
  computed: {
    ...mapState<MatchingState>('rituals', {
      ritual: (state: RitualsState) => state.current,
    }),
    matches(): Array<RitualResultsRow> {
      return (
        this.matchesPage.items?.map((match: IMatch) => ({
          ...match,
          user1: match.matchUsers[0],
          user2: match.matchUsers[1],
          statusTitle: this.getStatus(match),
        })) ?? []
      );
    },
  },
  watch: {
    async ritual(ritual: IRitual) {
      this.matchesPage = await this.$store.dispatch(new LoadRitualMatches(ritual, 1));
    },
  },
  async mounted() {
    if (this.ritual) {
      this.matchesPage = await this.$store.dispatch(new LoadRitualMatches(this.ritual, 1));
    }
  },
  methods: {
    formatDate(date: string | Date) {
      return formatInTimeZone(date, 'CET', `d MMMM yyyy h:m a`);
    },

    getStatus(match: IMatch) {
      switch (match.status) {
        case MatchStatus.Accepted:
          return { title: this.$t(`dashboard.widgets.ritual_matches.status.accepted`), color: 'success' };
        case MatchStatus.Declined:
          return { title: this.$t(`dashboard.widgets.ritual_matches.status.declined`), color: 'error' };
        case MatchStatus.Pending:
          return { title: this.$t(`dashboard.widgets.ritual_matches.status.pending`), color: undefined };
        case MatchStatus.Expired:
          return { title: this.$t(`dashboard.widgets.ritual_matches.status.expired`), color: 'rating' };
        case MatchStatus.Closed:
          return { title: this.$t(`dashboard.widgets.ritual_matches.status.closed`), color: 'accent' };
        default:
          return { title: this.$t(`dashboard.widgets.ritual_matches.status.pending`), color: undefined };
      }
    },
  },
});
</script>
