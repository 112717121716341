<template>
  <validation-provider v-slot="{ errors }" :debounce="validationDebounce" :name="label" :rules="validationRules" slim>
    <v-autocomplete
      :error-messages="errors"
      :label="labelWithRequired"
      v-bind="{ ...$props, ...$attrs }"
      v-on="$listeners"
      sentry-tag="autocomplete"
    >
    </v-autocomplete>
  </validation-provider>
</template>

<script lang="ts">
import { max, required } from 'vee-validate/dist/rules';
import { extend, setInteractionMode, ValidationProvider } from 'vee-validate';
import Vue from 'vue';

export default Vue.extend({
  name: 'm-autocomplete',
  components: {
    ValidationProvider,
  },
  props: {
    value: { type: [String, Array, Object, Number] },
    label: [String, Object /*as PropType<TranslateResult>*/],
    outlined: { type: Boolean, default: true },
    hideDetails: { type: String, default: 'auto' },
    validationRules: String,
    validationDebounce: Number,
    validationMode: { type: String, default: 'aggressive' },
    requiredSuffix: { type: String, default: ' *' },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    isRequired() {
      return this.validationRules?.includes('required') ?? false;
    },
    labelWithRequired() {
      return this.isRequired ? `${this.label ?? ''}${this.requiredSuffix}` : this.label;
    },
  },

  watch: {
    validationMode(mode: string) {
      setInteractionMode(mode);
    },
  },
  created() {
    setInteractionMode(this.validationMode);

    extend('required', {
      ...required,
      message: this.$t('validation.required'),
    } as never);

    extend('max', {
      ...max,
      message: this.$t('validation.max'),
    } as never);
  },
});
</script>
