<template>
  <validation-observer ref="validation" v-slot="{ invalid }">
    <m-save-profile-button :disabled="invalid || saveButtonDisabled" @onButtonClick="save" />
    <div class="d-flex flex-column gap-4 mt-4">
      <m-ms365-integration :integration="ms365Integration" />
    </div>
    <m-leave-dialog :value="showDialog" @onCancel="closeDialog" @onDiscard="leavePage" @onSave="saveAndLeave" />
  </validation-observer>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { LoadTimeSlots, SaveUserProfile, SaveUserSchedules, UsersState } from '@/store/users';
import MSaveProfileButton from '../MSaveProfileButton';
import { MLeaveDialog } from '@/components/Dialogs';
import { ValidationObserver } from 'vee-validate';
import { ISchedule, MeetingLocation, MentoringOptions, MentoringRole } from '@mentessa/types';
import MMs365Integration from '@/components/Settings/MSettingsIntegrations/MMS365Integration.vue';

export default Vue.extend({
  name: 'm-profile-integrations',
  components: {
    MMs365Integration,
    MLeaveDialog,
    MSaveProfileButton,
    ValidationObserver,
  },
  data: () => ({
    ms365Integration: undefined,
    mentoring: {
      available: false,
      contact: {
        enabled: false,
        email: undefined,
      },
      roles: undefined,
      meetingLocations: undefined,
    } as MentoringOptions,
    loading: false,
    showDialog: false,
    to: null,
    schedule: [] as Array<ISchedule>,
    initialSchedule: [] as Array<ISchedule>,
  }),
  computed: {
    ...mapGetters('tenant', {
      isMentoringAvailable: 'isMentoringAvailable',
      isScheduleAvailable: 'isScheduleAvailable',
    }),
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    ...mapGetters('ui', {
      formatInUserTimeZone: 'formatInUserTimeZone',
      currentUserTz: 'currentUserTz',
    }),
    isScheduleChanged() {
      if (this.initialSchedule.length !== this.schedule.length) {
        return true;
      }

      const unknownSlots = this.schedule.filter((slot) => slot.id == null);
      return unknownSlots.some((slot) => !this.initialSchedule.find((initialSlot) => initialSlot.date === slot.date));
    },
    isChanged() {
      if (this.mentoring.available !== (this.me.attributes?.mentoring?.available ?? false) || this.isScheduleChanged) {
        return true;
      }

      if ((this.mentoring.roles?.length ?? 0) !== (this.me.attributes?.mentoring?.roles?.length ?? 0)) {
        return true;
      }

      if (
        (this.mentoring.meetingLocations?.length ?? 0) !==
        (this.me.attributes?.mentoring?.meetingLocations?.length ?? 0)
      ) {
        return true;
      }

      if (
        this.mentoring.roles?.length &&
        !this.mentoring.roles.every((role: MentoringRole) => this.me.attributes?.mentoring?.roles?.includes(role))
      ) {
        return true;
      }

      if (
        this.mentoring.meetingLocations?.length &&
        !this.mentoring.meetingLocations.every((location: MeetingLocation) =>
          this.me.attributes?.mentoring?.meetingLocations?.includes(location),
        )
      ) {
        return true;
      }
      return false;
      //
    },
    saveButtonDisabled() {
      return this.loading || (!this.isChanged && this.me.complete);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isChanged || this.to) {
      next();
    } else {
      this.to = to;
      this.showDialog = true;
    }
  },
  methods: {
    async loadSchedule() {
      this.schedule = (await this.$store.dispatch(new LoadTimeSlots())) ?? [];
      this.initialSchedule = [...this.schedule];
    },
    updateAvailabilityData() {
      this.mentoring = {
        available: this.me.attributes?.mentoring?.available ?? false,
        contact: this.me.attributes?.mentoring?.contact,
        roles: this.me.attributes?.mentoring?.roles,
        meetingLocations: this.me.attributes?.mentoring?.meetingLocations,
      };
    },
    async save() {
      const dto = {
        attributes: {
          mentoring: {
            available: this.mentoring.available,
            roles: this.mentoring.roles,
            meetingLocations: this.mentoring.meetingLocations,
          },
        },
      };
      try {
        this.loading = true;
        await this.$store.dispatch(new SaveUserProfile(dto));
        if (this.isScheduleChanged) {
          if (await this.$store.dispatch(new SaveUserSchedules(this.schedule))) {
            this.initialSchedule = [...this.schedule];
          }
        }
      } finally {
        this.loading = false;
      }
    },
    async leavePage() {
      this.showDialog = false;
      await this.$router.push(this.to);
    },
    closeDialog() {
      this.showDialog = false;
      this.to = null;
    },
    async saveAndLeave() {
      await this.save();
      await this.leavePage();
    },
  },
  async mounted() {
    await this.loadSchedule();
    this.updateAvailabilityData();
  },
});
</script>
