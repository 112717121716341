<template>
  <validation-provider
    v-slot="{ errors }"
    :debounce="validationDebounce"
    :name="label"
    :rules="validationRules"
    class="d-flex justify-center"
    slim
  >
    <v-chip-group
      v-model="localValue"
      :error-messages="errors"
      :label="labelWithRequired"
      class="chip-group"
      column
      v-bind="{ ...$props, ...$attrs }"
    >
      <v-chip v-for="(item, index) in items" :key="index" :class="chipClass(index)">
        <slot :item="item" name="item">{{ tr(item) }}</slot>
      </v-chip>
    </v-chip-group>
  </validation-provider>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { setInteractionMode, ValidationProvider } from 'vee-validate';

export default Vue.extend({
  name: 'm-chip-group',
  components: {
    ValidationProvider,
  },
  props: {
    value: { type: Array as PropType<number[]>, default: () => [] },
    label: [String, Object /*as PropType<TranslateResult>*/],
    outlined: { type: Boolean, default: true },
    hideDetails: { type: String, default: 'auto' },
    validationRules: String,
    validationDebounce: Number,
    validationMode: { type: String, default: 'aggressive' },
    requiredSuffix: { type: String, default: ' *' },
    items: { type: Array as PropType<string[]>, default: () => [] },
    i18nPath: { type: String, default: undefined },
    multiple: { type: Boolean, default: true },
  },
  methods: {
    chipClass(index: number) {
      return {
        'active-chip': this.value.includes(index),
      };
    },
  },
  computed: {
    tr() {
      return (text: string) => {
        return this.i18nPath ? this.$t(`${this.i18nPath}.${text}`) : text;
      };
    },
    localValue: {
      get() {
        return this.value;
      },
      set(value: number[] | number) {
        const val = Array.isArray(value) ? value : [value];
        this.$emit('input', val);
      },
    },
    isRequired() {
      return this.validationRules?.includes('required') ?? false;
    },
    labelWithRequired() {
      return this.isRequired ? `${this.label ?? ''}${this.requiredSuffix}` : this.label;
    },
  },
  watch: {
    validationMode(mode: string) {
      setInteractionMode(mode);
    },
  },
  created() {
    setInteractionMode(this.validationMode);
  },
});
</script>

<style lang="scss" scoped>
:deep(.chip-group) {
  padding: 17px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  justify-self: center;
  align-items: center;
  justify-content: center;
  background-color: var(--v-white-base);
  border: 0.75px solid #d6d3d1;
}

.chip-group :deep(.v-slide-group__content) {
  justify-content: center;
}

.active-chip {
  background-color: var(--v-primary-base) !important;
  color: var(--v-white-base) !important;
}
</style>
