<template>
  <div class="m-dashboard-widget__col-2">
    <h3>{{ $t('dashboard.widgets.ritual_engagement.title') }}</h3>
    <h4 class="mt-4">{{ $t('dashboard.widgets.ritual_engagement.total') }}</h4>
    <h2 class="text-center mt-4">{{ totalMinutes }}</h2>
    <h4 class="mt-4">{{ $t('dashboard.widgets.ritual_engagement.sessions_per_month') }}</h4>
    <h2 class="text-center mt-4">{{ perMonth }}</h2>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

export interface RitualEngagementData {
  ritual_engagement: Array<{
    month: string;
    count: number;
  }>;
}

export default Vue.extend({
  name: 'm-ritual-engagement-widget',
  props: {
    value: { type: Object as PropType<RitualEngagementData> },
  },
  data: () => ({
    total: 0,
    perMonth: 0,
  }),
  computed: {
    totalMinutes() {
      return this.total ? this.total * 60 : '-';
    },
  },
  async mounted() {
    this.total = this.value?.ritual_engagement.reduce((pV, cV) => pV + Number(cV.count ?? 0), 0) ?? 0;
    this.perMonth = Math.ceil(this.total ? this.total / this.value.ritual_engagement.length : 0);
  },
});
</script>
