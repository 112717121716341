<template>
  <m-integration-card
    :integration="discordIntegration"
    :loading="loading"
    lg
    tool="discord"
    @onConnect="connect"
    @onDisconnect="disconnect"
  >
    <m-discord-dialog v-model="open" :discordIntegration="discordIntegration" />
  </m-integration-card>
</template>

<script lang="ts">
import Vue from 'vue';
import { MDiscordDialog } from '@/components/Dialogs';
import { DeleteIntegration, TenantState } from '@/store/tenant';
import { IIntegration, IntegrationType } from '@mentessa/types';
import MIntegrationCard from '@/components/integrations/MIntegrationCard';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'm-tenant-integration-discord',
  components: {
    MIntegrationCard,
    MDiscordDialog,
  },
  data: () => ({
    open: false,
    loading: false,
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      integrations: (state: TenantState) => {
        return state.tenant.integrations;
      },
    }),
    discordIntegration() {
      return this.integrations.find((i: IIntegration) => i.type === IntegrationType.Discord);
    },
  },
  methods: {
    connect() {
      this.open = true;
    },
    async disconnect() {
      this.loading = true;
      try {
        if (!this.discordServerId) {
          await this.$store.dispatch(new DeleteIntegration(this.discordIntegration));
        }
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.m-settings-integration-card {
  width: 75%;
  padding: 24px;
  background-color: var(--v-white-base);
  border: 1px solid var(--v-beige-base);
  border-radius: 16px;
}

.logo-container {
  display: grid;
  justify-content: center;
}

.discord-logo {
  width: 50px;
  height: 50px;
  background-color: var(--v-discord-base);
}
</style>
