import { Action } from '@/store/types';
import { IResource, ResourcesPage } from '@mentessa/types';

export const moduleName = 'resources';

export interface ResourcesState {
  resources: ResourcesPage;
}

export enum ResourcesActions {
  AddResource = '[Resources] Add Resource',
  RemoveResource = '[Resources] remove Resource',
  LoadResources = '[Resources] Load Resources',
  UpdateResource = '[Resources] Update Resource',
}

export enum ResourcesMutations {
  SetResources = '[Resources] Set Resources',
  UpdateResource = '[Resources] Update Resources',
  RemoveResource = '[Resources] Remove Resources',
}

export class LoadResources implements Action {
  type = `${moduleName}/${ResourcesActions.LoadResources}`;

  constructor(private readonly page: number = 1, private readonly tags?: Array<string>) {}
}

export class AddResource implements Action {
  type = `${moduleName}/${ResourcesActions.AddResource}`;

  constructor(private readonly resource: Omit<IResource, 'id'>) {}
}

export class UpdateResource implements Action {
  type = `${moduleName}/${ResourcesActions.UpdateResource}`;

  constructor(private readonly resource: IResource) {}
}

export class RemoveResource implements Action {
  type = `${moduleName}/${ResourcesActions.RemoveResource}`;

  constructor(private readonly resource: IResource) {}
}
