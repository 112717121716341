<template>
  <v-card outlined>
    <v-card-title v-if="!edit"><img src="@/assets/rules/bulletPoint.svg" />{{ title }}</v-card-title>
    <v-card-title v-else>
      <m-text-field
        v-model="newTitle"
        :background-color="deleteConfirmation ? 'var(--v-error-lighten4)' : undefined"
        :disabled="deleteConfirmation"
        :prepend-inner-icon="prependIcon"
        autofocus
        class="flex-grow-1 mr-2"
        dense
      />
    </v-card-title>
    <v-card-text v-if="!edit">
      <p :key="description" v-snip="{ lines: isTextHidden ? 5 : 0, onSnipped }" class="keep-new-line">
        {{ description }}
      </p>
      <v-btn
        v-if="hasEllipsis || !isTextHidden"
        class="font-weight-bold"
        color="primary"
        sentry-tag="community-rule-card.button.hide-text"
        text
        @click="isTextHidden = !isTextHidden"
      >
        {{ $t(isTextHidden ? 'actions.show.more' : 'actions.show.less') }}
      </v-btn>
    </v-card-text>
    <v-card-text v-else>
      <m-text-area
        v-model="newDescription"
        :background-color="deleteConfirmation ? 'var(--v-error-lighten4)' : undefined"
        :disabled="deleteConfirmation"
        :prepend-inner-icon="prependIcon"
        autofocus
        class="flex-grow-1 mr-2"
        dense
      />
    </v-card-text>
    <div v-if="editEnabled">
      <v-card-actions v-if="!edit">
        <v-btn icon @click="enableDeleteConfirmation">
          <v-icon class="ma-2" color="error">mdi-trash-can-outline</v-icon>
        </v-btn>
        <v-btn icon @click="enableEdit">
          <v-icon class="ma-2" color="primary">mdi-pencil-outline</v-icon>
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-btn icon @click="deleteConfirmation ? deleteRule() : save()">
          <v-icon :color="deleteConfirmation ? 'error' : ''" class="ma-2">mdi-check</v-icon>
        </v-btn>
        <v-btn icon @click="close">
          <v-icon class="ma-2">mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { CommunityRule } from '@mentessa/types';
import { MTextArea, MTextField } from '@/components/Inputs';
import { CreateLocaleContent, LoadLocaleContent, LoadTranslations, UpdateLocaleContent } from '@/store/ui';
import deepmerge from 'deepmerge';
import { UpdateCommunityRules } from '@/store/communityRules';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';

export default Vue.extend({
  name: 'm-community-rule-card',
  components: { MTextArea, MTextField },
  props: {
    rule: {
      type: Object as PropType<CommunityRule>,
      required: true,
    },
    parentRule: {
      type: Object as PropType<CommunityRule>,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    editEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isTextHidden: true,
    hasEllipsis: false,
    edit: false,
    deleteConfirmation: false,
    newTitle: undefined,
    newDescription: undefined,
  }),
  mounted() {
    this.edit = this.editMode;
  },
  computed: {
    title() {
      const translationKey = `communityRules.${this.parentRule.name}.${this.rule.name}.title`;
      const translation = this.$t(translationKey);
      return translation === translationKey ? '' : translation;
    },
    description() {
      const translationKey = `communityRules.${this.parentRule.name}.${this.rule.name}.description`;
      const translation = this.$t(translationKey);
      return translation === translationKey ? '' : translation;
    },
    prependIcon() {
      return this.deleteConfirmation ? 'mdi-trash-can-outline' : '';
    },
    ...mapState<TenantState>('tenant', {
      tenantId: (state: TenantState) => state.tenant.id,
    }),
  },
  methods: {
    onSnipped(props) {
      this.hasEllipsis = props.hasEllipsis;
    },
    enableEdit() {
      this.newTitle = this.title;
      this.newDescription = this.description;
      this.edit = true;
    },
    enableDeleteConfirmation() {
      this.newTitle = this.title;
      this.newDescription = this.description;
      this.deleteConfirmation = true;
      this.edit = true;
    },
    close() {
      this.edit = false;
      this.deleteConfirmation = false;
      if (this.editMode) {
        this.$emit('updateNewRules');
      }
    },
    async deleteRule() {
      try {
        await this.$store.dispatch(
          new UpdateCommunityRules(this.parentRule, {
            rules: this.parentRule.rules.filter((rule) => rule.name !== this.rule.name),
          }),
        );
      } finally {
        this.edit = false;
        this.deleteConfirmation = false;
      }
    },
    async save() {
      try {
        if (this.newTitle || this.newDescription) {
          let success = false;
          if (this.editMode) {
            success = await this.$store.dispatch(
              new UpdateCommunityRules(this.parentRule, {
                rules: [...this.parentRule.rules, this.rule],
              }),
            );
            this.$emit('updateNewRules');
          } else {
            success = true;
          }
          if (success) {
            const content = {
              communityRules: {
                [this.parentRule.name]: {
                  [this.rule.name]: {
                    title: this.newTitle,
                    description: this.newDescription,
                  },
                },
              },
            };
            const localeContent = await this.$store.dispatch(new LoadLocaleContent('en'));
            if (localeContent) {
              const mergedLocale = deepmerge(localeContent, content);
              await this.$store.dispatch(new UpdateLocaleContent('en', mergedLocale));
            } else {
              await this.$store.dispatch(new CreateLocaleContent('en', content));
            }
            await this.$store.dispatch(new LoadTranslations('en'));
          } else {
            this.$emit('updateNewRules');
          }
        }
      } finally {
        this.edit = false;
        this.deleteConfirmation = false;
      }
    },
  },
});
</script>
