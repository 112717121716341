<template>
  <div>
    <p class="m-settings-description">{{ $t('settings.mobile.description') }}</p>
    <v-tabs v-model="tab" color="primary" show-arrows>
      <v-tab href="#settings">Settings</v-tab>
      <v-tab href="#users">Users</v-tab>
      <v-tab href="#notifications">Notifications</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="fill-width mt-4">
      <v-tab-item value="settings">
        <m-mobile-settings />
      </v-tab-item>
      <v-tab-item value="users">
        <m-mobile-users />
      </v-tab-item>
      <v-tab-item value="notifications">
        <m-mobile-notifications />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { MMobileNotifications, MMobileSettings, MMobileUsers } from './forms';

export default Vue.extend({
  name: 'm-settings-mobile',
  components: { MMobileNotifications, MMobileSettings, MMobileUsers },
  data: () => ({
    //
  }),
  computed: {
    ...mapGetters('tenant', {
      isDebugAvailable: 'isDebugAvailable',
    }),
    tab: {
      set(tab: string) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },
  },
});
</script>

<style lang="scss" scoped>
//
</style>
