<template>
  <m-dialog id="invitation-dialog" :value="show" persistent>
    <m-loader v-if="isLoading" :is-loading="true" class="m-loader" />
    <m-invitation-welcome-form v-else-if="isWelcomeForm" :invite="invite" :tenant="tenant" @onNext="next" />
    <m-invitation-name-form v-else @onNext="next" />
  </m-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import { IInvite, ITenant, RitualType } from '@mentessa/types';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';
import MLoader from '@/components/MLoader.vue';
import { RouteName } from '@/router/types';
import { MInvitationNameForm, MInvitationWelcomeForm } from './forms';
import { UsersState } from '@/store/users';
import { LoadUserRituals } from '@/store/rituals';
import { LoadInvite } from '@/store/invites';
import { UIState } from '@/store/ui';
import { MDialog } from '@/components/Dialogs/MDialog';

enum InvitationDialogState {
  Loading = 'loading',
  Welcome = 'welcome',
  Name = 'name',
}

export default Vue.extend({
  name: 'm-invitation-dialog',
  components: { MDialog, MInvitationNameForm, MInvitationWelcomeForm, MLoader },
  props: {
    inviteId: String,
  },
  data: () => ({
    loading: true,
    state: InvitationDialogState.Loading as InvitationDialogState,
    invite: undefined as IInvite,
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState): ITenant => state.tenant,
    }),
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    ...mapState<UIState>('ui', {
      dialogsBlocked: (state: UIState) => state.dialogsBlocked,
    }),
    show() {
      return !this.dialogsBlocked;
    },
    tenantTitle() {
      return this.tenant?.attributes?.theme?.title ?? '';
    },
    isLoading() {
      return this.state === InvitationDialogState.Loading;
    },
    isWelcomeForm() {
      return this.state === InvitationDialogState.Welcome;
    },
    isNameForm() {
      return this.state === InvitationDialogState.Name;
    },
  },

  async mounted() {
    await this.next();
  },
  methods: {
    async closeDialog() {
      await this.$router.replace({
        name: RouteName.ActiveMentoringSession,
        params: this.$route.params,
        query: { ...this.$route.query, feedback: undefined },
      });
    },
    async next() {
      if (this.state === InvitationDialogState.Loading) {
        this.invite = await this.$store.dispatch(new LoadInvite(this.inviteId));
        if (this.invite.ritual.type !== RitualType.HappyHour) {
          this.state = InvitationDialogState.Welcome;
        } else {
          await this.enroll();
        }
      } else if (this.state === InvitationDialogState.Welcome && !this.me.complete) {
        this.state = InvitationDialogState.Name;
      } else {
        await this.enroll();
      }
    },
    async enroll() {
      const rituals = await this.$store.dispatch(new LoadUserRituals(null, this.inviteId));
      if (rituals.length !== 1) {
        console.error(`Unexpected invitations count: ${rituals?.map((ritual) => ritual.id)}`);
        await this.$router.replace({ name: RouteName.Community });
      }
      const ritualId = rituals?.[0]?.id;
      if (ritualId) {
        await this.$router.replace({ name: RouteName.RitualEnroll, params: { ritualId } });
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.m-loader {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
