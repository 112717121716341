import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { api } from '@/store/api';
import { ShowErrorNotification, ShowNotification } from '@/store/notifications';
import i18n from '@/plugins/i18n';
import { RitualsActions, RitualsMutations, RitualsState } from '@/store/rituals/types';
import { getIdOrCurrent } from '@/utils/api';
import { AxiosRequestConfig } from 'axios';
import { GetMe } from '@/store/users';
import { IRitual } from '@mentessa/types';

export const actions: ActionTree<RitualsState, RootState> = {
  async [RitualsActions.LoadRituals]({ commit, dispatch }, { page, options }) {
    try {
      const response = await api.get('rituals', { params: { page, ...options } });
      const rituals = response?.data;
      commit(RitualsMutations.SetRituals, rituals);
    } catch (e) {
      console.error('Error while LoadRituals.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
  async [RitualsActions.LoadQuestionnaires]({ dispatch }, { page }) {
    try {
      const response = await api.get(`questionnaires?page=${page}`);
      return response?.data;
    } catch (e) {
      console.error('Error while LoadQuestionnaires.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
  async [RitualsActions.LoadRitualQuestionnaires]({ dispatch }, { ritual }) {
    try {
      const response = await api.get(`ritualQuestionnaires/${ritual.id}`);
      const questionnaires = response?.data;
      return questionnaires;
    } catch (e) {
      console.error('Error while LoadBindedQuestionnaires.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [RitualsActions.LoadRitual]({ dispatch, commit }, { ritualRef, options }) {
    try {
      const response = await api.get(`rituals/${ritualRef.id}`, { params: options });
      const ritual: IRitual = response.data;
      commit(RitualsMutations.SetCurrentRitual, ritual);
      return ritual;
    } catch (e) {
      console.error('Error while LoadRitual.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [RitualsActions.CreateRitual]({ dispatch, commit }, { ritual, templateRef }) {
    try {
      const response = await api.post('rituals', { ...ritual, template: templateRef });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      const createdRitual: IRitual = response.data;
      commit(RitualsMutations.AppendRitual, createdRitual);
      return createdRitual;
    } catch (e) {
      console.error('Error while CreateRitual.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
  async [RitualsActions.CreateQuestionnaire]({ dispatch }, { questionnaire }) {
    try {
      const response = await api.post('questionnaires', questionnaire);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response?.status === 201 ?? false;
    } catch (e) {
      console.error('Error while CreateQuestionnaire.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
  async [RitualsActions.DeleteRitual]({ dispatch }, { ritual }) {
    try {
      await api.delete(`rituals/${ritual.id}`);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
    } catch (e) {
      console.error('Error while DeleteRitual.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [RitualsActions.DeleteQuestionnaire]({ dispatch }, { questionnaire }) {
    try {
      await api.delete(`questionnaires/${questionnaire.id}`);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
    } catch (e) {
      console.error('Error while DeleteQuestionnaire.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
  async [RitualsActions.CreateRitualQuestionnaire]({ dispatch }, { ritualQuestionnaire }) {
    try {
      await api.post('ritualQuestionnaires', ritualQuestionnaire);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
    } catch (e) {
      console.error('Error while DeleteQuestionnaire.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [RitualsActions.UpdateRitualQuestionnaire]({ dispatch }, { ritualQuestionnaire }) {
    try {
      await api.put(`ritualQuestionnaires/${ritualQuestionnaire.id}`, ritualQuestionnaire);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
    } catch (e) {
      console.error('Error while DeleteQuestionnaire.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [RitualsActions.DeleteRitualQuestionnaire]({ dispatch }, { ritualQuestionnaire }) {
    try {
      await api.delete(`ritualQuestionnaires/${ritualQuestionnaire.id}`);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
    } catch (e) {
      console.error('Error while DeleteRitualQuestionnaire.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [RitualsActions.UpdateRitual]({ dispatch, commit }, { ritualRef, update }) {
    try {
      const response = await api.put(`rituals/${ritualRef.id}`, update);
      const resultRitual = response.data;
      commit(RitualsMutations.UpdateRitual, resultRitual);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return resultRitual;
    } catch (e) {
      console.error('Error while UpdateRitual.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
  async [RitualsActions.UpdateRitualIcon]({ commit, dispatch }, { blob, ritual, forceDomain }) {
    try {
      const config: AxiosRequestConfig = {};
      if (forceDomain) {
        const hostParts = window.location.host.split('.');
        hostParts[0] = forceDomain;
        config['baseURL'] = `${window.location.protocol}//${hostParts.join('.')}/api`;
      }
      const formData = new FormData();
      const ritualPrefix = ritual.id ?? forceDomain ?? 'ritual';
      let fileName = `${ritualPrefix}-icon-${new Date().toISOString()}.jpeg`;
      if (blob.name) {
        const blobNameArr = blob.name.split('.');
        const format = blobNameArr[blobNameArr.length - 1];
        fileName = `${ritualPrefix}-icon-${new Date().toISOString()}.${format}`;
      }
      formData.append('file', blob, fileName);
      const response = await api.post(`rituals/${ritual.id}/image`, formData, config);
      const url = response.data?.result;
      commit(RitualsMutations.SetRitualIcon, { url, ritual });
      return url;
    } catch (e) {
      console.log('Error while UpdateRitualIcon', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [RitualsActions.UpdateQuestionnaire]({ dispatch }, { questionnaire }) {
    try {
      const response = await api.put(`questionnaires/${questionnaire.id}`, questionnaire);
      const resultQuestionnaire = response.data;
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return resultQuestionnaire;
    } catch (e) {
      console.error('Error while UpdateQuestionnaire.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
  async [RitualsActions.ExecuteRitual]({ dispatch }, { ritual }) {
    try {
      await api.post(`rituals/${ritual.id}/execute`);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
    } catch (e) {
      console.error('Error while ExecuteRitual.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [RitualsActions.LoadRitualInvitation]({ dispatch }, { ritual }) {
    try {
      const response = await api.get(`rituals/${ritual.id}/invite`);
      return response.data;
    } catch (e) {
      console.error('Error while LoadRitualInvitation.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [RitualsActions.LoadRitualMembers](
    { dispatch },
    { ritual, page, query, status, role, matched, sortBy, sortOrder },
  ) {
    try {
      const response = await api.get(`rituals/${ritual.id}/members`, {
        params: {
          page,
          query,
          status,
          role,
          matched,
          sortBy,
          sortOrder,
        },
      });
      return response.data ?? [];
    } catch (e) {
      console.error('Error while LoadRitualMembers.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [RitualsActions.LoadRitualMemberDetails]({ dispatch }, { ritualRef, ritualUserRef }) {
    try {
      const response = await api.get(`rituals/${ritualRef.id}/members/${ritualUserRef.id}`);
      return response.data ?? [];
    } catch (e) {
      console.error('Error while LoadRitualMemberDetails.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [RitualsActions.ExportRitualMembers]({ dispatch }, { ritual, query, status, role, matched }) {
    try {
      const response = await api.get(`rituals/${ritual.id}/members/export`, {
        params: {
          query,
          status,
          role,
          matched,
        },
        responseType: 'blob',
      });
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      const fileName = response.headers['content-disposition']?.split('"')?.[1] ?? 'program_members.csv';
      link.setAttribute('download', fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (e) {
      console.error('Error while ExportRitualMembers.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [RitualsActions.ExportRitualAnswers]({ dispatch }, { ritual, query, status, role, matched }) {
    try {
      const response = await api.get(`rituals/${ritual.id}/members/export_answers`, {
        params: {
          query,
          status,
          role,
          matched,
        },
        responseType: 'blob',
      });
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      const fileName = response.headers['content-disposition']?.split('"')?.[1] ?? 'program_member_answers.csv';
      link.setAttribute('download', fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (e) {
      console.error('Error while ExportRitualAnswers.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [RitualsActions.SendQuestionnaireAnswers]({ dispatch }, { ritual, ritualQuestionnaire, answers, user }) {
    try {
      await api.post(
        `users/${getIdOrCurrent(user)}/rituals/${ritual.id}/questionnaire/${ritualQuestionnaire.id}/answers`,
        answers,
      );
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      await dispatch(new GetMe(), { root: true });
    } catch (e) {
      console.error('Error while SendQuestionnaireAnswers.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [RitualsActions.LoadRitualResults]({ dispatch }, { ritual, user }) {
    try {
      const response = await api.get(`users/${getIdOrCurrent(user)}/rituals/${ritual.id}/results`);
      return response.data;
    } catch (e) {
      console.error('Error while LoadRitualResults.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [RitualsActions.CheckRitualComplete]({ dispatch }, { ritual, user }) {
    try {
      const response = await api.get(`users/${getIdOrCurrent(user)}/rituals/${ritual.id}/complete`);
      return response.data?.result ?? false;
    } catch (e) {
      console.error('Error while CheckRitualComplete.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [RitualsActions.LoadRitualMatches]({ dispatch }, { ritualRef, page, options }) {
    try {
      const url = `rituals/${ritualRef.id}/matches`;
      const response = await api.get(url, { params: { page, ...options } });
      return response.data;
    } catch (e) {
      console.error('Error while LoadRitualMatches.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [RitualsActions.EnrollToRitual]({ dispatch, commit }, { ritual, user }) {
    try {
      const response = await api.post(`users/${getIdOrCurrent(user)}/rituals/${ritual.id}/enroll`);
      commit(RitualsMutations.AddMember, { ritualRef: ritual, member: response.data });
      return true;
    } catch (e) {
      console.error('Error while EnrollToRitual.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
    return false;
  },
  async [RitualsActions.EnrollDemoUsersToRitual]({ dispatch }, { ritual, count }) {
    try {
      const response = await api.post(`rituals/${ritual.id}/enrollDemoUsers`, { count });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.error('Error while EnrollDemoUsersToRitual.', e);
      await dispatch(new ShowErrorNotification(e.response.data.message), {
        root: true,
      });
    }
    return false;
  },
  async [RitualsActions.UnenrollFromRitual]({ dispatch, commit, rootState }, { ritual, user }) {
    try {
      await api.post(`users/${getIdOrCurrent(user)}/rituals/${ritual.id}/unenroll`);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      commit(RitualsMutations.RemoveMember, { ritualRef: ritual, userRef: user ?? rootState.users.me });
      return true;
    } catch (e) {
      console.error('Error while UnenrollFromRitual.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
    return false;
  },

  async [RitualsActions.LoadRitualQuestionnaire]({ dispatch }, { ritualRef, role, user }) {
    try {
      const response = await api.get(`users/${getIdOrCurrent(user)}/rituals/${ritualRef.id}/questionnaire`, {
        params: { role },
      });
      return response.data;
    } catch (e) {
      if (e.response?.status !== 404) {
        console.error('Error while LoadRitualQuestionnaire.', e);
        await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
          root: true,
        });
      }
    }
  },

  async [RitualsActions.ChangeRitualUserAcceptStatus]({ dispatch }, { ritualRef, ritualUserRef, status }) {
    try {
      await api.put(`rituals/${ritualRef.id}/members/${ritualUserRef.id}/accepted`, { status });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return true;
    } catch (e) {
      console.error('Error while ChangeRitualUserAcceptStatus.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [RitualsActions.ChangeRitualUsersAcceptStatus]({ dispatch }, { ritualRef, ritualUserIds, status }) {
    try {
      await api.put(`rituals/${ritualRef.id}/members/selected/accepted`, { status, ids: ritualUserIds });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return true;
    } catch (e) {
      console.error('Error while ChangeRitualUsersAcceptStatus.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [RitualsActions.ChangeAllRitualUsersAcceptStatus]({ dispatch }, { ritualRef, status }) {
    try {
      await api.put(`rituals/${ritualRef.id}/members/all/accepted`, { status });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return true;
    } catch (e) {
      console.error('Error while ChangeAllRitualUsersAcceptStatus.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [RitualsActions.ChangeRitualMatchAcceptStatus]({ dispatch }, { ritualRef, matchRef, status, userRef }) {
    try {
      const response = await api.put(
        `users/${getIdOrCurrent(userRef)}/rituals/${ritualRef.id}/matches/${matchRef.id}/accepted`,
        {
          status,
        },
      );
      return response.data;
    } catch (e) {
      console.error('Error while ChangeRitualMatchAcceptStatus.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [RitualsActions.LoadRitualData]({ dispatch }, { ritualRef, options }) {
    try {
      const response = await api.get(`rituals/${ritualRef.id}/export`, { params: options });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.error('Error while LoadRitualData.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [RitualsActions.UploadRitualResults]({ dispatch }, { ritualRef, matches, withoutMatch, rematch }) {
    try {
      const response = await api.post(`rituals/${ritualRef.id}/upload`, { matches, withoutMatch, rematch });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.error('Error while UploadRitualResults.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [RitualsActions.RemoveFromRitual]({ dispatch }, { ritualRef, memberRef }) {
    try {
      const response = await api.delete(`rituals/${ritualRef.id}/members/${memberRef.id}`);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.error('Error while RemoveFromRitual.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [RitualsActions.ResendMatches]({ dispatch }, { ritualRef }) {
    try {
      const response = await api.post(`rituals/${ritualRef.id}/resend`);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.error('Error while ResendMatches.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [RitualsActions.ClosePendingMatches]({ dispatch }, { ritualRef }) {
    try {
      const response = await api.post(`rituals/${ritualRef.id}/close_pending_matches`);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.error('Error while ClosePendingMatches.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [RitualsActions.InviteAllToARitual]({ dispatch }, { ritualRef, isReminder }) {
    try {
      const response = await api.post(`rituals/${ritualRef.id}/invite_all`, { isReminder });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.error('Error while InviteAllToARitual.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [RitualsActions.LoadUserRituals]({ dispatch }, { userRef, status, inviteGUID }) {
    try {
      const response = await api.get(`users/${getIdOrCurrent(userRef)}/rituals`, {
        params: { status, invite: inviteGUID },
      });
      return response.data;
    } catch (e) {
      console.error('Error while LoadUserRituals.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [RitualsActions.LoadManagedRituals]({ dispatch }, { userRef }) {
    try {
      const response = await api.get(`users/${getIdOrCurrent(userRef)}/rituals/managed`);
      return response.data;
    } catch (e) {
      console.error('Error while LoadManagedRituals.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [RitualsActions.LoadRitualManagedMembers]({ dispatch }, { ritualRef, page, userRef }) {
    try {
      const response = await api.get(`users/${getIdOrCurrent(userRef)}/rituals/managed/${ritualRef.id}/members`, {
        params: { page },
      });
      return response.data;
    } catch (e) {
      console.error('Error while LoadRitualManagedMembers.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [RitualsActions.ChangeRitualManagedUserAcceptStatus](
    { dispatch },
    { ritualRef, ritualUserRef, status, userRef },
  ) {
    try {
      await api.put(
        `users/${getIdOrCurrent(userRef)}/rituals/managed/${ritualRef.id}/members/${ritualUserRef.id}/accepted`,
        { status },
      );
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return true;
    } catch (e) {
      console.error('Error while ChangeRitualManagedUserAcceptStatus.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
};
