<template>
  <m-panel v-if="true">
    <v-row class="justify-center">
      <v-col>
        <m-user-preview :user="user" big bold is-session job location max-width=""></m-user-preview>
        <a v-if="canCancel" class="primary--text" @click="showCancelDialog = true">
          {{ $t('appointmentCard.links.cancelSession') }}
        </a>
      </v-col>
      <v-col class="d-flex flex-column justify-center align-center" style="min-width: 200px; max-width: 200px">
        <v-icon>mdi-calendar-blank-outline</v-icon>
        <span>{{ formattedDate }}</span>
      </v-col>
    </v-row>
    <m-cancel-appointment-dialog
      v-model="showCancelDialog"
      :appointment="appointment"
      :date="formattedDate"
      :mentoring-session="session"
      :session="session"
      :targetUser="user"
    />
  </m-panel>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { AppointmentStatus, IAppointment, IMentoringSession, IUser } from '@mentessa/types';
import MUserPreview from '@/components/MUserPreview';
import { mapGetters } from 'vuex';
import { MCancelAppointmentDialog } from '@/components/Mentoring/dialogs';
import { isBefore, parseISO } from 'date-fns';
import MPanel from '@/components/MPanel';

export default Vue.extend({
  name: 'm-appointment-card',
  components: { MPanel, MCancelAppointmentDialog, MUserPreview },
  props: {
    session: { type: Object as PropType<IMentoringSession> },
    appointment: { type: Object as PropType<IAppointment> },
    user: { type: Object as PropType<IUser> },
  },
  data: () => ({
    showCancelDialog: false,
  }),
  computed: {
    ...mapGetters('ui', {
      formatInUserTimeZone: 'formatInUserTimeZone',
    }),
    formattedDate() {
      if (this.appointment?.date) {
        return this.formatInUserTimeZone(this.appointment.date, 'MMMM d, yyyy H:mm');
      }
      return '';
    },
    formattedTime() {
      if (this.appointment?.date) {
        return this.formatInUserTimeZone(this.appointment.date, 'H:mm');
      }
      return '';
    },
    canCancel() {
      return !this.cancelled && !this.finished;
    },
    finished() {
      if (!this.appointment) {
        return true;
      }
      if (this.appointment.status === AppointmentStatus.Finished) {
        return true;
      }
      const appointmentDate: string = this.appointment.date ?? this.appointment.slot?.date;
      if (!appointmentDate) {
        return true;
      }
      return isBefore(parseISO(appointmentDate), new Date());
    },
    cancelled() {
      return this.appointment?.status === AppointmentStatus.Canceled ?? true;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.m-appointment-card {
  min-height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid var(--v-lightgray-base);
  padding: 24px;
  border-radius: 6px;
  background: var(--v-white-base);
  box-shadow: 0 2px 4px 0 var(--v-lightgray-base);

  @media #{map-get($display-breakpoints, 'xs-only')} {
    flex-direction: column;
    gap: 30px;
  }

  &__actions {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      justify-content: center;
    }
  }

  &__date {
    min-width: 250px;
    color: var(--v-textlight-base);
  }

  &__action-links {
    padding-left: 70px;
  }
}
</style>
