<template>
  <div class="d-flex justify-center align-center flex-column">
    <m-chip-group
      v-model="selected"
      :items="items"
      :multiple="false"
      class="mt-4"
      i18n-path="onboarding.users_count.items"
      validation-rules="required"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { MChipGroup } from '@/components/Inputs';

export default Vue.extend({
  name: 'm-tenant-onboarding-users-count-form',
  components: { MChipGroup },
  props: {
    value: { type: String },
  },
  data: () => ({
    items: ['30', '100', '250', '1000', '2500', 'more'],
  }),
  computed: {
    selected: {
      get() {
        return this.value ? [this.items.indexOf(this.value)] : undefined;
      },
      set(value: number) {
        this.$emit('input', this.items[value]);
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.m-color-preview {
  width: 200px;
  height: 200px;
  border-radius: 7px;
  background-color: var(--background-color);
  cursor: pointer;
}
</style>
