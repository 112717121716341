<template>
  <v-container>
    <v-btn @click="reloadRules">Reload rules</v-btn>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'm-settings-debug-casbin',
  data: () => ({}),
  methods: {
    reloadRules() {
      // ToDo: TBD
    },
  },
});
</script>
