<template>
  <v-alert
    v-if="persistentNotification.isOpened"
    class="m-persistent-notification"
    outlined
    icon="mdi-alert-circle-outline"
    sentry-tag="persistent-notification"
  >
    <div class="m-persistent-notification__header d-flex align-center">
      <h5>{{ persistentNotification.title }}</h5>
      <v-icon
        @click="closePersistentNotification"
        class="m-persistent-notification__close-button ml-auto"
        sentry-tag="persistent-notification.button.close"
      >
        mdi-close
      </v-icon>
    </div>
    <div class="m-persistent-notification__body mt-2">
      {{ persistentNotification.text }}
    </div>
    <div class="m-persistent-notification__footer mt-4">
      <router-link
        v-if="persistentNotification.link"
        :to="persistentNotification.link.url"
        class="font-weight-bold text-decoration-none"
        sentry-tag="persistent-notification.link"
      >
        {{ persistentNotification.link.text }}
      </router-link>
    </div>
  </v-alert>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapState } from 'vuex';
import { HidePersistentNotification, NotificationsState } from '@/store/notifications';

export default Vue.extend({
  name: 'MPersistentNotification',
  computed: {
    ...mapState<NotificationsState>('notifications', {
      persistentNotification: (state: NotificationsState) => state.persistentNotification,
    }),
  },
  methods: {
    closePersistentNotification() {
      this.$store.dispatch(new HidePersistentNotification());
    },
  },
});
</script>

<style lang="scss" scoped>
.m-persistent-notification {
  border: 1px solid var(--v-lightgray-base) !important;

  &__body {
    font-size: 14px;
  }

  &__footer {
    font-size: 14px;
  }

  &__close-button {
    cursor: pointer;
  }
}
</style>
