<template>
  <div>
    <v-switch v-model="enabled" :label="enabled ? 'Enabled' : 'Disabled'" :disabled="isLoading" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { ChangeFeatureEnabledState, TenantState } from '@/store/tenant';
import { Features } from '@mentessa/types';

export default Vue.extend({
  name: 'm-feature-enable-switch',
  props: {
    feature: { type: String as PropType<Features>, default: undefined },
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    ...mapGetters('tenant', {
      featureConfig: 'featureConfig',
    }),
    targetFeature() {
      return this.featureConfig(this.feature);
    },
    enabled: {
      get() {
        return this.targetFeature.enabled;
      },
      async set(state: boolean) {
        if (state !== this.targetFeature.enabled) {
          try {
            this.isLoading = true;
            await this.$store.dispatch(new ChangeFeatureEnabledState(this.feature, state));
            if (state) this.$emit('enabled');
          } finally {
            this.isLoading = false;
          }
        }
      },
    },
  },
});
</script>
