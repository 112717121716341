import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import icons from '@/Icons/icons';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    thresholds: {
      md: 1482,
    },
  },
  theme: {
    themes: {
      light: {
        primary: '#6F1DCD',
        secondary: '#75C7FB',
        text: '#1B1F26',
        textlight: '#78716c',
        lightbg: '#F1F1F1',
        accent: '#F5BC51',
        error: '#EA473B',
        info: '#2196F3',
        success: '#1CB03C',
        warning: '#E6FD5A',
        // background: '#FFFFFF',
        // background: '#FAFAF9',
        // background: '#e8ddd9',
        lightgray: '#D1D5DB',
        white: '#FFFFFF',
        rating: '#F4B740',

        // rebranding common colors
        banner: '#f8d6fc',
        gray: '#666666',
        beige: '#e8ddd9',
        button: '#000000',
        discord: '#5865f2',
      },
      dark: false,
    },
    options: { customProperties: true },
  },
  icons: icons,
});
