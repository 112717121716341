<template>
  <v-dialog :value="value" max-width="574" sentry-tag="delete-member-dialog" @input="$emit('input', $event)">
    <v-sheet class="pa-14">
      <div class="d-flex flex-column justify-center">
        <h2 class="text-center">{{ $t('settings.members.deleteDialog.title') }}</h2>
        <p class="text-center text--secondary">
          {{ $t('settings.members.deleteDialog.question', { fullName }) }}
        </p>
        <v-text-field
          v-model="deletionMessage"
          :placeholder="$t('settings.members.deleteDialog.placeholder')"
          autofocus
          outlined
          @keydown.enter.prevent="deleteUser"
        />
        <div class="d-flex justify-space-between">
          <v-btn depressed sentry-tag="delete-member-dialog.button.close" @click="closeDialog">
            {{ $t('settings.members.deleteDialog.cancel') }}
          </v-btn>
          <v-btn
            :disabled="deletionMessage !== 'DELETE'"
            :loading="loading"
            color="error"
            depressed
            sentry-tag="delete-member-dialog.button.delete"
            @click="deleteUser"
          >
            {{ $t('settings.members.deleteDialog.confirmDeletion') }}
          </v-btn>
        </div>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { UserEntity } from '@mentessa/types';
import { getFullName } from '@/utils/user';
import { DeleteUser } from '@/store/users';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'm-delete-member-dialog',
  props: {
    value: Boolean,
    user: Object as PropType<UserEntity>,
  },
  data: () => ({
    deletionMessage: '',
    loading: false,
  }),
  computed: {
    ...mapGetters('tenant', {
      logoutUrl: 'logoutUrl',
    }),
    fullName() {
      if (this.user == null) {
        return this.$t('user.profile');
      }
      return getFullName(this.user);
    },
  },
  methods: {
    async deleteUser() {
      const isSelf = this.user == null;
      await this.$store.dispatch(new DeleteUser(this.user));
      this.deletionMessage = '';
      this.$emit('member-deleted');
      if (isSelf) {
        this.$keycloak.keycloak.logout({ redirectUri: this.logoutUrl });
      }
    },
    closeDialog() {
      this.$emit('input', false);
      this.deletionMessage = '';
    },
  },
});
</script>
