<template>
  <v-container>
    <h4 class="ma-0">{{ label }}</h4>
    <v-chip-group column style="min-height: 40px">
      <v-chip
        v-for="(tag, index) in value"
        :key="`tag-${index}`"
        close
        @click:close="removeTag(index)"
        color="beige"
        small
      >
        {{ tag.name }}
      </v-chip>
    </v-chip-group>
    <m-text-field
      v-model="search"
      class="v-loading"
      placeholder="Find more..."
      @input="searchForItems($event)"
      @keydown.enter="addNewTag"
      dense
    />
    <template v-if="items.length > 0">
      <v-chip-group class="ma-4 mt-0" column>
        <v-chip v-for="(tag, index) in filteredItems" :key="`tag-${index}`" @click="addExistingTag(tag)" small>
          {{ tag.name }}
        </v-chip>
      </v-chip-group>
    </template>
    <div v-else class="ma-4">
      <v-btn color="button" depressed @click="addNewTag">Create the "{{ search }}"</v-btn>
    </div>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import { FindTags } from '@/store/tenant';
import { ITag, TagEntity } from '@mentessa/types';
import { MTextField } from '@/components/Inputs';

// ToDo: This component is just a concept, and should be completed before it will be ready to use.
export default Vue.extend({
  name: 'm-skills-selector',
  components: {
    MTextField,
  },
  props: {
    debounce: { type: Number, default: 500 },
    value: { type: Array },
    label: [String, Object /*as PropType<TranslateResult>*/],
  },
  data: () => ({
    items: [],
    loading: false,
    searchTimer: undefined,
    previousSearch: undefined,
    search: '',
  }),
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(tags: Array<TagEntity | string>[]) {
        const newTags = tags
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((tag: string | Record<string, any>) => (typeof tag === 'string' ? { name: tag.trim() } : tag))
          .filter((tag, index, self) => self.findIndex((t) => t.name === tag.name) === index); // ToDo: Implement this in the proper way

        this.$emit('input', newTags);
      },
    },
    filteredItems() {
      return this.items
        .filter((tag) => this.value.every((cT) => cT.name.toLowerCase() != tag.name.toLowerCase()))
        .slice(0, 7);
    },
  },
  async mounted() {
    await this.loadTags();
  },
  methods: {
    async loadTags(query?: string) {
      try {
        this.loading = true;
        const tags: Array<ITag> = await this.$store.dispatch(new FindTags(query));
        if (tags) {
          this.items = tags;
        }
      } finally {
        this.loading = false;
      }
    },
    async searchForItems(value: string) {
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(async () => {
        if (this.previousSearch !== value) {
          this.previousSearch = value;
          await this.loadTags(value);
        }
      }, this.debounce);
    },
    async removeTag(index: number) {
      this.$emit('input', [...this.value.slice(0, index), ...this.value.slice(index + 1)]);
    },
    async addExistingTag(tag: ITag) {
      this.$emit('input', [...this.value, tag]);
      this.search = '';
    },
    async addNewTag() {
      const name = this.search.trim();
      if (name.length === 0) {
        return;
      }
      const existingTag = this.items.find((tag) => tag.name.toLowerCase() === name.toLowerCase());
      if (existingTag) {
        await this.addExistingTag(existingTag);
      } else {
        this.$emit('input', [...this.value, { name }]);
      }
      this.search = '';
    },
  },
});
</script>
