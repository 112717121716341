<template>
  <iframe
    v-if="isVideoFocused && isYoutube"
    :src="iframeSrc"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
    height="420"
    width="100%"
  ></iframe>
  <video v-else-if="isVideoFocused" autoplay class="fill-width m-video-wrapper" controls controlsList="nodownload">
    <source :src="video.url" type="video/mp4" />
  </video>
  <v-img v-else ref="thumbnail" :src="thumbnailUrl" class="m-video-wrapper" contain @load="changeQuality">
    <div class="m-video-thumbnail-overlay d-flex justify-center align-center">
      <v-btn color="primary" fab x-large @click="watchVideo">
        <v-icon>mdi-play</v-icon>
      </v-btn>
    </div>
  </v-img>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IVideo, VideoType } from '@mentessa/types';

const youtubePattern = /(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/|\/shorts\/)/;

export default Vue.extend({
  name: 'm-video',
  props: {
    video: { type: Object as PropType<IVideo>, default: () => ({}) },
    focusedVideo: { type: Object as PropType<IVideo>, default: () => ({}) },
  },
  data: () => ({
    currentQuality: 'maxresdefault',
    availableQualities: ['maxresdefault', 'hqdefault', 'sddefault', 'mqdefault', 'default'],
  }),
  computed: {
    isYoutube() {
      return this.video?.type === VideoType.YouTube;
    },
    isVideoFocused() {
      return this.video?.id === this.focusedVideo?.id;
    },
    thumbnailUrl() {
      if (this.video?.attributes?.preview != null) {
        return this.video.attributes.preview;
      }
      if (this.isYoutube) {
        const url = this.video?.url.split(youtubePattern);
        const videoId = url[2] !== undefined ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0];
        return `https://img.youtube.com/vi/${videoId}/${this.currentQuality}.jpg`;
      }

      return '';
    },
    iframeSrc() {
      const youtubeEmbedTemplate = 'https://www.youtube.com/embed/';
      const url = this.video.url.split(youtubePattern);
      const youtubeId = undefined !== url[2] ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0];
      return `${youtubeEmbedTemplate}${youtubeId}?autoplay=1`;
    },
  },
  methods: {
    watchVideo() {
      this.$emit('onFocus', this.video);
    },
    changeQuality() {
      // Note: https://stackoverflow.com/questions/23834025/broken-youtube-thumbnails-do-not-fire-error-callback
      const isYoutubeFallbackImg =
        this.$refs.thumbnail.image.naturalHeight === 90 && this.$refs.thumbnail.image.naturalWidth === 120;
      const index = this.availableQualities.indexOf(this.currentQuality);
      if (isYoutubeFallbackImg && index < this.availableQualities.length - 1) {
        this.currentQuality = this.availableQualities[index + 1];
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.m-video-thumbnail-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
}

.m-video-wrapper {
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
</style>
