<template>
  <div :class="textColor && `${textColor}--text`" class="py-1 mb-0" sentry-tag="text-message" v-html="text" />
</template>

<script lang="ts">
import Vue from 'vue';
import { linkifyHtml } from '@/utils/linkify';

export default Vue.extend({
  name: 'm-text-message',
  props: {
    message: {
      required: true,
      type: Object,
    },
    textColor: String,
  },
  computed: {
    text() {
      return this.$marked(this.linkify(this.message.text));
    },
  },
  methods: {
    linkify: linkifyHtml,
  },
});
</script>
