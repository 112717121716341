<template>
  <div class="pa-4">
    <div class="d-flex justify-end">
      <v-btn :disabled="newTemplate != null" class="ml-auto" color="button" @click="addNew">Add new template</v-btn>
    </div>

    <div class="d-flex flex-wrap gap-4 pt-4">
      <m-ritual-template-edit-card
        v-if="newTemplate"
        :questionnaires="questionnaires"
        :ritual-template="newTemplate"
        @onCancel="removeNewTemplate"
      />
      <m-ritual-template-edit-card
        v-for="(template, index) in templates"
        :key="`template-${index}`"
        :questionnaires="questionnaires"
        :ritual-template="template"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';
import { AssetIcon, IQuestionnaire, IRitualTemplate, RitualType, TenantEntity } from '@mentessa/types';
import { LoadRawRitualTemplates, RitualTemplatesState } from '@/store/ritualTemplates';
import { LoadQuestionnaires } from '@/store/rituals';
import MRitualTemplateEditCard from '@/components/Cards/MRitualTemplateEditCard/MRitualTemplateEditCard.vue';

export default Vue.extend({
  name: 'm-settings-debug-ritual-templates',
  components: { MRitualTemplateEditCard },
  data: () => ({
    nps: {
      id: undefined,
      enabled: undefined,
      interval: 10,
    },
    loading: false,

    questionnaires: [] as Array<IQuestionnaire>,
    newTemplate: undefined,
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState): TenantEntity => state.tenant,
    }),
    ...mapState<RitualTemplatesState>('ritualTemplates', {
      templates: (state: RitualTemplatesState): Array<IRitualTemplate> => state.rawRitualTemplates ?? [],
    }),
  },
  watch: {
    templates() {
      this.newTemplate = undefined;
    },
  },
  async mounted() {
    try {
      this.loading = true;
      await this.$store.dispatch(new LoadRawRitualTemplates());
      const { items } = await this.$store.dispatch(new LoadQuestionnaires());
      this.questionnaires = items;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    addNew() {
      this.newTemplate = {
        id: undefined,
        type: RitualType.SkillsMatching,
        ritualAttributes: {
          title: undefined,
          description: undefined,
          acceptanceRule: undefined,
          assetIcon: AssetIcon.Diamond,
          monthlyFeedback: {
            enabled: false,
            reminderEnabled: false,
          },
          roles: {
            choose: false,
            any: false,
          },
        },
        ritualTemplateQuestionnaires: [],
        ritualTemplateFeedbackQuestionnaires: [],
        createLink: false,
        precreate: false,
      };
    },
    removeNewTemplate() {
      this.newTemplate = undefined;
    },
  },
});
</script>

<style lang="scss" scoped>
.m-interval-field {
  max-width: 140px;
}
</style>
