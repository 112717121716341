<template>
  <m-panel class="d-flex flex-row flex-wrap align-center justify-center gap-2 fill-width" max-width="480px">
    <m-tool-preview v-if="tool" :lg="lg" :name="tool" :sm="sm" bold />
    <div v-else class="d-flex align-center justify-center gap-2">
      <v-img :height="imageSize" :src="image" :width="imageSize" contain></v-img>
      <h3>{{ title }}</h3>
    </div>
    <v-btn v-if="sales" class="ml-auto" color="button" depressed href="mailto:sales@mentessa.com" outlined
      >{{ $t('settings.integrations.contact_sales') }}
    </v-btn>
    <v-btn
      v-else
      :disabled="disabled"
      :loading="loading"
      :outlined="enabled"
      class="ml-auto"
      color="button"
      depressed
      @click="doAction"
    >
      {{ enabled ? $t('settings.integrations.disconnect') : $t('settings.integrations.connect') }}
    </v-btn>
    <div v-show="false">
      <slot />
    </div>
  </m-panel>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { mapState } from 'vuex';
import { UsersState } from '@/store/users';
import MPanel from '@/components/MPanel';
import { IIntegration } from '@mentessa/types';
import MToolPreview from '@/components/MToolPreview/MToolPreview.vue';

export default Vue.extend({
  name: 'm-integration-card',
  props: {
    tool: String,
    image: String,
    title: [String, Object],
    integration: { type: Object as PropType<IIntegration> },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    sales: { type: Boolean, default: false },
    sm: { type: Boolean, default: false },
    lg: { type: Boolean, default: false },
  },
  components: { MToolPreview, MPanel },
  data: () => ({}),
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    enabled() {
      return this.integration?.enabled ?? false;
    },
    imageSize() {
      if (this.lg) {
        return 32;
      } else if (this.sm) {
        return 16;
      }

      return 24;
    },
  },
  methods: {
    doAction() {
      if (this.enabled) {
        this.$emit('onDisconnect');
      } else {
        this.$emit('onConnect');
      }
    },
  },
});
</script>

<style lang="scss" scoped></style>
