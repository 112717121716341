<template>
  <div class="text-center m-invite-container mx-auto">
    <div class="d-flex align-center flex-column">
      <v-img :src="image" contain max-height="128" max-width="128" class="my-10" />
      <h1 class="text--text">{{ title }}</h1>
      <h2 v-if="false" class="text--text">{{ date }}</h2>
    </div>
    <template v-if="invite">
      <h4 class="text--text">{{ $t('qr_code.scan_qr_code') }}</h4>
      <qrcode-vue :size="200" :value="link" />
      <h4 v-if="false" class="text--text">{{ $t('qr_code.matching_closes', { time: dueTime }) }}</h4>
      <v-row class="justify-center">
        {{ link }}
        <a class="ml-4" @click="copyToClipboard">
          <v-icon>mdi-content-copy</v-icon>
        </a>
      </v-row>
    </template>
    <p v-else>{{ $t('qr_code.not_available') }}</p>

    <div class="d-flex align-center flex-column pb-4">
      <h5 class="text--text mb-2">{{ $t('qr_code.powered_by') }}</h5>
      <v-img max-width="96" src="@/assets/logo-mentessa.svg" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';
import QrcodeVue from 'qrcode.vue';
import { MatchingState } from '@/store/matching';
import { RitualsState } from '@/store/rituals';
import { dateWithDayOfWeek } from '@/utils/date';
import { parseISO } from 'date-fns';
import { RitualScheduleOptions } from '@mentessa/types';
import { formatInTimeZone } from 'date-fns-tz';
import { RouteName } from '@/router/types';
import { copy } from '@/utils/clipboard';
import { ShowNotification } from '@/store/notifications';
import i18n from '@/plugins/i18n';

export default Vue.extend({
  name: 'm-ritual-invite',
  components: {
    QrcodeVue,
  },
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    ...mapState<MatchingState>('rituals', {
      ritual: (state: RitualsState) => state.current,
    }),
    image() {
      if (this.tenant?.attributes.theme.image) {
        return this.tenant?.attributes.theme.image;
      }
      return require('@/assets/logo.svg');
    },
    date() {
      const schedule: RitualScheduleOptions = this.ritual?.attributes?.schedule;
      return schedule?.options?.once?.date ? dateWithDayOfWeek(parseISO(schedule.options.once.date.toString())) : '-';
    },
    link() {
      return this.invite ? `${window.origin}/invites/${this.invite.guid}` : undefined;
    },
    dueTime() {
      const schedule: RitualScheduleOptions = this.ritual?.attributes?.schedule;
      if (!schedule?.options?.once?.date) {
        return '';
      }
      const date = parseISO(schedule.options.once.date.toString());
      return formatInTimeZone(date, 'CET', 'h:mm a');
    },
    title() {
      if (!this.ritual) {
        return this.$t('settings.ritualEnroll.listTitle');
      }
      return this.ritual.attributes?.title ?? this.$t(`settings.rituals.knownNames.${this.ritual.type}`);
    },
    invite() {
      return this.ritual?.invites?.[0];
    },
  },
  methods: {
    goBack() {
      this.$router.replace({
        name: RouteName.SettingsRituals,
        params: this.$route.params,
        query: this.$route.query,
      });
    },
    async copyToClipboard() {
      if (this.invite) {
        const link = `${window.origin}/invites/${this.invite.guid}`;
        await copy(link);
        await this.$store.dispatch(new ShowNotification(i18n.t('notifications.ok').toString()));
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.m-invite-container {
  position: relative;
  max-width: 800px;
  gap: 32px;
  justify-content: center;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
}
</style>
