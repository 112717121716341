<template>
  <div class="d-flex flex-column m-dashboard-widget__col-3 m-dashboard-widget__row-2">
    <div class="d-flex justify-space-between">
      <h4>{{ $t('dashboard.widgets.ritual_unmatched_members.title') }}</h4>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-if="results.length" icon v-bind="attrs" @click="download" v-on="on">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('dashboard.widgets.ritual_unmatched_members.downloadTooltip') }}</span>
      </v-tooltip>
    </div>
    <div class="flex-grow-1 overflow-auto">
      <v-simple-table v-if="results.length > 0">
        <thead>
          <tr class="text-left valign-top">
            <th v-for="(header, index) in headers" :key="`header-${index}}`">
              {{ $t(`dashboard.widgets.ritual_unmatched_members.table.header.${header}`) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(result, index) in results" :key="`row-${index}`">
            <td>
              <m-user-preview :user="result.member" />
            </td>
            <td>{{ $t(`dashboard.widgets.ritual_unmatched_members.status.${result.status}`) }}</td>
            <td>{{ result.date }}</td>
          </tr>
        </tbody>
      </v-simple-table>
      <div v-else class="d-flex justify-center align-center fill-height">
        {{ $t(`dashboard.no_data`) }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IUser, RitualUserStatus, UserIdentityAttributes } from '@mentessa/types';
import MUserPreview from '@/components/MUserPreview';
import { mapGetters } from 'vuex';
import { downloadFile } from '@/utils/file';
import { buildCSV } from '@/utils/csv';

export interface RitualUnmatchedMembersRowBase {
  status: RitualUserStatus;
  date: string;
}

export interface RitualUnmatchedMembersRowExternal extends RitualUnmatchedMembersRowBase {
  userId: string;
  userIdentity: UserIdentityAttributes;
}

export interface RitualUnmatchedMembersRowInternal extends RitualUnmatchedMembersRowBase {
  member: Partial<IUser>;
}

export interface RitualUnmatchedMembersData {
  ritual_unmatched_members: Array<RitualUnmatchedMembersRowExternal>;
}

export default Vue.extend({
  name: 'm-ritual-not-matched-widget',
  components: { MUserPreview },
  props: {
    value: { type: Object as PropType<RitualUnmatchedMembersData> },
  },
  data: () => ({
    results: [] as Array<RitualUnmatchedMembersRowInternal>,
  }),
  computed: {
    ...mapGetters('ui', {
      formatInUserTimeZone: 'formatInUserTimeZone',
    }),
    headers() {
      return ['member', 'status', 'date'];
    },
  },
  async mounted() {
    this.results =
      this.value?.ritual_unmatched_members?.map((row) => ({
        status: row.status,
        date: this.formatInUserTimeZone(row.date, 'dd.MM.yyyy'),
        member: { id: row.userId, identity: { attributes: row.userIdentity } },
      })) ?? [];
  },
  methods: {
    download() {
      const content = buildCSV(
        this.headers.join(','),
        this.results.map((result) => `${result.member.identity.attributes.email},${result.status},${result.date}`),
      );
      const date = this.formatInUserTimeZone(new Date(), 'dd.MM.yyyy');
      downloadFile(content, `ritual_unmatched_members_${date}.csv`);
    },
  },
});
</script>
