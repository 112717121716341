export function ensurePath(obj: Record<string, unknown>, path: string | Array<string>) {
  if (!obj) return;
  if (!Array.isArray(path)) {
    path = path.split('.');
  }
  path.forEach((prop) => {
    if (!Object.prototype.hasOwnProperty.call(obj, prop)) {
      obj[prop] = {};
    }
  });
}

export function replaceKeyInObject(obj, paths: Array<string>, oldKey: string, newKey: string) {
  if (paths.length === 0) {
    // Add the value to the new key
    obj[newKey] = obj[oldKey];
    // Delete the old key from the object
    delete obj[oldKey];
    return obj;
  }
  for (const path of paths) {
    // Split the path into an array of keys
    const keys = path.split('.');

    // Traverse the object to get the value of the old key
    let currentValue = obj;
    for (const key of keys) {
      if (Object.prototype.hasOwnProperty.call(currentValue, key)) {
        currentValue = currentValue[key];
      } else {
        // If the key isn't in the object, move on to the next path
        break;
      }
    }

    // Add the value to the new key
    currentValue[newKey] = currentValue[oldKey];
    // Delete the old key from the object
    delete currentValue[oldKey];
  }
  // Return the modified object
  return obj;
}

export function deleteKeyInObject(obj, paths: Array<string>, key: string) {
  if (paths.length === 0) {
    // Delete the old key from the object
    delete obj[key];
    return obj;
  }
  for (const path of paths) {
    // Split the path into an array of keys
    const keys = path.split('.');

    // Traverse the object to get the value of the old key
    let currentValue = obj;
    for (const key of keys) {
      if (Object.prototype.hasOwnProperty.call(currentValue, key)) {
        currentValue = currentValue[key];
      } else {
        // If the key isn't in the object, move on to the next path
        break;
      }
    }
    // Delete the old key from the object
    delete currentValue[key];
  }
  // Return the modified object
  return obj;
}
