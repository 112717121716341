<template>
  <div class="m-dashboard-widget__col-2">
    <v-row class="justify-space-between ma-0">
      <h3>{{ $t('dashboard.widgets.ritual_registration.title') }}</h3>
      <router-link :to="detailsRoute">{{ $t('dashboard.widgets.details') }}</router-link>
    </v-row>
    <v-col>
      <v-row class="justify-space-between ma-0">
        <span>{{ $t('dashboard.widgets.ritual_registration.registered') }}:</span>
        <h3>{{ registered }}</h3>
      </v-row>
      <v-row class="justify-space-between ma-0">
        <span>{{ $t('dashboard.widgets.ritual_registration.enrolled') }}:</span>
        <h3>{{ enrolled }}</h3>
      </v-row>
      <v-row class="justify-space-between ma-0">
        <span>{{ $t('dashboard.widgets.ritual_registration.ready') }}:</span>
        <h3>{{ ready }}</h3>
      </v-row>
    </v-col>
    <h4 class="mt-4">{{ $t('dashboard.widgets.ritual_registration.share') }}</h4>
    <h2 class="text-center mt-4">{{ share }}</h2>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IRitual, RitualUserStatus } from '@mentessa/types';
import { RouteName } from '@/router/types';

export interface RitualRegistrationData {
  ritual_registration: Array<{ status: RitualUserStatus; complete: boolean; count: string }>;
}

export default Vue.extend({
  name: 'm-ritual-registration-widget',
  props: {
    value: { type: Object as PropType<RitualRegistrationData> },
    ritual: { type: Object as PropType<IRitual> },
  },
  data: () => ({
    registered: 0,
    enrolled: 0,
    ready: 0,
    total: 0,
  }),
  computed: {
    share() {
      if (this.total === 0) {
        return '-';
      }
      return `${Math.floor((this.ready / this.total) * 100)}%`;
    },
    detailsRoute() {
      if (!this.ritual) {
        return undefined;
      }
      return { name: RouteName.RitualManageMembers, params: { ritualId: this.ritual.id } };
    },
  },
  async mounted() {
    this.value?.ritual_registration?.forEach((row) => {
      if (row.status === RitualUserStatus.Invited && !row.complete) {
        this.registered = Number(row.count);
      } else if (row.status === RitualUserStatus.Invited && row.complete) {
        this.enrolled = Number(row.count);
      } else {
        this.ready += Number(row.count);
      }
      this.total += Number(row.count);
    });
  },
});
</script>
