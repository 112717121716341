import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import {
  MobileActions,
  MobileMutations,
  MobileState,
  SendPushNotificationOptions,
  SendPushNotificationTargetOptions,
} from './types';
import { api } from '@/store/api';
import i18n from '@/plugins/i18n';
import { ShowErrorNotification, ShowNotification } from '@/store/notifications';
import { getIdOrCurrent } from '@/utils/api';

export const actions: ActionTree<MobileState, RootState> = {
  async [MobileActions.LoadCompanyCode]({ commit, dispatch }, { tenantRef }) {
    try {
      const response = await api.get(`tenants/${getIdOrCurrent(tenantRef)}/company-codes`);
      const code = response?.data?.[0];
      if (code) {
        commit(MobileMutations.SetCompanyCode, code);
      }
    } catch (e) {
      if (e.response?.status !== 404) {
        console.error('Error while LoadCompanyCode.', e);
        await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
          root: true,
        });
      }
    }
  },

  async [MobileActions.UpdateCompanyCode]({ commit, dispatch }, { tenantRef, codeRef, code }) {
    try {
      const response = await api.patch(`tenants/${getIdOrCurrent(tenantRef)}/company-codes/${codeRef.id}`, { code });
      const newCode = response?.data;
      if (newCode) {
        commit(MobileMutations.SetCompanyCode, newCode);
        await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
          root: true,
        });
      }
    } catch (e) {
      console.error('Error while UpdateCompanyCode.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [MobileActions.CreateCompanyCode]({ commit, dispatch }, { tenantRef, code }) {
    try {
      const response = await api.post(`tenants/${getIdOrCurrent(tenantRef)}/company-codes`, { code });
      const newCode = response?.data;
      if (newCode) {
        commit(MobileMutations.SetCompanyCode, newCode);
        await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
          root: true,
        });
      }
    } catch (e) {
      console.error('Error while UpdateCompanyCode.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [MobileActions.LoadMobileUsers]({ dispatch }, { options }) {
    try {
      const response = await api.get(`mobile/users`, { params: options });
      return response.data;
    } catch (e) {
      if (e.response?.status !== 404) {
        console.error('Error while LoadMobileUsers.', e);
        await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
          root: true,
        });
      }
    }
  },

  async [MobileActions.SendPushNotification](
    { dispatch },
    { options }: { options: SendPushNotificationTargetOptions },
  ) {
    try {
      const response = await api.post(`notifications`, {
        mode: 'target',
        content: options.content,
        filter: {
          identities: options.users?.map((u) => u.identity.id),
        },
      });
      return response.data;
    } catch (e) {
      console.error('Error while SendPushNotification.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [MobileActions.SendBroadcastPushNotification](
    { dispatch },
    { options }: { options: SendPushNotificationOptions },
  ) {
    try {
      const response = await api.post(`notifications`, {
        mode: 'broadcast',
        content: options.content,
      });
      return response.data;
    } catch (e) {
      console.error('Error while SendBroadcastPushNotification.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [MobileActions.ResetChallenges]({ dispatch }, { mobileUserRef }) {
    try {
      const response = await api.post(`mobile/users/${mobileUserRef.id}/challenges/reset`);
      return response.data;
    } catch (e) {
      console.error('Error while ResetChallenges.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [MobileActions.RecalculateAnalytics]({ dispatch }, { mobileUserRef }) {
    try {
      const response = await api.post(`mobile/users/${mobileUserRef.id}/recalculate`, { kind: 'analytics' });
      return response.data;
    } catch (e) {
      console.error('Error while RecalculateAnalytics.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [MobileActions.RecalculateSkills]({ dispatch }, { mobileUserRef }) {
    try {
      const response = await api.post(`mobile/users/${mobileUserRef.id}/recalculate`, { kind: 'skills' });
      return response.data;
    } catch (e) {
      console.error('Error while RecalculateSkills.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [MobileActions.RecalculateAll]({ dispatch }, { mobileUserRef }) {
    try {
      const response = await api.post(`mobile/users/${mobileUserRef.id}/recalculate`, { kind: 'all' });
      return response.data;
    } catch (e) {
      console.error('Error while RecalculateAll.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [MobileActions.RecalculateAnalyticsForAll]({ dispatch }, { options }) {
    try {
      const response = await api.post(`mobile/users/recalculate`, { ...options, kind: 'analytics' });
      return response.data;
    } catch (e) {
      console.error('Error while RecalculateAnalyticsForAll.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [MobileActions.RecalculateSkillsForAll]({ dispatch }, { options }) {
    try {
      const response = await api.post(`mobile/users/recalculate`, { ...options, kind: 'skills' });
      return response.data;
    } catch (e) {
      console.error('Error while RecalculateSkillsForAll.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [MobileActions.RecalculateSkillsAndAnalyticsForAll]({ dispatch }, { options }) {
    try {
      const response = await api.post(`mobile/users/recalculate`, { ...options, kind: 'all' });
      return response.data;
    } catch (e) {
      console.error('Error while RecalculateSkillsAndAnalyticsForAll.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
};
