<template>
  <div class="d-flex flex-column m-dashboard-widget__col-3 m-dashboard-widget__row-2">
    <div class="d-flex justify-space-between">
      <h4>{{ $t('dashboard.widgets.ritual_results.title') }}</h4>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-if="results.length" icon v-bind="attrs" @click="download" v-on="on">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('dashboard.widgets.ritual_results.downloadTooltip') }}</span>
      </v-tooltip>
    </div>
    <div class="flex-grow-1 overflow-auto">
      <v-simple-table v-if="results.length > 0">
        <thead>
          <tr class="text-left valign-top">
            <th v-for="(header, index) in headers" :key="`header-${index}}`">
              {{ $t(`dashboard.widgets.ritual_results.table.header.${header}`) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(result, index) in results" :key="`row-${index}`">
            <td>
              <m-user-preview :user="result.mentor" />
            </td>
            <td>
              <m-user-preview :user="result.mentee" />
            </td>
            <td>{{ $t(`dashboard.widgets.ritual_matches.status.${result.status}`) }}</td>
            <td>{{ result.date }}</td>
          </tr>
        </tbody>
      </v-simple-table>
      <div v-else class="d-flex justify-center align-center fill-height">
        {{ $t(`dashboard.no_data`) }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IUser, MatchStatus, UserIdentityAttributes } from '@mentessa/types';
import MUserPreview from '@/components/MUserPreview/UserPreviewPure.vue';
import { mapGetters } from 'vuex';
import { buildCSV } from '@/utils/csv';
import { downloadFile } from '@/utils/file';

export interface RitualResultsRowBase {
  status: MatchStatus;
  date: string;
}

export interface RitualResultsRowExternal extends RitualResultsRowBase {
  menteeId: string;
  menteeIdentity: UserIdentityAttributes;
  mentorId: string;
  mentorIdentity: UserIdentityAttributes;
}

export interface RitualResultsRowInternal extends RitualResultsRowBase {
  mentor: Partial<IUser>;
  mentee: Partial<IUser>;
}

export interface RitualMonthlyFeedbacksData {
  ritual_results_with_roles: Array<RitualResultsRowExternal>;
}

export default Vue.extend({
  name: 'm-ritual-results-widget',
  components: { MUserPreview },
  props: {
    value: { type: Object as PropType<RitualMonthlyFeedbacksData> },
  },
  data: () => ({
    results: [] as Array<RitualResultsRowInternal>,
  }),
  computed: {
    ...mapGetters('ui', {
      formatInUserTimeZone: 'formatInUserTimeZone',
    }),
    headers() {
      return ['mentee', 'mentor', 'status', 'date'];
    },
  },
  async mounted() {
    this.results =
      this.value?.ritual_results_with_roles?.map((row) => ({
        status: row.status,
        date: this.formatInUserTimeZone(row.date, 'dd.MM.yyyy'),
        mentee: row.menteeId ? { id: row.menteeId, identity: { attributes: row.menteeIdentity } } : undefined,
        mentor: row.mentorId ? { id: row.mentorId, identity: { attributes: row.mentorIdentity } } : undefined,
      })) ?? [];
  },
  methods: {
    download() {
      const content = buildCSV(
        this.headers.join(','),
        this.results.map(
          (result) =>
            `${result.mentee?.identity.attributes.email ?? ''},${result.mentor?.identity.attributes.email ?? ''},${
              result.status
            },${result.date}`,
        ),
      );
      const date = this.formatInUserTimeZone(new Date(), 'dd.MM.yyyy');
      downloadFile(content, `ritual_results_${date}.csv`);
    },
  },
});
</script>
