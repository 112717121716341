import { ActionTree } from 'vuex';
import { RootActions, RootMutations, RootState } from '@/store/types';
import Vue from 'vue';
import { GetMe, LoadPermissions, LoadTimeSlots } from '@/store/users';
import { LoadAvailableTenants, LoadTenant, tenantGetter } from '@/store/tenant';
import { InitGeonames, LoadLanguages } from '@/store/utils';
import { resume } from '@/router';
import { StartFetchMessages } from '@/store/messages';
import i18n from '@/plugins/i18n';
import { MessagesFeature } from '@mentessa/types';
import { LoadTranslations } from '@/store/ui';
import { api } from '@/store/api';
import { LoadMentoringSessionList } from '@/store/mentoring';
import { LoadNps } from '@/store/nps';

export const actions: ActionTree<RootState, RootState> = {
  async [RootActions.Initialize]({ commit, dispatch, state, rootGetters }, { loadData, initSession, loadNps }) {
    Vue.prototype.$log.debug('Initialize app');

    if (initSession) {
      const pathParts = window.location.pathname.split('/');
      if (pathParts.length === 3 && pathParts[1] === 'invites') {
        console.log('Apply invite', pathParts[2]);
        await api.post('session', { invite: pathParts[2] });
      } else {
        await api.post('session');
      }
    }

    if (loadData) {
      await dispatch(new LoadTenant());
      await dispatch(new LoadAvailableTenants());
      await dispatch(new GetMe());
      await dispatch(new LoadPermissions());

      if (rootGetters[tenantGetter('isLocaleAvailable')](state.users.me.attributes.lang)) {
        i18n.locale = state.users.me.attributes.lang;
      } else {
        i18n.locale = rootGetters[tenantGetter('defaultLocale')];
      }
      await dispatch(new LoadTranslations(i18n.locale));
      await dispatch(new LoadLanguages(i18n.locale));
      await dispatch(new InitGeonames(i18n.locale));
      await dispatch(new LoadMentoringSessionList());
      await dispatch(new LoadTimeSlots());
      commit(RootMutations.Initialize);
      const messagesConfig: MessagesFeature = state.tenant.tenant.config.features.messages;
      if (messagesConfig?.fetchAuto ?? true) {
        await dispatch(new StartFetchMessages(messagesConfig?.fetchInterval));
      }
    } else {
      await dispatch(new GetMe());
      commit(RootMutations.Initialize);
    }
    if (loadNps) {
      await dispatch(new LoadNps());
    }
    resume();
  },
};
