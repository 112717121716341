<template>
  <div>
    <v-btn class="ma-4" sentry-tag="settings-debug.button.reindex" @click="reindex"> Reindex</v-btn>
    <v-btn class="ma-4" sentry-tag="settings-debug.button.reindexAll" @click="reindexAll"> Reindex All</v-btn>
  </div>
</template>

<script lang="ts">
import { RecreateSearchIndex, ReindexSearchForAllTenants } from '@/store/tenant';
import Vue from 'vue';

export default Vue.extend({
  name: 'm-settings-debug-search',
  methods: {
    async reindex() {
      await this.$store.dispatch(new RecreateSearchIndex());
    },
    async reindexAll() {
      await this.$store.dispatch(new ReindexSearchForAllTenants());
    },
  },
});
</script>
