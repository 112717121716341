<template>
  <div class="tags-wrap" sentry-tag="tags-wrapper">
    <p v-if="label" class="tags-wrap__label mb-1">{{ label }}</p>
    <div :class="{ 'flex-column': column }" class="d-flex mt-1">
      <div v-if="column" class="mb-1">
        <m-text-with-hint
          v-for="tag in tags.slice(0, 3)"
          :key="tag.name"
          :lines="1"
          :text="tag.name"
          class="font-weight-medium mb-1"
        />
      </div>
      <p v-else :style="style" class="font-weight-medium tags-wrap__paragraph">
        {{ tags.map((item) => item.name).join(' · ') }}
      </p>

      <v-tooltip v-if="invisibleTagsCount > 0" top>
        <template v-slot:activator="{ on }">
          <v-avatar class="tags-wrap__count" color="button" size="24" v-on="on">
            <span class="white--text">+{{ invisibleTagsCount }}</span>
          </v-avatar>
        </template>
        <span>{{ tags.map((item) => item.name).join(' · ') }}</span>
      </v-tooltip>
    </div>

    <div class="font-weight-medium mb-0 tags-wrap__hidden">
      <div ref="tags_wrap">
        <span v-for="(item, i) in tags" :key="i">
          {{ item.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { nextTick, PropType } from 'vue';
import { ITag } from '@mentessa/types';
import MTextWithHint from '@/components/MTextWithHint';

export default Vue.extend({
  name: 'm-tags-wrapper',
  components: { MTextWithHint },
  props: {
    label: [String, Object],
    tags: { type: Array as PropType<Array<ITag>>, default: () => [] },
    column: { type: Boolean, default: false },
    color: { type: String },
  },
  data: () => ({
    invisibleTagsCount: 0,
  }),
  computed: {
    style() {
      return {
        '--v-gradient-color': this.color ? `var(--v-${this.color}-base)` : 'var(--v-white-base)',
      };
    },
  },
  watch: {
    async tags() {
      await nextTick();
      this.updateTooltip();
    },
  },
  mounted() {
    this.updateTooltip();
  },
  methods: {
    updateTooltip() {
      if (this.column) {
        this.invisibleTagsCount = this.tags.length - 3;
      } else {
        let visibleItems = this.tags.length;
        const parent = this.$refs.tags_wrap;
        const parentWidth = parent.offsetWidth;
        const child = parent.children;
        let childWidth = 0;
        for (let i = 0; i < child.length; i++) {
          childWidth = childWidth + child[i].offsetWidth + 10;
          if (parentWidth - 20 < childWidth) {
            visibleItems = i;
            break;
          }
        }
        this.invisibleTagsCount = this.tags.length - visibleItems;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.tags-wrap {
  position: relative;
  min-height: 36px;
  overflow: hidden;

  &__label {
    color: var(--v-gray-base);
    margin-top: 6px;
    margin-bottom: 6px;
    font-weight: 500;
  }

  &__paragraph {
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    padding-right: 20px;
    margin-bottom: 0;
  }

  &__paragraph:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    height: 100%;
    // ToDo: Apply desired color to both gradient parts
    background: linear-gradient(to right, rgba(255, 255, 255, 0.2), var(--v-gradient-color) 100%);
  }

  &__hidden {
    position: absolute;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    white-space: nowrap;
  }

  &__count {
    position: relative;
    top: -2px;

    span {
      font-size: 12px !important;
    }
  }

  span {
    flex-shrink: 0;
    color: var(--v-primary-base);
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
