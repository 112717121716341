<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :value="value"
    max-width="600"
    sentry-tag="enroll-ritual-dialog"
    @input="$emit('input', $event)"
  >
    <v-sheet :style="style" class="pa-8 fill-height dialog_container">
      <m-enroll-dialog-form-questionnaire v-if="!complete" v-model="answers" :questions="questions" />
      <m-enroll-dialog-form-all-set v-else :ritual="ritual" />
    </v-sheet>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';
import { IRitual } from '@mentessa/types';
import { MEnrollDialogFormAllSet, MEnrollDialogFormQuestionnaire } from './forms';

export default Vue.extend({
  name: 'm-enroll-dialog',
  components: { MEnrollDialogFormAllSet, MEnrollDialogFormQuestionnaire },
  props: {
    value: Boolean,
    ritual: Object as PropType<IRitual>,
  },
  data: () => ({
    answers: [],
    complete: false,
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    questions() {
      return this.ritual?.attributes?.questionnaire ?? [];
    },
    isLastStep() {
      return this.step === this.questionnaireFields.length - 1;
    },
    style() {
      return {
        '--background-color':
          this.$vuetify.breakpoint.xsOnly && !this.complete ? 'var(--v-background-base)' : 'var(--v-white-base)',
      };
    },
  },
  watch: {
    async answers(answers: Array<number>) {
      if (answers.length === this.questions.length) {
        console.error(`Obsolete method`);
        // await this.$store.dispatch(new SendQuestionnaireAnswers(this.ritual, this.rit this.answers));
        this.complete = true;
      }
    },
  },
  methods: {},
});
</script>

<style lang="scss" scoped>
.m-step-container {
  gap: 60px;
}

.dialog_container {
  background-color: var(--background-color);
}
</style>
