import { Action } from '@/store/types';
import {
  IQuestionnaire,
  IRitual,
  IRitualQuestionnaire,
  IUser,
  MatchReference,
  MentoringRole,
  RitualQuestionnaireReference,
  RitualReference,
  RitualsPage,
  RitualTemplateReference,
  RitualUserReference,
  RitualUserStatus,
  UserReference,
} from '@mentessa/types';

export const moduleName = 'rituals';

export interface RitualsState {
  rituals: RitualsPage;
  current: IRitual;
}

export enum RitualsActions {
  LoadRituals = '[Rituals] Load Rituals',
  LoadQuestionnaires = '[Rituals] Load Questionnaires',
  LoadRitualQuestionnaires = '[Rituals] Load Ritual Questionnaires',
  LoadRitual = '[Rituals] Load Ritual',
  CreateRitual = '[Rituals] Create Ritual',
  CreateQuestionnaire = '[Rituals] Create Questionnaire',
  DeleteRitual = '[Rituals] Delete Ritual',
  DeleteQuestionnaire = '[Rituals] Delete Questionnaire',
  CreateRitualQuestionnaire = '[Rituals] Create Ritual Questionnaire',
  UpdateRitualQuestionnaire = '[Rituals] Update Ritual Questionnaire',
  DeleteRitualQuestionnaire = '[Rituals] Delete Ritual Questionnaire',
  UpdateRitual = '[Rituals] Update Ritual',
  UpdateRitualIcon = '[Rituals] Update Ritual Icon',
  UpdateQuestionnaire = '[Rituals] Update Questionnaire',
  ExecuteRitual = '[Rituals] Execute Ritual',
  LoadRitualInvitation = '[Rituals] Load Ritual Invitation',
  LoadRitualMembers = '[Rituals] Load Ritual Members',
  LoadRitualMemberDetails = '[Rituals] Load Ritual Member Details',
  ExportRitualMembers = '[Rituals] Export Ritual Members',
  ExportRitualAnswers = '[Rituals] Export Ritual Answers',
  LoadRitualResults = '[Rituals] Load Ritual Results',
  LoadRitualMatches = '[Rituals] Load Ritual Matches',
  SendQuestionnaireAnswers = '[Rituals] Send Questionnaire Answers',
  CheckRitualComplete = '[Rituals] Check Ritual Complete',
  EnrollToRitual = '[Rituals] Enroll To Ritual',
  EnrollDemoUsersToRitual = '[Rituals] Enroll Demo Users To Ritual',
  UnenrollFromRitual = '[Rituals] Unenroll From Ritual',
  LoadRitualQuestionnaire = '[Rituals] Load Ritual Questionnaire',
  ChangeRitualUserAcceptStatus = '[Rituals] Change Ritual User Accept Status',
  ChangeRitualUsersAcceptStatus = '[Rituals] Change Ritual Users Accept Status',
  ChangeAllRitualUsersAcceptStatus = '[Rituals] Change All Ritual Users Accept Status',

  ChangeRitualMatchAcceptStatus = '[Rituals] Change Ritual Match Accept Status',

  LoadRitualData = '[Rituals] Load Ritual Data',
  UploadRitualResults = '[Rituals] Upload Ritual Results',
  RemoveFromRitual = '[Rituals] Remove From Ritual',
  ResendMatches = '[Rituals] Resend Matches',
  ClosePendingMatches = '[Rituals] Close Pending Matches',
  InviteAllToARitual = '[Rituals] Invite All To A Ritual',
  LoadUserRituals = '[Rituals] Load User Rituals',
  LoadManagedRituals = '[Rituals] Load Managed Rituals',
  LoadRitualManagedMembers = '[Rituals] Load Ritual Managed Members',
  ChangeRitualManagedUserAcceptStatus = '[Rituals] Change Ritual Managed User Accept Status',
}

export enum RitualsMutations {
  SetRituals = '[Rituals] Set Rituals',
  AppendRitual = '[Rituals] Append Ritual',
  UpdateRitual = '[Rituals] Update Ritual',
  SetRitualIcon = '[Rituals] Set Ritual Icon',
  AddMember = '[Rituals] Add Member',
  RemoveMember = '[Rituals] Remove Member',
  SetCurrentRitual = '[Rituals] Set Current Ritual',
}

export interface FindRitualsOptions {
  invitations?: boolean;
  member?: boolean;
}

export interface LoadRitualMatchesOptions {
  query?: string;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
}

export class LoadRituals implements Action {
  type = `${moduleName}/${RitualsActions.LoadRituals}`;

  constructor(private readonly page: number = 1, private readonly options: FindRitualsOptions = {}) {}
}

export class LoadQuestionnaires implements Action {
  type = `${moduleName}/${RitualsActions.LoadQuestionnaires}`;

  constructor(private readonly page: number = 1) {}
}

export class LoadRitualQuestionnaires implements Action {
  type = `${moduleName}/${RitualsActions.LoadRitualQuestionnaires}`;

  constructor(private readonly ritual: IRitual) {}
}

export class LoadRitual implements Action {
  type = `${moduleName}/${RitualsActions.LoadRitual}`;

  constructor(private readonly ritualRef: RitualReference, private readonly options: FindRitualsOptions = {}) {}
}

export class CreateRitual implements Action {
  type = `${moduleName}/${RitualsActions.CreateRitual}`;

  constructor(private readonly dto: Partial<IRitual>, private readonly templateRef?: RitualTemplateReference) {}
}

export class CreateQuestionnaire implements Action {
  type = `${moduleName}/${RitualsActions.CreateQuestionnaire}`;

  constructor(private readonly questionnaire: IQuestionnaire) {}
}

export class CreateRitualQuestionnaire implements Action {
  type = `${moduleName}/${RitualsActions.CreateRitualQuestionnaire}`;

  constructor(private readonly ritualQuestionnaire: IRitualQuestionnaire) {}
}

export class UpdateRitualQuestionnaire implements Action {
  type = `${moduleName}/${RitualsActions.UpdateRitualQuestionnaire}`;

  constructor(private readonly ritualQuestionnaire: IRitualQuestionnaire) {}
}

export class DeleteRitualQuestionnaire implements Action {
  type = `${moduleName}/${RitualsActions.DeleteRitualQuestionnaire}`;

  constructor(private readonly ritualQuestionnaire: IRitualQuestionnaire) {}
}

export class DeleteRitual implements Action {
  type = `${moduleName}/${RitualsActions.DeleteRitual}`;

  constructor(private readonly ritual: IRitual) {}
}

export class DeleteQuestionnaire implements Action {
  type = `${moduleName}/${RitualsActions.DeleteQuestionnaire}`;

  constructor(private readonly questionnaire: IQuestionnaire) {}
}

export class UpdateRitual implements Action {
  type = `${moduleName}/${RitualsActions.UpdateRitual}`;

  constructor(private readonly ritualRef: RitualReference, private readonly update: Partial<IRitual>) {}
}

export class UpdateRitualIcon implements Action {
  type = `${moduleName}/${RitualsActions.UpdateRitualIcon}`;

  constructor(private readonly blob: Blob, private readonly ritual?: IRitual, private readonly forceDomain?: string) {}
}

export class UpdateQuestionnaire implements Action {
  type = `${moduleName}/${RitualsActions.UpdateQuestionnaire}`;

  constructor(private readonly questionnaire: IQuestionnaire) {}
}

export class ExecuteRitual implements Action {
  type = `${moduleName}/${RitualsActions.ExecuteRitual}`;

  constructor(private readonly ritual: IRitual) {}
}

export class LoadRitualInvitation implements Action {
  type = `${moduleName}/${RitualsActions.LoadRitualInvitation}`;

  constructor(private readonly ritual: IRitual) {}
}

export class LoadRitualMembers implements Action {
  type = `${moduleName}/${RitualsActions.LoadRitualMembers}`;

  constructor(
    private readonly ritual: IRitual,
    private readonly page: number,
    private readonly query?: string,
    private readonly status?: string | Array<string>,
    private readonly role?: string | Array<string>,
    private readonly matched?: boolean,
    private readonly sortBy?: string,
    private readonly sortOrder?: 'ASC' | 'DESC',
  ) {}
}

export class LoadRitualMemberDetails implements Action {
  type = `${moduleName}/${RitualsActions.LoadRitualMemberDetails}`;

  constructor(private readonly ritualRef: RitualReference, private readonly ritualUserRef: RitualUserReference) {}
}

export class ExportRitualMembers implements Action {
  type = `${moduleName}/${RitualsActions.ExportRitualMembers}`;

  constructor(
    private readonly ritual: IRitual,
    private readonly query: string,
    private readonly status: string | Array<string>,
    private readonly role: string | Array<string>,
    private readonly matched: boolean,
  ) {}
}

export class ExportRitualAnswers implements Action {
  type = `${moduleName}/${RitualsActions.ExportRitualAnswers}`;

  constructor(
    private readonly ritual: IRitual,
    private readonly query: string,
    private readonly status: string | Array<string>,
    private readonly role: string | Array<string>,
    private readonly matched: boolean,
  ) {}
}

export class SendQuestionnaireAnswers implements Action {
  type = `${moduleName}/${RitualsActions.SendQuestionnaireAnswers}`;

  constructor(
    private readonly ritual: RitualReference,
    private readonly ritualQuestionnaire: RitualQuestionnaireReference,
    private readonly answers: Record<string, unknown>,
    private readonly user?: IUser,
  ) {}
}

export class LoadRitualResults implements Action {
  type = `${moduleName}/${RitualsActions.LoadRitualResults}`;

  constructor(private readonly ritual: IRitual, private readonly user?: IUser) {}
}

export class CheckRitualComplete implements Action {
  type = `${moduleName}/${RitualsActions.CheckRitualComplete}`;

  constructor(private readonly ritual: RitualReference, private readonly user?: UserReference) {}
}

export class LoadRitualMatches implements Action {
  type = `${moduleName}/${RitualsActions.LoadRitualMatches}`;

  constructor(
    private readonly ritualRef: RitualReference,
    private readonly page = 1,
    private readonly options: LoadRitualMatchesOptions = {},
  ) {}
}

export class EnrollToRitual implements Action {
  type = `${moduleName}/${RitualsActions.EnrollToRitual}`;

  constructor(private readonly ritual: RitualReference, private readonly user?: UserReference) {}
}

export class EnrollDemoUsersToRitual implements Action {
  type = `${moduleName}/${RitualsActions.EnrollDemoUsersToRitual}`;

  constructor(private readonly ritual: RitualReference, private readonly count: number) {}
}

export class UnenrollFromRitual implements Action {
  type = `${moduleName}/${RitualsActions.UnenrollFromRitual}`;

  constructor(private readonly ritual: RitualReference, private readonly user?: UserReference) {}
}

export class LoadRitualQuestionnaire implements Action {
  type = `${moduleName}/${RitualsActions.LoadRitualQuestionnaire}`;

  constructor(
    private readonly ritualRef: RitualReference,
    private readonly role?: MentoringRole,
    private readonly user?: UserReference,
  ) {}
}

export class ChangeRitualUserAcceptStatus implements Action {
  type = `${moduleName}/${RitualsActions.ChangeRitualUserAcceptStatus}`;

  constructor(
    private readonly ritualRef: RitualReference,
    private readonly ritualUserRef: RitualUserReference,
    private readonly status: boolean,
  ) {}
}

export class AcceptRitualUser extends ChangeRitualUserAcceptStatus {
  constructor(ritualRef: RitualReference, ritualUserRef: RitualUserReference) {
    super(ritualRef, ritualUserRef, true);
  }
}

export class DeclineRitualUser extends ChangeRitualUserAcceptStatus {
  constructor(ritualRef: RitualReference, ritualUserRef: RitualUserReference) {
    super(ritualRef, ritualUserRef, false);
  }
}

export class ChangeRitualUsersAcceptStatus implements Action {
  type = `${moduleName}/${RitualsActions.ChangeRitualUsersAcceptStatus}`;

  constructor(
    private readonly ritualRef: RitualReference,
    private readonly ritualUserIds: Array<number>,
    private readonly status: boolean,
  ) {}
}

export class AcceptRitualUsers extends ChangeRitualUsersAcceptStatus {
  constructor(ritualRef: RitualReference, ritualUserIds: Array<number>) {
    super(ritualRef, ritualUserIds, true);
  }
}

export class DeclineRitualUsers extends ChangeRitualUsersAcceptStatus {
  constructor(ritualRef: RitualReference, ritualUserIds: Array<number>) {
    super(ritualRef, ritualUserIds, false);
  }
}

export class ChangeAllRitualUsersAcceptStatus implements Action {
  type = `${moduleName}/${RitualsActions.ChangeAllRitualUsersAcceptStatus}`;

  constructor(private readonly ritualRef: RitualReference, private readonly status: boolean) {}
}

export class AcceptAllRitualUsers extends ChangeAllRitualUsersAcceptStatus {
  constructor(ritualRef: RitualReference) {
    super(ritualRef, true);
  }
}

export class DeclineAllRitualUsers extends ChangeAllRitualUsersAcceptStatus {
  constructor(ritualRef: RitualReference) {
    super(ritualRef, false);
  }
}

export class ChangeRitualMatchAcceptStatus implements Action {
  type = `${moduleName}/${RitualsActions.ChangeRitualMatchAcceptStatus}`;

  constructor(
    private readonly ritualRef: RitualReference,
    private readonly matchRef: MatchReference,
    private readonly status: boolean,
    private readonly userRef?: UserReference,
  ) {}
}

export class AcceptRitualMatch extends ChangeRitualMatchAcceptStatus {
  constructor(ritualRef: RitualReference, matchRef: MatchReference, userRef?: UserReference) {
    super(ritualRef, matchRef, true, userRef);
  }
}

export class DeclineRitualMatch extends ChangeRitualMatchAcceptStatus {
  constructor(ritualRef: RitualReference, matchRef: MatchReference, userRef?: UserReference) {
    super(ritualRef, matchRef, false, userRef);
  }
}

export class LoadRitualData implements Action {
  type = `${moduleName}/${RitualsActions.LoadRitualData}`;

  constructor(
    private readonly ritualRef: RitualReference,
    private readonly options: {
      notMatched?: boolean;
      lastRound?: boolean;
    } = undefined,
  ) {}
}

export class UploadRitualResults implements Action {
  type = `${moduleName}/${RitualsActions.UploadRitualResults}`;

  constructor(
    private readonly ritualRef: RitualReference,
    private readonly matches: Array<Record<string, unknown>>, // ToDo: Investigate types
    private readonly withoutMatch: Array<number>,
    private readonly rematch: boolean,
  ) {}
}

export class RemoveFromRitual implements Action {
  type = `${moduleName}/${RitualsActions.RemoveFromRitual}`;

  constructor(private readonly ritualRef: RitualReference, private readonly memberRef: RitualUserReference) {}
}

export class ResendMatches implements Action {
  type = `${moduleName}/${RitualsActions.ResendMatches}`;

  constructor(private readonly ritualRef: RitualReference) {}
}

export class ClosePendingMatches implements Action {
  type = `${moduleName}/${RitualsActions.ClosePendingMatches}`;

  constructor(private readonly ritualRef: RitualReference) {}
}

export class InviteAllToARitual implements Action {
  type = `${moduleName}/${RitualsActions.InviteAllToARitual}`;

  constructor(private readonly ritualRef: RitualReference, private readonly isReminder = false) {}
}

export class LoadUserRituals implements Action {
  type = `${moduleName}/${RitualsActions.LoadUserRituals}`;

  constructor(
    private readonly status?: RitualUserStatus,
    private readonly inviteGUID?: string,
    private readonly userRef?: UserReference,
  ) {}
}

export class LoadManagedRituals implements Action {
  type = `${moduleName}/${RitualsActions.LoadManagedRituals}`;

  constructor(private readonly userRef?: UserReference) {}
}

export class LoadRitualManagedMembers implements Action {
  type = `${moduleName}/${RitualsActions.LoadRitualManagedMembers}`;

  constructor(
    private readonly ritualRef: RitualReference,
    private readonly page = 1,
    private readonly userRef?: UserReference,
  ) {}
}

export class ChangeRitualManagedUserAcceptStatus implements Action {
  type = `${moduleName}/${RitualsActions.ChangeRitualManagedUserAcceptStatus}`;

  constructor(
    private readonly ritualRef: RitualReference,
    private readonly ritualUserRef: RitualUserReference,
    private readonly status: boolean,
    private readonly userRef?: UserReference,
  ) {}
}

export class AcceptRitualManagedUser extends ChangeRitualManagedUserAcceptStatus {
  constructor(ritualRef: RitualReference, ritualUserRef: RitualUserReference, userRef?: UserReference) {
    super(ritualRef, ritualUserRef, true, userRef);
  }
}

export class DeclineRitualManagedUser extends ChangeRitualManagedUserAcceptStatus {
  constructor(ritualRef: RitualReference, ritualUserRef: RitualUserReference, userRef?: UserReference) {
    super(ritualRef, ritualUserRef, false, userRef);
  }
}
