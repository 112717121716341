<template>
  <v-btn-toggle v-model="localValue" class="justify-center" color="button" rounded>
    <v-btn width="128">{{ $t('inputs.yes_no.yes') }}</v-btn>
    <v-btn width="128">{{ $t('inputs.yes_no.no') }}</v-btn>
  </v-btn-toggle>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'm-yes-no',
  props: {
    hideDetails: { type: String, default: 'auto' },
    value: { type: Boolean },
  },
  computed: {
    localValue: {
      get() {
        switch (this.value) {
          case true:
            return 0;
          case false:
            return 1;
          default:
            return undefined;
        }
      },
      set(value: number) {
        switch (value) {
          case 0:
            this.$emit('input', true);
            break;
          case 1:
            this.$emit('input', false);
            break;
          default:
            this.$emit('input', undefined);
            break;
        }
      },
    },
  },
});
</script>
