<template>
  <div class="section-settings">
    <m-settings-panel :description="$t('settings.roles.roles.description')" :title="$t('settings.roles.roles.title')">
      <div>
        <h4>{{ $t('settings.roles.roles.available') }}</h4>
        <v-chip-group column style="min-height: 40px">
          <v-chip
            v-for="(role, index) in tenantRoles.selected"
            :key="`role-${index}`"
            color="beige"
            small
            close
            @click:close="removeRole(index)"
          >
            {{ roleName(role) }}
          </v-chip>
        </v-chip-group>

        <h4>{{ $t('settings.roles.roles.suggested') }}</h4>
        <v-chip-group class="flex-wrap" column multiple>
          <v-chip v-for="(role, index) in suggestedRoles" :key="`${index}`" small @click="addRole(role)">
            {{ roleName(role) }}
          </v-chip>
        </v-chip-group>
      </div>
      <div class="d-flex justify-end mt-auto">
        <v-btn
          :disabled="!tenantRolesChanged"
          :loading="loading"
          color="primary"
          sentry-tag="manage-roles-dialog.button.save"
          @click="saveTenantRoles"
        >
          {{ $t('settings.members.manageRolesDialog.saveChanges') }}
        </v-btn>
      </div>
    </m-settings-panel>
    <m-settings-panel
      :description="$t('settings.members.manageRolesDialog.description')"
      :title="$t('settings.members.manageRolesDialog.title')"
    >
      <validation-observer v-slot="{ invalid }">
        <v-row>
          <v-col class="pa-0">
            <v-data-table :disable-sort="true" :headers="headers" :items="roles" :loading="loading" hide-default-footer>
              <template v-slot:item="{ item, index }">
                <tr>
                  <td v-if="index >= rolesLength">
                    <m-text-field
                      :id="`${index}`"
                      v-model="item.role.value"
                      :label="$t('settings.members.manageRolesDialog.role')"
                      class="ma-4"
                      validation-rules="required"
                    />
                  </td>
                  <td v-else>{{ item.role.text }}</td>
                  <td>
                    <v-simple-checkbox v-model="item.NewsAdmins" :ripple="false"></v-simple-checkbox>
                  </td>
                  <td>
                    <v-simple-checkbox v-model="item.VideosAdmins" :ripple="false"></v-simple-checkbox>
                  </td>
                  <!-- <td><v-simple-checkbox v-model="item.RitualsAdmins" :ripple="false"></v-simple-checkbox></td>
                  <td><v-simple-checkbox v-model="item.UsersAdmins" :ripple="false"></v-simple-checkbox></td> -->
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <div v-if="false" class="d-flex justify-end mt-4">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" @click="roles.push({})" v-on="on">
                <v-icon color="primary">mdi-plus-circle-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('settings.members.manageRolesDialog.add') }}</span>
          </v-tooltip>
        </div>
        <div class="d-flex justify-end mt-8">
          <v-btn
            :disabled="invalid || !changed"
            :loading="loading"
            color="primary"
            sentry-tag="manage-roles-dialog.button.save"
            @click="saveChanges"
          >
            {{ $t('settings.members.manageRolesDialog.saveChanges') }}
          </v-btn>
        </div>
      </validation-observer>
    </m-settings-panel>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { CreateRoleGroup, LoadRoleGroups, RemoveRoleGroup } from '@/store/users';
import { MTextField } from '@/components/Inputs';
import { ValidationObserver } from 'vee-validate';
import { detailedDiff, diff } from 'deep-object-diff';
import MSettingsPanel from '@/components/Settings/MSettingsPanel';
import { mapState } from 'vuex';
import { TenantState, UpdateTenantAttributes } from '@/store/tenant';
import { TenantAttributes } from '@mentessa/types';

export default Vue.extend({
  name: 'm-settings-roles',
  components: {
    MSettingsPanel,
    ValidationObserver,
    MTextField,
  },
  data: () => ({
    loading: false,
    rolesLength: 1,
    roles: [],
    initialRoles: [],
    tenantRoles: {
      predefined: [
        'expert',
        'advisor',
        'mentor',
        'mentee',
        'startup',
        'investor',
        'ambassador',
        'supervisor',
        'manager',
        'hr_partner',
        'host',
      ],
      selected: [],
    },
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    headers() {
      return [
        { text: this.$t('settings.members.manageRolesDialog.headers.role'), value: 'role' },
        { text: this.$t('settings.members.manageRolesDialog.headers.news'), value: 'news' },
        { text: this.$t('settings.members.manageRolesDialog.headers.videos'), value: 'videos' },
        // { text: this.$t('settings.members.manageRolesDialog.rituals'), value: 'rituals' },
        // { text: this.$t('settings.members.manageRolesDialog.users'), value: 'users' },
      ];
    },
    changed() {
      return Object.keys(diff(this.initialRoles, this.roles)).length > 0;
    },
    availableRoles() {
      const roles = [];
      const customRoles = this.tenant?.attributes.customRoles;
      if (customRoles?.length > 0) roles.push(...customRoles);
      return roles;
    },
    suggestedRoles() {
      return this.tenantRoles.predefined.filter(
        (predefinedRole) => !this.tenantRoles.selected.includes(predefinedRole),
      );
    },
    roleName() {
      return (role: string) => {
        if (this.$te(`user.roles.${role}`)) {
          return this.$t(`user.roles.${role}`);
        }
        return role;
      };
    },
    tenantRolesChanged() {
      return Object.keys(diff(this.availableRoles, this.tenantRoles.selected)).length > 0;
    },
  },
  async mounted() {
    await this.loadGroups();
    this.loadRoles();
  },
  methods: {
    closeDialog() {
      this.$emit('input', false);
    },
    async saveChanges() {
      this.loading = true;
      try {
        const diff = detailedDiff(this.initialRoles, this.roles);
        for (const [index, val] of this.roles.entries()) {
          const { role } = val;
          const added = diff.added[index.toString()];
          const removed = diff.updated[index.toString()];
          if (added) {
            for (const key of Object.keys(added)) {
              await this.$store.dispatch(new CreateRoleGroup(role.value, key));
            }
          }
          if (removed) {
            for (const key of Object.keys(removed)) {
              await this.$store.dispatch(new RemoveRoleGroup(role.value, key));
            }
          }
        }
      } finally {
        this.loading = false;
      }
      this.closeDialog();
    },
    async loadGroups() {
      try {
        this.loading = true;

        const groups = (await this.$store.dispatch(new LoadRoleGroups('*'))).reduce((obj, str) => {
          obj[str] = true;
          return obj;
        }, {});
        this.roles = [
          {
            role: { text: this.$t('settings.members.manageRolesDialog.roles.anybody'), value: '*' },
            ...groups,
          },
        ];
        this.initialRoles = [...JSON.parse(JSON.stringify(this.roles))];
      } finally {
        this.loading = false;
      }
    },
    loadRoles() {
      this.tenantRoles.selected = Array.from(this.availableRoles);
    },
    addRole(role) {
      this.tenantRoles.selected.push(role);
    },
    removeRole(index) {
      this.tenantRoles.selected.splice(index, 1);
    },
    async saveTenantRoles() {
      try {
        this.loading = true;
        await this.$store.dispatch(
          new UpdateTenantAttributes({ customRoles: this.tenantRoles.selected } as TenantAttributes),
        );
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
