<template>
  <div :class="{ 'm-mentessa-image': $vuetify.breakpoint.mdAndUp }" class="fill-height fill-width">
    <m-wizard v-model="wizardData" :loading="loading" :steps="steps" style="max-width: 800px" @onComplete="submit" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {
  MFormColorStep,
  MFormImageStep,
  MFormNameStep,
  MFormTaglineStep,
  MTenantOnboardingCommunityRolesForm,
  MTenantOnboardingLookingForForm,
  MTenantOnboardingNeedToKnowForm,
  MTenantOnboardingProgramsForm,
  MTenantOnboardingRoleForm,
  MTenantOnboardingToolsForm,
  MTenantOnboardingUseForForm,
  MTenantOnboardingUsersCountForm,
} from './Steps';
import { CreateTenant, InviteMembers, UpdateTenantImage } from '@/store/tenant';
import { MWizard, WizardStep } from '@/components/MWizard';

export default Vue.extend({
  name: 'm-tenant-onboarding-wizard',
  components: {
    MWizard,
  },
  data: () => ({
    loading: false,
    wizardData: {
      user_name: {
        firstName: '' as string,
        lastName: '' as string,
      },
      role: undefined as Array<string>,
      use_for: undefined as Array<string>,
      tools: undefined as Array<string>,
      users_count: undefined as number,
      looking_for: undefined as Array<string>,
      need_to_know: undefined as string,
      programs: undefined as Array<string>,
      community_roles: undefined as Array<string>,
      tenant_name: {
        name: '',
        domain: '',
      },
      tag: '',
      color: '#074BCB',
      image: {
        src: undefined,
        type: undefined,
        blob: undefined,
      },
    },
  }),
  computed: {
    steps() {
      return [
        {
          name: 'role',
          title: this.$t(`onboarding.role.title`),
          description: this.$t(`onboarding.role.description`),
          component: MTenantOnboardingRoleForm,
        },
        {
          name: 'use_for',
          title: this.$t(`onboarding.use_for.title`),
          description: this.$t(`onboarding.use_for.description`),
          component: MTenantOnboardingUseForForm,
        },
        {
          name: 'tools',
          title: this.$t(`onboarding.tools.title`),
          description: this.$t(`onboarding.tools.description`),
          component: MTenantOnboardingToolsForm,
        },
        {
          name: 'users_count',
          title: this.$t(`onboarding.users_count.title`),
          description: this.$t(`onboarding.users_count.description`),
          component: MTenantOnboardingUsersCountForm,
        },
        {
          name: 'looking_for',
          title: this.$t(`onboarding.looking_for.title`),
          description: this.$t(`onboarding.looking_for.description`),
          component: MTenantOnboardingLookingForForm,
        },
        {
          name: 'need_to_know',
          title: this.$t(`onboarding.need_to_know.title`),
          description: this.$t(`onboarding.need_to_know.description`),
          component: MTenantOnboardingNeedToKnowForm,
        },
        {
          name: 'tenant_name',
          title: this.$t(`onboarding.tenant_name.title`),
          description: this.$t(`onboarding.tenant_name.description`),
          component: MFormNameStep,
        },
        {
          name: 'tag',
          title: this.$t(`onboarding.tag.title`),
          description: this.$t(`onboarding.tag.description`),
          component: MFormTaglineStep,
        },
        {
          name: 'programs',
          title: this.$t(`onboarding.programs.title`),
          description: this.$t(`onboarding.programs.description`),
          component: MTenantOnboardingProgramsForm,
        },
        {
          name: 'community_roles',
          title: this.$t(`onboarding.community_roles.title`),
          description: this.$t(`onboarding.community_roles.description`),
          component: MTenantOnboardingCommunityRolesForm,
        },
        {
          name: 'image',
          title: this.$t(`onboarding.image.title`),
          description: this.$t(`onboarding.image.description`),
          component: MFormImageStep,
        },
        {
          name: 'color',
          title: this.$t(`onboarding.color.title`),
          description: this.$t(`onboarding.color.description`),
          component: MFormColorStep,
        },
      ] as Array<WizardStep>;
    },
  },
  async mounted() {
    // await this.$store.dispatch(new InitTenantCreation());
  },
  methods: {
    navigateToNewDomain() {
      const splitHost = window.location.host.split('.');
      splitHost[0] = this.wizardData.tenant_name.domain;
      window.location.host = splitHost.join('.');
    },

    async submit() {
      try {
        this.loading = true;
        const tenant = await this.$store.dispatch(
          new CreateTenant({
            name: this.wizardData.tenant_name.name,
            domain: this.wizardData.tenant_name.domain,
            tagLine: this.wizardData.tagline,
            color: this.wizardData.color,
            communityRoles: this.wizardData.community_roles,
            programs: this.wizardData.programs,
            marketing: {
              role: this.wizardData.role,
              useFor: this.wizardData.use_for,
              tools: this.wizardData.tools,
              usersCount: this.wizardData.users_count,
              lookingFor: this.wizardData.looking_for,
              needToKnow: this.wizardData.need_to_know,
            },
          }),
        );

        if (tenant) {
          if (this.wizardData.image.blob) {
            await this.$store.dispatch(
              new UpdateTenantImage(this.wizardData.image.blob, null, this.wizardData.tenant_name.domain),
            );
          }

          const emailsList =
            this.members
              ?.split(/[,\n]/)
              .map((email) => email.trim())
              .filter((email) => !!email) ?? [];

          if (emailsList.length > 0) {
            await this.$store.dispatch(new InviteMembers(emailsList, null, null, this.wizardData.tenant_name.domain));
          }

          this.navigateToNewDomain();
        }
      } catch (e) {
        console.log('Error while create new tenant', e);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style lang="scss">
.m-mentessa-image {
  background: url('@/assets/mentessa.svg') no-repeat right bottom;
  background-size: 30% 20%;
}
</style>
