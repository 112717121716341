<template>
  <v-container>Hello Events</v-container>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'm-settings-debug-events',
  data: () => ({}),
  methods: {},
});
</script>
