<template>
  <v-container class="m-ritual-edit-wrapper" sentry-tag="settings">
    <div class="d-flex pa-4">
      <h1 class="text--text">{{ title }}</h1>
      <a
        class="m-back-button-container ml-auto"
        sentry-tag="ritual-settings.button.back"
        @click.prevent="backToRitualList"
      >
        <v-img contain height="24" src="@/assets/arrow-left-circle.svg" width="24" />
        <div class="text--text text--lighten-4">{{ $t('settings.rituals.ritualEdit.back') }}</div>
      </a>
    </div>
    <v-divider />
    <m-ritual-edit-section-name v-model="ritual" />
    <v-divider />
    <component :is="editComponent" v-if="editComponent" v-model="ritual" />
    <v-divider />
    <div class="d-flex justify-space-between py-4 m-section-container">
      <div>
        <h3 class="text--text mb-2">{{ $t('settings.rituals.ritualEdit.sectionInvitations.title') }}</h3>
        <div class="text--text text--lighten-5">
          {{ $t('settings.rituals.ritualEdit.sectionInvitations.subtitle') }}
        </div>
      </div>
      <div class="form_container">
        <v-switch
          v-if="isDebugAvailable"
          v-model="demo"
          :label="$t('settings.rituals.ritualEdit.sectionMatching.labelDemoRitual')"
          class="ma-0 ml-auto"
        />
        <v-switch
          v-if="!enabled"
          v-model="inviteAll"
          :label="$t('settings.rituals.ritualEdit.sectionInvitations.inviteAll')"
          class="ml-auto"
        />
        <v-switch
          v-model="inviteAuto"
          :label="$t('settings.rituals.ritualEdit.sectionInvitations.inviteAuto')"
          class="ma-0 ml-auto"
        />
      </div>
    </div>

    <div class="d-flex justify-end">
      <v-btn v-if="enabled" class="mr-4" color="button" outlined @click="deactivateRitual">
        {{ $t('settings.rituals.ritualEdit.deactivate') }}
      </v-btn>
      <v-btn v-else class="mr-4" color="button" outlined @click="updateRitual">
        {{ $t('settings.rituals.ritualEdit.saveDraft') }}
      </v-btn>
      <v-btn color="button" depressed @click="saveAndActivateRitual">
        {{ enabled ? $t('settings.rituals.ritualEdit.save') : $t('settings.rituals.ritualEdit.saveAndActivate') }}
      </v-btn>
    </div>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { RouteName } from '@/router/types';
import { MatchingState } from '@/store/matching';
import { RitualsState, UpdateRitual, UpdateRitualIcon } from '@/store/rituals';
import {
  MRitualEditSectionEventMatching,
  MRitualEditSectionHappyHour,
  MRitualEditSectionName,
  MRitualEditSectionSkillsMatching,
} from '@/components/MRitualEditSections';
import { IRitual, RitualType } from '@mentessa/types';
import deepmerge from 'deepmerge';
import { MSelect } from '@/components/Inputs';

export default Vue.extend({
  name: 'm-ritual-edit',
  components: {
    MSelect,
    MRitualEditSectionName,
    MRitualEditSectionHappyHour,
  },
  props: {
    ritualId: { type: Number },
  },
  data: () => ({
    ritual: undefined,
    demoUsersCount: 0,
  }),
  computed: {
    ...mapState<MatchingState>('rituals', {
      ritualsPage: (state: RitualsState) => {
        return state.rituals;
      },
    }),
    ...mapGetters('tenant', {
      isDebugAvailable: 'isDebugAvailable',
    }),
    rituals(): Array<IRitual> {
      return this.ritualsPage.items ?? [];
    },
    editComponent() {
      switch (this.ritual?.type) {
        case RitualType.HappyHour:
          return MRitualEditSectionHappyHour;
        case RitualType.EventMatching:
          return MRitualEditSectionEventMatching;
        case RitualType.SkillsMatching:
          return MRitualEditSectionSkillsMatching;
        default:
          return undefined;
      }
    },
    title() {
      if (!this.ritual) {
        return this.$t(`settings.rituals.knownNames.custom`);
      }
      return this.ritual?.attributes.title ?? this.$t(`settings.rituals.knownNames.${this.ritual?.type}`);
    },
    enabled() {
      return this.ritual?.enabled ?? false;
    },
    inviteAll: {
      get() {
        return this.ritual?.attributes?.inviteExistingUsers;
      },
      set(inviteAllUpdated: boolean) {
        const ritualUpdate = {
          attributes: {
            inviteExistingUsers: inviteAllUpdated,
          },
        };
        this.ritual = deepmerge(this.ritual, ritualUpdate);
      },
    },
    inviteAuto: {
      get() {
        return this.ritual?.attributes?.auto;
      },
      set(autoUpdated: boolean) {
        const ritualUpdate = {
          attributes: {
            auto: autoUpdated,
          },
        };
        this.ritual = deepmerge(this.ritual, ritualUpdate);
      },
    },
    demo: {
      get() {
        return this.ritual?.attributes?.demo;
      },
      set(isDemoRitual: boolean) {
        const ritualUpdate = {
          attributes: {
            demo: isDemoRitual,
          },
        };
        this.ritual = deepmerge(this.ritual, ritualUpdate);
      },
    },
  },
  watch: {
    rituals() {
      this.loadRitual();
    },
  },
  methods: {
    async updateRitual() {
      if (this.ritual.attributes?.icon?.blob) {
        await this.$store.dispatch(new UpdateRitualIcon(this.ritual.attributes.icon.blob, this.ritual));
      }
      if (this.ritual.attributes?.icon?.src) {
        delete this.ritual.attributes.icon;
      }
      await this.$store.dispatch(new UpdateRitual(this.ritual, this.ritual));
      if (this.ritual.type === RitualType.EventMatching) {
        await this.$router.replace({
          name: RouteName.SettingsRitualsCreated,
        });
      } else {
        await this.$router.replace({
          name: RouteName.SettingsRituals,
        });
      }
    },
    async saveAndActivateRitual() {
      this.ritual.enabled = true;
      await this.updateRitual();
    },
    async deactivateRitual() {
      this.ritual.enabled = false;
      await this.updateRitual();
    },
    loadRitual() {
      if (!this.ritualId) return;
      const baseRitual = this.rituals.find((r) => r.id === Number(this.ritualId));
      if (!baseRitual) return;
      this.ritual = deepmerge(baseRitual, {});
    },
    async backToRitualList() {
      await this.$router.push({
        name: RouteName.SettingsRituals,
      });
    },
  },
  async mounted() {
    this.loadRitual();
  },
});
</script>

<style lang="scss" scoped>
.m-ritual-edit-wrapper {
  max-width: 1200px;
}

.m-back-button-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  width: fit-content;
}
</style>
