<template>
  <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.66659 2.66667H13.3333C14.0666 2.66667 14.6666 3.26667 14.6666 4V12C14.6666 12.7333 14.0666 13.3333 13.3333 13.3333H2.66659C1.93325 13.3333 1.33325 12.7333 1.33325 12V4C1.33325 3.26667 1.93325 2.66667 2.66659 2.66667Z"
      stroke="#0062F4"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      d="M14.6666 4L7.99992 8.66667L1.33325 4"
      stroke="#2563EB"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'm-icon-envelope',
});
</script>
