<template>
  <div :style="style" class="d-flex align-center m-user-preview" sentry-tag="user-preview-pure">
    <m-ritual-icon :big="big" :dense="dense" :ritual="ritual" class="mr-4" />
    <div class="flex-column">
      <p v-if="debug && !dense" class="ma-0">ID: {{ id }}</p>
      <h3 v-if="big">{{ title }}</h3>
      <p v-else :class="{ 'font-weight-bold': bold }" class="text-truncate my-0">{{ title }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IRitual } from '@mentessa/types';
import { MRitualIcon } from '@/components/MRitualIcon';

export default Vue.extend({
  name: 'm-ritual-preview',
  components: { MRitualIcon },
  props: {
    ritual: Object as PropType<IRitual>,
    maxWidth: { type: String, default: '270px' },
    bold: { type: Boolean, default: false },
    big: { type: Boolean, default: false },
    debug: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
  },
  computed: {
    style() {
      return {
        '--preview-max-width': this.maxWidth,
        '--preview-height': this.imageSize,
      };
    },
    title() {
      if (!this.ritual) {
        return '';
      }
      if (this.ritual.attributes?.title) {
        return this.ritual.attributes.title;
      }
      return this.$t(`settings.rituals.knownNames.${this.ritual.type}`);
    },
    id() {
      return this.ritual?.id;
    },
    imageSize() {
      if (this.big) {
        return 56;
      } else if (this.dense) {
        return 24;
      }
      return 32;
    },
  },
});
</script>

<style lang="scss" scoped>
.m-user-preview {
  height: var(--preview-height);
  min-width: 120px;
  max-width: var(--preview-max-width);
}

.m-user-avatar {
  margin-right: 16px;
}

.v-chip {
  color: var(--v-white-base) !important;
  text-transform: uppercase;
}
</style>
