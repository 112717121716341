import { Module } from 'vuex';
import { RootState, StoreDataState } from '@/store/types';
import { mutations } from './mutations';
import { actions } from './actions';
import { DashboardsState, moduleName } from './types';
import { getters } from './getters';

export * from './types';

export function dasboardGetter(getter: string) {
  return `${moduleName}/${getter}`;
}

export const initialState: DashboardsState = {
  dashboards: {
    state: StoreDataState.Unknown,
    data: undefined,
  },
};

export const dashboardsModule: Module<DashboardsState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

export default dashboardsModule;
