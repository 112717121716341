import { MutationTree } from 'vuex';
import { TokensMutations, TokensState } from './types';
import { IToken, TokensPage } from '@mentessa/types';

export const mutations: MutationTree<TokensState> = {
  [TokensMutations.SetTokens](state: TokensState, page: TokensPage) {
    state.tokens = page;
  },
  [TokensMutations.RemoveToken](state: TokensState, token: IToken) {
    const existingTokenIndex = state.tokens.items.findIndex((r) => r.id === token.id);
    if (existingTokenIndex) {
      state.tokens.items.splice(existingTokenIndex, 1);
    }
  },
};
