import { RouteName } from '@/router/types';
import { MDashboard, MLegacyDashboard } from '@/components/Dashboard';
import { matchesGuard } from '@/router/guards';
import { MMatches } from '@/components/MMatches';

const dashboardsRoutes = [
  {
    path: 'community',
    name: RouteName.DashboardCommunity,
    component: MLegacyDashboard,
  },
  {
    path: 'rituals/:id',
    name: RouteName.DashboardRituals,
    component: MDashboard,
    props: (route) => ({
      dashboardSettingsId: route.params['id'] != null ? Number(route.params['id']) : undefined,
    }),
  },
  {
    path: 'matches',
    name: RouteName.DashboardMatches,
    component: MMatches,
    beforeEnter: matchesGuard,
  },
];

export { dashboardsRoutes };
export default dashboardsRoutes;
