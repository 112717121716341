import { render, staticRenderFns } from "./MMatchingPickPairsForm.vue?vue&type=template&id=f6cba6f0&scoped=true"
import script from "./MMatchingPickPairsForm.vue?vue&type=script&lang=ts"
export * from "./MMatchingPickPairsForm.vue?vue&type=script&lang=ts"
import style0 from "./MMatchingPickPairsForm.vue?vue&type=style&index=0&id=f6cba6f0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_prettier@2.8.8_vue-template-compiler@2.7.6_webpack@5.96.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6cba6f0",
  null
  
)

export default component.exports