import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { UIState } from './types';
import { format, formatInTimeZone } from 'date-fns-tz';
import { parseISO } from 'date-fns';

export const getters: GetterTree<UIState, RootState> = {
  formatInUserLocale(state: UIState) {
    return (date: Date | string, formatString: string) => {
      if (!date || !formatString) {
        return '';
      }
      if (typeof date === 'string') {
        date = parseISO(date);
      }
      return format(date, formatString, {
        locale: state.translations.dateLocale,
      });
    };
  },

  formatInUserTimeZone(state: UIState, getters) {
    return (date: Date | string, formatString: string) => {
      if (!date || !formatString) {
        return '';
      }
      if (typeof date === 'string') {
        date = parseISO(date);
      }
      const tz = getters.currentUserTz;
      return formatInTimeZone(date, tz, formatString, {
        locale: state.translations.dateLocale,
      });
    };
  },

  currentUserTz(state: UIState, getters, rootState: RootState) {
    return rootState.users.me.tz ?? rootState.users.me.identity.tz ?? rootState.tenant.tenant.tz ?? 'CET';
  },
};
