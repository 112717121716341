export enum RouteName {
  Community = 'community',
  CommunityUserDetails = 'community_user_details',
  CommunityEnroll = 'community_enroll',
  CommunityMatchAction = 'community_match_action',
  CommunityInvite = 'community_invite',
  CommunityAppointmentFeedback = 'community_appointment_feedback',
  Home = 'home',
  Dashboard = 'dashboard',
  DashboardCommunity = 'dashboard_community',
  DashboardRituals = 'dashboard_rituals',
  DashboardMatches = 'dashboard_matches',
  Settings = 'settings',
  SettingsCommunity = 'settings_community',
  SettingsMembers = 'settings_members',
  SettingsRoles = 'settings_roles',
  SettingsInvitations = 'settings_invitations',
  SettingsRituals = 'settings_rituals',
  SettingsRitualsTemplates = 'settings_rituals_tempaltes',
  SettingsRitualsEdit = 'settings_ritual_edit',
  SettingsRitualsInvitation = 'settings_ritual_invitation',
  SettingsRitualsCreated = 'settings_ritual_created',
  SettingsRitualsMembers = 'settings_ritual_members',
  SettingsRitualsResults = 'settings_ritual_results',
  SettingsRitualsData = 'settings_ritual_data',
  SettingsDebug = 'settings_debug',
  SettingsDebugMentoring = 'settings_debug_mentoring',
  SettingsDebugTokens = 'settings_debug_tokens',
  SettingsDebugUsers = 'settings_debug_users',
  SettingsDebugEmail = 'settings_debug_email',
  SettingsDebugSearch = 'settings_debug_search',
  SettingsDebugHubSpot = 'settings_debug_hubspot',
  SettingsDebugNewTenant = 'settings_debug_new_tenant',
  SettingsDebugEditTenant = 'settings_debug_edit_tenant',
  SettingsDebugTranslations = 'settings_debug_translations',
  SettingsDebugKeycloak = 'settings_debug_keycloak',
  SettingsDebugEvents = 'settings_debug_events',
  SettingsDebugCasbin = 'settings_debug_casbin',
  SettingsDebugQuestionnaires = 'questionnaires',
  SettingsDebugNps = 'settings_debug_nps',
  SettingsDebugRitualTemplates = 'settings_debug_ritual_templates',
  SettingsFeatures = 'settings_features',
  SettingsFeaturesCommunityRules = 'setting_features_community_rules',
  SettingsFeaturesCustomization = 'settings_features_customization',
  SettingsFeaturesDashboard = 'settings_features_dashboard',
  SettingsFeaturesEmail = 'settings_features_email',
  SettingsFeaturesGoals = 'settings_features_goals',
  SettingsFeaturesGroups = 'settings_features_groups',
  SettingsFeaturesHelpRequests = 'settings_features_help_requests',
  SettingsFeaturesInvites = 'settings_features_invites',
  SettingsFeaturesMatching = 'settings_features_matching',
  SettingsFeaturesMentoring = 'settings_features_mentoring',
  SettingsFeaturesMessages = 'settings_features_messages',
  SettingsFeaturesContent = 'settings_features_content',
  SettingsFeaturesProfile = 'settings_features_profile',
  SettingsFeaturesRituals = 'settings_features_rituals',
  SettingsFeaturesUsers = 'settings_features_users',
  SettingsFeaturesResources = 'settings_features_resources',
  SettingsFeaturesMobile = 'settings_features_mobile',
  SettingsFeaturesNps = 'settings_features_nps',
  SettingsFeaturesHome = 'settings_features_home',
  SettingsFeaturesIntegrations = 'settings_features_integrations',
  SettingsFeaturesUserIntegrations = 'settings_features_user_integrations',
  SettingsIntegrations = 'settings_integrations',
  SettingsMobile = 'settings_mobile',

  Inbox = 'inbox',
  InboxRoom = 'inbox_room',
  Profile = 'profile',
  Matches = 'matches',
  Hello = 'hello',
  Badges = 'badges',
  Resources = 'resources',
  Content = 'content',
  Appointments = 'appointments',
  News = 'news',
  Rituals = 'rituals',
  RitualsRitual = 'rituals_ritual',
  RitualsRitualQuestionnaire = 'rituals_ritual_questionnaire',
  RitualsRitualResult = 'rituals_ritual_result',
  RitualResults = 'ritual_results',
  RitualTempaltes = 'ritual_new',
  RitualManage = 'ritual_manage',
  RitualManageDashboard = 'ritual_manage_dashboard',
  RitualManageSettings = 'ritual_manage_settings',
  RitualManageMembers = 'ritual_manage_members',
  RitualManageResults = 'ritual_manage_results',
  RitualManageInvite = 'ritual_manage_invite',
  RitualManageDebug = 'ritual_manage_debug',
  RitualEnroll = 'ritual_enroll',
  RitualMatchAction = 'ritual_match_action',
  Mentoring = 'mentoring',
  MentoringSession = 'mentoring_session',
  ActiveMentoring = 'active_mentoring',
  ClosedMentoring = 'closed_mentoring',
  ActiveMentoringSession = 'active_mentoring_session',
  ClosedMentoringSession = 'closed_mentoring_session',
  MentoringSessionAppointment = 'mentoring_session_appointment',
  MentoringSessionFeedback = 'mentoring_session_feedback',
  ProfilePersonal = 'personal',
  ProfileSkills = 'skills',
  ProfileAvailability = 'availability',
  ProfileIntegrations = 'integrations',
  ProfileNotifications = 'notifications',
}
