import { Action } from '@/store/types';
import { EmailTemplatePage, IEmailTemplate } from '@mentessa/types';

export const moduleName = 'emails';

export interface EmailsState {
  emailsPage: EmailTemplatePage;
}

export enum EmailsActions {
  LoadEmails = '[Emails] Load Templates',
  UpdateEmail = '[Emails] Update Template',
  DeleteEmail = '[Emails] Delete Template',
  CreateEmail = '[Emails] Create Template',
  SendEmail = '[Emails] Send Template',
  PreviewEmail = '[Emails] Preview Template',
}

export enum EmailsMutations {
  SetEmails = '[Emails] Set Emails',
}

export class LoadEmails implements Action {
  type = `${moduleName}/${EmailsActions.LoadEmails}`;

  constructor(private readonly page: number = 1, private readonly options?: { locale?: string; type?: string }) {}
}

export class CreateEmail implements Action {
  type = `${moduleName}/${EmailsActions.CreateEmail}`;

  constructor(private readonly email: Omit<IEmailTemplate, 'id'>) {}
}

export class UpdateEmail implements Action {
  type = `${moduleName}/${EmailsActions.UpdateEmail}`;

  constructor(private readonly email: IEmailTemplate, private readonly update: Partial<IEmailTemplate>) {}
}

export class DeleteEmail implements Action {
  type = `${moduleName}/${EmailsActions.DeleteEmail}`;

  constructor(private readonly email: IEmailTemplate) {}
}

export class SendEmail implements Action {
  type = `${moduleName}/${EmailsActions.SendEmail}`;

  constructor(private readonly email: IEmailTemplate, private readonly options = {}) {}
}

export class PreviewEmail implements Action {
  type = `${moduleName}/${EmailsActions.PreviewEmail}`;

  constructor(private readonly email: IEmailTemplate, private readonly options = {}) {}
}
