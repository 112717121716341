<template>
  <div>
    <div class="content-header d-flex justify-space-between align-center">
      <m-headline :title="$t('content.contentViewTitle')" />
      <div>
        <v-btn class="mr-4" v-if="isContentManagingAvailable" color="button" depressed @click="addVideoDialog = true">
          {{ $t('videos.add') }}
        </v-btn>
        <v-btn v-if="isContentManagingAvailable" color="button" depressed @click="addNewsDialog = true">
          {{ $t('news.addNewsButtonTitle') }}
        </v-btn>
      </div>
    </div>
    <v-chip-group v-model="selectedTags" class="content-container__tags-list" multiple @change="loadContentWithTags">
      <v-chip v-for="(tag, index) in contentTags" :key="index" filter>{{ tag }}</v-chip>
    </v-chip-group>
    <div class="d-flex flex-column align-center mt-8 gap-4">
      <m-content-card
        v-for="(item, index) in content"
        :key="index"
        :content="item"
        @onEdit="onEdit"
        @onDelete="onDelete"
        class="m-content-container"
      />
      <v-row v-if="contentMeta.totalPages > 1" dense>
        <v-pagination
          v-model="page"
          :length="contentMeta.totalPages"
          class="m-settings-members__table-pagination pa-6 ml-auto"
          sentry-tag="content.pagination"
        />
      </v-row>
    </div>
    <m-add-news-dialog v-model="addNewsDialog" @onPublish="loadContent" />
    <m-new-video-dialog v-model="addVideoDialog" @onPublish="loadContent" />
    <m-edit-news-dialog v-model="editNewsDialog" @onPublish="loadContent" :content="editingContent" />
    <m-edit-video-dialog v-model="editVideoDialog" @onPublish="loadContent" :content="editingContent" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import MContentCard from '@/components/MContentCard';
import { MAddNewsDialog, MEditNewsDialog, MEditVideoDialog, MNewVideoDialog } from '@/components/Dialogs';
import { ContentState, DeleteContent, LoadContent } from '@/store/content';
import { ContentType, IContent } from '@mentessa/types';
import MHeadline from '@/components/MHeadline';

export default Vue.extend({
  name: 'm-content',
  components: {
    MHeadline,
    MAddNewsDialog,
    MNewVideoDialog,
    MEditNewsDialog,
    MEditVideoDialog,
    MContentCard,
  },
  data: () => ({
    addVideoDialog: false,
    addNewsDialog: false,
    editNewsDialog: false,
    editVideoDialog: false,
    editingContent: undefined,
    selectedTags: [],
    page: 1,
    loading: false,
  }),
  computed: {
    ...mapState<ContentState>('content', {
      content: (state: ContentState) => state.content.items,
      contentMeta: (state: ContentState) => state.content.meta,
    }),
    ...mapGetters('tenant', {
      isContentManagingAvailable: 'isContentManagingAvailable',
      contentTags: 'contentTags',
    }),
  },
  watch: {
    async page() {
      await this.loadContent();
    },
  },
  methods: {
    async onDelete(content: IContent) {
      this.$store.dispatch(new DeleteContent(content));
      await this.loadContent();
    },
    onEdit(content: IContent) {
      this.editingContent = content;
      switch (content.type) {
        case ContentType.News:
          this.editNewsDialog = true;
          break;
        case ContentType.Video:
          this.editVideoDialog = true;
          break;
      }
    },
    async loadContent() {
      try {
        this.loading = true;
        await this.$store.dispatch(new LoadContent(this.page));
      } finally {
        this.loading = false;
      }
    },
    async loadContentWithTags() {
      try {
        this.loading = true;
        const tags = this.selectedTags.map((tagIndex: number) => this.contentTags[tagIndex]);
        await this.$store.dispatch(new LoadContent(1, tags));
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    await this.loadContent();
  },
});
</script>

<style lang="scss" scoped>
.content-container__list {
  gap: 15px;
  padding: 32px;
}

.m-content-container {
  max-width: 800px;
}

.content-container__tags-list {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}
</style>
