<template>
  <m-panel beige>
    <div class="d-flex align-center mb-4">
      <h4>{{ $t('mentoring.goals.title') }}</h4>
      <v-tooltip v-if="!readOnly" right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" @click="addGoalDialog = true" v-on="on">
            <v-icon color="primary">mdi-plus-circle-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('mentoring.goals.addGoalHint') }}</span>
      </v-tooltip>
      <v-checkbox
        v-if="!readOnly"
        v-model="onlyMine"
        :label="$t('mentoring.goals.onlyMine')"
        class="ml-auto pt-0 mt-0"
        dense
        hide-details
      />
    </div>
    <div v-if="goals.length" class="m-goals-container">
      <m-mentoring-session-goal
        v-for="goal in filteredGoals"
        :key="`goal-${goal.id}`"
        :only-mine="onlyMine"
        :session="session"
        :session-goal="goal"
      />
    </div>
    <template v-else>
      <v-btn
        :dark="hasAppointments"
        :disabled="readOnly"
        :outlined="!hasAppointments"
        class="mt-3"
        x-large
        @click="addGoalDialog = true"
      >
        {{ $t('mentoring.goals.defineFirst') }}
      </v-btn>
      <p class="text--text text--lighten-4 mt-2">
        <strong>{{ $t('mentoring.goals.tip') }}:</strong>
        {{ $t('mentoring.goals.tips.shareLearning') }}
      </p>
    </template>
    <m-add-goal-dialog v-model="addGoalDialog" :mentoring-session="session" />
  </m-panel>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { mapState } from 'vuex';
import { UsersState } from '@/store/users';
import { IMentoringSession, IMentoringSessionGoal, MentoringSessionStatus } from '@mentessa/types';
import MAddGoalDialog from '@/components/Mentoring/dialogs/MAddGoalDialog/MAddGoalDialog.vue';
import MMentoringSessionGoal from '@/components/Mentoring/cards/MMentoringSessionGoal/MMentoringSessionGoal.vue';
import { compareDesc, parseISO } from 'date-fns';
import MPanel from '@/components/MPanel';

export default Vue.extend({
  name: 'm-mentoring-session-goals',
  components: { MPanel, MMentoringSessionGoal, MAddGoalDialog },
  props: {
    session: { type: Object as PropType<IMentoringSession> },
  },
  data: () => ({
    onlyMine: false,
    addGoalDialog: false,
  }),
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    goals() {
      let goals: Array<IMentoringSessionGoal> = this.session?.goals ?? [];
      return goals.slice().sort((l, r) => {
        return compareDesc(parseISO(l.goal.createdAt.toString()), parseISO(r.goal.createdAt.toString()));
      });
    },
    filteredGoals() {
      if (!this.onlyMine) {
        return this.goals;
      }
      return this.goals.filter((goal) => goal.goal.goalTasks.some((task) => task.task.createdById === this.me.id));
    },
    hasAppointments() {
      return this.session?.appointments?.length > 0 ?? false;
    },
    readOnly() {
      return this.session?.status !== MentoringSessionStatus.Progress;
    },
  },
  methods: {},
});
</script>

<style lang="scss" scoped>
.m-goals-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
