<template>
  <div id="wizard-container" class="fill-width fill-height d-flex flex-column">
    <v-progress-linear v-if="$vuetify.breakpoint.smAndDown" :value="progress" class="m-progress-top" />
    <v-window id="qweqwe" v-model="stepIndex" class="flex-grow-1" vertical>
      <v-window-item v-for="(step, index) in steps" :key="`step-${index}`" class="fill-height">
        <validation-observer v-slot="{ invalid }" class="fill-height flex-grow-1 d-flex flex-row">
          <div
            v-if="$vuetify.breakpoint.mdAndUp"
            class="m-progress-left d-flex flex-column gap-4 ml-4 mr-2 align-center"
          >
            <div
              :class="{
                'm-stepper-item__big': isFirstStep,
                'm-stepper-item__top': !isFirstStep,
                'm-stepper-item__tall': !isFirstStep,
                'mt-4': isFirstStep,
              }"
              class="m-stepper-item m-stepper-item__active"
            />
            <div class="m-stepper-item m-stepper-item__active flex-grow-1" />
            <div
              :class="{
                'm-stepper-item__big': isLastStep,
                'm-stepper-item__bottom': !isLastStep,
                'm-stepper-item__tall': !isLastStep,
                'm-stepper-item__active': isLastStep,
                'mb-9': isLastStep,
              }"
              class="m-stepper-item"
            />
          </div>

          <v-card class="no-border d-flex flex-column fill-height overflow-y-auto flex-grow-1 pa-4 no-backgound" flat>
            <v-card-title class="pb-0 mt-auto">
              <h3>{{ step.title }}</h3>
            </v-card-title>
            <v-card-text>
              {{ step.description }}
            </v-card-text>
            <component
              :is="step.component"
              :value="rawModel ? value : value[step.name]"
              v-bind="step.props"
              @input="mutate(step.name, $event)"
            />
            <v-card-actions class="justify-center mt-auto">
              <v-col v-if="isBackAvailable || !centerActions" cols="6">
                <v-btn
                  v-if="isBackAvailable"
                  :disabled="loading"
                  color="button"
                  depressed
                  outlined
                  width="100%"
                  @click="prevStep"
                  >{{ $t('actions.prev') }}
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  v-if="isNextAvailable"
                  :disabled="invalid || loading"
                  color="button"
                  depressed
                  width="100%"
                  @click="nextStep"
                  >{{ $t('actions.next') }}
                </v-btn>
                <v-btn v-else :disabled="invalid" color="button" depressed width="100%" @click="complete"
                  >{{ $t('actions.done') }}
                </v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
        </validation-observer>
      </v-window-item>
    </v-window>
  </div>
</template>

<script lang="ts">
import Vue, { Component, PropType } from 'vue';
import { UsersState } from '@/store/users';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';
import { RootState } from '@/store/types';
import { ValidationObserver } from 'vee-validate';

export interface WizardStep {
  name: string;
  component: Component;
  title: string;
  description: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props?: Array<any>;
}

export default Vue.extend({
  name: 'm-wizard',
  components: { ValidationObserver },
  props: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: { type: Object as PropType<Record<string, any>> },
    rawModel: { type: Boolean, default: false },
    steps: { type: Array as PropType<Array<WizardStep>>, default: () => [] as Array<WizardStep> },
    centerActions: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
  data: () => ({
    stepIndex: 0,
    show: false,
  }),
  computed: {
    ...mapState<RootState>({
      initialized: (state: RootState) => state.initialized,
    }),
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    ...mapState<TenantState>('tenant', {
      tenantName: (state: TenantState) => state.tenant?.attributes?.theme?.title ?? '',
    }),
    isFirstStep() {
      return this.stepIndex === 0;
    },
    isLastStep() {
      return this.steps.length === 0 || this.stepIndex === this.steps.length - 1;
    },

    progress() {
      return Math.round(((this.stepIndex + 1) / this.steps.length) * 100);
    },
    step() {
      return this.steps[this.stepIndex];
    },
    isNextAvailable() {
      return this.stepIndex < this.steps.length - 1;
    },
    isBackAvailable() {
      return this.stepIndex > 0;
    },
  },
  methods: {
    prevStep() {
      if (this.isBackAvailable) {
        --this.stepIndex;
      }
    },
    nextStep() {
      if (this.isNextAvailable) {
        ++this.stepIndex;
      }
    },
    complete() {
      this.$emit('onComplete');
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutate(name: string, value: any) {
      if (this.rawModel) {
        this.$emit('input', value);
      } else {
        this.$emit('input', {
          ...(this.value ?? {}),
          [name]: value,
        });
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

:deep(.v-window__container) {
  height: 100%;
}

.m-progress-top {
  width: 100%;
}

.m-progress-left {
  width: 24px;
}

.m-stepper-container {
  width: 24px;
  height: 100%;
}

.m-stepper-item {
  border-radius: 18px;
  background-color: var(--v-lightgray-base);
  width: 16px;
  height: 16px;

  &__big {
    width: 36px;
    height: 36px;
  }

  &__active {
    background-color: var(--v-primary-base);
  }

  &__top {
    border-radius: 0 0 18px 18px;
  }

  &__bottom {
    border-radius: 18px 18px 0 0;
  }

  &__tall {
    height: 64px;
  }
}
</style>
