<template>
  <div class="d-flex flex-column gap-4 align-center">
    <div v-for="(item, index) in items" :key="`scale-item-${index}`" class="d-flex flex-column align-center">
      <h4>{{ i18nPath ? $t(`${i18nPath}.${item}`) : item }}</h4>
      <m-scale
        @input="setValue(item, $event)"
        :value="value[item]"
        :boundaries="boundaries"
        :default-value="defaultValue"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import MScale from '@/components/Inputs/MScale/MScale.vue';

export default Vue.extend({
  name: 'm-scale-multiple',
  components: {
    MScale,
  },
  props: {
    defaultValue: { type: Number, default: 3 },
    value: { type: Object, default: () => ({}) },
    items: { type: Array, default: () => [] },
    boundaries: { type: Array as PropType<Array<string>>, default: () => ['', ''] },
    i18nPath: { type: String },
  },
  computed: {
    minDescription() {
      return this.boundaries?.[0];
    },
    maxDescription() {
      return this.boundaries?.[1];
    },
  },

  mounted() {
    const res = Object.fromEntries(
      this.items.filter((name) => this.value[name] != null).map((name) => [name, this.value[name]]),
    );
    this.$emit('input', res);
  },
  methods: {
    setValue(name: string, value: number) {
      const newValue = { ...this.value };
      newValue[name] = value;
      this.$emit('input', newValue);
    },
  },
});
</script>
