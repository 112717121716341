<template>
  <div>
    <h4 class="m-input-label">{{ $t('onboarding.tenant_name.communityNameLabel') }}</h4>
    <validation-provider v-slot="v" :name="$t('onboarding.tenant_name.communityNameLabel')" rules="required">
      <v-text-field
        v-model="name"
        :error-messages="v.errors"
        :placeholder="$t('onboarding.tenant_name.communityNamePlaceholder')"
        dense
        outlined
      />
    </validation-provider>
    <h4 class="m-input-label">{{ $t('onboarding.tenant_name.communityUrlLabel') }}</h4>
    <validation-provider
      v-slot="v"
      :debounce="1000"
      :name="$t('onboarding.name.communityUrlLabel')"
      rules="required|alpha_dash|uniq_domain"
    >
      <v-text-field
        v-model="domain"
        :error-messages="v.errors"
        :loading="domainLoading"
        :placeholder="$t('onboarding.tenant_name.communityUrlPlaceholder')"
        class="m-text-field m-text-field-with-text-append"
        dense
        outlined
      >
        <template v-slot:append>
          <div class="m-text-field-text-append d-flex align-center px-2 mt-n2 mr-n3">.{{ baseDomain }}</div>
        </template>
      </v-text-field>
    </validation-provider>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { extend, setInteractionMode, ValidationProvider } from 'vee-validate';
import { alpha_dash, required } from 'vee-validate/dist/rules';
import { CheckDomainNameAvailability } from '@/store/search';

setInteractionMode('aggressive');
extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});
extend('alpha_dash', {
  ...alpha_dash,
  message: 'The {_field_} may contain alpha-numeric characters as well as dashes and underscores',
});

export default Vue.extend({
  name: 'm-form-name-step',
  components: {
    ValidationProvider,
  },
  props: {
    value: { type: Object as PropType<{ name: string; domain: string }>, default: () => ({ name: '', domain: '' }) },
  },
  data: () => ({
    domainLoading: false,
  }),
  computed: {
    name: {
      get() {
        return this.value?.name;
      },
      set(name: string) {
        this.$emit('input', { ...this.value, name });
      },
    },
    domain: {
      get() {
        return this.value?.domain;
      },
      set(domain: string) {
        this.$emit('input', { ...this.value, domain: domain.toLowerCase() });
      },
    },
    baseDomain() {
      const splitHost = window.location.hostname.split('.');
      if (splitHost.length > 1) return splitHost.slice(1).join('.');
      return splitHost[0];
    },
  },
  methods: {
    submitStep(): void {
      this.$emit('onSubmit');
    },
    async checkDomain(name: string): Promise<{ valid: boolean }> {
      this.domainLoading = true;
      try {
        return {
          valid: await this.$store.dispatch(new CheckDomainNameAvailability(name)),
        };
      } catch (e) {
        return {
          valid: false,
        };
      } finally {
        this.domainLoading = false;
      }
    },
  },
  mounted() {
    extend('uniq_domain', {
      message: 'That URL already taken',
      validate: (value) => {
        return this.checkDomain(value);
      },
    });
  },
});
</script>

<style lang="scss" scoped>
.next-button {
  background-color: var(--v-primary-base);
  color: var(--v-white-base);
}

.m-text-field-text-append {
  height: 40px;
  //padding: 17px 14px;
  color: var(--v-text-lighten5);
  background-color: var(--v-lightbg-base);
  border-left: 1px solid var(--v-text-lighten2);
}
</style>
