import { RouteName } from '@/router/types';
import {
  MSettingsCommunity,
  MSettingsDebug,
  MSettingsFeatures,
  MSettingsIntegrations,
  MSettingsInvitations,
  MSettingsMembers,
  MSettingsMobile,
  MSettingsRituals,
  MSettingsRoles,
} from '@/components/Settings';
import {
  settingsCommunityGuard,
  settingsDebugGuard,
  settingsIntegrationsGuard,
  settingsInvitationsGuard,
  settingsMembersGuard,
  settingsMobileGuard,
  settingsRitualGuard,
} from '@/router/guards';
import { settingsDebugRoutes, settingsFeaturesRoutes, settingsRitualsRoutes } from '@/router/routes/settings/index';

const settingsRoutes = [
  {
    path: 'community',
    name: RouteName.SettingsCommunity,
    component: MSettingsCommunity,
    beforeEnter: settingsCommunityGuard,
  },
  {
    path: 'members',
    name: RouteName.SettingsMembers,
    component: MSettingsMembers,
    beforeEnter: settingsMembersGuard,
  },
  {
    path: 'roles',
    name: RouteName.SettingsRoles,
    component: MSettingsRoles,
    beforeEnter: settingsMembersGuard,
  },
  {
    path: 'invitations',
    name: RouteName.SettingsInvitations,
    component: MSettingsInvitations,
    beforeEnter: settingsInvitationsGuard,
  },
  {
    path: 'rituals',
    component: MSettingsRituals,
    beforeEnter: settingsRitualGuard,
    children: settingsRitualsRoutes,
  },
  {
    path: 'integrations',
    name: RouteName.SettingsIntegrations,
    component: MSettingsIntegrations,
    beforeEnter: settingsIntegrationsGuard,
  },
  {
    path: 'mobile',
    name: RouteName.SettingsMobile,
    component: MSettingsMobile,
    beforeEnter: settingsMobileGuard,
  },
  {
    path: 'debug',
    name: RouteName.SettingsDebug,
    component: MSettingsDebug,
    beforeEnter: settingsDebugGuard,
    children: settingsDebugRoutes,
  },
  {
    path: 'features',
    name: RouteName.SettingsFeatures,
    component: MSettingsFeatures,
    beforeEnter: settingsDebugGuard,
    children: settingsFeaturesRoutes,
  },
];

export { settingsRoutes };

export default settingsRoutes;
