<template>
  <div :style="style" class="d-flex align-center m-user-preview" sentry-tag="user-preview-pure" @click="$emit('click')">
    <m-user-avatar :large="big" :user="user" class="m-user-avatar" />
    <div class="flex-column ml-2">
      <p :class="{ 'text--disabled': isDeleted }" class="ma-0">
        <span v-if="debug"> ID: {{ userId }} </span>
      </p>
      <p :class="{ 'font-weight-bold': bold, 'text--disabled': isDeleted }" class="text-truncate my-0">
        <span v-if="isSession">
          {{ $t(`userPreview.sessionWith`) }}
          <span class="primary--text">{{ fullName }}</span>
        </span>
        <span v-else>{{ fullName }}</span>
        <span v-if="mentoringRole"> ({{ $t(`userPreview.role.${mentoringRole}`) }})</span>
      </p>
      <p v-if="showAttrsLine" class="mb-0 font-weight-medium text--secondary">
        <span v-if="job && jobTitle && location && userLocation"> · </span>
        <span v-if="location">{{ userLocation }}</span>
      </p>
      <div v-if="membership">
        <!--        ToDo: Move it to separate component like <m-membership-badge :tier='user.attributes.membership'>-->
        <v-chip v-if="isPremiumUser" class="member-premium" label small> {{ $t('user.membership.premium') }}</v-chip>
        <v-chip :color="isPremiumUser ? 'primary' : 'grey lighten-1'" label small>
          {{ isPremiumUser ? $t('user.membership.premium') : $t('user.membership.free') }}
        </v-chip>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import MUserAvatar from '@/components/MUserAvatar';
import { MentoringRole, UserEntity, UserReference } from '@mentessa/types';
import { getFullName } from '@/utils/user';

export default Vue.extend({
  name: 'm-user-preview',
  components: { MUserAvatar },
  props: {
    user: Object as PropType<UserEntity> | undefined | null,
    maxWidth: { type: String, default: '270px' },
    bold: { type: Boolean, default: false },
    big: { type: Boolean, default: false },
    membership: { type: Boolean, default: false },
    job: { type: Boolean, default: false },
    location: { type: Boolean, default: false },
    isSession: { type: Boolean, default: false },
    mentoringRole: { type: String as PropType<MentoringRole> },
    debug: { type: Boolean, default: false },
    userRef: Object as PropType<UserReference>,
  },
  data: () => ({
    loadedUser: undefined,
  }),
  computed: {
    style() {
      return {
        '--preview-max-width': this.maxWidth,
        '--preview-height': this.imageSize,
      };
    },
    fullName() {
      return getFullName(this.user);
    },
    imageSize() {
      return this.big ? 56 : 32;
    },
    isPremiumUser() {
      return false;
    },
    jobTitle() {
      return this.user?.attributes?.jobTitle;
    },
    userLocation() {
      return this.user?.attributes?.location;
    },
    showAttrsLine() {
      return this.job || this.location;
    },
    userId() {
      return this.user?.id;
    },
    isDeleted() {
      return this.user?.deletedAt != null;
    },
  },
});
</script>

<style lang="scss" scoped>
.m-user-preview {
  height: var(--preview-height);
  min-width: 120px;
  max-width: var(--preview-max-width);
}

.v-chip {
  color: var(--v-white-base) !important;
  text-transform: uppercase;
}
</style>
