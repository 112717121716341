import { Weekday } from '@mentessa/types';

const weekdaysMap: Record<Weekday, number> = { mon: 0, tue: 1, wed: 2, thu: 3, fri: 4, sat: 5, sun: 6 };
const weekdaysMapFromSunday: Record<Weekday, number> = { sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6 };

export function getWeekdayNumber(weekday: Weekday, startFromSunday?: boolean): number {
  if (startFromSunday) return weekdaysMapFromSunday[weekday];
  return weekdaysMap[weekday];
}

export function getWeekdayName(weekDayNumber: number): Weekday {
  return Object.keys(weekdaysMap).find((name: Weekday) => weekdaysMap[name] === weekDayNumber) as Weekday;
  // for (const name in weekdaysMap) {
  //   if (weekdaysMap[name] === weekDayNumber) {
  //     return name as Weekday;
  //   }
  // }
}

export function getSlotNextDate(weekday: Weekday, slot: string, shift = 0): Date {
  const dayNumber = getWeekdayNumber(weekday);
  const today = new Date();
  today.setUTCDate(today.getDate() + ((8 + dayNumber + shift - today.getDay()) % 7));
  const timeParts = slot.split(':');
  today.setUTCHours(Number(timeParts[0]), Number(timeParts[1]), 0, 0);
  return today;
}
