<template>
  <div>
    <v-tooltip top :disabled="!truncated">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" :style="style" ref="textTruncateBlock" class="truncate">
          {{ text }}
        </span>
      </template>
      <span>{{ text }}</span>
    </v-tooltip>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'm-text-with-hint',
  props: {
    text: String,
    lines: { type: Number, default: 2 },
    fontSize: { type: Number, default: 14 },
  },
  mounted() {
    this.checkTruncated();
  },
  data: () => ({
    truncated: false,
  }),
  computed: {
    style() {
      return {
        '--text-font-size': `${this.fontSize}px`,
        '--text-line-clamp': this.lines,
      };
    },
  },
  methods: {
    checkTruncated() {
      this.$nextTick(function () {
        const { scrollHeight, clientHeight } = this.$refs.textTruncateBlock;
        this.truncated = scrollHeight > clientHeight;
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-size: var(--text-font-size);
  -webkit-line-clamp: var(--text-line-clamp);
  line-clamp: var(--text-line-clamp);
}
</style>
