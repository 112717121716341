<template>
  <validation-provider v-slot="{ errors }" :debounce="validationDebounce" :name="label" :rules="validationRules" slim>
    <v-select
      :error-messages="errors"
      :label="labelWithRequired"
      v-bind="{ ...$props, ...$attrs }"
      v-on="$listeners"
      sentry-tag="select"
    >
      <template v-slot:prepend-item>
        <slot name="prepend-item" />
      </template>
      <template v-slot:item="data">
        <slot name="item" v-bind="data">{{ tr(data.item) }}</slot>
      </template>
      <template v-slot:selection="data">
        <slot name="selection" v-bind="data">{{ tr(data.item) }}</slot>
      </template>
    </v-select>
  </validation-provider>
</template>

<script lang="ts">
import { max, required } from 'vee-validate/dist/rules';
import { extend, setInteractionMode, ValidationProvider } from 'vee-validate';
import Vue from 'vue';

export default Vue.extend({
  name: 'm-select',
  components: {
    ValidationProvider,
  },
  props: {
    label: [String, Object /*as PropType<TranslateResult>*/],
    outlined: { type: Boolean, default: true },
    hideDetails: { type: String, default: 'auto' },
    validationRules: String,
    validationDebounce: Number,
    validationMode: { type: String, default: 'aggressive' },
    requiredSuffix: { type: String, default: ' *' },
    i18nPath: { type: String, default: undefined },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    isRequired() {
      return this.validationRules?.includes('required') ?? false;
    },
    labelWithRequired() {
      return this.isRequired ? `${this.label ?? ''}${this.requiredSuffix}` : this.label;
    },
    tr() {
      return (text: string) => {
        return this.i18nPath ? this.$t(`${this.i18nPath}.${text}`) : text;
      };
    },
  },

  watch: {
    validationMode(mode: string) {
      setInteractionMode(mode);
    },
  },
  created() {
    setInteractionMode(this.validationMode);

    extend('required', {
      ...required,
      message: this.$t('validation.required'),
    } as never);

    extend('max', {
      ...max,
      message: this.$t('validation.max'),
    } as never);
  },
});
</script>
