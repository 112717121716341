<template>
  <m-panel :title="$t('panels.goals.title')" beige draggable="draggable">
    <div class="d-flex flex-column gap-1 mt-2">
      <m-panel
        v-for="(goal, index) in goals"
        :key="`goal-${goal.goal.id}`"
        class="d-flex pt-2 justify-center align-center"
      >
        <m-text-field-edit
          v-if="goal.edit || goal.delete"
          v-model="goal.goal.text"
          :delete-confirmation="goal.delete"
          autofocus
          @onCancel="goal.edit = false"
          @onDelete="deleteGoal(goal, index)"
          @onDeleteCancel="goal.delete = false"
          @onSave="updateGoal(goal, $event, index)"
        />
        <template v-else>
          <p class="ma-0">{{ goalText(goal.goal) }}</p>
          <div class="ml-auto" style="min-width: 76px">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mr-1" icon v-bind="attrs" @click="goal.delete = true" v-on="on">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('mentoring.goals.deleteGoalHint') }}</span>
            </v-tooltip>
            <v-btn class="ml-auto" icon @click="goal.edit = true">
              <v-icon color="primary">mdi-pencil-outline</v-icon>
            </v-btn>
          </div>
        </template>
      </m-panel>
      <m-text-field-edit
        v-if="newGoal.edit"
        v-model="newGoal.text"
        autofocus
        @onCancel="cancelewGoal"
        @onSave="saveNewGoal"
      />
      <v-btn v-else class="ml-auto mt-3" color="button" @click="addNewGoal">{{ $t('panels.goals.add') }}</v-btn>
    </div>
  </m-panel>
</template>

<script lang="ts">
import Vue from 'vue';
import MPanel from '@/components/MPanel';
import { CreatePersonalGoal, DeletePersonalGoal, LoadPersonalGoals, UpdatePersonalGoal } from '@/store/mentoring';
import { MTextFieldEdit } from '@/components/Inputs';
import { IGoal } from '@mentessa/types';

interface Goal {
  goal: IGoal;
  edit: boolean;
  delete: boolean;
}

export default Vue.extend({
  name: 'm-my-goals-panel',
  components: { MTextFieldEdit, MPanel },
  props: {
    draggable: { type: Boolean, default: false },
  },
  data: () => ({
    loading: false,
    goals: [] as Array<Goal>,
    newGoal: {
      text: undefined,
      edit: false,
    },
  }),
  computed: {
    goalText() {
      return (goal: IGoal) => {
        if (goal.i18n) {
          return this.$t(`known_goals.${goal.text}`);
        }
        return goal.text;
      };
    },
  },
  async mounted() {
    try {
      this.loading = true;
      const goals = await this.$store.dispatch(new LoadPersonalGoals());
      this.goals = goals.map((goal: IGoal) => ({ goal, edit: false, delete: false }));
    } finally {
      this.loading = false;
    }
    this.$emit('onReady');
  },
  methods: {
    addNewGoal() {
      this.newGoal.text = undefined;
      this.newGoal.edit = true;
      this.$emit('onReady');
    },
    cancelewGoal() {
      this.newGoal.text = undefined;
      this.newGoal.edit = false;
      this.$emit('onReady');
    },
    async saveNewGoal(text: string) {
      if (!text) {
        return;
      }
      const newGoal = await this.$store.dispatch(new CreatePersonalGoal({ text }));
      if (newGoal) {
        this.newGoal.edit = false;
        this.goals.push({ goal: newGoal, edit: false, delete: false });
        this.$emit('onReady');
      }
    },
    async updateGoal(goal: Goal, text: string, index: number) {
      await this.$store.dispatch(new UpdatePersonalGoal(goal.goal, { text }));
      goal.edit = false;
      this.goals[index].goal.text = text;
      this.$emit('onReady');
    },
    async deleteGoal(goal: Goal, index: number) {
      if (await this.$store.dispatch(new DeletePersonalGoal(goal.goal))) {
        if (index != null) {
          this.goals.splice(index, 1);
        } else {
          goal.delete = false;
        }
        this.$emit('onReady');
      }
    },
  },
});
</script>
