<template>
  <div sentry-tag="image-picker">
    <m-user-avatar x-large :user="userWithImagePreview" />
    <v-file-input
      ref="fileInput"
      accept="image/*"
      class="d-none"
      @change="loadImage($event)"
      sentry-tag="image-picker.input.image"
    />
    <v-btn
      class="ml-4"
      depressed
      outlined
      @click="triggerImagePicker"
      sentry-tag="image-picker.button.triggerImagePicker"
    >
      <v-icon class="mr-2">mdi-cloud-upload-outline</v-icon>
      {{ value ? $t('components.imagePicker.replace') : $t('components.imagePicker.upload') }}
    </v-btn>
    <v-dialog v-model="showCropperDialog" height="500" width="500">
      <div class="m-image-picker__cropper-container">
        <cropper
          ref="cropper"
          :src="pickedImage.src"
          :stencil-props="{
            handlers: {},
            movable: false,
            resizable: false,
            aspectRatio: 1,
          }"
          :stencil-size="{ width: 400, height: 400 }"
          class="m-image-picker__cropper"
          image-restriction="stencil"
        />
        <div
          class="m-image-picker__cropper-close"
          @click="showCropperDialog = false"
          sentry-tag="image-picker.button.close"
        >
          <v-icon color="primary"> close</v-icon>
        </div>
        <div class="m-image-picker__cropper-apply">
          <v-btn
            class="ma-2 ml-1"
            color="button"
            depressed
            small
            @click.stop="applyImage"
            sentry-tag="image-picker.button.apply"
          >
            {{ $t('components.imagePicker.apply') }}
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import Pica from 'pica';
import { canvasToBlob, getMimeType, scaleCanvasSize } from '@/utils/image';
import MUserAvatar from '@/components/MUserAvatar';
import { UserEntity } from '@mentessa/types';

export default Vue.extend({
  name: 'm-image-picker',
  components: { MUserAvatar, Cropper },
  props: {
    value: undefined,
    user: Object as PropType<UserEntity>,
  },
  data: () => ({
    showCropperDialog: false,
    // Image that is picked by file-input
    pickedImage: {
      src: undefined,
      type: undefined,
    },
    // Image that is picket by cropper
    croppedImage: undefined,
    processing: false,
  }),
  computed: {
    userWithImagePreview() {
      const makePreview = () => {
        if (this.value) {
          if (this.value instanceof Blob) {
            return this.croppedImage;
          } else {
            return this.value;
          }
        }
        return undefined;
      };
      return { id: this.user.id, identity: { attributes: { ...this.user.identity.attributes, image: makePreview() } } };
    },
  },
  destroyed() {
    if (this.pickedImage.src) {
      URL.revokeObjectURL(this.pickedImage.src);
    }
  },
  methods: {
    triggerImagePicker() {
      this.$refs.fileInput.$refs.input.click();
    },
    loadImage(file: File) {
      if (file) {
        if (this.pickedImage.src) {
          URL.revokeObjectURL(this.pickedImage.src);
        }
        const blob = URL.createObjectURL(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          this.pickedImage = {
            src: blob,
            type: getMimeType(e.target.result as ArrayBuffer, file.type),
          };
          this.showCropperDialog = true;
        };
        reader.readAsArrayBuffer(file);
      }
    },
    async applyImage() {
      const pica = new Pica();
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        const resultCanvas = document.createElement('canvas');
        try {
          this.processing = true;
          scaleCanvasSize(canvas, resultCanvas, 512, 512);
          await pica.resize(canvas, resultCanvas);
          const blob = await canvasToBlob(resultCanvas, 'image/jpeg');
          if (this.croppedImage) {
            URL.revokeObjectURL(this.croppedImage);
          }
          this.croppedImage = URL.createObjectURL(blob);
          this.$emit('input', blob);
          this.showCropperDialog = false;
        } finally {
          this.processing = false;
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.m-image-picker__cropper {
  height: 512px;
  width: 512px;
  background-color: transparent;
}

.m-image-picker__cropper-container {
  position: relative;
  height: 500px;
}

.m-image-picker__cropper-close {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.m-image-picker__cropper-apply {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.v-avatar {
  border: 1px solid var(--v-lightgray-base);
}
</style>
