<template>
  <div class="invite-user-card v-card v-sheet" sentry-tag="invite-user-card">
    <div class="text-center mt-16">
      <div class="d-flex justify-center">
        <div style="width: 93px">
          <v-img src="@/assets/users.svg" />
        </div>
      </div>
    </div>
    <h2 class="text-center mb-1 mt-2 invite-user-card__title">{{ $t('inviteCard.title') }}</h2>
    <p class="text-center mb-8 invite-user-card__subtitle">{{ $t('inviteCard.text') }}</p>
    <v-btn
      @click="isAddMembersDialogOpened = true"
      large
      outlined
      depressed
      class="mx-auto d-block mb-16 invite-user-card__cta-button"
      sentry-tag="invite-user-card.button.invite"
    >
      {{ $t('inviteCard.buttonText') }}
    </v-btn>
    <m-add-members-dialog v-model="isAddMembersDialogOpened" :allowInviteLink="true" :allowRoleSelection="false" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { MAddMembersDialog } from '@/components/Dialogs/MAddMembersDialog';

export default Vue.extend({
  name: 'm-invite-user-card',
  components: { MAddMembersDialog },
  data: () => ({
    isAddMembersDialogOpened: false,
  }),
});
</script>

<style lang="scss" scoped>
.invite-user-card {
  padding: 26px 24px;
  width: 354px;

  &__title {
    font-size: 20px;
  }

  &__subtitle {
    font-size: 16px;
    color: var(--v-gray-base);
  }

  &__cta-button {
    :deep(.v-btn__content) {
      font-size: 16px;
    }
  }
}
</style>
