import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { mutations } from './mutations';
import { actions } from './actions';
import { ContentState, moduleName } from './types';
import { getters } from './getters';

export * from './types';

export function contentGetter(getter: string) {
  return `${moduleName}/${getter}`;
}

export const initialState: ContentState = {
  content: {
    items: [],
    meta: {
      currentPage: 0,
      totalPages: 0,
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 0,
    },
  },
};

export const contentModule: Module<ContentState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

export default contentModule;
