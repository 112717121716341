<template>
  <div class="m-settings-invitations" sentry-tag="settings-resources">
    <m-headline :description="$t('settings.resources.description')" />

    <div class="d-flex flex-row-reverse pt-0 pl-3 pr-3 pb-sm-6 pr-sm-6 pl-sm-6 pt-sm-6">
      <v-btn
        color="button"
        depressed
        sentry-tag="settings-resources.button.add"
        x-large
        @click="newResourceDialog = true"
      >
        {{ $t('settings.resources.add') }}
      </v-btn>
    </div>

    <div>
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-left">
              <div class="d-flex align-center">
                <span>{{ $t('settings.resources.table.title') }}</span>
              </div>
            </th>
            <th class="text-center">
              <span>{{ $t('settings.resources.table.url') }}</span>
            </th>
            <th v-if="false" class="text-center">
              <span>{{ $t('settings.resources.table.tags') }}</span>
            </th>

            <th class="text-left">
              <span>{{ $t('settings.resources.table.creator') }}</span>
            </th>
            <th class="text-left">
              <span>{{ $t('settings.resources.table.actions') }}</span>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(resource, index) in resources" :key="`settings-resources-${index}`">
            <td>
              <div class="text-left">
                {{ resource.title }}
              </div>
            </td>
            <td class="text-center">
              <a :href="resource.content">{{ resource.content }}</a>
            </td>
            <td v-if="false" class="text-center">{{ resource.attributes.tags }}</td>
            <td>
              <m-user-preview :user="resource.createdBy" />
            </td>
            <td>
              <div>
                <v-menu bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item link sentry-tag="resources-admins.menu.editResource" @click="editResource(resource)">
                      <v-list-item-title>{{ $t('settings.resources.action.edit') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="tenantId === resource.tenantId"
                      link
                      sentry-tag="resources-admins.menu.deleteResource"
                      @click="deleteResource(resource)"
                    >
                      <v-list-item-title>{{ $t('settings.resources.action.delete') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item link sentry-tag="resources-admins.menu.hideResource" @click="hideResource(resource)">
                      <v-list-item-title>{{ $t('settings.resources.action.hide') }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>

    <div class="d-flex justify-center justify-sm-end pt-6 pb-6 pr-6">
      <v-row v-if="resourcesMeta.totalPages > 1" dense>
        <v-pagination
          :length="resourcesPage.meta.totalPages"
          :page="page"
          :loading="loading"
          class="m-settings-invitations__table-pagination"
          sentry-tag="settings-resources.pagination"
        />
      </v-row>
    </div>

    <m-edit-resource-dialog v-model="editResourceDialog" :resource="editingResource" />
    <m-new-resource-dialog v-model="newResourceDialog" @onPublish="fetchResources" />
  </div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import Vue from 'vue';
import { getFullName } from '@/utils/user';
import { IResource } from '@mentessa/types';
import { LoadResources, RemoveResource, ResourcesState, UpdateResource } from '@/store/resources';
import { MUserPreview } from '@/components/MUserPreview';
import { MEditResourceDialog, MNewResourceDialog } from '@/components/Dialogs';
import { TenantState } from '@/store/tenant';
import MHeadline from '@/components/MHeadline';

export default Vue.extend({
  name: 'm-settings-resources',
  components: { MHeadline, MEditResourceDialog, MNewResourceDialog, MUserPreview },
  data: () => ({
    page: 1,
    loading: false,
    newResourceDialog: false,
    editResourceDialog: false,
    editingResource: undefined,
  }),
  computed: {
    ...mapState<ResourcesState>('resources', {
      resourcesPage: (state: ResourcesState) => state.resources,
      resourcesMeta: (state: ResourcesState) => state.resources.meta,
    }),
    ...mapState<TenantState>('tenant', {
      tenantId: (state: TenantState) => state.tenant.id,
    }),
    resources() {
      return this.resourcesPage?.items ?? [];
    },
  },
  methods: {
    async deleteResource(resource: IResource) {
      try {
        this.loading = true;
        await this.$store.dispatch(new RemoveResource(resource));
      } finally {
        this.loading = false;
      }
    },
    async hideResource(resource: IResource) {
      try {
        this.loading = true;
        await this.$store.dispatch(new UpdateResource({ ...resource, enabled: false }));
      } finally {
        this.loading = false;
      }
    },
    async editResource(resource: IResource) {
      this.editingResource = resource;
      this.editResourceDialog = true;
    },
    getFullName: getFullName,
    async fetchResources() {
      try {
        this.loading = true;
        await this.$store.dispatch(new LoadResources(this.page));
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    await this.fetchResources();
  },
});
</script>

<style lang="scss" scoped>
.m-settings-invitations {
  background-color: var(--v-white-base);

  &__table-pagination:deep(.v-pagination__navigation, .v-pagination__item) {
    box-shadow: none;
  }
}
</style>
