<template>
  <m-skeleton-row-user-card v-if="row" sentry-tag="skeleton-user-card.row" />
  <m-skeleton-column-user-card sentry-tag="skeleton-user-card.column" v-else />
</template>

<script lang="ts">
import Vue from 'vue';
import { MSkeletonColumnUserCard, MSkeletonRowUserCard } from '@/components/Cards/MUserCard';

export default Vue.extend({
  name: 'm-skeleton-user-card',
  components: {
    MSkeletonColumnUserCard,
    MSkeletonRowUserCard,
  },
  props: {
    row: { type: Boolean, default: false },
  },
});
</script>
