<template>
  <v-dialog :value="value" max-width="574" sentry-tag="add-members-dialog" @input="$emit('input', $event)">
    <div class="m-close-icon-container">
      <a class="m-close-icon" sentry-tag="add-members-dialog.button.close" @click="$emit('input', false)">
        <v-img contain height="24" src="@/assets/close-icon.svg" width="24" />
      </a>
    </div>
    <v-sheet class="pa-14">
      <h2 class="text-center">{{ $t('settings.members.addDialog.add') }}</h2>
      <p class="text-center text--secondary">{{ $t('settings.members.addDialog.inviteMembers') }}</p>
      <div v-if="allowInviteLink && magicLink">
        <v-btn large outlined class="magic-link-button" @click="copyMagicLinkToClipboard()">Share the link</v-btn>
        <div class="separator my-4">Or</div>
      </div>
      <validation-observer v-slot="{ invalid }">
        <InviteTextareaInput v-model="emails" required :withLabel="false" />
        <v-chip-group v-if="allowRoleSelection" v-model="selectedRoles" class="flex-wrap mt-4" column multiple>
          <v-chip v-for="(role, index) in availableRoles" :key="`${index}`" filter> {{ roleName(role) }}</v-chip>
        </v-chip-group>
        <v-row class="justify-center mt-12">
          <v-btn
            :disabled="invalid"
            :loading="loading"
            color="button"
            large
            depressed
            sentry-tag="add-members-dialog.button.add"
            @click="inviteMembers"
          >
            {{ $t('settings.members.addDialog.add') }}
          </v-btn>
        </v-row>
      </validation-observer>
    </v-sheet>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import { ValidationObserver } from 'vee-validate';
import { InviteMembers, TenantState } from '@/store/tenant';
import InviteTextareaInput from '@/components/MOnboarding/InviteTextareaInput.vue';
import { ShowNotification } from '@/store/notifications';
import { mapGetters, mapState } from 'vuex';
import { LoadInvites } from '@/store/invites';
import { copy } from '@/utils/clipboard';
import i18n from '@/plugins/i18n';

export default Vue.extend({
  name: 'm-add-members-dialog',
  components: {
    ValidationObserver,
    InviteTextareaInput,
  },
  props: {
    value: Boolean,
    allowInviteLink: {
      type: Boolean,
      default: false,
    },
    allowRoleSelection: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    emails: '',
    mode: 'text',
    loading: false,
    selectedRoles: [],
  }),
  mounted() {
    this.$store.dispatch(new LoadInvites());
  },
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    ...mapGetters('invites', {
      defaultInvite: 'defaultInvite',
    }),
    magicLink() {
      return this.defaultInvite ? `${window.origin}/invite?key=${this.defaultInvite.guid}` : null;
    },
    availableRoles() {
      const roles = ['Admin'];
      const customRoles = this.tenant?.attributes.customRoles;
      if (customRoles?.length > 0) roles.push(...customRoles);
      return roles;
    },
    roleName() {
      return (role: string) => {
        if (this.$te(`user.roles.${role}`)) {
          return this.$t(`user.roles.${role}`);
        }
        return role;
      };
    },
  },
  watch: {
    value(value: boolean) {
      if (value) {
        this.mode = 'text';
        this.emails = '';
        this.selectedRoles = [];
      }
    },
  },
  methods: {
    toggleMode() {
      if (this.mode === 'csv') this.mode = 'text';
      else this.mode = 'csv';
    },
    async inviteMembers() {
      const emailsList = this.emails
        .split(/[,\n]/)
        .map((email) => email.trim())
        .filter((email) => !!email);
      this.loading = true;
      try {
        const roles = this.selectedRoles.map((roleIndex) => this.availableRoles[roleIndex]);
        await this.$store.dispatch(new InviteMembers(emailsList, roles));
      } finally {
        this.loading = false;
      }
      this.$emit('input', false);
      this.$emit('members-invited');
    },
    async copyMagicLinkToClipboard() {
      await copy(this.magicLink);
      await this.$store.dispatch(new ShowNotification(i18n.t('notifications.linkCopiedToClipboard').toString()));
      this.$emit('input', false);
    },
  },
});
</script>

<style lang="scss" scoped>
.close-icon {
  position: absolute;
  top: 18px;
  right: 18px;
}

.m-close-icon-container {
  display: flex;
  justify-content: flex-end;
}

.m-close-icon {
  position: absolute;
  margin: 18px;
}

.magic-link-button {
  width: 100%;
  font-size: 16px;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--v-gray-base);
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid var(--v-gray-lighten4);
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}
</style>
