<template>
  <div class="m-headline">
    <h3 v-if="small">{{ title }}</h3>
    <h1 v-else>{{ title }}</h1>
    <p class="keep-new-line">{{ description }}</p>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { TranslateResult } from 'vue-i18n';

export default Vue.extend({
  name: 'm-headline',
  props: {
    title: String as PropType<TranslateResult>,
    description: String as PropType<TranslateResult>,
    small: { type: Boolean, default: false },
  },
});
</script>

<style lang="scss" scoped>
.m-headline {
  p {
    font-size: 16px;
    color: var(--v-gray-base);
  }
}
</style>
