import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { moduleName, UtilsState } from './types';

export * from './types';

export function utilsGetter(getter: string) {
  return `${moduleName}/${getter}`;
}

export const initialState: UtilsState = {
  languages: [],
  geonames: undefined,
};

export const utilsModule: Module<UtilsState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

export default utilsModule;
