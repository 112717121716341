import { render, staticRenderFns } from "./MRitualTasksWidget.vue?vue&type=template&id=3c8ee1f2"
import script from "./MRitualTasksWidget.vue?vue&type=script&lang=ts"
export * from "./MRitualTasksWidget.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_prettier@2.8.8_vue-template-compiler@2.7.6_webpack@5.96.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports