<template>
  <div>
    <m-autocomplete
      :items="locations"
      :loading="loading || waiting"
      :search-input.sync="searchToken"
      hide-no-data
      sentry-tag="location"
      v-bind="{ ...$props, ...$attrs }"
      @change="$emit('input', $event)"
      :chips="multiple"
      :deletable-chips="multiple"
    />
  </div>
</template>

<script lang="ts">
import MAutocomplete from '@/components/Inputs/MAutocomplete/MAutocomplete.vue';
import { LoadCitySuggestions } from '@/store/utils';
import { mapGetters } from 'vuex';

export default MAutocomplete.extend({
  name: 'm-location',
  components: {
    MAutocomplete,
  },
  props: {
    value: [String, Array],
    multiple: { type: Boolean, default: false },
    debounce: { type: Number, default: 1000 },
  },
  data: () => ({
    locations: [],
    searchToken: undefined as string,
    loading: false as boolean,
    waiting: false as boolean,
    debounceTimer: undefined,
  }),
  computed: {
    ...mapGetters('tenant', {
      filterConfig: 'filterConfig',
    }),
    items(): Array<string> {
      return this.filterConfig('location')?.rewriteItems ?? [];
    },
  },
  watch: {
    async searchToken(token: string) {
      if (!token) {
        if (this.value) {
          this.locations = [this.value, ...this.items];
        } else {
          this.locations = [...this.items];
        }

        return;
      }
      this.waiting = true;
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(async () => {
        await this.loadLocations(token);
        this.waiting = false;
      }, this.debounce);
    },
  },
  created() {
    const locations = [];
    if (this.value) locations.push(this.value);
    if (this.items) locations.push(...this.items);
    this.locations = locations;
  },
  methods: {
    async loadLocations(token: string) {
      try {
        this.loading = true;
        let locations = [];
        if (this.items.length > 0) {
          locations = this.items.filter((location) => location.toLowerCase().includes(token.toLowerCase()));
        } else {
          locations = (await this.$store.dispatch(new LoadCitySuggestions(token))) ?? [];
        }
        if (this.value) {
          this.locations = [this.value, ...locations];
        } else {
          this.locations = [...locations];
        }
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
