import { RouteName } from '@/router/types';
import {
  MProfileAvailability,
  MProfileIntegrations,
  MProfileNotifications,
  MProfilePersonal,
  MProfileSkills,
} from '@/components/Profile';
import { profileAvailabilityGuard, profileIntegrationsGuard } from '@/router/guards';

const profileRoutes = [
  {
    path: 'personal',
    name: RouteName.ProfilePersonal,
    component: MProfilePersonal,
  },
  {
    path: 'skills',
    name: RouteName.ProfileSkills,
    component: MProfileSkills,
  },
  {
    path: 'availability',
    name: RouteName.ProfileAvailability,
    component: MProfileAvailability,
    beforeEnter: profileAvailabilityGuard,
  },
  {
    path: 'integrations',
    name: RouteName.ProfileIntegrations,
    component: MProfileIntegrations,
    beforeEnter: profileIntegrationsGuard,
  },
  {
    path: 'notifications',
    name: RouteName.ProfileNotifications,
    component: MProfileNotifications,
  },
];

export { profileRoutes };

export default profileRoutes;
