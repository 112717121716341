<template>
  <div>
    <p v-if="withLabel" class="m-input-label">{{ $t('onboarding.members.addMembersViaEmailLabel') }}</p>
    <m-text-area
      v-model="localEmails"
      :placeholder="$t('onboarding.members.addMembersViaEmailPlaceholder')"
      class="m-textarea"
      height="180"
      hide-details="auto"
      outlined
      :validationRules="validationRules"
    />
    <div v-if="false" class="d-flex mt-2">
      <v-btn color="primary" small text @click="changeInviteMethod">
        <div class="mr-1">
          <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.2934 7.36667L8.1667 13.4933C7.41613 14.2439 6.39815 14.6656 5.3367 14.6656C4.27524 14.6656 3.25726 14.2439 2.5067 13.4933C1.75613 12.7428 1.33447 11.7248 1.33447 10.6633C1.33447 9.60188 1.75613 8.58389 2.5067 7.83333L8.63336 1.70667C9.13374 1.20629 9.81239 0.925182 10.52 0.925182C11.2277 0.925182 11.9063 1.20629 12.4067 1.70667C12.9071 2.20704 13.1882 2.88569 13.1882 3.59333C13.1882 4.30097 12.9071 4.97962 12.4067 5.48L6.27336 11.6067C6.02318 11.8569 5.68385 11.9974 5.33003 11.9974C4.97621 11.9974 4.63688 11.8569 4.3867 11.6067C4.13651 11.3565 3.99596 11.0171 3.99596 10.6633C3.99596 10.3095 4.13651 9.97019 4.3867 9.72L10.0467 4.06666"
              stroke="var(--v-primary-base)"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
          </svg>
        </div>
        {{ $t('onboarding.members.uploadCsv') }}
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { MTextArea } from '@/components/Inputs';

export default Vue.extend({
  name: 'InviteTextareaInput',
  components: {
    MTextArea,
  },
  props: {
    value: String,
    required: { type: Boolean, default: false },
    withLabel: { type: Boolean, default: true },
  },
  data: () => ({}),
  computed: {
    localEmails: {
      get() {
        return this.value;
      },
      set(value: string) {
        this.$emit('input', value);
      },
    },
    validationRules() {
      if (this.required) return 'required|emails';
      return 'emails';
    },
  },
  methods: {
    changeInviteMethod() {
      this.$emit('changeInviteMethod');
    },
  },
});
</script>
