<template>
  <div>
    <m-headline :description="$t('appointments.description')" :title="$t('appointments.title')" />
    <m-appointments-list :appointments="appointments" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapState } from 'vuex';
import { IAppointment } from '@mentessa/types';
import { MAppointmentsList } from '@/components/MAppointmentsList';
import { GetBookedSessions, MatchingState } from '@/store/matching';
import MHeadline from '@/components/MHeadline';

export default Vue.extend({
  name: 'm-appointments',
  components: { MHeadline, MAppointmentsList },
  computed: {
    ...mapState<MatchingState>('matching', {
      appointments: (state: MatchingState): IAppointment[] => state.appointments.items,
    }),
  },
  methods: {
    async fetchAppointments() {
      await this.$store.dispatch(new GetBookedSessions());
    },
  },
  async mounted() {
    await this.fetchAppointments();
  },
});
</script>
