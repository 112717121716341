<template>
  <div>
    <m-ritual-edit-section-name v-model="localRitual" />
    <v-divider />
    <component :is="editComponent" v-if="editComponent" v-model="localRitual" />
    <v-divider />
    <div class="d-flex justify-space-between py-4 m-section-container">
      <div>
        <h3 class="text--text mb-2">{{ $t('settings.rituals.ritualEdit.sectionInvitations.title') }}</h3>
        <div class="text--text text--lighten-5">
          {{ $t('settings.rituals.ritualEdit.sectionInvitations.subtitle') }}
        </div>
      </div>
      <div class="form_container">
        <v-switch
          v-if="isDebugAvailable"
          v-model="demo"
          :label="$t('settings.rituals.ritualEdit.sectionMatching.labelDemoRitual')"
          class="ma-0 ml-auto"
        />
        <v-switch
          v-if="!enabled"
          v-model="inviteAll"
          :label="$t('settings.rituals.ritualEdit.sectionInvitations.inviteAll')"
          class="ml-auto"
        />
        <v-switch
          v-model="inviteAuto"
          :label="$t('settings.rituals.ritualEdit.sectionInvitations.inviteAuto')"
          class="ma-0 ml-auto"
        />
      </div>
    </div>

    <div class="d-flex justify-end">
      <v-btn v-if="enabled" class="mr-4" color="button" outlined @click="deactivateRitual">
        {{ $t('settings.rituals.ritualEdit.deactivate') }}
      </v-btn>
      <v-btn v-else class="mr-4" color="button" outlined @click="updateRitual">
        {{ $t('settings.rituals.ritualEdit.saveDraft') }}
      </v-btn>
      <v-btn color="button" depressed @click="saveAndActivateRitual">
        {{ enabled ? $t('settings.rituals.ritualEdit.save') : $t('settings.rituals.ritualEdit.saveAndActivate') }}
      </v-btn>
    </div>
    <m-loader v-if="loading" :is-loading="loading" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { RouteName } from '@/router/types';
import { MatchingState } from '@/store/matching';
import { RitualsState, UpdateRitual, UpdateRitualIcon } from '@/store/rituals';
import {
  MRitualEditSectionEventMatching,
  MRitualEditSectionHappyHour,
  MRitualEditSectionName,
  MRitualEditSectionSkillsMatching,
} from '@/components/MRitualEditSections';
import { IRitual, RitualType } from '@mentessa/types';
import deepmerge from 'deepmerge';
import MSelect from '@/components/Inputs/MSelect/MSelect.vue';
import MLoader from '@/components/MLoader.vue';

export default Vue.extend({
  name: 'm-ritual-settings',
  components: {
    MLoader,
    MSelect,
    MRitualEditSectionName,
    MRitualEditSectionHappyHour,
  },
  data: () => ({
    demoUsersCount: 0,
    localRitual: undefined as IRitual,
  }),
  computed: {
    ...mapState<MatchingState>('rituals', {
      ritualsPage: (state: RitualsState) => {
        return state.rituals;
      },
      ritual: (state: RitualsState) => state.current,
    }),
    ...mapGetters('tenant', {
      isDebugAvailable: 'isDebugAvailable',
    }),
    // tab: {
    //   set(tab: string) {
    //     this.$router.replace({ query: { ...this.$route.query, tab } });
    //   },
    //   get() {
    //     return this.$route.query.tab;
    //   },
    // },
    rituals(): Array<IRitual> {
      return this.ritualsPage.items ?? [];
    },
    editComponent() {
      switch (this.localRitual?.type) {
        case RitualType.HappyHour:
          return MRitualEditSectionHappyHour;
        case RitualType.EventMatching:
          return MRitualEditSectionEventMatching;
        case RitualType.SkillsMatching:
          return MRitualEditSectionSkillsMatching;
        default:
          return undefined;
      }
    },
    title() {
      if (!this.localRitual) {
        return this.$t(`settings.rituals.knownNames.custom`);
      }
      return this.localRitual?.attributes.title ?? this.$t(`settings.rituals.knownNames.${this.localRitual?.type}`);
    },
    enabled() {
      return this.localRitual?.enabled ?? false;
    },
    inviteAll: {
      get() {
        return this.localRitual?.attributes?.inviteExistingUsers;
      },
      set(inviteAllUpdated: boolean) {
        const ritualUpdate = {
          attributes: {
            inviteExistingUsers: inviteAllUpdated,
          },
        };
        this.localRitual = deepmerge(this.localRitual, ritualUpdate);
      },
    },
    inviteAuto: {
      get() {
        return this.localRitual?.attributes?.auto;
      },
      set(autoUpdated: boolean) {
        const ritualUpdate = {
          attributes: {
            auto: autoUpdated,
          },
        };
        this.localRitual = deepmerge(this.localRitual, ritualUpdate);
      },
    },
    demo: {
      get() {
        return this.localRitual?.attributes?.demo;
      },
      set(isDemoRitual: boolean) {
        const ritualUpdate = {
          attributes: {
            demo: isDemoRitual,
          },
        };
        this.localRitual = deepmerge(this.localRitual, ritualUpdate);
      },
    },
    loading() {
      return this.ritual == null;
    },
    ritualsRoute() {
      return { name: RouteName.Rituals };
    },
  },
  watch: {
    ritual() {
      this.loadRitual();
    },
  },
  async mounted() {
    this.loadRitual();
  },
  methods: {
    async updateRitual() {
      if (this.localRitual.attributes?.icon?.blob) {
        await this.$store.dispatch(new UpdateRitualIcon(this.localRitual.attributes.icon.blob, this.ritual));
      }
      if (this.localRitual.attributes?.icon?.src) {
        delete this.localRitual.attributes.icon;
      }
      const result = await this.$store.dispatch(new UpdateRitual(this.ritual, this.localRitual));
      if (result) {
        await this.$router.push(this.ritualsRoute);
      }
    },
    async saveAndActivateRitual() {
      this.localRitual.enabled = true;
      await this.updateRitual();
    },
    async deactivateRitual() {
      this.localRitual.enabled = false;
      await this.updateRitual();
    },
    loadRitual() {
      if (!this.ritual) {
        return;
      }
      this.localRitual = deepmerge(this.ritual, {});
    },
  },
});
</script>
