import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { moduleName, UIState } from './types';

export * from './types';

export function uiGetter(getter: string) {
  return `${moduleName}/${getter}`;
}

export const initialState: UIState = {
  translations: {
    lang: '',
    dateLocale: undefined,
  },
  navigationVisible: undefined,
  communityRulesVisible: false,
  dialogsBlocked: false,
};

export const uiModule: Module<UIState, RootState> = {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};

export default uiModule;
