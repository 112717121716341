<template>
  <m-app-bar-menu :close-on-content-click="false">
    <template v-slot:activator>
      <m-user-preview :user="me" />
    </template>
    <template v-slot:items>
      <v-list class="pt-0 pb-0">
        <v-list-item v-if="isHomeAvailable" :to="personalRoute">
          <v-list-item-icon>
            <div class="icon-profile" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('appBar.profile') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="isSettingsAvailable" :to="settingsRoute">
          <v-list-item-icon>
            <div class="icon-permissions" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('appBar.settings') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item v-if="canSelectLocale">
          <m-select-ui-locale
            :label="$t('profile.personal.lang')"
            :outlined="false"
            :value="lang"
            flat
            sentry-tag="profile.input.lang"
            solo
            @input="setLocale"
          />
        </v-list-item>
        <template v-if="isImpersonated">
          <v-divider />
          <v-list-item class="pa-2 d-flex justify-center link-logout" @click.stop="cancelImpersonate">
            {{ $t('appBar.cancelImpersonate') }}
          </v-list-item>
        </template>
        <v-divider />
        <v-list-item class="pa-2 d-flex justify-center link-logout" @click.stop="logout">
          {{ $t('appBar.logout') }}
        </v-list-item>
      </v-list>
    </template>
  </m-app-bar-menu>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import MAppBarMenu from './AppBarMenu.vue';
import MUserPreview from '@/components/MUserPreview';
import { UpdateUserLang, UsersState } from '@/store/users';
import { getFullName } from '@/utils/user';
import MSelectUiLocale from '@/components/Inputs/MSelectUILocale/MSelectUILocale.vue';
import { RouteName } from '@/router/types';

export default Vue.extend({
  name: 'm-app-bar-menu-profile',
  components: {
    MSelectUiLocale,
    MUserPreview,
    MAppBarMenu,
  },
  data: () => ({
    lang: undefined,
    personalRoute: { name: RouteName.ProfilePersonal },
    settingsRoute: { name: RouteName.Settings },
  }),
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    ...mapGetters('tenant', {
      isProfileAvailable: 'isProfileAvailable',
      availableLocales: 'availableLocales',
      defaultLocale: 'defaultLocale',
      isHomeAvailable: 'isHomeAvailable',
      isSettingsAvailable: 'isSettingsAvailable',
      logoutUrl: 'logoutUrl',
    }),
    fullName() {
      return getFullName(this.me);
    },
    canSelectLocale() {
      return this.availableLocales.length > 1;
    },
    isImpersonated() {
      return this.$impersonate?.isActive() ?? false;
    },
  },
  watch: {
    me() {
      this.lang = this.me?.attributes.lang ?? this.defaultLocale;
    },
  },
  mounted() {
    this.lang = this.me?.attributes.lang ?? this.defaultLocale;
  },
  methods: {
    async logout() {
      await this.$keycloak.keycloak.logout({ redirectUri: this.logoutUrl });
    },
    async setLocale(locale: string) {
      await this.$store.dispatch(new UpdateUserLang(locale));
    },
    cancelImpersonate() {
      this.$impersonate?.clear();
    },
  },
});
</script>

<style lang="scss" scoped>
.dropdown {
  .link-logout.v-list-item {
    color: #e24a35 !important;
  }
}

[class^='icon-'] {
  width: 24px;
  height: 24px;
  background-color: var(--v-gray-base); /* defines the background color of the image */
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center;
}
</style>
