<template>
  <v-dialog
    v-model="isDialogOpen"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    sentry-tag="book-time-slot-dialog"
    width="746"
  >
    <v-card class="fill-height user-book-container">
      <div class="d-flex justify-end icon-container">
        <a sentry-tag="book-time-slot-dialog.button.close" @click="$emit('input', false)">
          <v-img contain height="24" src="@/assets/close-icon.svg" width="24" />
        </a>
      </div>
      <template v-if="additionalContactEnabled">
        <div
          class="fill-height user-book-detailed"
          v-html="$t('bookingModal.additionalContact', { email: additionalContactEmail })"
        ></div>
      </template>
      <template v-else>
        <div class="fill-height user-book-detailed">
          <div class="modal-header text-center">
            <div class="mb-2 font-weight-bold text-h5">
              {{ $t('bookingModal.title', { user: getFullName(user) }) }}
            </div>
          </div>
          <div>
            <m-user-availability-form v-if="isDialogOpen" :mentoring-session="mentoringSession" :user="user" />
          </div>
        </div>
      </template>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { getFullName } from '@/utils/user';
import { IMentoringSession, IMentoringSessionMember, IUser } from '@mentessa/types';
import MUserAvailabilityForm from '@/components/Dialogs/MUserDetailsDialog/MUserDetailsContent/forms/MUserAvailabilityForm.vue';

export default Vue.extend({
  name: 'm-book-time-slot-dialog',
  components: {
    MUserAvailabilityForm,
  },
  props: {
    user: { type: Object as PropType<IUser> },
    value: { type: Boolean, default: false },
    mentoringSession: { type: Object as PropType<IMentoringSession> },
    mentoringMember: { type: Object as PropType<IMentoringSessionMember> },
  },
  computed: {
    additionalContactEnabled() {
      return this.mentoringMember?.attributes?.contact?.enabled ?? false;
    },
    additionalContactEmail() {
      const email = this.mentoringMember?.attributes?.contact?.email;
      if (email) {
        const re = /(.*)?<(.*)>/.exec(email);
        if (re?.[2]) {
          return this.$marked(`[${re[1] ? re[1] : re[2]}](mailto:${re[2]})`);
        }
        return this.$marked(`[${email}](mailto:${email})`);
      }
      return '';
    },
    isDialogOpen: {
      get() {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    getFullName: getFullName,
  },
});
</script>

<style lang="scss" scoped>
.user-book-container {
  min-height: 80%;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .icon-container {
    padding-top: 18px;
    padding-right: 16px;
  }

  .user-book-detailed {
    padding: 8px 56px 36px;
  }
}
</style>
