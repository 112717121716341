import { MutationTree } from 'vuex';
import { Language, UtilsMutations, UtilsState } from '@/store/utils/types';
import { GeonamesInstance } from 'geonames.js/dist/geonames-types';

export const mutations: MutationTree<UtilsState> = {
  [UtilsMutations.SetLanguages](state: UtilsState, languages: Language[]) {
    state.languages = languages;
  },
  [UtilsMutations.SetGeonames](state: UtilsState, geonames: GeonamesInstance) {
    state.geonames = geonames;
  },
};
