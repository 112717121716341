<template>
  <div>
    <m-headline :description="$t('ritualShop.description')" :title="$t('ritualShop.title')" />
    <v-row class="justify-center ma-0 mt-8 gap-4">
      <m-ritual-card v-for="(ritual, index) in rituals" :key="index" :manage="canManage(ritual)" :value="ritual" />
      <m-add-ritual-card v-if="!loading && isRitualsManagingAvailable" />
    </v-row>
    <v-overlay :opacity="0.7" :value="loading" absolute color="var(--v-white-base)">
      <m-loader :isLoading="loading" />
    </v-overlay>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { getFullName } from '@/utils/user';
import { MatchingState } from '@/store/matching';
import { LoadManagedRituals, LoadRituals, RitualsState } from '@/store/rituals';
import { compareRituals } from '@/utils/rituals';
import MRitualCard from '@/components/Cards/MRitualCard';
import MAddRitualCard from '@/components/Cards/MAddRitualCard';
import { RouteName } from '@/router/types';
import MLoader from '@/components/MLoader';
import MHeadline from '@/components/MHeadline';
import { RitualReference } from '@mentessa/types';

export default Vue.extend({
  name: 'm-rituals-shop',
  components: { MLoader, MHeadline, MRitualCard, MAddRitualCard },
  data: () => ({
    loading: false,
    settingsRitualsRoute: { name: RouteName.SettingsRituals },
    managedRituals: [],
  }),
  computed: {
    ...mapGetters('tenant', {
      isRitualsManagingAvailable: 'isRitualsManagingAvailable',
    }),
    ...mapState<MatchingState>('rituals', {
      ritualsPage: (state: RitualsState) => {
        return state.rituals;
      },
    }),
    rituals() {
      if (!this.isRitualsManagingAvailable) {
        return this.ritualsPage.items.filter((ritual) => ritual.enabled).sort((l, r) => compareRituals(l, r));
      } else {
        return [...this.ritualsPage.items].sort((l, r) => compareRituals(l, r));
      }
    },
    pagination() {
      return this.ritualsPage.meta;
    },
  },
  async mounted() {
    try {
      this.loading = true;

      const managedRituals: Array<RitualReference> = await this.$store.dispatch(new LoadManagedRituals());
      this.managedRituals = managedRituals;

      if (!this.isRitualsManagingAvailable) {
        await this.$store.dispatch(new LoadRituals(1, { member: true }));
      } else {
        await this.$store.dispatch(new LoadRituals(1, { invitations: true, member: true }));
      }
    } finally {
      this.loading = false;
    }
  },
  methods: {
    getFullName: getFullName,
    canManage(ritualRef: RitualReference) {
      return (
        this.isRitualsManagingAvailable ||
        this.managedRituals.some((ritual: RitualReference) => ritual.id === ritualRef.id)
      );
    },
  },
});
</script>
