import Vue from 'vue';
import './logger';
import App from './App.vue';
import router from './router';
import store from './store';
import VueKeyCloak from '@dsb-norge/vue-keycloak-js';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import { api } from '@/store/api';
import { Initialize } from '@/store/types';
import { KeycloakConfig } from '@mentessa/types';
import InstantSearch from 'vue-instantsearch';
import VueObserveVisibility from 'vue-observe-visibility';
import VScrollLock from 'v-scroll-lock';
import VueSnip from 'vue-snip';
import axios from 'axios';
import sentry from '@/plugins/sentry';
import impersonate from '@/plugins/impersonate';
import InviteApp from '@/InviteApp.vue';
import OnboardingApp from '@/OnboardingApp.vue';
import LoginApp from '@/LoginApp.vue';
import TeamsApp from '@/TeamsApp.vue';
import NoAccessApp from '@/NoAccessApp.vue';
import { app } from '@/config';
import marked from './plugins/marked';
import { v4 as uuid } from 'uuid';
import MobileApp from '@/MobileApp.vue';

if (!Object.prototype.hasOwnProperty.call(window.crypto, 'randomUUID')) {
  window.crypto['randomUUID'] = uuid as () => `${string}-${string}-${string}-${string}-${string}`;
}

Vue.config.productionTip = false;

Vue.use(VueObserveVisibility);
Vue.use(InstantSearch);
Vue.use(VScrollLock, {
  bodyScrollOptions: {
    reserveScrollBarGap: true,
  },
});
Vue.use(VueSnip);

function tokenInterceptor() {
  api.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  // Hack for AIS
  // ToDo: Find the way to use api instance for AIS requests
  axios.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
  // axios.defaults.headers.common = {
  //   Authorization: `Bearer ${Vue.prototype.$keycloak.token}`,
  // };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function unauthorizedInterceptor(keycloak) {
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        console.error(`Unauthorized request.`);
        const noAccessHost = `${window.location.origin}/noaccess`;
        window.location.href = noAccessHost;
      } else if (error.response?.status === 403) {
        console.error(`You have no access to ${error.response.config.url}`);
      } else if (typeof error === 'string' && error === 'Not authenticated') {
        Vue.prototype.$keycloak.loginFn();
      }
      return Promise.reject(error);
    },
  );
}

function createMainApp() {
  api
    .get('tenants/current/initial')
    .then((response) => {
      const realmConfig: KeycloakConfig = response?.data?.keycloak;
      console.log('User realm:', realmConfig);
      console.log(`Sentry: ${response?.data?.sentry?.enabled}`);
      Vue.use(impersonate, app.impersonate);
      Vue.use(sentry, response?.data?.sentry);
      Vue.use(marked);
      Vue.use(VueKeyCloak, {
        logout: {
          redirectUri: window.location.origin,
        },
        onReady: (keycloak) => {
          if (!keycloak.authenticated) {
            const pathParts = window.location.pathname.split('/');
            if (pathParts.length === 3 && pathParts[1] === 'invites') {
              const invite = pathParts[2];
              if (!invite) {
                console.error(`No invite found: ${window.location.href}`);
              }
              localStorage.setItem('invite', invite);
              const params = new URLSearchParams(window.location.search);
              const action = params.get('action') === 'login' ? 'login' : 'register';
              const createUrl = action === 'login' ? keycloak.createLoginUrl : keycloak.createRegisterUrl;
              const url = `${createUrl({ redirectUri: window.location.href })}&invitation=${invite}`;
              window.location.href = url;
            } else {
              keycloak.login();
            }
          } else {
            tokenInterceptor();
            unauthorizedInterceptor(keycloak);
            new Vue({
              router,
              store,
              vuetify,
              i18n,
              render: (h) => h(App),
              async created() {
                await this.$store.dispatch(new Initialize(true, true, true));
              },
            }).$mount('#app');
          }
        },
        init: {
          onLoad: 'check-sso',
          checkLoginIframe: false,
        },
        config: realmConfig,
      });
    })
    .catch(() => {
      window.location.href = 'https://mentessa.com';
    });
}

function createInvitationApp() {
  api.get('tenants/current/initial').then((response) => {
    const realmConfig: KeycloakConfig = response?.data?.keycloak;
    console.log('User realm:', realmConfig);
    Vue.use(VueKeyCloak, {
      logout: {
        redirectUri: window.location.origin,
      },
      onReady: (keycloak) => {
        tokenInterceptor();
        unauthorizedInterceptor(keycloak);
        new Vue({
          store,
          vuetify,
          i18n,
          render: (h) => h(InviteApp),
        }).$mount('#app');
      },
      init: {
        onLoad: 'check-sso',
        checkLoginIframe: false,
      },
      config: realmConfig,
    });
  });
}

function createOnboardingApp() {
  api
    .get('tenants/current/initial')
    .then((response) => {
      const realmConfig: KeycloakConfig = response?.data?.keycloak;
      console.log('User realm:', realmConfig);
      Vue.use(VueKeyCloak, {
        logout: {
          redirectUri: window.location.origin,
        },
        onReady: (keycloak) => {
          if (!keycloak.authenticated) {
            keycloak.register();
          } else {
            tokenInterceptor();
            unauthorizedInterceptor(keycloak);
            new Vue({
              store,
              vuetify,
              i18n,
              render: (h) => h(OnboardingApp),
            }).$mount('#app');
          }
        },
        init: {
          onLoad: 'check-sso',
          checkLoginIframe: false,
        },
        config: realmConfig,
      });
    })
    .catch(() => {
      window.location.href = 'https://mentessa.com';
    });
}

function createLoginApp() {
  api
    .get('tenants/current/initial')
    .then((response) => {
      const realmConfig: KeycloakConfig = response?.data?.keycloak;
      console.log('User realm:', realmConfig);
      console.log(`Sentry: ${response?.data?.sentry?.enabled}`);
      Vue.use(impersonate, app.impersonate);
      Vue.use(sentry, response?.data?.sentry);
      Vue.use(VueKeyCloak, {
        logout: {
          redirectUri: window.location.origin,
        },
        onReady: (keycloak) => {
          tokenInterceptor();
          unauthorizedInterceptor(keycloak);
          new Vue({
            store,
            vuetify,
            i18n,
            render: (h) => h(LoginApp),
            async created() {
              await this.$store.dispatch(new Initialize(true, false));
            },
          }).$mount('#app');
        },
        init: {
          onLoad: 'login-required',
          checkLoginIframe: false,
        },
        config: realmConfig,
      });
    })
    .catch(() => {
      window.location.href = 'https://mentessa.com';
    });
}

function createTeamsApp() {
  api
    .get('tenants/current/initial')
    .then((response) => {
      console.log(`Sentry: ${response?.data?.sentry?.enabled}`);
      Vue.use(sentry, response?.data?.sentry);
      new Vue({
        vuetify,
        i18n,
        render: (h) => h(TeamsApp),
      }).$mount('#app');
    })
    .catch(() => {
      window.location.href = 'https://mentessa.com';
    });
}

function createNoAccessApp() {
  api.get('tenants/current/initial').then((response) => {
    const realmConfig: KeycloakConfig = response?.data?.keycloak;
    Vue.use(marked);
    Vue.use(VueKeyCloak, {
      logout: {
        redirectUri: window.location.origin,
      },
      onReady: (keycloak) => {
        tokenInterceptor();
        unauthorizedInterceptor(keycloak);
        new Vue({
          store,
          vuetify,
          i18n,
          render: (h) => h(NoAccessApp),
        }).$mount('#app');
      },
      init: {
        onLoad: 'check-sso',
        checkLoginIframe: false,
      },
      config: realmConfig,
    });
  });
}

function createMobileApp() {
  api
    .get('tenants/current/initial')
    .then((response) => {
      console.log(`Sentry: ${response?.data?.sentry?.enabled}`);
      Vue.use(sentry, response?.data?.sentry);
      new Vue({
        vuetify,
        i18n,
        store,
        render: (h) => h(MobileApp),
      }).$mount('#app');
    })
    .catch(() => {
      window.location.href = 'https://mentessa.com';
    });
}

function createApp() {
  const domain = window.location.hostname.split('.')[0];
  if (domain === 'new') {
    createOnboardingApp();
  } else if (domain === 'app') {
    createLoginApp();
  } else if (domain === 'teams') {
    createTeamsApp();
  } else if (window.location.pathname === '/invite') {
    createInvitationApp();
  } else if (window.location.pathname === '/noaccess') {
    createNoAccessApp();
  } else if (window.location.pathname === '/mobile') {
    createMobileApp();
  } else if (domain === 'auth') {
    //
  } else {
    createMainApp();
  }
}

createApp();
