import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { api } from '@/store/api';
import { ShowErrorNotification, ShowNotification } from '@/store/notifications';
import i18n from '@/plugins/i18n';
import { RitualTemplatesActions, RitualTemplatesMutations, RitualTemplatesState } from './types';
import { getIdOrCurrent } from '@/utils/api';
import { IRitualTemplate } from '@mentessa/types';

export const actions: ActionTree<RitualTemplatesState, RootState> = {
  async [RitualTemplatesActions.LoadRitualTemplates]({ commit, dispatch }, { tenantRef }) {
    try {
      const response = await api.get(`/tenants/${getIdOrCurrent(tenantRef)}/ritual-templates`);
      const ritualTemplates = response?.data ?? [];
      commit(RitualTemplatesMutations.SetRitualTemplates, ritualTemplates);
      return ritualTemplates;
    } catch (e) {
      console.error('Error while LoadRitualTemplates.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [RitualTemplatesActions.LoadRawRitualTemplates]({ commit, dispatch }) {
    try {
      const response = await api.get(`/ritual-templates`);
      const ritualTemplates = response?.data ?? [];
      commit(RitualTemplatesMutations.SetRawRitualTemplates, ritualTemplates);
      return ritualTemplates;
    } catch (e) {
      console.error('Error while LoadRawRitualTemplates.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [RitualTemplatesActions.UpdateRawRitualTemplate]({ commit, dispatch }, { ritualTemplateRef, dto }) {
    try {
      const response = await api.patch(`/ritual-templates/${ritualTemplateRef.id}`, dto);
      const ritualTemplate = response.data as IRitualTemplate;
      commit(RitualTemplatesMutations.UpdateRawRitualTemplate, { ritualTemplateRef, dto: ritualTemplate });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return ritualTemplate;
    } catch (e) {
      console.error('Error while UpdateRawRitualTemplate.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [RitualTemplatesActions.CreateRawRitualTemplate]({ commit, dispatch }, { dto }) {
    try {
      const response = await api.post('/ritual-templates', dto);
      const ritualTemplate: IRitualTemplate = response.data;
      commit(RitualTemplatesMutations.AppendRawRitualTemplate, { ritualTemplate });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response;
    } catch (e) {
      console.error('Error while CreateRawRitualTemplate.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [RitualTemplatesActions.DeleteRitualTemplate]({ commit, dispatch }, { ritualTemplateRef }) {
    try {
      const response = await api.delete(`/ritual-templates/${ritualTemplateRef.id}`);
      commit(RitualTemplatesMutations.RemoveRawRitualTemplate, { ritualTemplateRef });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response;
    } catch (e) {
      console.error('Error while DeleteRitualTemplate.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [RitualTemplatesActions.ChangeRitualTemplateEnabled](
    { commit, dispatch },
    { tenantRef, ritualTemplateRef, enabled },
  ) {
    try {
      const response = await api.patch(
        `/tenants/${getIdOrCurrent(tenantRef)}/ritual-templates/${ritualTemplateRef.id}`,
        { enabled },
      );
      commit(RitualTemplatesMutations.UpdateRitualTemplate, { ritualTemplateRef, update: { enabled } });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response;
    } catch (e) {
      console.error('Error while ChangeRitualTemplateEnabled.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
};
