<template>
  <div class="d-flex flex-column">
    <div class="d-flex align-center">
      <m-headline :title="$t('resources.title')" :description="$t('resources.description')" />
      <v-btn
        v-if="isResourcesManagingAvailable"
        color="button"
        class="ml-auto"
        depressed
        @click="newResourceDialog = true"
      >
        {{ $t('resources.add') }}
      </v-btn>
    </div>
    <v-chip-group v-model="selectedTags" class="resources-container__tags-list" multiple @change="reloadResources">
      <v-chip v-for="(tag, index) in resourcesTags" :key="index" filter>{{ tag }}</v-chip>
    </v-chip-group>
    <div class="d-flex m-resources-wrapper flex-column mx-auto mt-8" sentry-tag="resources">
      <m-resource-card
        v-for="(resource, index) in resources"
        :key="index"
        :resource="resource"
        @onDelete="deleteResource"
        @onEdit="editResource"
        @onHide="hideResource"
      />
      <v-row v-if="resourcesMeta.totalPages > 1" dense>
        <v-pagination
          v-model="page"
          :length="resourcesMeta.totalPages"
          :loading="loading"
          class="m-settings-members__table-pagination pa-6 ml-auto"
          sentry-tag="news.pagination"
        />
      </v-row>
    </div>
    <m-new-resource-dialog v-model="newResourceDialog" @onPublish="fetchResources" />
    <m-edit-resource-dialog v-model="editResourceDialog" :resource="editingResource" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import MResourceCard from '@/components/MResourceCard';
import { LoadResources, RemoveResource, ResourcesState, UpdateResource } from '@/store/resources';
import { IResource } from '@mentessa/types';
import { MEditResourceDialog, MNewResourceDialog } from '@/components/Dialogs';
import MHeadline from '@/components/MHeadline';

export default Vue.extend({
  name: 'm-resources',
  components: {
    MHeadline,
    MEditResourceDialog,
    MNewResourceDialog,
    MResourceCard,
  },
  data: () => ({
    newResourceDialog: false,
    editingResource: undefined,
    editResourceDialog: false,
    selectedTags: [],
    page: 1,
    loading: false,
  }),
  watch: {
    async page() {
      await this.fetchResources();
    },
  },
  computed: {
    ...mapState<ResourcesState>('resources', {
      resourcesPage: (state: ResourcesState) => state.resources,
      resourcesMeta: (state: ResourcesState) => state.resources.meta,
    }),

    resources() {
      return this.resourcesPage?.items ?? [];
    },
    ...mapGetters('tenant', {
      resourcesTags: 'resourcesTags',
      isResourcesManagingAvailable: 'isResourcesManagingAvailable',
    }),
  },
  async created() {
    await this.fetchResources();
  },
  methods: {
    editResource(resource: IResource) {
      this.editingResource = resource;
      this.editResourceDialog = true;
    },
    async deleteResource(resource: IResource) {
      try {
        this.loading = true;
        await this.$store.dispatch(new RemoveResource(resource));
      } finally {
        this.loading = false;
      }
    },
    async hideResource(resource: IResource) {
      try {
        this.loading = true;
        await this.$store.dispatch(new UpdateResource({ ...resource, enabled: false }));
      } finally {
        this.loading = false;
      }
    },
    reloadResources() {
      this.$store.dispatch(
        new LoadResources(
          1,
          this.selectedTags.map((tagIndex) => this.resourcesTags[tagIndex]),
        ),
      );
    },
    async fetchResources() {
      try {
        this.loading = true;
        await this.$store.dispatch(new LoadResources(this.page));
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.m-resources-wrapper {
  max-width: 800px;
  gap: 30px;
}

.resources-container__tags-list {
  display: flex;
  flex-wrap: wrap;
}
</style>
