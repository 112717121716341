import { ActionTree } from 'vuex';
import { RootState, StoreDataState } from '@/store/types';
import { api } from '@/store/api';
import { ShowErrorNotification } from '@/store/notifications';
import i18n from '@/plugins/i18n';
import { DasboardsActions, DasboardsMutations, DashboardsState } from './types';
import { getIdOrCurrent } from '@/utils/api';

export const actions: ActionTree<DashboardsState, RootState> = {
  async [DasboardsActions.LoadDashboardList]({ commit, dispatch }, { page, tenantRef }) {
    try {
      commit(DasboardsMutations.SetDasboardState, StoreDataState.Loading);
      const response = await api.get(`tenants/${getIdOrCurrent(tenantRef)}/dashboards`, { params: { page } });
      const dashboards = response?.data;
      if (dashboards?.items?.length) {
        commit(DasboardsMutations.SetDasboards, dashboards.items);
      } else {
        commit(DasboardsMutations.SetDasboardState, StoreDataState.Unknown);
      }
    } catch (e) {
      commit(DasboardsMutations.SetDasboardState, StoreDataState.Error);
      console.error('Error while SetDasboardState.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [DasboardsActions.LoadDashboard]({ dispatch }, { tenantRef, dashboardSettingsRef }) {
    try {
      const response = await api.get(`tenants/${getIdOrCurrent(tenantRef)}/dashboards/${dashboardSettingsRef.id}`);
      return response.data;
    } catch (e) {
      console.error('Error while LoadDashboard.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [DasboardsActions.LoadRitualDasboard]({ dispatch }, { tenantRef, ritualRef }) {
    try {
      const response = await api.get(`tenants/${getIdOrCurrent(tenantRef)}/dashboards`, {
        params: { ritual: ritualRef.id },
      });
      return response.data;
    } catch (e) {
      console.error('Error while LoadDashboard.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [DasboardsActions.LoadAdminDasboard]({ commit, dispatch }, { tenantRef }) {
    try {
      commit(DasboardsMutations.SetDasboardState, StoreDataState.Loading);
      const response = await api.get(`tenants/${getIdOrCurrent(tenantRef)}/dashboards`);
      const dashboard = response?.data;
      if (dashboard) {
        commit(DasboardsMutations.SetDasboards, dashboard);
      } else {
        commit(DasboardsMutations.SetDasboardState, StoreDataState.Unknown);
      }
    } catch (e) {
      commit(DasboardsMutations.SetDasboardState, StoreDataState.Error);
      console.error('Error while LoadAdminDasboard.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [DasboardsActions.CreateDasboard]({ dispatch }, { dashboard }) {
    try {
      const response = await api.post('dashboards', dashboard);
      return response?.status === 201 ?? false;
    } catch (e) {
      console.error('Error while CreateDasboard.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [DasboardsActions.DeleteDasboard]({ dispatch }, { dashboardRef }) {
    try {
      await api.delete(`dashboards/${dashboardRef.id}`);
    } catch (e) {
      console.error('Error while DeleteDasboard.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [DasboardsActions.UpdateDasboard]({ dispatch }, { dashboardRef, updateDto }) {
    try {
      const response = await api.put(`dashboards/${dashboardRef.id}`, updateDto);
      return response.data;
    } catch (e) {
      console.error('Error while UpdateDasboard.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [DasboardsActions.RetriveMetricsData]({ dispatch }, { dashboardRef, query }) {
    try {
      const response = await api.post(`dashboards/${dashboardRef.id}/execute`, { query });
      return response.data;
    } catch (e) {
      console.error('Error while RetriveMetricsData.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [DasboardsActions.RetriveWidgetData]({ dispatch }, { widget, tenantRef, filters }) {
    try {
      const response = await api.get(`tenants/${getIdOrCurrent(tenantRef)}/dashboards/data`, {
        params: { source: widget.source, ...(filters ?? {}) },
      });
      return response.data;
    } catch (e) {
      console.error('Error while RetriveWidgetData.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
};
