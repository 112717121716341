import { MutationTree } from 'vuex';
import { VideosMutations, VideosState } from './types';
import { IVideo, VideosPage } from '@mentessa/types';

export const mutations: MutationTree<VideosState> = {
  [VideosMutations.SetVideos](state: VideosState, videos: VideosPage) {
    state.videos = videos;
  },
  [VideosMutations.UpdateVideo](state: VideosState, video: IVideo) {
    state.videos.items = state.videos.items.map((videoItem) => {
      if (videoItem.id === video.id) {
        return { ...video };
      } else {
        return videoItem;
      }
    });
  },
  [VideosMutations.RemoveVideo](state: VideosState, video: IVideo) {
    state.videos.items = state.videos.items.filter((videoItem) => videoItem.id !== video.id);
  },
};
