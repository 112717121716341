import { moduleName, UsersState } from './types';
import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { PermissionLevel } from '@mentessa/types';

export * from './types';

export function usersGetter(getter: string) {
  return `${moduleName}/${getter}`;
}

function generateRandomOrder() {
  const availableIndexes = ['r0', 'r1', 'r2', 'r3', 'r4'];
  const count = Math.floor(Math.random() * 4);
  const pickedIndexes = new Array(count);
  for (let i = 0; i < pickedIndexes.length; ++i) {
    const targetIndex = Math.floor(Math.random() * availableIndexes.length);
    pickedIndexes[i] = `${availableIndexes[targetIndex]}:${Math.random() > 0.5 ? 'asc' : 'desc'}`;
    availableIndexes.splice(targetIndex, 1);
  }
  return pickedIndexes.join(',');
}

export const initialState: UsersState = {
  usersStream: {
    position: 0,
    seed: Math.floor(Math.random() * 10000),
    items: [],
    loading: false,
    finished: false,
  },
  detailedUser: undefined,
  me: undefined,
  schedules: [],
  permissions: {
    invites: PermissionLevel.None,
    customization: PermissionLevel.None,
    debug: PermissionLevel.None,
    communityRules: PermissionLevel.None,
    dashboard: PermissionLevel.None,
    matching: PermissionLevel.None,
    messages: PermissionLevel.None,
    rituals: PermissionLevel.None,
    videos: PermissionLevel.None,
    news: PermissionLevel.None,
    resources: PermissionLevel.None,
    content: PermissionLevel.None,
    nps: PermissionLevel.None,
  },
  members: {
    items: [],
    meta: {
      currentPage: 0,
      totalPages: 0,
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 0,
    },
  },
  sortingSequence: generateRandomOrder(),
};

export const usersModule: Module<UsersState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

export default usersModule;
