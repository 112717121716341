import { RouteName } from '@/router/types';
import {
  MSettingsFeaturesCommunityRules,
  MSettingsFeaturesContent,
  MSettingsFeaturesCustomization,
  MSettingsFeaturesDashboard,
  MSettingsFeaturesEmail,
  MSettingsFeaturesGoals,
  MSettingsFeaturesGroups,
  MSettingsFeaturesHelpRequests,
  MSettingsFeaturesHome,
  MSettingsFeaturesIntegrations,
  MSettingsFeaturesInvites,
  MSettingsFeaturesMatching,
  MSettingsFeaturesMentoring,
  MSettingsFeaturesMessages,
  MSettingsFeaturesMobile,
  MSettingsFeaturesNps,
  MSettingsFeaturesProfile,
  MSettingsFeaturesResources,
  MSettingsFeaturesRituals,
  MSettingsFeaturesUserIntegrations,
  MSettingsFeaturesUsers,
} from '@/components/Settings/MSettingsFeatures/features';

const settingsFeaturesRoutes = [
  {
    path: 'community_rules',
    name: RouteName.SettingsFeaturesCommunityRules,
    component: MSettingsFeaturesCommunityRules,
  },
  {
    path: 'customization',
    name: RouteName.SettingsFeaturesCustomization,
    component: MSettingsFeaturesCustomization,
  },
  {
    path: 'dashboard',
    name: RouteName.SettingsFeaturesDashboard,
    component: MSettingsFeaturesDashboard,
  },
  {
    path: 'email',
    name: RouteName.SettingsFeaturesEmail,
    component: MSettingsFeaturesEmail,
  },
  {
    path: 'goals',
    name: RouteName.SettingsFeaturesGoals,
    component: MSettingsFeaturesGoals,
  },
  {
    path: 'groups',
    name: RouteName.SettingsFeaturesGroups,
    component: MSettingsFeaturesGroups,
  },
  {
    path: 'help_requests',
    name: RouteName.SettingsFeaturesHelpRequests,
    component: MSettingsFeaturesHelpRequests,
  },
  {
    path: 'invites',
    name: RouteName.SettingsFeaturesInvites,
    component: MSettingsFeaturesInvites,
  },
  {
    path: 'matching',
    name: RouteName.SettingsFeaturesMatching,
    component: MSettingsFeaturesMatching,
  },
  {
    path: 'mentoring',
    name: RouteName.SettingsFeaturesMentoring,
    component: MSettingsFeaturesMentoring,
  },
  {
    path: 'messages',
    name: RouteName.SettingsFeaturesMessages,
    component: MSettingsFeaturesMessages,
  },
  {
    path: 'content',
    name: RouteName.SettingsFeaturesContent,
    component: MSettingsFeaturesContent,
  },
  {
    path: 'profile',
    name: RouteName.SettingsFeaturesProfile,
    component: MSettingsFeaturesProfile,
  },
  {
    path: 'rituals',
    name: RouteName.SettingsFeaturesRituals,
    component: MSettingsFeaturesRituals,
  },
  {
    path: 'users',
    name: RouteName.SettingsFeaturesUsers,
    component: MSettingsFeaturesUsers,
  },
  {
    path: 'resources',
    name: RouteName.SettingsFeaturesResources,
    component: MSettingsFeaturesResources,
  },
  {
    path: 'mobile',
    name: RouteName.SettingsFeaturesMobile,
    component: MSettingsFeaturesMobile,
  },
  {
    path: 'home',
    name: RouteName.SettingsFeaturesHome,
    component: MSettingsFeaturesHome,
  },
  {
    path: 'integrations',
    name: RouteName.SettingsFeaturesIntegrations,
    component: MSettingsFeaturesIntegrations,
  },
  {
    path: 'user_integrations',
    name: RouteName.SettingsFeaturesUserIntegrations,
    component: MSettingsFeaturesUserIntegrations,
  },
  {
    path: 'nps',
    name: RouteName.SettingsFeaturesNps,
    component: MSettingsFeaturesNps,
  },
];

export { settingsFeaturesRoutes };
export default settingsFeaturesRoutes;
