<template>
  <div>
    <v-text-field v-model="newTenant.name" label="Name" outlined />
    <v-text-field v-model="newTenant.domain" class="m-text-field" label="Domain" outlined />
    <v-text-field v-model="newTenant.tagline" class="m-text-field" label="TagLine" outlined />
    <v-text-field v-model="newTenant.color" class="m-text-field" label="Color" outlined />

    <v-btn class="ma-4" sentry-tag="settings-debug.button.createTenant" @click="createTenant"> Create tenant</v-btn>
  </div>
</template>

<script lang="ts">
import { CreateTenant } from '@/store/tenant';
import Vue from 'vue';

export default Vue.extend({
  name: 'm-settings-debug-new-tenant',
  data: () => ({
    newTenant: {
      name: undefined,
      domain: undefined,
      tagline: '',
      color: undefined,
    },
  }),
  methods: {
    async createTenant() {
      await this.$store.dispatch(
        new CreateTenant({
          name: this.newTenant.name,
          domain: this.newTenant.domain,
          tagLine: this.newTenant.tagline,
          color: this.newTenant.color,
          skipOwner: true,
        }),
      );
    },
  },
});
</script>
