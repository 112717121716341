<template v-if="true">
  <div class="section-settings-group" sentry-tag="settings-panel">
    <div class="section__entry mb-2 mb-md-0">
      <h4>{{ title }}</h4>
      <p class="mt-4">{{ description }}</p>
    </div>

    <div class="section__box">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'm-settings-panel',
  props: {
    title: {
      type: [String, Object /*as PropType<TranslateResult>*/],
      default: '',
    },
    description: {
      type: [String, Object /*as PropType<TranslateResult>*/],
      default: '',
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.section-settings-group {
  display: flex;
  justify-content: space-between;
  padding: 42px 0;

  .section__entry {
    width: 230px;

    p {
      color: var(--v-text-lighten5);
    }
  }

  .section__box {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 600px;
    padding: 24px 24px 24px;
    background: var(--v-white-base);
    border: 1px solid var(--v-beige-base);
    border-radius: 16px;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    display: block;
    padding: 24px 0 24px;

    .section__entry {
      width: auto;
    }
    .section__box {
      background: none;
      box-shadow: none;
      width: 100%;
      padding: 24px;
    }
  }
}
</style>
