<template>
  <div class="d-flex align-center gap-2">
    <v-img v-if="image" :height="imageSize" :src="image" :width="imageSize" contain />
    <h3 v-if="bold">{{ $t(`tools.${name}`) }}</h3>
    <span v-else>{{ $t(`tools.${name}`) }}</span>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'm-tool-preview',
  props: {
    name: String,
    bold: { type: Boolean, default: false },
    sm: { type: Boolean, default: false },
    lg: { type: Boolean, default: false },
  },
  computed: {
    imageSize() {
      if (this.lg) {
        return 32;
      } else if (this.sm) {
        return 16;
      }

      return 24;
    },
    image() {
      switch (this.name) {
        case 'whatsapp':
          return require('@/assets/tools/whatsapp.svg');
        case 'linkedin':
          return require('@/assets/tools/linkedin.svg');
        case 'teams':
          return require('@/assets/tools/microsoft-teams.svg');
        case 'workday':
          return require('@/assets/tools/workday.svg');
        case 'excel':
          return require('@/assets/tools/excel.svg');
        case 'slack':
          return require('@/assets/tools/slack.svg');
        case 'notion':
          return require('@/assets/tools/notion.svg');
        case 'discord':
          return require('@/assets/tools/discord.svg');
        case 'facebook':
          return require('@/assets/tools/facebook.svg');
      }
      return undefined;
    },
  },
});
</script>

<style lang="scss" scoped></style>
