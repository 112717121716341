import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { api } from '@/store/api';
import { ShowErrorNotification } from '@/store/notifications';
import i18n from '@/plugins/i18n';
import { ResourcesActions, ResourcesMutations, ResourcesState } from './types';

export const actions: ActionTree<ResourcesState, RootState> = {
  async [ResourcesActions.LoadResources]({ commit, dispatch }, { page, tags }) {
    try {
      const response = await api.get('resources', { params: { page, tags } });
      const resources = response?.data;
      commit(ResourcesMutations.SetResources, resources);
    } catch (e) {
      console.error('Error while LoadResources.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [ResourcesActions.AddResource]({ dispatch }, { resource }) {
    try {
      const response = await api.post('resources', resource);
      return response?.status === 201 ?? false;
    } catch (e) {
      console.error('Error while AddResource.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [ResourcesActions.RemoveResource]({ dispatch, commit }, { resource }) {
    try {
      await api.delete(`resources/${resource.id}`);
      commit(ResourcesMutations.RemoveResource, resource);
    } catch (e) {
      console.error('Error while RemoveResource.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [ResourcesActions.UpdateResource]({ dispatch, commit }, { resource }) {
    try {
      await api.put(`resources/${resource.id}`, resource);
      if (resource.enabled) {
        commit(ResourcesMutations.UpdateResource, resource);
      } else {
        commit(ResourcesMutations.RemoveResource, resource);
      }
    } catch (e) {
      console.error('Error while UpdateResource.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
};
