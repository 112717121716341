<template>
  <v-card class="user-card d-flex flex-column justify-space-between" sentry-tag="user-layout-column">
    <div>
      <m-user-info-card :value="user" x-large @click="onCardClick" />
      <div class="d-flex flex-wrap user-card__roles-wrapper">
        <v-chip v-for="(item, roleIndex) in roles" :key="roleIndex" class="my-1 mr-1" outlined>{{ item }}</v-chip>
      </div>
      <div class="d-flex pt-0 pb-10">
        <div class="user-card__tags-column pr-3">
          <m-tags-wrapper :label="$t('userCard.expertise')" :tags="user.expertiseTags" column />
        </div>
        <div class="user-card__tags-column pl-3">
          <m-tags-wrapper :label="$t('userCard.interests')" :tags="user.interestTags" column />
        </div>
      </div>
    </div>
    <v-btn
      class="user-card__meet-button"
      color="button"
      depressed
      large
      sentry-tag="user-layout-column.button.meet"
      @click="onCardClick"
    >
      {{ $t('userCard.meetBtn') }}
    </v-btn>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import MTagsWrapper from '@/components/MTagsWrapper';
import { UserEntity } from '@mentessa/types';
import MUserInfoCard from '@/components/Cards/MUserInfoCard';

export default Vue.extend({
  name: 'm-user-layout-column',
  components: { MUserInfoCard, MTagsWrapper },
  props: {
    user: Object as PropType<UserEntity>,
  },
  computed: {
    roles() {
      return (
        this.user?.attributes?.roles?.map((role) =>
          this.$te(`user.roles.${role}`) ? this.$t(`user.roles.${role}`) : role,
        ) ?? []
      );
    },
  },
  methods: {
    onCardClick() {
      this.$emit('onCardClick');
    },
  },
});
</script>

<style lang="scss" scoped>
@mixin user-icon-field {
  margin-bottom: 4px;
  display: flex;
  &:before {
    margin-right: 5px;
    position: relative;
    top: 2px;
  }
}

.user-card {
  padding: 26px 24px;
  width: 354px;
  height: 100%;

  &__roles-wrapper {
    height: 40px;
    min-width: 1px;
  }

  &__tags-column {
    width: 50%;
  }

  &__meet-button {
    :deep(.v-btn__content) {
      font-size: 16px;
      color: #ffffff;
    }
  }
}
</style>
