import { Action } from '@/store/types';
import { IVideo, VideosPage } from '@mentessa/types';

export const moduleName = 'videos';

export interface VideosState {
  videos: VideosPage;
}

export enum VideosActions {
  AddVideo = '[Videos] Add Video',
  RemoveVideo = '[Videos] remove Video',
  LoadVideos = '[Videos] Load Videos',
  UpdateVideo = '[Videos] Update Video',
}

export enum VideosMutations {
  SetVideos = '[Videos] Set Videos',
  UpdateVideo = '[Videos] Update Videos',
  RemoveVideo = '[Videos] Remove Videos',
}

export class LoadVideos implements Action {
  type = `${moduleName}/${VideosActions.LoadVideos}`;

  constructor(private readonly page: number = 1, private readonly tags?: Array<string>) {}
}

export class AddVideo implements Action {
  type = `${moduleName}/${VideosActions.AddVideo}`;

  constructor(private readonly video: Omit<IVideo, 'id'>) {}
}

export class UpdateVideo implements Action {
  type = `${moduleName}/${VideosActions.UpdateVideo}`;

  constructor(private readonly video: IVideo) {}
}

export class RemoveVideo implements Action {
  type = `${moduleName}/${VideosActions.RemoveVideo}`;

  constructor(private readonly video: IVideo) {}
}
