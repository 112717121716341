<template>
  <validation-observer v-slot="{ invalid }">
    <v-card :class="classes" class="d-flex flex-column pa-4 gap-4" max-width="450" width="450">
      ID: {{ localTemplate.id }}
      <m-text-field
        v-model="localTemplate.ritualAttributes.title"
        :placeholder="$t(`settings.rituals.knownNames.${localTemplate.type}`)"
        label="Title"
        validation-rules="required"
      />
      <m-text-area v-model="localTemplate.ritualAttributes.description" label="Description" />
      <m-select v-model="localTemplate.ritualAttributes.assetIcon" :items="assetIcons" label="Icon">
        <template v-slot:item="{ item }">
          <m-icon v-if="item !== 'custom'" :name="item" />
          <span v-else> {{ $t(`settings.rituals.ritualEdit.sectionName.icons.${item}`) }} </span>
        </template>
        <template v-slot:selection="{ item }">
          <m-icon v-if="item !== 'custom'" :name="item" />
          <span v-else> {{ $t(`settings.rituals.ritualEdit.sectionName.icons.${item}`) }} </span>
        </template>
      </m-select>
      <m-image-file-input v-if="hasCustomIcon" v-model="customIcon" required />

      <m-select v-model="localTemplate.type" :items="ritualTypes" label="Type" validation-rules="required">
        <template v-slot:selection="{ item }">
          {{ $t(`settings.ritual_templates.knownNames.${item}`) }}
        </template>
        <template v-slot:item="{ item }">
          {{ $t(`settings.ritual_templates.knownNames.${item}`) }}
        </template>
      </m-select>

      <m-select
        v-model="localTemplate.ritualAttributes.acceptanceRule"
        :items="acceptanceRules"
        label="How is the match confirmed?"
        validation-rules="required"
      >
        <template v-slot:selection="{ item }">
          {{ $t(`settings.ritual_templates.acceptance_rule.${item}`) }}
        </template>
        <template v-slot:item="{ item }">
          {{ $t(`settings.ritual_templates.acceptance_rule.${item}`) }}
        </template>
      </m-select>

      <v-expansion-panels multiple>
        <v-expansion-panel>
          <v-expansion-panel-header> Roles</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-switch
              v-model="localTemplate.ritualAttributes.roles.choose"
              class="mt-0"
              hide-details="auto"
              label="Users are free to chose between roles in program"
            />
            <v-switch
              v-model="localTemplate.ritualAttributes.roles.any"
              hide-details="auto"
              label="Users can stay open for any role"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header> Questionnaires</v-expansion-panel-header>

          <v-expansion-panel-content>
            <div
              v-for="(questionnaireTemplate, index) in localTemplate.ritualTemplateQuestionnaires"
              :key="`questionnaire-${index}`"
              class="d-flex align-center my-4"
            >
              <div class="flex-grow-1 m-questionnaire-block">
                <m-select
                  :items="roles"
                  :value="questionnaireRoleValue(index)"
                  label="Role"
                  @input="setQuestionnaireRoleValue(index, $event)"
                >
                  <template v-slot:selection="{ item }"> {{ $t(`mentoring.role.${item}`) }}</template>
                  <template v-slot:item="{ item }"> {{ $t(`mentoring.role.${item}`) }}</template>
                </m-select>
                <m-select
                  v-model="questionnaireTemplate.questionnaire"
                  :items="enrollQuestionnaires"
                  :menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                  }"
                  class="mt-2"
                  item-value="id"
                  label="Questionnaire"
                  return-object
                  validationRules="required"
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="questionnaireTemplate.questionnaire = undefined" @mousedown.prevent>
                      <v-list-item-content>
                        <v-list-item-title> Clear</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon :color="questionnaireTemplate.questionnaire?.id ? 'indigo darken-4' : ''">
                          mdi-close
                        </v-icon>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ item.description }}
                  </template>
                  <template v-slot:item="{ item }"> {{ item.description }} ({{ item.title }})</template>
                </m-select>
              </div>
              <v-btn icon @click="removeQuestionnaire(index)">
                <v-icon color="primary">mdi-minus-circle-outline</v-icon>
              </v-btn>
            </div>
            <v-btn class="ml-auto" icon @click="addQuestionnaire">
              <v-icon color="primary">mdi-plus-circle-outline</v-icon>
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Monthly Feedback</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-switch
              v-model="localTemplate.ritualAttributes.monthlyFeedback.enabled"
              hide-details="auto"
              label="Enabled"
            />
            <v-switch
              v-model="localTemplate.ritualAttributes.monthlyFeedback.reminderEnabled"
              hide-details="auto"
              label="Reminders Enabled"
            />
            <v-divider class="my-4" />
            <div
              v-for="(questionnaireTemplate, index) in localTemplate.ritualTemplateFeedbackQuestionnaires"
              :key="`feedback-${index}`"
              class="d-flex my-4 align-center"
            >
              <div class="flex-grow-1 m-questionnaire-block">
                <m-select
                  :items="roles"
                  :value="feedbackQuestionnaireRoleValue(index)"
                  label="Role"
                  @input="setFeedbackQuestionnaireRoleValue(index, $event)"
                >
                  <template v-slot:selection="{ item }"> {{ $t(`mentoring.role.${item}`) }}</template>
                  <template v-slot:item="{ item }"> {{ $t(`mentoring.role.${item}`) }}</template>
                </m-select>
                <m-select
                  v-model="questionnaireTemplate.questionnaire"
                  :items="feedbackQuestionnaires"
                  :menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                  }"
                  class="mt-2"
                  item-value="id"
                  label="Questionnaire"
                  return-object
                  validationRules="required"
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="questionnaireTemplate.questionnaire = undefined" @mousedown.prevent>
                      <v-list-item-content>
                        <v-list-item-title> Clear</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon :color="questionnaireTemplate.questionnaire?.id ? 'indigo darken-4' : ''">
                          mdi-close
                        </v-icon>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ item.description }}
                  </template>
                  <template v-slot:item="{ item }"> {{ item.description }} ({{ item.title }})</template>
                </m-select>
              </div>
              <v-btn icon @click="removeFeedbackQuestionnaire(index)">
                <v-icon color="primary">mdi-minus-circle-outline</v-icon>
              </v-btn>
            </div>
            <v-btn class="float-right" icon @click="addFeedbackQuestionnaire">
              <v-icon color="primary">mdi-plus-circle-outline</v-icon>
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-switch v-model="localTemplate.createLink" class="pa-0 ma-0" hide-details="auto" label="Create link" />
      <v-switch
        v-model="localTemplate.precreate"
        class="pa-0 ma-0"
        hide-details="auto"
        label="Precreate for a new tenant"
      />
      <v-switch v-model="localTemplate.ritualAttributes.demo" class="pa-0 ma-0" hide-details="auto" label="Demo" />
      <div class="d-flex justify-end gap-4">
        <template v-if="changed">
          <v-btn color="button" min-width="128px" outlined @click="cancel">Cancel</v-btn>
          <v-btn :disabled="invalid" color="button" min-width="128px" @click="save">Save</v-btn>
        </template>
        <v-btn v-else color="error" min-width="128px" @click="remove">{{ removeTitle }}</v-btn>
      </div>
    </v-card>
  </validation-observer>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import {
  AcceptanceRule,
  AssetIcon,
  IQuestionnaire,
  IRitualTemplate,
  IRitualTemplateFeedbackQuestionnaire,
  IRitualTemplateQuestionnaire,
  MentoringRole,
  RitualType,
} from '@mentessa/types';
import { MImageFileInput, MSelect, MTextArea, MTextField } from '@/components/Inputs';
import deepmerge from 'deepmerge';
import { ValidationObserver } from 'vee-validate';
import { CreateRawRitualTemplate, DeleteRawRitualTemplate, UpdateRawRitualTemplate } from '@/store/ritualTemplates';
import { diff } from 'deep-object-diff';
import MIcon from '@/components/MIcon/MIcon.vue';

type MentoringRoleWithAny = MentoringRole | 'any';

export default Vue.extend({
  name: 'm-ritual-template-edit-card',
  components: { MIcon, ValidationObserver, MImageFileInput, MTextArea, MSelect, MTextField },
  props: {
    ritualTemplate: { type: Object as PropType<IRitualTemplate> },
    questionnaires: { type: Array as PropType<Array<IQuestionnaire>>, default: () => [] },
  },
  data: () => ({
    ritualTypes: Object.values(RitualType),
    acceptanceRules: Object.values(AcceptanceRule),
    assetIcons: Object.values(AssetIcon),
    roles: ['any', ...Object.values(MentoringRole)] as Array<MentoringRoleWithAny>,
    localTemplate: {
      id: undefined,
      type: RitualType.SkillsMatching,
      ritualAttributes: {
        title: undefined,
        description: undefined,
        acceptanceRule: undefined,
        assetIcon: AssetIcon.Diamond,
        monthlyFeedback: {
          enabled: false,
          reminderEnabled: false,
        },
        roles: {
          choose: false,
          any: false,
        },
      },
      ritualTemplateQuestionnaires: [],
      ritualTemplateFeedbackQuestionnaires: [],
      createLink: false,
      precreate: false,
    } as IRitualTemplate,
    newIcon: undefined,
    feedbackEnabled: false,
  }),
  computed: {
    classes() {
      return {
        'm-dashed-card': this.ritualTemplate?.id == null,
      };
    },
    feedbackQuestionnaires() {
      return this.questionnaires?.filter((questionnaire) => questionnaire.attributes?.feedback === true);
    },
    enrollQuestionnaires() {
      return this.questionnaires?.filter((questionnaire) => !questionnaire.attributes?.feedback);
    },
    title() {
      return (
        this.localTemplate.ritualAttributes.title ?? this.$t(`settings.rituals.knownNames.${this.localTemplate.type}`)
      );
    },
    description() {
      return (
        this.localTemplate.ritualAttributes.description ??
        this.$t(`settings.rituals.knownDescriptions.${this.localTemplate.type}`)
      );
    },
    changed() {
      if (this.localTemplate.ritualAttributes.title !== this.ritualTemplate.ritualAttributes.title) {
        return true;
      }

      if (this.localTemplate.ritualAttributes.description !== this.ritualTemplate.ritualAttributes.description) {
        return true;
      }

      if (this.localTemplate.ritualAttributes.acceptanceRule !== this.ritualTemplate.ritualAttributes.acceptanceRule) {
        return true;
      }

      if (this.localTemplate.ritualAttributes.demo !== this.ritualTemplate.ritualAttributes.demo) {
        return true;
      }

      if (this.localTemplate.type !== this.ritualTemplate.type) {
        return true;
      }

      if (this.localTemplate.createLink !== this.ritualTemplate.createLink) {
        return true;
      }

      if (this.localTemplate.precreate !== this.ritualTemplate.precreate) {
        return true;
      }

      if (this.localTemplate.ritualAttributes.assetIcon !== this.ritualTemplate.ritualAttributes.assetIcon) {
        return true;
      }

      if (this.localTemplate.ritualAttributes.assetIcon === 'custom' && this.newIcon) {
        return true;
      }

      if (
        this.localTemplate.ritualTemplateQuestionnaires.length !==
        this.ritualTemplate.ritualTemplateQuestionnaires.length
      ) {
        return true;
      }

      for (let i = 0; i < this.localTemplate.ritualTemplateQuestionnaires.length; ++i) {
        if (
          this.localTemplate.ritualTemplateQuestionnaires[i].questionnaire?.id !==
          this.ritualTemplate.ritualTemplateQuestionnaires[i].questionnaire?.id
        ) {
          return true;
        }

        if (
          this.localTemplate.ritualTemplateQuestionnaires[i].filter?.role !==
          this.ritualTemplate.ritualTemplateQuestionnaires[i].filter?.role
        ) {
          return true;
        }
      }
      if (
        this.localTemplate.ritualTemplateFeedbackQuestionnaires.length !==
        this.ritualTemplate.ritualTemplateFeedbackQuestionnaires.length
      ) {
        return true;
      }

      for (let i = 0; i < this.localTemplate.ritualTemplateFeedbackQuestionnaires.length; ++i) {
        if (
          this.localTemplate.ritualTemplateFeedbackQuestionnaires[i].questionnaire?.id !==
          this.ritualTemplate.ritualTemplateFeedbackQuestionnaires[i].questionnaire?.id
        ) {
          return true;
        }

        if (
          this.localTemplate.ritualTemplateFeedbackQuestionnaires[i].filter?.role !==
          this.ritualTemplate.ritualTemplateFeedbackQuestionnaires[i].filter?.role
        ) {
          return true;
        }
      }

      return false;
    },
    defaultIconOption() {
      switch (this.localTemplate?.type as RitualType) {
        case RitualType.HappyHour:
          return AssetIcon.Cup;
        case RitualType.SkillsMatching:
          return AssetIcon.Hands;
        case RitualType.EventMatching:
          return AssetIcon.Diamond;
      }
      return AssetIcon.Diamond;
    },
    hasCustomIcon() {
      return this.localTemplate.ritualAttributes?.assetIcon === AssetIcon.Custom;
    },
    customIcon: {
      get() {
        if (this.newIcon) {
          return this.newIcon;
        }
        return { src: this.localTemplate.ritualAttributes?.icon };
      },
      set(value: { src: string; blob: Blob; type: string }) {
        this.newIcon = value;
      },
    },
    removeTitle() {
      return this.ritualTemplate?.id ? 'Delete' : 'Cancel';
    },
  },
  watch: {
    ritualTemplate() {
      this.updateTemplate();
    },
  },
  mounted() {
    this.updateTemplate();
  },
  methods: {
    questionnaireRoleValue(index: number) {
      return this.localTemplate.ritualTemplateQuestionnaires[index].filter?.role ?? 'any';
    },
    setQuestionnaireRoleValue(index: number, value: MentoringRoleWithAny) {
      this.localTemplate.ritualTemplateQuestionnaires[index].filter = value !== 'any' ? { role: value } : {};
    },
    feedbackQuestionnaireRoleValue(index: number) {
      return this.localTemplate.ritualTemplateFeedbackQuestionnaires[index].filter?.role ?? 'any';
    },
    setFeedbackQuestionnaireRoleValue(index: number, value: MentoringRoleWithAny) {
      this.localTemplate.ritualTemplateFeedbackQuestionnaires[index].filter = value !== 'any' ? { role: value } : {};
    },
    updateTemplate() {
      const template = deepmerge<IRitualTemplate>({}, this.ritualTemplate);
      if (template.ritualAttributes == null) {
        template.ritualAttributes = {};
      }
      if (template.ritualAttributes.monthlyFeedback == null) {
        template.ritualAttributes.monthlyFeedback = {
          enabled: false,
          reminderEnabled: false,
        };
      }
      if (template.ritualAttributes.roles == null) {
        template.ritualAttributes.roles = {
          choose: false,
          any: false,
        };
      }
      this.localTemplate = template;
    },
    cancel() {
      this.updateTemplate();
    },
    save() {
      if (this.ritualTemplate.id != null) {
        const dto = diff(this.ritualTemplate, this.localTemplate);
        if (Object.prototype.hasOwnProperty.call(dto, 'ritualTemplateQuestionnaires')) {
          dto['ritualTemplateQuestionnaires'] = this.localTemplate.ritualTemplateQuestionnaires.map(
            (ritualTemplateQuestionnaire: IRitualTemplateQuestionnaire) => ({
              ...ritualTemplateQuestionnaire,
              questionnaire: { id: ritualTemplateQuestionnaire.questionnaire.id },
            }),
          );
        }
        if (Object.prototype.hasOwnProperty.call(dto, 'ritualTemplateFeedbackQuestionnaires')) {
          dto['ritualTemplateFeedbackQuestionnaires'] = this.localTemplate.ritualTemplateFeedbackQuestionnaires.map(
            (ritualTemplateFeedbackQuestionnaire: IRitualTemplateFeedbackQuestionnaire) => ({
              ...ritualTemplateFeedbackQuestionnaire,
              questionnaire: { id: ritualTemplateFeedbackQuestionnaire.questionnaire.id },
            }),
          );
        }
        this.$store.dispatch(new UpdateRawRitualTemplate(this.ritualTemplate, dto));
      } else {
        this.$store.dispatch(new CreateRawRitualTemplate(this.localTemplate));
      }
    },
    remove() {
      if (this.ritualTemplate?.id) {
        this.$store.dispatch(new DeleteRawRitualTemplate(this.ritualTemplate));
      } else {
        this.$emit('onCancel');
      }
    },
    addQuestionnaire() {
      this.localTemplate.ritualTemplateQuestionnaires.push({
        questionnaire: undefined,
        filter: {},
        attributes: {},
      });
    },
    removeQuestionnaire(index: number) {
      this.localTemplate.ritualTemplateQuestionnaires.splice(index, 1);
    },
    addFeedbackQuestionnaire() {
      this.localTemplate.ritualTemplateFeedbackQuestionnaires.push({
        questionnaire: undefined,
        filter: {},
        attributes: {},
      });
    },
    removeFeedbackQuestionnaire(index: number) {
      this.localTemplate.ritualTemplateFeedbackQuestionnaires.splice(index, 1);
    },
  },
});
</script>

<style lang="scss" scoped>
.m-image-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
}

.m-questionnaire-block {
  border-left: var(--v-primary-base) 1px solid;
}

.m-dashed-card {
  border: 1px dashed var(--v-primary-base) !important;
}
</style>
