<template>
  <v-dialog v-model="isDialogOpen" max-width="746px" sentry-tag="new-video-dialog">
    <v-card :class="{ 'flex-column': this.$vuetify.breakpoint.xsOnly }" class="fill-height pa-6">
      <div class="d-flex justify-end">
        <a sentry-tag="edit-video-dialog.button.close" @click="$emit('input', false)">
          <v-img contain height="24" src="@/assets/close-icon.svg" width="24" />
        </a>
      </div>
      <validation-observer v-slot="{ invalid }" class="m-settings-dialog__form">
        <h1>{{ $t('settings.videos.edit') }}</h1>
        <m-text-field
          v-model="currentContent.title"
          :label="$t('settings.videos.dialogNew.title')"
          validation-rules="required"
          sentry-tag="new-video-dialog.input.title"
        />
        <m-text-field
          v-model="currentContent.content"
          :label="$t('settings.videos.dialogNew.url')"
          validation-rules="required"
          sentry-tag="new-video-dialog.input.url"
        />
        <m-combobox
          v-if="false"
          v-model="currentContent.attributes.tags"
          :label="$t('settings.videos.dialogNew.tags')"
          multiple
          sentry-tag="new-video-dialog.combobox.tags"
        />
        <v-chip-group v-model="currentContent.attributes.tags" column multiple>
          <v-chip v-for="(tag, index) in contentTags" :key="index" filter>{{ tag }}</v-chip>
        </v-chip-group>
        <div class="modal_foot text-center pt-4">
          <v-btn
            :disabled="invalid"
            color="button"
            depressed
            x-large
            @click="onPublish"
            sentry-tag="edit-video-dialog.button.add"
          >
            {{ $t('settings.videos.action.save') }}
          </v-btn>
        </div>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import { UpdateContent } from '@/store/content';
import { IContent } from '@mentessa/types';
import { MCombobox, MTextField } from '@/components/Inputs';

export default Vue.extend({
  name: 'm-edit-video-dialog',
  components: { MCombobox, MTextField, ValidationObserver },
  props: {
    value: { type: Boolean, default: false },
    content: { type: Object as PropType<IContent>, default: () => ({}) },
  },
  data: () => ({
    currentContent: undefined,
  }),
  watch: {
    content() {
      this.currentContent = {
        ...this.content,
        attributes: {
          ...this.content.attributes,
          tags: this.contentTags.reduce((prevValue, currentValue, index) => {
            const tagIndex = this.content.attributes?.tags.findIndex((item) => item === currentValue);
            if (tagIndex >= 0) {
              return [...prevValue, index];
            } else {
              return prevValue;
            }
          }, []),
        },
      };
    },
  },
  computed: {
    isDialogOpen: {
      get() {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    ...mapGetters('tenant', {
      contentTags: 'contentTags',
    }),
  },
  methods: {
    async onPublish() {
      await this.$store.dispatch(
        new UpdateContent({
          ...this.currentContent,
          attributes: {
            ...this.currentContent.attributes,
            tags: this.contentTags.filter((item, index) => this.currentContent.attributes.tags.includes(index)),
          },
        }),
      );
      this.$emit('input', false);
      this.$emit('onPublish');
    },
  },
});
</script>

<style lang="scss" scoped>
.m-settings-dialog__form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
}
</style>
