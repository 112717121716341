<template>
  <div :class="classes" class="box-list" sentry-tag="dashboard-widget-users-list">
    <h4>{{ title }}</h4>

    <div v-for="(item, index) in value" :key="`list-item-${index}`">
      <dl class="mt-4">
        <dt>
          <m-user-preview :user="item.title" />
        </dt>
        <dd>{{ item.value }}</dd>
      </dl>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { UserEntity } from '@mentessa/types';
import MUserPreview from '@/components/MUserPreview/UserPreviewPure.vue';

export interface UsersListWidgetValue {
  title: UserEntity;
  value: [string, number];
}

export default Vue.extend({
  name: 'm-dashboard-widget-users-list',
  components: { MUserPreview },
  props: {
    value: { type: Array as PropType<Array<UsersListWidgetValue>>, default: () => [] },
    title: [String, Object /*as PropType<TranslateResult>*/],
    nested: { type: Boolean, default: false },
  },
  computed: {
    classes() {
      return {
        'grid-item': !this.nested,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.box-list {
  padding: 24px;
  border-radius: 6px;
  background: var(--v-white-base);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  text-align: left;
}

dl {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
