<template>
  <v-col>
    <v-row>
      <p>Import users:</p>
    </v-row>
    <v-row class="align-center">
      <v-file-input
        v-model="file"
        accept="application/json"
        dense
        hide-details
        outlined
        placeholder="Put a JSON file here:"
      />
      <v-btn :disabled="!file || loading" class="ml-8" @click="processImportFile">Process</v-btn>
    </v-row>
    <v-row v-if="users.length > 0">
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-center"><span>#</span></th>
            <th class="text-center"><span>Preview</span></th>
            <th class="text-center"><span>Email</span></th>
            <th class="text-center"><span>Attributes</span></th>
            <th class="text-center"><span>Expertise</span></th>
            <th class="text-center"><span>Interests</span></th>
            <th class="text-center"><span>kcId</span></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(user, index) in users" :key="`import-user-${index}`">
            <td class="text-center">{{ index }}</td>
            <td>
              <m-user-preview :user="user" />
            </td>
            <td>{{ user.identity.attributes.email }}</td>
            <td>{{ user.attributes }}</td>
            <td>{{ user.expertiseTags?.map((tag) => tag.name) }}</td>
            <td>{{ user.interestTags?.map((tag) => tag.name) }}</td>
            <td>{{ user.identity.kcId }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-row>
    <v-row v-if="users.length > 0">
      <v-checkbox v-model="sendInvite" label="I want to send emails for all of those users" />
      <v-btn class="ml-auto" color="button" depressed @click="doImport" :loading="loading">Do Import</v-btn>
    </v-row>
  </v-col>
</template>

<script lang="ts">
import Vue from 'vue';
import { IUser } from '@mentessa/types';
import MUserPreview from '@/components/MUserPreview/UserPreviewPure.vue';
import { ImportMembers } from '@/store/tenant';

function readFileAsync(file): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsText(file);
  });
}

export default Vue.extend({
  name: 'm-import-users-json',
  components: { MUserPreview },
  data: () => ({
    file: undefined,
    sendInvite: false as boolean,
    users: [] as Array<Partial<IUser>>,
    content: undefined,
    loading: false as boolean,
  }),
  methods: {
    async processImportFile() {
      this.sendInvite = false;
      const content = await readFileAsync(this.file);
      const parsed = JSON.parse(content);
      this.users = parsed;
    },
    async doImport() {
      let totalCreated = 0;
      let totalUpdated = 0;
      try {
        this.loading = true;
        for (let start = 0; start < this.users.length; start += 50) {
          const data = await this.$store.dispatch(
            new ImportMembers(this.users.slice(start, start + 50), this.sendInvite),
          );
          totalCreated += data.usersCreated;
          totalUpdated += data.usersUpdated;
          console.log(data);
        }
        console.log('created:', totalCreated, 'updated:', totalUpdated);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
