<template>
  <m-panel>
    <m-feature-enable-switch feature="integrations" />
    <v-switch v-model="discord" class="mt-10" label="Discord" />
    <v-switch v-model="slack" label="Slack" />
  </m-panel>
</template>

<script lang="ts">
import Vue from 'vue';
import MFeatureEnableSwitch from '@/components/Settings/MSettingsFeatures/MFeatureEnableSwitch';
import { mapGetters, mapState } from 'vuex';
import { ChangeFeatureConfig, TenantState } from '@/store/tenant';
import { IntegrationsFeature } from '@mentessa/types';
import MPanel from '@/components/MPanel';

export default Vue.extend({
  name: 'm-settings-features-integrations',
  components: { MPanel, MFeatureEnableSwitch },
  data: () => ({
    discord: undefined,
    slack: undefined,
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    ...mapGetters('tenant', {
      featureConfig: 'featureConfig',
    }),
    integrationsConfig() {
      return this.featureConfig('integrations');
    },
  },
  methods: {
    async save() {
      const config: Partial<IntegrationsFeature> = {};

      if (!!this.discord !== !!this.integrationsConfig.discord) {
        config.discord = this.discord;
      }

      if (!!this.slack !== !!this.integrationsConfig.slack) {
        config.slack = this.slack;
      }

      if (JSON.stringify(config) !== '{}') {
        await this.$store.dispatch(new ChangeFeatureConfig(this.tenant, 'integrations', config));
      }
    },
    updateData() {
      this.discord = this.integrationsConfig.discord;
      this.slack = this.integrationsConfig.slack;
    },
  },
});
</script>
