<template>
  <v-chip-group v-model="enabledFilters" class="mb-4 flex-wrap" column multiple>
    <ais-clear-refinements>
      <template v-slot="{ refine }">
        <v-chip @click="refine">{{ $t('filters.quick_filters.all') }}</v-chip>
      </template>
    </ais-clear-refinements>

    <template v-for="filter in filters">
      <ais-refinement-list
        v-if="filter.enabled"
        :key="filter.name"
        :attribute="filter.attribute"
        :label="filter.label"
        :limit="0"
        operator="or"
      >
        <template v-slot="{ refine }">
          <v-chip :value="filter.name" filter @click="refineFilter(filter, refine)"
            >{{ getFilterLabel(filter) }}
          </v-chip>
        </template>
      </ais-refinement-list>
    </template>
    <v-chip @click="$emit('onAllFiltersClicked')">{{ $t('filters.quick_filters.all_filters') }}</v-chip>
  </v-chip-group>
</template>

<script lang="ts">
import Vue from 'vue';
import { UsersState } from '@/store/users';
import { mapGetters, mapState } from 'vuex';
import { SearchState } from '@/store/search';
import { LoadTopSkills, TenantState } from '@/store/tenant';
import { ITag, QuickFilterConfig, QuickFilterValueSource } from '@mentessa/types';

export default Vue.extend({
  name: 'm-ais-quick-filters',
  data: () => ({
    trandingInterests: [] as Array<string>,
    trandingExpertise: [] as Array<string>,
  }),

  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    ...mapState<SearchState>('search', {
      activeFacets: (state: SearchState) => state.activeFacets,
    }),
    ...mapState<TenantState>('tenant', {
      customRoles: (state: TenantState) => state.tenant?.attributes?.customRoles ?? [],
    }),
    ...mapGetters('tenant', {
      featureConfig: 'featureConfig',
    }),
    quickFiltersFeature() {
      return this.featureConfig('quickFilters');
    },
    filtersConfig(): Array<QuickFilterConfig> {
      const filters = [];
      filters.push(...(this.quickFiltersFeature.filters ?? []));
      filters.push(...(this.quickFiltersFeature.addFilters ?? []));
      if (this.quickFiltersFeature.addRoles) {
        this.customRoles.forEach((role: string) => {
          filters.push({
            name: role,
            values: [role],
            attribute: 'roles',
          });
        });
      }
      return filters;
    },
    filters() {
      return this.filtersConfig.map((filter: QuickFilterConfig) => ({
        ...filter,
        enabled: this.isFilterHasValues(filter),
      }));
    },
    userLocation() {
      return this.me?.attributes?.location;
    },
    enabledFilters: {
      get() {
        const filters = [];

        this.filters.forEach((filter: QuickFilterConfig) => {
          const activeValues = this.activeFacets[filter.attribute] ?? [];
          if (activeValues.length === 0) {
            return;
          }

          const filterValues = this.getFilterValues(filter);
          if (filterValues.length === 0) {
            return;
          }

          if (filterValues.every((value: string) => activeValues.includes(value))) {
            filters.push(filter.name);
          }
        });
        return filters;
      },
      set() {
        // Do nothing here. The search state is controlled by refine method
      },
    },
  },
  watch: {
    //
  },
  async mounted() {
    const skills = await this.$store.dispatch(new LoadTopSkills());
    this.trandingInterests = skills.expertise.map((skill: ITag) => skill.name);
    this.trandingExpertise = skills.interests.map((skill: ITag) => skill.name);
  },
  methods: {
    getFilterValues(filter: QuickFilterConfig): Array<string> {
      if (filter.values) {
        return filter.values;
      } else if (filter.valuesSource === QuickFilterValueSource.Interests) {
        return this.me?.interestTags?.map((tag: ITag) => tag.name) ?? [];
      } else if (filter.valuesSource === QuickFilterValueSource.Expertise) {
        return this.me?.expertiseTags?.map((tag: ITag) => tag.name) ?? [];
      } else if (filter.valuesSource === QuickFilterValueSource.Location) {
        return this.me?.attributes?.location ? [this.me.attributes.location] : [];
      } else if (filter.valuesSource === QuickFilterValueSource.TrandingInterest) {
        return this.trandingInterests;
      } else if (filter.valuesSource === QuickFilterValueSource.TrandingExpertise) {
        return this.trandingExpertise;
      }
      return [];
    },
    getFilterLabel(filter: QuickFilterConfig) {
      if (this.$te(`filters.quick_filters.known_names.${filter.name}`)) {
        return this.$t(`filters.quick_filters.known_names.${filter.name}`);
      }
      return filter.name;
    },
    isFilterHasValues(filter: QuickFilterConfig) {
      return this.getFilterValues(filter).length > 0;
    },
    isValueRefined(attribute: string, value: string) {
      return this.activeFacets[attribute]?.includes(value) ?? false;
    },
    refineFilter(filter: QuickFilterConfig, refine) {
      const isFilterActive = this.enabledFilters.includes(filter.name);

      const values = this.getFilterValues(filter);

      for (const value of values) {
        if (this.isValueRefined(filter.attribute, value) === isFilterActive) {
          refine(value);
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.v-chip--active {
  color: var(--v-primary-base);
}
</style>
