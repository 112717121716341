<template>
  <div :style="style" class="d-flex justify-space-between py-4 m-section-container">
    <div>
      <h3 class="text--text mb-2">{{ $t('settings.rituals.ritualEdit.sectionMatching.title') }}</h3>
      <div class="text--text text--lighten-5">{{ $t('settings.rituals.ritualEdit.sectionMatching.subtitle') }}</div>
    </div>
    <div class="form_container">
      <div class="text--text text--lighten-1 mb-2">
        {{ $t('settings.rituals.ritualEdit.sectionMatching.labelFrequency') }}
      </div>
      <m-select v-model="frequency" :items="periods" class="pr-4" sentry-tag="ritual-edit-dialog.select.weekday">
        <template v-slot:selection="{ item }">
          {{ $t(`profile.matching.frequency.period.${item}`) }}
        </template>
        <template v-slot:item="{ item }">
          {{ $t(`profile.matching.frequency.period.${item}`) }}
        </template>
      </m-select>
      <div class="text--text text--lighten-1 mb-2 mt-5">
        {{ $t('settings.rituals.ritualEdit.sectionMatching.labelWeekday') }}
      </div>
      <template v-if="useWeekday">
        <m-select v-model="ritualWeekday" :items="weekdays" class="pr-4" sentry-tag="ritual-edit-dialog.select.weekday">
          <template v-slot:selection="{ item }">
            {{ $t(`weekdays.full.${item}`) }}
          </template>
          <template v-slot:item="{ item }">
            {{ $t(`weekdays.full.${item}`) }}
          </template>
        </m-select>
        <div class="text--text text--lighten-1 mb-4" v-html="infoMatchingWeekday"></div>
      </template>
      <template v-else>
        <m-text-field v-model="ritualDay" :max="31" :min="1" type="number" />
        <div class="text--text text--lighten-1 mb-4" v-html="infoMatchingWeekday"></div>
      </template>
      <div class="text--text text--lighten-1 mb-2 mt-5">
        {{ $t('settings.rituals.ritualEdit.sectionMatching.labelHour') }}
      </div>
      <v-time-picker
        ref="timeRef"
        v-model="time"
        class="custom-time-picker"
        color="primary"
        format="24hr"
        @click:hour="onTimeChange"
      />
      <m-time-zone-picker v-model="tz" :label="$t('profile.tz')" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IRitual, RitualSchedulePeriodicFrequency } from '@mentessa/types';
import { MSelect, MTimeZonePicker } from '@/components/Inputs';
import deepmerge from 'deepmerge';
import MTextField from '@/components/Inputs/MTextField/MTextField.vue';
import { mapGetters } from 'vuex';
import { subMilliseconds } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';

export default Vue.extend({
  name: 'm-ritual-edit-section-happy-hour',
  components: {
    MTimeZonePicker,
    MTextField,
    MSelect,
  },
  props: {
    value: { type: Object as PropType<IRitual>, default: () => ({}) },
  },
  data: () => ({
    weekdays: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
    periods: ['weekly', 'biweekly', 'monthly'],
  }),
  computed: {
    ...mapGetters('ui', {
      currentUserTz: 'currentUserTz',
    }),
    time: {
      get() {
        return `${this.value?.attributes?.schedule?.options?.periodic?.hour}:00`;
      },
      set(timeUpdate: string) {
        const today = new Date();
        const hours = Number(timeUpdate.split(':')[0]);

        const utcDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), hours, 0, 0, 0));
        const targetDate = subMilliseconds(utcDate, getTimezoneOffset(this.tz));

        const ritualUpdate = {
          attributes: {
            schedule: {
              options: {
                periodic: {
                  hour: Number(String(timeUpdate.split(':')[0]).replace('00', '0')),
                  tz: this.tz,
                  date: targetDate,
                },
              },
            },
          },
        };
        const update = deepmerge(this.value, ritualUpdate);
        this.$emit('input', update);
      },
    },
    tz: {
      get() {
        return this.value?.attributes?.schedule?.options?.periodic?.tz ?? this.currentUserTz;
      },
      set(value: string) {
        const today = new Date();
        const hours = Number(this.time.split(':')[0]);

        const utcDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), hours, 0, 0, 0));
        const targetDate = subMilliseconds(utcDate, getTimezoneOffset(value));

        const ritualUpdate = {
          attributes: {
            schedule: {
              options: {
                periodic: {
                  hour: Number(String(this.time.split(':')[0]).replace('00', '0')),
                  tz: value,
                  date: targetDate,
                },
              },
            },
          },
        };
        const update = deepmerge(this.value, ritualUpdate);
        this.$emit('input', update);
      },
    },
    useWeekday() {
      return [RitualSchedulePeriodicFrequency.Weekly, RitualSchedulePeriodicFrequency.BiWeekly].includes(
        this.frequency,
      );
    },
    matchingWeekday() {
      return this.value?.attributes?.schedule?.options?.periodic?.weekday;
    },
    ritualWeekday: {
      get() {
        if (this.matchingWeekday != null) {
          return this.weekdays[(this.weekdays.indexOf(this.matchingWeekday) + 1) % this.weekdays.length];
        }
        return undefined;
      },
      set(weekdayUpdate: string) {
        const previousWeekday = (weekday) => {
          const weekdayNumber = this.weekdays.indexOf(weekday);
          if (weekdayNumber === 0) return this.weekdays[this.weekdays.length - 1];
          return this.weekdays[weekdayNumber - 1];
        };
        const matchingDay = previousWeekday(weekdayUpdate);
        const ritualUpdate = {
          attributes: {
            schedule: {
              options: {
                periodic: {
                  weekday: matchingDay,
                },
              },
            },
          },
        };
        const update = deepmerge(this.value, ritualUpdate);
        this.$emit('input', update);
      },
    },
    ritualDay: {
      get() {
        const day = this.value?.attributes?.schedule?.options?.periodic?.day;
        if (!day) {
          return 1;
        }
        return day % 32;
      },
      set(day: string) {
        if (!day || day === '0') {
          day = '1';
        }
        const ritualUpdate = {
          attributes: {
            schedule: {
              options: {
                periodic: {
                  day: Number(day),
                },
              },
            },
          },
        };
        const update = deepmerge(this.value, ritualUpdate);
        this.$emit('input', update);
      },
    },
    matchingDay() {
      if (this.ritualDay != null) {
        const day = this.ritualDay - 1;
        if (day < 1) {
          return 31;
        }
        return day;
      }
      return undefined;
    },
    frequency: {
      get() {
        return this.value?.attributes?.schedule?.options?.periodic?.frequency;
      },
      set(frequencyUpdate: string) {
        const ritualUpdate = {
          attributes: {
            schedule: {
              options: {
                periodic: {
                  frequency: frequencyUpdate,
                },
              },
            },
          },
        };
        const update = deepmerge(this.value, ritualUpdate);
        this.$emit('input', update);
      },
    },
    style() {
      return {
        '--m-flex-direction': this.$vuetify.breakpoint.smAndDown ? 'column' : 'row',
        '--m-text-align': this.$vuetify.breakpoint.smAndDown ? 'center' : '',
      };
    },
    infoMatchingWeekday() {
      return this.$marked(
        this.$t('settings.rituals.ritualEdit.sectionMatching.infoMatchingWeekday', {
          weekday: this.useWeekday ? this.$t(`weekdays.full.${this.matchingWeekday}`) : this.matchingDay,
        }),
      );
    },
  },
  mounted() {
    if (this.value && !this.value.attributes.schedule.options.periodic.tz) {
      this.tz = this.currentUserTz;
    }
  },
  methods: {
    onTimeChange() {
      this.$nextTick(() => {
        this.$refs.timeRef.selecting = 1;
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.form_container {
  padding: 24px;
  width: 100%;
  background-color: var(--v-white-base);
  border: 1px solid var(--v-beige-base);
  border-radius: 16px;
  max-width: 600px;
}

.custom-time-picker .v-time-picker-title {
  pointer-events: none;
}

.v-time-picker-title__time > *:not(:first-child) {
  display: none;
}

.m-section-container {
  flex-direction: var(--m-flex-direction);
  text-align: var(--m-text-align);
  gap: 15px;
}
</style>
