<template>
  <v-dialog v-model="isDialogOpen" width="576px" sentry-tag="new-invitation-link-dialog">
    <v-card :class="{ 'flex-column': this.$vuetify.breakpoint.xsOnly }" class="fill-height pa-4">
      <div class="m-close-icon-container">
        <a @click="$emit('input', false)" class="m-close-icon" sentry-tag="new-invitation-link-dialog.button.close">
          <v-img contain height="24" src="@/assets/close-icon.svg" width="24" />
        </a>
      </div>
      <div class="modal__inner px-10">
        <div class="modal_head mb-8">
          <h4 class="mb-4 text-center">{{ $t('settings.invitations.newDialog.title') }}</h4>
          <p class="mb-4 text-center subtitle-2" style="color: #64748a">
            {{ $t('settings.invitations.newDialog.description') }}
          </p>
        </div>

        <validation-observer v-slot="{ invalid }">
          <div class="m-invitation-dialog__form">
            <m-text-field
              v-model="name"
              :label="$t('settings.invitations.table.nameOptional')"
              validation-rules="max:50"
              sentry-tag="new-invitation-link-dialog.input.name"
            />

            <m-text-field
              v-model="limit"
              :label="$t('settings.invitations.table.limit')"
              validation-rules="required|between:1,1000"
              sentry-tag="new-invitation-link-dialog.input.limit"
            />
          </div>

          <div class="modal_foot text-center pt-4">
            <v-btn
              :disabled="invalid"
              color="button"
              x-large
              depressed
              @click="createInvite"
              sentry-tag="new-invitation-link-dialog.button.createInvite"
            >
              {{ $t('settings.invitations.newDialog.create') }}
            </v-btn>
          </div>
        </validation-observer>
      </div>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import { CreateInvite } from '@/store/invites';
import { ValidationObserver } from 'vee-validate';
import MTextField from '@/components/Inputs/MTextField/MTextField.vue';

export default Vue.extend({
  name: 'm-new-invitation-link-dialog',
  components: {
    MTextField,
    ValidationObserver,
  },
  props: {
    value: { type: Boolean, default: false },
  },
  data: () => ({
    limit: 10,
    name: '',
    isAdmin: false,
    isMentor: false,
  }),
  computed: {
    isDialogOpen: {
      get() {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    createInvite() {
      if (
        this.$store.dispatch(
          new CreateInvite({
            limit: this.limit,
            attributes: { name: this.name, isMentor: this.isMentor, isAdmin: this.isAdmin },
          }),
        )
      ) {
        this.$emit('input', false);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.m-invitation-dialog__form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.m-close-icon-container {
  display: flex;
  justify-content: flex-end;
}
</style>
