<template>
  <validation-provider ref="validator" v-slot="{ errors }" :rules="{ size: true, required: required }" tag="div">
    <div
      :style="style"
      class="m-image-preview d-flex justify-center align-center flex-column"
      @drop.prevent="onDrop($event)"
      @dragover.prevent
    >
      <v-file-input ref="fileInput" v-model="file" accept="image/*" class="d-none" @change="loadImage" />
      <v-btn class="m-upload-button" depressed outlined @click="triggerImagePicker">
        <v-icon class="mr-2">mdi-cloud-upload-outline</v-icon>
        {{ isUploaded ? $t('onboarding.replace') : $t('onboarding.Upload') }}
      </v-btn>
    </div>
    <div v-if="errors.length > 0 || forceErrors.length > 0" class="text-center error--text">
      {{ [...errors, ...forceErrors][0] }}
    </div>
  </validation-provider>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { getMimeType } from '@/utils/image';
import { ImageStepValue } from '@/components/MOnboarding/Steps/MFormImageStep/FormImageStep.vue';
import { extend, setInteractionMode, ValidationProvider } from 'vee-validate';

export default Vue.extend({
  name: 'm-image-file-input',
  components: { ValidationProvider },
  props: {
    value: { type: Object as PropType<ImageStepValue> },
    maxSize: { type: Number, default: 2 },
    validationMode: { type: String, default: 'aggressive' },
    required: { type: Boolean, default: false },
  },
  data: () => ({
    file: null,
  }),
  computed: {
    forceErrors() {
      if (this.required && !this.value?.src) {
        return [this.$t('validation.required')];
      }
      return [];
    },
    style() {
      return {
        '--background-image': this.value?.src ? `url(${this.value.src})` : undefined,
        '--border-image': this.value?.src ? '' : '2px dashed var(--v-lightbg-darken2)',
      };
    },
    localFile: {
      get() {
        return this.value;
      },
      set(value: ImageStepValue) {
        this.$emit('input', value);
      },
    },
    isUploaded() {
      return Boolean(this.localFile.src);
    },
  },
  methods: {
    triggerImagePicker() {
      this.$refs.fileInput.$refs.input.click();
    },
    loadImage(file: File) {
      if (file) {
        if (this.localFile?.src) {
          URL.revokeObjectURL(this.localFile?.src);
        }
        const url = URL.createObjectURL(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          this.localFile = {
            src: url,
            blob: file,
            type: getMimeType(e.target.result as ArrayBuffer, file.type),
          };
        };
        reader.readAsArrayBuffer(file);
      }
    },
    onDrop(e) {
      try {
        const file = e.dataTransfer.files[0];
        this.loadImage(file);
      } catch {
        console.log('Unable to Upload!');
      }
    },
  },
  watch: {
    validationMode(mode: string) {
      setInteractionMode(mode);
    },
  },
  created() {
    setInteractionMode(this.validationMode);
    extend('size', {
      validate: (value) => {
        if (value.size / (1024 * 1024) > this.maxSize) {
          return this.$t('validation.size', { size: this.maxSize });
        }
        return true;
      },
    } as never);
    extend('required', {
      validate: (value) => value?.size > 0,
      message: this.$t('validation.required'),
    } as never);
  },
});
</script>

<style lang="scss" scoped>
.m-image-preview {
  width: 100%;
  height: 200px;
  gap: 15px;
  border-radius: 7px;
  border: var(--border-image);
  background-size: cover !important;
  background-position: center !important;
  background: var(--background-image);
  background-repeat: no-repeat;
  background-size: contain !important;
}

.m-upload-button {
  background-color: var(--v-white-base);
}
</style>
