<template>
  <validation-provider v-slot="{ errors }" :debounce="validationDebounce" :name="label" :rules="validationRules" slim>
    <v-combobox
      :error-messages="errors"
      :label="labelWithRequired"
      :search-input.sync="search"
      sentry-tag="combobox"
      append-icon=""
      v-bind="{ ...$props, ...$attrs }"
      v-on="$listeners"
      ref="combobox"
    >
      <template v-if="search" v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <span v-html="$t('components.combobox.hintCreate', { name: search })"></span>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="addItem">
              <v-icon color="primary">mdi-plus-circle-outline</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-combobox>
  </validation-provider>
</template>

<script lang="ts">
import { max, required } from 'vee-validate/dist/rules';
import { extend, setInteractionMode, ValidationProvider } from 'vee-validate';
import Vue from 'vue';

export default Vue.extend({
  name: 'm-combobox',
  components: {
    ValidationProvider,
  },
  props: {
    multiple: { type: Boolean, default: true },
    chips: { type: Boolean, default: true },
    deletableChips: { type: Boolean, default: true },
    hideNoData: { type: Boolean, default: false },
    label: [String, Object /*as PropType<TranslateResult>*/],
    outlined: { type: Boolean, default: true },
    hideDetails: { type: String, default: 'auto' },
    validationRules: String,
    validationDebounce: Number,
    validationMode: { type: String, default: 'aggressive' },
    requiredSuffix: { type: String, default: ' *' },
  },
  data: () => ({
    search: '',
  }),
  computed: {
    isRequired() {
      return this.validationRules?.includes('required') ?? false;
    },
    labelWithRequired() {
      return this.isRequired ? `${this.label ?? ''}${this.requiredSuffix}` : this.label;
    },
  },

  watch: {
    validationMode(mode: string) {
      setInteractionMode(mode);
    },
  },
  created() {
    setInteractionMode(this.validationMode);

    extend('required', {
      ...required,
      message: this.$t('validation.required'),
    } as never);

    extend('max', {
      ...max,
      message: this.$t('validation.max'),
    } as never);
  },
  methods: {
    addItem() {
      this.$refs.combobox.blur();
    },
  },
});
</script>
