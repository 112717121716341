import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { InvitesState, moduleName } from './types';

export * from './types';

export function invitesGetter(getter: string) {
  return `${moduleName}/${getter}`;
}

export const initialState: InvitesState = {
  invites: {
    items: [],
    meta: {
      currentPage: 0,
      totalPages: 0,
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 0,
    },
  },
  current: undefined,
};

export const invitesModule: Module<InvitesState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

export default invitesModule;
