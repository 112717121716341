<template>
  <div>
    <v-col>
      <v-row>
        <v-btn class="ma-4" @click="loadClient">Load client</v-btn>
        <v-btn class="ma-4" @click="updateClient">Update client</v-btn>
        <v-btn class="ma-4" @click="createClient">Create client</v-btn>
      </v-row>
      <m-text-area v-model="clientData"></m-text-area>
      <v-row>
        <v-btn class="ma-4" @click="loadAllClient">Load All Clients</v-btn>
        <v-btn class="ma-4" @click="updateAllClient">Update All Clients</v-btn>
        <v-btn :disabled="true" class="ma-4" @click="createAllClient">Create All Clients</v-btn>
      </v-row>
    </v-col>
  </div>
</template>

<script lang="ts">
import MTextArea from '@/components/Inputs/MTextArea/MTextArea.vue';
import { CreateKeycloakClient, LoadKeycloakClient, UpdateKeycloakClient } from '@/store/tenant';
import { CreateAllKeycloakClients, LoadAllKeycloakClients, UpdateAllKeycloakClients } from '@/store/utils';
import Vue from 'vue';

export default Vue.extend({
  name: 'm-settings-debug-keycloak',
  components: { MTextArea },
  data: () => ({
    clientData: undefined,
  }),
  methods: {
    async loadClient() {
      const data = await this.$store.dispatch(new LoadKeycloakClient());
      this.clientData = JSON.stringify(data ?? {}, undefined, 4);
    },
    async updateClient() {
      await this.$store.dispatch(new UpdateKeycloakClient());
    },
    async createClient() {
      await this.$store.dispatch(new CreateKeycloakClient());
    },
    async loadAllClient() {
      await this.$store.dispatch(new LoadAllKeycloakClients());
    },
    async updateAllClient() {
      await this.$store.dispatch(new UpdateAllKeycloakClients());
    },
    async createAllClient() {
      await this.$store.dispatch(new CreateAllKeycloakClients());
    },
  },
});
</script>
