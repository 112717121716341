<template>
  <div>
    <m-headline :description="$t('dashboard.tabs.rituals.description')" />
    <div v-if="false" class="d-flex flex-row justify-end align-center my-4">
      <v-btn icon @click="reload">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </div>
    <div class="grid">
      <div class="gutter-sizer" />
      <div class="grid-sizer" />
      <m-dashboard-widget
        v-for="(widget, index) in widgets"
        :key="`widget-${index}`"
        ref="widgets"
        :ritual="ritual"
        :value="widget.dashboardWidget"
        class="m-dashboard-widget grid-item"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Packery from 'packery';
import Draggabilly from 'draggabilly';
import { LoadDashboard } from '@/store/dashboards';
import { MDashboardWidget } from '@/components/Dashboard/MDashboardWidget';
import { IDashboardSettings, IDashboardWidget } from '@mentessa/types';
import MHeadline from '@/components/MHeadline';

export default Vue.extend({
  name: 'm-dashboard',
  components: {
    MHeadline,
    MDashboardWidget,
  },
  props: {
    dashboardSettingsId: Number,
  },
  data: () => ({
    packery: undefined as Packery,
    dashboardSettings: undefined as IDashboardSettings,
    loading: false,
  }),
  computed: {
    ritual() {
      return this.dashboardSettings?.ritual;
    },
    widgets(): Array<{ class: Record<string, boolean>; dashboardWidget: IDashboardWidget }> {
      if (this.loading) {
        return [];
      }
      return (
        this.dashboardSettings?.dashboard?.dashboardWidgets?.map((dashboardWidget) => {
          return {
            dashboardWidget,
          };
        }) ?? []
      );
    },
  },
  watch: {
    async dashboardSettingsId() {
      await this.reload();
    },
  },
  async mounted() {
    const packery = new Packery('.grid', {
      columnWidth: '.grid-sizer',
      itemSelector: '.grid-item',
      gutter: '.gutter-sizer',
      percentPosition: true,
    });

    packery.getItemElements().forEach(function (itemElem) {
      packery.bindDraggabillyEvents(new Draggabilly(itemElem));
    });

    this.packery = packery;
    await this.reload();
  },
  methods: {
    async reload() {
      if (this.dashboardSettingsId == null) {
        return;
      }
      try {
        this.loading = true;
        this.dashboardSettings = await this.$store.dispatch(new LoadDashboard({ id: this.dashboardSettingsId }));
      } finally {
        this.loading = false;
      }

      this.$nextTick(() => {
        this.packery.reloadItems();
        if (!this.dashboardSettings.attributes?.disableDragAndDrop) {
          this.packery.getItemElements().forEach((itemElem) => {
            this.packery.bindDraggabillyEvents(new Draggabilly(itemElem));
          });
        }
        this.packery.layout();
      });
    },
  },
});
</script>

<style lang="scss" scoped>
// Warning: Not scoped style: used to manage all widgets layouts in one place
@import '~vuetify/src/styles/settings/_variables';

.grid {
  max-width: 940px;
}

/* clear fix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}

.grid-sizer,
.grid-item {
  width: calc(50% - 16px);
}

.gutter-sizer {
  width: 16px;
}

.grid-item {
  float: left;

  &.is-dragging,
  &.is-positioning-post-drag {
    z-index: 2;
  }

  &__x2 {
    width: 100%;
  }
}

.grid-item--large {
  z-index: 2;
  width: calc(100% - 16px);
  height: 100%;
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .grid-sizer,
  .grid-item {
    width: calc(100% - 16px);
  }
}

@media #{map-get($display-breakpoints, 'sm-only')} {
  .grid-sizer,
  .grid-item {
    width: calc(50% - 16px);
  }
}

.m-dashboard-widget {
  min-height: 130px;
  max-height: 130px;
  height: 130px;
  max-width: 450px;
  min-width: 440px;

  &__col-2 {
    max-height: 276px;
    height: 276px;
  }

  &__col-3 {
    max-height: 422px;
    height: 422px;
  }

  &__col-4 {
    max-height: 568px;
    height: 568px;
  }

  &__row-2 {
    max-width: calc(100% - 20px);
    width: 100%;
  }

  border: 1px solid var(--v-beige-base);
  border-radius: 16px;
  background: var(--v-white-base);
  padding: 24px;
}
</style>
