<template>
  <div sentry-tag="time-slots">
    <template v-if="!slotsOnly">
      <p class="text--text text--lighten-4">{{ $t('timeSlots.hint') }}</p>
    </template>
    <div class="d-flex time-slots-container">
      <div v-for="(day, index) in grid" :key="`day-${index}`" class="d-flex flex-column align-center m-weekday-column">
        <v-btn
          :class="{ 'm-weekday-selected': day.enabled }"
          :disabled="disabled"
          class="m-weekday mb-4 px-0"
          outlined
          sentry-tag="time-slots.button.toggleWeekday"
          width="58"
          @click="toggleDay(day)"
        >
          {{ $t(`weekdays.short.${day.name}`) }}
        </v-btn>
        <div v-if="day.enabled" class="d-flex flex-column align-center">
          <v-btn
            v-for="(slot, index) in day.slots"
            :key="`slot-${index}`"
            :class="{
              'm-time-selected': slot.enabled,
            }"
            :disabled="disabled"
            class="m-time my-1 mx-0"
            outlined
            sentry-tag="time-slots.button.toggleSlot"
            width="64"
            @click="toggleSlot(day, slot)"
          >
            {{ slot.hour }}:00
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { ISchedule, ITenant, ScheduleType, Weekday } from '@mentessa/types';
import { mapGetters, mapState } from 'vuex';
import { TenantState } from '@/store/tenant';
import { getDay, getHours, parseISO, subMilliseconds } from 'date-fns';
import { getWeekdayNumber } from '@/utils/weekdays';
import { getTimezoneOffset, toZonedTime } from 'date-fns-tz';

interface GridSlot {
  hour: number;
  enabled: boolean;
  schedule?: ISchedule;
}

interface GridDay {
  enabled: boolean;
  name: string;
  slots: Array<GridSlot>;
}

type Grid = Record<string, GridDay>;

export default Vue.extend({
  name: 'm-time-slots',
  props: {
    value: { type: Array as PropType<Array<ISchedule>>, default: () => [] },
    slotsOnly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data: () => ({
    grid: undefined as Grid,
    startHour: 8,
    endHour: 19,
  }),
  watch: {
    value() {
      this.buildGrid();
    },
  },
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState): ITenant => state.tenant,
    }),
    ...mapGetters('ui', {
      currentUserTz: 'currentUserTz',
    }),
    weekend() {
      return (this.tenant?.attributes?.mentoring?.weekend as boolean) ?? true;
    },
    weekdays() {
      const availableDays = ['mon', 'tue', 'wed', 'thu', 'fri'] as Array<Weekday>;
      if (this.weekend) {
        availableDays.push('sat', 'sun');
      }
      return availableDays;
    },
  },
  mounted() {
    this.buildGrid();
  },
  methods: {
    buildGrid() {
      const grid = {} as Grid;
      for (const [index, weekday] of this.weekdays.entries()) {
        grid[index] = {
          slots: Array.from({ length: this.endHour - this.startHour + 1 }, (v, k) => ({
            hour: k + this.startHour,
            enabled: false,
          })),
          enabled: false,
          name: weekday,
        };
      }
      this.value?.forEach((slot) => {
        const abstractDate = parseISO(slot.date);
        const offset = getTimezoneOffset(this.currentUserTz);
        const date = subMilliseconds(toZonedTime(abstractDate, this.currentUserTz), offset);
        const day = (6 + getDay(date)) % 7;
        if (day >= this.weekdays.length) {
          return;
        }
        const hour = getHours(date);
        const gridSlot = grid[day].slots.find((item) => item.hour === hour);
        if (gridSlot) {
          grid[day].enabled = true;
          gridSlot.schedule = slot;
          gridSlot.enabled = true;
        } else {
          console.warn(`Slot outside of possible hours`, slot);
        }
      });
      this.grid = grid;
    },
    toggleDay(day: GridDay) {
      if (day.enabled) {
        const enabledSlots = day.slots.filter((slot) => slot.enabled);
        if (enabledSlots.length > 0) {
          const schedule = [...this.value];
          enabledSlots.forEach((slot) => {
            const scheduleIndex = schedule.findIndex((schedule) => schedule.date === slot.schedule.date);
            if (scheduleIndex !== -1) {
              schedule.splice(scheduleIndex, 1);
            }
          });
          this.$emit('input', schedule);
        }
      }

      day.enabled = !day.enabled;
    },
    toggleSlot(day: GridDay, slot: GridSlot) {
      if (slot.enabled) {
        const scheduleIndex = this.value.findIndex((schedule) => schedule.date === slot.schedule.date);
        if (scheduleIndex !== -1) {
          this.$emit('input', [...this.value.slice(0, scheduleIndex), ...this.value.slice(scheduleIndex + 1)]);
        }
      } else {
        const today = new Date();
        const dayNumber = getWeekdayNumber(day.name as Weekday);
        const utcDate = new Date(
          Date.UTC(
            today.getUTCFullYear(),
            today.getUTCMonth(),
            today.getDate() + ((8 + dayNumber - today.getDay()) % 7),
            slot.hour,
            0,
            0,
            0,
          ),
        );
        const newItem = {
          type: ScheduleType.Weekly,
          date: utcDate.toISOString(),
        };
        this.$emit('input', [...this.value, newItem]);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.m-timeslots-header {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.m-slots-column {
  max-height: 170px;
  padding: 5px;
  overflow: auto;
}

.m-timeslots-switch-label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.m-timeslots {
  max-width: 620px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}

.m-weekday {
  font-size: 12px;
  min-width: 58px;
  border: 1px solid var(--v-lightgray-base);
}

.m-weekday-selected {
  border-color: var(--v-primary-base);
  border-style: inset;
  box-shadow: 0 0 0 2px var(--v-primary-lighten5);
}

.m-weekday-column {
  width: 68px;
}

.m-time {
  border-color: transparent;
  color: var(--v-primary-base);
  font-size: 12px;
}

.m-time-selected {
  border-color: var(--v-primary-base);
  box-shadow: 0 0 0 2px var(--v-primary-lighten5);
}

.time-slots-container {
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
}
</style>
