import { PluginObject } from 'vue';
import { UserReference } from '@mentessa/types';
import axios from 'axios';
import { api } from '@/store/api';

export interface VueImpersonateOptions {
  enabled: boolean;
  key: string;
}

const ImpersonatePlugin: PluginObject<VueImpersonateOptions> = {
  install(Vue, options): void {
    if (!options.enabled) {
      return;
    }

    const impersonate = Vue.observable({
      enabled: options.enabled ?? false,
      key: options.key,
      token: undefined,

      setUser: function (userRef: UserReference, skipReload = false) {
        if (!this.enabled) {
          return;
        }

        if (!userRef?.id) {
          this.clear();
          return;
        }
        Vue.set(
          this,
          'token',
          btoa(
            JSON.stringify({
              key: this.key,
              userId: userRef.id,
            }),
          ),
        );
        localStorage.setItem('impersonate.user.id', userRef.id.toString());
        if (!skipReload) {
          location.reload();
        }
      },
      clear() {
        Vue.set(this, 'token', undefined);
        localStorage.removeItem('impersonate.user.id');
        location.reload();
      },
      isActive() {
        return this.token != null;
      },
    });

    const userId = localStorage.getItem('impersonate.user.id');
    if (userId) {
      impersonate.setUser({ id: Number(userId) }, true);
    }

    Vue.prototype.$impersonate = impersonate;

    api.interceptors.request.use(
      (config) => {
        if (Vue.prototype.$impersonate.token) {
          config.headers['mentessa-auth-impersonate-token'] = Vue.prototype.$impersonate.token;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401 && Vue.prototype.$impersonate.isActive()) {
          console.error(`Impersonated user has no access to this tenant. Clear`);
          Vue.prototype.$impersonate.clear();
          location.reload();
        } else {
          return Promise.reject(error);
        }
      },
    );

    axios.interceptors.request.use(
      (config) => {
        if (Vue.prototype.$impersonate.token) {
          config.headers['mentessa-auth-impersonate-token'] = Vue.prototype.$impersonate.token;
        }
        return config;
      },
      (error) => {
        console.log(error);
        return Promise.reject(error);
      },
    );
  },
};

export default ImpersonatePlugin;
