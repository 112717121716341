<template>
  <v-card class="pa-6 fill-width timeline-v-card">
    <div class="d-flex justify-space-between fill-width">
      <div class="text-center d-flex flex-wrap justify-center align-center">
        <span class="text--text text--lighten-4">
          <span v-if="isFinished">{{ $t('mentoring.timeline.collaboration') }}:</span>
          <span v-else>{{ $t('mentoring.timeline.collaboratingSince') }}:</span>
        </span>
        <div class="pl-md-1">
          {{ formattedCollaborationDates }}
        </div>
      </div>
      <div class="text-center d-flex flex-wrap justify-center align-center">
        <span class="text--text text--lighten-4"> {{ $t('mentoring.timeline.collaborationPeriod') }}: </span>
        <div class="pl-md-1">
          {{ formattedCollaborationPeriod }}
        </div>
      </div>
    </div>
    <div class="d-flex fill-width justify-space-between mt-5">
      <div
        :class="[isStartOfCOllaborationStepCompleted ? 'done' : 'pending']"
        class="text-center d-flex flex-wrap justify-center align-center step"
      >
        <v-avatar size="24">
          <span>1</span>
        </v-avatar>
        <div class="pl-md-1">
          {{ $t('mentoring.timeline.startOfCollaboration') }}
        </div>
      </div>
      <v-divider class="line"></v-divider>
      <div
        :class="[isCompleteGoalsStepCompleted ? 'done' : 'pending']"
        class="text-center d-flex flex-wrap justify-center align-center step"
      >
        <v-avatar size="24">
          <span>2</span>
        </v-avatar>
        <div class="pl-md-1">
          {{ $t('mentoring.timeline.completeGoals') }}
        </div>
      </div>
      <v-divider class="line"></v-divider>
      <div
        :class="[isEndOfCOllaborationStepCompleted ? 'done' : 'pending']"
        class="text-center d-flex flex-wrap justify-center align-center step"
      >
        <v-avatar size="24">
          <span>3</span>
        </v-avatar>
        <div class="pl-md-1">
          {{ $t('mentoring.timeline.endOfCollaboration') }}
        </div>
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { IMentoringSession } from '@mentessa/types';
import { formatDuration, intervalToDuration, parseISO } from 'date-fns';
import { UIState } from '@/store/ui';

export default Vue.extend({
  name: 'm-mentoring-session-timeline',
  props: {
    session: { type: Object as PropType<IMentoringSession> },
  },
  computed: {
    ...mapGetters('ui', {
      formatInUserTimeZone: 'formatInUserTimeZone',
    }),
    ...mapState<UIState>('ui', {
      locale: (state: UIState) => state.translations.dateLocale,
    }),
    collaborationStartDate() {
      return this.session?.createdAt ? parseISO(this.session.createdAt) : null;
    },
    collaborationEndDate() {
      return this.session?.attributes?.finishedAt ? parseISO(this.session.attributes.finishedAt) : null;
    },
    formattedCollaborationStartDate() {
      return this.collaborationStartDate
        ? `${this.formatInUserTimeZone(this.collaborationStartDate, 'MMM d, yyyy')}`
        : null;
    },
    formattedCollaborationEndDate() {
      return this.collaborationEndDate
        ? `${this.formatInUserTimeZone(this.collaborationEndDate, 'MMM d, yyyy')}`
        : null;
    },
    formattedCollaborationDates() {
      return this.isFinished
        ? `${this.formattedCollaborationStartDate} - ${this.formattedCollaborationEndDate}`
        : this.formattedCollaborationStartDate;
    },
    formattedCollaborationPeriod() {
      if (!this.collaborationStartDate) {
        return null;
      }

      const duration = intervalToDuration({
        start: this.collaborationStartDate,
        end: this.collaborationEndDate ?? Date.now(),
      });

      return formatDuration(duration, {
        format: ['years', 'months', 'weeks', 'days'],
        locale: this.locale,
      });
    },
    isFinished() {
      return this.session?.status === 'finished';
    },
    isStartOfCOllaborationStepCompleted() {
      return true;
    },
    isCompleteGoalsStepCompleted() {
      return this.areAllGoalsAchieved;
    },
    isEndOfCOllaborationStepCompleted() {
      return this.isFinished;
    },
    areAllGoalsAchieved() {
      const goals = this.session?.goals ?? [];

      if (goals.length === 0) {
        return false;
      }

      return goals.every((goal) => {
        const tasks = goal.goal?.goalTasks ?? [];
        return tasks.every((task) => task.task?.done);
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.timeline-v-card {
  border: none !important;
}

.line {
  margin: auto 12px;
}

.step {
  span {
    color: var(--v-white-base);
  }
}

.step.pending {
  color: var(--v-gray-base);

  .v-avatar {
    background-color: var(--v-gray-base);
    border-color: var(--v-gray-base);
  }
}

.step.done {
  color: var(--v-primary-base);

  .v-avatar {
    background-color: var(--v-primary-base);
    border-color: var(--v-primary-base);
  }
}
</style>
