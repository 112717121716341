<template>
  <m-autocomplete-users-pure
    v-bind="$attrs"
    v-on="$attrs"
    :items="items"
    :loading="loading"
    @onSearchChanged="onSearchChanged"
    v-model="selected"
    sentry-tag="autocomplete-users-stateful"
  >
  </m-autocomplete-users-pure>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import MAutocompleteUsersPure from './AutocompleteUsersPure.vue';
import { UserEntity } from '@mentessa/types';
import { getFullName } from '@/utils/user';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'm-autocomplete-users',
  components: { MAutocompleteUsersPure },
  props: {
    // label: String,
    // placeholder: String,
    value: { type: Object as PropType<UserEntity>, default: () => ({}) },
  },
  data: () => ({
    items: [],
    loading: false,
  }),
  computed: {
    ...mapGetters('tenant', {
      itemsForSource: 'itemsForSource',
    }),
    selected: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit('onUserChanged', value);
      },
    },
  },
  mounted() {
    this.items = this.itemsForSource('searchUsers');
  },
  methods: {
    fullName(user: UserEntity) {
      return getFullName(user);
    },
    async onSearchChanged(/*value*/) {
      this.loading = true;
      try {
        // ToDo: Use real search here after we get rid of itemsForSource
        // this.items = await this.$store.dispatch(new SearchUsers(value));
      } catch (e) {
        console.error('Error while do search', e);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
