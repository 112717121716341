<template>
  <div class="d-flex justify-center align-center flex-column">
    <m-chip-group
      v-model="selected"
      :items="items"
      class="mt-4"
      i18n-path="onboarding.use_for.items"
      validation-rules="required"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { MChipGroup } from '@/components/Inputs';

export default Vue.extend({
  name: 'm-tenant-onboarding-use-for-form',
  components: { MChipGroup },
  props: {
    value: { type: Array as PropType<Array<string>>, default: () => [] },
  },
  data: () => ({
    items: [
      'internal_mobility',
      'skills_mathing',
      'dei',
      'mentoring_program',
      'expert_network',
      'upskilling',
      'agile',
      'professionsl_network',
      'community',
      'learning',
      'onboarding',
      'event',
      'volunteering',
    ],
  }),
  computed: {
    selected: {
      get() {
        return this.value.map((item) => this.items.indexOf(item));
      },
      set(value: Array<number>) {
        this.$emit(
          'input',
          value.map((index) => this.items[index]),
        );
      },
    },
  },
});
</script>
