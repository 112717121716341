<template>
  <div class="m-settings-invitations" sentry-tag="settings-debug">
    <v-col class=".m-admin-container">
      <v-tabs optional show-arrows>
        <v-tab v-for="{ title, link, sentryTag } in tabs" :key="title" :sentry-tag="sentryTag" :to="link">
          {{ title }}
        </v-tab>
      </v-tabs>
      <router-view />
    </v-col>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { RouteName } from '@/router/types';
import i18n from '@/plugins/i18n';

export default Vue.extend({
  name: 'm-settings-debug',
  props: {},
  data: () => ({
    tabs: [
      {
        title: i18n.t('settings.nps.title'),
        link: { name: RouteName.SettingsDebugNps },
        sentryTag: 'settings-debug.tab.nps',
      },
      {
        title: 'Ritual Templates',
        link: { name: RouteName.SettingsDebugRitualTemplates },
        sentryTag: 'settings-debug.tab.ritual-tempaltes',
      },
      {
        title: 'Email',
        link: { name: RouteName.SettingsDebugEmail },
        sentryTag: 'settings-debug.tab.email',
      },
      {
        title: 'Mentoring',
        link: { name: RouteName.SettingsDebugMentoring },
        sentryTag: 'settings-debug.tab.mentoring',
      },
      {
        title: 'Tokens',
        link: { name: RouteName.SettingsDebugTokens },
        sentryTag: 'settings-debug.tab.tokens',
      },
      {
        title: 'Users',
        link: { name: RouteName.SettingsDebugUsers },
        sentryTag: 'settings-debug.tab.demo',
      },
      {
        title: 'Search',
        link: { name: RouteName.SettingsDebugSearch },
        sentryTag: 'settings-debug.tab.search',
      },
      {
        title: 'Keycloak',
        link: { name: RouteName.SettingsDebugKeycloak },
        sentryTag: 'settings-debug.tab.keycloak',
      },
      {
        title: 'HubSpot',
        link: { name: RouteName.SettingsDebugHubSpot },
        sentryTag: 'settings-debug.tab.search',
      },
      {
        title: 'New Tenant',
        link: { name: RouteName.SettingsDebugNewTenant },
        sentryTag: 'settings-debug.tab.search',
      },
      {
        title: 'Edit Tenant',
        link: { name: RouteName.SettingsDebugEditTenant },
        sentryTag: 'settings-debug.tab.search',
      },
      {
        title: 'Casbin',
        link: { name: RouteName.SettingsDebugCasbin },
        sentryTag: 'settings-debug.tab.casbin',
      },
      {
        title: 'Questionnaires',
        link: { name: RouteName.SettingsDebugQuestionnaires },
        sentryTag: 'settings-debug.tab.questionnaires',
      },
      {
        title: 'Translations',
        link: { name: RouteName.SettingsDebugTranslations },
        sentryTag: 'settings-debug.tab.translations',
      },
      {
        title: 'Events',
        link: { name: RouteName.SettingsDebugEvents },
        sentryTag: 'settings-debug.tab.events',
      },
    ],
  }),
});
</script>

<style lang="scss" scoped>
.m-settings-debug {
  background-color: var(--v-white-base);
}
</style>
