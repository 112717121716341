<template>
  <div class="d-flex flex-column gap-4 pa-4 mt-4 justify-center" sentry-tag="settings-integrations">
    <m-tenant-integration-discord v-if="isDiscordEnabled" />
    <m-tenant-integration-slack v-if="isSlackEnabled" />
    <m-tenant-integration-teams v-if="isTeamsEnabled" />
    <m-tenant-integration-notion v-if="isNotionEnabled" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {
  MTenantIntegrationDiscord,
  MTenantIntegrationNotion,
  MTenantIntegrationSlack,
  MTenantIntegrationTeams,
} from '@/components/integrations';
import { mapGetters } from 'vuex';
import { IntegrationsFeature } from '@mentessa/types';

export default Vue.extend({
  name: 'm-settings-integrations',
  components: {
    MTenantIntegrationNotion,
    MTenantIntegrationTeams,
    MTenantIntegrationSlack,
    MTenantIntegrationDiscord,
  },
  data: () => ({}),
  computed: {
    ...mapGetters('tenant', {
      featureConfig: 'featureConfig',
    }),
    integrationsConfig(): IntegrationsFeature {
      return this.featureConfig('integrations');
    },
    isDiscordEnabled() {
      return this.integrationsConfig?.discord ?? false;
    },
    isSlackEnabled() {
      return this.integrationsConfig?.slack ?? false;
    },
    isTeamsEnabled() {
      return this.integrationsConfig?.teams ?? false;
    },
    isNotionEnabled() {
      return this.integrationsConfig?.notion ?? false;
    },
  },
  methods: {},
});
</script>

<style lang="scss" scoped>
.m-settings-integrations {
  background-color: var(--v-white-base);
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>
