<template>
  <div class="d-flex gap-4 py-4 flex-wrap">
    <m-ritual-template-card
      v-for="(template, index) in templates"
      :key="`template-${index}`"
      :ritual-template="template"
    />
  </div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import Vue from 'vue';
import { LoadRitualTemplates, RitualTemplatesState } from '@/store/ritualTemplates';
import { MRitualTemplateCard } from '@/components/Cards/MRitualTemplateCard';
import { ITenantRitualTemplate } from '@mentessa/types';

export default Vue.extend({
  name: 'm-ritual-templates',
  components: { MRitualTemplateCard },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapState<RitualTemplatesState>('ritualTemplates', {
      templates: (state: RitualTemplatesState): Array<ITenantRitualTemplate> => state.ritualTemplates ?? [],
    }),
  },
  async mounted() {
    try {
      this.loading = true;
      await this.$store.dispatch(new LoadRitualTemplates());
    } finally {
      this.loading = false;
    }
  },
});
</script>

<style lang="scss" scoped>
.m-settings-rituals {
  .m-active-tabs {
    background-color: transparent;
  }

  .m-settings-ritual {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 36px;
    background-color: var(--v-white-base);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
  }

  &__table-pagination:deep(.v-pagination__navigation, .v-pagination__item) {
    box-shadow: none;
  }
}
</style>
