<template>
  <div class="m-settings-matching" sentry-tag="settings-matching">
    <m-headline :description="$t('dashboard.tabs.matches.description')" />
    <div class="d-flex gap-4 mt-4">
      <m-search-field v-model="search" />
      <v-btn
        color="button"
        depressed
        sentry-tag="settings-matching.button.add"
        x-large
        @click="newMatchesDialog = true"
      >
        {{ $t('settings.matching.add') }}
      </v-btn>
    </div>
    <v-chip-group v-model="filter" class="px-6 mt-4" column>
      <v-chip v-for="(item, index) in filters" :key="index" filter>
        <m-ritual-preview v-if="item.kind === FilterKind.Ritual" :ritual="item.ritual" dense />
        <span v-else> {{ item.label }} </span>
      </v-chip>
    </v-chip-group>

    <v-overlay :opacity="0.7" :value="loading" absolute color="var(--v-white-base)">
      <m-loader :isLoading="loading" />
    </v-overlay>

    <template v-if="matches.items.length > 0">
      <v-data-table
        :headers="headers"
        :items="matches.items"
        :loading="loading"
        :options.sync="tableOptions"
        :server-items-length="matches.meta.totalItems"
        hide-default-footer
      >
        <template v-slot:item.id="{ item }">
          <span> {{ item.id }}</span>
        </template>
        <template v-slot:item.user1="{ item }">
          <m-user-preview :debug="isDebugAvailable" :user="getMatchUser(item, 0)" />
        </template>
        <template v-slot:item.user2="{ item }">
          <m-user-preview :debug="isDebugAvailable" :user="getMatchUser(item, 1)" />
        </template>
        <template v-slot:item.source="{ item }">
          <span> {{ $t(`settings.matching.source.${item.source}`) }}</span>
        </template>
        <template v-slot:item.createdBy="{ item }">
          <m-user-preview v-if="item.createdBy" :debug="isDebugAvailable" :user="item.createdBy" />
          <m-ritual-preview v-else-if="isExistingRitualMatch(item)" :debug="isDebugAvailable" :ritual="item.ritual" />
        </template>
        <template v-slot:item.createdAt="{ item }">
          {{ parseAndFormatDate(item.createdAt.toString()) }}
        </template>
      </v-data-table>

      <div class="d-flex justify-center justify-sm-end pt-6 pb-6 pr-6">
        <v-pagination
          v-model="currentPage"
          :length="matches.meta.totalPages"
          class="m-settings-matching__table-pagination"
          sentry-tag="settings-matching.pagination"
        />
      </div>
    </template>
    <m-empty-state
      v-else-if="loading === false"
      :cta="$t('settings.matching.empty.cta')"
      :description="$t('settings.matching.empty.description')"
      :image="require('@/assets/empty/community.svg')"
      :title="$t('settings.matching.empty.title')"
      @onButtonClick="newMatchesDialog = true"
    />

    <m-new-match-dialog v-model="newMatchesDialog" />
  </div>
</template>

<script lang="ts">
import { mapGetters, mapState } from 'vuex';
import Vue from 'vue';
import { getFullName } from '@/utils/user';
import { LoadMatches, MatchesFilter, MatchingState } from '@/store/matching';
import { MUserPreview } from '@/components/MUserPreview';
import { parseAndFormatDate } from '@/utils/date';
import { MNewMatchDialog } from '@/components/Dialogs';
import { IMatch, IRitual, MatchSource } from '@mentessa/types';
import MLoader from '@/components/MLoader';
import MHeadline from '@/components/MHeadline';
import MEmptyState from '@/components/MEmptyState';
import { LoadRituals, RitualsState } from '@/store/rituals';
import { compareRituals } from '@/utils/rituals';
import { MRitualPreview } from '@/components/MRitualPreview';
import { MSearchField } from '@/components/Inputs';

enum FilterKind {
  Source = 'source',
  Ritual = 'ritual',
}

interface FilterItem {
  kind: FilterKind;
  value: MatchSource | number;
  ritual?: IRitual;
  label?: string;
}

export default Vue.extend({
  name: 'm-settings-matching',
  components: {
    MSearchField,
    MRitualPreview,
    MHeadline,
    MEmptyState,
    MNewMatchDialog,
    MUserPreview,
    MLoader,
  },
  data: () => ({
    newMatchesDialog: false,
    loading: false,
    search: undefined,
    filter: undefined,
    tableOptions: {},
    FilterKind,
  }),
  computed: {
    ...mapGetters('tenant', {
      isDebugAvailable: 'isDebugAvailable',
    }),
    ...mapState<MatchingState>('matching', {
      matches: (state: MatchingState) => state.matches,
    }),
    ...mapState<MatchingState>('rituals', {
      ritualsPage: (state: RitualsState) => {
        return state.rituals;
      },
    }),
    rituals() {
      return [...this.ritualsPage.items].filter((ritual) => ritual.enabled).sort((l, r) => compareRituals(l, r));
    },
    filters() {
      const items: Array<FilterItem> = [
        { kind: FilterKind.Source, value: MatchSource.Self, label: this.$t('settings.matching.source.self') },
        { kind: FilterKind.Source, value: MatchSource.Manual, label: this.$t('settings.matching.source.manual') },
      ];

      this.rituals.forEach((ritual) => {
        items.push({ kind: FilterKind.Ritual, value: ritual.id, ritual });
      });

      return items;
    },
    currentPage: {
      get() {
        return this.matches?.meta?.currentPage ?? 1;
      },
      async set(value: number) {
        await this.loadMatches(value);
      },
    },
    headers() {
      const headers = [];

      if (this.isDebugAvailable) {
        headers.push({ text: 'ID', value: 'id', sortable: true });
      }

      headers.push(
        { text: this.$t('settings.matching.table.user1'), value: 'user1', sortable: false },
        { text: this.$t('settings.matching.table.user2'), value: 'user2', sortable: false },
        { text: this.$t('settings.matching.table.source'), value: 'source' },
        { text: this.$t('settings.matching.table.createdBy'), value: 'createdBy' },
        { text: this.$t('settings.matching.table.createdAt'), value: 'createdAt' },
      );

      return headers;
    },
  },
  watch: {
    async search() {
      await this.loadMatches();
    },
    async filter() {
      await this.loadMatches();
    },
    tableOptions: {
      async handler() {
        await this.loadMatches();
      },
      deep: true,
    },
  },
  async mounted() {
    this.loading = true;
    try {
      await this.$store.dispatch(new LoadRituals());
      await this.loadMatches();
    } finally {
      this.loading = false;
    }
  },
  methods: {
    getFullName: getFullName,
    parseAndFormatDate: parseAndFormatDate,
    getMatchUser(match: IMatch, position: number) {
      return match?.matchUsers?.[position]?.user;
    },
    isSelfMatch(match: IMatch) {
      return match?.source === MatchSource.Self;
    },
    isExistingRitualMatch(match: IMatch) {
      return match?.source === MatchSource.Ritual && match.ritual != null;
    },
    isDeletedRitualMatch(match: IMatch) {
      return match?.source === MatchSource.Ritual && match.ritual == null;
    },
    async loadMatches(page = 1) {
      this.loading = true;
      try {
        const filterOptions: MatchesFilter = { query: this.search };
        if (this.filter != null) {
          switch (this.filters[this.filter].kind) {
            case FilterKind.Source:
              filterOptions.source = this.filters[this.filter].value;
              break;
            case FilterKind.Ritual:
              filterOptions.ritual = this.filters[this.filter].value;
              break;
          }
        }
        await this.$store.dispatch(
          new LoadMatches(page, filterOptions, {
            sortBy: this.tableOptions.sortBy,
            sortOrder: this.tableOptions.sortDesc?.map((desc: boolean) => (desc ? 'desc' : 'asc')),
          }),
        );
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
:deep(.m-loader-circle-wrap) {
  background-color: transparent;
}

.m-settings-matching {
  position: relative;
  background-color: var(--v-white-base);

  &__table-pagination:deep(.v-pagination__navigation, .v-pagination__item) {
    box-shadow: none;
  }
}
</style>
