<template>
  <div class="d-flex flex-column text-center pa-4 gap-4">
    <v-row class="mb-0" :class="{ 'gap-4': $vuetify.breakpoint.xsOnly }">
      <v-col class="py-0" cols="12" sm="6">
        <m-text-field
          v-model="firstName"
          :label="$t('profile.personal.firstName')"
          sentry-tag="profile.input.firstName"
          validation-rules="required|max:150"
        />
      </v-col>
      <v-col class="py-0" cols="12" sm="6">
        <m-text-field
          v-model="lastName"
          :label="$t('profile.personal.lastName')"
          sentry-tag="profile.input.lastName"
          validation-rules="required|max:150"
        />
      </v-col>
    </v-row>

    <m-text-field
      v-if="false"
      v-model="email"
      :label="$t('profile.personal.email')"
      readonly
      sentry-tag="profile.input.email"
      validation-rules="required|email"
    />
    <m-select
      v-if="hasCustomCompanies"
      v-model="company"
      :items="customCompanies"
      :label="$t('profile.personal.company')"
      class="mb-3"
      validation-rules="required"
    />
    <m-select
      v-if="hasCustomUnits"
      v-model="unit"
      :items="customUnits"
      :label="$t('profile.personal.unit')"
      class="mb-3"
      validation-rules="required"
    />
    <component
      :is="field.component"
      v-for="(field, index) in customFields"
      :key="`custom-${index}`"
      v-model="custom[field.name]"
      :i18n-path="field.i18nPath"
      :items="field.items"
      :label="$t(`profile.customProfile.${field.name}.title`)"
      :validation-rules="field.validation"
      v-bind="field.props"
    />
    <m-select
      v-if="hasCustomPositions"
      v-model="jobTitle"
      :items="customPositions"
      :label="$t('profile.personal.jobTitle')"
      validation-rules="required"
    />
    <m-text-field
      v-else
      v-model="jobTitle"
      :label="$t('profile.personal.jobTitle')"
      sentry-tag="profile.input.jobTitle"
      validation-rules="max:150"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IUser } from '@mentessa/types';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';
import { CustomField, FieldType } from '@mentessa/types';
import { MLocation, MSelect, MTextField } from '@/components/Inputs';

function getFieldComponent(field: CustomField) {
  switch (field.type) {
    case FieldType.SelectOne:
      return MSelect;
    case FieldType.ShortText:
      return MTextField;
    case FieldType.Location:
      return MLocation;
    default:
      console.log(`Unknown field type: ${field.type}`, field);
      return undefined;
  }
}

export default Vue.extend({
  name: 'm-onboarding-personal-form',
  components: {
    MSelect,
    MTextField,
  },
  props: {
    value: Object as PropType<IUser>,
  },
  data: () => ({
    custom: {},
  }),
  watch: {
    custom: function (val, oldVal) {
      this.updateUserAttribute('custom', { ...oldVal, ...val });
    },
  },
  computed: {
    ...mapState<TenantState>('tenant', {
      customPositions: (state: TenantState) => state.tenant?.attributes?.positions ?? [],
      customUnits: (state: TenantState) => state.tenant?.attributes?.units ?? [],
      customCompanies: (state: TenantState) => state.tenant?.attributes?.companies ?? [],
      customProfileFields: (state: TenantState) => state.tenant?.attributes?.customProfileFields ?? [],
    }),
    hasCustomPositions() {
      return this.customPositions?.length > 0;
    },
    hasCustomCompanies() {
      return this.customCompanies?.length > 0;
    },
    hasCustomUnits() {
      return this.customUnits?.length > 0;
    },
    customFields() {
      const fields = [];
      this.customProfileFields?.forEach((field: CustomField) => {
        if (field.hide !== true) {
          const fieldComponent = {
            name: field.name,
            attribute: field.name,
            component: getFieldComponent(field),
            type: field.type,
            items: field.items,
            label: field.label,
            i18nPath: `profile.custom.${field.name}.items`,
            validation: field.validation,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            props: {} as Record<string, any>,
          };

          if (field.multiple) {
            fieldComponent.props.multiple = true;
          }

          fields.push(fieldComponent);
        }
      });
      return fields;
    },
    hasCustomFields() {
      return this.customFields.length > 0;
    },
    firstName: {
      get() {
        return this.value?.identity?.attributes.firstName;
      },
      set(value: string) {
        this.updateIdentityAttribute('firstName', value);
      },
    },
    lastName: {
      get() {
        return this.value?.identity?.attributes.lastName;
      },
      set(value: string) {
        this.updateIdentityAttribute('lastName', value);
      },
    },
    email: {
      get() {
        return this.value?.identity?.attributes.email;
      },
      set(value: string) {
        this.updateIdentityAttribute('email', value);
      },
    },
    jobTitle: {
      get() {
        return this.value?.attributes.jobTitle;
      },
      set(value: string) {
        this.updateUserAttribute('jobTitle', value);
      },
    },
    company: {
      get() {
        return this.value?.attributes.company;
      },
      set(value: string) {
        this.updateUserAttribute('company', value);
      },
    },
    unit: {
      get() {
        return this.value?.attributes.unit;
      },
      set(value: string) {
        this.updateUserAttribute('unit', value);
      },
    },
  },
  async mounted() {
    this.focusVideo = false;
  },
  methods: {
    updateIdentityAttribute(attr: string, value: string) {
      this.$emit('input', {
        ...this.value,
        identity: {
          ...this.value?.identity,
          attributes: {
            ...this.value?.identity?.attributes,
            [attr]: value,
          },
        },
      });
    },
    updateUserAttribute(attr: string, value: string) {
      this.$emit('input', {
        ...this.value,
        attributes: {
          ...this.value?.attributes,
          [attr]: value,
        },
      });
    },
  },
});
</script>
