<template>
  <div>
    <m-headline :title="$t('dashboard.title')" />
    <v-tabs v-model="currentTab" background-color="white">
      <v-tab :to="comminutyDahboardRoute">
        {{ $t('dashboard.tabs.community.title') }}
      </v-tab>
      <v-tab v-if="isMatchingAvailable" :to="matchesRoute">{{ $t('dashboard.tabs.matches.title') }}</v-tab>
    </v-tabs>
    <router-view class="my-4" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { RouteName } from '@/router/types';
import { mapGetters } from 'vuex';
import { IRitual } from '@mentessa/types';
import MHeadline from '@/components/MHeadline';

export default Vue.extend({
  name: 'm-dashboards',
  components: { MHeadline },
  data: () => ({
    currentTab: undefined,
    comminutyDahboardRoute: { name: RouteName.DashboardCommunity },
    matchesRoute: { name: RouteName.DashboardMatches },
  }),
  computed: {
    ...mapGetters('tenant', {
      isMatchingAvailable: 'isMatchingAvailable',
    }),
  },
  methods: {
    ritualTitle(ritual: IRitual) {
      if (!ritual) return '';
      return ritual.attributes?.title ?? this.$t(`settings.rituals.knownNames.${ritual.type}`);
    },
  },
});
</script>

<style lang="scss" scoped>
:deep(.v-slide-group__wrapper) {
  box-shadow: inset 0 -2px 0 0 var(--v-lightgray-base);
  margin-bottom: 2px;
}
</style>
