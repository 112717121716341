<template>
  <div sentry-tag="settings-nps">
    <validation-observer v-slot="{ invalid }">
      <m-settings-panel :description="$t('settings.nps.info.description')" :title="$t('settings.nps.info.title')">
        <v-switch v-model="nps.enabled" :label="$t('settings.nps.info.enabled.title')" />
        <div>
          <p class="mb-2">{{ $t('settings.nps.info.interval.title') }}</p>
          <p class="text--text text--lighten-4">{{ $t('settings.nps.info.interval.description') }}</p>
          <m-text-field
            v-model="nps.interval"
            label=""
            sentry-tag="settings-nps.input.interval"
            validation-rules="required|between:1,30"
            class="m-interval-field"
          />
        </div>
      </m-settings-panel>
      <div class="tab-actions d-flex pt-8">
        <v-btn
          :loading="loading"
          depressed
          color="button"
          :disabled="invalid"
          @click="save"
          sentry-tag="settings-nps.button.save"
        >
          {{ $t('settings.community.save') }}
        </v-btn>
      </div>
    </validation-observer>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { TenantState } from '@/store/tenant';
import { TenantEntity } from '@mentessa/types';
import MSettingsPanel from '@/components/Settings/MSettingsPanel';
import { MTextField } from '@/components/Inputs';
import { ValidationObserver } from 'vee-validate';
import { CreateNps, LoadNpsForTenant, UpdateNps } from '@/store/nps';

export default Vue.extend({
  name: 'm-settings-nps',
  components: { ValidationObserver, MTextField, MSettingsPanel },
  data: () => ({
    nps: {
      id: undefined,
      enabled: undefined,
      interval: 10,
    },
    loading: false,
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState): TenantEntity => state.tenant,
    }),
    ...mapGetters('tenant', {
      isDebugAvailable: 'isDebugAvailable',
    }),
  },
  methods: {
    async save() {
      try {
        this.loading = true;
        if (this.nps.id != null) {
          await this.$store.dispatch(
            new UpdateNps({ id: this.nps.id }, { enabled: this.nps.enabled, interval: this.nps.interval }),
          );
        } else {
          const result = await this.$store.dispatch(
            new CreateNps({ enabled: this.nps.enabled, interval: this.nps.interval }),
          );
          this.nps.id = result?.id;
        }
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    const npss = await this.$store.dispatch(new LoadNpsForTenant());

    if (npss.length > 0) {
      this.nps = {
        id: npss[0].id,
        enabled: npss[0].enabled,
        interval: npss[0].interval,
      };
    }
  },
});
</script>

<style lang="scss" scoped>
.m-interval-field {
  max-width: 140px;
}
</style>
