<script lang="ts">
import { IRitual, IRitualTemplate, RitualType } from '@mentessa/types';
import { MIcon } from '@/components/MIcon';
import { PropType } from 'vue';

export default MIcon.extend({
  name: 'm-ritual-icon',
  props: {
    ritual: { type: Object as PropType<IRitual> },
    ritualTemplate: { type: Object as PropType<IRitualTemplate> },
  },
  computed: {
    type(): RitualType {
      return this.ritual ? this.ritual.type : this.ritualTemplate ? this.ritualTemplate.type : undefined;
    },
    attributes() {
      return this.ritual ? this.ritual.attributes : this.ritualTemplate ? this.ritualTemplate.ritualAttributes : {};
    },
    srcOverride() {
      return this.attributes.icon;
    },
    assetNameOverride() {
      if (this.attributes.assetIcon) {
        return this.attributes.assetIcon;
      }
      switch (this.type) {
        case RitualType.HappyHour:
          return 'cup';
        case RitualType.SkillsMatching:
          return 'hands';
        case RitualType.EventMatching:
          return 'diamond';
      }
    },
  },
});
</script>
