<template>
  <validation-observer ref="validation" v-slot="{ invalid }" style="position: relative" tag="div">
    <m-save-profile-button :disabled="invalid || saveButtonDisabled" @onButtonClick="save" />
    <m-settings-panel
      :description="$t('profile.notifications.email.description')"
      :title="$t('profile.notifications.email.title')"
    >
      <v-switch v-model="notifications.email" :label="$t('profile.notifications.email.enable')"></v-switch>
    </m-settings-panel>
    <m-leave-dialog :value="showDialog" @onCancel="closeDialog" @onDiscard="leavePage" @onSave="saveAndLeave" />
  </validation-observer>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapState } from 'vuex';
import { SaveUserProfile, UsersState } from '@/store/users';
import { MSettingsPanel } from '@/components/Settings/MSettingsPanel';
import MSaveProfileButton from '../MSaveProfileButton';
import { MLeaveDialog } from '@/components/Dialogs';
import { ValidationObserver } from 'vee-validate';
import { UserNotificationsOptions } from '@mentessa/types';

export default Vue.extend({
  name: 'm-profile-notifications',
  components: {
    MLeaveDialog,
    MSaveProfileButton,
    ValidationObserver,
    MSettingsPanel,
  },
  data: () => ({
    notifications: {
      email: true,
    } as UserNotificationsOptions,
    loading: false,
    showDialog: false,
    to: null,
  }),
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    isChanged() {
      return this.notifications.email !== (this.me.attributes?.notifications?.email ?? true);
    },
    saveButtonDisabled() {
      return this.loading || (!this.isChanged && this.me.complete);
    },
  },
  watch: {
    me() {
      this.updateData();
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isChanged || this.to) {
      next();
    } else {
      this.to = to;
      this.showDialog = true;
    }
  },
  methods: {
    updateData() {
      this.notifications = {
        email: this.me.attributes?.notifications?.email ?? true,
      };
    },
    async save() {
      const dto = {
        attributes: {
          notifications: {
            email: this.notifications.email,
          },
        },
      };
      try {
        this.loading = true;
        await this.$store.dispatch(new SaveUserProfile(dto));
      } finally {
        this.loading = false;
      }
    },
    async leavePage() {
      this.showDialog = false;
      await this.$router.push(this.to);
    },
    closeDialog() {
      this.showDialog = false;
      this.to = null;
    },
    async saveAndLeave() {
      await this.save();
      await this.leavePage();
    },
  },
  async mounted() {
    this.updateData();
  },
});
</script>
