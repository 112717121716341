import store from '@/store';
import { NavigationGuardNext, Route } from 'vue-router';
import { RouteName } from '@/router/types';

export function profileIntegrationsGuard(to: Route, from: Route, next: NavigationGuardNext): boolean {
  if (!store.getters['tenant/isUserIntegrationsAvailable']) {
    next({ name: RouteName.Profile });
  } else {
    next();
  }
  return true;
}
