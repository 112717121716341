<template>
  <m-image-file-input v-model="localFile" class="form-image-step__file-input" />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { MImageFileInput } from '@/components/Inputs/MImageFileInput';

export interface ImageStepValue {
  src: string;
  type?: string;
  blob?: Blob;
}

export default Vue.extend({
  name: 'm-form-image-step',
  components: { MImageFileInput },
  props: {
    value: { type: Object as PropType<ImageStepValue> },
  },
  computed: {
    localFile: {
      get() {
        return this.value;
      },
      set(value: ImageStepValue) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    submitStep(): void {
      this.$emit('onSubmit');
    },
    doLater() {
      this.$emit('input', { src: undefined, type: undefined, blob: undefined });
      this.$emit('onSubmit');
    },
  },
});
</script>

<style lang="scss" scoped>
.form-image-step {
  &__file-input {
    width: 100%;
  }
}
</style>
