<template>
  <div>
    <h3 class="text--primary">{{ label }}</h3>
    <small v-if="boundariesDescription">({{ boundariesDescription }})</small>
    <v-rating :value="localValue" :length="length" @input="onChange($event)" class="m-rating">
      <template v-slot:item="data">
        <div class="d-inline-block text-center">
          <v-icon @click="data.click" color="primary" small class="pb-1">
            {{ data.isFilled ? 'mdi-checkbox-blank-circle' : 'mdi-checkbox-blank-circle-outline' }}
          </v-icon>
          <p class="primary--text mb-2">{{ data.index + 1 }}</p>
        </div>
      </template>
    </v-rating>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'm-rating',
  props: {
    label: String,
    value: { type: Number, default: 0 },
    length: { type: Number, default: 5 },
    boundaries: { type: Array as PropType<Array<string>> },
  },
  data: () => ({
    localValue: 0,
  }),
  computed: {
    boundariesDescription() {
      if (this.boundaries?.length > 1) {
        return `1 - ${this.boundaries[0]}, ${this.length} - ${this.boundaries[1]}`;
      }
      return null;
    },
  },
  methods: {
    onChange(value) {
      this.$emit('input', value);
    },
  },
});
</script>

<style lang="scss">
.m-rating {
  position: relative;
  left: -8px;
}
</style>
