<template>
  <v-footer v-if="isFooterShown" fixed class="m-footer">
    <m-notification-bar v-model="value" />
  </v-footer>
</template>

<script lang="ts">
import Vue from 'vue';
import MNotificationBar from '@/components/MNotifications/MNotificationBar.vue';

export default Vue.extend({
  name: 'm-footer',
  components: { MNotificationBar },
  data: () => ({
    value: true,
  }),
  computed: {
    isFooterShown() {
      // https://devjonas.medium.com/referenceerror-cant-find-variable-notification-and-how-to-solve-it-de4646caf472
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      return this.value && !isIOS;
    },
  },
});
</script>

<style lang="scss" scoped>
.m-footer {
  background-color: var(--v-text-base) !important;
}
</style>
