<template>
  <div sentry-tag="weekday-picker">
    <div class="d-flex flex-wrap justify-center">
      <div v-for="weekday in weekdays" :key="weekday" class="d-flex flex-column align-center m-weekday-column">
        <v-btn
          @click="toggleWeekday(weekday)"
          outlined
          :class="{ 'm-weekday-selected': isWeekdayEnabled(weekday) }"
          class="m-weekday mb-4 px-0"
          width="58"
          sentry-tag="time-slots.button.toggleWeekday"
        >
          {{ $t(`weekdays.full.${weekday}`) }}
        </v-btn>
      </div>
    </div>
    <div v-if="showContact" class="m-contact d-flex flex-column my-6 mx-auto">
      <v-switch
        v-model="schedule.contact.enabled"
        class="m-weekday-switch"
        :label="$t('timeSlots.additionalContactEnabled')"
        @change="updateValue"
      />
      <m-text-field
        v-model="schedule.contact.email"
        :label="$t('timeSlots.additionalContactEmail')"
        :placeholder="$t('timeSlots.additionalContactPlaceholder')"
        class="m-contact-textfield my-6"
        @input="updateValue"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { ITenant, Weekday } from '@mentessa/types';
import { MTextField } from '@/components/Inputs';
import deepmerge from 'deepmerge';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';

export default Vue.extend({
  name: 'm-weekday-picker',
  components: { MTextField },
  props: {
    value: { type: Object, default: () => ({}) },
    showContact: { type: Boolean, default: false },
  },
  data: () => ({
    schedule: {
      contact: {
        enabled: false,
        email: undefined,
      },
      weekdays: [],
    },
  }),
  watch: {
    value: {
      handler(newValue) {
        this.schedule = deepmerge({}, newValue);
      },
      deep: true,
    },
  },
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState): ITenant => state.tenant,
    }),
    weekend() {
      return (this.tenant?.attributes?.mentoring?.weekend as boolean) ?? true;
    },
    weekdays() {
      const availableDays = ['mon', 'tue', 'wed', 'thu', 'fri'] as Array<Weekday>;
      if (this.weekend) {
        availableDays.push('sat', 'sun');
      }
      return availableDays;
    },
  },
  mounted() {
    this.schedule = deepmerge(this.schedule, this.value);
  },
  methods: {
    updateValue() {
      this.$emit('input', this.schedule);
    },
    toggleWeekday(weekday: Weekday): void {
      const weekdayNumber = this.weekdays.indexOf(weekday);
      if (this.schedule.weekdays.includes(weekdayNumber)) {
        const i = this.schedule.weekdays.indexOf(weekdayNumber);
        this.schedule.weekdays.splice(i, 1);
      } else {
        this.schedule.weekdays.push(weekdayNumber);
      }
      this.updateValue();
    },
    isWeekdayEnabled(weekday: Weekday): boolean {
      return this.schedule.weekdays.includes(this.weekdays.indexOf(weekday));
    },
  },
});
</script>

<style scoped lang="scss">
.m-timeslots-header {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.m-slots-column {
  max-height: 170px;
  padding: 5px;
  overflow: auto;
}

.m-timeslots-switch-label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.m-timeslots {
  max-width: 620px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}

.m-weekday {
  font-size: 12px;
  min-width: 64px;
  border: 1px solid var(--v-lightgray-base);
}

.m-weekday-selected {
  border-color: var(--v-primary-base);
  border-style: inset;
  box-shadow: 0 0 0 2px var(--v-primary-lighten5);
}

.m-weekday-column {
  width: 70px;
}

.m-time {
  border-color: transparent;
  color: var(--v-primary-base);
  font-size: 12px;
}

.m-time-selected {
  border-color: var(--v-primary-base);
  box-shadow: 0 0 0 2px var(--v-primary-lighten5);
}
</style>
