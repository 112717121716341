<template>
  <div>
    <v-divider class="ma-8" />
    <h3>Templates</h3>
    <v-select v-model="emails.selectedTemplateType" :items="templateTypes" item-text="title" item-value="value">
      <template v-slot:prepend-item>
        <v-list-item @click.stop>
          <m-search-field v-model="filter" autofocus />
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
    </v-select>

    <template v-if="templates.length">
      <v-select v-model="emails.selectedTemplate" :items="templates" item-text="locale" item-value="id" return-object>
        <template v-slot:item="data">
          <slot name="item" v-bind="data">
            {{ data.item.locale }} ({{ isDefaultTemplate(data.item) ? 'Default' : 'Custom' }})
          </slot>
        </template>
        <template v-slot:selection="data">
          <slot name="selection" v-bind="data">
            {{ data.item.locale }} ({{ isDefaultTemplate(data.item) ? 'Default' : 'Custom' }})
          </slot>
        </template>
      </v-select>
      <template v-if="emails.selectedTemplate">
        <m-text-field v-model="emails.selectedTemplate.subject" class="ma-4" label="Subject"></m-text-field>
        <m-text-field v-model="emails.selectedTemplate.sender" class="ma-4" label="Sender"></m-text-field>
        <m-text-area v-model="emails.selectedTemplate.template" class="ma-4" label="Template"></m-text-area>

        <div v-html="emails.selectedTemplate.template"></div>
        <m-ais-wrapper class="m-inbox-sidebar__search ma-4" index="users">
          <v-row>
            <m-ais-autocomplete-users
              class="ma-4"
              labelTop="Sender"
              max-width="600px"
              prepend-inner-icon="mdi-message-plus-outline"
              @input="selectEmailSender"
            />
            <m-ais-autocomplete-users
              class="ma-4"
              labelTop="Receiver"
              max-width="600px"
              prepend-inner-icon="mdi-message-plus-outline"
              @input="selectEmailReceiver"
            />
          </v-row>
        </m-ais-wrapper>
        <m-text-area v-model="emails.testOptions"></m-text-area>
        <v-btn class="ma-4" @click="testEmail"> Send</v-btn>
        <v-btn class="ma-4" @click="previewEmail"> Preview</v-btn>
        <div v-if="emails.templatedEmail.html" v-html="emails.templatedEmail.html"></div>
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import { MAisAutocompleteUsers, MAisWrapper } from '@/components/MAIS';
import { EmailType, IEmailTemplate, IUser } from '@mentessa/types';
import { EmailsState, LoadEmails, PreviewEmail, SendEmail } from '@/store/emails';
import { mapState } from 'vuex';
import Vue from 'vue';
import { MSearchField, MTextArea, MTextField } from '@/components/Inputs';

export default Vue.extend({
  name: 'm-settings-debug-email',
  components: { MSearchField, MTextArea, MTextField, MAisAutocompleteUsers, MAisWrapper },
  data: () => ({
    emails: {
      selectedTemplateType: undefined,
      selectedLocale: 'en',
      selectedTemplate: undefined,
      testOptions: '',
      sender: undefined,
      receiver: undefined,
      templatedEmail: {
        html: '',
        subject: '',
        from: {
          name: '',
          email: '',
        },
      },
      templateTypes: (() => {
        const result = [];
        for (const [value, name] of Object.entries(EmailType)) {
          result.push({ title: value, value: name });
        }
        return result;
      })(),
    },
    filter: '',
  }),
  computed: {
    ...mapState<EmailsState>('emails', {
      templates: (state: EmailsState) => {
        return state.emailsPage.items;
      },
    }),
    templateTypes() {
      if (!this.filter) {
        return this.emails.templateTypes;
      }
      return this.emails.templateTypes.filter(
        (type) =>
          type.value?.toLowerCase()?.includes(this.filter.toLowerCase()) ||
          type.title?.toLowerCase()?.includes(this.filter.toLowerCase()),
      );
    },
  },
  methods: {
    async testEmail() {
      this.emails.templatedEmail = await this.$store.dispatch(
        new SendEmail(this.emails.selectedTemplate, this.buildEmailData()),
      );
    },
    buildEmailData() {
      const content = this.emails.testOptions?.length > 2 ? this.emails.testOptions : '{}';
      const data = JSON.parse(content);
      if (this.emails.sender) {
        data['senderRef'] = { id: this.emails.sender.id };
      }
      if (this.emails.receiver) {
        data['receiverRef'] = { id: this.emails.receiver.id };
      }
      return data;
    },
    async previewEmail() {
      this.emails.templatedEmail = await this.$store.dispatch(
        new PreviewEmail(this.emails.selectedTemplate, this.buildEmailData()),
      );
    },
    isDefaultTemplate(template: IEmailTemplate & { tenantId?: number }) {
      return template.tenantId !== this.tenant?.id;
    },
    selectEmailSender(user: IUser) {
      this.emails.sender = user;
    },
    selectEmailReceiver(user: IUser) {
      this.emails.receiver = user;
    },
  },
  watch: {
    async 'emails.selectedTemplateType'(type) {
      await this.$store.dispatch(new LoadEmails(1, { type }));
      if (this.templates?.length > 0) {
        this.emails.selectedTemplate = this.templates[0];
      }
    },
  },
});
</script>
