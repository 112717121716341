<template>
  <v-card class="pa-8 d-flex flex-column border-dashed" sentry-tag="m-add-ritual-card" width="325">
    <v-row class="mt-4 flex-grow-0" justify="center">
      <div class="m-add-ritual-card__image">
        <v-img contain width="46" height="46" :src="require('@/assets/empty/cup.svg')" transition="scale-transition" />
      </div>
    </v-row>

    <v-card-title class="justify-center">
      <p class="primary--text text-center">{{ $t('ritualShop.add.title') }}</p>
    </v-card-title>
    <v-card-text class="text-center flex-grow-1">
      {{ $t('ritualShop.add.description') }}
      <br />
    </v-card-text>
    <v-card-actions class="mt-4 justify-end">
      <v-row justify="center">
        <v-btn color="button" depressed outlined class="outlined-light" min-width="200" :to="ritualNewRoute">
          {{ $t('ritualShop.add.cta') }}
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import { RouteName } from '@/router/types';

export default Vue.extend({
  name: 'm-add-ritual-card',
  data: () => ({
    ritualNewRoute: { name: RouteName.RitualTempaltes },
  }),
});
</script>

<style lang="scss" scoped>
.m-add-ritual-card {
  &__image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: var(--v-beige-base);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
}
</style>
