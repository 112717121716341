<template>
  <div :class="classes" class="m-dashboard-widget-cloud" sentry-tag="dashboard-widget-cloud">
    <h4 class="mb-4">{{ title }}</h4>

    <ais-refinement-list :attribute="value" :limit="20">
      <template v-slot="{ items }">
        <vue-word-cloud
          :animation-duration="3000"
          :animation-overlap="0.2"
          :color="makeColors(items)"
          :font-size-ratio="3"
          :rotation="(word) => [0, 0, -0.25][Math.floor(Math.random() * 3)]"
          :words="makeWords(items)"
          font-family="inter"
          style="height: 300px"
        >
          <template v-slot="{ text, weight }">
            <v-tooltip top sentry-tag="dashboard-widget-cloud.tooltip">
              <template v-slot:activator="{ on }">
                <div style="cursor: pointer" v-on="on">
                  {{ text }}
                </div>
              </template>
              {{ text }} - {{ weight }}
            </v-tooltip>
          </template>
        </vue-word-cloud>
      </template>
    </ais-refinement-list>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import VueWordCloud from 'vuewordcloud';
import chroma from 'chroma-js';

export type WordsCloud = Array<Array<string | number>>;

function invertColor(hex, bw) {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  const r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    // https://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
  }
  // pad each with zeros and return
  return '#' + padZero((255 - r).toString(16)) + padZero((255 - g).toString(16)) + padZero((255 - b).toString(16));
}

function padZero(str, len = 2) {
  const zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
}

export default Vue.extend({
  name: 'm-dashboard-widget-list-tags',
  components: { 'vue-word-cloud': VueWordCloud },
  props: {
    value: String as PropType<'iTags' | 'eTags'>,
    title: [String, Object /*as PropType<TranslateResult>*/],
    nested: { type: Boolean, default: false },
    size: { type: String as PropType<'x2'> },
  },
  computed: {
    classes() {
      return {
        'grid-item': !this.nested,
        'grid-item__x2': this.size === 'x2',
      };
    },
  },
  methods: {
    makeWords(items): Array<WordsCloud> {
      return items.map((item) => [item.label, item.count]);
    },
    makeColors(items): (word) => string {
      if (!items?.length) {
        return () => undefined;
      }
      const primaryColor = this.$vuetify.theme.isDark
        ? this.$vuetify.theme.themes.dark.primary
        : this.$vuetify.theme.themes.light.primary;
      const inverseColor = invertColor(primaryColor, false);
      const scale = chroma.scale([inverseColor, primaryColor]);
      const maxValue = items[0].count;
      const minValue = items[items.length - 1].count;
      return (word) => {
        return scale((1 / (maxValue - minValue)) * (word[1] - minValue)).hex();
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.m-dashboard-widget-cloud {
  padding: 24px;
  border: 1px solid var(--v-beige-base);
  border-radius: 16px;
  background: var(--v-white-base);
  text-align: left;
  min-height: 448px;
  max-height: 448px;
}

dl {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
