<template>
  <div class="text-center">
    <h3>{{ tr('title') }}</h3>
    <div v-html="$marked(content)" class="text-left mt-8" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'm-questionnaire-guidelines-form',
  components: {},
  props: {
    items: { type: Array as PropType<string[]>, default: () => [] },
    i18nPath: { type: String },
    title: String,
  },
  computed: {
    tr() {
      return (text: string, options = {}) => {
        return this.i18nPath ? this.$t(`${this.i18nPath}.${text}`, options) : text;
      };
    },
    content(): string {
      return this.tr('content', { title: this.title });
    },
  },
});
</script>
