import { Action } from '@/store/types';
import { I18nContent } from '@mentessa/types';
import { Locale } from 'date-fns';

export const moduleName = 'ui';

export interface UIState {
  translations: {
    lang: string;
    dateLocale: Locale;
  };
  navigationVisible: boolean;
  communityRulesVisible: boolean;
  dialogsBlocked: boolean;
}

export enum UIActions {
  LoadTranslations = '[UI] Load Translations',
  LoadLocaleContent = '[UI] Load Locale Content',
  UpdateLocaleContent = '[UI] Update Locale Content',
  CreateLocaleContent = '[UI] Create Locale Content',
  ChangeNavigationVisible = '[UI] Change Navigation Visible',
  ChangeCommunityRulesVisible = '[UI] Change Community Rules Visible',
  ChangeDialogBlockedState = '[UI] Change Dialog Blocked State',
}

export enum UIMutations {
  SetTranslations = '[UI] Set Translations',
  SetNavigationVisible = '[UI] Set Navigation Visible',
  SetCommunityRulesVisible = '[UI] Set Community Rules Visible',
  SetDialogBlockedState = '[UI] Set Dialog Blocked State',
}

export class LoadTranslations implements Action {
  type = `${moduleName}/${UIActions.LoadTranslations}`;

  constructor(private readonly lang: string) {}
}

export class LoadLocaleContent implements Action {
  type = `${moduleName}/${UIActions.LoadLocaleContent}`;

  constructor(private readonly lang: string) {}
}

export class UpdateLocaleContent implements Action {
  type = `${moduleName}/${UIActions.UpdateLocaleContent}`;

  constructor(private readonly lang: string, private readonly content: I18nContent) {}
}

export class CreateLocaleContent implements Action {
  type = `${moduleName}/${UIActions.CreateLocaleContent}`;

  constructor(private readonly lang: string, private readonly content: I18nContent) {}
}

export class ChangeNavigationVisible implements Action {
  type = `${moduleName}/${UIActions.ChangeNavigationVisible}`;

  constructor(private readonly visible: boolean) {}
}

export class ChangeCommunityRulesVisible implements Action {
  type = `${moduleName}/${UIActions.ChangeCommunityRulesVisible}`;

  constructor(private readonly visible: boolean) {}
}

export class ChangeDialogBlockedState implements Action {
  type = `${moduleName}/${UIActions.ChangeDialogBlockedState}`;

  constructor(private readonly blocked: boolean) {}
}
