import { MutationTree } from 'vuex';
import { SearchMutations, SearchState } from './types';

export const mutations: MutationTree<SearchState> = {
  [SearchMutations.SetInstantSearchInstance](state, instantSearchInstance) {
    state.instantSearchInstance = instantSearchInstance;
  },

  [SearchMutations.SetActiveFacets](state, facets) {
    state.activeFacets = facets ?? {};
  },
};
