<template>
  <v-app sentry-tag="onboarding-app">
    <div v-if="initialized" class="fill-height">
      <v-main class="fill-height">
        <m-tenant-onboarding-wizard />
        <!--        <div v-if="initialized" class="fill-height">-->
        <!--          <m-onboarding />-->
        <!--        </div>-->
      </v-main>
    </div>
    <m-loader v-else :isLoading="true" />
    <div class="logout">
      {{ email }} (
      <v-btn icon @click="logout"> {{ $t('footer.logout') }}</v-btn>
      )
    </div>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import MLoader from '@/components/MLoader';
import { mapState } from 'vuex';
import { UsersState } from '@/store/users';
import { MTenantOnboardingWizard } from '@/components/Dialogs/MTenantOnboardingWizard';

export default Vue.extend({
  name: 'App',
  components: {
    MTenantOnboardingWizard,
    MLoader,
  },
  data: () => ({
    initialized: true,
  }),
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    email() {
      return `${this.$keycloak.userName}`;
    },
  },
  methods: {
    logout() {
      this.$keycloak.keycloak.logout({ redirectUri: window.location.origin });
    },
  },
});
</script>

<style lang="scss">
/* Override default vuetify text transformation. */
/* ToDo: Move it to variables.scss or just create custom class. Do not use non-scoped styles! */
.v-btn {
  font-size: 14px;
  text-transform: none;
  letter-spacing: unset;
}

.m-footer {
  color: #475568;
}

.m-footer a {
  color: #475568 !important;
  text-decoration: none;
}

.logout {
  position: absolute;
  top: 16px;
  right: 16px;
}
</style>
