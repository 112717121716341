import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { MentoringState } from './types';

export const getters: GetterTree<MentoringState, RootState> = {
  currentSession(state: MentoringState) {
    if (state.currentSessionId != null) {
      return state.sessions.data.find((session) => session.data.id === state.currentSessionId);
    }
  },
  hasMentoringSessions: (state: MentoringState) => (status?: string) => {
    if (status) {
      return state.sessions.data.some((session) => session.data.status === status);
    } else {
      return state.sessions?.data?.length > 0;
    }
  },
  allSessions(state: MentoringState) {
    return state.sessions.data;
  },
  getSession: (state: MentoringState) => (id: number) => {
    return state.sessions.data.find((session) => session.data.id === id);
  },
  sessionsByStatus: (state: MentoringState) => (status: string) => {
    return state.sessions.data.filter((session) => session.data.status === status);
  },
};
