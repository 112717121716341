<template>
  <v-card class="pa-6">
    <m-text-field-edit
      v-if="editMode || deleteConfirmationState"
      :deleteConfirmation="deleteConfirmationState"
      :value="text"
      goal
      @onCancel="cancel"
      @onDelete="remove"
      @onDeleteCancel="deleteConfirmationState = false"
      @onSave="save"
    />
    <div v-else class="d-flex justify-space-between align-center pt-2">
      <p class="ma-0 mt-1 mb-2 pb-2">{{ text }}</p>
      <div v-if="!readOnly">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-1" icon v-bind="attrs" @click="deleteConfirmationState = true" v-on="on">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('mentoring.goals.deleteGoalHint') }}</span>
        </v-tooltip>
        <v-btn class="ml-auto" icon @click="editMode = true">
          <v-icon color="primary">mdi-pencil-outline</v-icon>
        </v-btn>
      </div>
    </div>
    <div v-if="!readOnly">
      <v-divider class="mt-4"></v-divider>
      <div :class="{ 'justify-center': tasks.length === 0 }" class="d-flex justify-end mt-5 m-add-task-hint">
        <p class="primary--text text-right mb-0">{{ $t('mentoring.goals.addTaskHint') }}</p>
        <v-icon v-if="tasks.length > 0" color="primary">mdi-menu-down</v-icon>
      </div>
      <v-row class="mt-0 mb-2 mx-0 align-center">
        <span v-if="tasks.length > 0" class="text--text text--lighten-4">{{ $t('mentoring.goals.plan') }}</span>
        <div :class="{ 'mt-2 mr-auto': tasks.length === 0 }" class="ml-auto">
          <v-btn class="d-inline-block" icon @click="addTaskDialog = true">
            <v-icon color="primary" size="30">mdi-plus-circle-outline</v-icon>
          </v-btn>
        </div>
      </v-row>
    </div>
    <m-mentoring-session-goal-task
      v-for="task in tasks"
      :key="`task-${task.id}`"
      :goal-task="task"
      :session="session"
      :session-goal="sessionGoal"
    />
    <v-row v-if="tasks.length > 0" class="ma-0 mt-4 text--text text--lighten-4">
      <span>{{ $t('mentoring.goals.progress') }}</span>
      <span v-if="hasDueDate" class="ml-auto">{{ $t('mentoring.goals.daysLeft', { days: daysLeft }) }}</span>
    </v-row>
    <v-row v-if="tasks.length > 0" class="ma-0 mr-1 text--text text--lighten-4 align-center">
      <div class="flex-grow-1 mr-2">
        <v-progress-linear :value="progress" height="10" striped />
      </div>
      <span>{{ progress }}%</span>
    </v-row>
    <m-add-task-dialog v-model="addTaskDialog" :mentoring-goal="sessionGoal" :mentoring-session="session" />
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { mapState } from 'vuex';
import { UsersState } from '@/store/users';
import { IGoal, IGoalTask, IMentoringSession, IMentoringSessionGoal, MentoringSessionStatus } from '@mentessa/types';
import MMentoringSessionGoalTask from './MMentoringSessionGoalTask.vue';
import MAddTaskDialog from '@/components/Mentoring/dialogs/MAddTaskDialog/MAddTaskDialog.vue';
import { compareDesc, differenceInDays, parseISO } from 'date-fns';
import MTextFieldEdit from '@/components/Inputs/MTextFieldEdit/MTextFieldEdit.vue';
import { DeleteMentoringSessionGoal, UpdateMentoringSessionGoal } from '@/store/mentoring';

export default Vue.extend({
  name: 'm-mentoring-session-goal',
  components: { MTextFieldEdit, MAddTaskDialog, MMentoringSessionGoalTask },
  props: {
    sessionGoal: { type: Object as PropType<IMentoringSessionGoal> },
    session: { type: Object as PropType<IMentoringSession> },
    onlyMine: { type: Boolean, default: false },
  },
  data: () => ({
    addTaskDialog: false,
    editMode: false,
    loading: false,
    deleteConfirmationState: false,
  }),
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    allTasks(): Array<IGoalTask> {
      return this.sessionGoal?.goal.goalTasks ?? [];
    },
    tasks() {
      let tasks = this.allTasks;
      if (this.onlyMine) tasks = tasks.filter((task) => task.task.createdById === this.me.id);

      return tasks.slice().sort((l, r) => {
        return compareDesc(parseISO(l.task.createdAt.toString()), parseISO(r.task.createdAt.toString()));
      });
    },
    hasDueDate() {
      return this.sessionGoal?.goal?.dueDate != null;
    },
    daysLeft() {
      return differenceInDays(new Date(), parseISO(this.sessionGoal.goal.dueDate));
    },
    progress() {
      if (this.allTasks.length === 0) {
        return 100;
      }
      const doneCount = this.allTasks.reduce((count, task) => count + (task.task?.done === true ? 1 : 0), 0);
      return Math.floor((doneCount * 100) / this.allTasks.length);
    },
    text() {
      if (!this.sessionGoal?.goal?.text) {
        return '';
      }
      if (this.sessionGoal.goal.i18n && this.$te(`known_goals.${this.sessionGoal.goal.text}`)) {
        return this.$t(`known_goals.${this.sessionGoal.goal.text}`);
      }
      return this.sessionGoal.goal.text;
    },
    readOnly() {
      return this.session?.status !== MentoringSessionStatus.Progress;
    },
  },
  methods: {
    async save(newValue: string) {
      this.loading = true;
      try {
        const success = await this.$store.dispatch(
          new UpdateMentoringSessionGoal(this.session, this.sessionGoal, { goal: { text: newValue } as IGoal }),
        );
        if (success) {
          this.editMode = false;
        }
      } finally {
        this.loading = false;
      }
    },
    cancel() {
      this.editMode = false;
    },
    async remove() {
      this.loading = true;
      try {
        const success = await this.$store.dispatch(new DeleteMentoringSessionGoal(this.session, this.sessionGoal));
        if (success) {
          this.editMode = false;
        }
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.m-add-task-hint {
  padding-right: 6px;
}
</style>
