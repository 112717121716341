import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { moduleName, SearchState } from './types';

export * from './types';

export function searchGetter(getter: string) {
  return `${moduleName}/${getter}`;
}

export const initialState: SearchState = {
  isLoading: false,
  cache: {
    users: new Map<string, never>(),
  },
  instantSearchInstance: undefined,
  activeFacets: {},
};

export const searchModule: Module<SearchState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

export default searchModule;
