<template>
  <v-carousel
    v-model="streamUserIndex"
    :continuous="false"
    :show-arrows="false"
    class="fill-height"
    height="100%"
    hide-delimiters
    sentry-tag="user-details-carousel"
    style="height: 100vh"
  >
    <v-carousel-item v-for="(streamUser, index) in users" :key="index" :disabled="loading" height="100%">
      <v-skeleton-loader v-if="loading" height="100%" type="card" width="100%" />
      <div v-else>
        <m-user-details-content
          :user="streamUser.user"
          @onBooking="$emit('onBooking', streamUser.user)"
          @onUserChanged="$emit('onUserChanged', streamUser.user)"
        />
      </div>
    </v-carousel-item>
  </v-carousel>
</template>

<script lang="ts">
import Vue from 'vue';
import { LoadNextStreamUser, UsersState } from '@/store/users';
import { MUserDetailsContent } from '../MUserDetailsContent';
import { mapState } from 'vuex';
import { RouteName } from '@/router/types';

export default Vue.extend({
  name: 'm-user-details-carousel',
  components: {
    MUserDetailsContent,
  },
  data: () => ({
    streamUserIndex: 0,
  }),
  computed: {
    ...mapState<UsersState>('users', {
      streamUsers: (state: UsersState) => state.usersStream.items,
      loading: (state: UsersState) => state.usersStream.loading,
      finished: (state: UsersState) => state.usersStream.finished,
    }),
    users() {
      if (this.finished) return this.streamUsers;
      else return [...this.streamUsers, { loaded: false }];
    },
  },
  watch: {
    'streamUsers.length'(value: number) {
      if (this.streamUserIndex !== value - 1) {
        this.streamUserIndex = value - 1;
      }
    },
    async streamUserIndex(value: number) {
      if (value >= this.streamUsers.length) {
        await this.$store.dispatch(new LoadNextStreamUser());
      }
      if (
        !(
          this.$route.name === RouteName.CommunityUserDetails &&
          Number(this.$route.params.userId) === this.streamUsers[value].user.id
        )
      ) {
        await this.$router.push({
          name: RouteName.CommunityUserDetails,
          params: { userId: `${this.streamUsers[value].user.id}` },
        });
      }
    },
    finished(value: boolean) {
      if (value) {
        this.streamUserIndex = this.users.length - 1;
      }
    },
  },
});
</script>
