import { Action } from '@/store/types';
import { INews, NewsPage } from '@mentessa/types';

export const moduleName = 'news';

export interface NewsState {
  news: NewsPage;
}

export enum NewsActions {
  LoadNews = '[News] Load News',
  UpdateNews = '[News] Update News',
  DeleteNews = '[News] Delete News',
  CreateNews = '[News] Create News',
}

export enum NewsMutations {
  SetNews = '[News] Set News',
}

export class LoadNews implements Action {
  type = `${moduleName}/${NewsActions.LoadNews}`;

  constructor(private readonly page: number = 1, private readonly tags?: Array<string>) {}
}

export class CreateNews implements Action {
  type = `${moduleName}/${NewsActions.CreateNews}`;

  constructor(private readonly news: Omit<INews, 'id'>) {}
}

export class UpdateNews implements Action {
  type = `${moduleName}/${NewsActions.UpdateNews}`;

  constructor(private readonly news: Omit<INews, 'id'>) {}
}

export class DeleteNews implements Action {
  type = `${moduleName}/${NewsActions.DeleteNews}`;

  constructor(private readonly news: Omit<INews, 'id'>) {}
}
