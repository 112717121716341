<template>
  <v-app class="login-app" sentry-tag="login-app">
    <v-main v-if="initialized" class="mb-16">
      <v-container class="d-flex flex-column align-center">
        <v-img class="login-app__logo" src="./assets/logo-mentessa.svg" />
        <h2 class="mt-16">{{ $t('login.title') }}</h2>
        <div class="login-app__tenants d-flex flex-column align-center mt-12">
          <m-search-field
            v-if="showSearch"
            v-model="filter"
            :debounce="100"
            autofocus
            class="login-app__tenant-container mb-6"
          />
          <div
            v-for="tenant in filteredTenants"
            :key="tenant.id"
            class="login-app__tenant-container mb-6"
            @click="goToTenant(tenant)"
          >
            <m-tenant-preview :tenant="tenant" />
          </div>
        </div>
        <div class="login-app__divider mt-6">
          <div class="login-app__divider-text text-center">{{ $t('login.or') }}</div>
        </div>
        <a class="mt-12" @click="goToCreateCommunity">{{ $t('login.createNewCommunity') }}</a>
      </v-container>
    </v-main>
    <m-loader v-else :isLoading="true" />
    <v-footer class="text-center m-footer" fixed>
      <v-col>
        <a href="https://mentessa.com/privacy" sentry-tag="onboarding-app.footer.privacy" target="_blank">
          {{ $t('footer.privacy') }}
        </a>
        ·
        <a href="/terms.html" sentry-tag="onboarding-app.footer.terms" target="_blank">{{ $t('footer.terms') }}</a>
      </v-col>
    </v-footer>
    <v-row class="logout ma-0">
      <m-user-preview :user="me" />
      <div>
        (
        <v-btn icon @click="logout">{{ $t('footer.logout') }}</v-btn>
        )
      </div>
    </v-row>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';
import { MTenantPreview } from '@/components/MTenantPreview';
import MLoader from '@/components/MLoader/Loader.vue';
import { ITenant } from '@mentessa/types';
import { MUserPreview } from '@/components/MUserPreview';
import { UsersState } from '@/store/users';
import { MSearchField } from '@/components/Inputs';

export default Vue.extend({
  name: 'login-app',
  components: { MSearchField, MUserPreview, MLoader, MTenantPreview },
  data: () => ({
    initialized: true,
    filter: '',
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      availableTenants: (state: TenantState) => state.tenants,
    }),
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    isImpersonated() {
      return this.$impersonate?.isActive() ?? false;
    },
    filteredTenants() {
      if (!this.filter?.length) {
        return this.availableTenants;
      }

      const lowercase = this.filter.toLowerCase();

      return this.availableTenants.filter(
        (tenant: ITenant) =>
          tenant.domain.includes(lowercase) || tenant.attributes.theme?.title?.toLowerCase().includes(lowercase),
      );
    },
    showSearch() {
      return this.availableTenants?.length > 6;
    },
  },
  methods: {
    goToTenant(tenant: ITenant) {
      const splitHost = window.location.host.split('.');
      splitHost[0] = tenant.domain;
      window.location.host = splitHost.join('.');
    },
    goToCreateCommunity() {
      const splitHost = window.location.host.split('.');
      splitHost[0] = 'new';
      window.location.host = splitHost.join('.');
    },
    logout() {
      this.$keycloak.keycloak.logout({ redirectUri: window.location.origin });
    },
    cancelImpersonate() {
      this.$impersonate?.clear();
    },
  },
});
</script>

<style lang="scss">
.login-app {
  &__logo {
    margin-top: 84px;
  }

  &__tenants {
    max-width: 384px;
    width: 100%;
  }

  &__tenant-container {
    display: flex;
    border: 1px solid var(--v-lightgray-base);
    padding: 16px;
    border-radius: 6px;
    width: 100%;
    cursor: pointer;
  }

  &__divider {
    position: relative;
    border-bottom: 1px solid var(--v-lightgray-base);
    width: 100%;
    max-width: 384px;
    display: flex;
    justify-content: center;

    &-text {
      position: absolute;
      width: 70px;
      top: -10px;
      background-color: var(--v-white-base);
    }
  }
}

.m-footer {
  color: #475568;
  background-color: var(--v-white-base) !important;
}

.m-footer a {
  color: #475568 !important;
  text-decoration: none;
}
</style>
