<template>
  <div>
    <m-feature-enable-switch feature="matching" />
    <v-card class="pa-4">
      <h4>Self Matching</h4>
      <div style="display: flex; flex-wrap: wrap">
        <v-switch v-model="selfMatchingEnabled" class="mr-6" label="Enabled" />
      </div>
    </v-card>
    <v-btn color="button" depressed @click="save" class="my-4">Save</v-btn>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import MFeatureEnableSwitch from '@/components/Settings/MSettingsFeatures/MFeatureEnableSwitch';
import { ChangeFeatureConfig } from '@/store/tenant';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'm-settings-features-matching',
  components: { MFeatureEnableSwitch },
  data: () => ({
    selfMatchingEnabled: undefined,
  }),
  computed: {
    ...mapGetters('tenant', {
      featureConfig: 'featureConfig',
    }),
    matchingConfig() {
      return this.featureConfig('matching');
    },
  },
  mounted() {
    this.updateData();
  },
  methods: {
    async save() {
      const config: Record<string, unknown> = {};

      if (this.selfMatchingEnabled !== !!this.matchingConfig.selfMatching) {
        config.selfMatching = this.selfMatchingEnabled;
      }

      if (Object.keys(config).length > 0) {
        await this.$store.dispatch(new ChangeFeatureConfig(this.tenant, 'matching', config));
      }
    },
    updateData() {
      this.selfMatchingEnabled = this.matchingConfig.selfMatching;
    },
  },
});
</script>
