<template>
  <ais-autocomplete>
    <template v-slot="{ indices, refine }">
      <m-autocomplete-users-pure
        :items="parseIndices(indices)"
        :loading="loading"
        :value="selected"
        v-bind="{ ...$attrs, ...$props }"
        @onSearchChanged="refine($event)"
        v-on="$listeners"
      />
    </template>
  </ais-autocomplete>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { MAutocompleteUsersPure } from '@/components/Autocomplete';
import { UserEntity } from '@mentessa/types';
import { getFullName, makeUserEntityFromAIS } from '@/utils/user';

export default Vue.extend({
  name: 'm-ais-autocomplete-users',
  components: { MAutocompleteUsersPure },
  props: {
    value: { type: Object as PropType<UserEntity>, default: () => undefined },
    singleLine: { type: Boolean, default: true },
    debug: { type: Boolean, default: false },
  },
  data: () => ({
    items: [],
    loading: false,
  }),
  computed: {
    selected: {
      get: function (): UserEntity {
        return this.value;
      },
      set: function (value: UserEntity) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    fullName(user: UserEntity) {
      return getFullName(user);
    },
    parseIndices(indices) {
      const items = [];
      if (this.value && indices?.[0]?.hits.findIndex((hit) => hit.userId === this.value.id) === -1) {
        items.push(this.value);
      }
      if (indices?.[0]?.hits?.length) {
        items.push(...indices[0].hits.map((hit) => makeUserEntityFromAIS(hit)));
      }
      return items;
    },
  },
});
</script>
