import { ActionTree } from 'vuex';
import { TenantActions, TenantMutations, TenantState } from '@/store/tenant/types';
import { RootState } from '@/store/types';
import { api } from '@/store/api';
import { TenantEntity } from '@mentessa/types';
import { ShowErrorNotification, ShowNotification } from '@/store/notifications';
import i18n from '@/plugins/i18n';
import { getIdOrCurrent } from '@/utils/api';
import { AxiosRequestConfig } from 'axios';
import { communityRulesMutation, CommunityRulesMutations } from '@/store/communityRules';

export const actions: ActionTree<TenantState, RootState> = {
  async [TenantActions.LoadTenant]({ commit }) {
    const response = await api.get('tenants/current');
    const tenant: TenantEntity = response?.data;
    commit(TenantMutations.SetTenant, tenant);
    commit(
      communityRulesMutation(CommunityRulesMutations.SetCommunityRulesEnabled),
      tenant.attributes?.communityRules?.enabled ?? false,
      { root: true },
    );
    commit(
      communityRulesMutation(CommunityRulesMutations.SetCommunityRulesRequired),
      tenant.attributes?.communityRules?.required ?? false,
      { root: true },
    );
    console.log(`Tenant ${tenant.id} ("${tenant.attributes.name}") loaded`);
  },

  async [TenantActions.CreateTenant]({ dispatch, commit }, { options }) {
    try {
      const response = await api.post('onboarding/create_community', options);
      commit(TenantMutations.AppendAvailableTenant, response.data);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.log('Error while CreateTenant', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [TenantActions.InitTenantCreation]({ dispatch }) {
    try {
      await api.post('onboarding/init');
    } catch (e) {
      console.log('Error while InitTenantCreation', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [TenantActions.CreateIntegration]({ dispatch, commit }, { options }) {
    try {
      const response = await api.post('/integrations', options);
      commit(TenantMutations.SetIntegration, response.data);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.log('Error while CreateIntegration', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [TenantActions.UpdateIntegration]({ dispatch, commit }, { integrationRef, options }) {
    try {
      const response = await api.put(`/integrations/${integrationRef.id}`, options);
      commit(TenantMutations.UpdateIntegration, { ...integrationRef, ...options });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.log('Error while UpdateIntegration', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [TenantActions.DeleteIntegration]({ dispatch, commit }, { integration }) {
    try {
      const response = await api.delete(`/integrations/${integration.id}`);
      commit(TenantMutations.RemoveIntegration, integration);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.log('Error while DeleteIntegration', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [TenantActions.UpdateTenantAttributes]({ commit, dispatch }, { tenant, attributes }) {
    try {
      const response = await api.put(`tenants/${getIdOrCurrent(tenant)}/attributes`, attributes);
      const resultAttributes = response.data?.result.attributes;
      commit(TenantMutations.SetTenantAttributes, resultAttributes);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return resultAttributes;
    } catch (e) {
      console.log('Error while UpdateTenantAttributes', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return null;
    }
  },

  async [TenantActions.UpdateTenantConfig]({ commit, dispatch }, { tenant, config }) {
    try {
      const response = await api.put(`tenants/${getIdOrCurrent(tenant)}/config`, config);
      const resultConfig = response.data?.result.config;
      commit(TenantMutations.SetTenantConfig, resultConfig);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return resultConfig;
    } catch (e) {
      console.log('Error while UpdateTenantConfig', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return null;
    }
  },

  async [TenantActions.UpdateTenantAmbassador]({ dispatch }, { tenant, ambassadorRef }) {
    try {
      const config = { ambassador: { id: ambassadorRef.id } };
      const response = await api.put(`tenants/${getIdOrCurrent(tenant)}/ambassador`, config);
      return response.data;
    } catch (e) {
      console.log('Error while UpdateTenantAmbassador', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [TenantActions.UpdateTenantImage]({ commit, dispatch, state }, { tenant, blob, forceDomain }) {
    try {
      const config: AxiosRequestConfig = {};
      if (forceDomain) {
        const hostParts = window.location.host.split('.');
        hostParts[0] = forceDomain;
        config['baseURL'] = `${window.location.protocol}//${hostParts.join('.')}/api`;
      }
      const formData = new FormData();
      const tenantPrefix = tenant?.id ?? state.tenant?.id ?? forceDomain ?? 'tenant';
      let fileName = `${tenantPrefix}-image-${new Date().toISOString()}.jpeg`;
      if (blob.name) {
        const blobNameArr = blob.name.split('.');
        const format = blobNameArr[blobNameArr.length - 1];
        fileName = `${tenantPrefix}-image-${new Date().toISOString()}.${format}`;
      }
      formData.append('file', blob, fileName);
      const response = await api.post(`tenants/${getIdOrCurrent(tenant)}/image`, formData, config);
      const url = response.data?.result;
      commit(TenantMutations.SetTenantImage, url);
      return url;
    } catch (e) {
      console.log('Error while UpdateTenantImage', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
    return null;
  },
  async [TenantActions.LoadAvailableTenants]({ dispatch, commit }, { user }) {
    try {
      const response = await api.get(`users/${getIdOrCurrent(user)}/tenants`);
      if (response.status === 200) {
        commit(TenantMutations.SetAvailableTenants, response.data);
        return true;
      }
    } catch (e) {
      console.log('Error while LoadAvailableTenants', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
    return false;
  },

  async [TenantActions.InviteMembers]({ dispatch }, { emails, roles, tenant, forceDomain }) {
    try {
      const config: AxiosRequestConfig = {};
      if (forceDomain) {
        const hostParts = window.location.host.split('.');
        hostParts[0] = forceDomain;
        config['baseURL'] = `${window.location.protocol}//${hostParts.join('.')}/api`;
      }
      const result = await api.post(`tenants/${getIdOrCurrent(tenant)}/invite`, { emails, roles }, config);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return result?.status === 201;
    } catch (e) {
      console.error('Error while InviteMembers', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
    return false;
  },

  async [TenantActions.FindTags]({ dispatch }, { tenant, query }) {
    try {
      const queryString = query ? `?q=${query}` : '';
      const response = await api.get(`tenants/${getIdOrCurrent(tenant)}/tags${queryString}`);
      return response.data;
    } catch (e) {
      console.error('Error while FindTags', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [TenantActions.ChangeEmailRewriteEnabled]({ dispatch, commit }, { tenant, enabled }) {
    try {
      await api.post(`tenants/${getIdOrCurrent(tenant)}/features/email/to/rewrite`, { enabled });
      commit(TenantMutations.SetEmailRewriteEnabled, enabled);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
    } catch (e) {
      console.error('Error while ChangeEmailRewriteEnabled', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [TenantActions.ChangeEmailRewriteEmail]({ dispatch, commit }, { tenant, email }) {
    try {
      await api.post(`tenants/${getIdOrCurrent(tenant)}/features/email/to/rewrite`, { email });
      commit(TenantMutations.SetEmailRewriteEmail, email);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
    } catch (e) {
      console.error('Error while ChangeEmailRewriteEmail', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [TenantActions.RecreateSearchIndex]({ dispatch }) {
    try {
      await api.post(`search/populate`, { recreate: true });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
    } catch (e) {
      console.error('Error while RecreateSearchIndex', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [TenantActions.ReindexSearchForAllTenants]({ dispatch }) {
    try {
      await api.post(`search/reindex`);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
    } catch (e) {
      console.error('Error while ReindexSearchForAllTenants', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [TenantActions.ChangeFeatureEnabledState]({ commit, dispatch }, { tenantRef, feature, state }) {
    try {
      await api.post(`tenants/${getIdOrCurrent(tenantRef)}/features/${feature}`, { enabled: state });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      commit(TenantMutations.SetFeatureEnabledState, { feature, enabled: state });
    } catch (e) {
      console.error('Error while ChangeFeatureEnabledState', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [TenantActions.ChangeFeatureConfig]({ commit, dispatch }, { tenantRef, feature, config }) {
    try {
      await api.post(`tenants/${getIdOrCurrent(tenantRef)}/features/${feature}`, config);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      commit(TenantMutations.SetTenantConfig, { features: { [feature]: config } });
    } catch (e) {
      console.error('Error while ChangeFeatureConfig', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [TenantActions.LoadKeycloakClient]({ dispatch }, { tenantRef }) {
    try {
      const response = await api.get(`tenants/${getIdOrCurrent(tenantRef)}/keycloak/client`);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.error('Error while LoadKeycloakClient.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [TenantActions.UpdateKeycloakClient]({ dispatch }, { tenantRef }) {
    try {
      const response = await api.put(`tenants/${getIdOrCurrent(tenantRef)}/keycloak/client`);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.error('Error while UpdateKeycloakClient.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [TenantActions.CreateKeycloakClient]({ dispatch }, { tenantRef }) {
    try {
      const response = await api.post(`tenants/${getIdOrCurrent(tenantRef)}/keycloak/client`);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.error('Error while CreateKeycloakClient.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [TenantActions.ImportMembers]({ dispatch }, { members, sendInvite, tenantRef }) {
    try {
      const response = await api.post(`tenants/${getIdOrCurrent(tenantRef)}/import`, { members, sendInvite });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.error('Error while ImportMembers.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [TenantActions.UpdateAllowedProviders]({ dispatch }, { providers, tenantRef }) {
    try {
      const response = await api.put(`tenants/${getIdOrCurrent(tenantRef)}/allowedProviders`, { providers });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.error('Error while UpdateAllowedProviders.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [TenantActions.LoadTopSkills]({ dispatch }, { tenantRef }) {
    try {
      const response = await api.get(`tenants/${getIdOrCurrent(tenantRef)}/tags/top`);
      return response.data;
    } catch (e) {
      console.error('Error while LoadTopSkills.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
};
