<template>
  <v-navigation-drawer
    v-if="visible || isRulesRequiredNotAccepted"
    v-model="visible"
    :hide-overlay="false"
    :mobile-breakpoint="$vuetify.breakpoint.thresholds.md - 8"
    :permanent="isRulesRequiredNotAccepted"
    :temporary="!isRulesRequiredNotAccepted"
    class="m-community-rules-drawer"
    fixed
    right
  >
    <v-col class="community-rules-sidebar">
      <v-row v-if="editModeAvailable" class="gap-4">
        <v-switch v-model="enabled" :label="$t('communityRules.enabled')" />
        <v-switch v-model="required" :label="$t('communityRules.required')" />
        <v-switch v-model="editEnabled" append-icon="mdi-pencil" class="ml-auto" />
      </v-row>
      <v-row v-for="(communityRule, index) in communityRules" :key="`community-rule-${index}`">
        <div class="d-flex justify-space-between mt-2 fill-width">
          <h1 v-if="edit !== index">{{ title(communityRule) }}</h1>
          <m-text-field
            v-else
            v-model="newTitle"
            :background-color="deleteConfirmation ? 'var(--v-error-lighten4)' : undefined"
            :disabled="deleteConfirmation"
            :prepend-inner-icon="prependIcon"
            autofocus
            class="flex-grow-1 mr-2"
            dense
          />
          <div v-if="editEnabled">
            <div v-if="edit !== index">
              <v-btn icon>
                <v-icon class="ma-2" color="error" @click.stop="enableDelete(communityRule, index)"
                  >mdi-trash-can-outline
                </v-icon>
              </v-btn>
              <v-btn icon @click.stop="enableEdit(communityRule, index)">
                <v-icon class="ma-2" color="primary">mdi-pencil-outline</v-icon>
              </v-btn>
            </div>
            <div v-else>
              <v-btn icon @click.stop="deleteConfirmation ? deleteRules() : save()">
                <v-icon :color="deleteConfirmation ? 'error' : ''" class="ma-2">mdi-check</v-icon>
              </v-btn>
              <v-btn icon @click.stop="close">
                <v-icon class="ma-2">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <p v-if="edit !== index" class="rules-description">
          {{ description(communityRule) }}
        </p>
        <m-text-area
          v-else
          v-model="newDescription"
          :background-color="deleteConfirmation ? 'var(--v-error-lighten4)' : undefined"
          :disabled="deleteConfirmation"
          :prepend-inner-icon="prependIcon"
          autofocus
          class="flex-grow-1 mr-2"
          dense
        />
        <div v-for="(rule, index) in communityRule.rules" :key="`rule-${index}`" class="mt-2 fill-width">
          <m-community-rule-card :parentRule="communityRule" :rule="rule" :editEnabled="editEnabled" />
        </div>
        <div
          v-for="(rule, index) in currentNewCommunityRules(communityRule)"
          :key="`rule-new-${index}`"
          class="mt-2 fill-width"
        >
          <m-community-rule-card
            :parentRule="communityRule"
            :rule="rule"
            editMode
            edit-enabled
            @updateNewRules="removeNewRule(communityRule, rule)"
          />
        </div>
        <div v-if="editEnabled" class="fill-width" @click="addRule(communityRule)">
          {{ $t('communityRules.addRule') }}
          <v-btn icon>
            <v-icon color="primary">mdi-plus-circle-outline</v-icon>
          </v-btn>
        </div>
      </v-row>
      <v-row v-if="!rulesAccepted" class="justify-center mt-6 pb-10">
        <v-btn class="m-action-button" color="button" depressed @click="acceptRules"
          >{{ $t('communityRules.accept') }}
        </v-btn>
      </v-row>
      <v-row v-else class="justify-center mt-6 pb-10">
        <v-btn class="m-action-button" color="button" depressed @click="changeVisibility(false)"
          >{{ $t('communityRules.close') }}
        </v-btn>
      </v-row>
    </v-col>
  </v-navigation-drawer>
</template>

<script lang="ts">
import Vue from 'vue';
import MCommunityRuleCard from '@/components/Cards/MCommunityRuleCard';
import { mapGetters, mapState } from 'vuex';
import {
  ChangeCommunityRulesEnabled,
  ChangeCommunityRulesRequired,
  CommunityRulesState,
  DeleteCommunityRules,
  LoadCommunityRules,
} from '@/store/communityRules';
import { ICommunityRules } from '@mentessa/types';
import { AcceptRules, UsersState } from '@/store/users';
import {
  ChangeCommunityRulesVisible,
  CreateLocaleContent,
  LoadLocaleContent,
  LoadTranslations,
  UIState,
  UpdateLocaleContent,
} from '@/store/ui';
import { MTextArea, MTextField } from '@/components/Inputs';
import deepmerge from 'deepmerge';
import { v4 as uuid } from 'uuid';
import { TenantState } from '@/store/tenant';

export default Vue.extend({
  name: 'm-community-rules',
  components: { MCommunityRuleCard, MTextField, MTextArea },
  data: () => ({
    edit: undefined,
    newTitle: undefined,
    newDescription: undefined,
    deleteConfirmation: false,
    newCommunityRules: [],
    editEnabled: false,
  }),
  computed: {
    ...mapState<CommunityRulesState>('communityRules', {
      communityRules: (state: CommunityRulesState): Array<ICommunityRules> => state.communityRules,
      communityRulesEnabled: (state: CommunityRulesState) => state.enabled,
      communityRulesRequired: (state: CommunityRulesState) => state.required,
    }),
    ...mapState<UsersState>('users', {
      rulesAccepted: (state: UsersState) => state.me?.rulesAccepted ?? false,
      userComplete: (state: UsersState) => state.me?.complete ?? false,
    }),
    ...mapState<UIState>('ui', {
      communityRulesVisible: (state: UIState) => state.communityRulesVisible,
    }),
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    ...mapGetters('tenant', {
      isInvitesAvailable: 'isInvitesAvailable',
      isCommunityRulesAvailable: 'isCommunityRulesAvailable',
      isCommunityRulesRequired: 'isCommunityRulesRequired',
    }),
    tenantId() {
      return this.tenant.id;
    },
    prependIcon() {
      return this.deleteConfirmation ? 'mdi-trash-can-outline' : '';
    },
    isRulesRequiredNotAccepted() {
      return (
        this.isCommunityRulesRequired && this.isCommunityRulesAvailable && !this.rulesAccepted && this.userComplete
      );
    },

    visible: {
      get() {
        return this.communityRulesVisible;
      },
      set(value: boolean) {
        this.changeVisibility(value);
      },
    },

    enabled: {
      get() {
        return this.communityRulesEnabled;
      },
      async set(value: boolean) {
        await this.$store.dispatch(new ChangeCommunityRulesEnabled(value));
      },
    },

    required: {
      get() {
        return this.communityRulesRequired;
      },
      async set(value: boolean) {
        await this.$store.dispatch(new ChangeCommunityRulesRequired(value));
      },
    },
    editModeAvailable() {
      return this.isInvitesAvailable && this.userComplete;
    },
  },
  methods: {
    title(communityRule) {
      const transaltionKey = `communityRules.${communityRule.name}.title`;
      const translation = this.$t(transaltionKey);
      return translation === transaltionKey ? '' : translation;
    },
    description(communityRule) {
      const transaltionKey = `communityRules.${communityRule.name}.description`;
      const translation = this.$t(transaltionKey);
      return translation === transaltionKey ? '' : translation;
    },
    async acceptRules() {
      await this.$store.dispatch(new AcceptRules(this.user));
      this.changeVisibility(false);
    },
    changeVisibility(value: boolean) {
      this.$store.dispatch(new ChangeCommunityRulesVisible(value));
    },
    enableEdit(communityRule, index) {
      this.newTitle = this.$t(`communityRules.${communityRule.name}.title`);
      this.newDescription = this.$t(`communityRules.${communityRule.name}.description`);
      this.edit = index;
    },
    enableDelete(communityRule, index) {
      this.newTitle = this.$t(`communityRules.${communityRule.name}.title`);
      this.newDescription = this.$t(`communityRules.${communityRule.name}.description`);
      this.deleteConfirmation = true;
      this.edit = index;
    },
    close() {
      this.edit = -1;
      this.deleteConfirmation = false;
    },
    addRule(communityRule) {
      const index = this.newCommunityRules.findIndex((one) => one.name === communityRule.name);
      if (index != -1) {
        this.newCommunityRules[index].rules.push({ name: uuid() });
      } else {
        this.newCommunityRules.push({ ...communityRule, rules: [{ name: uuid() }] });
      }
    },
    currentNewCommunityRules(communityRule) {
      return this.newCommunityRules.find((one) => one.name === communityRule.name)?.rules ?? [];
    },
    removeNewRule(communityRule, rule) {
      const index = this.newCommunityRules.findIndex((one) => one.name === communityRule.name);
      if (index != -1) {
        this.newCommunityRules[index].rules = this.newCommunityRules[index].rules.filter(
          (one) => one.name !== rule.name,
        );
      }
    },
    async deleteRules() {
      try {
        await this.$store.dispatch(new DeleteCommunityRules(this.communityRules[this.edit]));
      } finally {
        this.close();
      }
    },
    async save() {
      try {
        const content = {
          communityRules: {
            [this.communityRules[this.edit].name]: {
              title: this.newTitle,
              description: this.newDescription,
            },
          },
        };
        const localeContent = await this.$store.dispatch(new LoadLocaleContent('en'));
        if (localeContent) {
          const mergedLocale = deepmerge(localeContent, content);
          await this.$store.dispatch(new UpdateLocaleContent('en', mergedLocale));
        } else {
          await this.$store.dispatch(new CreateLocaleContent('en', content));
        }
        await this.$store.dispatch(new LoadTranslations('en'));
      } finally {
        this.close();
      }
    },
  },
  async mounted() {
    await this.$store.dispatch(new LoadCommunityRules());
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.m-community-rules-drawer {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    width: 40% !important;
  }
  width: 320px !important;
  z-index: 15 !important;
}

.community-rules-sidebar {
  padding: 24px 32px;
  overflow-y: auto;
  background-color: var(--v-beige-base);
  height: 100%;
}

.m-action-button {
  min-width: 128px !important;
}

.rules-description {
  color: var(--v-gray-base);
}
</style>
