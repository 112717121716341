import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { mutations } from './mutations';
import { actions } from './actions';
import { CommunityRulesMutations, CommunityRulesState, moduleName } from './types';
import { getters } from './getters';

export * from './types';

export function communityRulesGetter(getter: string) {
  return `${moduleName}/${getter}`;
}

export function communityRulesMutation(mutation: CommunityRulesMutations) {
  return `${moduleName}/${mutation}`;
}

export const initialState: CommunityRulesState = {
  communityRules: [],
  enabled: false,
  required: false,
};

export const communityRulesModule: Module<CommunityRulesState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

export default communityRulesModule;
