<template>
  <div class="d-flex flex-column text-center pa-4 gap-4">
    <m-text-area v-model="about" :label="$t('profile.about.about')" sentry-tag="profile.textarea.about" />
    <m-panel beige class="text-left">
      <h3>{{ $t('onboarding_user.about.sample.title') }}</h3>
      <p>{{ $t('onboarding_user.about.sample.content') }}</p>
    </m-panel>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IUser } from '@mentessa/types';
import { MTextArea } from '@/components/Inputs';
import MPanel from '@/components/MPanel/MPanel.vue';

export default Vue.extend({
  name: 'm-onboarding-about-form',
  components: {
    MPanel,
    MTextArea,
  },
  props: {
    value: Object as PropType<IUser>,
  },
  computed: {
    about: {
      get() {
        return this.value?.attributes.about;
      },
      set(value: string) {
        this.$emit('input', {
          ...this.value,
          attributes: {
            ...this.value?.attributes,
            about: value,
          },
        });
      },
    },
  },
});
</script>
