<template>
  <div :style="style" class="m-autocomplete-pure" sentry-tag="autocomplete-pure">
    <p class="mb-2 m-autocomplete-pure-header">{{ labelTop }}</p>
    <v-autocomplete
      :search-input.sync="searchInput"
      :value="value"
      outlined
      persistent-placeholder
      sentry-tag="autocomplete-pure.autocomplete"
      v-bind="{ ...$props, ...$attrs }"
      v-on="$listeners"
    >
      <template v-slot:item="data">
        <slot :item="data.item" name="content">{{ data.item }}</slot>
      </template>
      <template v-slot:selection="data">
        <slot :item="data.item" name="content">{{ data.item }}</slot>
      </template>
    </v-autocomplete>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

/*

 ToDo: Default height = 48px.
 ToDo: Default width ?

 */
export default Vue.extend({
  name: 'm-autocomplete-pure',
  props: {
    labelTop: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    items: { type: Array, default: () => [] },
    maxWidth: { type: String, default: '320px' },
    value: undefined,
    // filter: Function,
  },
  data: () => ({
    search: null,
  }),
  computed: {
    searchInput: {
      get() {
        return this.search;
      },
      set(newValue) {
        if (newValue != this.search) {
          this.search = newValue;
          this.$emit('onSearchChanged', newValue);
        }
      },
    },
    style() {
      return {
        '--preview-max-width': this.maxWidth,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.m-autocomplete-pure-header {
  color: #374151;
  font-size: 14px;
  font-weight: 600;
}

.m-autocomplete-pure {
  min-width: 120px;
  max-width: var(--preview-max-width);
}

.m-autocomplete-pure:deep(.v-input__slot) {
  background-color: var(--v-white-base);
}

.m-autocomplete-pure .v-text-field--filled > .v-input__control > .v-input__slot,
.m-autocomplete-pure .v-text-field--full-width > .v-input__control > .v-input__slot,
.m-autocomplete-pure .v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 48px;
}

.m-autocomplete-pure .v-input__icon {
  position: relative;
  top: -5px;
}
</style>
