import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { api } from '@/store/api';
import { ShowErrorNotification, ShowNotification } from '@/store/notifications';
import i18n from '@/plugins/i18n';
import { CommunityRulesActions, CommunityRulesMutations, CommunityRulesState } from './types';
import { getIdOrCurrent } from '@/utils/api';

export const actions: ActionTree<CommunityRulesState, RootState> = {
  async [CommunityRulesActions.LoadCommunityRules]({ commit, dispatch }, { tenant }) {
    try {
      const response = await api.get(`/tenants/${getIdOrCurrent(tenant)}/community-rules`);
      const communityRules = response?.data;
      commit(CommunityRulesMutations.SetCommunityRules, communityRules);
    } catch (e) {
      console.error('Error while LoadCommunityRules.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [CommunityRulesActions.CreateCommunityRules]({ dispatch, commit }, { communityRules, tenant }) {
    try {
      const response = await api.post(`/tenants/${getIdOrCurrent(tenant)}/community-rules`, communityRules);
      commit(CommunityRulesMutations.AppendCommunityRules, communityRules);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response?.status === 201 ?? false;
    } catch (e) {
      console.error('Error while CreateCommunityRules.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [CommunityRulesActions.DeleteCommunityRules]({ dispatch, commit }, { communityRules, tenant }) {
    try {
      const response = await api.delete(`/tenants/${getIdOrCurrent(tenant)}/community-rules/${communityRules.id}`);
      if (Array.isArray(response.data)) {
        commit(CommunityRulesMutations.SetCommunityRules, response.data);
      } else {
        commit(CommunityRulesMutations.RemoveCommunityRules, communityRules);
      }
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
    } catch (e) {
      console.error('Error while DeleteCommunityRules.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [CommunityRulesActions.UpdateCommunityRules]({ dispatch, commit }, { communityRulesRef, update, tenant }) {
    try {
      const response = await api.patch(
        `/tenants/${getIdOrCurrent(tenant)}/community-rules/${communityRulesRef.id}`,
        update,
      );
      if (Array.isArray(response.data)) {
        commit(CommunityRulesMutations.SetCommunityRules, response.data);
      } else {
        commit(CommunityRulesMutations.UpdateCommunityRules, response.data);
      }

      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response?.status === 200 ?? false;
    } catch (e) {
      console.error('Error while UpdateCommunityRules.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [CommunityRulesActions.ChangeCommunityRulesEnabled]({ dispatch, commit }, { status, tenant }) {
    try {
      const response = await api.put(`/tenants/${getIdOrCurrent(tenant)}/attributes/communityRules`, {
        enabled: status,
      });
      commit(CommunityRulesMutations.SetCommunityRulesEnabled, status);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response?.status === 200 ?? false;
    } catch (e) {
      console.error('Error while ChangeCommunityRulesEnabled.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [CommunityRulesActions.ChangeCommunityRulesRequired]({ dispatch, commit }, { status, tenant }) {
    try {
      const response = await api.put(`/tenants/${getIdOrCurrent(tenant)}/attributes/communityRules`, {
        required: status,
      });
      commit(CommunityRulesMutations.SetCommunityRulesRequired, status);
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response?.status === 200 ?? false;
    } catch (e) {
      console.error('Error while ChangeCommunityRulesEnabled.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
};
