import store from '@/store';
import { Route } from 'vue-router';
import { NavigationGuardNext } from 'vue-router/types/router';
import { RouteName } from '@/router/types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function matchesGuard(to: Route, from: Route, next: NavigationGuardNext): boolean {
  if (!store.getters['tenant/isMatchingAvailable']) {
    next({ name: RouteName.Settings });
  } else {
    next();
  }
  return true;
}
