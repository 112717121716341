import { MutationTree } from 'vuex';
import { RitualTemplatesMutations, RitualTemplatesState } from './types';
import {
  IRitualTemplate,
  ITenantRitualTemplate,
  RitualTemplateReference,
  TenantRitualTemplateReference,
} from '@mentessa/types';

export interface UpdateRitualTemplateOptions {
  ritualTemplateRef: RitualTemplateReference;
  dto: Partial<IRitualTemplate>;
}

export interface AppendRitualTemplateOptions {
  ritualTemplate: IRitualTemplate;
}

export interface RemoveRitualTemplateOptions {
  ritualTemplateRef: RitualTemplateReference;
}

export interface UpdateRitualTemplateOptions {
  ritualTemplateRef: TenantRitualTemplateReference;
  update: Partial<ITenantRitualTemplate>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function mergeInplace(target: any, source: any) {
  for (const [key, val] of Object.entries(source)) {
    if (val != null && typeof val === 'object') {
      target[key] ??= {};
      // console.log(`Update nested onbject ${key}`);
      mergeInplace(target[key], val);
    } else if (target[key] !== val) {
      console.log(`Assign ${key} from ${target[key]} to "${val}"`);
      target[key] = val;
    }
  }
  return target;
}

export const mutations: MutationTree<RitualTemplatesState> = {
  [RitualTemplatesMutations.SetRitualTemplates](
    state: RitualTemplatesState,
    ritualTemplates: Array<ITenantRitualTemplate>,
  ) {
    state.ritualTemplates = ritualTemplates;
  },

  [RitualTemplatesMutations.UpdateRitualTemplate](state: RitualTemplatesState, options: UpdateRitualTemplateOptions) {
    const template = state.ritualTemplates.find((template) => template.id === options.ritualTemplateRef.id);
    if (template) {
      template.enabled = options.update.enabled;
    }
  },

  [RitualTemplatesMutations.SetRawRitualTemplates](
    state: RitualTemplatesState,
    ritualTemplates: Array<IRitualTemplate>,
  ) {
    state.rawRitualTemplates = ritualTemplates;
  },

  [RitualTemplatesMutations.UpdateRawRitualTemplate](
    state: RitualTemplatesState,
    options: UpdateRitualTemplateOptions,
  ) {
    const templateIndex = state.rawRitualTemplates.findIndex(
      (template) => template.id === options.ritualTemplateRef.id,
    );
    if (templateIndex !== -1) {
      mergeInplace(state.rawRitualTemplates[templateIndex], options.dto);
    }
  },

  [RitualTemplatesMutations.AppendRawRitualTemplate](
    state: RitualTemplatesState,
    options: AppendRitualTemplateOptions,
  ) {
    state.rawRitualTemplates = [options.ritualTemplate, ...state.rawRitualTemplates];
  },

  [RitualTemplatesMutations.RemoveRawRitualTemplate](
    state: RitualTemplatesState,
    options: RemoveRitualTemplateOptions,
  ) {
    state.rawRitualTemplates = state.rawRitualTemplates.filter(
      (template) => template.id !== options.ritualTemplateRef.id,
    );
  },
};
