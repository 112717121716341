<template>
  <div v-show="value" class="m-inbox-sidebar-wrap" sentry-tag="inbox-sidebar-wrap">
    <div class="m-inbox-sidebar px-0">
      <div class="m-inbox-sidebar__header pb-2 d-flex align-center">
        <h1 class="m-inbox-sidebar__title">{{ $t('chat.title') }}</h1>
        <div class="d-flex align-center ml-auto mr-4">
          <a @click="isSelectUserOpened = !isSelectUserOpened">
            <v-img src="@/assets/inbox/plus.svg" />
          </a>
        </div>
      </div>
      <m-ais-wrapper
        v-if="isSelectUserOpened || isSelectUserOpenedExternal"
        class="m-inbox-sidebar__search ma-4"
        index="users"
      >
        <m-ais-autocomplete-users
          :placeholder="$t('chat.selectRoom')"
          max-width="600px"
          prepend-inner-icon="mdi-message-plus-outline"
          @input="selectUser"
          :exclude="[me]"
        />
      </m-ais-wrapper>
      <div>
        <div
          v-for="room in roomsWithNew"
          :key="room.id"
          :class="{ active: isActive(room) }"
          class="m-inbox-sidebar__rooms-items py-3 px-4"
          @click="selectRoom(room)"
          sentry-tag="inbox-chat.room"
        >
          <div class="d-flex align-center font-size-16 font-weight-medium">
            <m-user-preview :user="getDirectMember(room, me)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { MAisAutocompleteUsers, MAisWrapper } from '@/components/MAIS';
import { mapState } from 'vuex';
import { FindDirectRoom, MessagesState, SelectRoom } from '@/store/messages';
import { IUser, RoomEntity, RoomType } from '@mentessa/types';
import { LoadUserDetails, UsersState } from '@/store/users';
import { getFullName } from '@/utils/user';
import MUserPreview from '@/components/MUserPreview/UserPreviewPure.vue';
import { getDirectMember } from '@/components/MInbox/utils';
import { RouteName } from '@/router/types';

export default Vue.extend({
  name: 'm-inbox-sidebar',
  components: {
    MUserPreview,
    MAisWrapper,
    MAisAutocompleteUsers,
  },
  props: {
    value: { type: Boolean, default: false }, // is sidebar visible?
    isSelectUserOpenedExternal: { type: Boolean, default: false },
  },
  data: () => ({
    filterName: '',
    isLoading: false,
    selectedUser: undefined,
    newRoom: null,
    isSelectUserOpened: false,
  }),
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState): IUser => state.me,
    }),
    ...mapState<MessagesState>('messages', {
      rooms: (state: MessagesState) => state.rooms,
      messages: (state: MessagesState) => state.messages,
      room: (state: MessagesState) => state.activeRoom,
      roomsWithNew() {
        if (this.newRoom) return [this.newRoom, ...this.rooms];
        return this.rooms;
      },
    }),
  },
  watch: {
    async room(newValue: RoomEntity, oldValue: RoomEntity) {
      if (oldValue && oldValue.id !== newValue.id) {
        if (this.$route.path !== `/inbox/${newValue.id}`) {
          await this.$router.push({ name: RouteName.InboxRoom, params: { roomId: String(newValue.id) } });
        }
      }
    },
  },
  methods: {
    isActive(room: RoomEntity): boolean {
      return room.id === this.room?.id;
    },
    async selectRoom(room: RoomEntity) {
      if (room.id !== this.room?.id || room.id === 0) {
        await this.$store.dispatch(new SelectRoom(room));
        if (this.$route.path !== `/inbox/${room.id}`) {
          await this.$router.push({ name: RouteName.InboxRoom, params: { roomId: String(room.id) } });
        }
      }
      if (this.$vuetify.breakpoint.xs) {
        this.$emit('input', false);
      }
    },
    async selectUser(user: IUser) {
      this.selectedUser = user;
      const room = this.rooms.find((room) => room.members.some((rm) => rm.user?.id === user.id));
      if (room) {
        await this.selectRoom(room);
        this.isSelectUserOpened = false;
        return;
      }

      await this.selectRoom({
        type: RoomType.Direct,
        id: 0,
        members: [
          {
            user: this.me as IUser,
          },
          {
            user: user,
          },
        ],
      });

      this.isSelectUserOpened = false;
    },
    getFullName,
    getDirectMember,
  },
  async mounted() {
    if (this.$route.query.direct) {
      if (this.$route.query.direct == this.me.id) return await this.$router.push('/inbox');
      const room = await this.$store.dispatch(new FindDirectRoom(this.$route.query.direct));
      if (room) {
        await this.selectRoom(room);
      } else {
        const user = await this.$store.dispatch(new LoadUserDetails(this.$route.query.direct, true));
        if (user) {
          await this.selectRoom({
            type: RoomType.Direct,
            id: 0,
            members: [
              {
                user: this.me as IUser,
              },
              {
                user: user as IUser,
              },
            ],
          });
        }
      }
    }
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.m-inbox-sidebar-wrap {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    border-right: 1px solid var(--v-beige-base);
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    width: 100%;
  }
}

.m-inbox-sidebar {
  position: sticky;
  top: 60px;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    max-width: 280px;
    min-width: 280px;
    //height: calc(100vh - 100px);
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    top: 0;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    width: 100%;
    max-width: none;
    min-width: auto;
  }

  &__header {
    border-bottom: 1px solid #e8e8e8;
  }

  &__rooms-items {
    background: #fff;
    border-top: 1px solid #e8e8e8;
    cursor: pointer;

    &:nth-child(1) {
      border-color: transparent;
    }

    &.active {
      background: var(--v-beige-base);
      border-radius: 8px;
      border-color: transparent;

      :deep(.m-user-avatar__empty) {
        border: 1px solid var(--v-white-base) !important;
      }
    }

    &.active + .m-inbox-sidebar__rooms-items {
      border-color: transparent;
    }
  }

  &__input {
    border-top: 1px solid var(--v-lightgray-base);
  }

  &__search {
    border-bottom: 1px solid var(--v-lightgray-base);
  }

  &__title {
    line-height: 51px;
  }
}
</style>
