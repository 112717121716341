<template>
  <div>
    <p class="m-settings-description">{{ $t('settings.members.description') }}</p>
    <v-container class="m-settings-members mt-8" sentry-tag="settings-members">
      <div class="d-flex flex-column flex-md-row gap-4">
        <m-search-field v-model="search" :loading="loading" />
        <div class="d-flex flex-row gap-4 align-center flex-wrap justify-end">
          <v-btn
            v-if="invitationsAvailable"
            color="button"
            depressed
            large
            sentry-tag="settings-members.button.add"
            @click.stop="addMembersDialogOpened = true"
          >
            {{ $t('settings.members.add.full') }}
          </v-btn>
          <v-btn
            v-if="invitationsAvailable"
            :loading="exportInProgress"
            color="button"
            depressed
            large
            @click="exportMembers"
          >
            {{ $t('settings.members.export') }}
          </v-btn>
        </div>
      </div>
      <v-row class="mt-4">
        <v-col class="pa-0">
          <v-data-table
            :headers="headers"
            :items="members.items"
            :loading="loading"
            :options.sync="options"
            :server-items-length="members.meta.totalItems"
            class="m-settings-members__table"
            hide-default-footer
          >
            <template v-for="(col, i) in filters" v-slot:[`header.${i}`]="{ header }">
              {{ header.text }}
              <v-menu :key="i" :close-on-content-click="false" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon :color="activeFilters[header.value]?.length > 0 ? 'primary' : ''" small> mdi-filter</v-icon>
                  </v-btn>
                </template>
                <v-list class="pa-0" style="width: 200px">
                  <v-list-item class="px-2" ripple @click="resetFilter(header.value)">
                    <v-list-item-action class="mr-2">
                      <v-icon :color="activeFilters[header.value]?.length > 0 ? 'primary darken-1' : ''">
                        mdi-close-box
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> {{ $t('filters.reset') }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2" />
                  <v-list-item-group v-model="activeFilters[header.value]" class="py-2" multiple>
                    <template v-for="item in filters[header.value]">
                      <v-list-item :key="item.value" :ripple="false" :value="item.value" class="px-2">
                        <template v-slot:default="{ active }">
                          <v-list-item-action class="mr-2">
                            <v-checkbox :input-value="active" :ripple="false" color="primary" dense />
                          </v-list-item-action>
                          <v-list-item-title> {{ filterTitle(item, header) }}</v-list-item-title>
                        </template>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:item.name="{ item }">
              <m-user-preview :user="item" />
            </template>
            <template v-slot:item.email="{ item }">
              <div>{{ item.identity.attributes.email }}</div>
            </template>
            <template v-slot:item.attributes.roles="{ item }">
              <v-chip-group column>
                <v-chip v-for="(index, value) of item.attributes.roles" :key="`role-${index}`" small>
                  {{ roleName(item.attributes.roles[value]) }}
                </v-chip>
              </v-chip-group>
            </template>
            <template v-slot:item.matchingAuto="{ item }">
              <v-chip v-if="isEnabledToMatch(item)" color="success" small>
                {{ $t('settings.members.matchingAuto.enabled') }}
              </v-chip>
              <v-chip v-else small>{{ $t('settings.members.matchingAuto.disabled') }}</v-chip>
            </template>
            <template v-slot:item.status="{ item }">
              <v-chip v-if="item.hidden" color="grey" small text-color="white">
                {{ $t('settings.members.status.hidden') }}
              </v-chip>
              <v-chip v-else-if="item.deletedAt" color="red" small text-color="white">
                {{ $t('settings.members.status.suspended') }}
              </v-chip>
              <v-chip v-else-if="item.complete" color="success" small
                >{{ $t('settings.members.status.active') }}
              </v-chip>
              <v-chip v-else small>{{ $t('settings.members.status.invited') }}</v-chip>
            </template>
            <template v-slot:item.createdAt="{ item }">
              {{ formatDate(item.createdAt) }}
            </template>
            <template v-slot:item.lastSeen="{ item }">
              {{ formatDate(item.lastSeen) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="pa-3">
                <v-menu :disabled="loading" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-if="!item.deletedAt">
                      <v-list-item v-if="!item.complete" class="text--darken-3" link>
                        <v-list-item-title sentry-tag="settings-members.menu.resendInvite" @click="resendInvite(item)">
                          {{ $t('settings.members.action.resendInvite') }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        link
                        sentry-tag="settings-members.menu.manageRoles"
                        @click.stop="openManageMemberRolesDialog(item)"
                      >
                        <v-list-item-title>{{ $t('settings.members.action.manageRoles') }}</v-list-item-title>
                      </v-list-item>
                      <v-divider />
                      <v-list-item
                        class="text--darken-3"
                        link
                        sentry-tag="settings-members.menu.openSuspendMemberDialog"
                        @click.stop="openSuspendMemberDialog(item)"
                      >
                        <v-list-item-title>{{ $t('settings.members.action.suspend') }}</v-list-item-title>
                      </v-list-item>
                    </template>
                    <v-list-item
                      v-else
                      class="text--darken-3"
                      link
                      sentry-tag="settings-members.menu.restoreUser"
                      @click="restoreUser(item)"
                    >
                      <v-list-item-title>{{ $t('settings.members.action.restore') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      class="error--text"
                      link
                      sentry-tag="settings-members.menu.openDeleteMemberDialog"
                      @click.stop="openDeleteMemberDialog(item)"
                    >
                      <v-list-item-title>{{ $t('settings.members.action.delete') }}</v-list-item-title>
                    </v-list-item>
                    <template v-if="isDebugAvailable">
                      <v-divider />
                      <v-list-item v-if="item.hidden" link @click="unHideUser(item)">
                        <v-list-item-title sentry-tag="settings-members.menu.unHide">
                          {{ $t('settings.members.action.unHide') }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item v-else link @click="hideUser(item)">
                        <v-list-item-title sentry-tag="settings-members.menu.hide">
                          {{ $t('settings.members.action.hide') }}
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item v-if="isImpersonateAvailable" link @click="impersonateUser(item)">
                        <v-list-item-title sentry-tag="settings-members.menu.impersonate">
                          {{ $t('settings.members.action.impersonate') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </div>
            </template>
          </v-data-table>
          <v-row dense>
            <v-pagination
              v-model="currentPage"
              :disabled="loading"
              :length="members.meta.totalPages"
              class="m-settings-members__table-pagination pa-6 ml-auto"
              sentry-tag="settings-members.pagination"
            />
          </v-row>
        </v-col>
      </v-row>
      <m-add-members-dialog v-model="addMembersDialogOpened" @members-invited="loadMembers" />
      <m-delete-member-dialog
        v-model="deleteMemberDialogOpened"
        :user="selectedUser"
        @member-deleted="closeDeleteMemberDialog"
      />
      <m-suspend-member-dialog
        v-model="suspendMemberDialogOpened"
        :user="selectedUser"
        @member-suspended="closeSuspendMemberDialog"
      />
      <m-manage-member-roles-dialog
        v-model="manageMemberRolesDialogOpened"
        :user="selectedUser"
        @roles-changed="closeManageMemberRolesDialog"
      />
    </v-container>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { ExportMembers, HideUser, LoadMembers, RestoreUser, UnHideUser, UsersState } from '@/store/users';
import { IUser, UserEntity, UserReference, UsersFeature } from '@mentessa/types';
import {
  MAddMembersDialog,
  MDeleteMemberDialog,
  MManageMemberRolesDialog,
  MSuspendMemberDialog,
} from '@/components/Dialogs';
import { MUserPreview } from '@/components/MUserPreview';
import { InviteMembers, TenantState } from '@/store/tenant';
import { MSearchField } from '@/components/Inputs';

export default Vue.extend({
  name: 'm-settings-members',
  components: {
    MSearchField,
    MUserPreview,
    MDeleteMemberDialog,
    MAddMembersDialog,
    MSuspendMemberDialog,
    MManageMemberRolesDialog,
  },
  data: () => ({
    loading: false,
    exportInProgress: false,
    addMembersDialogOpened: false,
    deleteMemberDialogOpened: false,
    suspendMemberDialogOpened: false,
    manageMemberRolesDialogOpened: false,
    selectedUser: null,
    search: '',
    activeFilters: {},
    options: {},
  }),
  computed: {
    ...mapState<UsersState>('users', {
      members: (state: UsersState) => state.members,
    }),
    ...mapGetters('tenant', {
      featureConfig: 'featureConfig',
      isDebugAvailable: 'isDebugAvailable',
    }),
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    ...mapGetters('ui', {
      formatInUserTimeZone: 'formatInUserTimeZone',
    }),
    isImpersonateAvailable() {
      return this.$impersonate?.enabled ?? false;
    },
    filters() {
      const roles = ['Admin'];
      const customRoles = this.tenant?.attributes.customRoles;
      if (customRoles?.length > 0) roles.push(...customRoles);
      let availableFilters = {
        status: [
          { text: this.$t('settings.members.status.active'), value: 'active', showCount: true },
          { text: this.$t('settings.members.status.invited'), value: 'invited', showCount: true },
          { text: this.$t('settings.members.status.suspended'), value: 'suspended', showCount: true },
        ],
        'attributes.roles': roles.map((item) => {
          return { text: item, value: item, showCount: true };
        }),
      };
      if (this.isDebugAvailable) {
        availableFilters['status'] = [
          ...availableFilters['status'],
          { text: this.$t('settings.members.status.hidden'), value: 'hidden', showCount: true },
        ];
      }
      return availableFilters;
    },
    filterTitle() {
      return (item, header) =>
        `${item.text}${item.showCount ? ` (${this.members.meta.filterCount?.[header.value]?.[item.value] ?? 0})` : ''}`;
    },
    usersFeature() {
      return this.featureConfig('users') as UsersFeature;
    },
    currentPage: {
      get() {
        return this.members?.meta?.currentPage ?? 1;
      },
      async set(value: number) {
        await this.loadMembers(value);
      },
    },
    headers() {
      let columnHeaders = [
        { text: this.$t('settings.members.table.name'), value: 'name', sortable: false },
        { text: this.$t('settings.members.table.email'), value: 'email', sortable: false },
        { text: this.$t('settings.members.table.role'), value: 'attributes.roles' },
        { text: this.$t('settings.members.table.status'), value: 'status', align: 'center' },
        { text: this.$t('settings.members.table.location'), value: 'attributes.location' },
        { text: this.$t('settings.members.table.createdAt'), value: 'createdAt' },
        { text: this.$t('settings.members.table.lastSeen'), value: 'lastSeen' },
        { text: this.$t('settings.members.table.actions'), value: 'actions', align: 'center', sortable: false },
      ];
      if (this.isDebugAvailable) {
        columnHeaders = [{ text: 'Id', value: 'id', sortable: false }, ...columnHeaders];
      }
      return columnHeaders;
    },
    invitationsAvailable() {
      return !this.usersFeature.sso;
    },
    roleName() {
      return (role: string) => {
        if (this.$te(`user.roles.${role}`)) {
          return this.$t(`user.roles.${role}`);
        }
        return role;
      };
    },
  },
  watch: {
    async search() {
      await this.loadMembers();
    },
    activeFilters: {
      async handler() {
        await this.loadMembers();
      },
      deep: true,
    },
    options: {
      async handler() {
        await this.loadMembers();
      },
      deep: true,
    },
  },
  async mounted() {
    await this.loadMembers();
  },
  methods: {
    isEnabledToMatch(item) {
      return item?.attributes?.matching?.auto;
    },
    resetFilter(field: string) {
      this.activeFilters[field] = [];
    },
    async loadMembers(page = 1) {
      try {
        const { sortBy, sortDesc } = this.options;
        this.loading = true;
        await this.$store.dispatch(
          new LoadMembers(
            page,
            this.search,
            this.activeFilters['status'],
            this.activeFilters['attributes.roles'],
            sortBy[0],
            sortBy.length > 0 ? (sortDesc[0] ? 'DESC' : 'ASC') : 'DESC',
          ),
        );
      } finally {
        this.loading = false;
      }
    },
    async resendInvite(user: UserEntity) {
      try {
        this.loading = true;
        await this.$store.dispatch(new InviteMembers([user.identity.attributes.email]));
      } finally {
        this.loading = false;
      }
    },
    openDeleteMemberDialog(user: UserEntity) {
      this.selectedUser = user;
      this.deleteMemberDialogOpened = true;
    },
    openSuspendMemberDialog(user: UserEntity) {
      this.selectedUser = user;
      this.suspendMemberDialogOpened = true;
    },
    openManageMemberRolesDialog(user: UserEntity) {
      this.selectedUser = user;
      this.manageMemberRolesDialogOpened = true;
    },
    closeDeleteMemberDialog() {
      this.deleteMemberDialogOpened = false;
      this.loadMembers();
    },
    closeSuspendMemberDialog() {
      this.suspendMemberDialogOpened = false;
      this.loadMembers();
    },
    closeManageMemberRolesDialog() {
      this.manageMemberRolesDialogOpened = false;
    },
    isAdmin(user: IUser) {
      return user?.attributes.roles?.includes('Admin') ?? false;
    },
    async reloadFilters() {
      if (Object.keys(this.activeFilters).length !== 0) {
        await this.loadMembers();
      }
    },
    async restoreUser(user: UserEntity) {
      try {
        this.loading = true;
        await this.$store.dispatch(new RestoreUser(user));
        await this.reloadFilters();
      } finally {
        this.loading = false;
      }
    },
    async hideUser(user: UserEntity) {
      try {
        this.loading = true;
        await this.$store.dispatch(new HideUser(user));
        await this.reloadFilters();
      } finally {
        this.loading = false;
      }
    },
    async unHideUser(user: UserEntity) {
      try {
        this.loading = true;
        await this.$store.dispatch(new UnHideUser(user));
        await this.reloadFilters();
      } finally {
        this.loading = false;
      }
    },
    impersonateUser(userRef: UserReference) {
      this.$impersonate.setUser(userRef);
    },
    formatDate(date: string) {
      return this.formatInUserTimeZone(date, 'dd.MM.yyyy');
    },
    async exportMembers() {
      // ToDo: Set loading state and call call export API endpoint with all filters
      this.loading = true;
      this.exportInProgress = true;
      try {
        await this.$store.dispatch(new ExportMembers(this.activeFilters));
      } finally {
        this.loading = false;
        this.exportInProgress = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.m-settings-members {
  border: 1px solid var(--v-beige-base);
  border-radius: 16px;
  background-color: var(--v-white-base);

  &__header {
    border-bottom: 1px solid var(--v-lightgray-base);
  }

  &__table {
    border-radius: unset;
    border-bottom: 1px solid var(--v-lightgray-base);
  }

  &__table-pagination:deep(.v-pagination__navigation, .v-pagination__item) {
    box-shadow: none;
  }
}

:deep(.v-data-table-header) th {
  white-space: nowrap;
}
</style>
