<template>
  <div class="d-flex align-center flex-column fill-height">
    <v-img :src="require('@/assets/sparkles.png')" contain max-height="128" max-width="128" />
    <h2 class="text--text my-4 text-center">
      {{ $t(`ritual.enrollDialog.${ritualTypeTranslationPath}.success.title`) }}
    </h2>

    <div class="text--text mt-auto text-center font-size-18" v-html="$marked(content)" />

    <v-row class="justify-end mt-auto flex-grow-0 fill-width">
      <v-col cols="6">
        <v-btn color="button" depressed width="100%" @click="doAction">
          {{ $t(`ritual.enrollDialog.${ritualTypeTranslationPath}.success.cta`, { tenant: tenantTitle }) }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';
import { UsersState } from '@/store/users';
import { IRitual, RitualType } from '@mentessa/types';
import { getFirstName } from '@/utils/user';

export default Vue.extend({
  name: 'm-success-form',
  props: {
    ritual: Object as PropType<IRitual>,
  },
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    tenantTitle() {
      return this.tenant?.attributes?.theme?.title ?? '';
    },
    firstName() {
      return getFirstName(this.me);
    },
    ritualTitle() {
      if (!this.ritual) return '';
      if (this.ritual.attributes?.title) return this.ritual.attributes?.title;
      return this.$t(`settings.rituals.knownNames.${this.ritual.type}`);
    },
    ritualTypeTranslationPath() {
      switch (this.ritual.type) {
        case RitualType.SkillsMatching: {
          return RitualType.SkillsMatching;
        }
        default: {
          return 'default';
        }
      }
    },
    content(): string {
      return this.$t(`ritual.enrollDialog.${this.ritualTypeTranslationPath}.success.content`, {
        ritual: this.ritualTitle,
      });
    },
  },
  methods: {
    async doAction() {
      this.$emit('onNext');
    },
  },
});
</script>
