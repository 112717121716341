<template>
  <div class="d-flex justify-center align-center flex-column">
    <m-chip-group v-model="selected" :items="items" class="mt-4" i18n-path="onboarding.looking_for.items" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { MChipGroup } from '@/components/Inputs';

export default Vue.extend({
  name: 'm-tenant-onboarding-looking-for-form',
  components: { MChipGroup },
  props: {
    value: { type: Array as PropType<Array<string>>, default: () => [] },
  },
  data: () => ({
    items: ['automate', 'transformation', 'skills', 'connect', 'culture', 'guidance'],
  }),
  computed: {
    selected: {
      get() {
        return this.value.map((item) => this.items.indexOf(item));
      },
      set(value: Array<number>) {
        this.$emit(
          'input',
          value.map((index) => this.items[index]),
        );
      },
    },
  },
  methods: {
    submitStep(): void {
      this.$emit('onSubmit');
    },
  },
});
</script>
