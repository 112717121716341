<template>
  <v-card class="m-skeleton-row-user-card mb-3 mt-3" sentry-tag="skeleton-row-user-card">
    <div class="m-skeleton-row-user-card__container d-flex align-center justify-center">
      <v-skeleton-loader class="mr-4" type="avatar" />
      <div>
        <v-skeleton-loader class="mb-3" type="text" style="width: 100px" />
        <v-skeleton-loader type="text" style="width: 200px" />
      </div>
      <div>
        <div class="d-flex mb-3">
          <v-skeleton-loader type="text" style="width: 60px" />
          <v-skeleton-loader class="ml-2" type="text" style="width: 200px" />
        </div>
        <div class="d-flex">
          <v-skeleton-loader type="text" style="width: 60px" />
          <v-skeleton-loader class="ml-2" type="text" style="width: 200px" />
        </div>
      </div>
      <div class="ml-auto">
        <v-skeleton-loader v-if="!invite" class="m-skeleton-row-user-card_button" width="75" type="button" />
        <v-btn
          v-else
          @click="isAddMembersDialogOpened = true"
          color="button"
          depressed
          sentry-tag="skeleton-row-user-card.button.invite"
        >
          {{ $t('inviteCard.buttonText') }}
        </v-btn>
      </div>
    </div>
    <m-add-members-dialog v-model="isAddMembersDialogOpened" />
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import MAddMembersDialog from '@/components/Dialogs/MAddMembersDialog/MAddMembersDialog.vue';

export default Vue.extend({
  name: 'm-skeleton-row-user-card',
  components: { MAddMembersDialog },
  props: {
    invite: { type: Boolean, default: false },
  },
  data: () => ({
    isAddMembersDialogOpened: false,
  }),
});
</script>

<style lang="scss" scoped>
.m-skeleton-row-user-card {
  display: flex;
  padding: 24px;

  &__container {
    gap: 30px;
    width: 100%;
  }

  &:deep(.v-skeleton-loader__button) {
    width: 75px;
  }
}
</style>
