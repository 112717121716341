<template>
  <m-panel :title="$t('panels.user_stats.connecting.title')" draggable="draggable">
    <v-row class="text-center">
      <v-col>
        <h1>{{ appointments }}</h1>
        {{ $t('panels.user_stats.connecting.sessions') }}
      </v-col>
      <v-col>
        <h1>{{ matches }}</h1>
        {{ $t('panels.user_stats.connecting.matches') }}
      </v-col>
      <v-col>
        <h1>{{ contacts }}</h1>
        {{ $t('panels.user_stats.connecting.contacts') }}
      </v-col>
    </v-row>
    <h4 class="text-uppercase mt-4">{{ $t('panels.user_stats.learning.title') }}</h4>
    <v-row class="text-center">
      <v-col>
        <h1>{{ learningMinutes }}</h1>
        {{ $t('panels.user_stats.learning.minutes') }}
      </v-col>
      <v-col>
        <h1>{{ journals }}</h1>
        {{ $t('panels.user_stats.learning.journals') }}
      </v-col>
      <v-col>
        <h1>{{ formattedNetworkingScore }}</h1>
        {{ $t('panels.user_stats.learning.networking') }}
      </v-col>
    </v-row>
  </m-panel>
</template>

<script lang="ts">
import Vue from 'vue';
import MPanel from '@/components/MPanel';
import { LoadUserStats } from '@/store/users';

export default Vue.extend({
  name: 'm-user-stats-panel',
  components: { MPanel },
  props: {
    draggable: { type: Boolean, default: false },
  },
  data: () => ({
    matches: 0,
    contacts: 0,
    networkingScore: 0,
    journals: 0,
    appointments: 0,
    learningMinutes: 0,
    loading: false,
  }),
  computed: {
    formattedNetworkingScore() {
      return `${Math.floor(this.networkingScore * 100)}%`;
    },
  },
  watch: {
    loading(value: boolean) {
      if (!value) {
        this.$emit('onReady');
      }
    },
  },
  async mounted() {
    try {
      this.loading = true;
      const stats = await this.$store.dispatch(new LoadUserStats());
      this.matches = stats?.matches ?? 0;
      this.contacts = stats?.contacts ?? 0;
      this.networkingScore = stats?.networkingScore ?? 0;
      this.journals = stats?.journals ?? 0;
      this.appointments = stats?.appointments ?? 0;
      this.learningMinutes = stats?.learningMinutes ?? 0;
    } finally {
      this.loading = false;
    }
  },
});
</script>
