<template>
  <m-panel :title="$t('panels.sessions.title')" draggable="draggable">
    <div v-if="upcomingAppointments.length > 0" class="mt-9 gap-4 d-flex flex-column">
      <m-appointment-card
        v-for="appointment in upcomingAppointments"
        :key="appointment.id"
        :appointment="appointment"
        :user="getMatchUser(appointment)"
        @remove-match="removeMatch"
      />
      <v-btn :to="appointmentsRoute" class="outlined-light mt-2" color="button" depressed outlined>
        {{ $t('appointments.title') }}
      </v-btn>
    </div>
    <m-empty-state
      v-else
      :cta="$t('appointments.title')"
      :description="$t('appointments.empty.upcoming.title')"
      :to="appointmentsRoute"
    />
  </m-panel>
</template>

<script lang="ts">
import Vue from 'vue';
import MPanel from '@/components/MPanel';
import { mapState } from 'vuex';
import { ClearBookedSession, GetBookedSessions, MatchingState } from '@/store/matching';
import { AppointmentStatus, IAppointment, IUser } from '@mentessa/types';
import { compareAsc, isAfter, parseISO } from 'date-fns';
import MAppointmentCard from '@/components/MAppointmentCard';
import { UsersState } from '@/store/users';
import MEmptyState from '@/components/MEmptyState';
import { RouteName } from '@/router/types';

export default Vue.extend({
  name: 'm-user-sessions-panel',
  components: { MEmptyState, MAppointmentCard, MPanel },
  props: {
    draggable: { type: Boolean, default: false },
  },
  data: () => ({
    loaging: false,
    appointmentsRoute: { name: RouteName.Appointments },
  }),
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState): IUser => state.me,
    }),
    ...mapState<MatchingState>('matching', {
      appointments: (state: MatchingState): IAppointment[] => state.appointments.items ?? [],
    }),
    upcomingAppointments() {
      const result = this.appointments.filter((appointment: IAppointment) => {
        const appointmentDate: string = appointment.date?.toString() ?? appointment.slot?.date.toString();
        return appointment.status !== AppointmentStatus.Canceled && isAfter(parseISO(appointmentDate), new Date());
      });

      return result
        .sort((left, right) => {
          const lDate: string = left.date?.toString() ?? left.slot?.date.toString();
          const rDate: string = right.date?.toString() ?? right.slot?.date.toString();
          if (!lDate) {
            return -1;
          }
          if (!rDate) {
            return 1;
          }
          return compareAsc(parseISO(left.date), parseISO(right.date));
        })
        .slice(0, 3);
    },
  },
  watch: {
    loaging(value: boolean) {
      if (!value) {
        this.$emit('onReady');
      }
    },
  },
  async mounted() {
    try {
      this.loaging = true;
      await this.$store.dispatch(new GetBookedSessions());
    } finally {
      this.loaging = false;
    }
  },
  methods: {
    getMatchUser(appointment: IAppointment) {
      if (appointment.organizerId === this.me.id) {
        return appointment.target;
      }
      return appointment.organizer;
    },
    async removeMatch(appointment: IAppointment) {
      await this.$store.dispatch(new ClearBookedSession(this.me, appointment));
    },
  },
});
</script>
