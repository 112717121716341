<template>
  <m-ais-wrapper class="m-user-stream-wrapper" index="users" sentry-tag="user-stream" sync-store>
    <ais-configure :hits-per-page.camel="12" />
    <div :class="contentClasses">
      <m-ais-stream-container :grid="isGridView">
        <template v-slot:prepend>
          <m-profile-completion-banner class="mb-8" extended />
          <m-headline :description="$t('community.description')" :title="$t('community.title')" />
          <div class="m-top-section pt-4">
            <div class="m-top-section__left-container">
              <m-ais-search-box />
            </div>
            <div class="m-top-section__right-container">
              <ais-clear-refinements class="filter-toggle" @click.native="toggleFilters">
                <template v-slot="{ canRefine }">
                  <div>
                    <v-icon color="primary">{{ canRefine ? 'mdi-filter' : 'mdi-filter-outline' }}</v-icon>
                    <span>{{ $t('filters.showFilters') }}</span>
                  </div>
                </template>
              </ais-clear-refinements>
              <div class="ml-auto d-flex flex-row">
                <v-btn
                  v-if="showMatchButton"
                  class="m-top-section__match-button mx-2"
                  color="button"
                  depressed
                  sentry-tag="user-stream.button.match"
                  x-large
                  @click="newMatchesDialog = true"
                >
                  {{ $t('dashboard.matchBtn') }}
                </v-btn>
                <div class="view-toggle">
                  <a sentry-tag="user-stream.button.enableGridView" @click.prevent="enableGridView(true)">
                    <svg
                      :fill="isGridView ? getSelectedIconColor : getUnSelectedIconColor"
                      height="24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <path
                          d="M10.5 0H.5a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V.5a.5.5 0 0 0-.5-.5ZM23.5 0h-10a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V.5a.5.5 0 0 0-.5-.5ZM10.5 13H.5a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5v-10a.5.5 0 0 0-.5-.5Z"
                        />
                        <path
                          d="M10.5 0H.5a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V.5a.5.5 0 0 0-.5-.5ZM23.5 13h-10a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5v-10a.5.5 0 0 0-.5-.5Z"
                        />
                      </g>
                    </svg>
                  </a>

                  <a sentry-tag="user-stream.button.disableGridView" @click.prevent="enableGridView(false)">
                    <svg fill="none" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                      <g :fill="!isGridView ? getSelectedIconColor : getUnSelectedIconColor" clip-path="url(#a)">
                        <path
                          d="M23.5 9H.5a.5.5 0 0 0-.5.5v5a.5.5 0 0 0 .5.5h23a.5.5 0 0 0 .5-.5v-5a.5.5 0 0 0-.5-.5ZM23.5 1H.5a.5.5 0 0 0-.5.5v5a.5.5 0 0 0 .5.5h23a.5.5 0 0 0 .5-.5v-5a.5.5 0 0 0-.5-.5ZM23.5 17H.5a.5.5 0 0 0-.5.5v5a.5.5 0 0 0 .5.5h23a.5.5 0 0 0 .5-.5v-5a.5.5 0 0 0-.5-.5Z"
                        />
                      </g>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <m-ais-quick-filters v-if="isQuickFiltersAvailable" @onAllFiltersClicked="toggleFilters" />
        </template>
        <template v-if="isInvitesAvailable && isCommunityEmpty" v-slot:append-hit>
          <m-invite-user-card />
        </template>
        <template v-slot:item="{ item }">
          <m-user-card :row="!isGridView" :user="makeUserEntity(item)" @onUserDetails="openUserDetails(item)" />
        </template>
      </m-ais-stream-container>
      <m-loader v-if="false" :isLoading="isLoading" />
    </div>
    <m-stream-filters v-model="isFiltersVisible" />
    <router-view />
    <m-new-match-dialog v-model="newMatchesDialog" />
    <m-nps-dialog v-model="showNpsDialog" :nps="nps" />
  </m-ais-wrapper>
</template>

<script lang="ts">
import Vue from 'vue';
import { IUserIdentity, UserEntity } from '@mentessa/types';
import { UsersState } from '@/store/users';
import { mapGetters, mapState } from 'vuex';
import { MAisQuickFilters, MAisSearchBox, MAisStreamContainer, MAisWrapper } from '@/components/MAIS';
import MStreamFilters from '@/components/MStreamFilters';
import { MInviteUserCard, MUserCard } from '@/components/Cards/MUserCard';
import MLoader from '@/components/MLoader.vue';
import { RouteName } from '@/router/types';
import { MProfileCompletionBanner } from '@/components/MBanner';
import { MNewMatchDialog, MNpsDialog } from '@/components/Dialogs';
import { NpsState } from '@/store/nps';
import MHeadline from '@/components/MHeadline';
import { TenantState } from '@/store/tenant';

export default Vue.extend({
  name: 'm-user-stream',
  components: {
    MAisQuickFilters,
    MHeadline,
    MNpsDialog,
    MNewMatchDialog,
    MProfileCompletionBanner,
    MLoader,
    MUserCard,
    MInviteUserCard,
    MAisStreamContainer,
    MStreamFilters,
    MAisWrapper,
    MAisSearchBox,
  },
  props: {
    matchingMode: { type: Boolean, default: false },
  },
  data: () => ({
    view: 'grid',
    isFiltersVisible: false,
    isGridView: true,
    isLoading: false,
    newMatchesDialog: false,
  }),
  computed: {
    ...mapGetters('tenant', {
      isMatchingAvailable: 'isMatchingAvailable',
      isInvitesAvailable: 'isInvitesAvailable',
      isCommunityEmpty: 'isCommunityEmpty',
      featureConfig: 'featureConfig',
      isQuickFiltersAvailable: 'isQuickFiltersAvailable',
    }),
    ...mapState<TenantState>('tenant', {
      customFields: (state: TenantState) => state.tenant.attributes?.customFields?.map((field) => field.name) ?? [],
    }),
    ...mapState<UsersState>('users', {
      detailedUser: (state: UsersState) => state.detailedUser,
      me: (state: UsersState) => state.me,
    }),
    ...mapState<NpsState>('nps', {
      nps: (state: NpsState) => state.nps,
    }),
    showNpsDialog() {
      return this.nps != null && !this.newMatchesDialog;
    },
    getSelectedIconColor() {
      return this.$vuetify.theme.themes.light.primary;
    },
    getUnSelectedIconColor() {
      return this.$vuetify.theme.themes.light.lightgray;
    },
    showMatchButton() {
      return this.isMatchingAvailable && this.isInvitesAvailable;
    },
    contentClasses() {
      return {
        'm-main-container': true,
        'm-main-container--with-filter': this.isFiltersVisible && this.$vuetify.breakpoint.lgAndUp,
      };
    },
  },
  methods: {
    async openUserDetails(hit) {
      if (this.$route.name !== RouteName.CommunityUserDetails) {
        await this.$router.push({ name: RouteName.CommunityUserDetails, params: { userId: hit.userId } });
      }
    },

    enableGridView(enabled: boolean) {
      this.isGridView = enabled;
    },

    toggleFilters() {
      this.isFiltersVisible = !this.isFiltersVisible;
    },

    makeUserEntity(searchItem): UserEntity & { highlights: Array<Record<string, unknown>> } {
      const result = {
        id: searchItem.userId,
        tenant: {
          id: searchItem.tenantId,
        },
        attributes: {
          jobTitle: searchItem.jobTitle,
          location: searchItem.location,
          mentoring: {
            available: searchItem.isAvailable ?? false,
          },
          roles: searchItem.roles,
          company: searchItem.company,
        },
        identity: {
          attributes: {
            firstName: searchItem.firstName,
            lastName: searchItem.lastName,
            email: searchItem.email,
            image: searchItem.image,
          },
        } as IUserIdentity,
        expertiseTags: searchItem.eTags.map((tag) => ({ name: tag })),
        interestTags: searchItem.iTags.map((tag) => ({ name: tag })),
        isFavorite: searchItem.isFavorite ?? false,
        highlights: searchItem.highlights,
      };

      if (this.customFields?.length > 0) {
        const custom = {};
        this.customFields.forEach((name: string) => {
          if (name && searchItem[name]) {
            custom[name] = this.$t(`profile.custom.${name}.items.${searchItem[name]}`);
          }
        });
        result.attributes['custom'] = custom;
      }

      return result;
    },
  },
});
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';

.m-top-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 10px;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    flex-direction: column;
  }

  .m-top-section__right-container {
    flex: 1;
    display: flex;
  }

  .m-top-section__left-container {
    flex: 1;
  }

  &__match-button {
    max-height: 51px;
  }
}

.filter-toggle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 51px;
  max-width: 94px;
  border-radius: 6px;
  border: 1px solid var(--v-beige-base);
  box-shadow: 0 0 1px var(--v-beige-base);
  padding: 0 16px;
  cursor: pointer;
  width: 100%;
  background-color: var(--v-white-base);

  span {
    font-size: 14px;
    font-weight: 500;
  }
}

.view-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 51px;
  max-width: 90px;
  border-radius: 6px;
  border: 1px solid var(--v-beige-base);
  padding: 0 16px;
  width: 100%;
  background-color: var(--v-white-base);

  a {
    display: block;
    font-size: 0;
  }

  a + a {
    margin-left: 16px;
  }
}

.m-main-container {
  max-width: 1126px;
  width: 100%;

  &--with-filter {
    margin-right: 320px;
  }
}

.m-user-stream-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}
</style>
