<template>
  <validation-observer ref="validation" v-slot="{ invalid }">
    <m-save-profile-button :disabled="invalid || saveButtonDisabled" @onButtonClick="save" />
    <m-settings-panel :description="$t('profile.tags.description')" :title="$t('profile.tags.title')">
      <m-combobox-tags
        v-model="tags.expertise"
        :label="$t('profile.tags.expertise')"
        sentry-tag="profile.combobox.tags.expertise"
      />
      <m-combobox-tags
        v-model="tags.interests"
        :label="$t('profile.tags.interests')"
        sentry-tag="profile.combobox.tags.interests"
      />
    </m-settings-panel>
    <m-settings-panel
      v-if="hasCustomFields"
      :description="$t('profile.custom.description')"
      :title="$t('profile.custom.title')"
    >
      <component
        :is="field.component"
        v-for="(field, index) in customFields"
        :key="`custom-${index}`"
        v-model="custom[field.name]"
        :i18n-path="field.i18nPath"
        :items="field.items"
        :label="$t(`profile.custom.${field.name}.title`)"
        :validation-rules="field.validation"
        v-bind="field.props"
      />
    </m-settings-panel>
    <m-leave-dialog :value="showDialog" @onCancel="closeDialog" @onDiscard="leavePage" @onSave="saveAndLeave" />
  </validation-observer>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { SaveUserProfile, UsersState } from '@/store/users';
import { TenantState } from '@/store/tenant';
import { MComboboxTags, MLocation, MSelect, MSelectMultiple, MTextArea, MTextField } from '@/components/Inputs';
import { MSettingsPanel } from '@/components/Settings/MSettingsPanel';
import MSaveProfileButton from '../MSaveProfileButton';
import { ValidationObserver } from 'vee-validate';
import { CustomField, FieldType, ITag, ITenant } from '@mentessa/types';
import { MLeaveDialog } from '@/components/Dialogs';
import { haveDifferentElements } from '@/utils/array';

function getFieldComponent(field: CustomField) {
  switch (field.type) {
    case FieldType.SelectOne:
      return MSelect;
    case FieldType.SelectMultiple:
      return MSelectMultiple;
    case FieldType.ShortText:
      return MTextField;
    case FieldType.LongText:
      return MTextArea;
    case FieldType.URL:
      return MTextField;
    case FieldType.Location:
      return MLocation;
    default:
      console.log(`Unknown field type: ${field.type}`, field);
      return undefined;
  }
}

export default Vue.extend({
  name: 'm-profile-skills',
  components: {
    MLeaveDialog,
    MSaveProfileButton,
    ValidationObserver,
    MSettingsPanel,
    MComboboxTags,
    MSelect,
  },
  data: () => ({
    tags: {
      expertise: [],
      interests: [],
    },
    matching: {
      auto: false,
      frequency: '',
    },
    custom: {},
    loading: false,
    showDialog: false,
    to: null,
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState): ITenant => state.tenant,
    }),
    ...mapGetters('tenant', {
      isMatchingAvailable: 'isMatchingAvailable',
    }),
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    customFields() {
      const fields = [];
      this.tenant?.attributes.customFields?.forEach((field: CustomField) => {
        if (field.hide !== true) {
          const fieldComponent = {
            name: field.name,
            attribute: field.name,
            component: getFieldComponent(field),
            type: field.type,
            items: field.items,
            label: field.label,
            i18nPath: `profile.custom.${field.name}.items`,
            validation: field.validation,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            props: {} as Record<string, any>,
          };

          if (field.multiple) {
            fieldComponent.props.multiple = true;
          }

          fields.push(fieldComponent);
        }
      });
      return fields;
    },
    hasCustomFields() {
      return this.customFields.length > 0;
    },
    isChanged() {
      const skillsChanged = (tagsA: ITag[], tagsB: ITag[]): boolean => {
        return tagsA.length !== tagsB.length || tagsA.some(({ id }) => tagsB.findIndex((skill) => skill.id === id) < 0);
      };
      const customFieldChanged = (field): boolean => {
        if (field.type === FieldType.SelectOne) {
          return this.custom[field.name] !== this.me.attributes.custom?.[field.name];
        } else if (field.type === FieldType.SelectMultiple || field.type === FieldType.Location) {
          return haveDifferentElements(this.custom[field.name] ?? [], this.me.attributes.custom?.[field.name] ?? []);
        } else if ([FieldType.ShortText, FieldType.LongText, FieldType.URL].includes(field.type)) {
          return (this.custom[field.name] ?? '') !== (this.me.attributes.custom?.[field.name] ?? '');
        } else {
          console.log(`Unknown field type: ${field.type}`);
          return false;
        }
      };

      return (
        skillsChanged(this.tags.expertise, this.me.expertiseTags ?? []) ||
        skillsChanged(this.tags.interests, this.me.interestTags ?? []) ||
        this.matching.auto !== (this.me.attributes.matching?.auto ?? false) ||
        this.matching.frequency !== (this.me.attributes.matching?.frequency ?? '') ||
        Object.keys(this.customFields).some((field) => customFieldChanged(this.customFields[field]))
      );
    },
    saveButtonDisabled() {
      return this.loading || (!this.isChanged && this.me.complete);
    },
  },
  watch: {
    me() {
      this.updateSkillsData();
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isChanged || this.to) {
      next();
    } else {
      this.to = to;
      this.showDialog = true;
    }
  },
  created() {
    this.updateSkillsData();
  },
  methods: {
    async save() {
      const dto = {
        attributes: {
          custom: this.custom,
        },
        tags: this.tags,
      };
      try {
        this.loading = true;
        await this.$store.dispatch(new SaveUserProfile(dto));
      } finally {
        this.loading = false;
      }
    },
    async leavePage() {
      this.showDialog = false;
      await this.$router.push(this.to);
    },
    closeDialog() {
      this.showDialog = false;
      this.to = null;
    },
    async saveAndLeave() {
      await this.save();
      await this.leavePage();
    },
    updateSkillsData() {
      this.matching = {
        auto: this.me.attributes.matching?.auto ?? false,
        frequency: this.me.attributes.matching?.frequency ?? '',
      };
      this.tags = {
        expertise: this.me.expertiseTags,
        interests: this.me.interestTags,
      };
      const fields = {};
      this.tenant?.attributes.customFields?.forEach((field) => {
        fields[field.name] = this.me.attributes.custom?.[field.name];
      });
      this.custom = fields;
    },
  },
});
</script>
