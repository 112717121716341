import { MentoringMutations, MentoringState } from './types';
import { MutationTree } from 'vuex';
import {
  GoalTaskReference,
  IAppointment,
  IGoalTask,
  IMentoringSession,
  IMentoringSessionGoal,
  IMentoringSessionMessage,
  ITask,
  MentoringSessionGoalReference,
  MentoringSessionReference,
} from '@mentessa/types';
import { StoreData, StoreDataState } from '@/store/types';
import deepmerge from 'deepmerge';

export interface MentoringSessionItem<T> {
  sessionRef: MentoringSessionReference;
  data: T;
}

export interface MentoringSessionGoalItem<T> extends MentoringSessionItem<T> {
  sessionGoalRef: MentoringSessionGoalReference;
}

export interface MentoringSessionGoalTaskItem<T> extends MentoringSessionGoalItem<T> {
  goalTaskRef: GoalTaskReference;
}

export interface MentoringSessionAppointmentItem<T> extends MentoringSessionItem<T> {
  appointmentRef: MentoringSessionGoalReference;
}

function findSessionIndexById(state: MentoringState, id: number) {
  return state.sessions.data.findIndex((session) => session.data.id === id) ?? -1;
}

function findSessionById(state: MentoringState, id: number) {
  return state.sessions.data.find((session) => session.data.id === id);
}

// eslint-disable-next-line
function findSessionGoalIndexById(session: StoreData<IMentoringSession>, id: number) {
  return session?.data.goals?.findIndex((goal) => goal.id === id) ?? -1;
}

function findSessionGoalById(session: StoreData<IMentoringSession>, id: number) {
  return session?.data.goals?.find((goal) => goal.id === id);
}

function findSessionGoalTaskIndexById(goal: IMentoringSessionGoal, id: number) {
  return goal?.goal?.goalTasks?.findIndex((task) => task.id === id) ?? -1;
}

function findSessionAppointmentById(session: StoreData<IMentoringSession>, id: number) {
  return session?.data.appointments?.find((appointment) => appointment.id === id);
}

// eslint-disable-next-line
function findSessionGoalTaskById(goal: IMentoringSessionGoal, id: number) {
  return goal?.goal?.goalTasks?.find((task) => task.id === id);
}

export const mutations: MutationTree<MentoringState> = {
  [MentoringMutations.SetMentoringSessionsList](state: MentoringState, sessions: Array<IMentoringSession>) {
    if (state.sessions.state === StoreDataState.Loaded) {
      state.sessions.data = sessions.map((session) => ({ data: session, state: StoreDataState.Unknown }));
    }
  },
  [MentoringMutations.SetMentoringSession](state: MentoringState, sessionData: IMentoringSession) {
    const sessionIndex = findSessionIndexById(state, sessionData.id);
    if (sessionIndex !== -1) {
      state.sessions.data[sessionIndex].data = sessionData;
    }
  },
  [MentoringMutations.UpdateMentoringSession](state: MentoringState, sessionData: IMentoringSession) {
    const sessionIndex = findSessionIndexById(state, sessionData.id);
    if (sessionIndex !== -1) {
      state.sessions.data[sessionIndex].data = { ...state.sessions.data[sessionIndex].data, ...sessionData };
    }
  },
  [MentoringMutations.SetMentoringSessionGoals](
    state: MentoringState,
    { sessionRef, data }: MentoringSessionItem<Array<IMentoringSessionGoal>>,
  ) {
    const session = findSessionById(state, sessionRef.id);
    if (session) {
      session.data.goals = data ?? [];
    }
  },
  [MentoringMutations.SetMentoringSessionMessages](
    state: MentoringState,
    { sessionRef, data }: MentoringSessionItem<Array<IMentoringSessionMessage>>,
  ) {
    const session = findSessionById(state, sessionRef.id);
    if (session) {
      session.data.messages = data ?? [];
    }
  },
  [MentoringMutations.SetMentoringSessionAppointments](
    state: MentoringState,
    { sessionRef, data }: MentoringSessionItem<Array<IAppointment>>,
  ) {
    const session = findSessionById(state, sessionRef.id);
    if (session) {
      session.data.appointments = data ?? [];
    }
  },
  [MentoringMutations.SetSessionsState](state: MentoringState, sessionsState: StoreDataState) {
    state.sessions.state = sessionsState;
  },
  [MentoringMutations.SetActiveMentoringSessionId](state: MentoringState, id: number) {
    state.currentSessionId = id;
  },
  [MentoringMutations.SetSessionState](
    state: MentoringState,
    { sessionRef, data }: MentoringSessionItem<StoreDataState>,
  ) {
    const session = findSessionById(state, sessionRef.id);
    session.state = data;
  },
  [MentoringMutations.AppendSessionMessage](
    state: MentoringState,
    { sessionRef, data }: MentoringSessionItem<IMentoringSessionMessage>,
  ) {
    const session = findSessionById(state, sessionRef.id);
    if (session) {
      if (session.data.messages == null) {
        session.data.messages = [];
      }
      session.data.messages.push(data);
    }
  },
  [MentoringMutations.ChangeSessionMessage](
    state: MentoringState,
    { sessionRef, data }: MentoringSessionItem<IMentoringSessionMessage>,
  ) {
    const session = findSessionById(state, sessionRef.id);
    if (session) {
      const messageIndex = session.data.messages.findIndex((message) => message.id === data.id);
      if (messageIndex !== -1) {
        session.data.messages[messageIndex] = data;
      }
    }
  },
  [MentoringMutations.RemoveSessionMessage](
    state: MentoringState,
    { sessionRef, data }: MentoringSessionItem<IMentoringSessionMessage>,
  ) {
    const session = findSessionById(state, sessionRef.id);
    if (session) {
      const messageIndex = session.data.messages.findIndex((message) => message.id === data.id);
      if (messageIndex !== -1) {
        session.data.messages.splice(messageIndex, 1);
      }
    }
  },
  [MentoringMutations.AppendSessionAppointment](
    state: MentoringState,
    { sessionRef, data }: MentoringSessionItem<IAppointment>,
  ) {
    const session = findSessionById(state, sessionRef.id);
    if (session) {
      if (session.data.appointments == null) {
        session.data.appointments = [];
      }
      session.data.appointments.push(data);
    }
  },
  [MentoringMutations.ChangeSessionAppointment](
    state: MentoringState,
    { sessionRef, appointmentRef, data }: MentoringSessionAppointmentItem<IAppointment>,
  ) {
    const session = findSessionById(state, sessionRef.id);
    const appointment = findSessionAppointmentById(session, appointmentRef.id);
    if (appointment) {
      if (data.status != null) appointment.status = data.status;
      if (data.date != null) appointment.date = data.date;
      if (data.attributes != null) appointment.attributes = deepmerge(appointment.attributes, data.attributes);
    }
  },
  [MentoringMutations.RemoveSessionAppointment](
    state: MentoringState,
    { sessionRef, data }: MentoringSessionItem<IAppointment>,
  ) {
    const session = findSessionById(state, sessionRef.id);
    if (session) {
      const appointmentIndex = session.data.appointments.findIndex((appointment) => appointment.id === data.id);
      if (appointmentIndex !== -1) {
        session.data.appointments.splice(appointmentIndex, 1);
      }
    }
  },
  [MentoringMutations.AppendSessionGoal](
    state: MentoringState,
    { sessionRef, data }: MentoringSessionItem<IMentoringSessionGoal>,
  ) {
    const session = findSessionById(state, sessionRef.id);
    if (session) {
      if (session.data.goals == null) {
        session.data.goals = [];
      }
      const goal = { ...data };
      if (goal.goal.goalTasks == null) {
        goal.goal.goalTasks = [];
      }
      session.data.goals.push(data);
    }
  },
  [MentoringMutations.ChangeSessionGoal](
    state: MentoringState,
    { sessionRef, sessionGoalRef, data }: MentoringSessionGoalItem<IMentoringSessionGoal>,
  ) {
    const session = findSessionById(state, sessionRef.id);
    const sessionGoalIndex = findSessionGoalIndexById(session, sessionGoalRef.id);
    if (sessionGoalIndex !== -1) {
      session.data.goals[sessionGoalIndex].goal = deepmerge(session.data.goals[sessionGoalIndex].goal, data.goal);
    }
  },
  [MentoringMutations.RemoveSessionGoal](
    state: MentoringState,
    { sessionRef, data }: MentoringSessionItem<IMentoringSessionGoal>,
  ) {
    const session = findSessionById(state, sessionRef.id);
    if (session) {
      const goalIndex = session.data.goals.findIndex((goal) => goal.id === data.id);
      if (goalIndex !== -1) {
        session.data.goals.splice(goalIndex, 1);
      }
    }
  },
  [MentoringMutations.AppendSessionGoalTask](
    state: MentoringState,
    { sessionRef, sessionGoalRef, data }: MentoringSessionGoalItem<IGoalTask>,
  ) {
    const session = findSessionById(state, sessionRef.id);
    const goal = findSessionGoalById(session, sessionGoalRef.id);
    if (goal) {
      if (goal.goal.goalTasks == null) {
        goal.goal.goalTasks = [];
      }
      goal.goal.goalTasks.push(data);
    }
  },
  [MentoringMutations.ChangeSessionGoalTask](
    state: MentoringState,
    { sessionRef, sessionGoalRef, goalTaskRef, data }: MentoringSessionGoalTaskItem<ITask>,
  ) {
    const session = findSessionById(state, sessionRef.id);
    const goal = findSessionGoalById(session, sessionGoalRef.id);
    if (goal) {
      const goalTaskIndex = findSessionGoalTaskIndexById(goal, goalTaskRef.id);
      if (goalTaskIndex !== -1) {
        goal.goal.goalTasks[goalTaskIndex].task = deepmerge(goal.goal.goalTasks[goalTaskIndex].task, data);
      }
    }
  },
  [MentoringMutations.RemoveSessionGoalTask](
    state: MentoringState,
    { sessionRef, sessionGoalRef, data }: MentoringSessionGoalItem<ITask>,
  ) {
    const session = findSessionById(state, sessionRef.id);
    const goal = findSessionGoalById(session, sessionGoalRef.id);
    if (session) {
      const taskIndex = findSessionGoalTaskIndexById(goal, data.id);
      if (taskIndex !== -1) {
        goal.goal.goalTasks.splice(taskIndex, 1);
      }
    }
  },
};
