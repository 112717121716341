<template>
  <m-panel>
    <v-row class="justify-center flex-column flex-sm-row">
      <v-col>
        <v-chip color="white">
          <m-ritual-preview :ritual="value" max-width="240px" />
        </v-chip>
      </v-col>

      <v-col class="flex-grow-0 gap-2 d-flex flex-sm-column justify-center">
        <v-btn :to="enrollRoute" color="button" width="120">{{ $t('ritualShop.enroll') }}</v-btn>
      </v-col>
    </v-row>
  </m-panel>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IRitual } from '@mentessa/types';
import MPanel from '@/components/MPanel';
import { mapState } from 'vuex';
import { UsersState } from '@/store/users';
import MRitualPreview from '@/components/MRitualPreview';
import { RouteName } from '@/router/types';

export default Vue.extend({
  name: 'm-pending-ritual-card',
  components: {
    MRitualPreview,
    MPanel,
  },
  props: {
    value: Object as PropType<IRitual>,
  },
  data: () => ({
    //
  }),
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    enrollRoute() {
      return {
        name: RouteName.RitualEnroll,
        params: this.value ? { ritualId: this.value.id } : undefined,
      };
    },
  },
});
</script>

<style lang="scss" scoped></style>
