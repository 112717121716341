<template>
  <v-app sentry-tag="teams-app">
    <div class="fill-height">
      <v-main class="fill-height">
        <div class="fill-height d-flex justify-center align-center">
          <v-btn @click="handler" color="button" depressed x-large width="200">{{ $t('teamsApp.button') }}</v-btn>
        </div>
      </v-main>
    </div>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'App',
  methods: {
    handler() {
      // ToDo: Implement logic here
    },
  },
});
</script>

<style lang="scss">
.v-btn {
  font-size: 14px;
  text-transform: none;
  letter-spacing: unset;
}

.m-footer {
  color: #475568;
}

.m-footer a {
  color: #475568 !important;
  text-decoration: none;
}
</style>
