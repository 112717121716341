<template>
  <div class="m-settings-ritual-card">
    <div :style="style" class="m-settings-ritual-info gap-2" sentry-tag="ritual">
      <div class="d-flex flex-column flex-grow-1">
        <p class="font-weight-bold text-h6 mb-2">
          {{ title }}
          <v-chip v-if="enabled" class="mx-2" color="primary" label small>
            {{ $t('settings.rituals.active') }}
          </v-chip>
        </p>
        <p class="text-h7 text--text text--lighten-4 mb-2">{{ description }}</p>
      </div>
      <div class="d-flex align-center text--text text--lighten-4">
        <v-img class="mr-2" contain height="24" src="@/assets/calendar.svg" width="24" />
        <div class="text-h7 mr-8">{{ formattedScheduleLabel }}</div>
      </div>
      <div class="d-flex dlex-row align-center">
        <v-btn :outlined="enabled" color="button" depressed large @click="editRitual">
          {{ $t(`settings.rituals.${enabled ? 'manage' : 'start'}`) }}
        </v-btn>
        <v-menu content-class="align-center" offset-y sentry-tag="ritual.menu">
          <template v-slot:activator="{ on, attrs }">
            <a class="pl-4" sentry-tag="ritual.menu.activator" v-bind="attrs" v-on="on">
              <v-img contain height="24" src="@/assets/more_vertical.svg" width="24" />
            </a>
          </template>
          <v-list>
            <v-list-item v-if="invitationAvailable" link @click="showInvitation">
              <v-list-item-title sentry-tag="ritual.menu.invitation">
                {{ $t('settings.rituals.invitation') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item class="ml-auto" link @click="showResults">
              <v-list-item-title sentry-tag="ritual.menu.members">
                {{ $t('settings.rituals.results') }}
              </v-list-item-title>
            </v-list-item>
            <template v-if="isRitualExecuteAvailable && executeAllowed">
              <v-divider />
              <v-list-item class="error--text" link @click="forceExecute">
                <v-list-item-title sentry-tag="ritual.menu.execute">
                  {{ $t('settings.rituals.execute') }}
                </v-list-item-title>
              </v-list-item>
            </template>

            <template v-if="isDebugAvailable">
              <v-divider />
              <v-list-item class="error--text" link @click="deleteRitual">Delete Ritual</v-list-item>
              <v-divider />
              <v-list-item class="warning--text text--darken-3" link @click="bindQuestionnaireDialogOpened = true">
                Bind Questionnaire
              </v-list-item>
              <v-list-item class="warning--text text--darken-3" link @click="prepareData">Prepare data</v-list-item>
              <v-divider />
              <v-list-item class="error--text" link @click="resendMatches">Resend matches</v-list-item>
              <v-list-item class="error--text" link @click="closePendingMatches">Close pending matches</v-list-item>
              <v-list-item class="error--text" link @click="forceInvite(false)">Invite not joined users</v-list-item>
              <v-list-item class="error--text" link @click="forceInvite(true)">Remind not joined users</v-list-item>
            </template>
          </v-list>
        </v-menu>
      </div>
    </div>
    <v-row class="ma-0 justify-end">
      <a class="text--text text--lighten-4" @click="toggleEnrollList">
        {{ $t('settings.rituals.members') }}
        <v-icon>{{ showUsersList ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</v-icon>
      </a>
    </v-row>
    <m-ritual-enroll-list v-if="showUsersList" v-model="showUsersList" :ritual="value" class="ma-4" />
    <m-bind-ritual-questionnaire-dialog v-model="bindQuestionnaireDialogOpened" :ritual="value" />
  </div>
</template>

<script lang="ts">
import { IRitual, RitualScheduleOptions, RitualScheduleType, RitualType } from '@mentessa/types';

import Vue, { PropType } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { TenantState } from '@/store/tenant';
import MRitualEnrollList from '@/components/Settings/MSettingsRituals/MRitualEnrollList';
import { getSlotNextDate } from '@/utils/weekdays';
import {
  ClosePendingMatches,
  DeleteRitual,
  ExecuteRitual,
  InviteAllToARitual,
  ResendMatches,
  UpdateRitual,
} from '@/store/rituals';
import { RouteName } from '@/router/types';
import { MBindRitualQuestionnaireDialog } from '@/components/Dialogs';

export default Vue.extend({
  name: 'm-ritual',
  components: {
    MRitualEnrollList,
    MBindRitualQuestionnaireDialog,
  },
  props: {
    value: { type: Object as PropType<IRitual> },
  },
  data: () => ({
    showUsersList: false,
    bindQuestionnaireDialogOpened: false,
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    ...mapGetters('tenant', {
      isRitualExecuteAvailable: 'isRitualExecuteAvailable',
      isDebugAvailable: 'isDebugAvailable',
    }),
    ...mapGetters('ui', {
      formatInUserTimeZone: 'formatInUserTimeZone',
    }),
    executeAllowed() {
      // ToDo: Delete it
      // return [8, 49, 29].includes(this.tenant?.id);
      return true;
    },
    style() {
      return {
        '--flex-direction': this.$vuetify.breakpoint.xsOnly ? 'column' : 'row',
      };
    },
    formattedScheduleLabel() {
      const schedule: RitualScheduleOptions = this.value.attributes?.schedule;
      switch (schedule?.type) {
        case RitualScheduleType.Once: {
          if (schedule.options.once?.date) {
            const date = parseISO(schedule.options.once.date.toString());
            return `${this.formatInUserTimeZone(date, 'EEEE, d.M.yyyy H:mm')}`;
          } else {
            return '-';
          }
        }
        case RitualScheduleType.Periodic: {
          const weekday = schedule.options?.periodic.weekday;
          const hour = schedule.options?.periodic.hour;
          const date = getSlotNextDate(weekday, `${hour}:00`, this.value?.type == RitualType.HappyHour ? 1 : 0);
          const dateParts = formatInTimeZone(date, 'UTC', 'EEEE H:mm').split(' ');
          return this.$t('settings.rituals.label', {
            weekday: dateParts[0],
            hour: dateParts[1],
          });
        }
        default:
          return '-';
      }
    },
    enabled: {
      get(): boolean {
        return this.value?.enabled ?? false;
      },
      async set(value: boolean) {
        await this.setEnabled(value);
        if (value) {
          await this.editRitual();
        }
      },
    },
    title() {
      return this.value.attributes.title ?? this.$t(`settings.rituals.knownNames.${this.value.type}`);
    },
    description() {
      return this.value.attributes.description ?? this.$t(`settings.rituals.knownDescriptions.${this.value.type}`);
    },
    invitationAvailable() {
      return this.value?.invites?.length > 0;
    },
  },
  methods: {
    toggleEnrollList() {
      this.showUsersList = !this.showUsersList;
    },
    async setEnabled(value: boolean) {
      const newRitual = { ...this.value, enabled: value };
      await this.$store.dispatch(new UpdateRitual(this.value, newRitual));
    },
    async forceExecute() {
      await this.$store.dispatch(new ExecuteRitual(this.value));
    },
    async editRitual() {
      await this.$router.push({
        name: RouteName.SettingsRitualsEdit,
        params: { ritualId: this.value.id.toString() },
      });
    },
    async showInvitation() {
      await this.$router.push({
        name: RouteName.SettingsRitualsInvitation,
        params: { ritualId: this.value.id.toString() },
      });
    },
    async showResults() {
      await this.$router.push({
        name: RouteName.SettingsRitualsResults,
        params: { ritualId: this.value.id.toString() },
      });
    },
    async prepareData() {
      await this.$router.push({
        name: RouteName.SettingsRitualsData,
        params: { ritualId: this.value.id.toString() },
      });
    },
    async resendMatches() {
      await this.$store.dispatch(new ResendMatches(this.value));
    },
    async closePendingMatches() {
      await this.$store.dispatch(new ClosePendingMatches(this.value));
    },
    async forceInvite(isReminder = false) {
      await this.$store.dispatch(new InviteAllToARitual(this.value, isReminder));
    },
    async deleteRitual() {
      await this.$store.dispatch(new DeleteRitual(this.value));
      this.$emit('ritual-deleted');
    },
  },
});
</script>

<style lang="scss" scoped>
.m-settings-ritual-card {
  width: 100%;
  padding: 24px;
  background-color: var(--v-white-base);
  border: 1px solid var(--v-beige-base);
  border-radius: 16px;
}

.m-settings-ritual-info {
  position: relative;
  flex-direction: var(--flex-direction);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
