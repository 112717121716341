import { Action, StoreData } from '@/store/types';
import {
  AppointmentFeedback,
  AppointmentReference,
  AppointmentSlot,
  GoalReference,
  GoalTaskReference,
  IAppointment,
  IGoal,
  IMentoringSession,
  IMentoringSessionGoal,
  ITask,
  MentoringSessionFeedbackReference,
  MentoringSessionGoalReference,
  MentoringSessionMessageReference,
  MentoringSessionReference,
  ScheduleReference,
  UserReference,
} from '@mentessa/types';

export const moduleName = 'mentoring';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MentoringState {
  sessions: StoreData<Array<StoreData<IMentoringSession>>>;
  currentSessionId: number;
}

export enum MentoringActions {
  LoadMentoringSessionList = '[Mentoring] Load Mentoring Session List',
  LoadMentoringSession = '[Mentoring] Load Mentoring Session',
  OpenMentoringSession = '[Mentoring] Open Mentoring Session',
  CloseMentoringSession = '[Mentoring] Close Mentoring Session',
  LoadMentoringSessionMessages = '[Mentoring] Load Mentoring Session Messages',
  CreateMentoringSessionMessage = '[Mentoring] Create Mentoring Session Message',
  UpdateMentoringSessionMessage = '[Mentoring] Update Mentoring Session Message',
  DeleteMentoringSessionMessage = '[Mentoring] Delete Mentoring Session Message',
  LoadMentoringSessionGoals = '[Mentoring] Load Mentoring Session Goals',
  CreateMentoringSessionGoal = '[Mentoring] Create Mentoring Session Goal',
  UpdateMentoringSessionGoal = '[Mentoring] Update Mentoring Session Goal',
  DeleteMentoringSessionGoal = '[Mentoring] Delete Mentoring Session Goal',
  CreateMentoringSessionTask = '[Mentoring] Create Mentoring Session Task',
  UpdateMentoringSessionTask = '[Mentoring] Update Mentoring Session Task',
  ChangeMentoringSessionTaskDoneStatus = '[Mentoring] Change Mentoring Session Task Done Status',
  MarkMentoringSessionTaskComplete = '[Mentoring] Mark Mentoring Session Task Complete',
  MarkMentoringSessionTaskIncomplete = '[Mentoring] Mark Mentoring Session Task Incomplete',
  DeleteMentoringSessionTask = '[Mentoring] Delete Mentoring Session Task',
  LoadMentoringSessionAppointments = '[Mentoring] Load Mentoring Session Appointments',
  CreateMentoringSessionAppointment = '[Mentoring] Create Mentoring Session Appointment',
  UpdateMentoringSessionAppointment = '[Mentoring] Update Mentoring Session Appointment',
  DeleteMentoringSessionAppointment = '[Mentoring] Delete Mentoring Session Appointment',
  SetCurrentMentoringSession = '[Mentoring] Set Current Mentoring Session',
  SendMentoringSessionAppointmentFeedback = '[Mentoring] SendMentoring Session Appointment Feedback',
  LoadMentoringSessionListDebug = '[Mentoring] Load Mentoring Session List Debug',
  CreateMentoringSessionDebug = '[Mentoring] Create Mentoring Session Debug',
  DeleteMentoringSessionDebug = '[Mentoring] Delete Mentoring Session Debug',
  SendMentoringSessionFeedbackAnswers = '[Mentoring] Send Mentoring Session Feedback Answers',
  LoadMentoringSessionFeedbackQuestionnaire = '[Mentoring] Load Mentoring Session Feedback Questionnaire',
  LoadPersonalGoals = '[Mentoring] Load Personal Goals',
  CreatePersonalGoal = '[Mentoring] Create Personal Goal',
  UpdatePersonalGoal = '[Mentoring] Update Personal Goal',
  DeletePersonalGoal = '[Mentoring] Delete Personal Goal',
}

export enum MentoringMutations {
  SetMentoringSessionsList = '[Mentoring] Set Mentoring Sessions List',
  SetMentoringSession = '[Mentoring] Set Mentoring Session',
  UpdateMentoringSession = '[Mentoring] Update Mentoring Session',
  SetMentoringSessionGoals = '[Mentoring] Set Mentoring Session Goals',
  SetMentoringSessionMessages = '[Mentoring] Set Mentoring Session Messages',
  SetMentoringSessionAppointments = '[Mentoring] Set Mentoring Session Appointments',
  SetActiveMentoringSessionId = '[Mentoring] Set Active Mentoring Session ID',
  SetSessionsState = '[Mentoring] Set Sessions State',
  SetSessionState = '[Mentoring] Set Session State',
  AppendSessionMessage = '[Mentoring] Append Session Message',
  ChangeSessionMessage = '[Mentoring] Change Session Message',
  RemoveSessionMessage = '[Mentoring] Remove Session Message',
  AppendSessionAppointment = '[Mentoring] Append Session Appointment',
  ChangeSessionAppointment = '[Mentoring] Change Session Appointment',
  RemoveSessionAppointment = '[Mentoring] Remove Session Appointment',
  AppendSessionGoal = '[Mentoring] Append Session Goal',
  ChangeSessionGoal = '[Mentoring] Change Session Goal',
  RemoveSessionGoal = '[Mentoring] Remove Session Goal',
  AppendSessionGoalTask = '[Mentoring] Append Session Goal Task',
  ChangeSessionGoalTask = '[Mentoring] Change Session Goal Task',
  RemoveSessionGoalTask = '[Mentoring] Remove Session Goal Task',
}

export class LoadMentoringSessionList implements Action {
  type = `${moduleName}/${MentoringActions.LoadMentoringSessionList}`;

  constructor(private readonly user?: UserReference) {}
}

export class LoadMentoringSession implements Action {
  type = `${moduleName}/${MentoringActions.LoadMentoringSession}`;

  constructor(private readonly sessionRef: MentoringSessionReference, private readonly user?: UserReference) {}
}

export class OpenMentoringSession implements Action {
  type = `${moduleName}/${MentoringActions.OpenMentoringSession}`;

  constructor(private readonly sessionRef: MentoringSessionReference) {}
}

export class CloseMentoringSession implements Action {
  type = `${moduleName}/${MentoringActions.CloseMentoringSession}`;

  constructor(private readonly sessionRef: MentoringSessionReference) {}
}

export class LoadMentoringSessionMessages implements Action {
  type = `${moduleName}/${MentoringActions.LoadMentoringSessionMessages}`;

  constructor(private readonly sessionRef: MentoringSessionReference, private readonly user?: UserReference) {}
}

export class LoadMentoringSessionGoals implements Action {
  type = `${moduleName}/${MentoringActions.LoadMentoringSessionGoals}`;

  constructor(private readonly sessionRef: MentoringSessionReference, private readonly user?: UserReference) {}
}

export class LoadMentoringSessionAppointments implements Action {
  type = `${moduleName}/${MentoringActions.LoadMentoringSessionAppointments}`;

  constructor(private readonly sessionRef: MentoringSessionReference, private readonly user?: UserReference) {}
}

export class SetCurrentMentoringSession implements Action {
  type = `${moduleName}/${MentoringActions.SetCurrentMentoringSession}`;

  constructor(private readonly sessionRef: MentoringSessionReference, private readonly user?: UserReference) {}
}

export class CreateMentoringSessionMessage implements Action {
  type = `${moduleName}/${MentoringActions.CreateMentoringSessionMessage}`;

  constructor(
    private readonly sessionRef: MentoringSessionReference,
    private readonly text,
    private readonly user?: UserReference,
  ) {}
}

export class UpdateMentoringSessionMessage implements Action {
  type = `${moduleName}/${MentoringActions.UpdateMentoringSessionMessage}`;

  constructor(
    private readonly sessionRef: MentoringSessionReference,
    private readonly messageRef: MentoringSessionMessageReference,
    private readonly text,
    private readonly user?: UserReference,
  ) {}
}

export class DeleteMentoringSessionMessage implements Action {
  type = `${moduleName}/${MentoringActions.DeleteMentoringSessionMessage}`;

  constructor(
    private readonly sessionRef: MentoringSessionReference,
    private readonly messageRef: MentoringSessionMessageReference,
    private readonly user?: UserReference,
  ) {}
}

export class CreateMentoringSessionAppointment implements Action {
  type = `${moduleName}/${MentoringActions.CreateMentoringSessionAppointment}`;

  constructor(
    private readonly targetUserRef: UserReference,
    private readonly sessionRef: MentoringSessionReference,
    private readonly scheduleRef: ScheduleReference,
    private readonly date: Date,
    private readonly message: string,
    private readonly slot?: AppointmentSlot,
    private readonly userRef?: UserReference,
  ) {}
}

export class UpdateMentoringSessionAppointment implements Action {
  type = `${moduleName}/${MentoringActions.UpdateMentoringSessionAppointment}`;

  constructor(
    private readonly sessionRef: MentoringSessionReference,
    private readonly appointmentRef: AppointmentReference,
    private readonly data: Partial<IAppointment>,
    private readonly user?: UserReference,
  ) {}
}

export class CreateMentoringSessionGoal implements Action {
  type = `${moduleName}/${MentoringActions.CreateMentoringSessionGoal}`;

  constructor(
    private readonly sessionRef: MentoringSessionReference,
    private readonly goal: IGoal,
    private readonly user?: UserReference,
  ) {}
}

export class UpdateMentoringSessionGoal implements Action {
  type = `${moduleName}/${MentoringActions.UpdateMentoringSessionGoal}`;

  constructor(
    private readonly sessionRef: MentoringSessionReference,
    private readonly sessionGoalRef: MentoringSessionGoalReference,
    private readonly sessionGoal: Partial<IMentoringSessionGoal>,
    private readonly user?: UserReference,
  ) {}
}

export class DeleteMentoringSessionGoal implements Action {
  type = `${moduleName}/${MentoringActions.DeleteMentoringSessionGoal}`;

  constructor(
    private readonly sessionRef: MentoringSessionReference,
    private readonly sessionGoalRef: MentoringSessionGoalReference,
    private readonly user?: UserReference,
  ) {}
}

export class CreateMentoringSessionTask implements Action {
  type = `${moduleName}/${MentoringActions.CreateMentoringSessionTask}`;

  constructor(
    private readonly sessionRef: MentoringSessionReference,
    private readonly sessionGoalRef: MentoringSessionGoalReference,
    private readonly task: ITask,
    private readonly user?: UserReference,
  ) {}
}

export class UpdateMentoringSessionTask implements Action {
  type = `${moduleName}/${MentoringActions.UpdateMentoringSessionTask}`;

  constructor(
    private readonly sessionRef: MentoringSessionReference,
    private readonly sessionGoalRef: MentoringSessionGoalReference,
    private readonly goalTaskRef: GoalTaskReference,
    private readonly task: Partial<ITask>,
    private readonly user?: UserReference,
  ) {}
}

export class DeleteMentoringSessionTask implements Action {
  type = `${moduleName}/${MentoringActions.DeleteMentoringSessionTask}`;

  constructor(
    private readonly sessionRef: MentoringSessionReference,
    private readonly sessionGoalRef: MentoringSessionGoalReference,
    private readonly goalTaskRef: GoalTaskReference,
    private readonly user?: UserReference,
  ) {}
}

export class LoadMentoringSessionListDebug implements Action {
  type = `${moduleName}/${MentoringActions.LoadMentoringSessionListDebug}`;
}

export class CreateMentoringSessionDebug implements Action {
  type = `${moduleName}/${MentoringActions.CreateMentoringSessionDebug}`;

  constructor(private readonly session: Partial<IMentoringSession>) {}
}

export class DeleteMentoringSessionDebug implements Action {
  type = `${moduleName}/${MentoringActions.DeleteMentoringSessionDebug}`;

  constructor(private readonly sessionRef: MentoringSessionReference) {}
}

export class SendMentoringSessionAppointmentFeedback implements Action {
  type = `${moduleName}/${MentoringActions.SendMentoringSessionAppointmentFeedback}`;

  constructor(
    private readonly sessionRef: MentoringSessionReference,
    private readonly appointmentRef: AppointmentReference,
    private readonly feedback: AppointmentFeedback,
    private readonly userRef?: UserReference,
  ) {}
}

export class SendMentoringSessionFeedbackAnswers implements Action {
  type = `${moduleName}/${MentoringActions.SendMentoringSessionFeedbackAnswers}`;

  constructor(
    private readonly sessionRef: MentoringSessionReference,
    private readonly sessionFeedbackRef: MentoringSessionFeedbackReference,
    private readonly answers: Record<string, unknown>,
    private readonly userRef?: UserReference,
  ) {}
}

export class LoadMentoringSessionFeedbackQuestionnaire implements Action {
  type = `${moduleName}/${MentoringActions.LoadMentoringSessionFeedbackQuestionnaire}`;

  constructor(private readonly sessionRef: MentoringSessionReference, private readonly userRef?: UserReference) {}
}

export class LoadPersonalGoals implements Action {
  type = `${moduleName}/${MentoringActions.LoadPersonalGoals}`;

  constructor(private readonly userRef?: UserReference) {}
}

export class CreatePersonalGoal implements Action {
  type = `${moduleName}/${MentoringActions.CreatePersonalGoal}`;

  constructor(private readonly goal: Partial<IGoal>, private readonly userRef?: UserReference) {}
}

export class UpdatePersonalGoal implements Action {
  type = `${moduleName}/${MentoringActions.UpdatePersonalGoal}`;

  constructor(
    private readonly goalRef: GoalReference,
    private readonly update: Partial<IGoal>,
    private readonly userRef?: UserReference,
  ) {}
}

export class DeletePersonalGoal implements Action {
  type = `${moduleName}/${MentoringActions.DeletePersonalGoal}`;

  constructor(private readonly goalRef: GoalReference, private readonly userRef?: UserReference) {}
}
