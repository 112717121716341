<template>
  <m-select
    v-model="localValue"
    :items="genders"
    i18n-path="profile.gender.items"
    sentry-tag="language"
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
  >
  </m-select>
</template>

<script lang="ts">
import { MSelect } from '@/components/Inputs';
import Vue from 'vue';

export default Vue.extend({
  name: 'm-gender',
  components: {
    MSelect,
  },
  props: {
    value: String,
  },
  data: () => ({
    genders: ['male', 'female', 'diverse'],
  }),
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value: string) {
        this.$emit('input', value);
      },
    },
  },
});
</script>
