<template>
  <div class="d-flex align-center flex-column">
    <v-img :src="require('@/assets/sparkles.png')" contain max-height="128" max-width="128" />

    <h2 class="text--text my-4 text-center">{{ $t('enrollRitual.allSet.title') }}</h2>

    <h4 class="text--text my-2 text-center">{{ $t('enrollRitual.allSet.openUntil') }}</h4>

    <h1 class="mb-2">{{ time }} CET</h1>

    <p class="text-container text-center">{{ $t('enrollRitual.allSet.text') }}</p>

    <v-btn class="my-4" color="button" depressed @click="goToResults" :disabled="!isResultsAvailable">
      {{ $t('enrollRitual.allSet.seeResults') }}
    </v-btn>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { RouteName } from '@/router/types';
import { IRitual } from '@mentessa/types';
import { formatInTimeZone } from 'date-fns-tz';
import { parseISO } from 'date-fns';
import { CheckRitualComplete } from '@/store/rituals';

export default Vue.extend({
  name: 'm-enroll-dialog-forms-all-set',
  props: {
    ritual: Object as PropType<IRitual>,
  },
  data: () => ({
    isResultsAvailable: false,
    checkResultsTimer: undefined,
    checkResultsDebounce: 1000 * 60, // Every 1 minutes
  }),
  computed: {
    time() {
      const date = parseISO(this.ritual?.attributes?.schedule?.options?.once?.date);
      return formatInTimeZone(date, 'CET', 'h:mm a');
    },
  },
  mounted() {
    clearInterval(this.checkResultsTimer);
    this.checkResultsTimer = setInterval(async () => {
      const isComplete = await this.$store.dispatch(new CheckRitualComplete(this.ritual));
      if (isComplete) {
        this.isResultsAvailable = true;
        clearInterval(this.checkResultsTimer);
      }
    }, this.checkResultsDebounce);
  },
  methods: {
    async goToResults() {
      await this.$router.push({ name: RouteName.RitualResults, params: { ritualId: this.ritual.id } });
    },
  },
});
</script>

<style lang="scss" scoped>
.text-container {
  white-space: pre-line;
  max-width: 420px;
}
</style>
