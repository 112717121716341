import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { UIActions, UIMutations, UIState } from './types';
import { api } from '@/store/api';
import i18n from '@/plugins/i18n';
import { ShowErrorNotification, ShowNotification } from '@/store/notifications';
import * as Locales from 'date-fns/locale';

export const actions: ActionTree<UIState, RootState> = {
  async [UIActions.LoadTranslations]({ commit, dispatch }, { lang }) {
    try {
      const response = await api.get(`i18n/${lang}`);
      const data = response?.data;
      const dateLocale = Locales[lang] ?? Locales['enGB'];
      commit(UIMutations.SetTranslations, { lang, content: data, dateLocale });
      return data?.result ?? false;
    } catch (e) {
      console.error('Error while LoadTranslations.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
  async [UIActions.LoadLocaleContent]({ dispatch }, { lang }) {
    try {
      const response = await api.get(`i18n/${lang}?strict=${true}`);
      return response?.data;
    } catch (e) {
      console.error('Error while LoadLocaleContent.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [UIActions.UpdateLocaleContent]({ dispatch }, { lang, content }) {
    try {
      // Update Customized Content for current tent
      const response = await api.put(`i18n/${lang}`, {
        content,
      });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.error('Error while UpdateLocaleContent', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [UIActions.CreateLocaleContent]({ dispatch }, { lang, content }) {
    try {
      // Create new entry in i18n table to save Customized Content for current tenant
      const response = await api.post(`i18n`, {
        lang,
        content,
      });
      await dispatch(new ShowNotification(i18n.t('notifications.ok').toString()), {
        root: true,
      });
      return response.data;
    } catch (e) {
      console.error('Error while CreateLocaleContent', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  [UIActions.ChangeNavigationVisible]({ commit }, { visible }) {
    commit(UIMutations.SetNavigationVisible, visible);
  },
  [UIActions.ChangeCommunityRulesVisible]({ commit }, { visible }) {
    commit(UIMutations.SetCommunityRulesVisible, visible);
  },
  [UIActions.ChangeDialogBlockedState]({ commit }, { blocked }) {
    commit(UIMutations.SetDialogBlockedState, blocked);
  },
};
