<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.xsOnly"
    content-class="m-user-details-dialog"
    scrollable
    sentry-tag="user-details-dialog"
    transition="slide-x-reverse-transition"
    value="true"
    width="746"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card class="m-user-details">
      <div class="m-icon-container">
        <a sentry-tag="user-details-content.button.close" @click="close">
          <v-img :src="closeImage" contain height="24" width="24" />
        </a>
      </div>
      <m-user-details-carousel @onUserChanged="loadUser" />
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import { RouteName } from '@/router/types';
import { MUserDetailsCarousel } from '@/components/Dialogs/MUserDetailsDialog/MUserDetailsCarousel';
import { LoadUserDetails } from '@/store/users';

export default Vue.extend({
  name: 'm-user-details-dialog',
  components: {
    MUserDetailsCarousel,
  },
  props: {
    userId: { type: Number },
  },
  data: () => ({
    user: undefined,
  }),
  computed: {
    closeImage() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return require('@/assets/arrow-left.svg');
      }
      return require('@/assets/close-icon.svg');
    },
  },
  async mounted() {
    await this.loadUser();
  },
  methods: {
    async loadUser() {
      const user = await this.$store.dispatch(new LoadUserDetails(this.userId));
      if (user) {
        this.user = user;
      } else {
        await this.close();
      }
    },
    async close() {
      await this.$router.push({ name: RouteName.Community });
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

:deep(.m-user-details-dialog) {
  position: fixed;
  right: 0;
  margin: 0;
  border-radius: 0;
  max-height: 100%;
  height: 100%;

  .v-sheet.v-card {
    border-radius: 0;
  }

  .m-user-details {
    background-color: var(--v-beige-base);
  }
}

.m-icon-container {
  padding: 10px;
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 1;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    left: 4px;
  }
}
</style>
