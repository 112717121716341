<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row m-settings-members__header align-center gap-4 mt-4">
      <m-search-field v-model="query" :loading="loading" />
      <div class="ml-auto">
        <v-btn :loading="exportInProgress" color="button" depressed large @click="exportMembers">
          {{ $t('settings.members.export') }}
        </v-btn>
      </div>
    </div>
    <v-chip-group v-model="filters" class="px-6" column multiple>
      <v-chip v-for="(item, index) in availableFilters" :key="index" filter>
        <span> {{ item.label }} </span>
      </v-chip>
    </v-chip-group>
    <v-data-table
      :headers="headers"
      :items="matches"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalMatches"
      hide-default-footer
      show-expand
      single-expand
    >
      <template v-slot:item.member1="{ item }">
        <v-badge v-if="item.member1" :content="item.member1.attributes?.role" inline left>
          <m-user-preview :debug="isDebugAvailable" :user="item.member1.user" />
        </v-badge>
      </template>
      <template v-slot:item.member2="{ item }">
        <v-badge v-if="item.member2" :content="item.member2.attributes?.role" inline left>
          <m-user-preview :debug="isDebugAvailable" :user="item.member2.user" />
        </v-badge>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip :color="getStatusColor(item.status)">
          {{ $t(`dashboard.widgets.ritual_matches.status.${item.status}`) }}
        </v-chip>
      </template>
      <template v-slot:item.round="{ item }">
        {{ item.ritualHistory?.id }}
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>

      <template v-slot:expanded-item="{ item }">
        <td :colspan="expandedDataOffset"></td>
        <td>
          <div class="d-flex flex-column gap-2 my-2 align-start">
            <v-badge v-if="item.member1" :content="item.member1.attributes?.role" inline left>
              <m-user-preview :debug="isDebugAvailable" :user="item.member1.user" />
            </v-badge>
            <v-badge v-if="item.member2" :content="item.member2.attributes?.role" inline left>
              <m-user-preview :debug="isDebugAvailable" :user="item.member2.user" />
            </v-badge>
          </div>
        </td>
        <td>
          <div class="d-flex flex-column gap-2 align-start">
            <v-chip v-if="item.member1" :color="getStatusColor(item.member1.status)">
              {{ $t(`dashboard.widgets.ritual_matches.status.${item.member1.status}`) }}
            </v-chip>
            <v-chip v-if="item.member2" :color="getStatusColor(item.member2.status)">
              {{ $t(`dashboard.widgets.ritual_matches.status.${item.member2.status}`) }}
            </v-chip>
          </div>
        </td>
      </template>
      <template v-slot:no-data>
        <p class="mt-4 text-center">{{ $t('settings.ritualEnroll.noMembers') }}</p>
      </template>
    </v-data-table>
    <v-row class="justify-end mr-4 mt-2"> Total: {{ totalMatches }}</v-row>
    <v-row dense>
      <v-pagination
        v-model="currentPage"
        :disabled="loading"
        :length="totalPages"
        class="m-settings-members__table-pagination pa-6 ml-auto"
        sentry-tag="settings-members.pagination"
      />
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {
  AcceptRitualUser,
  DeclineRitualUser,
  EnrollDemoUsersToRitual,
  LoadRitualMatches,
  RemoveFromRitual,
  RitualsState,
} from '@/store/rituals';
import { IMatch, IMatchUser, IRitualUser, MatchingPage, MatchStatus, MentoringRole, RitualType } from '@mentessa/types';
import { mapGetters, mapState } from 'vuex';
import { MUserPreview } from '@/components/MUserPreview';
import { MSearchField } from '@/components/Inputs';

function mentoringRoleScore(matchUser: IMatchUser) {
  switch (matchUser.attributes?.role) {
    case MentoringRole.Mentor:
      return 3;
    case MentoringRole.Mentee:
      return 1;
    case MentoringRole.Peer:
      return 2;
    default:
      return 0;
  }
}

function compareMatchUsers(left: IMatchUser, right: IMatchUser) {
  const cmpRoles = mentoringRoleScore(right) - mentoringRoleScore(left);
  if (cmpRoles !== 0) {
    return cmpRoles;
  }
  return right.id - left.id;
}

function getMatchUserStatus(matchUser: IMatchUser) {
  if (matchUser.accepted) {
    return MatchStatus.Accepted;
  } else if (matchUser.accepted === false) {
    return MatchStatus.Declined;
  } else {
    return MatchStatus.Pending;
  }
}

export default Vue.extend({
  name: 'm-ritual-matching-report',
  components: { MSearchField, MUserPreview },
  data: () => ({
    loading: false,
    selected: [],
    demoUsersCount: 0,
    options: undefined,
    matchesPage: undefined as MatchingPage,
    query: undefined,
    exportInProgress: false,
    filters: [],
  }),
  computed: {
    ...mapGetters('tenant', {
      isDebugAvailable: 'isDebugAvailable',
    }),
    ...mapState<RitualsState>('rituals', {
      ritual: (state: RitualsState) => state.current,
    }),
    ...mapGetters('ui', {
      formatInUserTimeZone: 'formatInUserTimeZone',
    }),

    expandedDataOffset() {
      return this.isDebugAvailable ? 2 : 1;
    },

    headers() {
      const headers = [];
      if (!this.ritual) {
        return headers;
      }

      if (this.isDebugAvailable) {
        headers.push({ text: 'Id', value: 'id', sortable: false });
      }

      headers.push({ text: this.$t('settings.matching.table.user1'), value: 'member1', sortable: false });
      headers.push({ text: this.$t('settings.matching.table.user2'), value: 'member2', sortable: false });
      headers.push({ text: this.$t('settings.members.table.status'), value: 'status', sortable: true });
      headers.push({ text: 'Round', value: 'round', sortable: true });
      headers.push({ text: this.$t('settings.members.table.createdAt'), value: 'createdAt', sortable: true });

      headers.push({ text: '', value: 'data-table-expand' });

      return headers;
    },

    currentPage: {
      get() {
        return this.matchesPage?.meta?.currentPage ?? 1;
      },
      async set(value: number) {
        await this.loadMatches(value);
      },
    },

    totalPages() {
      return this.matchesPage?.meta?.totalPages ?? 1;
    },

    totalMatches() {
      return this.matchesPage?.meta?.totalItems ?? 0;
    },

    matches() {
      return this.matchesPage?.items ?? [];
    },

    availableRoles() {
      const roles = [];
      if (this.ritual?.type === RitualType.SkillsMatching) {
        roles.push(MentoringRole.Mentor, MentoringRole.Mentee, MentoringRole.Peer);
        if (this.ritual.attributes?.roles?.any) {
          roles.push('any');
        }
      }
      return roles;
    },

    availableFilters() {
      return [];
    },
  },
  watch: {
    async ritual() {
      await this.loadMatches();
    },
    async query() {
      await this.loadMatches();
    },
    options: {
      async handler() {
        await this.loadMatches();
      },
      deep: true,
    },
    async filters() {
      await this.loadMatches();
    },
  },
  async mounted() {
    if (this.ritual) {
      await this.loadMatches();
    }
  },
  methods: {
    async loadMatches(page = 1) {
      if (!this.ritual) {
        return;
      }
      try {
        const { sortBy, sortDesc } = this.options;
        this.loading = true;
        const orderBy = sortBy?.length > 0 ? (sortDesc[0] ? 'desc' : 'asc') : undefined;
        // const statusFilter = this.filters
        //   .filter((filterIndex: number) => this.availableFilters[filterIndex].kind === FilterKind.Status)
        //   .map((filterIndex) => this.availableFilters[filterIndex].value);
        // const roleFilter = this.filters
        //   .filter((filterIndex: number) => this.availableFilters[filterIndex].kind === FilterKind.Role)
        //   .map((filterIndex) => this.availableFilters[filterIndex].value);
        // const matchedFilter = this.filters.find(
        //   (filterIndex) => this.availableFilters[filterIndex].kind === FilterKind.Matched,
        // )
        //   ? false
        //   : undefined;
        const matchingPage: MatchingPage = await this.$store.dispatch(
          new LoadRitualMatches(this.ritual, page, { query: this.query, sortBy: sortBy[0], sortOrder: orderBy }),
        );

        matchingPage.items.forEach(
          (
            match: IMatch & {
              member1: IMatchUser & { status: MatchStatus };
              member2: IMatchUser & { status: MatchStatus };
            },
          ) => {
            match.matchUsers?.sort(compareMatchUsers);
            match.member1 = match.matchUsers?.[0]
              ? { ...match.matchUsers[0], status: getMatchUserStatus(match.matchUsers[0]) }
              : undefined;
            match.member2 = match.matchUsers?.[1]
              ? { ...match.matchUsers[1], status: getMatchUserStatus(match.matchUsers[1]) }
              : undefined;
          },
        );
        this.matchesPage = matchingPage;
      } finally {
        this.loading = false;
      }
    },

    formatDate(date: string) {
      return this.formatInUserTimeZone(date, 'dd.MM.yyyy');
    },

    async exportMembers() {
      this.exportInProgress = true;
      try {
        // const statusFilter = this.filters
        //   .filter((filterIndex: number) => this.availableFilters[filterIndex].kind === FilterKind.Status)
        //   .map((filterIndex) => this.availableFilters[filterIndex].value);
        // const roleFilter = this.filters
        //   .filter((filterIndex: number) => this.availableFilters[filterIndex].kind === FilterKind.Role)
        //   .map((filterIndex) => this.availableFilters[filterIndex].value);
        // const matchedFilter = this.filters.find(
        //   (filterIndex) => this.availableFilters[filterIndex].kind === FilterKind.Matched,
        // )
        //   ? false
        //   : undefined;
        // await this.$store.dispatch(
        //   new ExportRitualMembers(this.ritual, this.query, statusFilter, roleFilter, matchedFilter),
        // );
      } finally {
        this.exportInProgress = false;
      }
    },

    memberRole(member: IRitualUser) {
      const roles = member?.user?.attributes?.roles ?? [];
      if (roles.includes('Mentor')) {
        return this.$t('mentoring.role.mentor');
      } else if (roles.includes('Mentee')) {
        return this.$t('mentoring.role.mentee');
      }
      return '';
    },

    async enrollDemoUsers() {
      if (this.demoUsersCount > 0) {
        await this.$store.dispatch(new EnrollDemoUsersToRitual({ id: this.ritual?.id }, this.demoUsersCount));
        await this.fetchData(this.page);
      }
    },
    updateAccepted(memberIds: Array<number>, status: boolean, all = false) {
      this.members.forEach((member) => {
        if (all || memberIds.includes(member.id)) member.accepted = status;
      });
    },
    async acceptMember(member: IRitualUser) {
      try {
        this.loading = true;
        if (await this.$store.dispatch(new AcceptRitualUser(this.ritual, member))) {
          this.updateAccepted([member.id], true);
          this.toggleSelected(member.id, false);
        }
      } finally {
        this.loading = false;
      }
    },
    async declineMember(member: IRitualUser) {
      try {
        this.loading = true;
        if (await this.$store.dispatch(new DeclineRitualUser(this.ritual, member))) {
          this.updateAccepted([member.id], false);
          this.toggleSelected(member.id, false);
        }
      } finally {
        this.loading = false;
      }
    },
    async toggleAccepted(member: IRitualUser) {
      if (member.accepted) {
        await this.declineMember(member);
      } else {
        await this.acceptMember(member);
      }
    },
    async exclude(member: IRitualUser) {
      try {
        this.loading = true;
        await this.$store.dispatch(new RemoveFromRitual(this.ritual, member));
        const memberId = this.members.findIndex((m) => m.id === member.id);
        if (memberId !== -1) {
          this.members.splice(memberId, 1);
        }
      } finally {
        this.loading = false;
      }
    },
    getStatusColor(status: MatchStatus) {
      switch (status) {
        case MatchStatus.Accepted:
          return 'success';
        case MatchStatus.Declined:
          return 'error';
        case MatchStatus.Expired:
          return 'rating';
        case MatchStatus.Closed:
          return 'accent';

        default:
          return undefined;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
:deep(.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content) {
  box-shadow: none;
}
</style>
