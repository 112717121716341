<template>
  <m-text-field-edit
    v-if="editMode || deleteConfirmationState"
    :deleteConfirmation="deleteConfirmationState"
    :value="text"
    @onCancel="cancel"
    @onDelete="remove"
    @onDeleteCancel="deleteConfirmationState = false"
    @onSave="save"
  />
  <v-row v-else class="ma-0 pt-1 pb-2 align-center">
    <v-checkbox
      v-model="done"
      :disabled="readOnly"
      :label="text"
      class="pt-0 mt-0"
      color="success"
      dense
      hide-details
      style="max-width: 600px"
    />
    <div class="ml-auto">
      <v-tooltip v-if="!readOnly" top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-2" icon v-bind="attrs" @click="deleteConfirmationState = true" v-on="on">
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('mentoring.goals.deleteHint') }}</span>
      </v-tooltip>
      <v-btn v-if="!readOnly" class="ml-1" icon @click="enableEditMode">
        <v-icon color="primary">mdi-pencil-outline</v-icon>
      </v-btn>
    </div>
  </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { mapState } from 'vuex';
import { UsersState } from '@/store/users';
import { IGoalTask, IMentoringSession, IMentoringSessionGoal, MentoringSessionStatus } from '@mentessa/types';
import { MTextFieldEdit } from '@/components/Inputs';
import { DeleteMentoringSessionTask, UpdateMentoringSessionTask } from '@/store/mentoring';

export default Vue.extend({
  name: 'm-mentoring-session-goal-task',
  components: { MTextFieldEdit },
  props: {
    sessionGoal: { type: Object as PropType<IMentoringSessionGoal> },
    session: { type: Object as PropType<IMentoringSession> },
    goalTask: { type: Object as PropType<IGoalTask> },
  },
  data: () => ({
    editMode: false,
    deleteConfirmationState: false,
  }),
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    text() {
      if (!this.goalTask?.task?.text) {
        return '';
      }
      if (this.goalTask.task.i18n && this.$te(`known_tasks.${this.goalTask.task.text}`)) {
        return this.$t(`known_tasks.${this.goalTask.task.text}`);
      }
      return this.goalTask.task.text;
    },
    done: {
      get() {
        return this.goalTask?.task?.done ?? false;
      },
      async set(isDone: boolean) {
        this.loading = true;
        try {
          await this.$store.dispatch(
            new UpdateMentoringSessionTask(this.session, this.sessionGoal, this.goalTask, { done: isDone }),
          );
        } finally {
          this.loading = false;
        }
      },
    },
    createdBy() {
      return this.session?.members.find((member) => member.user.id === this.goalTask?.task?.createdById)?.user;
    },
    readOnly() {
      return this.session?.status !== MentoringSessionStatus.Progress;
    },
  },
  methods: {
    enableEditMode() {
      this.editMode = true;
    },
    async save(newValue: string) {
      this.loading = true;
      try {
        const success = await this.$store.dispatch(
          new UpdateMentoringSessionTask(this.session, this.sessionGoal, this.goalTask, { text: newValue }),
        );
        if (success) {
          this.editMode = false;
        }
      } finally {
        this.loading = false;
      }
    },
    cancel() {
      this.editMode = false;
    },
    async remove() {
      this.loading = true;
      try {
        const success = await this.$store.dispatch(
          new DeleteMentoringSessionTask(this.session, this.sessionGoal, this.goalTask),
        );
        if (success) {
          this.editMode = false;
        }
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
