<template>
  <v-container sentry-tag="badges"></v-container>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'm-badges',
});
</script>
