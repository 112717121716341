import 'material-design-icons-iconfont/dist/material-design-icons.css';
import Close from './Close.vue';
import HandshakeEmoji from './HandshakeEmoji.vue';
import Envelope from './Envelope.vue';
import { IconsOptions } from 'vuetify/types/services/icons';

const options: IconsOptions = {
  iconfont: 'md',
  values: {
    c3lose: {
      component: Close,
    },
    handshake: {
      component: HandshakeEmoji,
    },
    envelope: {
      component: Envelope,
    },
  },
};

export default options;
