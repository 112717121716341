<template>
  <m-panel banner>
    <m-user-preview :mentoring-role="role" :user="user" bold job location />
    <p class="text--text text--lighten-2 mt-3 mb-1">{{ $t('mentoring.userCard.expertise') }}</p>
    <m-tags-wrapper :tags="expertise" color="banner" />
    <div v-if="user">
      <a class="primary--text d-block mb-4" @click="showUserDetailsDialog = true">
        {{ $t('mentoring.userCard.viewProfile') }}
      </a>
      <a class="primary--text d-block mb-4" @click="goToResources">
        {{ $t('mentoring.userCard.checkResources') }}
      </a>
      <v-btn v-if="isClosed" class="d-block" dark x-large @click="reOpenSession">
        {{ $t('mentoring.dashboard.reopen') }}
      </v-btn>
      <v-btn
        v-else
        :dark="initialTasksCompleted || !hadAppointments"
        :disabled="readOnly"
        :outlined="!(initialTasksCompleted || !hadAppointments)"
        class="d-block"
        x-large
        @click="showBookingDialog = true"
      >
        {{ bookBtnLabel }}
      </v-btn>
    </div>
    <m-user-details-simple-dialog v-if="userId" v-model="showUserDetailsDialog" :user-id="userId" />
    <m-book-time-slot-dialog
      v-model="showBookingDialog"
      :mentoring-member="member"
      :mentoring-session="session"
      :user="user"
    />
  </m-panel>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import MUserPreview from '@/components/MUserPreview';
import { IAppointment, IMentoringSession, IMentoringSessionMember, MentoringSessionStatus } from '@mentessa/types';
import { MUserDetailsSimpleDialog } from '@/components/Dialogs/MUserDetailsDialog';
import { MBookTimeSlotDialog } from '@/components/Dialogs/MBookTimeSlotDialog';
import MTagsWrapper from '@/components/MTagsWrapper';
import { ShowNotification } from '@/store/notifications';
import { OpenMentoringSession } from '@/store/mentoring';
import { RouteName } from '@/router/types';
import MPanel from '@/components/MPanel';

export default Vue.extend({
  name: 'm-mentoring-user-card',
  components: { MPanel, MTagsWrapper, MBookTimeSlotDialog, MUserDetailsSimpleDialog, MUserPreview },
  props: {
    member: Object as PropType<IMentoringSessionMember>,
    session: { type: Object as PropType<IMentoringSession> },
  },
  data: () => ({
    showUserDetailsDialog: false,
    showBookingDialog: false,
    resourcesRoute: { name: RouteName.Resources },
  }),
  computed: {
    user() {
      return this.member?.user;
    },
    expertise() {
      return this.user?.expertiseTags ?? [];
    },
    userId() {
      return this.user?.id;
    },
    role() {
      return this.member?.role;
    },
    appointments(): Array<IAppointment> {
      return this.session?.appointments ?? [];
    },
    bookBtnLabel() {
      if (this.session?.appointments == null) {
        return this.$t('mentoring.dashboard.book');
      }
      return this.appointments.length === 0
        ? this.$t('mentoring.dashboard.bookFirst')
        : this.$t('mentoring.dashboard.book');
    },
    hadAppointments() {
      return this.appointments.length > 0;
    },
    initialTasksCompleted() {
      return this.appointments?.length > 0 && this.session?.messages?.length > 0 && this.session?.goals?.length > 0;
    },
    readOnly() {
      return this.session?.status !== MentoringSessionStatus.Progress;
    },
    isClosed() {
      return this.session?.status === MentoringSessionStatus.Finished;
    },
    journalRoute() {
      return {
        name: RouteName.ActiveMentoringSession,
        params: { sessionId: this.session.id },
      };
    },
  },
  methods: {
    async goToResources() {
      await this.$router.push(this.resourcesRoute);
    },
    openUserDetailsDialog() {
      this.showUserDetailsDialog = true;
    },
    async reOpenSession() {
      await this.$store.dispatch(new OpenMentoringSession({ id: this.session.id }));
      await this.$store.dispatch(new ShowNotification(this.$t('notifications.mentoringSessionOpened')), {
        root: true,
      });
      await this.$router.push(this.journalRoute);
    },
  },
});
</script>

<style lang="scss" scoped>
.mentoring-user-card__header {
  //color: var(--v-lightgray-base);
}

.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: black !important;
  color: gray !important;
}
</style>
