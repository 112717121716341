<template>
  <m-panel>
    <h4 class="text-uppercase">{{ $t('panels.matches.title') }}</h4>
    <v-slide-group class="mt-4" show-arrows center-active>
      <v-slide-item v-for="user in matchedUsers" :key="`match-${user.id}`" class="mx-2" v-slot="{ toggle }">
        <v-chip @click="showDetails(user, toggle)" color="beige">
          <m-user-preview :user="user" />
        </v-chip>
      </v-slide-item>
    </v-slide-group>
    <m-user-details-simple-dialog v-if="activeMatch" v-model="showUserDetailsDialog" :user-id="activeMatch.id" />
  </m-panel>
</template>

<script lang="ts">
import Vue from 'vue';
import MPanel from '@/components/MPanel';
import { LoadUserMatches } from '@/store/matching';
import { IMatchUser, IUser } from '@mentessa/types';
import MUserPreview from '@/components/MUserPreview';
import { MUserDetailsSimpleDialog } from '@/components/Dialogs';

export default Vue.extend({
  name: 'm-user-matches-panel',
  components: { MUserDetailsSimpleDialog, MUserPreview, MPanel },
  props: {
    max: { type: Number, default: 10 },
  },
  data: () => ({
    matchedUsers: [] as Array<IUser>,
    loading: false,
    activeMatch: undefined as IUser,
    showUserDetailsDialog: false,
  }),
  watch: {
    loading(value: boolean) {
      if (!value) {
        this.$emit('onReady');
      }
    },
  },
  async mounted() {
    try {
      this.loading = true;
      const matches: Array<IMatchUser> = await this.$store.dispatch(new LoadUserMatches());
      const matchedUsers = [];
      const uniqMatchUsers = new Set();
      matches.forEach((match) => {
        const matchWith = match.match?.matchUsers?.[0]?.user;
        if (matchWith && !uniqMatchUsers.has(matchWith.id)) {
          uniqMatchUsers.add(matchWith.id);
          matchedUsers.push(matchWith);
        }
      });
      this.matchedUsers = matchedUsers;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    showDetails(user: IUser, toggle: () => void) {
      toggle();
      this.activeMatch = user;
      this.showUserDetailsDialog = true;
    },
  },
});
</script>

<style lang="scss" scoped>
:deep(.v-slide-group) {
  .v-slide-group__wrapper {
    mask: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 5%, rgba(0, 0, 0, 1) 95%, rgba(0, 0, 0, 0) 100%);
  }
  .v-slide-group__next,
  .v-slide-group__prev {
    min-width: 24px;
  }
}
</style>
