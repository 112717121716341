<template>
  <v-container class="m-invite">
    <div class="text-center d-flex flex-column m-invite-container pa-8">
      <v-btn fab depressed top left absolute class="mt-12 ml-2" @click="goBack">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <div class="d-flex align-center flex-column">
        <v-img contain :src="image" max-width="128" max-height="128" />
        <h1 class="text--text mt-2">{{ title }}</h1>
        <h2 v-if="false" class="text--text">{{ date }}</h2>
      </div>
      <h4 class="text--text">{{ $t('qr_code.scan_qr_code') }}</h4>
      <qrcode-vue :value="link" :size="200" />
      <h4 v-if="false" class="text--text">{{ $t('qr_code.matching_closes', { time: dueTime }) }}</h4>
      <v-row>
        {{ link }}
        <a @click="copyToClipboard" class="ml-4">
          <v-icon>mdi-content-copy</v-icon>
        </a>
      </v-row>

      <div class="d-flex align-center flex-column">
        <h5 class="text--text mb-2">{{ $t('qr_code.powered_by') }}</h5>
        <v-img src="@/assets/logo-mentessa.svg" max-width="96" />
      </div>
    </div>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';
import QrcodeVue from 'qrcode.vue';
import { MatchingState } from '@/store/matching';
import { RitualsState } from '@/store/rituals';
import deepmerge from 'deepmerge';
import { dateWithDayOfWeek } from '@/utils/date';
import { parseISO } from 'date-fns';
import { RitualScheduleOptions } from '@mentessa/types';
import { formatInTimeZone } from 'date-fns-tz';
import { RouteName } from '@/router/types';
import { copy } from '@/utils/clipboard';
import { ShowNotification } from '@/store/notifications';
import i18n from '@/plugins/i18n';

export default Vue.extend({
  name: 'm-ritual-invite',
  components: {
    QrcodeVue,
  },
  props: {
    ritualId: { type: Number },
  },
  data: () => ({
    ritual: undefined,
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    ...mapState<MatchingState>('rituals', {
      ritualsPage: (state: RitualsState) => {
        return state.rituals;
      },
    }),
    rituals() {
      return this.ritualsPage.items;
    },
    image() {
      if (this.tenant?.attributes.theme.image) {
        return this.tenant?.attributes.theme.image;
      }
      return require('@/assets/logo.svg');
    },
    date() {
      const schedule: RitualScheduleOptions = this.ritual?.attributes?.schedule;
      return schedule?.options?.once?.date ? dateWithDayOfWeek(parseISO(schedule.options.once.date.toString())) : '-';
    },
    link() {
      return this.invite ? `${window.origin}/invites/${this.invite.guid}` : undefined;
    },
    dueTime() {
      const schedule: RitualScheduleOptions = this.ritual?.attributes?.schedule;
      if (!schedule?.options?.once?.date) {
        return '';
      }
      const date = parseISO(schedule.options.once.date.toString());
      return formatInTimeZone(date, 'CET', 'h:mm a');
    },
    title() {
      if (!this.ritual) {
        return this.$t('settings.ritualEnroll.listTitle');
      }
      return this.ritual.attributes?.title ?? this.$t(`settings.rituals.knownNames.${this.ritual.type}`);
    },
    invite() {
      return this.ritual?.invites?.[0];
    },
  },
  watch: {
    rituals() {
      this.loadRitual();
    },
  },
  async mounted() {
    this.loadRitual();
  },
  methods: {
    loadRitual() {
      if (!this.ritualId) return;
      const baseRitual = this.rituals.find((r) => r.id === Number(this.ritualId));
      if (!baseRitual) return;
      this.ritual = deepmerge(baseRitual, {});
    },
    goBack() {
      this.$router.replace({
        name: RouteName.SettingsRituals,
        params: this.$route.params,
        query: this.$route.query,
      });
    },
    async copyToClipboard() {
      if (this.invite) {
        const link = `${window.origin}/invites/${this.invite.guid}`;
        await copy(link);
        await this.$store.dispatch(new ShowNotification(i18n.t('notifications.ok').toString()));
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.m-invite-container {
  position: relative;
  max-width: 800px;
  gap: 32px;
  align-items: center;
  justify-content: center;
  background-color: var(--v-white-base);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  aspect-ratio: 1;
  border-radius: 6px;
  width: 100%;
}

.m-invite {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
