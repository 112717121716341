<template>
  <v-card class="m-skeleton-user-card" width="286" height="591" sentry-tag="skeleton-column-user-card">
    <v-skeleton-loader type="image" height="240" />
    <div class="m-skeleton-user-card__content">
      <v-skeleton-loader type="text" class="mb-2" width="100" />
      <v-skeleton-loader type="text" class="mb-6" min-height="50"></v-skeleton-loader>
      <v-skeleton-loader type="text" class="mb-2" width="100" height="21" />
      <v-skeleton-loader type="text" min-height="36"></v-skeleton-loader>
      <v-skeleton-loader type="text" class="mb-2" width="100" height="21" />
      <v-skeleton-loader type="text" min-height="33"></v-skeleton-loader>
    </div>
    <v-divider />
    <div class="m-skeleton-user-card__button-container d-flex justify-center align-center">
      <v-skeleton-loader v-if="!invite" type="button" />
      <v-btn
        v-else
        @click="isAddMembersDialogOpened = true"
        color="button"
        depressed
        sentry-tag="skeleton-column-user-card.button.invite"
      >
        {{ $t('inviteCard.buttonText') }}
      </v-btn>
    </div>
    <m-add-members-dialog v-model="isAddMembersDialogOpened" />
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import { MAddMembersDialog } from '@/components/Dialogs/MAddMembersDialog';

export default Vue.extend({
  name: 'm-skeleton-column-user-card',
  components: { MAddMembersDialog },
  props: {
    invite: { type: Boolean, default: false },
  },
  data: () => ({
    isAddMembersDialogOpened: false,
  }),
});
</script>

<style lang="scss" scoped>
.m-skeleton-user-card {
  &__content {
    padding: 32px 25px;
  }

  &__button-container {
    height: 60px;
  }
}

.m-skeleton-user-card:deep(.v-skeleton-loader__image) {
  height: 240px;
}
</style>
