<template>
  <div class="m-dashboard-widget__col-3">
    <h4>{{ $t('dashboard.widgets.ritual_appointments.title') }}</h4>
    <v-container>
      <v-row class="ma-0">
        <v-col>
          <v-row class="ma-0">
            <h3>{{ status.scheduled }}</h3>
            <span class="ml-2">{{ $t('dashboard.widgets.ritual_appointments.status.scheduled') }}</span>
          </v-row>
        </v-col>
        <v-col>
          <v-row class="ma-0">
            <h3>{{ status.upcoming }}</h3>
            <span class="ml-2">{{ $t('dashboard.widgets.ritual_appointments.status.upcoming') }}</span>
          </v-row>
        </v-col>
        <v-col>
          <v-row class="ma-0">
            <h3>{{ status.canceled }}</h3>
            <span class="ml-2">{{ $t('dashboard.widgets.ritual_appointments.status.canceled') }}</span>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="justify-center fill-width">
        <h4 class="text-start fill-width">{{ $t('dashboard.widgets.ritual_appointments.feedbacksTitle') }}</h4>
        <doughnut :data="values" :options="statusChartOptions" class="m-chart" />
      </v-row>
    </v-container>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { AppointmentStatus } from '@mentessa/types';
import { Doughnut } from 'vue-chartjs';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { generateLabelsWithCount } from '@/utils/charts';
import { mapGetters } from 'vuex';

ChartJS.register(ArcElement, Tooltip, Legend);

export interface RitualAppointmentsData {
  ritual_appointments: Array<{ status: AppointmentStatus; count: string }>;
  ritual_appointments_feedback: Array<{
    initiator_positive: string;
    participant_positive: string;
    inititator_negative: string;
    participant_negative: string;
  }>;
}

const options = {
  responsive: true,
  maintainAspectRatio: true,
};

export default Vue.extend({
  name: 'm-ritual-appointments-widget',
  components: {
    Doughnut,
  },
  props: {
    value: { type: Object as PropType<RitualAppointmentsData> },
  },
  data: () => ({
    total: 0,
    status: {
      scheduled: 0,
      upcoming: 0,
      canceled: 0,
    },
    feedback: {
      initiator_positive: 0,
      participant_positive: 0,
      inititator_negative: 0,
      participant_negative: 0,
    },
  }),
  computed: {
    ...mapGetters('tenant', {
      getColorPalette: 'getColorPalette',
    }),
    values() {
      return {
        labels: [
          this.$t(`dashboard.widgets.ritual_appointments.feedback.positive`),
          this.$t(`dashboard.widgets.ritual_appointments.feedback.negative`),
        ],
        datasets: [
          {
            backgroundColor: [this.getColorPalette.at(0), this.getColorPalette.at(-1)],
            data: [
              this.feedback.initiator_positive + this.feedback.participant_positive,
              this.feedback.inititator_negative + this.feedback.participant_negative,
            ],
          },
        ],
      };
    },
    options() {
      return options;
    },
    statusChartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: 'right',
            labels: {
              font: {
                size: 16,
                lineHeight: 1,
              },
              generateLabels: generateLabelsWithCount,
            },
          },
        },
      };
    },
  },
  async mounted() {
    this.value?.ritual_appointments?.forEach((row) => {
      const count = Number(row.count ?? 0);
      switch (row.status) {
        case AppointmentStatus.Pending:
          this.status.upcoming += count;
          this.status.scheduled += count;
          break;
        case AppointmentStatus.Confirmed:
          this.status.upcoming += count;
          this.status.scheduled += count;
          break;
        case AppointmentStatus.Canceled:
          this.status.canceled = count;
          break;
        case AppointmentStatus.Finished:
          this.status.scheduled = count;
          break;
      }
      this.total += count;
    });

    const feedbacks = this.value?.ritual_appointments_feedback?.[0];
    this.feedback.participant_positive = Number(feedbacks?.participant_positive ?? 0);
    this.feedback.initiator_positive = Number(feedbacks?.initiator_positive ?? 0);
    this.feedback.participant_negative = Number(feedbacks?.participant_negative ?? 0);
    this.feedback.inititator_negative = Number(feedbacks?.inititator_negative ?? 0);
  },
});
</script>

<style lang="scss" scoped>
.m-chart {
  max-height: 240px;
}
</style>
