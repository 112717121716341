<template>
  <m-panel>
    <v-chip>
      <m-ritual-preview v-if="ritual" :ritual="ritual" max-width="240px" />
      <span v-else>{{ $t(`settings.matching.source.${source}`) }}</span>
    </v-chip>

    <m-user-info-card :value="matchedUser" />
    <div class="d-flex flex-row mt-4 justify-center">
      <v-btn :to="journalRoute" color="button" width="120">{{ $t('cards.journal.open') }}</v-btn>
    </div>
  </m-panel>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IMentoringSession, MatchSource } from '@mentessa/types';
import MPanel from '@/components/MPanel';
import MRitualPreview from '@/components/MRitualPreview';
import { RouteName } from '@/router/types';
import { mapState } from 'vuex';
import { UsersState } from '@/store/users';
import MUserInfoCard from '@/components/Cards/MUserInfoCard';

export default Vue.extend({
  name: 'm-journal-card',
  components: { MUserInfoCard, MRitualPreview, MPanel },
  props: {
    value: Object as PropType<IMentoringSession>,
  },
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    source() {
      return this.value?.ritual ? MatchSource.Ritual : this.value?.match?.source ?? MatchSource.Manual;
    },
    matchedUser() {
      return this.value?.members?.find((member) => member.userId != this.me.id)?.user;
    },
    ritual() {
      return this.value?.ritual;
    },
    journalRoute() {
      return this.value ? { name: RouteName.MentoringSession, params: { sessionId: this.value.id } } : undefined;
    },
  },
});
</script>

<style lang="scss" scoped></style>
