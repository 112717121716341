<template>
  <div class="m-questionnaire-container">
    <!-- ToDo: It should work without v-if. Some recent change lead to issue where first step cannot be selected -->
    <v-navigation-drawer
      v-model="sidebarVisible"
      :absolute="$vuetify.breakpoint.smAndDown"
      :permanent="!$vuetify.breakpoint.smAndDown"
      :temporary="$vuetify.breakpoint.smAndDown"
      :touchless="!$vuetify.breakpoint.smAndDown"
      width="300px"
    >
      <v-stepper v-if="sections.length" v-model="currentStep" class="m-questionnaire-stepper" vertical>
        <div class="m-logo-container">
          <v-img :src="image" contain max-height="90%" max-width="80%" />
        </div>
        <h3 class="ma-2">{{ tr('title') }}</h3>
        <template v-for="(section, index) in sections">
          <v-stepper-step
            :key="`step-${index + 1}`"
            :complete="completedSectionsIndex > index"
            :editable="editable && completedSectionsIndex + 1 > index"
            :step="arabicToRoman(index + 1)"
            class="m-section-stepper-step"
          >
            {{ tr(`${section.name}.title`) }}
          </v-stepper-step>
          <v-stepper-content :key="`step-${index}-content`" :step="arabicToRoman(index + 1)">
            <ol :start="firstQuestionIndexShift">
              <li v-for="(question, questionIndex) in section.questions" :key="`step-content-section-${questionIndex}`">
                <a @click="selectQuestion(questionIndex)">
                  <strong v-if="questionIndex === currentSubStepIndex" class="primary--text">
                    {{ tr(`${section.name}.questions.${question.name}`) }}
                  </strong>
                  <span v-else>{{ tr(`${section.name}.questions.${question.name}`) }}</span>
                </a>
              </li>
            </ol>
          </v-stepper-content>
        </template>
      </v-stepper>
    </v-navigation-drawer>
    <div v-if="!loading" class="m-questionnaire-content-container flex-grow-1">
      <v-col class="m-questionnaire-content pt-8">
        <div class="m-questionnaire-content__header">
          <div class="d-flex m-questionnaire-content__title">
            <div class="m-section-number mr-4">{{ currentStep }}</div>
            <h2>{{ sectionTitle }}</h2>
          </div>
        </div>
        <validation-observer v-if="question" v-slot="{ invalid }" class="m-questionnaire-content__question mt-12">
          <h3 v-if="showQuestionTitle" class="mb-8 primary--text keep-new-line text-center font-size-20">
            {{ globalQuestionIndex }}. {{ questionTitle }}
          </h3>
          <span v-if="questionHasDescription">{{ questionDescription }}</span>
          <div class="m-questionnaire-content__question__container ma-auto">
            <div class="m-questionnaire-content__question__component-container justify-center d-flex">
              <component
                :is="question.component"
                v-if="question.component"
                v-model="answers[section.name][question.name]"
                :class="question.classes"
                autofocus
                class="m-questionnaire-content__question__component"
                v-bind="question.props"
              />
            </div>
          </div>
          <div class="m-questionnaire-content__button_container d-flex">
            <v-col cols="6">
              <v-btn v-if="showBackButton" :disabled="loading" color="button" width="100%" outlined @click="goBack">
                {{ $t('questionnaires.back') }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn :disabled="invalid || loading" color="button" width="100%" @click="goNext">
                {{ actionTitle }}
              </v-btn>
            </v-col>
          </div>
        </validation-observer>
      </v-col>
    </div>
    <m-loader v-else is-loading />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { arabicToRoman, romanToArabic } from '@/utils/convert';
import { LoadRitual, LoadRitualQuestionnaire, SendQuestionnaireAnswers } from '@/store/rituals';
import {
  IQuestion,
  IRitual,
  IRitualQuestionnaire,
  MentoringRole,
  QuestionType,
  SyncDirection,
  SyncField,
} from '@mentessa/types';
import {
  MChipGroup,
  MCombobox,
  MComboboxTags,
  MScale,
  MScaleMultiple,
  MSelect,
  MTextArea,
  MTextField,
  MTimeSlots,
  MWeekdayPicker,
  MYesNo,
} from '@/components/Inputs';
import { ValidationObserver } from 'vee-validate';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';
import MQuestionnaireSubmitForm from './MQuestionnaireSubmitForm.vue';
import MQuestionnaireGuidelinesForm from './MQuestionnaireGuidelinesForm.vue';
import { KnownValidationRule, ValidationList } from '@/utils/validation';
import { UsersState } from '@/store/users';
import MLoader from '@/components/MLoader';

function getQuestionComponent(question: IQuestion) {
  switch (question.type) {
    case QuestionType.FreeText:
      return MTextArea;
    case QuestionType.FreeTags:
      return MCombobox;
    case QuestionType.FreeSkillsTags:
      return MComboboxTags;
    case QuestionType.Scale:
      return MScale;
    case QuestionType.ScaleMultiple:
      return MScaleMultiple;
    case QuestionType.ChipGroup:
      return MChipGroup;
    case QuestionType.Weekday:
      return MWeekdayPicker;
    case QuestionType.WeekdayExtended:
      return MWeekdayPicker;
    case QuestionType.Guidelines:
      return MQuestionnaireGuidelinesForm;
    case QuestionType.Submit:
      return MQuestionnaireSubmitForm;
    case QuestionType.Schedule:
      return MTimeSlots;
    case QuestionType.FreeTextLine:
      return MTextField;
    case QuestionType.YesNo:
      return MYesNo;
    case QuestionType.SelectOne:
      return MSelect;
    case QuestionType.Number:
      return MTextField;
  }
  return undefined;
}

export default Vue.extend({
  name: 'm-questionnaire-form',
  components: {
    MLoader,
    ValidationObserver,
  },
  props: {
    ritualId: Number,
    editable: { type: Boolean, default: true },
    role: { type: String as PropType<MentoringRole | 'any'> },
  },
  data: () => ({
    currentStep: arabicToRoman(1),
    currentSubStepIndex: 0,
    ritualQuestionnaire: undefined as IRitualQuestionnaire,
    firstQuestionIndexShift: 1,
    answers: {},
    sidebarVisible: true,
    loading: false,
    completedSectionsIndex: 0,
    ritual: undefined as IRitual,
  }),
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    i18nPath() {
      return (
        this.ritualQuestionnaire?.attributes.i18nPath ??
        this.ritualQuestionnaire?.questionnaire.attributes.i18nPath ??
        `questionnaire.${this.ritualQuestionnaire?.questionnaire.title}`
      );
    },
    tr() {
      return (text: string) => {
        return this.$t(`${this.i18nPath}.${text}`);
      };
    },
    te() {
      return (text: string) => {
        return this.$te(`${this.i18nPath}.${text}`);
      };
    },
    actionTitle() {
      if (
        this.currentSubStepIndex + 1 === this.section.questions?.length &&
        this.currentStepIndex + 1 === this.sections.length
      ) {
        return this.$t('questionnaires.submit');
      } else {
        return this.$t('questionnaires.next');
      }
    },
    sections() {
      return this.ritualQuestionnaire?.questionnaire?.sections ?? [];
    },
    currentStepIndex() {
      return romanToArabic(this.currentStep) - 1;
    },
    section() {
      return this.sections[this.currentStepIndex] ?? {};
    },
    sectionTitle() {
      return this.tr(`${this.section.name}.title`);
    },
    question(): IQuestion & { props: Record<string, never>; component: unknown; classes: Record<string, boolean> } {
      const question: IQuestion = this.section?.questions?.[this.currentSubStepIndex] ?? {};
      const props = {};
      const classes = {};
      if (question.type === QuestionType.Submit) {
        props['title'] = this.ritual.attributes.title;
      }
      if (question.min != null) props['min'] = question.min;
      if (question.max != null) props['max'] = question.max;
      if (question.items != null) props['items'] = question.items;

      if (question.type === QuestionType.ChipGroup) {
        props['multiple'] = question.options?.multiple ?? true;
      }

      if ([QuestionType.Scale, QuestionType.ScaleMultiple].includes(question.type)) {
        if (props['min'] == null) props['min'] = 1;
        if (props['max'] == null) props['max'] = 5;
        props['boundaries'] = [
          this.tr(`${this.section.name}.boundaries.${question.name}.0`),
          this.tr(`${this.section.name}.boundaries.${question.name}.1`),
        ];
      }

      if (question.type === QuestionType.Schedule) {
        props['slotsOnly'] = true;
      }
      const rules = ValidationList.fromString(question.validation);

      if (question.type === QuestionType.FreeText && !rules.includes(KnownValidationRule.Max)) {
        rules.add(KnownValidationRule.Max, 10000);
      }

      if (question.type === QuestionType.Number) {
        rules.add(KnownValidationRule.Integer);
      }

      if (!rules.isEmpty()) {
        props['validationRules'] = rules.toString();
      }

      if (question.type === QuestionType.WeekdayExtended) {
        props['showContact'] = true;
      }

      if (this.i18nPath != null) {
        props['i18nPath'] = `${this.i18nPath}.${this.section.name}.items.${question.name}`;
      }

      if (question.placeholder != null) {
        props['placeholder'] = question.placeholder;
      } else if ([QuestionType.FreeText].includes(question.type)) {
        props['placeholder'] = this.$t('inputs.textPlaceholder');
      }

      return { ...question, props, component: getQuestionComponent(question), classes };
    },
    questionTitle() {
      return this.tr(`${this.section.name}.questions.${this.question.name}`);
    },
    questionHasDescription() {
      return this.te(`${this.section.name}.descriptions.${this.question.name}`);
    },
    questionDescription() {
      return this.tr(`${this.section.name}.descriptions.${this.question.name}`);
    },
    globalQuestionIndex() {
      return this.firstQuestionIndexShift + this.currentSubStepIndex;
    },
    image() {
      if (this.tenant?.attributes.theme.image) {
        return this.tenant?.attributes.theme.image;
      }
      return require('@/assets/logo.svg');
    },
    showBackButton() {
      return !(this.currentStepIndex === 0 && this.currentSubStepIndex === 0);
    },
    showQuestionTitle() {
      return this.question?.type !== QuestionType.Submit;
    },
  },
  watch: {
    currentStepIndex(value: number) {
      const count = this.sections[value]?.questions?.length - 1 ?? 0;
      if (value < this.completedSectionsIndex) {
        this.currentSubStepIndex = count;
      } else {
        this.currentSubStepIndex = 0;
      }

      this.firstQuestionIndexShift = this.sections
        .slice(0, value)
        .reduce((pV, cV) => pV + cV.questions?.length ?? 0, 1);
    },
  },
  async mounted() {
    if (!this.ritualId) {
      this.tryToClose();
      return;
    }

    this.loading = true;

    try {
      this.ritual = await this.$store.dispatch(new LoadRitual({ id: this.ritualId }));
      if (!this.ritual) {
        this.tryToClose();
        return;
      }

      const ritualQuestionnaire: IRitualQuestionnaire = await this.$store.dispatch(
        new LoadRitualQuestionnaire({ id: this.ritualId }, this.role),
      );
      if (!ritualQuestionnaire) {
        this.tryToClose();
        return;
      }

      const trPath = `questionnaire.${ritualQuestionnaire.questionnaire.title}`;
      const answers = {};
      ritualQuestionnaire?.questionnaire?.sections?.forEach((section) => {
        answers[section.name] = {};

        section.questions?.forEach((question) => {
          let initialValue = undefined;

          if ([SyncDirection.Pull, SyncDirection.Sync].includes(question.sync?.direction)) {
            switch (question.sync.field) {
              case SyncField.About:
                initialValue = this.me?.attributes.about;
                break;
              case SyncField.Company:
                initialValue = this.me?.attributes.company;
                break;
              case SyncField.MeetingLocation:
                initialValue =
                  this.me?.attributes?.mentoring?.meetingLocations
                    ?.map((location) => question.items.findIndex((item) => item === location))
                    .filter((item) => item !== -1) ?? [];
                break;
              case SyncField.Custom:
                if (question.sync.name) {
                  initialValue = this.me?.attributes.custom?.[question.sync.name];
                }
                break;
              case SyncField.Expertise: {
                const tags = question.items.map((name) =>
                  this.$t(`${trPath}.${section.name}.items.${question.name}.${name}`),
                );
                const itemIndexes = this.me?.expertiseTags?.map((tag) => tags.indexOf(tag.name)) ?? [];
                initialValue = itemIndexes.filter((index) => index !== -1);
                break;
              }
              case SyncField.Interests: {
                const tags = question.items.map((name) =>
                  this.$t(`${trPath}.${section.name}.items.${question.name}.${name}`),
                );
                const itemIndexes = this.me?.interestTags?.map((tag) => tags.indexOf(tag.name)) ?? [];
                initialValue = itemIndexes.filter((index) => index !== -1);
                break;
              }
              case SyncField.Position:
                initialValue = this.me?.attributes.jobTitle;
                break;
            }
          }
          answers[section.name][question.name] = initialValue;
        });
      });
      this.answers = answers;
      this.ritualQuestionnaire = ritualQuestionnaire;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    arabicToRoman,
    tryToClose() {
      this.$emit('onNext');
    },
    async goBack() {
      if (this.currentSubStepIndex > 0) {
        this.currentSubStepIndex -= 1;
      } else if (this.currentStepIndex > 0) {
        this.currentStep = arabicToRoman(this.currentStepIndex);
      }
    },
    async goNext() {
      if (this.currentSubStepIndex + 1 < this.section.questions?.length) {
        ++this.currentSubStepIndex;
      } else if (this.currentStepIndex + 1 < this.sections.length) {
        this.currentStep = arabicToRoman(this.currentStepIndex + 2);
        if (this.completedSectionsIndex < this.currentStepIndex) {
          this.completedSectionsIndex = this.currentStepIndex;
        }
      } else {
        this.loading = true;
        try {
          await this.$store.dispatch(
            new SendQuestionnaireAnswers({ id: this.ritualId }, this.ritualQuestionnaire, this.answers),
          );
          this.tryToClose();
        } finally {
          this.loading = false;
        }
      }
    },
    selectQuestion(index: string | number) {
      if (this.editable && Number(index) !== this.currentSubStepIndex) {
        this.currentSubStepIndex = Number(index);
      }
      this.sidebarVisible = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.m-questionnaire-container {
  background-color: var(--v-white-base);
  display: flex;
  height: 100%;
  width: 100%;
  max-height: 100vh;
}

.m-questionnaire-stepper {
  max-width: 300px;
  max-height: 100vh;
  height: 100%;

  a {
    text-decoration: none;
    color: unset;
  }

  li:hover {
    text-shadow: 0 0 0 var(--v-primary-base);
  }

  :deep(.v-stepper__content:not(:last-child)) {
    border-left: 1px solid var(--v-primary-base) !important;
  }
}

.m-questionnaire-content-container {
  display: flex;
  justify-content: center;
  max-height: 100vh;
}

.m-questionnaire-content {
  max-width: 910px;
  display: flex;
  flex-direction: column;
  max-height: inherit;

  &__header {
    height: 120px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--v-lightgray-base);
    @media #{map-get($display-breakpoints, 'xs-only')} {
      justify-content: center;
    }
  }

  &__title {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      text-align: center;
      align-items: center;
    }
  }

  &__button_container {
    width: 100%;
    max-width: 800px;
  }

  &__question {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;

    @media #{map-get($display-breakpoints, 'xs-only')} {
      text-align: center;
    }

    &__component {
      width: 100%;
    }

    &__component-container {
      height: fit-content;
      padding: 25px;
      width: 100%;
      max-width: 800px;
    }

    &__container {
      overflow: auto;
      max-height: inherit;
      height: fit-content;
      width: 100%;
      max-width: 800px;
    }
  }
}

.m-section-stepper-step {
  max-width: 300px;
  width: 100%;
}

.m-section-number {
  padding: 5px;
  aspect-ratio: 1;
  background-color: var(--v-primary-base);
  color: var(--v-white-base);
  border-radius: 50%;
  height: 39px;
  width: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.m-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  border-bottom: 1px solid var(--v-lightgray-base);
}
</style>
