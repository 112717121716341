<template>
  <div :class="{ 'flex-row-reverse': right }" class="d-flex align-start">
    <m-user-avatar :user="user" />
    <div :class="right ? 'ml-10 mr-2' : 'mr-10 ml-2'" class="flex-grow-1">
      <div class="m-mentoring-message-message keep-new-line" v-html="text" />
      <p :class="{ 'text-right': right }" class="m-mentoring-message-date mt-2">
        {{ formattedTime }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { MUserAvatar } from '@/components/MUserAvatar';
import { mapGetters } from 'vuex';
import { IMentoringSessionMember, IMentoringSessionMessage } from '@mentessa/types';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'm-mentoring-message',
  components: { MUserAvatar },
  props: {
    message: { type: Object as PropType<IMentoringSessionMessage> },
    right: { type: Boolean, default: false },
    member: { type: Object as PropType<IMentoringSessionMember> },
  },
  computed: {
    ...mapGetters('ui', {
      formatInUserTimeZone: 'formatInUserTimeZone',
    }),
    formattedTime() {
      return this.formatInUserTimeZone(this.message.createdAt, 'MMMM d, yyyy H:mm');
    },
    text() {
      return this.$marked(this.message.text).trim();
    },
    user() {
      return this.member?.user;
    },
  },
});
</script>

<style lang="scss" scoped>
.m-mentoring-message-message {
  padding: 8px 12px;
  border: 1px solid var(--v-lightgray-base);
  border-radius: 4px;

  :deep(p) {
    margin: 0;
  }
}

.m-mentoring-message-date {
  color: var(--v-lightgray-base);
}
</style>
