<template>
  <div :style="style" class="d-flex align-center m-tenant-preview" sentry-tag="tenant-preview-pure">
    <v-img
      v-if="!noImage"
      :height="imageSize"
      :max-width="imageSize"
      :src="image"
      :style="imageStyle"
      :width="imageSize"
      class="mr-4"
      contain
    />
    <p v-if="!imageOnly" :class="{ 'font-weight-bold': bold }" class="text-truncate my-0">{{ title }}</p>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { TenantEntity } from '@mentessa/types';

export default Vue.extend({
  name: 'm-tenant-preview',
  props: {
    tenant: Object as PropType<TenantEntity>,
    maxWidth: { type: String, default: '270px' },
    bold: { type: Boolean, default: false },
    mini: { type: Boolean, default: false },
    noImage: { type: Boolean, default: false },
    imageOnly: { type: Boolean, default: false },
  },
  computed: {
    style() {
      return {
        '--preview-max-width': this.maxWidth,
        width: '100%',
      };
    },
    imageStyle() {
      return {
        left: this.mini ? '-4px' : '0',
      };
    },
    image() {
      if (this.tenant?.attributes.theme.image) {
        return this.tenant?.attributes.theme.image;
      }
      return require('@/assets/logo.svg');
    },
    title() {
      return this.tenant?.attributes.theme.title ?? '';
    },
    imageSize() {
      return this.mini ? 24 : 32;
    },
  },
});
</script>

<style lang="scss" scoped>
.m-tenant-preview {
  height: 36px;
  min-width: 120px;
  max-width: var(--preview-max-width);
}
</style>
