import Vue from 'vue';
import VueRouter from 'vue-router';
import { app, AppType } from '@/config';
import { routes } from './use';
import store from '../store';
import { mentoringRedirection } from '@/router/guards';
import { RouteName } from '@/router/types';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

let pausedResolve = null;
let pausedPromise = Promise.resolve();

router.beforeEach(async (to, from, next) => {
  await pausedPromise;

  if (app.type === AppType.UseCommunity) {
    const user = store.state.users.me;

    if (
      to.name !== RouteName.Community &&
      store.getters['tenant/isCommunityRulesAvailable'] &&
      store.getters['tenant/isCommunityRulesRequired'] &&
      !user?.rulesAccepted
    ) {
      return next({ name: RouteName.Community });
    }
  }

  if (to.name === 'mentoring') {
    mentoringRedirection(to, from, next);
  } else {
    next();
  }
});

export function pause(): void {
  if (!pausedResolve) {
    console.log('Pause routing');
    pausedPromise = new Promise((resolve) => (pausedResolve = resolve));
  }
}

export function resume(): void {
  if (pausedResolve) {
    console.log('Resume routing');
    pausedResolve();
    pausedResolve = null;
  }
}

pause();

export default router;
