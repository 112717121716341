<template>
  <v-dialog :value="value" max-width="600px" sentry-tag="questionnaire-dialog" @input="$emit('input', $event)">
    <div class="m-close-icon-container">
      <a class="m-close-icon" sentry-tag="questionnaire-dialog.button.close" @click="$emit('input', false)">
        <v-img contain height="24" src="@/assets/close-icon.svg" width="24" />
      </a>
    </div>
    <v-sheet class="pa-14">
      <h2 class="text-center">{{ type }} Questionnaire</h2>
      <v-tabs background-color="white" v-model="currentTab">
        <v-tab sentry-tag="settings-debug.tab.questionnaires.rawJson">Raw Json</v-tab>
        <v-tab sentry-tag="settings-debug.tab.questionnaires.form">Form</v-tab>
      </v-tabs>
      <v-tabs-items v-model="currentTab">
        <v-tab-item>
          <m-text-area class="mt-4" v-model="rawQuestionnaireDto" label="QuestionnaireDto" />
          <v-row class="justify-center ma-4">
            <v-btn
              :loading="loading"
              color="button"
              large
              depressed
              sentry-tag="questionnaire-dialog.button.save"
              @click="saveQuestionnaire"
            >
              {{ type }} Questionnaire
            </v-btn>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <validation-observer v-slot="{ invalid }">
            <div v-if="step === 0 && !manageTranslations">
              <m-text-field
                class="mt-4"
                :value="questionnaireDto.title"
                @input="handleChangeQuestionnaireName($event)"
                :label="'Questionnaire Name'"
                validation-rules="required|lowercaseUnderscore"
                sentry-tag="questionnaire-dialog.input.title"
              />
              <m-text-field
                class="mt-4"
                v-model="questionnaireDto.description"
                :label="'Questionnaire Description'"
                validation-rules="required"
                sentry-tag="questionnaire-dialog.input.description"
              />
              <m-text-field
                class="mt-4"
                v-model="questionnaireDto.attributes.i18nPath"
                :label="'i18nPath'"
                sentry-tag="questionnaire-dialog.input.i18nPath"
              />
              <v-btn color="primary" v-if="questionnaireDto.sections.length <= 1" class="ma-4" @click="addSection(0)"
                >Add Section
              </v-btn>
              <template v-for="(section, sectionIndex) in questionnaireDto.sections">
                <v-row :key="sectionIndex" style="justify-content: center; align-items: center" class="mt-4">
                  <v-col>
                    <m-text-field
                      :value="section.name"
                      @input="handleChangeSectionName(sectionIndex, $event)"
                      :label="`Section ${sectionIndex + 1} Name`"
                      validation-rules="required|lowercaseUnderscore"
                      :disabled="sectionIndex === questionnaireDto.sections.length - 1"
                    />
                  </v-col>
                  <v-col cols="1" style="padding: 0" v-if="sectionIndex !== questionnaireDto.sections.length - 1">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon @click="removeSection(sectionIndex)">
                          <v-icon color="red">mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Remove Section</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="1" style="padding: 0" v-if="sectionIndex !== questionnaireDto.sections.length - 1">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon @click="addSection(sectionIndex + 1)">
                          <v-icon color="primary">mdi-plus-circle-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Add Section Below</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </template>
            </div>
            <div v-else-if="!manageTranslations">
              <div @click="step -= 1" style="cursor: pointer" class="m-back-button-container ma-4">
                <v-img contain height="24" src="@/assets/arrow-left-circle.svg" width="24" />
                <div class="text--text text--lighten-4">Back</div>
              </div>
              <v-row style="justify-content: center; align-items: center">
                <v-col>
                  <h3>{{ questionnaireDto.sections[step - 1].name }} section</h3>
                </v-col>
                <v-col
                  style="text-align: right"
                  v-if="
                    !questionnaireDto.sections[step - 1].questions ||
                    questionnaireDto.sections[step - 1].questions.length === 0
                  "
                >
                  <v-btn color="primary" @click="addQuestion(step - 1, 0)">Add Question</v-btn>
                </v-col>
                <v-col>
                  <v-btn color="green" @click="handleManageTranslations()">Section Translation</v-btn>
                </v-col>
              </v-row>
              <template v-for="(question, questionIndex) in questionnaireDto.sections[step - 1].questions">
                <div :key="questionIndex">
                  <m-text-field
                    class="mt-4"
                    :value="question.name"
                    @input="handleChangeQuestionName(questionIndex, $event)"
                    :label="`Question ${questionIndex + 1} Name`"
                    validation-rules="required|lowercaseUnderscore"
                    :disabled="step === questionnaireDto.sections.length"
                  />
                  <m-select
                    class="mt-4"
                    v-if="step !== questionnaireDto.sections.length"
                    v-model="question.type"
                    :items="questionTypes"
                    :label="`Question ${questionIndex + 1} Type`"
                    validation-rules="required"
                    @change="handleQuestionTypeChange(question, questionIndex)"
                  />
                  <m-text-field
                    v-else
                    class="mt-4"
                    v-model="question.type"
                    :label="`Question ${questionIndex + 1} Type`"
                    :disabled="step === questionnaireDto.sections.length"
                    validation-rules="required"
                  />
                  <v-combobox
                    v-model="question.items"
                    v-if="shouldHaveItems(question)"
                    chips
                    deletable-chips
                    hide-details
                    :label="`Question ${questionIndex + 1} Items`"
                    multiple
                    outlined
                    class="mt-4"
                  ></v-combobox>
                  <m-text-field
                    v-if="step !== questionnaireDto.sections.length"
                    class="mt-4"
                    v-model="question.min"
                    :label="`Question ${questionIndex + 1} Min`"
                    type="number"
                  />
                  <m-text-field
                    class="mt-4"
                    v-if="step !== questionnaireDto.sections.length"
                    v-model="question.max"
                    :label="`Question ${questionIndex + 1} Max`"
                    type="number"
                  />
                  <m-select
                    v-if="canSync(question)"
                    v-model="question.sync.field"
                    class="mt-4"
                    :items="syncFieldOptions(question.type)"
                    :label="`Question ${questionIndex + 1} Sync Field`"
                  />
                  <m-select
                    class="mt-4"
                    v-if="canSync(question)"
                    v-model="question.sync.direction"
                    :items="syncDirectionOptions"
                    :label="`Question ${questionIndex + 1} Sync Direction`"
                  />
                  <m-text-field
                    v-if="question.sync?.field === 'custom'"
                    class="mt-4"
                    v-model="question.sync.name"
                    :label="`Question ${questionIndex + 1} Sync Name`"
                  />
                  <v-checkbox
                    @change="handleQuestionRequired(questionIndex, $event)"
                    v-if="step !== questionnaireDto.sections.length"
                    :label="`Question ${questionIndex + 1} Required`"
                  />
                  <v-row style="justify-content: center; align-items: center" class="mt-2">
                    <v-col v-if="step !== questionnaireDto.sections.length">
                      <v-btn color="red" @click="removeQuestion(step - 1, questionIndex)"
                        >Remove Question {{ questionIndex + 1 }}
                      </v-btn>
                    </v-col>
                    <v-col>
                      <v-btn
                        color="green"
                        @click="handleManageTranslations(questionIndex)"
                        :disabled="!question.name || !question.type"
                        >Question Translations
                      </v-btn>
                    </v-col>
                    <v-col style="text-align: right" v-if="step !== questionnaireDto.sections.length">
                      <v-btn color="primary" @click="addQuestion(step - 1, questionIndex + 1)">Add Question</v-btn>
                    </v-col>
                  </v-row>
                </div>
              </template>
            </div>
            <div v-else>
              <div
                @click="
                  manageTranslations = false;
                  currentQuestion = undefined;
                "
                style="cursor: pointer"
                class="m-back-button-container ma-4"
              >
                <v-img contain height="24" src="@/assets/arrow-left-circle.svg" width="24" />
                <div class="text--text text--lighten-4">Back</div>
              </div>
              <m-select v-model="locale" :items="locales" sentry-tag="translation-locale" class="mt-4" label="Locale">
                <template v-slot:selection="data">
                  <v-chip>
                    {{ nameForLang(data.item) }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  {{ nameForLang(data.item) }}
                </template>
              </m-select>
              <div v-if="step === questionnaireDto.sections.length && currentQuestion >= 0">
                <v-row style="justify-content: center; align-items: center">
                  <v-col>
                    <h3>{{ questionnaireDto.sections[step - 1].name }} section</h3>
                  </v-col>
                </v-row>
                <m-text-field
                  class="mt-4"
                  v-model="questionnaireDto.sections[step - 1].questions[currentQuestion].name"
                  :label="`Question Name`"
                  disabled
                />
                <m-text-field
                  class="mt-4"
                  v-model="
                    currentSectionTranslation().items.questions[
                      questionnaireDto.sections[step - 1].questions[currentQuestion].name
                    ]
                  "
                  :label="`Question`"
                />
                <m-text-field
                  class="mt-4"
                  v-model="
                    currentSectionTranslation().items[
                      questionnaireDto.sections[step - 1].questions[currentQuestion].name
                    ].title
                  "
                  :label="`Title`"
                />
                <m-text-area
                  class="mt-4"
                  v-model="
                    currentSectionTranslation().items[
                      questionnaireDto.sections[step - 1].questions[currentQuestion].name
                    ].content
                  "
                  :label="`Content`"
                />
              </div>
              <div v-else-if="step !== 0">
                <v-row style="justify-content: center; align-items: center">
                  <v-col>
                    <h3>{{ questionnaireDto.sections[step - 1].name }} section</h3>
                  </v-col>
                </v-row>
                <div v-if="currentQuestion >= 0">
                  <m-text-field
                    class="mt-4"
                    v-model="questionnaireDto.sections[step - 1].questions[currentQuestion].name"
                    :label="`Question ${currentQuestion + 1} Name`"
                    disabled
                  />
                  <m-text-field
                    class="mt-4"
                    v-model="
                      currentSectionTranslation().questions[
                        questionnaireDto.sections[step - 1].questions[currentQuestion].name
                      ]
                    "
                    :label="`Question ${currentQuestion + 1}`"
                  />
                  <template
                    v-for="(item, itemIndex) in questionnaireDto.sections[step - 1].questions[currentQuestion].items"
                  >
                    <div :key="itemIndex" class="mt-4">
                      <m-text-field
                        v-model="
                          currentSectionTranslation().items[
                            questionnaireDto.sections[step - 1].questions[currentQuestion].name
                          ][item]
                        "
                        :label="`${item}`"
                      />
                    </div>
                  </template>
                  <m-text-field
                    v-if="questionnaireDto.sections[step - 1].questions[currentQuestion].type?.includes('scale')"
                    class="mt-4"
                    v-model="
                      currentSectionTranslation().boundaries[
                        questionnaireDto.sections[step - 1].questions[currentQuestion].name
                      ][0]
                    "
                    :label="`Question ${currentQuestion + 1} Lower Boundary`"
                  />
                  <m-text-field
                    class="mt-4"
                    v-if="questionnaireDto.sections[step - 1].questions[currentQuestion].type?.includes('scale')"
                    v-model="
                      currentSectionTranslation().boundaries[
                        questionnaireDto.sections[step - 1].questions[currentQuestion].name
                      ][1]
                    "
                    :label="`Question ${currentQuestion + 1} Upper Boundary`"
                  />
                </div>
                <div v-else>
                  <m-text-field
                    class="mt-4"
                    v-model="
                      translations[locale][questionnaireDto.title][questionnaireDto.sections[step - 1].name].title
                    "
                    :label="`Section Title`"
                  />
                </div>
              </div>
              <div v-else>
                <m-text-field
                  class="mt-4"
                  v-model="translations[locale][questionnaireDto.title].title"
                  :label="'Questionnaire Title'"
                  sentry-tag="questionnaire-dialog.input.title.translation"
                />
              </div>
            </div>
            <v-row justify="center" v-if="!manageTranslations">
              <v-col>
                <v-btn color="green" v-if="step === 0" @click="handleManageTranslations" :disabled="invalid"
                  >Questionnaire Translation
                </v-btn>
              </v-col>
              <v-col style="text-align: right" v-if="step !== questionnaireDto.sections.length">
                <v-btn
                  :disabled="invalid"
                  :loading="loading"
                  color="primary"
                  large
                  sentry-tag="questionnaire-dialog.button.next"
                  @click="step += 1"
                >
                  Next
                </v-btn>
              </v-col>
              <v-col style="text-align: right" v-else>
                <v-btn
                  :disabled="invalid"
                  :loading="loading"
                  color="primary"
                  large
                  sentry-tag="questionnaire-dialog.button.save"
                  @click="saveQuestionnaire"
                >
                  {{ type }} Questionnaire
                </v-btn>
              </v-col>
            </v-row>
          </validation-observer>
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { ValidationObserver } from 'vee-validate';
import { MSelect, MTextArea, MTextField } from '@/components/Inputs';
import { IQuestion, IQuestionGroup, IQuestionnaire, QuestionType, SyncDirection, SyncField } from '@mentessa/types';
import { CreateQuestionnaire, UpdateQuestionnaire } from '@/store/rituals';
import { mapGetters } from 'vuex';
import { CreateLocaleContent, LoadLocaleContent, UpdateLocaleContent } from '@/store/ui';
import deepmerge from 'deepmerge';
import { deleteKeyInObject, replaceKeyInObject } from '@/utils/object';
import { KnownValidationRule, ValidationList } from '@/utils/validation';

export default Vue.extend({
  name: 'm-questionnaire-dialog',
  components: {
    ValidationObserver,
    MTextField,
    MSelect,
    MTextArea,
  },
  props: {
    value: Boolean,
    type: String,
    editedQuestionnaire: Object as PropType<IQuestionnaire>,
  },
  data: () => ({
    currentTab: undefined,
    loading: false,
    questionnaireDto: {
      title: '',
      description: '',
      attributes: {
        i18nPath: '',
      },
      sections: [],
    },
    translations: {},
    questionTypes: Object.values(QuestionType).filter((type) => type !== 'submit'),
    manageTranslations: false,
    step: 0,
    currentQuestion: undefined,
    locale: undefined,
    syncDirectionOptions: Object.values(SyncDirection),
  }),
  computed: {
    ...mapGetters('utils', {
      nameForLang: 'nameForLang',
    }),
    rawQuestionnaireDto: {
      get() {
        return JSON.stringify(this.questionnaireDto, undefined, 4);
      },
      set(value: string) {
        this.questionnaireDto = JSON.parse(value);
      },
    },
    locales() {
      return Object.keys(this.$i18n.messages);
    },
  },
  watch: {
    async value(value: boolean) {
      if (value) {
        this.locale = this.$i18n.locale;
        this.currentTab = undefined;
        this.step = 0;
        this.manageTranslations = false;
        if (this.type === 'Create') {
          this.questionnaireDto = {
            title: '',
            description: '',
            attributes: {
              i18nPath: '',
            },
            sections: [
              {
                guid: 1,
                name: 'result',
                questions: [{ guid: `1_1`, name: 'submit', type: 'submit' }],
              },
            ],
          };
          this.translations = {};
        } else {
          this.questionnaireDto = { ...this.editedQuestionnaire };
          this.questionnaireDto.sections.forEach((section) => {
            section.questions.forEach((question) => {
              question.sync = {};
            });
          });
          this.translations = { [this.locale]: {} };
          const localeContent = await this.LoadTranslations();
          this.translations[this.locale][this.questionnaireDto.title] =
            localeContent?.questionnaire?.[this.questionnaireDto.title];
        }
      }
    },
    async locale() {
      const questionnaireTitle = this.questionnaireDto.title;
      if (questionnaireTitle) {
        this.populateTranslations();
      }
      if (this.type === 'Edit') {
        const localeContent = await this.LoadTranslations();
        this.translations[this.locale][questionnaireTitle] =
          localeContent?.questionnaire?.[this.questionnaireDto.title] ??
          this.translations[this.locale][questionnaireTitle];
      }
    },
  },
  methods: {
    populateTranslations() {
      const questionnaireTitle = this.questionnaireDto.title;
      this.translations.en ??= {};
      this.translations[this.locale] ??= {};
      this.translations.en[questionnaireTitle] ??= {
        result: {
          items: {
            submit: {
              title: 'Almost done.. Last chance to edit answers! 🤓',
              content:
                'Thank you for completing your skills questionnaire.\nThis information will be used for calculating your mentoring match.\nPlease ensure that all fields have been filled in completely, because you won\'t be able to return to edit your answers once you click "Submit".',
            },
            questions: {
              submit: 'Submit',
            },
          },
          title: 'Complete Questionnaire',
        },
      };
      this.translations[this.locale][questionnaireTitle] ??= {
        result: {
          items: {
            submit: {},
            questions: {},
          },
        },
      };
      this.questionnaireDto.sections.forEach((section: IQuestionGroup) => {
        this.translations[this.locale][questionnaireTitle][section.name] ??= {
          questions: {},
          items: {},
          boundaries: {},
        };
      });
      if (this.step > 0) {
        const section = this.questionnaireDto.sections[this.step - 1];
        section.questions?.forEach((question: IQuestion) => {
          if (question.type?.includes('scale')) {
            this.translations[this.locale][questionnaireTitle][section.name].boundaries[question.name] ??= ['', ''];
          }
          if (question.items) {
            this.translations[this.locale][questionnaireTitle][section.name].items[question.name] ??= {};
          }
        });
      }
    },
    handleManageTranslations(questionIndex?: number) {
      this.currentQuestion = questionIndex;
      this.populateTranslations();
      this.manageTranslations = true;
    },
    replaceKeyInTranslations(paths: Array<string>, oldKey: string, newKey: string) {
      Object.keys(this.translations).forEach((key) => {
        this.translations[key] = replaceKeyInObject(this.translations[key], paths, oldKey, newKey);
      });
    },
    deleteKeyInTranslations(paths: Array<string>, oldKey: string) {
      Object.keys(this.translations).forEach((key) => {
        this.translations[key] = deleteKeyInObject(this.translations[key], paths, oldKey);
      });
    },
    handleChangeQuestionnaireName(event: string) {
      const questionnaireTitle = this.questionnaireDto.title;
      if (questionnaireTitle) {
        this.replaceKeyInTranslations([], questionnaireTitle, event);
      }
      this.questionnaireDto.title = event;
    },
    handleQuestionRequired(questionIndex: number, event: boolean) {
      const validationString = this.questionnaireDto.sections[this.step - 1].questions[questionIndex].validation;
      const validationList = ValidationList.fromString(validationString);
      if (event) {
        validationList.add(KnownValidationRule.Required);
      } else {
        validationList.remove(KnownValidationRule.Required);
      }
      this.questionnaireDto.sections[this.step - 1].questions[questionIndex].validation = validationList.toString();
    },
    handleChangeSectionName(sectionIndex: number, event: string) {
      const questionnaireTitle = this.questionnaireDto.title;
      const sectionName = this.questionnaireDto.sections[sectionIndex].name;
      if (sectionName) {
        this.replaceKeyInTranslations([`${questionnaireTitle}`], sectionName, event);
      }
      this.questionnaireDto.sections[sectionIndex].name = event;
    },
    handleChangeQuestionName(questionIndex: string, event: string) {
      const questionnaireTitle = this.questionnaireDto.title;
      const sectionName = this.questionnaireDto.sections[this.step - 1].name;
      const questionName = this.questionnaireDto.sections[this.step - 1].questions[questionIndex].name;
      if (questionName) {
        this.replaceKeyInTranslations(
          [
            `${questionnaireTitle}.${sectionName}.questions`,
            `${questionnaireTitle}.${sectionName}.items`,
            `${questionnaireTitle}.${sectionName}.boundaries`,
          ],
          questionName,
          event,
        );
      }
      this.questionnaireDto.sections[this.step - 1].questions[questionIndex].name = event;
    },
    handleQuestionTypeChange(question: IQuestion, questionIndex: number) {
      const questionnaireTitle = this.questionnaireDto.title;
      const sectionName = this.questionnaireDto.sections[this.step - 1].name;
      if (!this.shouldHaveItems(question)) {
        delete this.questionnaireDto.sections[this.step - 1].questions[questionIndex].items;
        this.deleteKeyInTranslations([`${questionnaireTitle}.${sectionName}.items`], question.name);
      }
      if (!question.type.includes('scale')) {
        delete this.questionnaireDto.sections[this.step - 1].questions[questionIndex].boundaries;
        this.deleteKeyInTranslations([`${questionnaireTitle}.${sectionName}.boundaries`], question.name);
      }
      this.questionnaireDto.sections[this.step - 1].questions[questionIndex].sync = {};
    },
    syncFieldOptions(type: QuestionType) {
      if ([QuestionType.FreeText, QuestionType.FreeTextLine].includes(type)) {
        return [SyncField.About, SyncField.Company, SyncField.Position, SyncField.Custom];
      }
      return [SyncField.Expertise, SyncField.Interests];
    },
    shouldHaveItems(question: IQuestion) {
      return question.type?.includes('tags') || question.type?.includes('chip') || question.type?.includes('multiple');
    },
    canSync(question: IQuestion) {
      const types = [QuestionType.FreeText, QuestionType.FreeTags, QuestionType.FreeSkillsTags, QuestionType.ChipGroup];
      return question.type && types.includes(question.type);
    },
    removeQuestion(sectionIndex: number, questionIndex: number) {
      const questionnaireTitle = this.questionnaireDto.title;
      const questionName = this.questionnaireDto.sections[sectionIndex].questions[questionIndex].name;
      const sectionName = this.questionnaireDto.sections[sectionIndex].name;
      this.questionnaireDto.sections[sectionIndex].questions.splice(questionIndex, 1);
      if (questionName) {
        this.deleteKeyInTranslations(
          [
            `${questionnaireTitle}.${sectionName}.questions`,
            `${questionnaireTitle}.${sectionName}.items`,
            `${questionnaireTitle}.${sectionName}.boundaries`,
          ],
          questionName,
        );
      }
    },
    currentSectionTranslation() {
      return this.translations[this.locale][this.questionnaireDto.title][
        this.questionnaireDto.sections[this.step - 1].name
      ];
    },
    removeSection(sectionIndex: number) {
      const questionnaireTitle = this.questionnaireDto.title;
      const sectionName = this.questionnaireDto.sections[sectionIndex].name;
      this.questionnaireDto.sections.splice(sectionIndex, 1);
      if (sectionName) {
        this.deleteKeyInTranslations([`${questionnaireTitle}`], sectionName);
      }
    },
    addSection(sectionIndex: number) {
      this.questionnaireDto.sections.splice(sectionIndex, 0, { guid: `${this.questionnaireDto.sections.length + 1}` });
    },
    addQuestion(sectionIndex: number, questionIndex: number) {
      let questions = this.questionnaireDto.sections[sectionIndex].questions ?? [];
      questions.splice(questionIndex, 0, { guid: `${sectionIndex + 1}_${questions.length + 1}`, sync: {} });
      this.$set(this.questionnaireDto.sections[sectionIndex], 'questions', questions);
    },
    async LoadTranslations(locale?: string) {
      return await this.$store.dispatch(new LoadLocaleContent(locale ?? this.locale));
    },
    async saveQuestionnaire() {
      this.loading = true;
      try {
        if (this.type === 'Create') {
          await this.$store.dispatch(new CreateQuestionnaire(this.questionnaireDto));
        } else {
          await this.$store.dispatch(new UpdateQuestionnaire(this.questionnaireDto));
        }

        for (const key of Object.keys(this.translations)) {
          const localeContent = await this.LoadTranslations(key);
          if (localeContent) {
            const mergedLocale = deepmerge(localeContent, { questionnaire: this.translations[key] });
            await this.$store.dispatch(new UpdateLocaleContent(key, mergedLocale));
          } else {
            await this.$store.dispatch(new CreateLocaleContent(key, { questionnaire: this.translations[key] }));
          }
        }
      } finally {
        this.loading = false;
      }
      this.$emit('input', false);
      this.$emit('questionnaire-create-edit');
    },
  },
});
</script>

<style lang="scss" scoped>
.close-icon {
  position: absolute;
  top: 18px;
  right: 18px;
}

.m-close-icon-container {
  display: flex;
  justify-content: flex-end;
}

.m-close-icon {
  position: absolute;
  margin: 18px;
}

.m-back-button-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  width: fit-content;
}
</style>
