<template>
  <div>
    <m-headline :title="$t('settings.title')" />
    <v-tabs show-arrows>
      <v-tab v-if="isCustomizationAvailable" :to="communityRoute" sentry-tag="settings.tab.community">
        {{ $t('settings.community.title') }}
      </v-tab>
      <v-tab v-if="isMembersAvailable" :to="membersRoute" sentry-tag="settings.tab.members">
        {{ $t('settings.members.title') }}
      </v-tab>
      <v-tab v-if="isMembersAvailable" :to="rolesRoute" sentry-tag="settings.tab.roles">
        {{ $t('settings.roles.title') }}
      </v-tab>
      <v-tab v-if="isInvitesAvailable" :to="invitationsRoute" sentry-tag="settings.tab.invitations">
        {{ $t('settings.invitations.title') }}
      </v-tab>
      <v-tab v-if="isIntegrationsEnabled" :to="integrationsRoute" sentry-tag="settings.tab.integrations">
        {{ $t('settings.integrations.title') }}
      </v-tab>

      <template v-if="isDebugAvailable">
        <v-tab v-if="isMobileAvailable" :to="mobileRoute" sentry-tag="settings.tab.mobile">
          {{ $t('settings.mobile.title') }}
        </v-tab>

        <v-tab :to="ritualsRoute" class="red--text" sentry-tag="settings.tab.rituals">
          {{ $t('settings.rituals.title') }}
        </v-tab>
        <v-tab :to="debugRoute" class="red--text" sentry-tag="settings.tab.debug">
          {{ $t('settings.debug.title') }}
        </v-tab>
        <v-tab :to="featuresRoute" class="red--text" sentry-tag="settings.tab.features">
          {{ $t('settings.features.title') }}
        </v-tab>
      </template>
    </v-tabs>
    <router-view />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { RouteName } from '@/router/types';
import MHeadline from '@/components/MHeadline';

export default Vue.extend({
  name: 'm-settings',
  components: { MHeadline },

  data: () => ({
    border: true,
    communityRoute: { name: RouteName.SettingsCommunity },
    membersRoute: { name: RouteName.SettingsMembers },
    rolesRoute: { name: RouteName.SettingsRoles },
    invitationsRoute: { name: RouteName.SettingsInvitations },
    ritualsRoute: { name: RouteName.SettingsRituals },
    debugRoute: { name: RouteName.SettingsDebug },
    featuresRoute: { name: RouteName.SettingsFeatures },
    integrationsRoute: { name: RouteName.SettingsIntegrations },
    mobileRoute: { name: RouteName.SettingsMobile },
  }),

  computed: {
    ...mapGetters('tenant', {
      isCustomizationAvailable: 'isCustomizationAvailable',
      isMembersAvailable: 'isMembersAvailable',
      isInvitesAvailable: 'isInvitesAvailable',
      isRitualsAvailable: 'isRitualsAvailable',
      isDebugAvailable: 'isDebugAvailable',
      isResourcesEnabled: 'isResourcesEnabled',
      isIntegrationsEnabled: 'isIntegrationsEnabled',
      isNpsAndNpsManagingAvailable: 'isNpsAndNpsManagingAvailable',
      isMobileAvailable: 'isMobileEnabled',
    }),
  },
  mounted() {
    if (this.$route.name === RouteName.Settings) {
      if (this.isCustomizationAvailable) {
        this.$router.replace(this.communityRoute);
      } else if (this.isInvitesAvailable) {
        this.$router.replace(this.membersRoute);
      } else if (this.isRitualsAvailable) {
        this.$router.replace(this.ritualsRoute);
      } else if (this.isDebugAvailable) {
        this.$router.replace(this.debugRoute);
      }
    }
  },
});
</script>

<style lang="scss" scoped>
//
</style>
