<template>
  <validation-observer v-slot="{ invalid }" class="ma-4">
    <h2 class="text-center mb-4">{{ $t('dialogs.invitation.name.title') }}</h2>
    <m-text-field
      v-model="firstName"
      :label="$t('profile.personal.firstName')"
      class="mb-4"
      validation-rules="required|max:150"
    />
    <m-text-field v-model="lastName" :label="$t('profile.personal.lastName')" validation-rules="required|max:150" />
    <p class="text-center text--secondary my-8">
      {{ $t('dialogs.invitation.name.note') }}
    </p>
    <v-row class="justify-center mt-4">
      <v-btn :disabled="invalid" color="button" depressed large min-width="128" @click="updateName">
        {{ $t('dialogs.invitation.name.cta') }}
      </v-btn>
    </v-row>
  </validation-observer>
</template>

<script lang="ts">
import Vue from 'vue';
import { MTextField } from '@/components/Inputs';
import { ValidationObserver } from 'vee-validate';
import { UpdateUserFullName, UsersState } from '@/store/users';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';

export default Vue.extend({
  name: 'm-invitation-name-form',
  components: { MTextField, ValidationObserver },
  data: () => ({
    firstName: undefined,
    lastName: undefined,
    loading: false,
  }),
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    ...mapState<TenantState>('tenant', {
      forceTimeZone: (state: TenantState) => state.tenant?.attributes?.forceTenantTimeZone ?? false,
      forcedTz: (state: TenantState) => state.tenant?.tz,
    }),
  },
  mounted() {
    this.firstName = this.me?.identity?.attributes?.firstName;
    this.lastName = this.me?.identity?.attributes?.lastName;
  },
  methods: {
    async updateName() {
      try {
        this.loading = true;
        const tz = this.forceTimeZone
          ? this.forcedTz
          : this.me.tz ?? this.me.attributes.tz ?? Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'CET';
        if (await this.$store.dispatch(new UpdateUserFullName(this.firstName, this.lastName, tz))) {
          await this.$emit('onNext');
        }
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
