<template>
  <v-menu offset-y top left>
    <template v-slot:activator="{ on }">
      <v-btn color="discord" fab v-on="on" fixed bottom right class="discord-btn" />
    </template>
    <iframe
      :src="`https://discord.com/widget?id=${serverId}&theme=dark`"
      width="350"
      height="500"
      allowtransparency="true"
      frameborder="0"
      sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
    ></iframe>
  </v-menu>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'discord-widget',
  props: {
    serverId: String,
  },
});
</script>
<style scoped>
.v-menu__content {
  box-shadow: none;
}

.discord-btn {
  background-image: url('@/assets/tools/discord.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
