<template>
  <div>
    <v-tabs class="m-mentoring-tabs" show-arrows>
      <v-tab v-for="(session, index) of sessions" :key="`session-${index}`" :to="sessionRoute(session.data)">
        {{ sessionName(session.data) }}
        <v-img
          v-if="isInProgress(session.data)"
          contain
          src="@/assets/close-icon.svg"
          class="close-icon"
          @click="closeSession(session.data)"
        />
      </v-tab>
    </v-tabs>
    <router-view />
    <m-close-mentoring-session-dialog v-model="closeMentoringSessionDialogOpened" :session="sessionToClose" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { RouteName } from '@/router/types';
import { MentoringState } from '@/store/mentoring';
import { IMentoringSession, MentoringSessionStatus } from '@mentessa/types';
import { UsersState } from '@/store/users';
import { getFullName } from '@/utils/user';
import { RawLocation, Route } from 'vue-router';
import { MCloseMentoringSessionDialog } from '@/components/Dialogs';

export default Vue.extend({
  name: 'm-mentoring-sessions',
  props: {
    sessionsStatus: { type: String },
  },
  data: () => ({ closeMentoringSessionDialogOpened: false, sessionToClose: undefined }),
  components: {
    MCloseMentoringSessionDialog,
  },
  computed: {
    ...mapState<MentoringState>('mentoring', {
      state: (state: MentoringState) => state.sessions.state,
    }),
    ...mapGetters('mentoring', {
      allSessions: 'allSessions',
      sessionsByStatus: 'sessionsByStatus',
    }),
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    withRedirectionRoutes() {
      return [RouteName.ClosedMentoring, RouteName.ActiveMentoring];
    },
    sessions() {
      return this.sessionsStatus ? this.sessionsByStatus(this.sessionsStatus) : this.allSessions;
    },
  },
  watch: {
    $route(route: Route) {
      if (this.withRedirectionRoutes.includes(route.name)) {
        this.$router.push(this.sessionRoute(this.pickDefaultSession()));
      }
    },
  },
  mounted() {
    if (this.withRedirectionRoutes.includes(this.$route.name)) {
      this.$router.push(this.sessionRoute(this.pickDefaultSession()));
    }
  },
  methods: {
    sessionName(session: IMentoringSession) {
      const sessionWith = session.members.find((member) => member.user?.id !== this.me.id);
      return getFullName(sessionWith?.user);
    },
    sessionRoute(session: IMentoringSession): RawLocation {
      switch (session.status) {
        case MentoringSessionStatus.Progress:
          return { name: RouteName.ActiveMentoringSession, params: { sessionId: session.id.toString() } };
        case MentoringSessionStatus.Finished:
          return { name: RouteName.ClosedMentoringSession, params: { sessionId: session.id.toString() } };
        default:
          return null;
      }
    },
    pickDefaultSession() {
      if (this.sessions.length === 0) {
        return null;
      }

      const defaultSession =
        this.sessions.filter((s) => s.data.status === MentoringSessionStatus.Progress)[0] ?? this.sessions[0];

      return defaultSession.data;
    },
    closeSession(session: IMentoringSession) {
      this.closeMentoringSessionDialogOpened = true;
      this.sessionToClose = session;
    },
    isInProgress(session: IMentoringSession) {
      return session.status === MentoringSessionStatus.Progress;
    },
  },
});
</script>

<style lang="scss" scoped>
:deep(.v-slide-group) {
  .v-slide-group__wrapper {
    mask: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 5%, rgba(0, 0, 0, 1) 95%, rgba(0, 0, 0, 0) 100%);
  }

  .v-slide-group__next,
  .v-slide-group__prev {
    min-width: 24px;
  }
}

.close-icon {
  width: 15px;
  height: 15px;
  margin-left: 15px;
  cursor: pointer;
  visibility: hidden;
}

.v-tab:hover .close-icon {
  visibility: visible;
}
</style>
