import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { InvitesActions, InvitesMutations, InvitesState } from '@/store/invites/types';
import { api } from '@/store/api';
import { ShowErrorNotification } from '@/store/notifications';
import i18n from '@/plugins/i18n';

export const actions: ActionTree<InvitesState, RootState> = {
  async [InvitesActions.LoadInvites]({ commit, dispatch }, { page, kind }) {
    try {
      const response = await api.get('invites', { params: { page, kind } });
      const invites = response?.data;
      commit(InvitesMutations.SetInvites, invites);
    } catch (e) {
      console.error('Error while LoadInvites.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [InvitesActions.LoadInvite]({ commit, dispatch }, { guid }) {
    try {
      const response = await api.post('invites/check', { guid });
      const invite = response?.data;
      commit(InvitesMutations.SetCurrentInvite, invite);
      return invite ? invite : undefined;
    } catch (e) {
      if (e.response?.status !== 404) {
        console.error('Error while LoadInvite.', e);
        await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
          root: true,
        });
      }
      return false;
    }
  },

  async [InvitesActions.CreateInvite]({ dispatch, commit }, { invite }) {
    try {
      const response = await api.post('invites', invite);
      commit(InvitesMutations.AppendInvite, response.data);
      return response?.status === 201 ?? false;
    } catch (e) {
      console.error('Error while CreateInvite.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [InvitesActions.DeleteInvite]({ dispatch }, { invite }) {
    try {
      await api.delete(`invites/${invite.id}`);
    } catch (e) {
      console.error('Error while DeleteInvite.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [InvitesActions.DisableInvite]({ dispatch }, { invite }) {
    try {
      await api.put(`invites/${invite.id}`, { isValid: false });
    } catch (e) {
      console.error('Error while DisableInvite.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [InvitesActions.UseInvite]({ dispatch }, { invite, email }) {
    try {
      const response = await api.post('invites/use', { guid: invite.guid, email });
      return response?.status === 201 ?? false;
    } catch (e) {
      console.error('Error while UseInvite.', e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
};
