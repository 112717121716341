<template>
  <div class="m-save-profile-button-wrap" :class="{ 'with-completion-banner': !hideCompletionBanner }">
    <v-btn :disabled="disabled" depressed color="button" @click="$emit('onButtonClick')">
      {{ title }}
    </v-btn>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { UsersState } from '@/store/users';

export default Vue.extend({
  name: 'm-save-profile-button',
  props: {
    disabled: { type: Boolean, default: true },
  },
  computed: {
    ...mapGetters('tenant', {
      hideCompletionBanner: 'hideCompletionBanner',
    }),
    ...mapState<UsersState>('users', {
      userComplete: (state: UsersState) => state.me?.complete ?? false,
    }),
    title() {
      return this.userComplete ? this.$t('settings.community.save') : this.$t('profileForm.buttonNext');
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.m-save-profile-button-wrap {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .v-btn {
    position: fixed;
    top: 236px;
    z-index: 2;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      margin-right: 16px;
      top: calc(100% - 120px);
    }
  }
}
</style>
