<template>
  <div>
    <v-switch
      v-model="localEnabled"
      :label="$t('profile.mentoring.enabled')"
      hide-details="auto"
      sentry-tag="time-slots.switcher.mentoringAvailable"
    >
      <template v-slot:label>
        <span>{{ $t('timeSlots.availableForMentoring') }}</span>
      </template>
    </v-switch>
    <template>
      <p class="text--text text--lighten-4 mt-4 mb-0 text-left">{{ $t('profile.mentoring.role.title') }}</p>
      <v-chip-group v-model="localMentoringRoles" column multiple>
        <v-chip
          v-for="(role, index) in mentoringRoles"
          :key="`role-${index}`"
          :disabled="!localEnabled"
          :value="role"
          filter
          >{{ $t(`mentoring.role.${role}`) }}
        </v-chip>
      </v-chip-group>
      <p class="text--text text--lighten-4 mt-4 mb-0 text-left">{{ $t('profile.mentoring.meeting_location.title') }}</p>
      <v-chip-group v-model="localMeetingLocations" column multiple>
        <v-chip
          v-for="(location, index) in meetingLocations"
          :key="`location-${index}`"
          :disabled="!localEnabled"
          :value="location"
          filter
          >{{ $t(`profile.mentoring.meeting_location.items.${location}`) }}
        </v-chip>
      </v-chip-group>
    </template>
    <div v-if="false" class="m-contact d-flex flex-column my-6 mx-auto">
      <v-switch
        :label="$t('timeSlots.additionalContactEnabled')"
        :value="contact.enabled"
        class="m-weekday-switch"
        @change="$emit('onContactChanged', { ...contact, enabled: $event })"
      />
      <m-text-field
        :label="$t('timeSlots.additionalContactEmail')"
        :placeholder="$t('timeSlots.additionalContactPlaceholder')"
        :value="contact.email"
        class="m-contact-textfield my-6"
        @input="$emit('onContactChanged', { ...contact, email: $event })"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { ITenant, MeetingLocation, MentoringOptions, MentoringOptionsContact, MentoringRole } from '@mentessa/types';
import { MTextField } from '@/components/Inputs';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';

export default Vue.extend({
  name: 'm-mentoring-availability',
  props: {
    value: { type: Object as PropType<MentoringOptions> },
    contact: { type: Object as PropType<MentoringOptionsContact>, default: () => ({ enabled: false }) },
  },
  components: { MTextField },
  data: () => ({
    mentoringRoles: Object.values(MentoringRole),
    meetingLocations: Object.values(MeetingLocation),
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState): ITenant => state.tenant,
    }),
    localEnabled: {
      get() {
        return this.value?.available ?? false;
      },
      set(available: boolean) {
        this.$emit('input', {
          ...this.value,
          available,
        });
      },
    },
    localMentoringRoles: {
      get() {
        return this.value?.roles ?? [];
      },
      set(roles: Array<MentoringRole>) {
        this.$emit('input', {
          ...this.value,
          roles,
        });
      },
    },
    localMeetingLocations: {
      get() {
        return this.value?.meetingLocations ?? [];
      },
      set(meetingLocations: Array<MeetingLocation>) {
        this.$emit('input', {
          ...this.value,
          meetingLocations,
        });
      },
    },
  },
});
</script>

<style lang="scss" scoped>
:deep(.v-chip--active) {
  color: var(--v-primary-base);
}
</style>
