export enum KnownValidationRule {
  Min = 'min',
  Max = 'max',
  Required = 'required',
  Size = 'size',
  Email = 'email',
  Emails = 'emails',
  Between = 'between',
  Integer = 'integer',
  URL = 'url',
  AlphaDash = 'alpha_dash',
  UniqueDomain = 'uniq_domain',
}

export class ValidationRule {
  name: KnownValidationRule | string;
  params: Array<string>;

  constructor(rule: KnownValidationRule | string, ...params) {
    if (!Object.values(KnownValidationRule).includes(rule as KnownValidationRule)) {
      console.warn(`Unknown rule name: ${rule}`);
    }

    this.name = rule;
    this.params = params ?? [];
  }

  static fromString(representation: string) {
    const [name, params, ...problems] = representation.split(':');
    if (!name || problems?.length > 0) {
      throw new SyntaxError(`Invalid rule: ${representation}`);
    }
    return new ValidationRule(name, ...(params?.split(',') ?? []));
  }

  toString() {
    if (this.hasParams()) {
      return `${this.name}:${this.params.join(',')}`;
    }
    return this.name;
  }

  hasParams() {
    return this.params?.length > 0;
  }
}

export class ValidationList {
  private rules: Array<ValidationRule>;

  constructor(rules?: Array<ValidationRule>) {
    this.rules = rules ?? [];
  }

  static fromString(representation?: string) {
    return new ValidationList(
      representation?.split('|').map((ruleRepresentation) => ValidationRule.fromString(ruleRepresentation)),
    );
  }

  toString() {
    return this.rules.join('|');
  }

  isEmpty() {
    return this.rules.length === 0;
  }

  includes(name: KnownValidationRule) {
    return this.rules.findIndex((rule) => rule.name === name) !== -1;
  }

  add(name: KnownValidationRule, ...params) {
    this.rules.push(new ValidationRule(name, ...params));
  }

  remove(name: KnownValidationRule) {
    const index = this.rules.findIndex((rule) => rule.name === name);
    if (index !== -1) {
      this.rules.splice(index, 1);
    }
  }
}
