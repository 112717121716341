<template>
  <m-text-field
    v-model="localValue"
    :height="52"
    :loading="loading || waiting"
    :placeholder="$t('search.searchPlaceholder')"
    background-color="var(--v-white-base)"
    class="m-search-box"
    dense
    prepend-inner-icon="search"
    v-bind="{ ...$attrs }"
  />
</template>

<script lang="ts">
import Vue from 'vue';
import { MTextField } from '@/components/Inputs';

export default Vue.extend({
  name: 'm-search-field',
  components: { MTextField },
  props: {
    value: { type: String },
    debounce: { type: Number, default: 500 },
    loading: { type: Boolean, default: false },
  },
  data: () => ({
    waiting: false as boolean,
    previousValue: undefined as string,
    debounceTimer: undefined,
  }),
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value: string) {
        clearTimeout(this.debounceTimer);
        this.waiting = true;
        this.debounceTimer = setTimeout(async () => {
          if (this.previousValue !== value) {
            this.previousValue = value;
            this.waiting = false;
            this.$emit('input', value);
          }
        }, this.debounce);
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.m-search-box:deep(.v-input__prepend-inner) {
  margin-top: 14px !important;
}
</style>
