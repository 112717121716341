<template>
  <ais-refinement-list :attribute="filter.attribute" :operator="filter.operator">
    <template v-slot="{ refine }">
      <v-switch
        :label="$t(`filters.${filter.name}`)"
        :value="toggled"
        class="pl-3"
        dense
        inset
        @change="refine('true')"
      >
        <template v-slot:label>
          <span>{{ $t(`filters.${filter.name}`) }}</span>
        </template>
      </v-switch>
    </template>
  </ais-refinement-list>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { mapState } from 'vuex';
import { SearchState } from '@/store/search';

export interface Filter {
  name: string;
  attribute: string;
  operator: 'and' | 'or';
}

export default Vue.extend({
  name: 'm-ais-toggle-refinement',
  props: {
    filter: { type: Object as PropType<Filter> },
  },
  computed: {
    ...mapState<SearchState>('search', {
      activeFacets: (state: SearchState) => state.activeFacets,
    }),
    toggled() {
      return this.activeFacets?.[this.filter.attribute]?.includes('true') ?? false;
    },
  },
});
</script>
