<template>
  <v-dialog
    v-model="notificationDialog.isOpened"
    max-width="460"
    content-class="m-notification-dialog"
    persistent
    sentry-tag="notification-dialog"
  >
    <v-card>
      <v-card-title class="d-flex justify-center">
        <v-avatar :color="notificationDialog.variant" size="60">
          <v-icon dark x-large>
            {{ icon }}
          </v-icon>
        </v-avatar>
      </v-card-title>
      <v-card-text class="pt-10 text-center text-body-1">
        <p class="text-subtitle-1">{{ notificationDialog.message }}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="closeDialog"
          :color="notificationDialog.variant"
          dark
          sentry-tag="notification-dialog.button.close"
        >
          <span class="text-capitalize">
            {{ buttonText }}
          </span>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapState } from 'vuex';
import { CloseNotificationDialog, NotificationsState } from '@/store/notifications';

export default Vue.extend({
  name: 'm-notification-dialog',
  computed: {
    ...mapState<NotificationsState>('notifications', {
      notificationDialog: (state: NotificationsState) => state.notificationDialog,
    }),

    icon() {
      const icons = {
        success: 'check',
        warning: 'warning',
        pending: 'hourglass_top',
        error: 'thumb_down',
      };
      return icons[this.notificationDialog.variant] || 'check';
    },

    buttonText() {
      return this.notificationDialog.buttonText || 'Ok';
    },
  },
  methods: {
    closeDialog() {
      this.$store.dispatch(new CloseNotificationDialog());
    },
  },
});
</script>
