<template>
  <v-app v-if="render" fill-height fluid sentry-tag="invite-app">
    <v-row v-if="tenant" align="center" justify="center">
      <validation-observer v-slot="{ invalid }">
        <v-card class="pa-8" width="400">
          <v-card-title class="justify-center">
            <v-img :src="image" max-width="128" />
          </v-card-title>
          <v-card-text class="text-center black--text">
            <p class="mt-8 mb-0">
              {{ $t('settings.invitations.wasInvited', { title: tenant.attributes.theme.title }) }}
            </p>
            <m-text-field
              v-if="invite && !inviteSent"
              class="mt-4"
              v-model="email"
              :label="$t('settings.invitations.emailPlaceholder')"
              sentry-tag="invite-app.input.email"
              validation-rules="required|email"
            />
            <p v-else-if="inviteSent">{{ $t('settings.invitations.sent') }}</p>
            <p v-else>
              {{ isRitualInvite ? $t('settings.invitations.expiredEvent') : $t('settings.invitations.expired') }}
            </p>
          </v-card-text>
          <v-card-actions v-if="invite && !inviteSent" class="justify-center">
            <v-btn
              :disabled="invalid"
              class="pr-8 pl-8"
              color="button"
              sentry-tag="invite-app.button.useInvite"
              x-large
              @click="useInvite"
            >
              {{ $t('settings.invitations.getInvite') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </v-row>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapState } from 'vuex';
import { LoadTenant, TenantState } from '@/store/tenant';
import { TenantEntity } from '@mentessa/types';
import { InvitesState, LoadInvite, UseInvite } from '@/store/invites';
import { ValidationObserver } from 'vee-validate';
import i18n from '@/plugins/i18n';
import { LoadTranslations } from '@/store/ui';
import MTextField from '@/components/Inputs/MTextField/MTextField.vue';

export default Vue.extend({
  name: 'InviteApp',
  components: {
    MTextField,
    ValidationObserver,
  },

  data: () => ({
    email: '',
    inviteSent: false,
    render: false,
  }),

  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    ...mapState<InvitesState>('invites', {
      invite: (state: InvitesState) => state.current,
    }),
    image() {
      if (this.tenant?.attributes.theme.image) {
        return this.tenant?.attributes.theme.image;
      }
      return require('@/assets/logo.svg');
    },
    isRitualInvite() {
      return this.invite?.ritual != null;
    },
  },
  watch: {
    async tenant(tenant: TenantEntity) {
      const theme = this.$vuetify.theme.themes.light;
      const colors = tenant.attributes.theme.colors;
      theme.primary = colors.primary;
      theme.secondary = colors.secondary;
      theme.accent = colors.accent;
      const title = tenant.attributes.theme.title;
      document.title = title ? title : 'Mentessa';
      if (tenant.attributes.theme.favicon) {
        const favicon = document.getElementById('favicon') as HTMLLinkElement;
        favicon.href = tenant.attributes.theme.favicon;
      }

      const available = tenant.config.locales.available ?? ['en'];
      const browserLocale = navigator.language?.split('-')[0];
      const ifAvailable = (locale) => (locale && available.includes(locale) ? locale : null);
      i18n.locale = ifAvailable(browserLocale) ?? ifAvailable(tenant.config.locales.default) ?? 'en';
      await this.$store.dispatch(new LoadTranslations(i18n.locale));
    },
  },
  methods: {
    async useInvite() {
      if (await this.$store.dispatch(new UseInvite(this.invite, this.email))) {
        this.inviteSent = true;
      }
    },
  },
  async created() {
    const searchParams = new URLSearchParams(location.search);
    const guid = searchParams.get('key');
    await this.$store.dispatch(new LoadInvite(guid));

    if (this.isRitualInvite) {
      localStorage.setItem('invite', this.invite.guid);
      const registerUrl = this.$keycloak.createRegisterUrl({ redirectUri: window.location.origin });
      console.log('register:', `${registerUrl}&invitation=${this.invite.guid}`);

      const loginUrl = this.$keycloak.createLoginUrl({ redirectUri: window.location.origin });
      console.log('login:', `${loginUrl}&invitation=${this.invite.guid}`);

      location.href = `${registerUrl}&invitation=${this.invite.guid}`;
    } else {
      await this.$store.dispatch(new LoadTenant());
      this.render = true;
    }
  },
});
</script>
